import { RawEditorOptions } from 'tinymce';
import { isImageElement } from 'utils/html';

const EDITOR_HTML_MARGIN = 8;

export const pastImageProcessor: RawEditorOptions['paste_postprocess'] = (editor, args) => {
  const element = args.node;
  const children = element.children;

  const container = editor.editorContainer;

  const { width } = container.getBoundingClientRect();

  Array.from(children).forEach((element) => {
    if (isImageElement(element)) {
      const image = new Image();
      image.onload = function () {
        const devicePixelRatio = window.devicePixelRatio;

        const imgWidth = image.width / devicePixelRatio;
        const imgHeight = image.height / devicePixelRatio;

        const rotation = imgWidth / imgHeight;

        const newWidth = Math.min(Math.floor(width - EDITOR_HTML_MARGIN * 2), imgWidth);
        const newHeight = Math.floor(newWidth / rotation);

        const bodyElement = editor.getBody();
        const el = bodyElement.querySelector(`[src="${image.src}"]`);

        if (!el) return;

        el.setAttribute('width', String(newWidth));
        el.setAttribute('height', String(newHeight));
      };
      image.src = element.src;
    }
  });
};
