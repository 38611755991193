import { yupResolver } from '@hookform/resolvers/yup';
import { AppDatePicker } from 'components/app-date-picker';
import { AppInput } from 'components/app-input';
import { DialogApply } from 'components/dialog-apply';
import { useFieldProps, useTranslate } from 'hooks';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ImageData, schemaImageData } from '../../models';

interface Props {
  initValue: ImageData;
  onClose: () => void;
  onChange: (formData: ImageData) => void;
}

export const DialogEdit: React.FC<Props> = ({ initValue, onClose, onChange }) => {
  const { tp } = useTranslate();
  const { handleSubmit, control, register } = useForm({
    defaultValues: schemaImageData.cast({ ...initValue }),
    resolver: yupResolver(schemaImageData),
  });

  const getFieldProps = useFieldProps();

  return (
    <DialogApply
      isLoading={false}
      onClose={onClose}
      onApply={handleSubmit(onChange)}
      title={tp('insert-images')}
    >
      <input {...register('id')} hidden />
      <input {...register('userCrmProfileID')} hidden />
      <Controller
        control={control}
        name={'entryDate'}
        render={(renderProps) => {
          return (
            <AppDatePicker
              {...getFieldProps(renderProps)}
              slotProps={{ textField: { fullWidth: true } }}
            />
          );
        }}
      />
      <Controller
        control={control}
        name={'description'}
        render={(renderProps) => {
          return <AppInput autoFocus {...getFieldProps(renderProps)} multiline minRows={2} />;
        }}
      />
    </DialogApply>
  );
};
