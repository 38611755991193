import { Box } from '@mui/material';
import InactiveLabel from 'components/inactive-label';
import { HTMLAttributes } from 'react';
import { Unset } from '../../utils/types';

export interface IUserDetailsItem {
  firstName?: Unset;
  lastName?: Unset;
  title?: string;
  isActive?: boolean;
}

export const getUserDetailsOptionLabel = <T extends IUserDetailsItem>(option: T) =>
  [option.firstName, option.lastName].filter(Boolean).join(' ');

const renderUserDetailsOption = (props: HTMLAttributes<HTMLElement>, option: IUserDetailsItem) => {
  const name = option.firstName && option.lastName;
  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={'100%'}
      {...props}
    >
      <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Box>{name ? `${option.firstName} ${option.lastName}` : option.title}</Box>
        <Box>{!option.isActive && <InactiveLabel />}</Box>
      </Box>
    </Box>
  );
};

export default renderUserDetailsOption;
