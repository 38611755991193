import { Box, FormControl, FormHelperText, InputLabel } from '@mui/material';
import clsx from 'clsx';
import { memo, useCallback, useState } from 'react';
import { DEFAULT_INIT, HtmlEditor, HtmlEditorProps, InitOptions } from '../html-editor';
import style from './index.module.scss';

export const DEFAULT_INIT_AUTOSIZE: InitOptions = {
  ...DEFAULT_INIT,
  plugins: DEFAULT_INIT.plugins + ' autoresize',
  min_height: 200,
};

const DEFAULTS: InitOptions = {
  ...DEFAULT_INIT_AUTOSIZE,
  auto_focus: true,
};

export interface Props extends HtmlEditorProps {
  classNameEditor?: string;
}

export const HtmlEditorPreview = memo<Props>(({ value, onChange, ...rest }) => {
  const [isShow, setIsShow] = useState(false);

  const handleClick = useCallback(() => {
    setIsShow(true);
  }, [setIsShow]);

  return isShow ? (
    <HtmlEditor
      sx={{ position: 'relative' }}
      value={value}
      onChange={onChange}
      {...rest}
      editorProps={{
        init: DEFAULTS,
      }}
    />
  ) : (
    <FormControl {...rest} className={clsx(style.root, rest?.className)}>
      {rest.label && (
        <InputLabel shrink variant={'standard'}>
          {rest.label}
        </InputLabel>
      )}
      <Box className={clsx(style.html, rest?.classNameEditor)} onClick={handleClick} mt={2.2}>
        <div
          dangerouslySetInnerHTML={{
            __html: value ? value : rest.label ? `<p><i>${rest.label}</i></p>` : '',
          }}
        />
      </Box>
      {rest.helperText && <FormHelperText>{rest.helperText}</FormHelperText>}
    </FormControl>
  );
});
