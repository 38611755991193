import { createTheme, ThemeOptions } from '@mui/material';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';
import variables from 'styles/config.scss';

const appBreakpoints = {
  values: {
    xs: Number(variables.xs),
    sm: Number(variables.sm),
    md: Number(variables.md),
    lg: Number(variables.lg),
    xl: Number(variables.xl),
    xxl: Number(variables.xxl),
  },
};

const breakpoints = createBreakpoints({ ...appBreakpoints });
const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string, lightColor?: string, darkColor?: string) =>
  augmentColor({ color: { main: mainColor, light: lightColor, dark: darkColor } });

export const AppThemeOptions: ThemeOptions = {
  breakpoints: breakpoints,
  spacing: 10,

  typography: {
    fontSize: 14,
    htmlFontSize: 10,
    h1: {
      fontSize: '4rem',
      lineHeight: 1.2,
      fontWeight: 'normal',
    },
    h2: {
      fontSize: '2.4rem',
      lineHeight: 1.33,
      fontWeight: 500,
    },
    h3: {
      fontSize: '2rem',
      lineHeight: 1.2,
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.8rem',
      lineHeight: 1.2,
      fontWeight: 500,
    },

    subtitle1: {
      fontSize: '1.6rem',
      lineHeight: 1.25,
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '1.4rem',
      lineHeight: 1.4,
      fontWeight: 500,
    },
    body1: {
      fontSize: '1.4rem',
      lineHeight: 1.1,
    },
    body2: {
      fontSize: '1.4rem',
      lineHeight: 1.4,
    },
    button: {
      fontSize: '1.4rem',
      fontWeight: 500,
    },
    caption: {
      fontSize: '1.2rem',
      lineHeight: 1.25,
    },
  },

  palette: {
    primary: {
      main: variables.colorPrimary,
      dark: variables.colorSecondary,
      contrastText: '#fff',
    },
    secondary: {
      main: variables.colorSecondary,
      dark: variables.colorPrimary,
      contrastText: '#fff',
    },
    success: {
      main: variables.colorGreen,
    },
    error: {
      main: variables.colorRed,
    },
    warning: {
      main: variables.colorYellow,
    },
    text: {
      secondary: variables.colorGrey,
    },

    silver: createColor(variables.colorGrey),

    tonalOffset: 0.05,
  },

  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        containedPrimary: {
          borderRadius: '.4rem',
        },
        root: {
          fontSize: '1.4rem',
          lineHeight: '1.429',
          fontWeight: 500,
          letterSpacing: '0.125rem',
        },
        sizeLarge: {
          minHeight: '4.8rem',
        },
        sizeMedium: {
          minHeight: '3.6rem',
        },
        sizeSmall: {
          minHeight: '2.8rem',
          fontSize: '1.2rem',
          letterSpacing: 'normal',
          lineHeight: 1,
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        color: 'secondary',
        size: 'small',
        autoComplete: 'off',
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          height: '1.8em',
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          lineHeight: '1.8em',
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        underline: {
          '&::before': {
            borderBottomColor: variables.colorGreyLight,
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        notchedOutline: {
          borderColor: '#E9EEF0',
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: variables.colorGrey,
          letterSpacing: '0.04rem',
          paddingRight: '2rem',
        },
        shrink: {
          color: variables.colorGrey,
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          marginTop: '0.2rem',
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          fontSize: '1.4rem',
          padding: '1.4rem 0.8rem',
        },
        option: {
          borderRadius: '0.4rem',
          padding: '0.1rem 0.8rem 0.1rem 1.2rem !important',
          minHeight: '3.2rem !important',
          display: 'grid',
          alignItems: 'center',
        },
        clearIndicator: {
          color: variables.colorSecondaryLight,
        },
        popupIndicator: {
          color: variables.colorSecondaryLight,
        },
      },
    },

    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '2.4rem',
        },
        fontSizeSmall: {
          fontSize: '1.5rem',
        },
      },
    },

    MuiPaginationItem: {
      styleOverrides: {
        root: {
          height: '3.2rem',
          minWidth: '3.2rem',
          fontSize: '1.2rem',
          fontWeight: 400,
          margin: '0 0.5rem',
          backgroundColor: variables.colorGreyLight2,
          color: variables.colorPrimary,
        },
        icon: {
          fontSize: '2.2rem',
        },
        text: {
          lineHeight: '2.4rem',
        },
      },
    },

    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: variables.boxShadowDarkBlue,
        },
      },
    },

    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          color: variables.colorSecondaryLightest,
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        // sizeMedium: {
        //   padding: '0.6rem',
        // },
        root: {
          '&.Mui-disabled': {
            color: variables.colorGrey,
          },
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '0.8rem',
        },
        paperFullScreen: {
          borderRadius: '0',
        },
        //@ts-ignore
        backdrop: {
          background: 'rgba(0,0,0,0.25)',
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '2rem ',
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '0 2rem 2rem',
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '1.6rem 2rem',
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: variables.boxShadowDarkBlue,
          borderRadius: '0',
        },
        outlined: {
          boxShadow: 'none',
          borderRadius: '0.4rem',
          borderColor: variables.colorGreyLight,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: '4px',
          padding: '2.2rem 0',
          marginBottom: 0,
        },
        track: {
          border: 'none',
        },
        thumb: {
          height: '1.6rem',
          width: '1.6rem',
        },
        rail: {
          height: '2px',
          opacity: 1,
        },
        mark: {
          width: '1px',
          height: '4px',
          background: '#fff',
        },
        markLabel: {
          fontSize: '1.2rem',
          top: '-.4rem',
          '@media (pointer: coarse)': {
            top: '-.4rem',
          },
        },
        colorPrimary: {
          '.MuiSlider-markLabel': {
            color: variables.colorPrimary,
          },
          '.MuiSlider-rail': {
            backgroundColor: variables.colorGrey,
          },
          '.MuiSlider-track': {
            backgroundColor: variables.colorSecondary,
          },
        },
        colorSecondary: {
          '.MuiSlider-markLabel': {
            color: variables.colorSecondary,
          },
          '.MuiSlider-rail': {
            backgroundColor: variables.colorGrey,
          },
          '.MuiSlider-track': {
            backgroundColor: variables.colorPrimary,
          },
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        gutters: {
          [breakpoints.up('xs')]: {
            paddingLeft: '1.6rem',
            paddingRight: '1.6re',
          },
          [breakpoints.up('md')]: {
            paddingLeft: '2rem',
            paddingRight: '2rem',
          },
        },
      },
    },

    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '1.6rem',
          paddingRight: '1.6rem',
          [breakpoints.up('sm')]: {
            paddingLeft: '1.6rem',
            paddingRight: '1.6rem',
          },
          [breakpoints.up('md')]: {
            paddingLeft: '1.6rem',
            paddingRight: '1.6rem',
          },
          [breakpoints.up('lg')]: {
            paddingLeft: '3rem',
            paddingRight: '3rem',
          },
        },
      },
    },

    MuiAlert: {
      styleOverrides: {
        root: {
          paddingTop: 5,
          paddingBottom: 5,
          boxShadow: 'none',
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: variables.colorGreyLight,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '.Mui-checked': {
            color: 'red',
            '.MuiSwitch-thumb::before': {
              display: 'none',
            },
          },
        },
        track: {
          backgroundColor: variables.colorGreyLight,
          opacity: 1,
        },
        thumb: {
          position: 'relative',
          boxShadow: '0px 0 2px 0 rgba(0,0,0,0.4), 0px 3px 3px 0 rgba(0,0,0,0.15)',
          '&::before': {
            display: 'block',
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            height: 20,
            width: 20,
            borderRadius: '50%',
            backgroundColor: variables.colorGreyLight2,
          },
        },
      },
    },
  },
};
