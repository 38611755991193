import { Model, TicketActionItemDialogNew } from 'components/ticket-action-item-containers';
import { BaseSelectTicketDialog, ModelTicket } from 'components/tickets';
import { endOfWeek, startOfWeek } from 'date-fns';
import { useAppSelector } from 'hooks';
import { useStatusesWithTickets } from 'hooks/use-statuses-with-tickets';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { AppNote } from 'services/notes';
import { ITicketActionItem } from 'services/ticket-action-items';
import { selectNotesLayoutFilterCustomerID } from 'store/notes-layout';
import { selectSourceTicketsFullMap } from 'store/sources';

interface DialogSelectTicketProps {
  customerID: string | null;
  onSubmit: (model: ModelTicket) => void;
  onCancel: () => void;
}

const DialogSelectTicket: React.FC<DialogSelectTicketProps> = ({
  onSubmit,
  onCancel,
  customerID,
}) => {
  const { data, isLoading } = useStatusesWithTickets();

  const _statuses = useMemo(() => {
    const copyOfStatuses = [...data];
    copyOfStatuses.splice(-1);
    return copyOfStatuses.filter((status) => status._tickets.length > 0);
  }, [data]);

  const statuses = useMemo(() => {
    return _statuses
      .map((item) => {
        let tickets = customerID
          ? item._tickets.filter((ticket) => ticket._customer?.id === customerID)
          : item._tickets;
        return { id: item.id, labelKey: item.labelKey, tickets };
      })
      .filter((item) => item.tickets.length > 0);
  }, [_statuses, customerID]);

  return (
    <BaseSelectTicketDialog
      isLoading={isLoading}
      statuses={statuses}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
};

interface Props {
  note: AppNote;
  onClose: () => void;
  onDone: (ticketActionItem: Pick<ITicketActionItem, 'id'>) => void;
}

export const ContainerAttachToTicket = memo<Props>(({ onClose, note, onDone }) => {
  const [ticketID, setTicketID] = useState<string | undefined>(undefined);

  const filterCustomerID = useAppSelector(selectNotesLayoutFilterCustomerID);

  const customerID = note.customerID || filterCustomerID;

  // start: load data
  useStatusesWithTickets();
  // end: load data

  const ticketsMap = useAppSelector(selectSourceTicketsFullMap);
  const selectedTicket = ticketID ? ticketsMap[ticketID] : null;

  const initValues = useMemo<Partial<Model>>(
    () => ({
      priorityID: selectedTicket?.priorityID,
      reporterUserCrmProfileID: selectedTicket?.defaultReporterUserCrmProfileID,

      title: note.title ?? undefined,
      description: note.description ?? undefined,
      images: note.images.map((image) => ({ ...image, id: null })),
      assigneeUserCrmProfileID: note?.userCrmProfileID,

      activityReportDescription: note.title ?? undefined,

      startDateAndTime: startOfWeek(new Date()).toISOString(),
      endDateAndTime: endOfWeek(new Date()).toISOString(),
    }),
    [selectedTicket, note],
  );

  const onSelected = useCallback((input: ModelTicket) => {
    setTicketID(input.ticketID);
  }, []);

  if (!selectedTicket) {
    return <DialogSelectTicket onCancel={onClose} onSubmit={onSelected} customerID={customerID} />;
  }

  return (
    <TicketActionItemDialogNew
      ticketID={selectedTicket.id}
      onClose={onClose}
      onDone={onDone}
      initValues={initValues}
    />
  );
});
