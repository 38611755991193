import { Box, Typography } from '@mui/material';
import { Item, MenuButton, MenuButtonProps } from 'components/menu-button';
import { TemplateIcon } from 'components/templates/template-icon';
import React, { useMemo } from 'react';

interface IconProps {
  src?: string;
  alt?: string;
}
const Icon: React.FC<IconProps> = ({ src, alt }) => {
  return (
    <Box
      component={'img'}
      src={src}
      alt={alt || ''}
      width={'2rem'}
      height={'2rem'}
      display={'block'}
    />
  );
};

interface MenuWithIconProps {
  icon: string;
  title: string;
}
export const MenuWithIcon: React.FC<MenuWithIconProps> = ({ icon, title }) => {
  return (
    <TemplateIcon
      icon={<Icon src={icon} alt={title} />}
      TypographyProps={{ maxWidth: '20rem', noWrap: true }}
    >
      {title}
    </TemplateIcon>
  );
};

interface MenuWithColorProps {
  color: string;
  title: string;
}
export const MenuWithColor: React.FC<MenuWithColorProps> = ({ color, title }) => {
  return (
    <Typography maxWidth={'20rem'} title={title} color={color}>
      {title}
    </Typography>
  );
};

interface Props<T> extends Omit<MenuButtonProps, 'items'> {
  value: string;
  onChange?: (value: this['value'], option: T) => void;
  options: T[];
  children: React.ReactNode;
  renderOption: (option: T) => React.ReactNode;
}

export const BaseSubMenu = <
  T extends { id: string; icon?: string; title: string; color?: string },
>({
  value,
  onChange,
  options,
  children,
  renderOption,
  ...rest
}: Props<T>) => {
  const menuItems = useMemo<Array<Item>>(() => {
    return options.map((option) => ({
      id: option.id,
      title: renderOption(option),
      selected: option.id === value,
      onClick: () => onChange && onChange(option.id, option),
    }));
  }, [options, value, onChange, renderOption]);

  return (
    <MenuButton items={menuItems} {...rest}>
      {children}
    </MenuButton>
  );
};
