import { Box } from '@mui/material';
import { TemplateIcon } from 'components/templates/template-icon';
import { HTMLAttributes } from 'react';
import { Unset } from 'utils/types';

type Option = {
  icon: Unset;
  title: Unset;
};
export const renderOptionWithIcon = (props: HTMLAttributes<HTMLElement>, option: Option) => {
  return (
    <li {...props}>
      <TemplateIcon
        icon={
          <Box
            sx={{ width: 24, height: 24 }}
            component={'img'}
            src={option.icon ?? undefined}
            alt={''}
          />
        }
      >
        {option.title}
      </TemplateIcon>
    </li>
  );
};
