import AddIcon from '@mui/icons-material/Add';
import { ButtonBase, Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslate } from 'hooks';
import React from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { BaseSlot } from '../base-slot';
import style from './index.module.scss';

interface Props {
  disabled?: boolean;
  onClick: () => void;
  onDrop: (files: File[]) => void;
  isError?: boolean;
}
export const SlotNew: React.FC<Props> = ({ disabled, onClick, onDrop, isError }) => {
  const { tp } = useTranslate();
  const title = tp('add-image');

  const [{ isOver, canDrop, isDrugging }, drop] = useDrop(
    {
      accept: [NativeTypes.FILE],
      drop(item: { files: File[] }) {
        onDrop(item.files);
      },
      canDrop(item) {
        return !disabled;
      },
      hover(item) {},
      collect: (monitor) => {
        const item = monitor.getItem();

        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
          isDrugging: !!item && !!item.files,
        };
      },
    },
    [disabled, onDrop],
  );
  // console.log(isOver, canDrop, isDrugging);

  return (
    <>
      <BaseSlot
        ref={drop}
        alignItems={'center'}
        justifyContent={'center'}
        component={ButtonBase}
        disabled={disabled}
        onClick={onClick}
        className={clsx({
          [style.root]: true,
          [style.isOver]: isOver,
          [style.canDrop]: canDrop,
          [style.isDrugging]: isDrugging,
          [style.isError]: isError,
        })}
      >
        <AddIcon color={'inherit'} />
        <Typography fontWeight={500} color={'inherit'}>
          {title}
        </Typography>
      </BaseSlot>
    </>
  );
};
