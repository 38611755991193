import { createTypedRoute } from 'modules/typed-routes';

export const APP_ROUTES = {
  DASHBOARD: createTypedRoute('/'),
  TIME_TRACING: createTypedRoute('/time-tracking'),
  SETTINGS: createTypedRoute('/settings'),
  PROFILE: createTypedRoute('/profile'),

  NOTES: createTypedRoute('/notes'),
  NOTES_ARCHIVE: createTypedRoute('/notes/archive'),

  NOTIFICATIONS: createTypedRoute('/notifications'),
  NOTIFICATIONS_PERSONAL: createTypedRoute('/notifications/personal'),

  CHECK_DONE: createTypedRoute('/check-done'),
  CHECK_DONE_COMPLETED: createTypedRoute('/check-done/completed'),
  CHECK_DONE_UN_ASSIGN: createTypedRoute('/check-done/un-assign'),
  CHECK_DONE_TRASH: createTypedRoute('/check-done/trash'),
  CHECK_DONE_ALL: createTypedRoute<{
    'ticket-action-item'?: string;
    'ticket-action-item-comment'?: string;
  } | void>('/check-done/all'),

  MONTHLY_ACTIVITIES_REPORT: createTypedRoute('monthly-activities-report'),
  REPORTS: createTypedRoute('/reports'),
  REPORT_ITEM: createTypedRoute<{ reportID: string }>('/reports/:reportID'),

  PROJECTS: createTypedRoute('/settings/projects'),
  PROJECT_ITEM: createTypedRoute<{ projectID: string }>('/settings/projects/:projectID'),
  USERS: createTypedRoute('/settings/users'),
  USER_ITEM: createTypedRoute<{ appUserID: string | 'new' }>('/settings/users/:appUserID'),
  PERMISSIONS: createTypedRoute('/settings/permissions'),
  ALERTS: createTypedRoute('/settings/alerts'),
  LABELS: createTypedRoute('/settings/labels'),
  TIME_TRACKING_ACTIVITIES: createTypedRoute('/settings/time-tracking-activities'),

  TICKET_TYPES: createTypedRoute('/settings/ticket-types'),

  PRIORITIES: createTypedRoute('/settings/priorities'),
  TICKET_STATUSES: createTypedRoute('/settings/ticket-statuses'),

  TICKET_ACTION_ITEM_STATUSES: createTypedRoute('/settings/ticket-action-item-statuses'),

  EXPENSES: createTypedRoute('/expenses'),
  SUPPLIERS: createTypedRoute('/expenses/suppliers'),
  CATEGORIES: createTypedRoute('/expenses/categories'),

  LOGIN: createTypedRoute<{ redirect?: string }>('/login'),
  LOGIN_CODE: createTypedRoute<{ email: string; redirect?: string }>('/login/:email'),
};
