import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import React, { useId } from 'react';
import { BaseReport } from '../base-dialog-add-to-report';
import { formatRange } from '../base-dialog-add-to-report/helpers';
import style from './index.module.scss';

interface Props<O> extends RadioGroupProps {
  label?: React.ReactNode;
  value: string;
  options: O[];
  disabled?: boolean;
}
export const BaseReportRadioField = <O extends BaseReport>({
  options,
  value,
  onChange,
  disabled,
  label,
  ...radioGroupProps
}: Props<O>) => {
  const id = useId();

  return (
    <FormControl fullWidth>
      {label && <FormLabel id={id}>{label}</FormLabel>}
      <RadioGroup
        aria-labelledby={id}
        value={value}
        onChange={onChange}
        className={style.group}
        {...radioGroupProps}
      >
        {options.map((report) => {
          const selected = report.id === value;

          return (
            <FormControlLabel
              key={report.id}
              className={clsx(style.item, selected && style.itemSelected)}
              value={report.id}
              control={<Radio className={style.itemIcon} />}
              disabled={disabled}
              label={
                <>
                  <Typography variant={'caption'} fontWeight={'bold'} component={'div'}>
                    {formatRange(report)}
                  </Typography>
                  <Typography pt={0.5} component={'div'}>
                    {report.reportName}
                  </Typography>
                </>
              }
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};
