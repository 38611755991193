import { Avatar, Box, Stack, Typography } from '@mui/material';
import { BaseCommentDescription } from 'components/tickets';
import { TIME_FORMAT_DATE_TIME } from 'configs/const';
import React from 'react';
import { BaseImage } from 'services/@shared/images';
import variables from 'styles/config.scss';
import { formatDate } from 'utils/dates';
import { Unset } from 'utils/types';

interface Props {
  userPhoto: string;
  userName: string;
  date?: string;
  description: Unset;
  images: BaseImage[];
}

export const CommentPreviewItem: React.FC<Props> = ({
  userPhoto,
  userName,
  date,
  images,
  description,
}) => {
  return (
    <Stack direction={'row'} spacing={1}>
      <Box flex={'0 0 2.4rem'}>
        <Avatar
          sx={{ width: 24, height: 24, border: `1px solid ${variables.colorGrey}` }}
          src={userPhoto}
        />
      </Box>
      <Stack spacing={0.5} pt={0.2} flexGrow={1}>
        <Stack direction={'row'} spacing={1} alignItems={'baseline'}>
          <Typography variant={'subtitle1'} flexGrow={1}>
            {userName}
          </Typography>
          <Typography variant={'caption'} component={'div'} color={'text.secondary'}>
            {formatDate(date, TIME_FORMAT_DATE_TIME)}
          </Typography>
        </Stack>
        <BaseCommentDescription images={images} description={description} />
      </Stack>
    </Stack>
  );
};
