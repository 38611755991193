import {
  endOfMonth,
  endOfWeek,
  isSameDay,
  isWithinInterval,
  max,
  min,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import React, { memo, useMemo, useState } from 'react';
import style from './index.module.scss';

import { Box } from '@mui/material';
import {
  DateCalendar,
  DateCalendarClasses,
  DateCalendarProps,
  PickersDay,
} from '@mui/x-date-pickers';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay/PickersDay';
import clsx from 'clsx';
import { convertToDate, DateValue, maxDate, minDate } from 'utils/dates';

export interface ICalendarPickerStrategy {
  (pikedDate: Date | null, currentValue: Date[]): Date[];
}

interface Classes extends DateCalendarClasses {
  dayWrapper: string;
  dayWrapperHover: string;
  dayWrapperRange: string;
  dayWrapperStart: string;
  dayWrapperEnd: string;
  day: string;
}

export const strategyCalendarPickerRange: ICalendarPickerStrategy = (pikedDate, currentValue) => {
  const [start, end] = currentValue;
  if (!pikedDate) return [];
  if (!start) return [pikedDate];
  if (!end) return [start, pikedDate].sort((a, b) => Number(a) - Number(b));
  if (start && end) {
    if (
      isWithinInterval(pikedDate, {
        start: min([start, end]),
        end: max([start, end]),
      })
    ) {
      return Math.abs(Number(start) - Number(pikedDate)) < Math.abs(Number(end) - Number(pikedDate))
        ? [start, pikedDate]
        : [pikedDate, end];
    }
    return [pikedDate];
  }
  return [];
};
export const strategyCalendarPickerWeekly: ICalendarPickerStrategy = (pikedDate) => {
  if (!pikedDate) {
    return [];
  }
  return [startOfWeek(pikedDate), endOfWeek(pikedDate)];
};
export const strategyCalendarPickerMonthly: ICalendarPickerStrategy = (pikedDate) => {
  if (!pikedDate) {
    return [];
  }
  return [startOfMonth(pikedDate), endOfMonth(pikedDate)];
};

export interface CalendarRangePickerProps
  extends Omit<DateCalendarProps<any>, 'date' | 'onChange'> {
  className?: string;
  classes?: Partial<Classes>;
  value?: DateValue[];
  onChange?: (value: Date[]) => void;
  pickerStrategy?: ICalendarPickerStrategy;
}

interface ComponentSlotInterface {
  day: Date;
  start: Date;
  end: Date;
  hoverDay: Date | undefined;
  setHoverDay: React.Dispatch<React.SetStateAction<Date | undefined>>;
  className?: string;
  classes?: Partial<Classes>;
}

const ComponentSlot = memo<ComponentSlotInterface & PickersDayProps<Date>>(
  ({ day, start, end, hoverDay, setHoverDay, classes, className, ...rest }) => {
    const isFirstDay = Boolean(start && isSameDay(start, day));
    const isLastDay = Boolean(end && isSameDay(end, day));

    const isInTheInterval =
      start &&
      end &&
      isWithinInterval(day, {
        start: min([start, end]),
        end: max([start, end]),
      });

    const isHighlight = Boolean(isInTheInterval || isFirstDay || isLastDay);

    const previewStart = minDate(start, hoverDay);
    const previewEnd = maxDate(start, hoverDay);

    const isPreview =
      start &&
      !end &&
      hoverDay &&
      isWithinInterval(day, {
        start: previewStart,
        end: previewEnd,
      });

    const isSelectedOneDay = start && end && isSameDay(start, end);

    return (
      <Box
        onMouseEnter={() => setHoverDay(day)}
        onMouseLeave={() => setHoverDay(undefined)}
        className={clsx(style.dayWrapper, classes?.dayWrapper, {
          [style.dayWrapperHighlight]: isHighlight,
          [style.dayWrapperHighlightStart]: isHighlight && isFirstDay,
          [style.dayWrapperHighlightEnd]: isHighlight && isLastDay,

          [style.dayWrapperPreview]: isPreview,
          [style.dayWrapperPreviewStart]: isPreview && isSameDay(previewStart, day),
          [style.dayWrapperPreviewEnd]: isPreview && isSameDay(previewEnd, day),

          [style.dayWrapperTheSame]: isSelectedOneDay,
        })}
      >
        <PickersDay
          {...rest}
          day={day}
          selected={isFirstDay || isLastDay}
          className={clsx(className, style.day, classes?.day, className)}
        />
      </Box>
    );
  },
);

export const CalendarRangePicker = memo<CalendarRangePickerProps>(
  ({
    value: outerValue,
    onChange,
    className,
    classes,
    pickerStrategy = strategyCalendarPickerRange,
    ...rest
  }) => {
    const [innerValue, onChangeInner] = useState<Date[]>([]);
    const [hoverDay, setHoverDay] = useState<Date>();

    const pickerValue = useMemo(() => {
      if (outerValue) {
        return outerValue.filter(Boolean).map(convertToDate);
      }
      return innerValue;
    }, [outerValue, innerValue]);

    const pickerOnChange = onChange === undefined ? onChangeInner : onChange;

    const start = pickerValue[0];
    const end = pickerValue[1];

    const componentSlotProps = useMemo(() => {
      return { start, end, setHoverDay, hoverDay, classes, className };
    }, [start, end, setHoverDay, hoverDay, classes, className]);

    return (
      <DateCalendar
        {...rest}
        slots={{
          day: (props) => {
            return <ComponentSlot {...props} {...componentSlotProps} />;
          },
        }}
        slotProps={{
          day: {
            selectedDay: innerValue,
          } as any,
        }}
        value={start}
        onChange={(date) => {
          pickerOnChange(pickerStrategy(date, pickerValue));
        }}
      />
    );
  },
);
