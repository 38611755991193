import { createSelector } from '@reduxjs/toolkit';
import { groupBy, keyBy } from 'lodash-es';
import { selectSourceCustomersAll } from 'services/customers';
import { selectSourceProjectsAll } from 'services/projects';
import {
  selectSourceTicketStatusesAll,
  selectSourceTicketStatusesAllMap,
} from 'services/ticket-statuses';
import { selectSourceTicketsAll } from 'services/tickets';

export const selectSourceProjectsWithCustomer = createSelector(
  selectSourceProjectsAll,
  selectSourceCustomersAll,
  (projects, customers) => {
    const mapCustomers = keyBy(customers, 'id');
    return projects.map((project) => {
      return { ...project, _customer: mapCustomers[project.customerID] || null };
    });
  },
);

export const selectSourceProjectsWithCustomerMap = createSelector(
  selectSourceProjectsWithCustomer,
  (projects) => {
    return keyBy(projects, 'id');
  },
);

export const selectSourceTicketsFull = createSelector(
  selectSourceTicketsAll,
  selectSourceProjectsWithCustomerMap,
  selectSourceTicketStatusesAllMap,
  (tickets, mapProjects, mapStatuses) => {
    return tickets.map((ticket) => {
      const projectEntity = mapProjects[ticket.projectID] || null;
      return {
        ...ticket,
        _project: projectEntity || null,
        _customer: projectEntity?._customer || null,
        _status: mapStatuses[ticket.ticketStatusID] || null,
      };
    });
  },
);

export const selectSourceTicketsFullMap = createSelector(selectSourceTicketsFull, (items) => {
  return keyBy(items, 'id');
});

export const selectSourceTicketsFullByStatus = createSelector(
  selectSourceTicketStatusesAll,
  selectSourceTicketsFull,
  (statuses, selectSourceTicketsFull) => {
    let groupByStatus = groupBy(selectSourceTicketsFull, 'ticketStatusID');

    return statuses.map((status) => {
      return { ...status, _tickets: groupByStatus[status.id] || [] };
    });
  },
);
