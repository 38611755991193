import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Button, Stack } from '@mui/material';
import { MenuButton } from 'components/menu-button';
import {
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { useTranslate } from 'hooks';
import React, { useCallback, useMemo } from 'react';
import { convertToDate, isDateLike } from 'utils/dates';
import { CalendarRangePicker } from '../../../calendar-range-picker';
import { RenderCalendarProps } from '../../models';

export interface Props extends RenderCalendarProps {}
export const CalendarRange: React.FC<Props> = ({ value: _value, onChange, onApply }) => {
  const { t } = useTranslate();
  const value = useMemo(() => {
    return _value.filter(isDateLike).map(convertToDate);
  }, [_value]);

  const handleOnChange = useCallback(
    (value: Date[]) => {
      const newValue = [value[0] && startOfDay(value[0]), value[1] && endOfDay(value[1])].filter(
        isDateLike,
      );

      onChange(newValue);

      onApply(newValue);
    },
    [onChange, onApply],
  );

  const shortcuts = useMemo(() => {
    return [
      {
        title: t('current-week'),
        onClick: () => handleOnChange([startOfWeek(new Date()), endOfWeek(new Date())]),
      },
      {
        title: t('current-month'),
        onClick: () => handleOnChange([startOfMonth(new Date()), endOfMonth(new Date())]),
      },
      {
        title: t('current-quarter'),
        onClick: () => handleOnChange([startOfQuarter(new Date()), endOfQuarter(new Date())]),
      },
      {
        title: t('current-year'),
        onClick: () => handleOnChange([startOfYear(new Date()), endOfYear(new Date())]),
      },
    ];
  }, [t, handleOnChange]);

  return (
    <div>
      <Stack alignItems={'end'} p={1} mb={-1}>
        <MenuButton items={shortcuts}>
          <Button
            size={'small'}
            variant={'contained'}
            color={'primary'}
            endIcon={<FormatListBulletedIcon />}
          >
            {t('shortcuts')}
          </Button>
        </MenuButton>
      </Stack>

      <CalendarRangePicker value={value} onChange={handleOnChange} />
    </div>
  );
};
