import { Stack, Typography } from '@mui/material';
import {
  CommentPreview,
  CommentPreviewItem,
  DescriptionPreview,
  IntoItemWrapper,
  UserPreview,
} from 'components/ticket-action-item-containers/components/ticket-action-item-dialog/components/preview-content/components';
import { TicketActionItemLink } from 'components/ticket-action-item-containers/components/ticket-action-item-link';
import { useTicketActionItemInformation } from 'components/ticket-action-item-containers/hooks/use-ticket-information';
import { BaseBreadcrumbs } from 'components/tickets';
import { useAppSelector, useEffectNotifyError, useTranslate } from 'hooks';
import React, { useMemo } from 'react';
import { apiPriorities, selectSourcePrioritiesAllMap } from 'services/priorities';
import { apiTicketActionItemComments } from 'services/ticket-action-item-comments';
import {
  apiTicketActionItemStatuses,
  selectSourceTicketActionItemStatusesAllMap,
} from 'services/ticket-action-item-statuses';
import { ITicketActionItemWithMeta } from 'services/ticket-action-items';
import { apiUserCrmProfiles, selectSourceCrmUsersAllMap } from 'services/user-crm-profiles';
import { formatDate, isDateLike } from 'utils/dates';
import { Unset } from 'utils/types';
import style from './index.module.scss';

const useFetchCommentsQuery =
  apiTicketActionItemComments.useGetTicketActionItemCommentsByTicketActionQuery;

interface Props {
  itemKey: string;
  data: Unset<ITicketActionItemWithMeta>;
}
export const TicketActionItemPreview: React.FC<Props> = ({ data, itemKey }) => {
  const { tp } = useTranslate();
  const id = data?.id;
  const resultInfo = useTicketActionItemInformation(id);
  useEffectNotifyError(resultInfo.error);
  const { customer, ticket } = resultInfo;

  apiPriorities.useGetPrioritiesAllQuery();
  const mapPriority = useAppSelector(selectSourcePrioritiesAllMap);
  const priority = data ? mapPriority[data.priorityID] : null;

  apiTicketActionItemStatuses.useGetTicketActionItemStatusesSourceQuery();
  const mapStatus = useAppSelector(selectSourceTicketActionItemStatusesAllMap);
  const status = data?.ticketActionItemStatusID ? mapStatus[data.ticketActionItemStatusID] : null;

  apiUserCrmProfiles.useGetUserCrmProfileSourceQuery();
  const mapUsers = useAppSelector(selectSourceCrmUsersAllMap);

  const assignee = data ? mapUsers[data.assigneeUserCrmProfileID] : null;
  const reporter = data ? mapUsers[data.reporterUserCrmProfileID] : null;

  const date = useMemo(() => {
    return [data?.startDateAndTime, data?.endDateAndTime]
      .filter(isDateLike)
      .map((v) => formatDate(v))
      .join(' - ');
  }, [data]);

  const resultComments = useFetchCommentsQuery(id || '', {
    skip: !id,
  });

  const comments = useMemo(() => {
    return (resultComments.data || []).map((item) => {
      return {
        ...item,
        _user: mapUsers[item.userCrmProfileID] || null,
      };
    });
  }, [resultComments.data, mapUsers]);

  const breadcrumbs = useMemo(
    () => [
      ...(customer ? [{ label: customer.title }] : []),
      ...(ticket ? [{ label: ticket.title }] : []),
    ],
    [customer, ticket],
  );

  return (
    <div className={style.root}>
      <Stack direction={'row'} mb={1}>
        <BaseBreadcrumbs
          isLoading={resultInfo.isFetching}
          sx={{ flexGrow: 1 }}
          items={breadcrumbs}
        />
        <TicketActionItemLink itemKey={itemKey} />
      </Stack>

      <Typography variant={'subtitle1'} component={'h4'} color={'primary'} mb={2}>
        {data?.title}
      </Typography>

      <Stack spacing={2}>
        <div className={style.group}>
          <IntoItemWrapper
            title={tp('priority')}
            sx={{
              minWidth: 100,
              maxWidth: 100,
            }}
          >
            <Stack direction={'row'} alignItems={'center'} spacing={0.4}>
              <img src={priority?.icon} alt={priority?.labelKey} />
              {priority?.labelKey}
            </Stack>
          </IntoItemWrapper>
          <IntoItemWrapper title={tp('ticket-action-item-status')}>
            <Typography
              variant={'caption'}
              component={'div'}
              sx={{
                backgroundColor: status?.color,
                color: '#fff',
                minHeight: 20,
                borderRadius: 16,
                display: 'inline-flex',
                alignItems: 'center',
                paddingX: 1.2,
              }}
            >
              {status?.labelKey}
            </Typography>
          </IntoItemWrapper>
          <IntoItemWrapper
            title={tp('reporter')}
            sx={{
              minWidth: 120,
              maxWidth: 120,
            }}
          >
            <UserPreview userPhoto={assignee?.userPhoto} fullName={assignee?.fullName} />
          </IntoItemWrapper>
          <IntoItemWrapper
            title={tp('assignee')}
            sx={{
              minWidth: 120,
              maxWidth: 120,
            }}
          >
            <UserPreview userPhoto={reporter?.userPhoto} fullName={reporter?.fullName} />
          </IntoItemWrapper>
          <IntoItemWrapper title={tp('date')}>{date}</IntoItemWrapper>
        </div>

        {data?.description && (
          <DescriptionPreview description={data?.description} images={data?.images} />
        )}

        {comments.length > 0 && (
          <CommentPreview>
            {comments.map((item) => {
              return (
                <CommentPreviewItem
                  key={item.id}
                  userPhoto={item._user?.userPhoto}
                  userName={item._user?.fullName}
                  date={item.createdDate}
                  description={item.description}
                  images={item?.images}
                />
              );
            })}
          </CommentPreview>
        )}
      </Stack>
    </div>
  );
};
