import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';
import { IDoneItem } from 'services/done-items';
import { convertToDate, DateValue } from 'utils/dates';
import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'Reports';

export const API_REPORTS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  CREATE: `${API}/Create`,
  PATCH: (data: Pick<IReport, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<IReport, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IGetAllReport
  extends FixServerObject<
    Components.Schemas.Report,
    | 'id'
    | 'createdDate'
    | 'reportName'
    | 'bill'
    | 'lock'
    | 'reportDoneItems'
    | 'fromDate'
    | 'toDate'
  > {
  reportDoneItemsCount: number | undefined;
}

export enum REPORT_DIRECTION {
  LTR = 1,
  RTL = 2,
}

export interface IReport
  extends FixServerObject<
    Omit<Components.Schemas.Report, 'direction'>,
    'id' | 'fromDate' | 'toDate' | 'reportName' | 'excelFileName' | 'customerID'
  > {
  direction?: REPORT_DIRECTION;
}

export interface IGetReport extends IReport {
  customerName: string;
  direction: REPORT_DIRECTION;
}
export const schemaReport = yup.object({
  templateName: yup.string().required('rule-required').default('weekly'),
  customerName: yup.string().default(''),
  customerID: yup.string().required('rule-required').default(''),
  fromDate: yup.string().required('rule-required').default(''),
  toDate: yup.string().required('rule-required').default(''),
  reportName: yup.string().required('rule-required').default(''),
  excelFileName: yup.string().required('rule-required').default(''),
  displayColumnCustomerName: yup.boolean().default(false),
  direction: yup
    .mixed<REPORT_DIRECTION>()
    .oneOf(Object.values(REPORT_DIRECTION) as REPORT_DIRECTION[])
    .default(REPORT_DIRECTION.LTR),

  lock: yup.boolean().default(false),
  bill: yup.boolean().default(false),

  displayColumnProjectName: yup.boolean().default(false),
  columnProjectName: yup.string().trim().default(''),

  displayColumnUserName: yup.boolean().default(true),
  columnUserName: yup.string().trim().default(''),

  displayColumnDate: yup.boolean().default(true),
  columnDate: yup.string().trim().default(''),

  displayColumnTag: yup.boolean().default(false),
  columnTag: yup.string().trim().default(''),

  displayColumnDuration: yup.boolean().default(false),
  columnDuration: yup.string().trim().default(''),

  displayColumnBillableDuration: yup.boolean().default(true),
  columnBillableDuration: yup.string().trim().default(''),

  displayColumnDescription: yup.boolean().default(true),
  columnDescription: yup.string().trim().default(''),

  maxHoursReport: yup.number().default(0),
  maxHoursPerUser: yup.number().default(0),
});

export const templateOptions = [
  {
    id: 'monthly',
    title: 'monthly',
    makeRange: (date: DateValue) => {
      const dateInst = convertToDate(date);

      return [startOfMonth(dateInst), endOfMonth(dateInst)];
    },
  },
  {
    id: 'weekly',
    title: 'weekly',
    makeRange: (date: DateValue) => {
      const dateInst = convertToDate(date);

      return [startOfWeek(dateInst), endOfWeek(dateInst)];
    },
  },
];

export interface IReportData extends FixServerObject<Components.Schemas.Report, 'id'> {}

type IGridReportDoneItem = Pick<IDoneItem, 'duration' | 'billableDuration' | 'userCrmProfileID'>;

export interface IGridReportOutput
  extends Pick<
    IReportData,
    | 'id'
    | 'fromDate'
    | 'toDate'
    | 'reportName'
    | 'bill'
    | 'lock'
    | 'maxHoursReport'
    | 'maxHoursPerUser'
  > {
  doneItems: IGridReportDoneItem[];
}
export interface IGridReport extends IGridReportOutput {}

export interface IGridReportArgs {
  search: string;
  customerID: string;
  dateRange: DateValue[];
  inProgress: boolean;
}
