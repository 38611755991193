import { Box, ButtonBase, FormControl, FormHelperText, Tooltip } from '@mui/material';
import { AppSwitch, AppSwitcherProps } from 'components/app-switch';
import React, { forwardRef, memo } from 'react';
import { TooltipError } from '../../../tooltips';
import style from './index.module.scss';

interface Props extends Omit<AppSwitcherProps, 'ref'> {
  error?: boolean;
  helperText?: React.ReactNode;
}

export const BaseDone = memo(
  forwardRef<HTMLInputElement, Props>(({ label, error, helperText, ...rest }, ref) => {
    const TooltipComponents = error ? TooltipError : Tooltip;

    return (
      <Box position={'relative'} pb={1.5}>
        <ButtonBase className={style.root}>
          <FormControl fullWidth error={error} className={style.form}>
            <AppSwitch ref={ref} labelPlacement={'start'} label={label} {...rest} />
          </FormControl>
        </ButtonBase>
        <div className={style.helperTextWrap}>
          <TooltipComponents title={helperText}>
            <FormHelperText className={style.helperText} error={error}>
              {helperText}
            </FormHelperText>
          </TooltipComponents>
        </div>
      </Box>
    );
  }),
);
