import { useCallback, useMemo, useState } from 'react';

export const useOpenValue = <T extends any = string>(init: T | null | undefined = undefined) => {
  const [openValue, setOpenValue] = useState<T | null | undefined>(init);

  const open = useCallback((value: T) => {
    setOpenValue(value);
  }, []);
  const close = useCallback(() => {
    setOpenValue(null);
  }, []);

  return useMemo(() => {
    return [
      openValue,
      {
        set: setOpenValue,
        open,
        close,
      },
    ] as const;
  }, [openValue, setOpenValue, open, close]);
};
