import { factoryCreateConfigColumn } from 'components/data-grid-config';
import {
  TemplateDate,
  TemplateDescription,
  TemplateUserName,
} from 'components/data-grid-templates';
import { useCallback, useMemo } from 'react';
import { durationToMinutes } from 'utils/app-helpers';
import { DynamicOrder } from 'utils/dynamic';
import { compareDates, compareNumbers, compareStrings } from 'utils/front-sorting';
import { ColumnControls } from '../components/column-controls';
import { GridModel, GridModelExtended, Meta } from '../models';

type ExtraProps = {
  orderBy?: (a: GridModel, b: GridModel, order: 'asc' | 'desc' | null) => number;
};

const createColumn = factoryCreateConfigColumn<GridModelExtended, ExtraProps>({});

interface Options {
  meta: Meta;
}
export const useGridConfig = (options: Options) => {
  const { meta } = options;
  const configColumns = useMemo(() => {
    return [
      createColumn({
        field: 'userCrmProfileID',

        gridProps: {
          sortable: true,
          paddingLeft: 0,
          minWidth: '14rem',
          maxWidth: '14rem',
          width: '14rem',
          whiteSpace: 'normal',
          sx: {},
          children: ({ row }) => (
            <TemplateUserName
              avatarHeight={24}
              avatarWidth={24}
              userPhoto={row.userCrmProfile.userPhoto}
              fullName={row.userCrmProfile.fullName}
            />
          ),
        },

        extraProps: {
          orderBy: (a, b, order) => {
            return compareStrings(a.userCrmProfile.fullName, b.userCrmProfile.fullName, order);
          },
        },
      }),
      createColumn({
        field: 'date',
        gridProps: {
          sortable: true,
          minWidth: '9rem',
          maxWidth: '9rem',
          width: '9rem',
          fontSize: '1.2rem',
          children: ({ row }) => <TemplateDate value={row.date} />,
        },
        extraProps: {
          orderBy: (a, b, order) => {
            return compareDates(a.date, b.date, order);
          },
        },
      }),
      createColumn({
        field: 'timeTrackingActivityID',
        gridProps: {
          sortable: false,
          minWidth: '12rem',
          maxWidth: '12rem',
          width: '12rem',
          whiteSpace: 'inherit',
          fontSize: '1.2rem',
          children: ({ row }) => row.timeTrackingActivity.title,
        },
        extraProps: {},
      }),
      ...(meta.displayDescription
        ? [
            createColumn({
              field: 'description',
              gridProps: {
                sortable: true,
                minWidth: '12rem',
                maxWidth: '100%',
                width: '50%',
                sx: { fontWeight: 'bold' },
                children: ({ row }) => <TemplateDescription html={row.description} />,
              },
              extraProps: {},
            }),
          ]
        : []),

      createColumn({
        field: 'duration',
        gridProps: {
          sortable: true,
          minWidth: '9.5rem',
          maxWidth: '9.5rem',
          width: '9.5rem',
          sx: { fontWeight: 'bold' },
        },
        extraProps: {
          orderBy: (a, b, order) => {
            return compareNumbers(
              durationToMinutes(a.duration),
              durationToMinutes(b.duration),
              order,
            );
          },
        },
      }),
      createColumn({
        field: 'id',
        includeInExcel: false,
        gridProps: {
          width: 'auto',
          sortable: false,
          title: null,
          sticky: 'right',
          children: ({ row }) => <ColumnControls row={row} />,
        },
        extraProps: {},
      }),
    ];
  }, [meta]);

  const gridColumns = useMemo(() => configColumns.map((col) => col.gridProps), [configColumns]);

  const getOrderFactory = useCallback(
    (field: keyof GridModel | null, order: DynamicOrder['order']) => {
      const column = configColumns.find((column) => column.field === field);
      const orderBy = column?.extraProps?.orderBy;
      return (a: GridModel, b: GridModel) => (orderBy ? orderBy(a, b, order) : 0);
    },
    [configColumns],
  );

  return { gridColumns, getOrderFactory };
};
