import { FixServerObject } from 'utils/types';

const API = 'Languages';

export const API_LANGUAGES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  CREATE: `${API}/Create`,
  PATCH: (data: Pick<Language, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<Language, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface Language extends FixServerObject<Components.Schemas.Language, 'id' | 'title'> {}

export interface ILanguage extends Language {}
