import { DynamicOrder } from 'utils/dynamic';
import { FixServerObject } from 'utils/types';

const API = 'ReportDoneItems';

export const API_REPORT_DONE_ITEMS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  CREATE: `${API}/Create`,
  PATCH: (data: Pick<IReportDoneItem, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<IReportDoneItem, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IGetAllReportDoneItem
  extends FixServerObject<Components.Schemas.ReportDoneItem, 'id' | 'reportID' | 'doneItemID'> {
  reportLock: Components.Schemas.Report['lock'];
}

export interface IReportDoneItem
  extends FixServerObject<Components.Schemas.ReportDoneItem, 'id' | 'reportID' | 'doneItemID'> {}

export interface DoneItemsForReport
  extends Pick<IReportDoneItem, 'id' | 'reportID' | 'doneItemID'> {
  tag: Components.Schemas.DoneItem['tag'];
  date: Components.Schemas.DoneItem['date'];
  billableDuration: Components.Schemas.DoneItem['billableDuration'];
  duration: Components.Schemas.DoneItem['duration'];
  timeTrackingID: Components.Schemas.DoneItem['timeTrackingID'];
  projectID: Components.Schemas.TimeTracking['projectID'];
  description: Components.Schemas.DoneItem['description'];
  projectName: Components.Schemas.Project['projectName'];
  userName: Components.Schemas.UserCrmProfile['fullName'];
  reportName: Components.Schemas.Report['reportName'];
  timeTrackingDate: Components.Schemas.TimeTracking['date'];
  userCrmProfileID: Components.Schemas.DoneItem['userCrmProfileID'];
  userPhoto: Components.Schemas.UserCrmProfile['userPhoto'];
  fullName: Components.Schemas.UserCrmProfile['fullName'];
  ticketActionItemID: Components.Schemas.TimeTracking['ticketActionItemID'];
}

export interface GetDoneItemsForReportInput {
  reportID: string;
  orderBy: DynamicOrder;
}
