import { DynamicOrder } from 'utils/dynamic';
import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'Projects';

export const API_PROJECTS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<IProject, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<IProject, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IProject
  extends FixServerObject<
    Components.Schemas.Project,
    | 'id'
    | 'projectTypeID'
    | 'customerID'
    | 'projectName'
    | 'projectStatusID'
    | 'isActive'
    | 'startDate'
    | 'projectNameHeb'
    | 'projectKey'
  > {}

export const schemaProject = yup.object({
  projectName: yup.string().required('rule-required').min(1, 'rule-min-length').default(''),
  projectNameHeb: yup.string().required('rule-required').min(1, 'rule-min-length').default(''),
  projectKey: yup
    .string()
    .required('rule-required')
    .min(1, 'rule-min-length')
    .max(5, 'rule-max-length')
    .default(''),
  customerID: yup.string().required('rule-required').default(''),
  projectTypeID: yup.string().required('rule-required').default(''),
  projectStatusID: yup.string().required('rule-required').default(''),
  startDate: yup.string().required('rule-required').default(new Date().toISOString()),
});

export interface IGridProjects
  extends Pick<
    IProject,
    'id' | 'projectName' | 'isActive' | 'startDate' | 'projectNameHeb' | 'projectKey'
  > {
  projectStatusTitle: string;
  projectTypeTitle: number;
  customerCompanyName: string;
}

export interface IGridProjectsArgs {
  search: string;
  projectTypeID: string;
  take: number;
  skip: number;
  orderBy: DynamicOrder;
}
