import { addDays } from 'date-fns';
import { omit } from 'lodash-es';
import * as dynamic from 'utils/dynamic';
import { mergeFilters } from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService } from 'utils/service';
import { Unset } from 'utils/types';
import {
  BaseImage,
  BaseImageExist,
  BaseImageNew,
  BaseImageOptions,
  BehaviourHandleImages,
  ServiceWithImages,
} from '../@shared/images';
import { ServiceNoteImages } from '../note-images';
import {
  API_NOTES,
  AppNote,
  DueDateManageInput,
  IBoardNote,
  IBoardNotesArgs,
  IGridNote,
  IGridNotesArgs,
  INote,
  NoteDeleteInput,
  NoteDoneInput,
  NotePatchInput,
  NotePostInput,
  NoteToggleArchiveInput,
  NOTE_CUSTOMER, NotePatchDates,
} from './models';

export * from './models';

const SELECT = dynamic.select(
  'id',
  'title',
  'description',
  'userCrmProfileID',
  'customerID',
  'ticketActionItemID',
  'ticketActionItem.itemKey',
  'isActive',
  'entryDate',
  'dueDate',
  'rowIndex',
  `noteImages.OrderBy(rank).Select(i => new {
              i.id,
              i.entryDate,
              i.description,
              i.image,
              i.rank,
            }) as images`,
);

const normalize = <N extends Pick<AppNote, 'userCrmProfileID' | 'images'>>(note: N) => {
  return {
    ...note,
    images: note.images.map((image) => ({
      ...image,
      userCrmProfileID: note.userCrmProfileID,
    })),
  };
};

type Model = INote;

const REVALIDATE_TAG = 'Notes' as const;

class Service extends DynamicService<Model> implements ServiceWithImages {
  async getImagesByID(id: string) {
    const { data } = await this.getDynamic<{ images: BaseImage[] }>(id, {
      select: dynamic.select(
        'noteImages.OrderBy(rank).Select(i => new { i.id, i.entryDate, i.description, i.image, i.rank }) as images',
      ),
    });

    return data.images;
  }
  async createImage(image: BaseImageNew, options: BaseImageOptions) {
    const payload = omit(
      {
        ...image,
        noteID: options.mainID,
      },
      'userCrmProfileID',
    );

    await ServiceNoteImages.postItem(payload);
  }
  async updateImage(image: BaseImageExist, options: BaseImageOptions) {
    const payload = omit(
      {
        ...image,
        noteID: options.mainID,
      },
      'userCrmProfileID',
    );
    await ServiceNoteImages.updateItem(payload);
  }
  async deleteImage(image: BaseImageExist, options: BaseImageOptions) {
    await ServiceNoteImages.delete({ id: image.id });
  }

  async postWithImages(input: NotePostInput) {
    const { images, ...rest } = input;

    const res = await super.post(rest);

    if (images) {
      await ServiceImages.perform([], images, { mainID: res.data.id });
    }

    return res;
  }

  async patchWithImages(input: NotePatchInput) {
    const { images, ...rest } = input;

    if (typeof images !== 'undefined') {
      const prev = await this.getImagesByID(input.id);
      await ServiceImages.perform(prev, images, { mainID: input.id });
    }

    return super.patch(rest);
  }

  async patchDates(input: NotePatchDates) {
    return super.patch(input);
  }

  async getNotesBoard(appUserID: string) {
    const params = {
      filter: dynamic
        .mergeFilters(
          dynamic.makeFilter('userCrmProfileID', appUserID, dynamic.equals),
          dynamic.makeFilter('isActive', true, dynamic.equals),
        )
        .join('&&'),
      select: dynamic.select(SELECT, 'createdDate'),
      count: true,
      orderBy: 'rowIndex',
    };

    const res = await ServiceNotes.getAllDynamic<IBoardNote, typeof params>(params);
    const value = res.data.value.map((item) => {
      return normalize(item);
    });

    return {
      data: value,
    };
  }

  async manageDueDate(input: DueDateManageInput) {
    let days = input.days || 0;
    if (days > 0) {
      return ServiceNotes.patch({
        id: input.id,
        dueDate: addDays(new Date(), days).toISOString(),
      });
    }

    return ServiceNotes.patch({
      id: input.id,
      isActive: false,
    });
  }
}

export const ServiceNotes = new Service({
  getAll: API_NOTES.GET_ALL_DYNAMIC,
  post: API_NOTES.POST,
  patch: API_NOTES.PATCH,
  delete: API_NOTES.DELETE,
});

const ServiceImages = new BehaviourHandleImages(ServiceNotes);

const filterCustomer = (value: string | NOTE_CUSTOMER) => {
  if (value === NOTE_CUSTOMER.ALL) {
    return undefined;
  }
  if (value === NOTE_CUSTOMER.WITHOUT_CUSTOMER) {
    return mergeFilters(`customerID==null`).join('&&');
  }

  return mergeFilters(`customerID=="${value}"`).join('&&');
};

export const apiNotes = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridNotes: build.query<DynamicResult<IGridNote, { count: true }>, IGridNotesArgs>({
      queryFn: async (arg) => {
        const { search, dateRange, customer, appUserID, take, skip, orderBy } = arg;

        const params = {
          filter: dynamic
            .mergeFilters(
              dynamic.makeFilter('userCrmProfileID', appUserID, dynamic.equals),
              dynamic.makeFilter('createdDate', dateRange, dynamic.dateRange),
              dynamic.makeFilter('isActive', false, dynamic.equals),
              filterCustomer(customer),
              dynamic.makeFilter(
                ['title', 'customer.companyName', 'description'],
                search,
                dynamic.decoratorIsNotNullable(dynamic.contains),
              ),
            )
            .join('&&'),
          select: dynamic.select(SELECT, 'customer.companyName as companyName'),
          count: true,
          take,
          skip,
          orderBy: dynamic.orderBy(orderBy),
        };

        try {
          const res = await ServiceNotes.getAllDynamic<IGridNote, typeof params>(params);

          const value = res.data.value.map((item) => {
            return normalize(item);
          });

          return {
            data: { value, count: res.data.count },
          };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG, id: 'grid' }],
    }),
    getBoardNotes: build.query<IBoardNote[], IBoardNotesArgs>({
      queryFn: async (arg) => {
        const { search, dateRange, customer, appUserID } = arg;

        const params = {
          filter: dynamic
            .mergeFilters(
              dynamic.makeFilter('userCrmProfileID', appUserID, dynamic.equals),
              dynamic.makeFilter('createdDate', dateRange, dynamic.dateRange),
              dynamic.makeFilter('isActive', true, dynamic.equals),
              filterCustomer(customer),
              dynamic.makeFilter(
                ['title', 'customer.companyName', 'description'],
                search,
                dynamic.decoratorIsNotNullable(dynamic.contains),
              ),
            )
            .join('&&'),
          select: SELECT,
          count: true,
        };

        try {
          const res = await ServiceNotes.getAllDynamic<IBoardNote, typeof params>(params);
          const value = res.data.value.map((item) => {
            return normalize(item);
          });
          return {
            data: value,
          };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG, id: 'grid' }],
    }),
    getNote: build.query<AppNote, string>({
      queryFn: async (input) => {
        try {
          const { data } = await ServiceNotes.getDynamic<AppNote>(input, {
            select: SELECT,
          });
          return { data: normalize(data) };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result) => (result ? [{ type: REVALIDATE_TAG, id: result.id }] : []),
    }),
    postNote: build.mutation<void, NotePostInput>({
      queryFn: async (data, { dispatch }) => {
        try {
          await ServiceNotes.postWithImages(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG, id: 'grid' }],
    }),
    patchNote: build.mutation<void, NotePatchInput>({
      queryFn: async (data, { dispatch }) => {
        try {
          await ServiceNotes.patchWithImages(data);

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG, id: 'grid' }],
    }),
    patchNoteDates: build.mutation<void, NotePatchDates>({
      queryFn: async (data, { dispatch }) => {
        try {
          await ServiceNotes.patchDates(data);

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG, id: 'grid' }],
    }),
    doneNote: build.mutation<void, NoteDoneInput>({
      queryFn: async (input) => {
        try {
          await ServiceNotes.patch({
            isActive: false,
            id: input.id,
            ticketActionItemID: input.ticketActionItemID,
          });

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, arg, meta) => [
        { type: REVALIDATE_TAG, id: 'grid' },
        { type: REVALIDATE_TAG, id: arg.id },
      ],
    }),
    deleteNote: build.mutation<void, NoteDeleteInput>({
      queryFn: async (data) => {
        try {
          await ServiceNotes.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG, id: 'grid' }],
    }),
    toggleArchiveNote: build.mutation<void, NoteToggleArchiveInput>({
      queryFn: async (input) => {
        try {
          await ServiceNotes.patch({ id: input.id, isActive: !input.value });
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG, id: 'grid' }],
    }),
    manageNotesDueDate: build.mutation<void, { id: string; days: Unset<number> }[]>({
      queryFn: async (input) => {
        try {
          const promises = input.map((row) => {
            return ServiceNotes.manageDueDate(row);
          });

          await Promise.all(promises);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: REVALIDATE_TAG, id: 'grid' },
        ...arg.map((row) => ({ type: REVALIDATE_TAG, id: row.id })),
      ],
    }),
  }),
});
