import { Avatar, Box, Stack, Typography } from '@mui/material';
import { useCurrentUser, useOpen, useTranslate } from 'hooks';
import React, { useCallback, useRef } from 'react';
import { BaseCommentInput, FormData } from '../comment-input';
import style from './index.module.scss';

interface Props {
  init: Partial<FormData>;
  disabled: boolean;
  onSubmit: (formData: FormData) => Promise<boolean>;
}

export const BaseCommentNew: React.FC<Props> = ({ onSubmit, disabled, init }) => {
  const { userPhoto, name } = useCurrentUser();
  const { tp } = useTranslate();
  const stateEdit = useOpen();

  const onSubmitWrap = useCallback(
    async (formData: FormData) => {
      const res = await onSubmit(formData);

      if (res) {
        stateEdit.onClose();
      }
    },
    [onSubmit, stateEdit],
  );

  const ref = useRef<HTMLDivElement>();
  const onClick = useCallback(() => {
    stateEdit.onOpen();
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
    }, 500);
  }, [stateEdit]);

  return (
    <Stack width={'100%'} direction={'row'} alignItems={'start'} spacing={0.8}>
      <Stack alignItems={'center'} pt={1.4} spacing={2}>
        <Avatar sx={{ width: 24, height: 24 }} title={name} src={userPhoto} />
      </Stack>
      <Box ref={ref} flexGrow={1}>
        {stateEdit.isOpen ? (
          <BaseCommentInput
            init={init}
            onCancel={stateEdit.onClose}
            disabled={disabled}
            onSubmit={onSubmitWrap}
          />
        ) : (
          <Typography component={'div'} className={style.input} onClick={onClick}>
            {tp('add-comment-placeholder')}
          </Typography>
        )}
      </Box>
    </Stack>
  );
};
