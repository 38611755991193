import { AppState } from 'store';
import { selectAppUserID } from 'store/auth';
import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicService } from 'utils/service';
import { apiTicketStatuses } from '../ticket-statuses';
import {
  API_TICKETS,
  ITicket,
  ITicketDelete,
  ITicketFull,
  ITicketPatch,
  ITicketPost,
} from './models';

type Model = ITicket;

const REVALIDATE_TAG = 'Tickets' as const;

class Service extends DynamicService<Model> {
  async postTicket(data: ITicketPost) {
    return super.post(data);
  }

  async patchTicket(data: ITicketPatch) {
    return super.patch(data);
  }
}

export const ServiceTickets = new Service({
  getAll: API_TICKETS.GET_ALL_DYNAMIC,
  post: API_TICKETS.POST,
  patch: API_TICKETS.PATCH,
  delete: API_TICKETS.DELETE,
});

export const apiTickets = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getTicketsAll: build.query<Array<ITicketFull>, void>({
      queryFn: async (_, { getState }) => {
        try {
          const appUserID = selectAppUserID(getState() as AppState);
          const { data } = await ServiceTickets.getAllDynamic<ITicketFull>({
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('isActive', true, dynamic.equals),
                dynamic
                  .mergeFilters(
                    dynamic.makeFilter('isPrivate', false, dynamic.equals),
                    dynamic.makeFilter('ownerUserCrmProfileID', appUserID, dynamic.equals),
                  )
                  .join('||'),
              )
              .join('&&'),
            select: dynamic.select(
              'id',
              'title',
              'itemKey',
              'done',
              'isPrivate',
              'mustTrackTime',

              'ticketTypeID',
              'ticketStatusID',
              'priorityID',

              'ownerUserCrmProfileID',
              'defaultReporterUserCrmProfileID',

              'projectID',
            ),

            orderBy: dynamic.orderBy('rowIndex', 'asc'),
          });
          return { data: data.value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getTicketsByCustomer: build.query<Array<ITicketFull>, string>({
      queryFn: async (customerID, { getState }) => {
        try {
          const appUserID = selectAppUserID(getState() as AppState);

          const { data } = await ServiceTickets.getAllDynamic<ITicketFull>({
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('project.customerID', customerID, dynamic.equals),
                dynamic.makeFilter('isActive', true, dynamic.equals),
                dynamic
                  .mergeFilters(
                    dynamic.makeFilter('isPrivate', false, dynamic.equals),
                    dynamic.makeFilter('ownerUserCrmProfileID', appUserID, dynamic.equals),
                  )
                  .join('||'),
              )
              .join('&&'),
            select: dynamic.select(
              'id',
              'title',
              'itemKey',
              'done',
              'isPrivate',
              'mustTrackTime',

              'ticketTypeID',
              'ticketStatusID',
              'priorityID',

              'ownerUserCrmProfileID',
              'defaultReporterUserCrmProfileID',

              'projectID',
            ),

            orderBy: dynamic.orderBy('rowIndex', 'asc'),
          });
          return { data: data.value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getTicket: build.query<Model, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServiceTickets.getDynamic(id);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    getTicketByKey: build.query<Model, string>({
      queryFn: async (key) => {
        try {
          const response = await ServiceTickets.getAllDynamic({
            filter: dynamic
              .mergeFilters(dynamic.makeFilter('itemKey', key, dynamic.equals))
              .join('&&'),
            take: 1,
          });

          const { data } = ServiceTickets.transformDynamicResponseToItem(response);
          return { data: data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    postTicket: build.mutation<void, ITicketPost>({
      queryFn: async (data) => {
        try {
          await ServiceTickets.postTicket(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchTicket: build.mutation<void, ITicketPatch>({
      queryFn: async (data, { dispatch }) => {
        try {
          let payload = { ...data };

          if (data.ticketStatusID) {
            const requestStatuses = dispatch(
              apiTicketStatuses.endpoints.getTicketStatuses.initiate(),
            );
            requestStatuses.unsubscribe();
            const result = await requestStatuses.unwrap();

            const status = result.find((status) => status.id === data.ticketStatusID);

            payload.done = !!status?.isTicketDone;
          }

          await ServiceTickets.patchTicket(payload);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: REVALIDATE_TAG, id }],
    }),
    deleteTicket: build.mutation<void, ITicketDelete>({
      queryFn: async (data) => {
        try {
          await ServiceTickets.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: REVALIDATE_TAG }],
    }),
  }),
});
