import { DynamicOrder } from 'utils/dynamic';
import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'ProjectWikiSubjects';

export const API_PROJECT_WIKI_SUBJECTS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<IProjectWikiSubject, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<IProjectWikiSubject, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IProjectWikiSubject
  extends FixServerObject<
    Components.Schemas.ProjectWikiSubject,
    'id' | 'isActive' | 'title' | 'projectID'
  > {}

export const schemaProjectWikiSubject = yup.object({
  id: yup.string().notRequired().default(undefined),
  title: yup.string().required('rule-required').default(''),
  isActive: yup.boolean().default(true),
  projectID: yup.string().required('rule-required').default(''),
  rank: yup.number().notRequired().default(0),
});

export interface IGridProjectWikiSubjectsItemsArgs {
  projectID: string;
  orderBy: DynamicOrder;
}
