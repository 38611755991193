import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppNotificationLatest } from 'services/app-notifications';
import { actionNotificationsNotDoneFetch } from './actions';
import { PREFIX } from './helpers';

export interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;
  isOpen: boolean;

  data: AppNotificationLatest[];
  count: number;
}

const initState = (): State => {
  return {
    error: null,
    isLoading: false,
    isInit: false,
    isOpen: false,

    data: [],
    count: 0,
  };
};

const slice = createSlice({
  name: PREFIX,
  initialState: initState(),
  reducers: {
    toggle(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionNotificationsNotDoneFetch.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(actionNotificationsNotDoneFetch.fulfilled, (state, action) => {
        const { payload } = action;

        state.data = payload.value;
        state.count = payload.count;
        state.isLoading = false;
        state.isInit = true;
      })
      .addCase(actionNotificationsNotDoneFetch.rejected, (state, action) => {
        const { error } = action;
        state.isLoading = false;
        state.error = error;
        state.isInit = true;
      });
  },
});

export const actionsNotificationsNotDone = slice.actions;
export const reducerNotificationsNotDone = slice.reducer;
