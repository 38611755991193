import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ButtonBase, Typography } from '@mui/material';
import clsx from 'clsx';
import { Item, MenuButton } from 'components/menu-button';
import { useMemo } from 'react';
import { useOpen } from '../../hooks';
import style from './index.module.scss';

interface Props<T extends { id: string; title: string }> {
  value: T['id'];
  onChange?: (value: T['id'], option: T) => void;
  disabled?: boolean;
  options: T[];
}

export const InlineOrder = <T extends { id: string; title: string }>({
  value,
  onChange,
  disabled,
  options,
}: Props<T>) => {
  const menuItems = useMemo<Array<Item>>(() => {
    return options.map((option) => ({
      id: option.id,
      title: (
        <Typography component={'div'} maxWidth={'20rem'}>
          {option.title}
        </Typography>
      ),
      selected: option.id === value,
      onClick: () => onChange && onChange(option.id, option),
    }));
  }, [options, value, onChange]);

  const selectedOption = useMemo(() => {
    return options.find((option) => option.id === value);
  }, [value, options]);

  const isDisabled = menuItems.length === 0 || disabled || !onChange;

  const stateOpen = useOpen();

  return (
    <MenuButton
      isOpen={stateOpen.isOpen}
      onOpen={stateOpen.onOpen}
      onClose={stateOpen.onClose}
      items={menuItems}
      MenuItemProps={{
        sx: {
          paddingLeft: '0.8rem',
        },
      }}
    >
      <ButtonBase className={clsx(style.root)} disabled={isDisabled}>
        {selectedOption?.title}
        <div className={clsx(style.icon, stateOpen.isOpen && style.iconOpen)}>
          <ArrowDropDownIcon />
        </div>
      </ButtonBase>
    </MenuButton>
  );
};
