import { ButtonBase, Chip, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { TemplateDate, TemplateUserName } from 'components/data-grid-templates';
import { DialogNotificationsView } from 'components/notifications';
import { useAppSelector, useEffectNotifyError, useOpen, useTranslate } from 'hooks';
import React, { useCallback } from 'react';
import {
  apiAppNotifications,
  AppNotificationLatest,
  getNotificationActionType,
  getNotificationIcon,
  isNotificationView,
} from 'services/app-notifications';
import { selectSourceCrmUsersAllMap } from 'services/user-crm-profiles';
import { composeFunctions } from 'utils/other';
import { NotificationCardControlDone } from '../notification-card-control-done';
import style from './index.module.scss';

const useDoneMutation = apiAppNotifications.useDoneAppNotificationMutation;

interface Props {
  row: AppNotificationLatest;
}
export const NotificationCard: React.FC<Props> = ({ row }) => {
  const { tp } = useTranslate();
  const type = getNotificationActionType(row.notification);
  const Icon = getNotificationIcon(type);

  const reporterID = row.notification.reporterUserCrmProfileID;

  const mapUsers = useAppSelector(selectSourceCrmUsersAllMap);

  const reporter = reporterID ? mapUsers[reporterID] : null;
  const isViewable = isNotificationView(type);
  const stateOpen = useOpen();

  const [doneItem, resultDoneItem] = useDoneMutation();
  useEffectNotifyError(resultDoneItem.error);

  const onDone = useCallback(() => {
    doneItem(String(row.id));
  }, [doneItem, row.id]);

  return (
    <>
      <ButtonBase
        component={'div'}
        className={clsx({
          [style.root]: true,
          [style.rootUrgent]: row.urgent,
          [style.rootClickable]: isViewable,
        })}
        disableRipple={!isViewable}
        onClick={isViewable ? stateOpen.onOpen : undefined}
      >
        <div className={style.icon}>
          <Icon
            color={'primary'}
            fontSize={'inherit'}
            sx={{ fontSize: 20 }}
            className={style.icon}
          />
        </div>
        <div className={style.header}>
          <Stack direction={'row'} alignItems={'center'} spacing={0.8}>
            <Stack direction={'row'} spacing={0.8} flexGrow={1} alignItems={'center'}>
              <TemplateUserName
                avatarHeight={24}
                avatarWidth={24}
                userPhoto={reporter?.userPhoto}
                fullName={reporter?.fullName}
                TypographyProps={{
                  fontWeight: 'bold',
                }}
              />
              {row.urgent && (
                <Chip label={tp('urgent')} size={'small'} color={'error'} className={style.chip} />
              )}
            </Stack>
            <TemplateDate
              value={row.notification.entryDate}
              format={'dd.MM.yyyy, HH:mm'}
              sx={{
                fontSize: 12,
              }}
            />
          </Stack>
        </div>
        <div className={style.bottom}>
          <Typography
            component={'div'}
            className={style.description}
            title={row.notification.message ?? undefined}
          >
            {row.notification.message}
          </Typography>
          <NotificationCardControlDone row={row} disabled={resultDoneItem.isLoading} />
        </div>
      </ButtonBase>
      {stateOpen.isOpen && (
        <DialogNotificationsView row={row} onClose={composeFunctions(stateOpen.onClose, onDone)} />
      )}
    </>
  );
};
