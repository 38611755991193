import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'lodash-es';
import { apiUserCrmProfiles } from './api';

const selectSourceCrmUsersResult = apiUserCrmProfiles.endpoints.getUserCrmProfileSource.select();
export const selectSourceCrmUsersAll = createSelector(selectSourceCrmUsersResult, (res) => {
  return res.data || [];
});
export const selectSourceCrmUsersAllMap = createSelector(selectSourceCrmUsersAll, (users) => {
  return keyBy(users, 'id');
});
