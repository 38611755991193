import { BaseDialogActionAddToReport, BaseReportList } from 'components/tickets';
import { useEffectNotifyError } from 'hooks';
import React, { useCallback, useMemo } from 'react';
import { apiDoneItems, schemaDoneItem } from 'services/done-items';
import { apiReports, ServiceReports } from 'services/reports';
import { useTicketActionItemInformation } from '../../../../hooks/use-ticket-information';
import { useCheckDoneFetchItem } from '../../hooks/use-check-done-fetch-item';
import { ActionComponentProps } from '../../models';

const useCreateDoneItemsMutation = apiDoneItems.useCreateDoneItemsWithReportMutation;
const useFetchReportsQuery = apiReports.useGetAllReportsQuery;

type FormModel = BaseReportList;

interface Props extends ActionComponentProps {}
export const DialogActionAddToReport: React.FC<Props> = ({
  onClose,
  ticketActionItemID,
  selectedRows,
  meta,
}) => {
  const onRefreshPage = useCheckDoneFetchItem(ticketActionItemID);
  const resultGetTicket = useTicketActionItemInformation(ticketActionItemID);
  const { customer, ticketActionItem, project } = resultGetTicket;

  const customerID = customer?.id || '';
  const resultGetReports = useFetchReportsQuery({ customerID }, { skip: !customerID });

  const reportOptions = useMemo(() => {
    const list = resultGetReports.data || [];
    return list.filter((report) => !ServiceReports.isReportLocked(report));
  }, [resultGetReports.data]);

  const defDescription =
    meta.activityReportDescription || ticketActionItem?.activityReportDescription;

  const [createDoneItems, resultCreateDoneItems] = useCreateDoneItemsMutation();
  useEffectNotifyError(resultCreateDoneItems.error);

  const onSubmit = useCallback(
    async (formData: FormModel) => {
      const { description } = formData.ticketActionItems[0];
      const items = selectedRows.map((row) => {
        const data = schemaDoneItem.cast({
          timeTrackingID: row.id,
          userCrmProfileID: row.userCrmProfileID,
          duration: row.duration,
          billableDuration: row.billableDuration || row.duration,
          description: description ?? '',
          date: row.date ?? new Date().toISOString(),
        });

        return { ...data, description: data.description ?? '' };
      });

      await createDoneItems({ reportID: formData.reportID, items });

      onRefreshPage();
      onClose();
    },
    [selectedRows, createDoneItems, onClose, onRefreshPage],
  );

  const isLoading =
    resultGetTicket.isFetching || resultGetReports.isFetching || resultCreateDoneItems.isLoading;

  const initValues = useMemo(() => {
    const ticketActionItems = ticketActionItemID
      ? [
          {
            id: ticketActionItemID,
            title: ticketActionItem?.title ?? '',
            description: defDescription ?? '',
          },
        ]
      : [];

    return {
      description: defDescription ?? '',
      ticketActionItems: ticketActionItems,
    };
  }, [defDescription, ticketActionItem, ticketActionItemID]);

  const reportMeta = useMemo(() => {
    return { customerName: customer?.title, projectName: project?.title };
  }, [customer, project]);

  return (
    <BaseDialogActionAddToReport
      isInit={resultGetReports.isSuccess}
      reports={reportOptions}
      isLoading={isLoading}
      onClose={onClose}
      initialValues={initValues}
      onApply={onSubmit}
      customerID={customerID}
      meta={reportMeta}
    />
  );
};
