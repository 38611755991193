import deepmerge from 'deepmerge';
import { ForwardedRef, forwardRef, memo, useMemo } from 'react';
import { fileURLToFile } from 'utils/file-uploader';
import { HtmlEditor, HtmlEditorProps, InitOptions } from '../html-editor';

export const INIT: InitOptions = {};

interface Props extends HtmlEditorProps {
  onInsertImages: (files: File[]) => void;
}
const Component = (props: Props, ref: ForwardedRef<HTMLInputElement>) => {
  const { editorProps: _editorProps, onInsertImages } = props;
  const editorProps = useMemo(
    () =>
      deepmerge(_editorProps || {}, {
        init: {
          ...INIT,
          paste_preprocess: (editor, args) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(args.content, 'text/html');
            const images = Array.from(doc.images);

            images.forEach((image) => {
              image.remove();
            });

            let SRCs = images
              .map((img) => {
                return img.src;
              })
              .filter(Boolean);

            Promise.all(SRCs.map(fileURLToFile)).then((files) => {
              onInsertImages(files);
            });

            args.content = doc.documentElement.innerHTML;
          },
        },
      }),
    [_editorProps, onInsertImages],
  );

  return <HtmlEditor ref={ref} {...props} editorProps={editorProps} />;
};

export const HtmlEditorLight = memo(forwardRef(Component)) as typeof Component;
