import * as yup from 'yup';
import { InferType } from 'yup';

const API = 'NotificationUserProfiles';

export const API_NOTIFICATION_USER_PROFILES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<INotificationUserProfile, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<INotificationUserProfile, 'id'>) => `${API}/Delete/${data.id}`,
};

export const schemaNotificationUserProfile = yup.object().shape({
  id: yup.string().required('rule-required').default(undefined),
  userCrmProfileID: yup.string().required('rule-required').default(''),
  notificationID: yup.string().required('rule-required').default(''),
  done: yup.boolean().default(false),
  urgent: yup.boolean().default(false),
});

export type INotificationUserProfile = InferType<typeof schemaNotificationUserProfile>;

export const schemaNotificationUserProfilePost = schemaNotificationUserProfile.pick([
  'userCrmProfileID',
  'notificationID',
  'urgent',
]);

export type NotificationUserProfilePostInput = InferType<typeof schemaNotificationUserProfilePost>;

export const schemaNotificationUserProfilePatch = schemaNotificationUserProfilePost.concat(
  schemaNotificationUserProfile.pick(['id', 'done']),
);

export type NotificationUserProfilePatchInput = InferType<
  typeof schemaNotificationUserProfilePatch
>;

export type NotificationUserProfileDeleteInput = Pick<INotificationUserProfile, 'id'>;
