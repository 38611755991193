import { useCallback, useEffect, useRef, useState } from 'react';
import { DraggableEventHandler } from 'react-draggable';
import { DraggableCalcInitPosition } from './models';

interface Options {
  calcInitPosition?: DraggableCalcInitPosition;
}
export const useDraggablePosition = (options: Options) => {
  const { calcInitPosition } = options;
  const paperRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({ x: -100000, y: -10000 });
  const onStopDrag = useCallback<DraggableEventHandler>((e, data) => {
    setPosition({ x: data.x, y: data.y });
  }, []);

  useEffect(() => {
    requestAnimationFrame(() => {
      if (paperRef.current && calcInitPosition) {
        setPosition(calcInitPosition(paperRef.current));
      }
    });
  }, [paperRef, calcInitPosition]);

  return [position, setPosition, { onStopDrag, paperRef }] as const;
};
