import { Box, BoxProps, Skeleton, Stack } from '@mui/material';
import clsx from 'clsx';
import { NativeScroll } from 'components/native-scroll';
import React from 'react';
import style from './index.module.scss';

interface Props extends BoxProps {
  className?: string;
}

export const BaseSkeleton: React.FC<Props> = ({ className, ...rest }) => {
  return (
    <Box className={clsx(style.root, className)} {...rest}>
      <NativeScroll>
        <Stack spacing={1} direction={'row'} justifyContent={'space-between'} mb={1}>
          <Skeleton variant={'rectangular'} height={20} width={'33%'} />
          <Skeleton variant={'rectangular'} height={20} width={100} />
        </Stack>
        <Skeleton
          variant={'rectangular'}
          height={30}
          width={'50%'}
          sx={{
            marginBottom: 2,
          }}
        />
        <Skeleton
          variant={'rectangular'}
          height={100}
          sx={{
            marginBottom: 2,
          }}
        />

        <Skeleton
          variant={'rectangular'}
          height={200}
          sx={{
            marginBottom: 2,
          }}
        />
        <Skeleton
          variant={'rectangular'}
          height={20}
          width={160}
          sx={{
            marginBottom: 1,
          }}
        />
        <Stack direction={'row'} spacing={1} mb={2}>
          <Skeleton variant={'rectangular'} height={72} width={96} />
          <Skeleton variant={'rectangular'} height={72} width={96} />
          <Skeleton variant={'rectangular'} height={72} width={96} />
        </Stack>

        <Skeleton
          variant={'rectangular'}
          height={20}
          width={220}
          sx={{
            marginBottom: 1,
          }}
        />
        <Stack spacing={0.5} mb={1}>
          <Skeleton variant={'rectangular'} height={10} width={'50%'} />
          <Skeleton variant={'rectangular'} height={10} width={'45%'} />
          <Skeleton variant={'rectangular'} height={10} width={'20%'} />
        </Stack>
        <Stack spacing={0.5}>
          <Skeleton variant={'rectangular'} height={10} width={'50%'} />
          <Skeleton variant={'rectangular'} height={10} width={'45%'} />
          <Skeleton variant={'rectangular'} height={10} width={'20%'} />
        </Stack>
      </NativeScroll>
    </Box>
  );
};
