import { MapSelectedResult } from 'hooks/use-map-selected';
import React from 'react';
import { GridModel } from '../../models';

type DataProviderContext = MapSelectedResult<GridModel> & {};

export const SelectedContext = React.createContext<null | DataProviderContext>(null);

interface Props {
  children: React.ReactNode;
}
export const SelectedProvider: React.FC<Props & MapSelectedResult<GridModel>> = ({
  children,
  ...rest
}) => {
  return <SelectedContext.Provider value={rest}>{children}</SelectedContext.Provider>;
};
