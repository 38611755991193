import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { ButtonProps, Stack, Typography } from '@mui/material';
import { AppButtonBase } from 'components/buttons/app-button-base';
import { useTranslate } from 'hooks';
import React from 'react';

interface Props extends ButtonProps {
  total: number;
}
export const BaseCommentsButton: React.FC<Props> = ({ total, ...rest }) => {
  const { tp } = useTranslate();
  return (
    <AppButtonBase {...rest}>
      <Stack direction={'row'} spacing={0.4}>
        <ChatBubbleOutlineIcon fontSize={'small'} color={'silver'} />
        <Typography variant={'caption'} component={'span'}>
          <Typography component={'strong'} fontWeight={500}>
            {total}
          </Typography>{' '}
          {tp('comments')}
        </Typography>
      </Stack>
    </AppButtonBase>
  );
};
