import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { IconButton, Modal, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useCallback, useRef, useState } from 'react';
import Slider from 'react-slick';
import { useOpen } from '../../hooks';
import { DialogImagePreview } from '../dialog-image-preview';
import style from './index.module.scss';

interface ArrowProps {
  onClick?: () => void;
}

const ArrowNext: React.FC<ArrowProps> = (props) => {
  return (
    <IconButton className={clsx(style.arrow, style.arrowNext)} onClick={props.onClick}>
      <ArrowBackIosNewRoundedIcon />
    </IconButton>
  );
};
const ArrowPrev: React.FC<ArrowProps> = (props) => {
  return (
    <IconButton className={clsx(style.arrow, style.arrowPrev)} onClick={props.onClick}>
      <ArrowBackIosNewRoundedIcon />
    </IconButton>
  );
};

interface SlideProps {
  title: string;
  picture: string;
}
const Slide: React.FC<SlideProps> = ({ title, picture }) => {
  const { isOpen, onOpen, onClose } = useOpen();
  return (
    <>
      <div className={style.slide}>
        <Typography color={'white'} variant={'subtitle1'} noWrap textAlign={'center'}>
          {title}
        </Typography>
        <img className={style.img} onClick={onOpen} src={picture} alt={''} />
      </div>
      {isOpen && <DialogImagePreview title={title} picture={picture} onClose={onClose} />}
    </>
  );
};

interface IImage {
  title: string;
  picture: string;
}

interface Props {
  initialIndex?: number;
  images: IImage[];
  onClose: () => void;
}

export const DialogImagesPreview: React.FC<Props> = ({ initialIndex = 0, images, onClose }) => {
  const customPaging = useCallback(
    (index: number) => {
      return (
        <span className={style.paging}>
          <img src={images[index].picture} alt={''} />
        </span>
      );
    },
    [images],
  );

  const refDotsTrack = useRef<HTMLUListElement>(null);

  const [indicatorPosition, setIndicatorPosition] = useState([0, 0]);

  const calcIndicatorPosition = useCallback(() => {
    setTimeout(() => {
      const element = refDotsTrack.current;
      if (!element) {
        return;
      }

      const activeElement = element.getElementsByClassName('slick-active')[0] as HTMLLIElement;

      if (!activeElement) {
        return;
      }

      setIndicatorPosition([activeElement.offsetLeft, activeElement.offsetTop]);
    }, 300);
  }, []);

  return (
    <Modal
      open={true}
      onClose={onClose}
      className={style.root}
      slotProps={{
        backdrop: {
          className: style.backdrop,
        },
      }}
    >
      <div
        style={{
          outline: 'none',
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            color: '#fff',
            position: 'absolute',
            top: 15,
            right: 20,
            zIndex: 100,
          }}
          className={style.iconButton}
        >
          <CloseRoundedIcon />
        </IconButton>
        <div className={style.container}>
          <Slider
            initialSlide={initialIndex}
            customPaging={customPaging}
            dots
            infinite
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            dotsClass={clsx('slick-dots', style.dots)}
            afterChange={calcIndicatorPosition}
            onInit={calcIndicatorPosition}
            appendDots={(dots) => (
              <ul ref={refDotsTrack}>
                {dots}
                <div
                  className={style.indicator}
                  style={{
                    transform: `translate(${indicatorPosition[0]}px,${indicatorPosition[1]}px) scale(1.2, 1.4)`,
                  }}
                />
              </ul>
            )}
            nextArrow={<ArrowNext />}
            prevArrow={<ArrowPrev />}
          >
            {images.map((image, i) => (
              <Slide key={i} title={image.title} picture={image.picture} />
            ))}
          </Slider>
        </div>
      </div>
    </Modal>
  );
};
