import {
  Action,
  combineReducers,
  configureStore,
  createListenerMiddleware,
  ThunkAction,
} from '@reduxjs/toolkit';
import { PersistMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { all } from 'typed-redux-saga';
import { apiRtk } from 'utils/service';
import packages from '../../package.json';
import { reducerAlerts } from './alerts';
import { reducerAccount, sagasAccounts } from './auth';
import { reducerCheckDoneAll, sagaCheckDoneAll } from './check-done-all';
import { reducerCheckDoneCompleted, sagaCheckDoneCompleted } from './check-done-completed';
import {
  reducerCheckDoneNotCompleted,
  sagaCheckDoneNotCompleted,
} from './check-done-not-completed';
import { reducerCheckDoneTickets, sagaCheckDoneTickets } from './check-done-tickets';
import { reducerCheckDoneTrash, sagaCheckDoneTrash } from './check-done-trash';
import { reducerCheckDoneUnAssign, sagaCheckDoneUnAssign } from './check-done-un-assign';
import { reducerCrmPermissions } from './crm-permissions';
import { reducerCrmUsers, sagasCrmUsers } from './crm-users';
import { reducerCustomers } from './customers';
import { reducerDashboardReports } from './dashboard-reports';
import { reducerExpenses } from './expenses';
import { reducerLabels } from './labels';
import { reducerNotes, sagasNotes } from './notes';
import { reducerNotesLayout } from './notes-layout';
import { sagasNotificationsAuto } from './notifications-auto';
import { reducerNotificationsLayout } from './notifications-layout';
import { reducerNotificationsNotDone, sagasNotificationsNotDone } from './notifications-not-done';
import { reducerProjects, sagasProjects } from './projects';
import { reducerReportItem, sagasReportItem } from './report-item';
import { reducerReports, sagasReports } from './reports';
import { reducerTimeTracking, sagasTimeTracking } from './time-tracking';

const sagaMiddleware = createSagaMiddleware();
const listenerMiddleware = createListenerMiddleware();

const VERSION = parseInt(packages.version.replace(/\./gi, ''));

const migrateStore: PersistMigrate = (state) => {
  if (VERSION === state?._persist.version) {
    return Promise.resolve(state);
  } else {
    return Promise.resolve(undefined);
  }
};

const rootReducer = combineReducers({
  checkDoneNotCompleted: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'checkDoneNotCompleted',
      storage,
      whitelist: ['filters', 'orders', 'pinnedMap'],
    },
    reducerCheckDoneNotCompleted,
  ),
  checkDoneAll: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'checkDoneAll',
      storage,
      whitelist: ['filters'],
    },
    reducerCheckDoneAll,
  ),
  checkDoneUnAssign: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'checkDoneUnAssign',
      storage,
      whitelist: ['filters'],
    },
    reducerCheckDoneUnAssign,
  ),
  checkDoneCompleted: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'checkDoneCompleted',
      storage,
      whitelist: ['filters'],
    },
    reducerCheckDoneCompleted,
  ),
  checkDoneTrash: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'checkDoneTrash',
      storage,
      whitelist: ['filters'],
    },
    reducerCheckDoneTrash,
  ),
  checkDoneTickets: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'checkDoneTickets',
      storage,
      whitelist: ['filters'],
    },
    reducerCheckDoneTickets,
  ),
  account: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'account',
      storage,
      whitelist: ['languageID'],
    },
    reducerAccount,
  ),
  customers: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'customers',
      storage,
      whitelist: ['filters'],
    },
    reducerCustomers,
  ),
  labels: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'labels',
      storage,
      whitelist: ['filters'],
    },
    reducerLabels,
  ),
  projects: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'projects',
      storage,
      whitelist: ['filters'],
    },
    reducerProjects,
  ),
  alerts: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'alerts',
      storage,
      whitelist: ['filters'],
    },
    reducerAlerts,
  ),
  crmUsers: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'crmUsers',
      storage,
      whitelist: ['filters'],
    },
    reducerCrmUsers,
  ),
  timeTracking: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'timeTracking',
      storage,
      whitelist: ['filters', 'view'],
    },
    reducerTimeTracking,
  ),
  dashboardReports: reducerDashboardReports,
  expenses: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'expenses',
      storage,
      whitelist: ['duplicatedExpenseItemData'],
    },
    reducerExpenses,
  ),
  crmPermissions: reducerCrmPermissions,
  reportItem: reducerReportItem,
  reports: reducerReports,
  notificationsNotDone: reducerNotificationsNotDone,
  notesLayout: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'notesLayout',
      storage,
      whitelist: ['filters', 'isExpanded'],
    },
    reducerNotesLayout,
  ),
  notificationsLayout: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'notificationsLayout',
      storage,
      whitelist: ['filters', 'isExpanded'],
    },
    reducerNotificationsLayout,
  ),
  notes: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'notes',
      storage,
      whitelist: ['filters', 'orders'],
    },
    reducerNotes,
  ),

  [apiRtk.reducerPath]: apiRtk.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(listenerMiddleware.middleware)
      .concat(sagaMiddleware)
      .concat(apiRtk.middleware),
  devTools: process.env.NODE_ENV === 'development',
});
export const persistor = persistStore(store);

function* rootSaga() {
  yield all([
    ...sagasAccounts,
    ...sagasCrmUsers,
    ...sagaCheckDoneTickets,
    ...sagaCheckDoneNotCompleted,
    ...sagaCheckDoneAll,
    ...sagaCheckDoneUnAssign,
    ...sagaCheckDoneCompleted,
    ...sagaCheckDoneTrash,
    ...sagasTimeTracking,
    ...sagasProjects,
    ...sagasReports,
    ...sagasReportItem,
    ...sagasNotes,
    ...sagasNotificationsNotDone,
    ...sagasNotificationsAuto,
  ]);
}

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;
export type AppAsyncThunkConfig = {
  state: AppState;
  dispatch: AppDispatch;
  serializedErrorType: Error;
};
