import { Avatar, Stack, StackProps, Typography } from '@mui/material';
import React from 'react';
import variables from 'styles/config.scss';
import { getShortFullName } from 'utils/app-helpers';

interface Props extends StackProps {
  userPhoto?: string;
  fullName?: string;
}

export const UserPreview: React.FC<Props> = ({ userPhoto, fullName, ...rest }) => {
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={0.8} {...rest}>
      <Avatar
        sx={{ width: 24, height: 24, border: `1px solid ${variables.colorGrey}` }}
        src={userPhoto}
      />
      <Typography variant={'caption'} component={'div'}>
        {getShortFullName(fullName)}
      </Typography>
    </Stack>
  );
};
