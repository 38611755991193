import { useMemo } from 'react';
import { useTranslate } from './use-translate';

export const useSelectOptionsStrategyTranslateLabelKey = <Option extends { labelKey: string }>(
  options: Option[],
) => {
  const { t } = useTranslate();
  return useMemo(() => {
    return options.map((item) => {
      return {
        ...item,
        title: t(item.labelKey),
      };
    });
  }, [options, t]);
};
