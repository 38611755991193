import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogProps } from '@mui/material';
import { ButtonCancel } from 'components/buttons';
import { useTranslate } from 'hooks';
import { DialogHeader } from '../dialog-header';

interface Props extends Omit<Partial<DialogProps>, 'title'> {
  title?: React.ReactNode;
  children: React.ReactNode;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  disabled?: boolean;
}

export const DialogConfirm: React.FC<Props> = ({
  children,
  onClose,
  onCancel,
  onConfirm,
  disabled,
  title,
  ...rest
}) => {
  const { tp } = useTranslate();

  return (
    <Dialog open={true} {...rest} maxWidth={'xs'} fullWidth>
      {title && <DialogHeader title={title} onClose={onClose} />}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <ButtonCancel onClick={onCancel} />
        <Button disabled={disabled} variant={'contained'} color={'primary'} onClick={onConfirm}>
          {tp('agree')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
