import { useContext } from 'react';
import { DataGridTasksContext, DataGridTasksContextValue } from '../components';
import { DataGridDefaultTasks, DataGridTask } from '../models';

export const useDataGridTasks = <M, Task extends DataGridTask<any> = DataGridDefaultTasks<M>>() => {
  const context = useContext(DataGridTasksContext);
  if (!context) {
    throw Error('You should use DataGridTasksContext');
  }
  return context as DataGridTasksContextValue<Task>;
};
