export const isHasScrollLeft = (el: Pick<HTMLElement, 'scrollLeft'>) => {
  return el.scrollLeft > 0;
};
export const isHasScrollRight = (
  el: Pick<HTMLElement, 'scrollLeft' | 'scrollWidth' | 'clientWidth'>,
) => {
  return Math.ceil(el.scrollLeft) < el.scrollWidth - el.clientWidth;
};

export const isHasScrollX = (
  el: Pick<HTMLElement, 'scrollLeft' | 'scrollWidth' | 'clientWidth'>,
) => {
  return [isHasScrollLeft, isHasScrollRight].some((cb) => cb(el));
};

export const isHasScrollTop = (el: Pick<HTMLElement, 'scrollTop'>) => {
  return el.scrollTop > 0;
};

export const isHasScrollBottom = (
  el: Pick<HTMLElement, 'scrollTop' | 'scrollHeight' | 'clientHeight'>,
) => {
  return Math.ceil(el.scrollTop) < el.scrollHeight - el.clientHeight;
};

export const isHasScrollY = (
  el: Pick<HTMLElement, 'scrollTop' | 'scrollHeight' | 'clientHeight'>,
) => {
  return [isHasScrollTop, isHasScrollBottom].some((cb) => cb(el));
};
