import * as yup from 'yup';

yup.addMethod<yup.StringSchema>(yup.string, 'duration', function (message: string) {
  return this.test('duration', message, function (value) {
    const { path, createError } = this;
    if (!value) return true;
    if (!/^\d\d:\d\d$/.test(String(value))) {
      return createError({ path, message });
    }
    return true;
  });
});
yup.addMethod<yup.StringSchema>(yup.string, 'hex', function (message: string) {
  return this.test('hex', message, function (value) {
    const { path, createError } = this;
    if (!value) return true;
    if (!/^#([0-9a-f]{3}|[0-9a-f]{6})$/i.test(String(value))) {
      return createError({ path, message });
    }
    return true;
  });
});

export default yup;
