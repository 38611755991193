import { decoratorRank, decoratorWithFiles } from 'utils/decorators';
import * as dynamic from 'utils/dynamic';
import { apiRtk, behaviourMoveRows, DynamicResult, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_PRIORITIES, IPriority, IPriorityArgs } from './models';

export * from './models';

type Model = IPriority;

const REVALIDATE_TAG = 'Priorities' as const;

class Service extends DynamicService<Model> {
  @decoratorWithFiles('id', 'icon')
  async patchWithFiles(data: PatchPartial<Model, 'id'>) {
    return super.patch(data);
  }

  @decoratorWithFiles('id', 'icon')
  @decoratorRank('rank')
  async postWithFiles(data: Omit<Model, 'id' | 'rank'>) {
    return super.post(data);
  }

  moveRows = async (newRows: Partial<Model>[], oldRows: Partial<Model>[]) => {
    return behaviourMoveRows({
      mainField: 'id',
      moveField: 'rank',
      newRows,
      oldRows,
      requestPatch: this.patch,
    });
  };
}

export const ServicePriorities = new Service({
  getAll: API_PRIORITIES.GET_ALL_DYNAMIC,
  post: API_PRIORITIES.POST,
  patch: API_PRIORITIES.PATCH,
  delete: API_PRIORITIES.DELETE,
});

export const apiPriorities = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getPriorities: build.query<Model[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServicePriorities.getAllDynamic({
            filter: dynamic.makeFilter('isActive', true, dynamic.equals),
            select: dynamic.select('id', 'labelKey', 'icon', 'color', 'rank', 'isDefault'),
            orderBy: dynamic.orderBy('rank', 'asc'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getPrioritiesAll: build.query<Model[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServicePriorities.getAllDynamic({
            select: dynamic.select('id', 'labelKey', 'icon', 'color', 'rank', 'isDefault'),
            orderBy: dynamic.orderBy('rank', 'asc'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getGridPriorities: build.query<DynamicResult<Model, { count: true }>, IPriorityArgs>({
      query: ({ search, orderBy, take, skip }) => ({
        url: API_PRIORITIES.GET_ALL_DYNAMIC,
        params: {
          filter: dynamic
            .mergeFilters(
              dynamic.makeFilter(
                ['labelKey'],
                search,
                dynamic.decoratorIsNotNullable(dynamic.contains),
              ),
            )
            .join('&&'),
          select: dynamic.select(
            'id',
            'labelKey',
            'icon',
            'color',
            'isActive',
            'rank',
            'isDefault',
          ),
          take,
          skip,
          orderBy: dynamic.orderBy(orderBy),
          count: true,
        },
      }),
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getPriorityItem: build.query<Model, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServicePriorities.getDynamic(id);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    postPriority: build.mutation<void, Omit<Model, 'id' | 'rank'>>({
      queryFn: async (postData) => {
        try {
          await ServicePriorities.postWithFiles(postData);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchPriority: build.mutation<void, PatchPartial<Model, 'id'>>({
      queryFn: async (patchData) => {
        try {
          await ServicePriorities.patchWithFiles(patchData);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_TAG },
        { type: REVALIDATE_TAG, id },
      ],
    }),
    deletePriority: build.mutation<void, Pick<Model, 'id'>>({
      queryFn: async (deleteData) => {
        try {
          await ServicePriorities.delete(deleteData);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_TAG },
        { type: REVALIDATE_TAG, id },
      ],
    }),
    normalizePriorities: build.mutation<void, void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServicePriorities.getAllDynamic<Pick<Model, 'id'>>({
            select: dynamic.select('id', 'rank'),
            orderBy: dynamic.orderBy('rank', 'asc'),
          });

          for (let i = 0; i < value.length; i++) {
            const item = value[i];
            await ServicePriorities.patch({ ...item, rank: i + 1 });
          }

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    movePriorities: build.mutation<void, { newRows: Partial<Model>[]; oldRows: Partial<Model>[] }>({
      queryFn: async ({ newRows, oldRows }) => {
        try {
          await ServicePriorities.moveRows(newRows, oldRows);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { newRows }) => [
        { type: REVALIDATE_TAG },
        ...newRows.map((item) => ({ type: REVALIDATE_TAG, id: item.id })),
      ],
    }),
  }),
});
