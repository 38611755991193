import { AppBar, Box, Divider, Stack, Theme, Toolbar, useMediaQuery } from '@mui/material';
import { SvgLogo } from 'components/svg-icons/logo';
import React from 'react';
import { HeaderMobileNavigation } from '../header-mobile-navigation';
import { HeaderNavigation } from '../header-navigation';
import { HeaderNotification } from '../header-notification';
import { HeaderProfile } from '../header-profile';
import style from './index.module.scss';

export const Header: React.FC = () => {
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('xl'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <Box className={style.root}>
      <AppBar color={'primary'} position={'relative'} sx={{ borderRadius: '0.4rem' }}>
        <Toolbar>
          {isTablet && (
            <Box ml={-1} mr={1}>
              <HeaderMobileNavigation />
            </Box>
          )}

          <SvgLogo sx={{ width: 112, height: 40 }} />

          <Box flexGrow={1} ml={2.2}>
            {!isTablet && <HeaderNavigation />}
          </Box>

          <Stack
            mr={-1.5}
            direction={'row'}
            divider={
              <Divider
                orientation={'vertical'}
                flexItem
                sx={{ borderColor: 'rgba(255,255,255,0.2)' }}
              />
            }
            spacing={isMobile ? 1 : 2}
            alignSelf={'stretch'}
            alignItems={'center'}
          >
            <HeaderNotification />
            <HeaderProfile />
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
