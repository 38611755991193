import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Collapse, DialogActions, LinearProgress, Stack } from '@mui/material';
import clsx from 'clsx';
import { AppInputTitle } from 'components/app-input-title';
import { ButtonCancel, ButtonCreate, ButtonRemove } from 'components/buttons';
import { DialogDelete } from 'components/dialog-delete';
import { NativeScroll } from 'components/native-scroll';
import {
  TicketActionItemLink,
  useTicketActionItemInformation,
} from 'components/ticket-action-item-containers';
import { BaseBreadcrumbs } from 'components/tickets';
import { useCurrentUser, useEffectNotifyError, useFieldProps, useOpen, useTranslate } from 'hooks';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { apiTicketActionItems, schemaTicketActionItem } from 'services/ticket-action-items';
import { composeFunctions } from 'utils/other';
import { isFulfilledMutation } from 'utils/service';
import { DeepPartial } from 'utils/types';
import { InferType } from 'yup';
import { ticketActionItemEmitter } from '../../../../emitter';
import { TicketActionItemComments } from '../../../ticket-action-item-comments';
import { ControlAddNotification } from '../control-add-notification';
import { Form, Meta } from '../form';
import { RightPanel } from '../right-panel';
import style from './index.module.scss';

const schema = schemaTicketActionItem;
const defaultValues = schema.cast({});

const useFetchQuery = apiTicketActionItems.useGetTicketActionItemByKeyQuery;
const usePatchMutation = apiTicketActionItems.usePatchTicketActionItemMutation;
const useDeactivateMutation = apiTicketActionItems.useDeactivateTicketActionItemMutation;
const useDeleteMutation = apiTicketActionItems.useDeleteTicketActionItemMutation;

type FormData = InferType<typeof schema>;

interface Props {
  itemKey: string;
  onClose: () => void;
  onDone: () => void;
}
export const EditContent = memo<Props>(({ itemKey, onClose, onDone }) => {
  const { tp } = useTranslate();

  const resultGet = useFetchQuery(itemKey);
  useEffectNotifyError(resultGet.error);

  const initData = resultGet.data;
  const id = initData?.id;
  const resultInfo = useTicketActionItemInformation(id);
  useEffectNotifyError(resultInfo.error);
  const { customer, ticket } = resultInfo;

  const isReadOnly = initData ? initData.isActive === false : true;

  const user = useCurrentUser();
  const methods = useForm({
    defaultValues: {
      ...defaultValues,
      reporterUserCrmProfileID: user.appUserID,
      assigneeUserCrmProfileID: user.appUserID,
    },
    resolver: yupResolver(schema),
    context: {
      mustTrackTime: ticket?.mustTrackTime,
      durationCount: resultGet.data?._durationCount,
    },
  });

  const { reset, handleSubmit, setValue, trigger, control, watch } = methods;
  const getFieldProps = useFieldProps();

  const refOnce = useRef(false);
  useEffect(() => {
    if (!initData) return;
    if (refOnce.current) {
      trigger();
      return;
    }

    reset(schema.cast(initData, { assert: false, stripUnknown: true }));
    refOnce.current = true;
  }, [initData, reset, trigger]);

  const [patchItem, resultPatch] = usePatchMutation();
  useEffectNotifyError(resultPatch.error);

  const [deactivateItem, resultDeactivate] = useDeactivateMutation();
  useEffectNotifyError(resultDeactivate.error);

  const [deleteItem, resultDelete] = useDeleteMutation();
  useEffectNotifyError(resultDelete.error);

  const onConfirmDelete = useCallback(async () => {
    if (!id) return;
    const result = isReadOnly ? await deleteItem({ id }) : await deactivateItem({ id });

    if (isFulfilledMutation(result)) {
      onClose();
      onDone();
    }
  }, [id, deleteItem, deactivateItem, onClose, onDone, isReadOnly]);

  const onSubmit = useCallback(
    async (formData: FormData) => {
      if (!id) return;

      const result = await patchItem({
        ...formData,
        id,
        entryDate: formData.entryDate ?? undefined,
      });

      return result;
    },
    [id, patchItem],
  );

  const onSubmitAndClose = useCallback(
    async (formData: FormData) => {
      const result = await onSubmit(formData);

      if (isFulfilledMutation(result)) {
        onClose();
        onDone();
      }
    },
    [onDone, onClose, onSubmit],
  );

  const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useOpen();

  const breadcrumbs = useMemo(
    () => [
      ...(customer ? [{ label: customer.title }] : []),
      ...(ticket ? [{ label: ticket.title }] : []),
    ],
    [customer, ticket],
  );

  const isDoneDisabled = Boolean(ticket?.mustTrackTime && initData?._durationCount === 0);

  const meta = useMemo<DeepPartial<Meta>>(() => {
    return {
      disabled: {
        done: isDoneDisabled,
        reporterUserCrmProfileID: ticket?.isPrivate,
        assigneeUserCrmProfileID: ticket?.isPrivate,
      },
      helperTexts: {
        done: undefined,
      },
      errors: {
        done: isDoneDisabled,
      },
    };
  }, [ticket, isDoneDisabled]);

  useEffect(() => {
    if (!ticket) return;
    if (!ticket.isPrivate) return;

    setValue('reporterUserCrmProfileID', ticket.ownerUserCrmProfileID);
    setValue('assigneeUserCrmProfileID', ticket.ownerUserCrmProfileID);
  }, [ticket, setValue]);

  const isLoading =
    resultGet.isFetching ||
    resultPatch.isLoading ||
    resultDelete.isLoading ||
    resultDeactivate.isLoading ||
    resultInfo.isFetching;

  const showReportDescription =
    ticket?.mustTrackTime && user.appUserID === ticket?.ownerUserCrmProfileID && !isLoading;

  useEffect(() => {
    const onSave = () => {
      handleSubmit(onSubmit)();
    };
    ticketActionItemEmitter.on('save-active-item-form', onSave);

    return () => {
      ticketActionItemEmitter.off('save-active-item-form', onSave);
    };
  }, [onSubmit, handleSubmit]);

  return (
    <>
      {isLoading && <LinearProgress />}
      <div className={clsx({ [style.layout]: true, [style.layoutSmall]: isReadOnly })}>
        <NativeScroll className={style.left} mode={'always'}>
          <FormProvider {...methods}>
            <div className={style.top}>
              <Stack direction={'row'} spacing={1} mb={0.2}>
                <BaseBreadcrumbs
                  isLoading={resultInfo.isFetching}
                  sx={{ flexGrow: 1 }}
                  items={breadcrumbs}
                />
                <Box flexShrink={0}>
                  <TicketActionItemLink itemKey={itemKey} />
                </Box>
              </Stack>
              <Controller
                control={control}
                name={'title'}
                render={(renderProps) => {
                  const props = getFieldProps(renderProps);

                  return (
                    <AppInputTitle
                      value={props.value}
                      onChange={props.onChange}
                      error={props.error}
                      helperText={props.helperText}
                      placeholder={tp('ticket-action-item-placeholder')}
                      disabled={isLoading || isReadOnly}
                    />
                  );
                }}
              />
            </div>
            <Collapse in={isDoneDisabled && !isReadOnly}>
              <Alert severity={'error'} sx={{ mb: 1 }}>
                {tp('note-must-track-time')}
              </Alert>
            </Collapse>
            <Form
              isLoading={isLoading}
              meta={meta}
              showReportDescription={showReportDescription}
              disabled={isReadOnly}
            />
            <TicketActionItemComments ticketActionItemID={id} />
          </FormProvider>
        </NativeScroll>
        <RightPanel
          ticketActionItemID={id}
          activityReportDescription={watch('activityReportDescription')}
        />
      </div>
      <DialogActions
        sx={{
          pt: '2rem',
        }}
      >
        <ButtonRemove
          disabled={isLoading || !id}
          sx={{ marginRight: 'auto' }}
          onClick={onOpenDelete}
        />
        {id && <ControlAddNotification disabled={isLoading} ticketActionItemID={id} />}
        <ButtonCancel disabled={isLoading} onClick={onClose} color={'primary'}>
          {tp('cancel')}
        </ButtonCancel>
        <ButtonCreate
          disabled={isLoading}
          onClick={handleSubmit(onSubmitAndClose)}
          color={'primary'}
        >
          {tp('save')}
        </ButtonCreate>
      </DialogActions>
      {isOpenDelete && (
        <DialogDelete
          onClose={onCloseDelete}
          onAgree={composeFunctions(onCloseDelete, onConfirmDelete)}
        >
          {tp('delete-item')}
        </DialogDelete>
      )}
    </>
  );
});
