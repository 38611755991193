import { decoratorRank } from 'utils/decorators';
import * as dynamic from 'utils/dynamic';
import { apiRtk, behaviourMoveRows, DynamicResult, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import {
  API_PROJECT_WIKI_SUBJECTS,
  IGridProjectWikiSubjectsItemsArgs,
  IProjectWikiSubject,
} from './models';

export * from './models';

type Model = IProjectWikiSubject;
const REVALIDATE_TAG = 'ProjectWikiSubjects' as const;

class Service extends DynamicService<Model> {
  @decoratorRank('rank')
  async post(data: Omit<Model, 'id'>) {
    return super.post(data);
  }
  moveRows = async (newRows: Partial<Model>[], oldRows: Partial<Model>[]) => {
    return behaviourMoveRows({
      mainField: 'id',
      moveField: 'rank',
      newRows,
      oldRows,
      requestPatch: this.patch,
    });
  };
}

export const ServiceProjectWikiSubjects = new Service({
  getAll: API_PROJECT_WIKI_SUBJECTS.GET_ALL_DYNAMIC,
  post: API_PROJECT_WIKI_SUBJECTS.POST,
  patch: API_PROJECT_WIKI_SUBJECTS.PATCH,
  delete: API_PROJECT_WIKI_SUBJECTS.DELETE,
});

export const apiProjectWikiSubjects = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getProjectWikiSubjectsGrid: build.query<
      DynamicResult<Model, { count: true }>,
      IGridProjectWikiSubjectsItemsArgs
    >({
      query: ({ projectID, orderBy }) => ({
        url: API_PROJECT_WIKI_SUBJECTS.GET_ALL_DYNAMIC,
        params: {
          filter: dynamic.makeFilter('projectID', projectID, dynamic.equals),
          select: dynamic.select('id', 'rank', 'title', 'isActive'),
          orderBy: orderBy ? dynamic.orderBy(orderBy.field, orderBy.order) : undefined,
        },
      }),
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getProjectWikiSubjects: build.query<
      Model[],
      { projectID: string; order?: { field: string; order: null | 'asc' | 'desc' } }
    >({
      queryFn: async ({ projectID, order }) => {
        try {
          const {
            data: { value },
          } = await ServiceProjectWikiSubjects.getAllDynamic({
            filter: dynamic.makeFilter('projectID', projectID, dynamic.equals),
            select: dynamic.select('id', 'rank', 'title', 'isActive'),
            orderBy: order ? dynamic.orderBy(order.field, order.order) : undefined,
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getProjectWikiSubject: build.query<Model, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServiceProjectWikiSubjects.getDynamic(id);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    getProjectWikiSubjectSource: build.query<Model[], { projectID: string }>({
      queryFn: async ({ projectID }) => {
        try {
          const {
            data: { value },
          } = await ServiceProjectWikiSubjects.getAllDynamic({
            filter: dynamic.makeFilter('projectID', projectID, dynamic.equals),
            select: dynamic.select('id', 'title'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    postProjectWikiSubject: build.mutation<void, Omit<Model, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceProjectWikiSubjects.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchProjectWikiSubject: build.mutation<void, PatchPartial<Model, 'id' | 'projectID'>>({
      queryFn: async (data) => {
        try {
          await ServiceProjectWikiSubjects.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_TAG },
        { type: REVALIDATE_TAG, id },
      ],
    }),
    deleteProjectWikiSubject: build.mutation<void, PatchPartial<Model, 'id' | 'projectID'>>({
      queryFn: async (data) => {
        try {
          await ServiceProjectWikiSubjects.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_TAG },
        { type: REVALIDATE_TAG, id },
      ],
    }),
    normalizeProjectSubjects: build.mutation<void, { projectID: string }>({
      queryFn: async ({ projectID }) => {
        try {
          const {
            data: { value },
          } = await ServiceProjectWikiSubjects.getAllDynamic<Pick<Model, 'id'>>({
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter(
                  'projectID',
                  projectID,
                  dynamic.decoratorIsNotNullable(dynamic.equals),
                ),
              )
              .join('&&'),
            select: dynamic.select('id', 'rank'),
            orderBy: dynamic.orderBy('rank', 'asc'),
          });

          for (let i = 0; i < value.length; i++) {
            const item = value[i];
            await ServiceProjectWikiSubjects.patch({ ...item, rank: i + 1 });
          }

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    moveProjectWikiSubjects: build.mutation<
      void,
      { newRows: Partial<Model>[]; oldRows: Partial<Model>[] }
    >({
      queryFn: async ({ newRows, oldRows }) => {
        try {
          await ServiceProjectWikiSubjects.moveRows(newRows, oldRows);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { newRows }) => [
        { type: REVALIDATE_TAG },
        ...newRows.map((item) => ({ type: REVALIDATE_TAG, id: item.id })),
      ],
    }),
  }),
});
