import { decoratorWithFiles } from 'utils/decorators';
import { DynamicService } from 'utils/service';
import {
  API_TICKET_ACTION_ITEM_COMMENT_IMAGES,
  AppTicketActionItemCommentImage,
  ITicketActionItemCommentImage,
} from './models';

export * from './models';

type Model = ITicketActionItemCommentImage;

class Service extends DynamicService<Model> {
  @decoratorWithFiles('id', 'image')
  postItem(input: Omit<AppTicketActionItemCommentImage, 'id'>) {
    return this.post(input as Model);
  }

  @decoratorWithFiles('id', 'image')
  updateItem(input: AppTicketActionItemCommentImage) {
    return this.patch(input as Model);
  }
}

export const ServiceTicketActionItemCommentImages = new Service({
  getAll: API_TICKET_ACTION_ITEM_COMMENT_IMAGES.GET_ALL_DYNAMIC,
  post: API_TICKET_ACTION_ITEM_COMMENT_IMAGES.POST,
  patch: API_TICKET_ACTION_ITEM_COMMENT_IMAGES.PATCH,
  delete: API_TICKET_ACTION_ITEM_COMMENT_IMAGES.DELETE,
});
