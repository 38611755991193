import { yupResolver } from '@hookform/resolvers/yup';
import ReorderIcon from '@mui/icons-material/Reorder';
import { Box, DialogActions, Grid, Stack } from '@mui/material';
import { AppInput } from 'components/app-input';
import { AppSelect } from 'components/app-select';
import { BaseLabelExcel } from 'components/base-label-excel';
import { ButtonCancel, ButtonCreate } from 'components/buttons';
import { DateRangeInput } from 'components/date-picker-range';
import { DialogHeader, DialogHeaderProps } from 'components/dialog-header';
import { NativeScroll } from 'components/native-scroll';
import { endOfWeek, format, startOfWeek } from 'date-fns';
import { useEffectNotifyError, useFieldProps, useTranslate } from 'hooks';
import { toCorrectDateString, useDateInputStrategyCurrent } from 'hooks/use-date-input-strategy';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { apiReports, schemaReport, templateOptions } from 'services/reports';
import { convertToDate, DateValue } from 'utils/dates';
import { composeFunctions } from 'utils/other';
import { isFulfilledMutation } from 'utils/service';
import { InferType } from 'yup';
import { ReportMeat } from '../../models';

const usePostMutation = apiReports.usePostReportMutation;

const schema = schemaReport.pick([
  'templateName',
  'fromDate',
  'toDate',
  'reportName',
  'excelFileName',
]);

type FormModel = InferType<typeof schema>;

interface Props {
  HeaderProps?: Partial<DialogHeaderProps>;
  onClose: () => void;

  date?: DateValue;
  customerID: string | null;
  meta: ReportMeat;
}
export const ContentReportCreate: React.FC<Props> = ({
  HeaderProps,
  onClose,
  meta,
  date: _date,
  customerID,
}) => {
  const { customerName, projectName } = meta;
  let date = convertToDate(_date);
  const { tp, t } = useTranslate();

  const translatedTemplateOptions = useMemo(
    () => templateOptions.map((item) => ({ ...item, title: t(item.title) })),
    [t],
  );

  const methods = useForm({
    defaultValues: schema.cast({
      fromDate: startOfWeek(date).toISOString(),
      toDate: endOfWeek(date).toISOString(),
    }),
    resolver: yupResolver(schema),
  });
  const getFieldProps = useFieldProps();
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const templateName = watch('templateName');

  const [postItem, resultPost] = usePostMutation();
  useEffectNotifyError(resultPost.error);

  const onSubmit = useCallback(
    async (formData: FormModel) => {
      if (!customerID) {
        return;
      }
      const { templateName, ...data } = formData;

      const result = await postItem({
        ...data,
        customerID,
      });

      if (isFulfilledMutation(result)) {
        onClose();
      }
    },
    [postItem, onClose, customerID],
  );

  const isLoading = resultPost.isLoading;

  const [fromDate, toDate] = watch(['fromDate', 'toDate']);

  const fromToValue = useMemo(() => [fromDate, toDate], [fromDate, toDate]);
  const fromToErrors = useMemo(() => errors['fromDate'] || errors['toDate'], [errors]);
  const onChangeFromTo = useCallback(
    (value: DateValue[]) => {
      setValue('fromDate', toCorrectDateString(value[0]));
      setValue('toDate', toCorrectDateString(value[1]));
    },
    [setValue],
  );
  const strategyCurrent = useDateInputStrategyCurrent();

  const onChangeTemplate = useCallback(
    (value: string) => {
      let temp = templateOptions.find(({ id }) => id === value);

      if (!temp) return;

      const [from, to] = temp.makeRange(date);
      setValue('fromDate', from.toISOString());
      setValue('toDate', to.toISOString());
    },
    [setValue, date],
  );

  useEffect(() => {
    const reportName = [customerName, projectName, templateName].filter(Boolean).join(', ');

    setValue('reportName', reportName);
    setValue('excelFileName', `${format(new Date(), 'yyyy-MM-dd')} ${reportName}`);
  }, [customerName, projectName, setValue, templateName]);

  return (
    <>
      <DialogHeader
        {...HeaderProps}
        title={
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <ReorderIcon />
            <div>{tp('add-to-report')}</div>
          </Stack>
        }
        onClose={onClose}
      />
      <NativeScroll
        mode={'always'}
        sx={{
          paddingX: 2,
        }}
      >
        <Grid container rowSpacing={0.2} columnSpacing={2.8}>
          <Grid item xs={4}>
            <Controller
              control={control}
              name={'templateName'}
              render={(renderProps) => {
                const props = getFieldProps(renderProps);
                return (
                  <AppSelect
                    {...props}
                    onChange={composeFunctions(props.onChange, onChangeTemplate)}
                    disabled={isLoading}
                    disableClearable
                    options={translatedTemplateOptions}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={true}>
            <Box mt={1.2}>
              <DateRangeInput
                value={fromToValue}
                onChange={onChangeFromTo}
                strategies={strategyCurrent}
                error={!!fromToErrors}
                helperText={fromToErrors?.message}
                disabled={isLoading}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={'reportName'}
              render={(renderProps) => (
                <AppInput
                  required
                  {...getFieldProps(renderProps)}
                  disabled={isLoading}
                  disableClearable
                />
              )}
            />
          </Grid>
          <Grid item xs={12} pt={'3rem'}>
            <Controller
              control={control}
              name={'excelFileName'}
              render={(renderProps) => {
                const { label, ...rest } = getFieldProps(renderProps);
                return (
                  <>
                    <BaseLabelExcel label={label} />
                    <AppInput {...rest} label={null} disabled={isLoading} disableClearable />
                  </>
                );
              }}
            />
          </Grid>
        </Grid>
      </NativeScroll>
      <DialogActions>
        <ButtonCancel onClick={onClose} color={'primary'} sx={{ minWidth: 100 }} />
        <ButtonCreate
          title={tp('add-tooltip')}
          color={'primary'}
          disabled={isLoading}
          onClick={handleSubmit(onSubmit)}
          sx={{ minWidth: 100 }}
        >
          {tp('add')}
        </ButtonCreate>
      </DialogActions>
    </>
  );
};
