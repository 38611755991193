import CachedIcon from '@mui/icons-material/Cached';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { BoxProps, Button, ButtonProps, IconButton, Tooltip } from '@mui/material';
import { useTranslate } from 'hooks';
import React, { useRef } from 'react';
import { ConnectDragSource } from 'react-dnd';

interface TemplateDragAndDropProps extends Omit<BoxProps, 'title'> {
  drag: ConnectDragSource;
  title: string | number | undefined;
}

export const TemplateDragAndDrop: React.FC<TemplateDragAndDropProps> = ({ drag, title }) => {
  const ref = useRef(null);
  drag(ref);
  return (
    <IconButton ref={ref} title={String(title || 'unknown')}>
      <DragIndicatorIcon />
    </IconButton>
  );
};

interface TemplateDragAndDropHeadProps extends ButtonProps {}
export const TemplateDragAndDropHead: React.FC<TemplateDragAndDropHeadProps> = ({
  children,
  ...rest
}) => {
  const { tp } = useTranslate();
  return (
    <Tooltip title={tp('normalize-rank')}>
      <Button
        color={'inherit'}
        size={'small'}
        variant={'text'}
        endIcon={<CachedIcon fontSize={'small'} />}
        {...rest}
        sx={{ textTransform: 'capitalize', fontWeight: 'inherit', ...rest?.sx }}
      >
        {children === undefined ? tp('rank') : children}
      </Button>
    </Tooltip>
  );
};
