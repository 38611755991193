import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, ButtonProps } from '@mui/material';
import { useTranslate } from 'hooks';
import React from 'react';

interface Classes {
  root: string;
}

interface Props extends ButtonProps {
  className?: string;
  classes?: Partial<Classes>;
}

export const ButtonRefresh: React.FC<Props> = ({ title, children, ...rest }) => {
  const { tp } = useTranslate();
  return (
    <Button startIcon={<RefreshIcon />} variant={'text'} color={'primary'} {...rest}>
      {children ?? tp('refresh')}
    </Button>
  );
};
