import { Skeleton, Typography, TypographyProps } from '@mui/material';
import React from 'react';
import { useTranslate } from '../../../../hooks';

interface Props extends TypographyProps {
  isLoading: boolean;
}
export const BaseDialogTitle: React.FC<Props> = ({ isLoading, ...rest }) => {
  const { tp } = useTranslate();
  const element = (
    <Typography color={'primary'} variant={'subtitle1'} component={'h4'} mt={1.5} {...rest}>
      {isLoading ? tp('loading') : rest.children}
    </Typography>
  );

  return isLoading ? <Skeleton width={'80%'}>{element}</Skeleton> : element;
};
