import { Button, Stack, Typography } from '@mui/material';
import { APP_ROUTES } from 'configs';
import { useTranslate } from 'hooks';
import React, { createRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { AppNotificationLatest } from 'services/app-notifications';
import { NotificationCard } from '../notification-card';
import { NotificationControlAdd } from '../notification-control-add';
import { NotificationEmpty } from '../notification-empty';
import { NotificationSkeleton } from '../notification-skeleton';
import style from './index.module.scss';

interface Item {
  row: AppNotificationLatest;
  __nodeRef: React.RefObject<HTMLDivElement>;
}
const renderRow = (item: Item) => {
  const { row, __nodeRef } = item;
  return (
    <CSSTransition
      key={row.id}
      timeout={300}
      nodeRef={__nodeRef}
      classNames={{
        enter: style.itemEnter,
        enterActive: style.itemEnterActive,

        exit: style.itemExit,
        exitActive: style.itemExitActive,
      }}
    >
      <div ref={__nodeRef}>
        <NotificationCard row={row} />
      </div>
    </CSSTransition>
  );
};

interface Props {
  isLoading: boolean;
  rows: AppNotificationLatest[];
  onClose: () => void;
}
export const NotificationList: React.FC<Props> = ({ isLoading, rows, onClose }) => {
  const { tp } = useTranslate();
  let items = useMemo(() => {
    return rows.map((row) => {
      return { row, __nodeRef: createRef<HTMLDivElement>() };
    });
  }, [rows]);

  const isEmpty = !isLoading && rows.length === 0;

  return (
    <div className={style.root}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        className={style.header}
      >
        <Typography variant={'subtitle1'} component={'h3'}>
          {tp('notifications')}
        </Typography>
        <NotificationControlAdd />
      </Stack>
      {isLoading && <NotificationSkeleton />}
      {isEmpty ? (
        <NotificationEmpty />
      ) : (
        <TransitionGroup className={style.group}>{items.map(renderRow)}</TransitionGroup>
      )}
      <div className={style.bottom}>
        <Button
          component={Link}
          fullWidth={true}
          variant={'contained'}
          to={APP_ROUTES.NOTIFICATIONS().config}
          onClick={onClose}
        >
          {tp('view-more')}
        </Button>
      </div>
    </div>
  );
};
