import { Alert } from '@mui/material';
import { AppCheckbox } from 'components/app-checkbox';
import { DialogConfirm } from 'components/dialog-confirm';
import { TooltipError } from 'components/tooltips';
import { useEffectNotifyError, useOpen, useTranslate } from 'hooks';
import React, { useCallback, useState } from 'react';
import { apiTicketActionItems, schemaTicketActionItem } from 'services/ticket-action-items';
import { handleEventStopAndPrevent } from 'utils/handlers';
import { composeFunctions } from 'utils/other';

const useUpdateMutation = apiTicketActionItems.usePatchTicketActionItemMutation;
const schema = schemaTicketActionItem.pick(['done']);

interface Props {
  className?: string;
  done: boolean;
  durationCount: number;
  mustTrackTime: boolean;
  id: string;
}
export const TicketActionItemDone: React.FC<Props> = ({
  done,
  className,
  id,
  durationCount,
  mustTrackTime,
}) => {
  const { tp } = useTranslate();
  const { isOpen, onOpen, onClose } = useOpen();
  const [validationError, setValidationError] = useState<string>();
  const [update, resultUpdate] = useUpdateMutation();
  useEffectNotifyError(resultUpdate.error);
  const onSubmit = useCallback(
    async (value: boolean) => {
      try {
        setValidationError(undefined);
        await schema.validate({ done: value }, { context: { durationCount, mustTrackTime } });
        update({ id, done: value });
      } catch (e: any) {
        setValidationError(e.message || 'unknown-error');
      }
    },
    [update, id, durationCount, mustTrackTime],
  );

  const onDone = () => onSubmit(true);

  const handleChange = (_: any, value: boolean) => {
    if (value) {
      onOpen();
    } else {
      onSubmit(false);
    }
  };

  return (
    <>
      <TooltipError title={validationError || ''}>
        <AppCheckbox
          value={done}
          onChange={handleChange}
          disabled={resultUpdate.isLoading}
          className={className}
          onDoubleClick={handleEventStopAndPrevent}
        />
      </TooltipError>
      {isOpen && (
        <DialogConfirm
          title={tp('confirm')}
          onClose={onClose}
          onCancel={onClose}
          onConfirm={composeFunctions(onClose, onDone)}
        >
          <Alert severity={'info'}>{tp('done-confirm')}</Alert>
        </DialogConfirm>
      )}
    </>
  );
};
