import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../index';

const selectState = (state: AppState) => state.dashboardReports;

export const selectProjectHoursFilters = createSelector(selectState, ({ projectHoursFilters }) => {
  return projectHoursFilters;
});
export const selectUserHoursFilters = createSelector(selectState, ({ userHoursFilters }) => {
  return userHoursFilters;
});
export const selectMonthWorkFilters = createSelector(selectState, ({ monthWorkFilters }) => {
  return monthWorkFilters;
});
export const selectInteractionsProjectsLength = createSelector(
  selectState,
  ({ interactionsProjectsLength }) => {
    return interactionsProjectsLength;
  },
);
export const selectProjectHoursHeight = createSelector(selectState, ({ projectHoursHeight }) => {
  return projectHoursHeight;
});
