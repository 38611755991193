import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box, ButtonBase, Tooltip } from '@mui/material';
import clsx from 'clsx';
import React, { memo, useMemo } from 'react';
import { ConnectDragSource } from 'react-dnd';
import { useTranslate } from '../../../../hooks';
import { useDataGridSettings, useDataGridSortColumn } from '../../hooks';
import { IDataGridColumnParsedCell, IDataGridColumnProps, IDataGridHeadProps } from '../../models';
import { DataGridEditorContainer } from '../data-grid-editor-container';
import style from './index.module.scss';

interface DataGridColumnCellProps {
  drag: ConnectDragSource;
  row: any;
  options: IDataGridColumnParsedCell;
}
export const DataGridColumnCell = memo<DataGridColumnCellProps>(({ row, drag, options }) => {
  const { classes } = useDataGridSettings();
  const {
    editable,
    Editor,
    EditorContainer = DataGridEditorContainer,
    EditorProps,
    field,
    children,
    CellProps,
    sticky,
    ...rest
  } = options;

  const Children =
    typeof children === 'function'
      ? children({ row, field, drag })
      : children === undefined
      ? row[field]
      : children;

  const additionalProps = useMemo(() => {
    return typeof CellProps === 'function' ? CellProps({ row, field }) : CellProps;
  }, [CellProps, row, field]);

  if (editable && !Editor) {
    throw new Error('client-error: Editor is required');
  }

  return (
    <Box
      component={'td'}
      padding={'0 1rem'}
      height={'3.7rem'}
      whiteSpace={'nowrap'}
      {...rest}
      {...additionalProps}
      className={clsx(style.cell, classes.cell, rest.className, additionalProps?.className, {
        [style.editable]: Boolean(editable),
        [style.sticky]: Boolean(sticky),
        [style.stickyLeft]: sticky === 'left',
        [style.stickyRight]: sticky === 'right',
      })}
    >
      {editable && Editor ? (
        <EditorContainer row={row} field={field} Editor={Editor} EditorProps={EditorProps}>
          {Children}
        </EditorContainer>
      ) : (
        Children
      )}
    </Box>
  );
});

export const DataGridColumnHead: React.FC<IDataGridHeadProps> = ({
  sortable,
  field,
  sortableKey = field,
  sticky,
  children,
  InnerProps,
  ...rest
}) => {
  const { tp } = useTranslate();
  const { classes } = useDataGridSettings();

  const { isAsc, isDesc, onNext } = useDataGridSortColumn({ fieldKey: sortableKey });

  const sortOptions = useMemo(() => {
    if (isDesc) {
      return { title: tp('ordered-desc'), icon: <ArrowDropDownIcon /> };
    }
    if (isAsc) {
      return { title: tp('ordered-asc'), icon: <ArrowDropUpIcon /> };
    }
    return {
      title: null,
      icon: (
        <Box component={'span'} visibility={'hidden'}>
          <ArrowDropUpIcon />
        </Box>
      ),
    };
  }, [isDesc, isAsc, tp]);

  return (
    <Box
      component={'th'}
      whiteSpace={'nowrap'}
      fontSize={'1.2rem'}
      padding={'0.1rem 1rem'}
      textAlign={'left'}
      minHeight={'3.8rem'}
      {...rest}
      className={clsx(style.cellHead, classes?.cellHead, rest?.className, {
        [style.sticky]: Boolean(sticky),
        [style.stickyLeft]: sticky === 'left',
        [style.stickyRight]: sticky === 'right',
      })}
    >
      <Tooltip placement={'top-start'} title={sortOptions.title || ''} arrow>
        <ButtonBase
          color={'inherit'}
          sx={{
            fontFamily: 'inherit',
            fontSize: 'inherit',
          }}
          {...InnerProps}
          className={clsx(classes?.cellHeadInner, InnerProps?.className)}
          disableRipple={!sortable}
          onClick={sortable ? onNext : undefined}
        >
          {children}
          {sortable && sortOptions.icon}
        </ButtonBase>
      </Tooltip>
    </Box>
  );
};

// Register Component
export const DataGridColumn = <Model extends Record<string, any> = any>(
  props: IDataGridColumnProps<Model>,
) => {
  return null;
};
DataGridColumn.displayName = 'DataGridColumn';
