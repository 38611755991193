import { createAsyncThunk } from '@reduxjs/toolkit';
import { IGridCustomers, ServiceCustomers } from 'services/customers';
import * as dynamic  from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';
import {
  selectCustomersFilters,
  selectCustomersOrderBy,
  selectCustomersPagination,
} from './selectors';

export const actionCustomersGetDynamic = createAsyncThunk<
  { value: IGridCustomers[]; count: number },
  { page: number; take?: number } | void,
  AppAsyncThunkConfig
>(`CUSTOMERS/getDynamic`, async (payload, { getState }) => {
  try {
    const { search, status } = selectCustomersFilters(getState());
    const { take, skip } = selectCustomersPagination(getState());
    const { field, order } = selectCustomersOrderBy(getState());
    const params = {
      filter: dynamic.mergeFilters(
        dynamic.makeFilter(['companyName', 'invoiceCompanyName'], search, dynamic.contains),
        dynamic.makeFilter(['customerStatusID'], status, dynamic.decoratorExclude(dynamic.equals, 'all')),
      ).join('&&'),
      select: dynamic.select(
        'id',
        'customerStatusID',
        'companyName',
        'invoiceCompanyName',
        'contacts.count as totalContacts',
        'projects.count as totalProjects',
        'customerStatus.title as customerStatusTitle',
      ),
      orderBy: dynamic.orderBy(field, order),
      count: true,
      take,
      skip,
    };
    const { data: result } = await ServiceCustomers.getAllDynamic<IGridCustomers, typeof params>(
      params,
    );
    return result;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
