import { Dialog, Theme, useMediaQuery } from '@mui/material';
import { DialogHeader } from 'components/dialog-header';
import { LayoutError } from 'components/layout-error';
import { useTranslate } from 'hooks';
import { memo } from 'react';
import { apiTicketActionItems } from 'services/ticket-action-items';
import { useTicketActionItemInformation } from '../../../../hooks/use-ticket-information';
import { BaseSkeleton } from '../base-skeleton';
import { EditContent } from '../edit-content';
import { PreviewContent } from '../preview-content';

const useFetchQuery = apiTicketActionItems.useGetTicketActionItemByKeyQuery;

interface Props {
  itemKey: string;
  onClose: () => void;
  onDone: () => void;
}
export const TicketActionItemDialogEdit = memo<Props>(({ itemKey, onClose, onDone }) => {
  const { tp } = useTranslate();

  const resultGet = useFetchQuery(itemKey);
  const initData = resultGet.data;

  const id = initData?.id;
  const resultInfo = useTicketActionItemInformation(id);
  const { customer } = resultInfo;

  const { isLoading, error, isSuccess } = resultGet;

  const isPreview = initData ? initData.isActive === false : true;

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const Component = isPreview ? PreviewContent : EditContent;

  return (
    <Dialog open={true} fullWidth maxWidth={isPreview ? 'md' : 'xxl'} fullScreen={isMobile}>
      <DialogHeader
        bColor={customer?.color}
        title={initData?.title || (isLoading ? tp('loading') : ' ')}
        onClose={onClose}
        isLoading={isLoading}
      />

      {isLoading && !error && <BaseSkeleton />}
      {error && (
        <LayoutError
          message={error?.message}
          minHeight={'35rem'}
          ReloadButtonProps={{ onClick: resultGet.refetch, disabled: isLoading }}
        />
      )}
      {isSuccess && <Component itemKey={itemKey} onClose={onClose} onDone={onDone} />}
    </Dialog>
  );
});
