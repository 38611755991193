import { createAsyncThunk } from '@reduxjs/toolkit';
import { DoneItemsForReport, ServiceReportDoneItems } from 'services/report-done-items';
import { parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';
import { PREFIX } from './helpers';
import { selectReportItemOrderBy, selectReportItemStatuses } from './selectors';

export const actionsReportItemInit = createAsyncThunk<
  Array<DoneItemsForReport>,
  string,
  AppAsyncThunkConfig
>(`${PREFIX}/actionsReportItemInit`, async (reportID, { getState }) => {
  try {
    const state = getState();

    const orderBy = selectReportItemOrderBy(state);

    const result = await ServiceReportDoneItems.getDoneItemsForReport({
      reportID,
      orderBy,
    });

    return result;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
export const actionsReportItemRefresh = createAsyncThunk<
  Array<DoneItemsForReport>,
  void,
  AppAsyncThunkConfig
>(`${PREFIX}/actionsReportItemRefresh`, async (_, { getState }) => {
  try {
    const state = getState();

    const { reportID } = selectReportItemStatuses(state);

    if (!reportID) {
      throw new Error('Report ID is required');
    }

    const orderBy = selectReportItemOrderBy(state);

    const result = await ServiceReportDoneItems.getDoneItemsForReport({
      reportID,
      orderBy,
    });

    return result;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
