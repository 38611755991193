import { ActionMatchingPattern } from '@redux-saga/types';
import { apiAppNotifications } from 'services/app-notifications';
import { apiTicketActionItemComments } from 'services/ticket-action-item-comments';
import { apiTicketActionItems, ServiceTicketActionItems } from 'services/ticket-action-items';
import { call, put, select, takeEvery } from 'typed-redux-saga';
import { selectAccountTranslate, selectAccountUser } from '../auth';

function* watchCreateTicketActionItem(
  action: ActionMatchingPattern<
    typeof apiTicketActionItems.endpoints.postTicketActionItem.matchFulfilled
  >,
) {
  const { payload } = action;

  const { tp } = yield* select(selectAccountTranslate);
  const current = yield* select(selectAccountUser);

  if (!current) return;

  const { data } = yield* call(ServiceTicketActionItems.getInfoForNotificationCreated, payload.id);

  const { assignee, reporter } = data;

  const message = tp('notification-auto-ticket-action-item-created', {
    title: data.title,
    current: { fullName: current.name },
    reporter: { fullName: reporter.fullName },
    assignee: { fullName: assignee.fullName },
  });

  yield* put(
    apiAppNotifications.endpoints.createAppNotification.initiate({
      ticketActionItemID: data.id,
      ticketActionItemCommentID: null,
      reporterUserCrmProfileID: current.appUserID,
      userCrmProfileIDs: [assignee.appIdentityUserID],
      urgent: false,
      message: message,
    }),
  );
}

function* watchCreateTicketActionItemComment(
  action: ActionMatchingPattern<
    typeof apiTicketActionItemComments.endpoints.postTicketActionItemComment.matchFulfilled
  >,
) {
  const {
    payload,
    meta: {
      arg: { originalArgs },
    },
  } = action;

  const { tp } = yield* select(selectAccountTranslate);
  const current = yield* select(selectAccountUser);

  const userCrmProfileIDs = originalArgs.notificationUserCrmProfileIDs;

  if (!current) return;
  if (!userCrmProfileIDs.length) return;

  const { data: ticketActionItem } = yield* call(
    ServiceTicketActionItems.getInfoForNotificationCreated,
    originalArgs.ticketActionItemID,
  );

  const message = tp('notification-auto-ticket-action-item-comment-created', {
    title: ticketActionItem.title,
    current: { fullName: current.name },
  });

  yield* put(
    apiAppNotifications.endpoints.createAppNotification.initiate({
      ticketActionItemID: null,
      ticketActionItemCommentID: payload.id,
      reporterUserCrmProfileID: current.appUserID,
      userCrmProfileIDs: originalArgs.notificationUserCrmProfileIDs,
      urgent: false,
      message: message,
    }),
  );
}

function* watchUpdateTicketActionItemComment(
  action: ActionMatchingPattern<
    typeof apiTicketActionItemComments.endpoints.patchTicketActionItemComment.matchFulfilled
  >,
) {
  const {
    meta: {
      arg: { originalArgs },
    },
  } = action;

  const { tp } = yield* select(selectAccountTranslate);
  const current = yield* select(selectAccountUser);

  const userCrmProfileIDs = originalArgs.notificationUserCrmProfileIDs;

  if (!current) return;
  if (!userCrmProfileIDs.length) return;

  const { data: ticketActionItem } = yield* call(
    ServiceTicketActionItems.getInfoForNotificationCreated,
    originalArgs.ticketActionItemID,
  );

  const message = tp('notification-auto-ticket-action-item-comment-updated', {
    title: ticketActionItem.title,
    current: { fullName: current.name },
  });

  yield* put(
    apiAppNotifications.endpoints.createAppNotification.initiate({
      ticketActionItemID: null,
      ticketActionItemCommentID: originalArgs.id,
      reporterUserCrmProfileID: current.appUserID,
      userCrmProfileIDs: originalArgs.notificationUserCrmProfileIDs,
      urgent: false,
      message: message,
    }),
  );
}

export const sagasNotificationsAuto = [
  takeEvery(
    apiTicketActionItems.endpoints.postTicketActionItem.matchFulfilled,
    watchCreateTicketActionItem,
  ),
  takeEvery(
    apiTicketActionItemComments.endpoints.postTicketActionItemComment.matchFulfilled,
    watchCreateTicketActionItemComment,
  ),
  takeEvery(
    apiTicketActionItemComments.endpoints.patchTicketActionItemComment.matchFulfilled,
    watchUpdateTicketActionItemComment,
  ),
];
