import deepmerge from 'deepmerge';
import React, { ComponentProps, ForwardedRef, forwardRef } from 'react';

export const withDefaultProps = <C extends React.FunctionComponent<any>>(Component: C) => {
  return (props: ComponentProps<C>) => {
    const Wrapper = (originProps: ComponentProps<C>, ref: ForwardedRef<any>) => (
      <Component {...deepmerge(props, originProps)} ref={ref} />
    );
    return forwardRef(Wrapper) as unknown as C;
  };
};
