import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicService } from 'utils/service';
import { API_CUSTOMER_STATUCES, CustomerStatus } from './models';

export * from './models';

class Service extends DynamicService<CustomerStatus> {}

export const ServiceCustomerStatuses = new Service({
  getAll: API_CUSTOMER_STATUCES.GET_ALL_DYNAMIC,
  post: API_CUSTOMER_STATUCES.POST,
  patch: API_CUSTOMER_STATUCES.PATCH,
  delete: API_CUSTOMER_STATUCES.DELETE,
});

export const apiCustomerStatuses = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getCustomerStatuses: build.query<CustomerStatus[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceCustomerStatuses.getAllDynamic({
            select: dynamic.select('id', 'title', 'rank'),
            orderBy: dynamic.orderBy('rank', 'asc'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
