import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useEffectNotifyError, useSourcePriorities, useTranslate } from 'hooks';
import { memo, useCallback } from 'react';
import { IPriority } from 'services/priorities';
import { apiTickets } from 'services/tickets';
import { BaseSubMenu, MenuWithIcon } from '../base-sub-menu';

const useUpdateMutation = apiTickets.usePatchTicketMutation;

interface Props {
  id: string;
  priorityID: string;
  onClose: () => void;
}

const renderOption = (option: IPriority) => {
  return <MenuWithIcon title={option.labelKey} icon={option.icon} />;
};

export const MenuPriorities = memo<Props>(({ id, priorityID, onClose }) => {
  const { tp } = useTranslate();
  const { data: source, isFetching } = useSourcePriorities();
  const [update, updateResult] = useUpdateMutation();
  useEffectNotifyError(updateResult.error);

  const onChange = useCallback(
    (priorityID: string) => {
      update({ id, priorityID });
      onClose();
    },
    [update, id, onClose],
  );

  return (
    <BaseSubMenu
      value={priorityID}
      onChange={onChange}
      options={source}
      renderOption={renderOption}
      MenuProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
    >
      <MenuItem disabled={isFetching}>
        <ListItemIcon>
          <LowPriorityIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{tp('ticket-priority')}</ListItemText>
        <ArrowRightIcon />
      </MenuItem>
    </BaseSubMenu>
  );
});
