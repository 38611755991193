import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { ButtonCancel, ButtonCreate } from 'components/buttons';
import { DialogHeaderCustomer } from 'components/dialog-header-customer';
import { useCurrentUser, useEffectNotifyError } from 'hooks';
import React, { useCallback } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { apiNotes, schemaNotePost } from 'services/notes';
import { isFulfilledMutation } from 'utils/service';
import { InferType } from 'yup';
import { Form } from '../form';
import { addDays } from 'date-fns';

const usePostMutation = apiNotes.usePostNoteMutation;
const schema = schemaNotePost;
type FormData = InferType<typeof schema>;

interface Props {
  customerID: string | null;
  onClose: () => void;
  onDone: () => void;
}

export const DialogNoteNew: React.FC<Props> = ({ customerID, onClose, onDone }) => {
  const { appUserID, noteFadeDefaultDuration } = useCurrentUser();
  const [postItem, resultPost] = usePostMutation();
  useEffectNotifyError(resultPost.error);

  const methods = useForm({
    defaultValues: {
      userCrmProfileID: appUserID,
      customerID: customerID ?? null,
      entryDate: new Date().toISOString(),
      dueDate: addDays(new Date(), noteFadeDefaultDuration || 1).toISOString(),
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, control } = methods;
  const isLoading = resultPost.isLoading;

  const onSubmit = useCallback(
    async (formData: FormData) => {
      const res = await postItem(formData);

      if (isFulfilledMutation(res)) {
        onDone();
      }
    },
    [postItem, onDone],
  );

  return (
    <Dialog open={true} fullWidth maxWidth={'md'} onClose={onClose}>
      <Controller
        control={control}
        name={'customerID'}
        render={(renderProps) => {
          return (
            <DialogHeaderCustomer
              value={renderProps.field.value}
              onChange={renderProps.field.onChange}
              onClose={onClose}
            />
          );
        }}
      />

      <DialogContent>
        <FormProvider {...methods}>
          <Form isLoading={isLoading} />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <ButtonCancel onClick={onClose} />
        <ButtonCreate onClick={handleSubmit(onSubmit)} disabled={isLoading} />
      </DialogActions>
    </Dialog>
  );
};
