import { apiCustomerStatuses } from 'services/customer-statuses';
import { apiCustomers } from 'services/customers';
import { apiLabelSources } from 'services/label-sources';
import { apiLanguages } from 'services/languages';
import { apiProjectStatuses } from 'services/project-statuses';
import { apiProjectTypes } from 'services/project-types';
import { apiProjectWikiSubjects } from 'services/project-wiki-subjects';
import { apiProjects } from 'services/projects';
import { apiTicketActionItemStatuses } from 'services/ticket-action-item-statuses';
import { apiTimeTrackingActivities } from 'services/time-tracking-activities';
import { apiUserCrmProfilePermissions } from 'services/user-crm-profile-permissions';
import { apiUserCrmProfiles } from 'services/user-crm-profiles';
import { apiPriorities } from '../services/priorities';
import { apiTicketStatuses } from '../services/ticket-statuses';
import { useSelectOptionsStrategyTranslateLabelKey } from './use-select-options-strategies';
import { useSourceData, useSourceDataMap, useSourceTranslate } from './use-source';

export const useSourceCustomerStatuses = () => {
  return useSourceData(apiCustomerStatuses.useGetCustomerStatusesQuery());
};
export const useSourceCustomers = () => {
  return useSourceDataMap(useSourceData(apiCustomers.useGetCustomersQuery()), 'id');
};

export const useSourceCustomersWithExistingReportsOnly = () => {
  return useSourceDataMap(
    useSourceData(apiCustomers.useGetCustomersWithExistingReportsOnlyQuery()),
    'id',
  );
};

export const useSourceProjects = () => {
  return useSourceData(apiProjects.useGetProjectSourcesQuery());
};

export const useSourceCrmPermissions = () => {
  return useSourceData(apiUserCrmProfilePermissions.useGetUserCrmProfilePermissionSourceQuery());
};
export const useSourceCrmUser = () => {
  return useSourceData(apiUserCrmProfiles.useGetUserCrmProfileSourceQuery());
};
export const useSourceCrmUsersSelect = () => {
  return useSourceData(apiUserCrmProfiles.useGetUserCrmProfileSelectSourceQuery());
};
export const useSourceProjectWikiSubject = ({ projectID }: { projectID: string }) => {
  return useSourceData(apiProjectWikiSubjects.useGetProjectWikiSubjectSourceQuery({ projectID }));
};
export const useSourceProjectTypes = () => {
  return useSourceData(apiProjectTypes.useGetProjectTypesQuery());
};
export const useSourceProjectStatuses = () => {
  return useSourceData(apiProjectStatuses.useGetProjectStatusesQuery());
};
export const useSourceLabelSources = () => {
  return useSourceTranslate(useSourceData(apiLabelSources.useGetAllLabelSourcesQuery()), 'key');
};
export const useSourceLanguages = () => {
  return useSourceData(apiLanguages.useGetAllLanguagesQuery());
};

export const useSourceTimeTrackingActivities = () => {
  return useSourceTranslate(
    useSourceData(apiTimeTrackingActivities.useGetTimeTrackingActivitiesSourceQuery()),
    'title',
  );
};

export const useSourceTicketStatuses = () => {
  const result = useSourceData(apiTicketStatuses.useGetTicketStatusesQuery());
  const updatedData = useSelectOptionsStrategyTranslateLabelKey(result.data);
  return { ...result, data: updatedData };
};
export const useSourcePriorities = () => {
  const result = useSourceDataMap(useSourceData(apiPriorities.useGetPrioritiesQuery()), 'id');

  const updatedData = useSelectOptionsStrategyTranslateLabelKey(result.data);
  return { ...result, data: updatedData };
};

export const useSourceTicketItemActionStatuses = () => {
  const result = useSourceData(
    apiTicketActionItemStatuses.useGetTicketActionItemStatusesSourceQuery(),
  );
  const updatedData = useSelectOptionsStrategyTranslateLabelKey(result.data);
  return { ...result, data: updatedData };
};
