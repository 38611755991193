import { yupResolver } from '@hookform/resolvers/yup';
import { Popover, Stack } from '@mui/material';
import { IAllProps } from '@tinymce/tinymce-react';
import { AttachmentInput, AttachmentInputHandle } from 'components/attachment-input';
import { ButtonCancel, ButtonCreate } from 'components/buttons';
import { DEFAULT_INIT, InitOptions } from 'components/html-editor';
import { HtmlEditorLight } from 'components/html-editor-light';
import { SelectEmployees } from 'components/select-employees';
import { mergeRef, useFieldProps, useOpen, useTranslate } from 'hooks';
import React, { useCallback, useMemo, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { InferType } from 'yup';
import { schemaComment } from '../../models';
import style from './index.module.scss';
const schema = schemaComment;

const ICON_RING =
  '<svg height="24" width="24" viewBox="0 0 24 24"><path d="M10.01 21.01c0 1.1.89 1.99 1.99 1.99s1.99-.89 1.99-1.99zm8.87-4.19V11c0-3.25-2.25-5.97-5.29-6.69v-.72C13.59 2.71 12.88 2 12 2s-1.59.71-1.59 1.59v.72C7.37 5.03 5.12 7.75 5.12 11v5.82L3 18.94V20h18v-1.06zM16 13.01h-3v3h-2v-3H8V11h3V8h2v3h3z"></path></svg>';

export type FormData = InferType<typeof schema>;

const INIT: InitOptions = {
  ...DEFAULT_INIT,
  height: 300,
};

interface Props {
  init?: Partial<FormData>;
  disabled: boolean;
  onSubmit: (value: FormData) => void;
  onCancel: () => void;
}

export const BaseCommentInput: React.FC<Props> = ({ init, onSubmit, onCancel, disabled }) => {
  const { tp } = useTranslate();
  const { control, handleSubmit } = useForm({
    defaultValues: schema.cast(init),
    resolver: yupResolver(schema),
  });
  const getFieldProps = useFieldProps();
  const onSubmitWrap = useCallback(
    (formData: FormData) => {
      onSubmit(formData);
    },
    [onSubmit],
  );

  const ref = useRef<AttachmentInputHandle>();
  const onInsertImages = useCallback((files: File[]) => {
    ref.current?.addFiles(files);
  }, []);

  const { isOpen: isOpenPopup, onOpen: onOpenPopup, onClose: onClosePopup } = useOpen();
  const refPopup = useRef(null);

  const editorProps = useMemo<Partial<IAllProps>>(() => {
    return {
      init: {
        ...INIT,
        toolbar: [INIT.toolbar, 'notifications'].filter(Boolean).join(' | '),
        setup: (editor) => {
          editor.ui.registry.addIcon('notifications', ICON_RING);
          editor.ui.registry.addButton('notifications', {
            icon: 'notifications',
            onAction: onOpenPopup,
          });
        },
      },
    };
  }, [onOpenPopup]);

  return (
    <>
      <Stack className={style.root}>
        <div ref={refPopup}>
          <Controller
            control={control}
            name={'description'}
            render={(renderProps) => {
              return (
                <HtmlEditorLight
                  editorProps={editorProps}
                  onInsertImages={onInsertImages}
                  {...getFieldProps(renderProps)}
                  label={null}
                />
              );
            }}
          />
        </div>

        <Controller
          control={control}
          name={'images'}
          render={(renderProps) => {
            const props = getFieldProps(renderProps);
            return <AttachmentInput {...props} label={null} ref={mergeRef(props.ref, ref)} />;
          }}
        />
        <Stack spacing={1} direction={'row'} alignItems={'center'} justifyContent={'flex-end'}>
          <ButtonCancel onClick={onCancel} size={'small'} />
          <ButtonCreate disabled={disabled} onClick={handleSubmit(onSubmitWrap)} size={'small'}>
            {tp('save')}
          </ButtonCreate>
        </Stack>
      </Stack>
      <Popover
        anchorEl={refPopup.current}
        open={isOpenPopup}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: { sx: { p: 1 } },
        }}
        onClose={onClosePopup}
      >
        <Controller
          control={control}
          name={'notificationUserCrmProfileIDs'}
          render={(renderProps) => {
            return (
              <SelectEmployees
                {...getFieldProps(renderProps)}
                label={null}
                helperText={null}
                size={'small'}
                multiple={true}
                fullWidth={true}
                RenderInputProps={{
                  variant: 'outlined',
                  size: 'small',
                }}
                sx={{ minWidth: 300 }}
              />
            );
          }}
        />
      </Popover>
    </>
  );
};
