import { Stack, StackProps, Typography, TypographyProps } from '@mui/material';
import React from 'react';

interface Props extends StackProps {
  icon: React.ReactNode;
  children: React.ReactNode;
  TypographyProps?: Partial<TypographyProps>;
}

export const TemplateIcon: React.FC<Props> = ({ icon, children, TypographyProps, ...rest }) => {
  return (
    <Stack direction={'row'} spacing={0.4} alignItems={'center'} {...rest}>
      {icon}
      <Typography variant={'caption'} component={'div'} flexGrow={1} {...TypographyProps}>
        {children}
      </Typography>
    </Stack>
  );
};
