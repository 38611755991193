import { startOfDay } from 'date-fns';
import { DynamicOrder } from 'utils/dynamic';
import { FixServerObject, PatchPartial } from 'utils/types';
import * as yup from 'yup';
import { IDoneItem } from '../done-items';
import { IProject } from '../projects';
import { IReportDoneItem } from '../report-done-items';
import { IReport } from '../reports';
import { ITrackingActivity } from '../time-tracking-activities';
import { IUserCrmProfile } from '../user-crm-profiles';

const API = 'TimeTrackings';

export const API_TIME_TRACKINGS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ITimeTracking, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ITimeTracking, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface ITimeTracking
  extends FixServerObject<
    Components.Schemas.TimeTracking,
    | 'id'
    | 'timeTrackingActivityID'
    | 'userCrmProfileID'
    | 'projectID'
    | 'duration'
    | 'date'
    | 'ticketActionItemID'
  > {}

export interface TimeTrackingItemForAddReport
  extends FixServerObject<
    Components.Schemas.TimeTracking,
    | 'id'
    | 'description'
    | 'userCrmProfileID'
    | 'billableDuration'
    | 'duration'
    | 'date'
    | 'ticketActionItemID'
  > {
  projectName: Components.Schemas.Project['projectName'];
  customerID: Components.Schemas.Project['customerID'];
  customerName: Components.Schemas.Customer['companyName'];
  activityReportDescription: Components.Schemas.TicketActionItem['activityReportDescription'];
}

export interface IGridTimeTracking
  extends Pick<
    ITimeTracking,
    | 'id'
    | 'date'
    | 'duration'
    | 'userCrmProfileID'
    | 'billableDuration'
    | 'projectID'
    | 'timeTrackingActivityID'
    | 'ticketActionItemID'
    | 'description'
    | 'baseRate'
    | 'billableRate'
    | 'internal'
    | 'isActive'
  > {
  customerID: string;
  employeeFirstName: string;
  employeeLastName: string;
  employeePhoto: string;
  customerName: string;
  projectName: string;
  projectNameHeb: string;
  titleHeb: string;
  timeTrackingActivityTitle: string;
  day: string;
  firstName: string;
  lastName: string;
  userPhoto: string;
}

export const schemaTimeTracking = yup.object({
  userCrmProfileID: yup.string().required('rule-required').default(''),
  projectID: yup.string().required('rule-required').default(''),
  timeTrackingActivityID: yup.string().nullable().required('rule-required').default(''),
  customerID: yup.string().required('rule-required').default(''),
  ticketActionItemID: yup.string().required('rule-required').default(''),
  ticketID: yup.string().required('rule-required').default(''),
  duration: yup.string().required('rule-required').duration('rule-duration').default(''),
  description: yup.string().nullable().notRequired().default(null),
  baseRate: yup.number().nullable().notRequired().default(0),
  billableRate: yup.number().nullable().notRequired().default(0),
  billableDuration: yup.string().nullable().notRequired().default(''),
  internal: yup.boolean().notRequired().default(false),
  date: yup
    .string()
    .required('rule-required')
    .default(() => startOfDay(new Date()).toISOString()),
});

export const schemaTimeTrackingMultiple = schemaTimeTracking.shape({
  dates: yup
    .array()
    .of(yup.string().required('rule-required').nonNullable())
    .min(1, 'rule-required')
    .default(() => [startOfDay(new Date()).toISOString()]),
});

export interface ITimeTrackPostInput extends Omit<ITimeTracking, 'id'> {
  customerID?: string;
  ticketActionItemID: string;
}

export interface ITimeTrackPostMultipleInput extends Omit<ITimeTracking, 'id'> {
  customerID?: string;
  dates: string[];
  ticketActionItemID: string;
}

export interface ITimeTrackPatchInput extends PatchPartial<ITimeTracking, 'id'> {
  customerID?: string;
  ticketActionItemID: string;
}

export interface IGridTimeTrackingArgs {
  userCrmProfileID: string;
  customerID: string;
  projectID: string;
  timeTrackingActivityID: string;
  period: string[];
  orderBy: DynamicOrder;
}

export interface IGridTimeTrackingProjectItemArgs {
  projectID: string;
  userCrmProfileID: string | null;
  period: string[];
  order: DynamicOrder;
  take: number;
  skip: number;
}

export interface ITimeTrackingMoveItemToActivityInput
  extends Pick<
    Components.Schemas.TimeTracking,
    'userCrmProfileID' | 'date' | 'duration' | 'description'
  > {
  customerID: Components.Schemas.Project['customerID'];
  projectNameHeb: Components.Schemas.Project['projectNameHeb'];
  titleHeb: Components.Schemas.TimeTrackingActivity['titleHeb'];
}

export interface GridTimeTrackingForTicketActionItems
  extends Pick<
    ITimeTracking,
    | 'id'
    | 'userCrmProfileID'
    | 'userCrmProfile'
    | 'date'
    | 'timeTrackingActivityID'
    | 'ticketActionItemID'
    | 'timeTrackingActivity'
    | 'duration'
    | 'billableDuration'
    | 'description'
    | 'isActive'
  > {
  userCrmProfile: Pick<IUserCrmProfile, 'appIdentityUserID' | 'fullName' | 'userPhoto'>;
  timeTrackingActivity: Pick<ITrackingActivity, 'id' | 'title'>;
  customerID: IProject['customerID'];
  doneItemsDescriptionData: string[];
  report: IReport;
  doneItems: Array<Pick<IDoneItem, 'id'>>;
}

export interface ITimeTrackingPreview
  extends Pick<Components.Schemas.TimeTracking, 'id' | 'duration' | 'date'> {
  userCrmProfileName: Components.Schemas.UserCrmProfile['fullName'];
  timeTrackingActivityTitle: Components.Schemas.TimeTrackingActivity['title'];
  ticketActionItemTitle: Components.Schemas.TicketActionItem['title'];
}

export interface IGridTimeTrackingForTicketActionItemsArgs {
  ticketActionItemID: string;
}

export interface TimeTrackingDeleteDoneItem {
  id: string;
  reportDoneItems: Array<Pick<IReportDoneItem, 'id'>>;
}

export interface GetDoneItemsToDeleteOutput extends Pick<ITimeTracking, 'doneItems'> {
  doneItems: Array<TimeTrackingDeleteDoneItem>;
}
