import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
import { Typography } from '@mui/material';
import { useTranslate } from 'hooks';
import { NotificationControlAdd } from 'layouts/private/components/notification-control-add';
import React from 'react';
import style from './index.module.scss';

interface Props {}
export const NotificationEmpty: React.FC<Props> = () => {
  const { tp } = useTranslate();
  return (
    <div className={style.root}>
      <NotificationsOffOutlinedIcon className={style.icon} />
      <Typography variant={'h2'} color={'primary'} mb={1}>
        {tp('notification-empty-title')}
      </Typography>
      <Typography fontSize={16} mb={1.6}>
        {tp('notification-empty-description')}
      </Typography>
      <NotificationControlAdd variant={'contained'} color={'secondary'} />
    </div>
  );
};
