import React, { memo, PropsWithChildren, useMemo } from 'react';
import { AnyObjectSchema } from 'yup';
import { DataGridClasses } from '../../models';

interface DataGridSettingContextValue {
  rowKey: any;
  classes: Partial<DataGridClasses>;
  schema?: AnyObjectSchema;
}
export const DataGridSettingContext = React.createContext<DataGridSettingContextValue | null>(null);

interface Props {
  rowKey: any;
  classes?: Partial<DataGridClasses>;
  schema?: AnyObjectSchema;
}
export const DataGridSettingsProvider = memo<PropsWithChildren<Props>>(
  function DataGridSettingsProvider({ rowKey, schema, children, classes }) {
    const value = useMemo(() => {
      return {
        rowKey,
        schema,
        classes: { ...classes },
      };
    }, [classes, rowKey, schema]);
    return (
      <DataGridSettingContext.Provider value={value}>{children}</DataGridSettingContext.Provider>
    );
  },
);
