import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import { Stack, Tab, Tabs, Typography } from '@mui/material';
import { CollapsePanel } from 'components/collapse-panel';
import { APP_ROUTES } from 'configs';
import { useAppDispatch, useAppSelector, useTranslate } from 'hooks';
import { memo, useCallback, useMemo } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { actionsNotesLayout, selectNotesLayoutExpanded } from 'store/notes-layout';
import { actionsNotes, NOTES_VIEW, selectView } from '../../store/notes';
import { enumToArray } from '../../utils/types';
import { Customers } from './components';
import style from './index.module.scss';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';

export const NotesLayout = memo(() => {
  const { tp, t } = useTranslate();
  const dispatch = useAppDispatch();
  const title = tp('notes');

  const { pathname } = useLocation();

  const isExpanded = useAppSelector(selectNotesLayoutExpanded);
  const onToggleExpand = useCallback(() => {
    dispatch(actionsNotesLayout.toggleExpanded());
  }, [dispatch]);

  const view = useAppSelector(selectView);
  const viewOptions = useMemo(() => {
    return enumToArray(NOTES_VIEW).map(({ id }: { id: NOTES_VIEW }) => {
      return {
        id,
        title: t(id),
        icon: (() => {
          switch (id) {
            case NOTES_VIEW.CALENDAR:
              return <CalendarMonthIcon sx={{ fontSize: 20 }} />;
            case NOTES_VIEW.TIMELINE:
              return <ViewTimelineIcon sx={{ fontSize: 20 }} />;
            default:
              return <ViewCompactIcon sx={{ fontSize: 20 }} />;
          }
        })()
      };
    }, []);
  }, [t]);
  const handleChangeView = useCallback(
    (value: NOTES_VIEW) => {
      dispatch(actionsNotes.setView(value));
    },
    [dispatch],
  );

  return (
    <CollapsePanel
      in={isExpanded}
      onClick={onToggleExpand}
      panel={
        <div className={style.left}>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={1}>
            <Typography variant={'h2'} component={'h1'} color={'primary'}>
              {title}
            </Typography>
          </Stack>

          <Customers isLoading={false} />
        </div>
      }
    >
      <div className={style.right}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Tabs value={pathname}>
            <Tab
              value={APP_ROUTES.NOTES.path}
              label={tp('active-notes')}
              component={Link}
              to={APP_ROUTES.NOTES().config}
              icon={<DashboardOutlinedIcon sx={{ fontSize: 20 }} />}
              iconPosition={'start'}
              sx={{
                minHeight: 48,
              }}
            />
          </Tabs>

          {pathname === APP_ROUTES.NOTES.path && (
            <Tabs value={view}>
              {viewOptions.map(({ id, title, icon }) => (
                <Tab
                  key={id}
                  value={id}
                  label={title}
                  onClick={() => handleChangeView(id)}
                  sx={{
                    minHeight: 48,
                  }}
                  icon={icon}
                  iconPosition={'start'}
                />
              ))}
            </Tabs>
          )}

          <Tabs value={pathname}>
            <Tab
              value={APP_ROUTES.NOTES_ARCHIVE.path}
              label={tp('archive')}
              component={Link}
              to={APP_ROUTES.NOTES_ARCHIVE().config}
              icon={<ArchiveOutlinedIcon sx={{ fontSize: 20 }} />}
              iconPosition={'start'}
              sx={{
                minHeight: 48,
              }}
            />
          </Tabs>
        </Stack>
        <Outlet />
      </div>
    </CollapsePanel>
  );
});
