import { Skeleton, Stack } from '@mui/material';
import React, { useMemo } from 'react';

interface Props {
  count?: number;
}
export const NotificationSkeleton: React.FC<Props> = ({ count = 3 }) => {
  const rows = useMemo(() => Array.from({ length: count }).fill(null), [count]);
  return (
    <Stack spacing={0.4} px={1.6}>
      {rows.map((_, i) => {
        return <Skeleton key={i} variant="rectangular" width={'100%'} height={84} />;
      })}
    </Stack>
  );
};
