import { createSelector } from '@reduxjs/toolkit';
import { apiLabels, ILabel } from 'services/labels';
import { calcHtmlTemplate, createMap } from 'utils/other';
import { TranslateMap, TranslateProps } from '../../configs';
import { AppState } from '../index';

const selectState = (state: AppState) => state.account;
export const selectAccountUser = createSelector(selectState, ({ user }) => {
  return user;
});

export const selectAccountPermissions = createSelector(selectAccountUser, (user) => {
  if (!user) return null;

  const calculatedFields = {
    isAdmin: user.userCrmProfilePermission.isAllowToEditSetting,
    __isAllowMakeReport: true,
  };

  if (process.env.NODE_ENV === 'production') {
    return { ...user.userCrmProfilePermission, ...calculatedFields };
  }

  // Here you can rewrite permissions
  return {
    ...user.userCrmProfilePermission,
    ...calculatedFields,
    isAdmin: true,
    isAllowToEditSetting: true,
    isAllowToEditLabels: true,
  };
});

export const selectAccountState = createSelector(selectState, ({ isLoading, isInit, error }) => {
  return { isLoading, isInit, error };
});
export const selectAppUserID = createSelector(selectState, ({ user }) => {
  return user ? user.appUserID : '';
});
export const selectAccountLanguageID = createSelector(selectState, ({ languageID }) => {
  return languageID;
});
const selectRtkLabels = createSelector(
  (state: AppState) => state,
  selectAccountLanguageID,
  (state, languageID) => {
    return apiLabels.endpoints.getAppLabels.select(languageID || '')(state);
  },
);
export const selectAccountLabels = createSelector(selectRtkLabels, (result) => {
  return result.data || [];
});

export const selectAccountLabelsMap = createSelector(selectAccountLabels, (labels) => {
  return createMap(labels, 'labelKey') || {};
});

const getTranslateValue = (map: Record<string, ILabel | undefined>, key: string) => {
  const label = map[key];
  if (!label && process.env.NODE_ENV === 'production') {
    console.log(`%c "${key}" %c not found please add it to crm`, 'color: red;', 'color: black;');
  }
  return label?.title || key;
};

export const selectAccountTranslate = createSelector(selectAccountLabelsMap, (map) => {
  const t = (key: string) => {
    return getTranslateValue(map, key);
  };

  const tp = <T extends keyof TranslateMap>(...params: TranslateProps<T>) => {
    return params[1] ? calcHtmlTemplate(t(params[0]), params[1]) : t(params[0]);
  };

  return { t, tp };
});
