import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicResult } from 'utils/service';
import {
  apiNotificationUserProfiles,
  schemaNotificationUserProfilePost,
  ServiceNotificationUserProfiles,
} from '../notification-user-profile';
import { apiNotifications, schemaNotificationPost } from '../notifications';
import {
  AppNotificationGridInput,
  AppNotificationGridPersonalInput,
  AppNotificationLatest,
  APP_NOTIFICATION_TYPE,
  IAppNotificationPostInput,
} from './models';

export * from './models';

const filterType = (type: null | APP_NOTIFICATION_TYPE) => {
  if (type === APP_NOTIFICATION_TYPE.ACTIONS) {
    return dynamic.makeFilter('notification.ticketActionItemID', null, dynamic.notEquals);
  }

  if (type === APP_NOTIFICATION_TYPE.COMMENTS) {
    return dynamic.makeFilter('notification.ticketActionItemCommentID', null, dynamic.notEquals);
  }

  if (type === APP_NOTIFICATION_TYPE.REQUESTS) {
    return dynamic
      .mergeFilters(
        dynamic.makeFilter('notification.ticketActionItemID', null, dynamic.notEquals),
        dynamic.makeFilter('notification.ticketActionItemCommentID', null, dynamic.notEquals),
      )
      .join('&&');
  }

  return undefined;
};

const SELECT_APP_NOTIFICATION_LATEST = dynamic.select(
  'id',
  'notificationID',
  'userCrmProfileID',
  'urgent',
  'done',
  'new { notification.message, notification.entryDate, notification.ticketActionItemCommentID, notification.reporterUserCrmProfileID, notification.ticketActionItemID } as notification',
  'notification.ticketActionItemComment.itemKey as commentItemKey',
  'notification.ticketActionItem.itemKey as itemKey',
);

class Service {
  async getCurrentNotDone(appUserID: string) {
    const params = {
      filter: dynamic
        .mergeFilters(
          dynamic.makeFilter('userCrmProfileID', appUserID, dynamic.equals),
          dynamic.makeFilter('done', false, dynamic.equals),
        )
        .join('&&'),
      select: SELECT_APP_NOTIFICATION_LATEST,
      orderBy: 'urgent desc, notification.entryDate desc',
      take: 5,
      count: true,
    };
    return ServiceNotificationUserProfiles.getAllDynamic<AppNotificationLatest, typeof params>(
      params,
    );
  }
}

export const ServiceAppNotifications = new Service();

export const apiAppNotifications = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    createAppNotification: build.mutation<void, IAppNotificationPostInput>({
      queryFn: async (input, { dispatch }) => {
        try {
          const notificationPayload = await schemaNotificationPost.validate(input);

          const notification = await dispatch(
            apiNotifications.endpoints.postNotification.initiate(
              schemaNotificationPost.cast(notificationPayload, { stripUnknown: true }),
            ),
          ).unwrap();

          let promises = input.userCrmProfileIDs.map(async (userCrmProfileID) => {
            const notificationUserProfile = await schemaNotificationUserProfilePost.validate({
              userCrmProfileID,
              notificationID: notification.id,
              urgent: input.urgent,
            });

            return dispatch(
              apiNotificationUserProfiles.endpoints.postNotificationUserProfile.initiate(
                schemaNotificationUserProfilePost.cast(notificationUserProfile, {
                  stripUnknown: true,
                }),
              ),
            ).unwrap();
          });

          await Promise.all(promises);

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: 'NotificationsCurrentUser' }],
    }),
    doneAppNotification: build.mutation<void, string>({
      queryFn: async (input) => {
        try {
          await ServiceNotificationUserProfiles.patch({ id: input, done: true });

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: 'NotificationsCurrentUser' }],
    }),
    getAppNotificationsGrid: build.query<
      DynamicResult<AppNotificationLatest, { count: true }>,
      AppNotificationGridInput
    >({
      queryFn: async (input) => {
        try {
          const { appUserID, search, take, skip, date, type } = input;
          const params = {
            select: SELECT_APP_NOTIFICATION_LATEST,
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('userCrmProfileID', appUserID, dynamic.equals),
                dynamic.makeFilter(['notification.message'], search, dynamic.contains),
                dynamic.makeFilter('notification.entryDate', date, dynamic.dateRange),
                filterType(type),
              )
              .join('&&'),
            orderBy: 'urgent desc, done asc, notification.entryDate desc',
            take,
            skip,
            count: true,
          };
          const { data } = await ServiceNotificationUserProfiles.getAllDynamic<
            AppNotificationLatest,
            typeof params
          >(params);

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: 'NotificationsCurrentUser' }],
    }),
    getAppNotificationsGridPersonal: build.query<
      DynamicResult<AppNotificationLatest, { count: true }>,
      AppNotificationGridPersonalInput
    >({
      queryFn: async (input) => {
        try {
          const { appUserID, search, take, skip, date, type } = input;
          const params = {
            select: SELECT_APP_NOTIFICATION_LATEST,
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter(
                  'notification.reporterUserCrmProfileID',
                  appUserID,
                  dynamic.equals,
                ),
                dynamic.makeFilter(['notification.message'], search, dynamic.contains),
                dynamic.makeFilter('notification.entryDate', date, dynamic.dateRange),
                filterType(type),
              )
              .join('&&'),
            orderBy: 'urgent desc, done asc, notification.entryDate desc',
            take,
            skip,
            count: true,
          };
          const { data } = await ServiceNotificationUserProfiles.getAllDynamic<
            AppNotificationLatest,
            typeof params
          >(params);

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: 'NotificationsCurrentUser' }],
    }),
  }),
});
