import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { apiDoneItems, schemaDoneItem } from 'services/done-items';
import { ServiceTicketActionItems } from 'services/ticket-action-items';
import { ITimeTracking } from 'services/time-trackings';
import * as dynamic from 'utils/dynamic';
import { isPromiseFulfilled, wait } from 'utils/promises';
import { parseErrorData } from 'utils/service';
import { ValidationError } from 'yup';
import { selectAccountTranslate, selectAppUserID } from '../auth';
import { AppAsyncThunkConfig } from '../index';
import { ICheckDoneUnAssignResponseItem, PREFIX } from './helpers';
import { actionsCheckDoneUnAssign } from './slice';

const SELECT_TICKET_ITEMS = dynamic.select(
  'id',
  'title',

  'done',
  'review',
  'itemKey',

  'reporterUserCrmProfileID',
  'assigneeUserCrmProfileID',
  'priorityID',
  'ticketActionItemStatusID',

  'startDateAndTime',
  'endDateAndTime',
  'activityReportDescription',

  'ticketID',
  `timeTrackings.Select(t => 
    new {
        t.duration,
        t.date,
        (t.doneItems.Where(d => d.reportDoneItems.count() > 0).count() > 0) as isAttached
    }) as tracking`,
);

export const actionCheckDoneUnAssignFetchAll = createAsyncThunk<
  ICheckDoneUnAssignResponseItem[],
  void,
  AppAsyncThunkConfig
>(`${PREFIX}/actionCheckDoneUnAssignFetchAll`, async (_, { getState }) => {
  try {
    const appUserID = selectAppUserID(getState());
    const params = {
      filter: dynamic
        .mergeFilters(
          dynamic.makeFilter('isActive', true, dynamic.equals),
          dynamic
            .mergeFilters(
              dynamic.makeFilter('ticket.isPrivate', false, dynamic.equals),
              dynamic.makeFilter('ticket.ownerUserCrmProfileID', appUserID, dynamic.equals),
            )
            .join('||'),
          'timeTrackings.Any(t => t.doneItems.All(d => d.reportDoneItems.Count() == 0))',
        )
        .join('&&'),
      select: SELECT_TICKET_ITEMS,
    };
    const result = await ServiceTicketActionItems.getAllDynamic<
      ICheckDoneUnAssignResponseItem,
      typeof params
    >(params);
    return result.data.value;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionCheckDoneUnAssignFetchItem = createAsyncThunk<
  ICheckDoneUnAssignResponseItem,
  string,
  AppAsyncThunkConfig
>(`${PREFIX}/actionCheckDoneUnAssignFetchItem`, async (id) => {
  try {
    const params = {
      select: SELECT_TICKET_ITEMS,
    };
    const result = await ServiceTicketActionItems.getDynamic<
      ICheckDoneUnAssignResponseItem,
      typeof params
    >(id, params);
    return result.data;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
export const actionCheckDoneUnAssignAttachToReportItem = createAsyncThunk<
  { id: string },
  { id: string; reportID: string; description: string },
  AppAsyncThunkConfig
>(
  `${PREFIX}/actionCheckDoneUnAssignAttachToReportItem`,
  async ({ id, reportID, description }, { dispatch, getState }) => {
    try {
      const { data } = await ServiceTicketActionItems.getDynamic<{
        id: string;
        timeTrackings: Array<
          Pick<ITimeTracking, 'id' | 'userCrmProfileID' | 'duration' | 'billableDuration' | 'date'>
        >;
      }>(id, {
        select: dynamic.select(
          'id',
          'timeTrackings.Where(t => t.doneItems.All(d => d.reportDoneItems.Count() == 0)).Select(t => new { t.id, t.userCrmProfileID, t.duration, t.billableDuration, t.date }) as timeTrackings',
        ),
      });

      const items = await Promise.all(
        data.timeTrackings.map(async (row) => {
          try {
            const payload = await schemaDoneItem.validate(
              {
                timeTrackingID: row.id,
                userCrmProfileID: row.userCrmProfileID,
                duration: row.duration,
                billableDuration: row.billableDuration,
                date: row.date || new Date().toISOString(),
                description,
              },
              { stripUnknown: true },
            );
            return { ...payload, description: payload.description ?? '' };
          } catch (e: any) {
            if (e instanceof ValidationError) {
              throw new Error('Schema error');
            }
            throw e;
          }
        }),
      );

      await dispatch(
        apiDoneItems.endpoints.createDoneItemsWithReport.initiate({ reportID, items }),
      ).unwrap();

      return { id };
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);

export const actionCheckDoneUnAssignAttachToReport = createAsyncThunk<
  void,
  { reportID: string; ticketActionItems: Array<{ id: string; description: string }> },
  AppAsyncThunkConfig
>(
  `${PREFIX}/actionCheckDoneUnAssignAttachToReport`,
  async ({ reportID, ticketActionItems }, { getState, dispatch }) => {
    try {
      const state = getState();

      const res = await Promise.allSettled(
        ticketActionItems.map((row) => {
          return dispatch(
            actionCheckDoneUnAssignAttachToReportItem({
              id: row.id,
              reportID,
              description: row.description,
            }),
          ).unwrap();
        }),
      );

      let { error, success } = res.reduce(
        (acc, item) => {
          if (isPromiseFulfilled(item)) {
            acc.success += 1;
          }
          if (item.status === 'rejected') {
            acc.error += 1;
          }
          return acc;
        },
        { error: 0, success: 0 },
      );

      const { tp } = selectAccountTranslate(state);

      toast.success(tp('unassign-attach-success', { count: success }));
      error && toast.error(tp('unassign-attach-error', { count: error }));

      dispatch(actionsCheckDoneUnAssign.resetScroll());
      await wait(1000);

      for await (const item of res) {
        if (isPromiseFulfilled(item)) {
          await wait(500);
          dispatch(actionsCheckDoneUnAssign.removeItem(item.value.id));
        }
      }
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);
