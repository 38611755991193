import { apiReportDoneItems } from 'services/report-done-items';
import { put, select, takeLatest } from 'typed-redux-saga';
import { workerErrorNotifyThunk } from 'utils/sagas';
import { actionsReportItemInit, actionsReportItemRefresh } from './actions';
import { selectReportItemStatuses } from './selectors';

function* watchUpdate() {
  const { isInit } = yield* select(selectReportItemStatuses);

  if (!isInit) return;

  yield* put(actionsReportItemRefresh());
}

export const sagasReportItem = [
  takeLatest(
    [actionsReportItemInit.rejected, actionsReportItemRefresh.rejected],
    workerErrorNotifyThunk,
  ),
  takeLatest(
    [
      apiReportDoneItems.endpoints.deleteReportDoneItemBulk.matchFulfilled,
      apiReportDoneItems.endpoints.deactivateReportDoneItemBulk.matchFulfilled,
    ],
    watchUpdate,
  ),
];
