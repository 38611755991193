import { ITicketActionItemExtended } from 'services/ticket-action-items';

export const PREFIX = 'CHECK_DONE_UN_ASSIGN';
export enum CHECK_DONE_UN_ASSIGN_STATUS {
  DEFAULT,
  LOADING,
  SUCCESS,
  ERROR,
}

export interface ICheckDoneUnAssignResponseItem extends ITicketActionItemExtended {
  activityReportDescription: string | null;
}

export interface ICheckDoneUnAssignItem
  extends Omit<ICheckDoneUnAssignResponseItem, '_commentsCount'> {
  __checked: boolean;
  __status: CHECK_DONE_UN_ASSIGN_STATUS;
  __error: null | Error;
}
