import React, { ForwardedRef, forwardRef, useMemo } from 'react';

import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { AppSelect, AppSelectProps } from 'components/app-select';
import { apiUserCrmProfiles } from 'services/user-crm-profiles';
import { makeFullName } from 'utils/app-helpers';
import { getSearchFilter } from 'utils/front-filters';
import { Unset } from 'utils/types';
import { InactiveLabel } from '../inactive-label';
import style from './index.module.scss';

type Option = Pick<
  Components.Schemas.UserCrmProfile,
  'id' | 'firstName' | 'lastName' | 'userPhoto' | 'isActive' | 'fullName'
>;

interface AvatarIconProps {
  userPhoto?: Unset;
  title?: string;
}
const AvatarIcon: React.FC<AvatarIconProps> = ({ userPhoto, title }) => {
  return <Avatar sx={{ width: 24, height: 24 }} src={userPhoto ?? undefined} title={title} />;
};

interface TemplateEmployeeProps {
  firstName: Unset;
  lastName: Unset;
  userPhoto: Unset;
  width?: string;
  isActive?: boolean;
}
const TemplateEmployee: React.FC<TemplateEmployeeProps> = ({
  firstName,
  lastName,
  userPhoto,
  width = '100%',
  isActive,
}) => {
  const fullName = useMemo(() => makeFullName({ firstName, lastName }), [firstName, lastName]);
  return (
    <Stack title={fullName} spacing={1} direction={'row'} alignItems={'center'} width={width}>
      <AvatarIcon userPhoto={userPhoto} />
      <Typography noWrap flexGrow={1}>
        {fullName}
      </Typography>
      {isActive === false && <InactiveLabel />}
    </Stack>
  );
};

const renderTags = (options: Option[]) => {
  return (
    <div className={style.tags}>
      {options.map((option) => {
        return (
          <TemplateEmployee
            firstName={option.firstName}
            lastName={option.lastName}
            userPhoto={option.userPhoto}
            width={'unset'}
            isActive={true}
          />
        );
      })}
    </div>
  );
};

const renderOptionSingle = (
  props: React.HTMLAttributes<HTMLLIElement | HTMLDivElement>,
  option: Option,
) => {
  return (
    <li {...props}>
      <Box ml={-0.5}>
        <TemplateEmployee
          firstName={option.firstName}
          lastName={option.lastName}
          userPhoto={option.userPhoto}
          isActive={option.isActive}
        />
      </Box>
    </li>
  );
};
const renderOptionMultiple = (
  props: React.HTMLAttributes<HTMLLIElement | HTMLDivElement>,
  option: Option,
  state: { selected: boolean },
) => {
  return (
    <li {...props}>
      <FormControlLabel
        className={style.FormControlLabel}
        control={<Checkbox checked={state.selected} className={style.checkBox} />}
        label={
          <TemplateEmployee
            firstName={option.firstName}
            lastName={option.lastName}
            userPhoto={option.userPhoto}
            isActive={option.isActive}
          />
        }
      />
    </li>
  );
};

const getOptionLabel = (option: Option | string) => {
  if (typeof option === 'string') return option;
  return option.fullName || 'unknown';
};
const filterOptions = <T extends Option>(options: T[], state: any) => {
  return options.filter(
    getSearchFilter<T>({
      value: state.inputValue,
      fields: ['firstName', 'lastName'],
    }),
  );
};

interface Props<
  T extends Record<string, any> = Option,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
> extends Partial<AppSelectProps<T, Multiple, DisableClearable, FreeSolo>> {
  options?: T[];
  disabled?: boolean;
  loading?: boolean;
  label?: React.ReactNode;
}

const useFetchSource = apiUserCrmProfiles.useGetUserCrmProfileSelectSourceQuery;
const SelectGendersComponent = <
  T extends Option,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>(
  { loading, ...rest }: Props<T, Multiple, DisableClearable, FreeSolo>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const source = useFetchSource(undefined, {
    skip: rest.options !== undefined,
  });
  const options = useMemo(() => {
    return (rest.options || source.data || []) as T[];
  }, [rest.options, source.data]);

  const selectedOption = useMemo(() => {
    if (rest.multiple) {
      return undefined;
    }
    return options.find((option) => {
      return option.id === rest.value;
    });
  }, [rest.multiple, options, rest.value]);

  const isLoading = rest.options === undefined ? source.isLoading : loading;
  const disabled = rest.disabled || (rest.options === undefined ? source.isLoading : rest.disabled);

  const updatedOptions = useMemo(() => {
    return (source?.data || []).map((item) => ({ ...item, lastName: `${item.lastName[0]}.` }));
  }, [source]);

  const getRenderInputProps = () => {
    if (rest.multiple) {
      return rest.RenderInputProps;
    }
    if (!selectedOption) {
      return rest.RenderInputProps;
    }
    return {
      ...rest?.RenderInputProps,
      InputProps: {
        ...rest?.RenderInputProps?.InputProps,
        startAdornment: (
          <InputAdornment position={'start'}>
            <AvatarIcon userPhoto={selectedOption.userPhoto} />
          </InputAdornment>
        ),
      },
    };
  };

  return (
    <AppSelect
      ref={ref}
      filterOptions={filterOptions}
      renderOption={rest.multiple ? renderOptionMultiple : renderOptionSingle}
      renderTags={renderTags}
      getOptionLabel={getOptionLabel}
      options={updatedOptions as unknown as T[]}
      {...rest}
      RenderInputProps={getRenderInputProps()}
      disabled={disabled}
      loading={isLoading}
      classes={{ popper: style.popper, option: style.option }}
    />
  );
};

export const SelectEmployees = forwardRef(SelectGendersComponent) as typeof SelectGendersComponent;
