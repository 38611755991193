import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosResponseTransformer,
} from 'axios';
import qs from 'qs';
import * as dynamic from './dynamic';
import { ConditionPartial, PatchPartial } from './types';

export const isServerDateString = <T>(value: T) => {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.?\d{0,7}$/gi.test(String(value));
};

export const axiosDateTransformer: AxiosResponseTransformer = (res) => {
  try {
    return JSON.parse(res, (key, value) => {
      if (typeof value === 'string' && isServerDateString(value)) {
        const _date = Date.parse(value);
        if (_date) {
          return `${value.split('.')[0]}.000Z`;
        }
      }
      return value;
    });
  } catch (e) {
    return res;
  }
};

export const BASE_URL = process.env.REACT_APP_API_SERVER;

export const apiApp = axios.create({
  baseURL: BASE_URL,
  transformResponse: [axiosDateTransformer],
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'brackets', format: 'RFC3986' });
  },
});
export const apiFree = axios.create({
  baseURL: BASE_URL,
  transformResponse: [axiosDateTransformer],
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'brackets', format: 'RFC3986' });
  },
});

// TODO: need added to the project the following code
// TODO: but after that, we need to refactor all requests to catch the error
// const requestTokens: { [key: string]: CancelTokenSource } = {};
//
// const generateRequestKey = (config: AxiosRequestConfig) => {
//   return `${config.url}?${qs.stringify(config.params)}`;
// };
//
// apiApp.interceptors.request.use((config) => {
//   if (config.method?.toUpperCase() === 'GET') {
//     const requestKey = generateRequestKey(config);
//
//     if (requestTokens[requestKey]) {
//       requestTokens[requestKey].cancel('Operation canceled due to new request.');
//     }
//
//     const source = axios.CancelToken.source();
//     config.cancelToken = source.token;
//     requestTokens[requestKey] = source;
//   }
//
//   return config;
// });
//
// apiApp.interceptors.response.use(
//   (response) => {
//     if (response.config.method?.toUpperCase() === 'GET') {
//       const requestKey = generateRequestKey(response.config);
//       delete requestTokens[requestKey];
//     }
//     return response;
//   },
//   (error) => {
//     return Promise.reject(error);
//     // if (axios.isCancel(error)) {
//     //   console.log('Request canceled', error.message);
//     // } else {
//     //   return Promise.reject(error);
//     // }
//   }
// );

export interface DynamicParams {
  select?: string;
  filter?: string;
  orderBy?: string;
  take?: number;
  skip?: number;
  count?: boolean;
}

export type DynamicResult<T extends any, P extends DynamicParams = {}> = P extends {
  count: boolean;
}
  ? { value: T[]; count: number }
  : { value: T[] };

export const axiosBaseQuery =
  (): BaseQueryFn<AxiosRequestConfig, unknown, AxiosError | Error> => async (config) => {
    try {
      const result = await apiApp({
        method: 'get',
        ...config,
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: err,
      };
    }
  };
export const apiRtk = createApi({
  reducerPath: 'apiRtk',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'Customers',
    'Labels',
    'Languages',
    'Projects',
    'ProjectTypes',
    'ProjectStatuses',
    'ProjectAlerts',
    'ProjectPriceLists',
    'ProjectWikiItems',
    'ProjectTimeTracking',
    'UserCrmProfilePermissions',
    'UserCrmProfiles',
    'TimeTracking',
    'ProjectWikiSubjects',
    'UserCrmProfileMonthlyPayments',
    'ProjectMonthlyPayments',
    'DashboardReports',
    'ExpensesCategories',
    'Suppliers',
    'Expenses',
    'MediaUploads',
    'MonthlyActivityReports',
    'TimeTrackingActivities',
    'Tickets',
    'TicketStatuses',
    'TicketTypes',
    'Priorities',
    'TicketActionItems',
    'TicketComments',
    'TicketActionItemComments',
    'TicketActionItemStatuses',
    'TicketActionItemsHelper',
    'Reports',
    'DoneItems',
    'ReportDoneItems',
    'Notes',
    'Notifications',
    'NotificationUserProfiles',
    'NotificationsCurrentUser',
  ],
  endpoints: () => ({}),
});

export const isAxiosError = (error: any): error is AxiosError => {
  return 'isAxiosError' in error;
};
export const parseErrorData = <T = string>(error: AxiosError<T> | Partial<Error>) => {
  if (!error) {
    return new Error('error');
  }
  if (isAxiosError(error)) {
    const errorData = error.response?.data;

    if (!errorData) {
      return new Error('error');
    }

    if (typeof errorData === 'string') {
      return new Error(errorData);
    }
    return { message: 'error', ...errorData };
  }
  return new Error(error.message);
};

export const isRejectedMutation = <T>(mutationResult: any): mutationResult is { error: T } => {
  return Boolean(mutationResult && mutationResult.error);
};
export const isFulfilledMutation = <T>(mutationResult: any): mutationResult is { data: T } => {
  return Boolean(
    mutationResult && mutationResult.hasOwnProperty && mutationResult.hasOwnProperty('data'),
  );
};

export const transformResponseDynamic = <T extends { value: any[] }>(data: T) => {
  return data.value;
};
export const transformResponseDynamicItem = <T extends { value: any[] }>(data: T) => {
  const item = data.value[0];
  if (!item) {
    throw new Error('record-not-found');
  }
  return item;
};

interface PaginationOptions {
  take: number;
  page: number;
  count: number;
}

export const calcPaginationSkip = ({ page, take }: Pick<PaginationOptions, 'take' | 'page'>) => {
  return take * (page - 1);
};
export const calcPaginationPages = ({ count, take }: Pick<PaginationOptions, 'take' | 'count'>) => {
  return Math.ceil(count / take);
};
export const calcPaginationState = ({ take, page, count }: PaginationOptions) => {
  const skip = calcPaginationSkip({ take, page });
  const pages = calcPaginationPages({ take, count });
  const isLastPage = pages === page;
  const isFirstPage = page === 1;
  return {
    take,
    page,
    count,
    pages,
    skip,
    isFirstPage,
    isLastPage,
  };
};

const prepareFieldWithID = (value: any, key: any) => {
  return value === '' && String(key).endsWith('ID') ? null : value;
};

export const prepareRequestData = <T extends { [x: string]: any | null } = {}>(data: T) => {
  const keys = Object.keys(data) as (keyof T)[];
  return keys.reduce((acc, key) => {
    const value = acc[key];
    acc[key] = prepareFieldWithID(value, key);
    return acc;
  }, data);
};

interface DynamicModel {
  id: string;
}

interface DynamicServiceOptions<M> {
  engine?: AxiosInstance;
  getAll: string;
  post: string;
  patch: (data: PatchPartial<M, keyof M>) => string;
  delete: (data: PatchPartial<M, keyof M>) => string;
}

export class DynamicService<M = DynamicModel> {
  public engine: AxiosInstance = apiApp;
  public urlGetAll: string;
  public urlPost: string;
  public urlPatch: (data: PatchPartial<M, keyof M>) => string;
  public urlDelete: (data: PatchPartial<M, keyof M>) => string;
  public mainField: string;

  constructor(
    options: DynamicServiceOptions<M> & ConditionPartial<M, DynamicModel, { mainField: keyof M }>,
  ) {
    const { getAll, patch, post, engine = this.engine, mainField } = options;

    this.mainField = String(mainField || 'id');

    this.engine = engine;

    this.urlGetAll = getAll;
    this.urlPatch = patch;
    this.urlPost = post;
    this.urlDelete = options.delete;

    this.getAllDynamic = this.getAllDynamic.bind(this);
    this.getDynamic = this.getDynamic.bind(this);
    this.patch = this.patch.bind(this);
    this.post = this.post.bind(this);
    this.delete = this.delete.bind(this);
  }

  async getAllDynamic<Model = M, Params extends DynamicParams = DynamicParams>(params?: Params) {
    return this.engine.get<DynamicResult<Model, Params>>(this.urlGetAll, { params });
  }

  async getDynamic<Model = M, Params extends DynamicParams = DynamicParams>(
    id: string,
    params?: Params,
  ) {
    const result = await this.getAllDynamic<Model>({
      ...params,
      filter: dynamic
        .mergeFilters(dynamic.makeFilter(this.mainField as any, id, dynamic.equals), params?.filter)
        .join('&&'),
      take: 1,
    });
    return this.transformDynamicResponseToItem<Model>(result);
  }

  async patch(data: Partial<M>) {
    return this.engine.patch(this.urlPatch(data as any), { ...data, [this.mainField]: undefined });
  }

  async post(data: Partial<M>) {
    return this.engine.post<M>(this.urlPost, { ...data, [this.mainField]: undefined });
  }

  async delete(data: Partial<M>) {
    return this.engine.delete<any, AxiosResponse<M>>(this.urlDelete(data as any));
  }

  transformDynamicResponseToItem = <T>(response: AxiosResponse<DynamicResult<T>>) => {
    const res = this.transformDynamicResponseToItemMaybe<T>(response);

    if (!res.data) {
      throw new Error('record-not-found');
    }

    return res as AxiosResponse<T>;
  };
  transformDynamicResponseToItemMaybe = <T>(response: AxiosResponse<DynamicResult<T>>) => {
    const item = response.data.value[0];

    if (!item) {
      return { ...response, data: null };
    }

    return { ...response, data: item };
  };
}

interface MoveRowsOptions<T extends Record<string, any>> {
  mainField: keyof T;
  moveField: keyof T;
  requestPatch: (data: Partial<T>) => void;
  newRows: Partial<T>[];
  oldRows: Partial<T>[];
}

export const behaviourMoveRows = async <T extends Record<string, any>>(
  options: MoveRowsOptions<T>,
) => {
  const { newRows, oldRows, requestPatch } = options;
  return Promise.all(
    newRows.map((oldRecord, i) => {
      return requestPatch({
        [options.mainField]: oldRecord[options.mainField],
        [options.moveField]: oldRows[i][options.moveField],
      } as Partial<T>);
    }),
  );
};
