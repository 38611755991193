import { APP_ROUTES } from 'configs';
import { useAppDispatch } from 'hooks';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { actionCheckDoneAllFetchItem } from 'store/check-done-all';
import { actionCheckDoneCompletedFetchItem } from 'store/check-done-completed';
import { actionCheckDoneNotCompletedFetchItem } from 'store/check-done-not-completed';
import { actionCheckDoneUnAssignFetchItem } from 'store/check-done-un-assign';

export const useCheckDoneFetchItem = (ticketActionItemID: string | undefined | null) => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  return useCallback(() => {
    if (!ticketActionItemID) {
      return;
    }

    switch (pathname) {
      case APP_ROUTES.CHECK_DONE.path:
        dispatch(actionCheckDoneNotCompletedFetchItem(ticketActionItemID));
        return;
      case APP_ROUTES.CHECK_DONE_COMPLETED.path:
        dispatch(actionCheckDoneCompletedFetchItem(ticketActionItemID));
        return;
      case APP_ROUTES.CHECK_DONE_UN_ASSIGN.path:
        dispatch(actionCheckDoneUnAssignFetchItem(ticketActionItemID));
        return;
      case APP_ROUTES.CHECK_DONE_ALL.path:
        dispatch(actionCheckDoneAllFetchItem(ticketActionItemID));
        return;
    }
  }, [dispatch, pathname, ticketActionItemID]);
};
