import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Box, FormControl, FormHelperText, Typography } from '@mui/material';
import React, {
  ForwardedRef,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { getFileInfo } from 'utils/file-uploader';

import { useOpenValue } from 'hooks';
import {
  useFileUploader,
  useFileUploaderParseError,
  UseFileUploaderProps,
} from 'hooks/use-file-uploader';
import { useDragLayer } from 'react-dnd';
import { ValueFileUploader } from 'utils/file-uploader';
import { DialogImagesPreview } from '../dialog-images-preview';
import { DialogInsert } from './components/dialog-insert';
import { SlotDndWrapper } from './components/slot-dnd-wrapper';
import { SlotImage } from './components/slot-image';
import { SlotNew } from './components/slot-new';
import { makeTitle } from './helpers';
import { ImageData, ImageValue } from './models';

export { makeTitle } from './helpers';

type MoveableValue<V extends ImageValue> = V & { __id: string };

export type AttachmentInputHandle = {
  addFiles: (files: File[]) => void;
};

interface Props<V> {
  value?: V[];
  onChange?: (value: V[]) => void;
  disabled?: boolean;
  label?: React.ReactNode;
  error?: boolean;
  helperText?: React.ReactNode;
  limit?: number;
}
const AttachmentInputComponent = <V extends ImageValue>(
  {
    label,
    value: _value,
    onChange: _onChange,
    disabled,
    error: _error,
    helperText: _helperText,
    limit = 5,
  }: Props<V>,
  ref: ForwardedRef<AttachmentInputHandle>,
) => {
  const value = useMemo(() => _value || [], [_value]);
  const dndType = useId();
  const onChange = useCallback(
    (value: V[]) => {
      return _onChange && _onChange(value.slice(0, limit));
    },
    [_onChange, limit],
  );

  const onInsert = (values: ImageValue[]) => {
    onChange([...values, ...value] as Array<V>);
  };

  const factoryRemove = (index: number) => {
    return () => onChange(value.filter((_, i) => i !== index));
  };
  const factoryUpdated = (index: number) => {
    return (formData: ImageData) =>
      onChange(value.map((item, i) => (i === index ? { ...item, ...formData } : item)));
  };

  const [previewIndex, previewIndexActions] = useOpenValue<number>();
  const previewImages = useMemo(() => {
    return value.map((image) => ({
      picture: getFileInfo(image.image).fileUrl,
      title: makeTitle(image),
    }));
  }, [value]);
  const factoryPreview = (index: number) => {
    return () => previewIndexActions.set(index);
  };

  const [moveableValue, setMoveableValue] = useState<MoveableValue<V>[]>([]);
  useEffect(() => {
    setMoveableValue(value.map((item, index) => ({ __id: String(item.id || index), ...item })));
  }, [value]);

  const move = useCallback((dragIndex: number, hoverIndex: number) => {
    setMoveableValue((prev) => {
      let copy = [...prev];

      const drugItem = prev[dragIndex];
      const hoverItem = prev[hoverIndex];

      copy[hoverIndex] = drugItem;
      copy[dragIndex] = hoverItem;

      return copy;
    });
  }, []);
  const moveEnd = useCallback(() => {
    onChange(moveableValue.map(({ __id, ...rest }) => rest as unknown as V));
  }, [moveableValue, onChange]);

  const [insertItems, insertActions] = useOpenValue<Array<ValueFileUploader>>();
  const onChangeInput = useCallback(
    (values: ValueFileUploader[]) => {
      insertActions.set(values);
    },
    [insertActions],
  );

  const options: Pick<UseFileUploaderProps, 'maxSize' | 'accept'> = {
    accept: ['.jpeg', '.png', '.jpg', '.webp'],
    maxSize: 3,
  };

  const getErrorMessage = useFileUploaderParseError(options);
  const { inputProps, onOpen, onAddFiles, errors } = useFileUploader({
    multiple: true,
    onChange: onChangeInput,
    ...options,
  });

  const insert = (formData: ImageData) => {
    if (!insertItems) return;
    onInsert(insertItems.map((fileValue, i) => ({ ...formData, image: fileValue, rank: i + 1 })));
    insertActions.close();
  };

  const fileError = errors[0];

  const error = !!fileError || _error;
  const helperText = fileError ? getErrorMessage(fileError.error) : _helperText;

  useImperativeHandle(
    ref,
    () => {
      return {
        addFiles: onAddFiles,
        focus: () => {},
      };
    },
    [onAddFiles],
  );

  const { isDragging } = useDragLayer((monitor) => {
    return { isDragging: monitor.isDragging() };
  });

  return (
    <>
      <input {...inputProps} />
      <FormControl error={error} fullWidth margin={'none'}>
        {label && (
          <>
            <Typography
              color={!error ? 'primary' : 'error'}
              display={'flex'}
              alignItems={'center'}
              component={'div'}
              fontWeight={600}
              textTransform={'uppercase'}
              letterSpacing={1.25}
              gap={0.6}
              mb={0.6}
            >
              <AttachFileIcon /> {label}
            </Typography>
          </>
        )}

        <Box gap={1} display={'flex'} flexWrap={'wrap'} width={'100%'}>
          {moveableValue.length < limit && (
            <SlotNew disabled={disabled} onClick={onOpen} onDrop={onAddFiles} isError={error} />
          )}

          {moveableValue.map((imageEntity, i) => {
            return (
              <SlotDndWrapper
                key={imageEntity.__id}
                index={i}
                type={dndType}
                move={move}
                moveEnd={moveEnd}
              >
                <SlotImage
                  id={imageEntity.id}
                  disabled={disabled}
                  url={imageEntity.image}
                  entryDate={imageEntity.entryDate}
                  description={imageEntity.description}
                  userCrmProfileID={imageEntity.userCrmProfileID}
                  hideControls={isDragging}
                  onRemove={factoryRemove(i)}
                  onPreview={factoryPreview(i)}
                  onUpdated={factoryUpdated(i)}
                />
              </SlotDndWrapper>
            );
          })}
        </Box>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
      {typeof previewIndex === 'number' && (
        <DialogImagesPreview
          initialIndex={previewIndex}
          images={previewImages}
          onClose={previewIndexActions.close}
        />
      )}
      {insertItems && <DialogInsert onClose={insertActions.close} onInsert={insert} />}
    </>
  );
};

export const AttachmentInput = memo(forwardRef(AttachmentInputComponent));
