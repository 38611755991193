import { GridModel } from './models';

export const isLocked = (row: GridModel) => {
  const report = row.report;
  if (!report) return false;

  return Boolean(report.bill || report.lock);
};

export const isInReport = (row: GridModel) => {
  return row.doneItems.length > 0;
};
