import { createSelector } from '@reduxjs/toolkit';
import { groupBy, sortBy } from 'lodash-es';
import { selectSourceCrmUsersAllMap } from 'services/user-crm-profiles';
import { isAcrossIntervals } from 'utils/dates';
import {
  selectCheckDoneTicketsSelectedID,
  selectTicketsDataFilteredMap,
  selectTicketsDataFullMap,
} from '../check-done-tickets';
import { AppState } from '../index';

const selectState = (state: AppState) => state.checkDoneTrash;

export const selectCheckDoneTrashStatus = createSelector(
  selectState,
  ({ isLoading, error, isInit }) => {
    return { isLoading, error, isInit };
  },
);

export const selectCheckDoneTrashFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});

export const selectCheckDoneTrashHasFilters = createSelector(
  selectCheckDoneTrashFilters,
  (filters) => {
    return [
      filters.search,
      filters.reporterUserCrmProfileID,
      filters.assigneeUserCrmProfileIDs.length,
      filters.dateRange.length,
      filters.ticketActionStatusID,
    ].some(Boolean);
  },
);

export const selectCheckDoneTrashData = createSelector(selectState, ({ data }) => {
  return data;
});

const selectCheckDoneTrashDataFilteredByTickets = createSelector(
  selectCheckDoneTrashData,
  selectTicketsDataFilteredMap,
  (ticketActionItems, ticketsMap) => {
    return ticketActionItems.filter((ticketActionItem) => {
      return !!ticketsMap[ticketActionItem.ticketID];
    });
  },
);

const selectCheckDoneTrashDataFull = createSelector(
  selectCheckDoneTrashDataFilteredByTickets,
  selectSourceCrmUsersAllMap,
  (ticketItems, mapUsers) => {
    return ticketItems.map((ticketItem) => {
      return {
        ...ticketItem,
        _reporter: mapUsers[ticketItem.reporterUserCrmProfileID],
        _assigner: mapUsers[ticketItem.assigneeUserCrmProfileID],
      };
    });
  },
);

const selectCheckDoneTrashDataFiltered = createSelector(
  selectCheckDoneTrashDataFull,
  selectCheckDoneTrashFilters,
  selectCheckDoneTicketsSelectedID,
  (items, filters, selectedTicketID) => {
    const {
      search,
      reporterUserCrmProfileID,
      assigneeUserCrmProfileIDs,
      dateRange,
      ticketActionStatusID,
    } = filters;

    return items.filter((item) => {
      const res: boolean[] = [true];

      if (selectedTicketID) {
        res.push(item.ticketID === selectedTicketID);
      }

      if (search) {
        res.push(
          [
            item.title.includes(search),
            item._reporter?.fullName.includes(search),
            item._assigner?.fullName.includes(search),
            item.itemKey?.includes(search),
          ].some(Boolean),
        );
      }

      if (reporterUserCrmProfileID) {
        res.push(reporterUserCrmProfileID === item.reporterUserCrmProfileID);
      }

      if (assigneeUserCrmProfileIDs.length) {
        res.push(assigneeUserCrmProfileIDs.some((id) => id === item.assigneeUserCrmProfileID));
      }

      if (ticketActionStatusID) {
        res.push(ticketActionStatusID === item.ticketActionItemStatusID);
      }

      if (dateRange.length === 2) {
        res.push(isAcrossIntervals([item.startDateAndTime, item.endDateAndTime], dateRange));
      }

      return res.every(Boolean);
    });
  },
);

export const selectCheckDoneTrashItemsWithTicket = createSelector(
  selectCheckDoneTrashDataFiltered,
  selectTicketsDataFullMap,
  (actionItems, ticketsMap) => {
    const items = actionItems.map((actionItem) => {
      const ticket = ticketsMap[actionItem.ticketID];
      return {
        ...actionItem,
        _ticket: ticket,
      };
    });
    return sortBy(items, (row) => row._ticket._customer.title);
  },
);

export const selectCheckDoneTrashItemsWithTicketGrouped = createSelector(
  selectCheckDoneTrashItemsWithTicket,
  (ticketActionItems) => {
    const groups = groupBy(ticketActionItems, 'assigneeUserCrmProfileID');

    return Object.entries(groups).map(([assigneeUserCrmProfileID, groupByAssigner]) => {
      const firstItem = groupByAssigner[0];

      return {
        id: assigneeUserCrmProfileID,
        assigner: firstItem._assigner,
        actionItems: groupByAssigner,
      };
    });
  },
);
export const selectCheckDoneTrashView = createSelector(
  selectCheckDoneTrashFilters,
  ({ assigneeUserCrmProfileIDs }) => {
    if (assigneeUserCrmProfileIDs.length > 1) {
      return 'board';
    }
    return 'list';
  },
);
