import {
  Checkbox as MaterialCheckbox,
  CheckboxProps as MaterialCheckboxProps,
} from '@mui/material';
import { forwardRef } from 'react';

export interface AppCheckboxProps
  extends Omit<MaterialCheckboxProps, 'value' | 'checked' | 'onBlur'> {
  value?: boolean;
  onBlur?: () => void;
}

export const AppCheckbox = forwardRef<HTMLInputElement, AppCheckboxProps>(
  ({ value, onChange, onBlur, ...rest }, ref) => {
    return (
      <MaterialCheckbox
        inputRef={ref}
        checked={value}
        onChange={onChange}
        onBlur={onBlur}
        {...rest}
      />
    );
  },
);
