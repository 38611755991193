import { startOfDay } from 'date-fns';
import { ICustomer } from 'services/customers';
import { IUserCrmProfile } from 'services/user-crm-profiles';
import { DynamicOrder } from 'utils/dynamic';
import { FixServerObject, PatchPartial } from 'utils/types';
import * as yup from 'yup';

const API = 'MonthlyActivityReports';

export const API_MONTHLY_ACTIVITY_REPORTS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<IMonthlyActivityReport, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<IMonthlyActivityReport, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IMonthlyActivityReport
  extends FixServerObject<Components.Schemas.MonthlyActivityReport, 'id'> {}

export interface IGridMonthlyActivityReport
  extends Pick<
    IMonthlyActivityReport,
    | 'id'
    | 'date'
    | 'projectName'
    | 'activityType'
    | 'duration'
    | 'billableDuration'
    | 'shortDescription'
    | 'isSent'
  > {
  customerName: ICustomer['companyName'];
  employeePhoto: IUserCrmProfile['userPhoto'];
  employeeName: IUserCrmProfile['nameHeb'];
}

export interface IGridMonthlyActivityReportArgs {
  search: string;
  customerID?: string;
  year: number;
  month: string;
  userCrmProfileID?: string;
  activityType?: string;
  projectName?: string;
  isSent?: null | 'true' | 'false';

  take?: number;
  skip?: number;
  orderBy: DynamicOrder;
}

export const schemaMonthlyActivityReport = yup.object({
  customerID: yup.string().required('rule-required').default(''),
  userCrmProfileID: yup.string().required('rule-required').default(''),
  projectName: yup.string().required('rule-required').default(''),
  activityType: yup.string().default(''),
  date: yup.string().required('rule-required').default(startOfDay(new Date()).toISOString()),
  activityYear: yup.number().integer('rule-integer').default(new Date().getFullYear()),
  activityMonth: yup.number().integer('rule-integer').default(new Date().getMonth()),

  description: yup.string().nullable().notRequired().default(''),
  shortDescription: yup.string().nullable().notRequired().default(''),

  duration: yup.string().required('rule-required').duration('rule-duration').default(''),
  billableDuration: yup.string().nullable().notRequired().default(''),
  isSent: yup.boolean().default(false),
});

export type SchemaMonthlyActivityReport = yup.InferType<typeof schemaMonthlyActivityReport>;

export interface IMonthlyActivityReportPostInput extends Omit<IMonthlyActivityReport, 'id'> {}
export interface IMonthlyActivityReportPatchInput
  extends PatchPartial<IMonthlyActivityReport, 'id'> {}
export interface IMonthlyActivityReportDeleteInput extends Pick<IMonthlyActivityReport, 'id'> {}
