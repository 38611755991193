export type PatchPartial<T, K extends keyof T> = Partial<T> & Pick<T, K>;
export type ArrayType<T> = T extends (infer U)[] ? U : never;
export type ConditionPartial<T, C, Rest> = T extends C ? Partial<Rest> : Rest;
export type PromiseType<T> = T extends Promise<infer U> ? U : never;

export type Primitive = string | null | number | undefined | symbol | boolean;

export type Unset<T = string> = T | null | undefined;

export type Leave<T, Allowed extends unknown = Primitive> = {
  [P in keyof T]: Extract<T[P], Allowed>;
};

export type FixServerObject<T, K extends keyof T> = T &
  Required<{ [P in K]: Exclude<T[P], null | undefined> }>;

export type Defined<T extends Record<string, any>> = Required<{
  [P in keyof T]: Exclude<T[P], null | undefined>;
}>;

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type DeepRequired<T> = T extends object
  ? {
      [P in keyof T]-?: DeepRequired<T[P]>;
    }
  : T;

export type Prev = [
  never,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  ...0[],
];

export type Join<K, P, S extends string = '.'> = K extends string | number
  ? P extends string | number
    ? `${K}${'' extends P ? '' : S}${P}`
    : never
  : never;

export type Paths<T, D extends number = 1> = [D] extends [never]
  ? never
  : T extends object
  ? {
      [K in keyof T]-?: K extends string | number
        ? `${K}` | Join<K, Paths<T[K], Prev[D]>, '.'>
        : never;
    }[keyof T]
  : '';

export type Leaves<T, D extends number = 1> = [D] extends [never]
  ? never
  : T extends object
  ? { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T]
  : '';

export const enumToArray = <T extends Record<string, any>>(en: T) => {
  return Object.entries(en)
    .filter(([key]) => !isFinite(key as any))
    .map(([key, val]) => ({
      id: val,
      title: key,
    })) as { id: T[keyof T]; title: string }[];
};

export type KeyOf<T> = keyof T;
export type ValueOf<T, K extends keyof T> = T[K];
