import { useContext } from 'react';
import { SelectedContext } from '../components/selected-provider';

export const useSelected = () => {
  const context = useContext(SelectedContext);

  if (!context) {
    throw new Error('SelectedContext: is required');
  }

  return context;
};
