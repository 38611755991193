import { Container } from '@mui/material';
import { Footer, Header } from 'layouts/private/components';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import style from './index.module.scss';

export const PrivateLayout = memo(() => {
  return (
    <div className={style.root}>
      <Header />
      <Container maxWidth={false}>
        <Outlet />
      </Container>
      <Footer />
    </div>
  );
});
