import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Divider, Drawer, IconButton, Stack } from '@mui/material';
import { SvgLogo } from 'components/svg-icons/logo';
import { useOpen } from 'hooks';
import React from 'react';
import { HeaderNavigation } from '../header-navigation';
import style from './index.module.scss';

interface Props {}

export const HeaderMobileNavigation: React.FC<Props> = () => {
  const { isOpen, onClose, onOpen } = useOpen();
  return (
    <>
      <IconButton color={'inherit'} onClick={onOpen}>
        <MenuIcon />
      </IconButton>
      <Drawer open={isOpen} onClose={onClose} classes={{ paper: style.drawer }} color={'primary'}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mr={-1}>
          <SvgLogo sx={{ width: 101, height: 36 }} />
          <IconButton color={'inherit'} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider
          orientation={'horizontal'}
          sx={{ borderColor: 'rgba(255,255,255,0.5)', margin: '1.6rem -1.6rem' }}
        />
        <HeaderNavigation isVertical onChanged={onClose} />
      </Drawer>
    </>
  );
};
