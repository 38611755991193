import { usePermissions } from 'hooks';
import React from 'react';
import { TicketActionItemDurationDialogNew } from '../editor-new';
import { TicketActionItemDurationDialogNewExtended } from '../editor-new-extended';
import { TicketActionItemDurationDialogRegular } from '../editor-regular';

interface Props {
  itemID: string | undefined;
  ticketActionItemID: string;
  onClose: () => void;
}
export const Editor: React.FC<Props> = ({ itemID, onClose, ticketActionItemID }) => {
  const { isAdmin } = usePermissions();

  const ComponentNew = isAdmin
    ? TicketActionItemDurationDialogNewExtended
    : TicketActionItemDurationDialogNew;

  if (!itemID) return <ComponentNew ticketActionItemID={ticketActionItemID} onClose={onClose} />;

  return (
    <TicketActionItemDurationDialogRegular
      itemID={itemID}
      ticketActionItemID={ticketActionItemID}
      onClose={onClose}
    />
  );
};
