import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { AppInputTitle } from 'components/app-input-title';
import { ButtonCancel, ButtonCreate } from 'components/buttons';
import { DialogHeader } from 'components/dialog-header';
import { BaseBreadcrumbs } from 'components/tickets';
import {
  useCurrentUser,
  useEffectNotifyError,
  useFieldProps,
  useSourcePriorities,
  useSourceTicketItemActionStatuses,
  useTranslate,
} from 'hooks';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  apiTicketActionItems,
  ITicketActionItem,
  schemaTicketActionItem,
} from 'services/ticket-action-items';
import { apiTickets } from 'services/tickets';
import { isFulfilledMutation } from 'utils/service';
import { DeepPartial } from 'utils/types';
import { InferType } from 'yup';
import { useTicketInformation } from '../../../../hooks/use-ticket-information';
import { Form, Meta } from '../form';

const schema = schemaTicketActionItem;
const usePostMutation = apiTicketActionItems.usePostTicketActionItemMutation;
const useFetchTicketQuery = apiTickets.useGetTicketQuery;
export type Model = InferType<typeof schema>;

const findDefault = (option: { isDefault?: boolean }) => option.isDefault;

interface Props {
  ticketID: string;
  initValues?: Partial<Model>;
  onClose: () => void;
  onDone?: (item: ITicketActionItem) => void;
}

export const TicketActionItemDialogNew = memo<Props>(
  ({ ticketID, initValues, onClose, onDone }) => {
    const resultTicket = useFetchTicketQuery(ticketID);
    useEffectNotifyError(resultTicket.error);

    const ticketInfo = useTicketInformation(ticketID);
    useEffectNotifyError(ticketInfo.error);

    const { ticket, customer } = ticketInfo;

    const breadcrumbs = useMemo(
      () => [
        ...(customer ? [{ label: customer.title }] : []),
        ...(ticket ? [{ label: ticket.title }] : []),
      ],
      [customer, ticket],
    );

    const { tp } = useTranslate();
    const user = useCurrentUser();
    const methods = useForm({
      defaultValues: schema.cast(
        {
          reporterUserCrmProfileID: user.appUserID,
          assigneeUserCrmProfileID: user.appUserID,
          ...initValues,
          ticketID,
        },
        { assert: false },
      ),
      resolver: yupResolver(schema),
      shouldUnregister: false,
    });
    const { handleSubmit, setValue, getValues, control } = methods;
    const getFieldProps = useFieldProps();

    const [postItem, resultPost] = usePostMutation();
    useEffectNotifyError(resultPost.error);

    const isLoading = resultTicket.isFetching || resultPost.isLoading;

    const onSubmit = useCallback(
      async (formData: Model) => {
        const result = await postItem({ ...formData, entryDate: formData.entryDate ?? undefined });
        if (isFulfilledMutation(result)) {
          onClose();
          onDone && onDone(result.data);
        }
      },
      [postItem, onClose, onDone],
    );

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const isDoneDisabled = ticket?.mustTrackTime;

    const meta = useMemo<DeepPartial<Meta>>(() => {
      return {
        disabled: {
          done: isDoneDisabled,
          reporterUserCrmProfileID: ticket?.isPrivate,
          assigneeUserCrmProfileID: ticket?.isPrivate,
        },
      };
    }, [ticket, isDoneDisabled]);

    useEffect(() => {
      if (!ticket) return;
      if (!ticket.isPrivate) return;

      setValue('reporterUserCrmProfileID', ticket.ownerUserCrmProfileID);
      setValue('assigneeUserCrmProfileID', ticket.ownerUserCrmProfileID);
    }, [ticket, setValue]);

    // set default priority
    const sourcePriorities = useSourcePriorities();
    useEffect(() => {
      const options = sourcePriorities.data;
      const val = getValues('priorityID');

      if (!options) return;

      if (val) return;
      const entity = options.find(findDefault);
      if (!entity) return;

      setValue('priorityID', entity.id);
    }, [sourcePriorities.data, setValue, getValues]);

    const showReportDescription =
      ticket?.mustTrackTime && user.appUserID === ticket?.ownerUserCrmProfileID;

    // set default status
    const sourceStatuses = useSourceTicketItemActionStatuses();
    useEffect(() => {
      const options = sourceStatuses.data;
      const val = getValues('ticketActionItemStatusID');

      if (!options) return;

      if (val) return;

      const entity = options.find(findDefault);
      if (!entity) return;

      setValue('ticketActionItemStatusID', entity.id);
    }, [sourceStatuses.data, setValue, getValues]);

    return (
      <Dialog open={true} fullWidth maxWidth={'lg'} fullScreen={isMobile}>
        <DialogHeader
          bColor={customer?.color}
          isLoading={isLoading}
          title={tp('create-new')}
          onClose={onClose}
        />
        <DialogContent>
          <BaseBreadcrumbs isLoading={ticketInfo.isFetching} items={breadcrumbs} />
          <FormProvider {...methods}>
            <Controller
              control={control}
              name={'title'}
              render={(renderProps) => {
                const props = getFieldProps(renderProps);

                return (
                  <AppInputTitle
                    value={props.value}
                    onChange={props.onChange}
                    error={props.error}
                    helperText={props.helperText}
                    placeholder={tp('ticket-action-item-placeholder')}
                  />
                );
              }}
            />
            <Collapse in={isDoneDisabled}>
              <Alert severity={'error'} sx={{ mb: 1 }}>
                {tp('note-must-track-time')}
              </Alert>
            </Collapse>
            <Form
              isLoading={isLoading}
              meta={meta}
              disabled={false}
              showReportDescription={showReportDescription}
            />
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <ButtonCancel onClick={onClose}>{tp('cancel')}</ButtonCancel>
          <ButtonCreate disabled={isLoading} onClick={handleSubmit(onSubmit)}>
            {tp('create')}
          </ButtonCreate>
        </DialogActions>
      </Dialog>
    );
  },
);
