import { BaseCommentNew, FormData } from 'components/tickets';
import { useCurrentUser } from 'hooks';
import React, { useCallback } from 'react';
import { apiTicketActionItemComments } from 'services/ticket-action-item-comments';
import { isFulfilledMutation } from 'utils/service';

const usePostMutation = apiTicketActionItemComments.usePostTicketActionItemCommentMutation;

interface Props {
  disabled: boolean;
  ticketActionItemID: string | undefined;
  init: Partial<FormData>;
}
export const TicketActionItemCommentNewContainer: React.FC<Props> = ({
  ticketActionItemID,
  disabled,
  init,
}) => {
  const { appUserID } = useCurrentUser();
  const [postItem, resultPost] = usePostMutation();
  const onCreate = useCallback(
    async (formData: FormData) => {
      if (!ticketActionItemID) return false;

      const res = await postItem({
        description: formData.description,
        ticketActionItemID,
        userCrmProfileID: appUserID,
        images: formData.images || [],
        notificationUserCrmProfileIDs: formData.notificationUserCrmProfileIDs,
      });

      return isFulfilledMutation(res);
    },
    [ticketActionItemID, postItem, appUserID],
  );

  return (
    <BaseCommentNew init={init} disabled={resultPost.isLoading || disabled} onSubmit={onCreate} />
  );
};
