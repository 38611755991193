import { durationToMinutes } from 'utils/app-helpers';
import { TrackingItem } from './models';

export const calcTrackingData = (tracking: Array<TrackingItem>) => {
  let countAttached = 0;
  const count = tracking.length;

  let durationInMinutes = 0;
  let durationAttachedInMinutes = 0;

  for (let track of tracking) {
    durationInMinutes += durationToMinutes(track.duration);
    durationAttachedInMinutes += track.isAttached ? durationToMinutes(track.duration) : 0;

    if (track.isAttached) {
      countAttached++;
    }
  }

  return { count, durationInMinutes, countAttached, durationAttachedInMinutes };
};
