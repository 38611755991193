import { FormControl, FormControlProps, FormHelperText, InputLabel } from '@mui/material';
import { Editor, IAllProps } from '@tinymce/tinymce-react';
import clsx from 'clsx';
import React, { ForwardedRef, forwardRef, memo } from 'react';
import { RawEditorOptions } from 'tinymce';
import { pastImageProcessor } from '../../modules/tinymce/past-image-processor';
import style from './index.module.scss';

const TINYMCE_SCRIPT_SRC = process.env.PUBLIC_URL + '/tinymce/tinymce.min.js';

export type InitOptions = RawEditorOptions & {
  selector?: undefined;
  target?: undefined;
};

export type { IAllProps };

export const DEFAULT_INIT: InitOptions = {
  formats: {
    removeformat: [
      {
        selector: '*',
        remove: 'all',
        split: true,
        block_expand: true,
        expand: false,
        deep: true,
      },
    ],
  },
  file_picker_types: 'file image media',
  paste_as_text: true,
  menubar: false,
  statusbar: false,
  default_link_target: '_blank',
  plugins:
    'autolink code image link media template codesample pagebreak nonbreaking anchor advlist lists directionality',
  toolbar: 'removeformat bold italic underline | numlist bullist checklist | link | ltr rtl',
  paste_postprocess: pastImageProcessor,
};

export interface HtmlEditorProps extends Omit<FormControlProps, 'value' | 'onBlur' | 'onChange'> {
  label?: React.ReactNode;
  value: string | null;
  onChange?: (v: string) => void;
  onBlur?: () => void;
  error?: boolean;
  disabled?: boolean;
  helperText?: React.ReactNode;
  editorProps?: IAllProps;
}

const Component = (props: HtmlEditorProps, ref: ForwardedRef<HTMLInputElement>) => {
  const {
    value,
    onChange,
    onBlur,
    error,
    label,
    disabled,
    helperText,
    editorProps,
    children,
    ...rest
  } = props;

  return (
    <FormControl
      error={error}
      {...rest}
      className={clsx(style.root, rest?.className, label && style.rootLabel)}
      ref={ref}
    >
      <InputLabel shrink variant={'standard'}>
        {label}
      </InputLabel>
      <Editor
        tinymceScriptSrc={TINYMCE_SCRIPT_SRC}
        value={value || ''}
        onEditorChange={onChange}
        disabled={disabled}
        onBlur={onBlur}
        init={DEFAULT_INIT}
        {...editorProps}
      />
      {children}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export const HtmlEditor = memo(forwardRef(Component)) as typeof Component;
