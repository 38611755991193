import { FormControlLabel, FormControlLabelProps, Switch, SwitchProps } from '@mui/material';
import { forwardRef } from 'react';

export interface AppSwitcherProps extends Omit<SwitchProps, 'value' | 'checked' | 'onBlur'> {
  label?: FormControlLabelProps['label'];
  value?: boolean;
  onBlur?: () => void;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
}

export const AppSwitch = forwardRef<HTMLInputElement, AppSwitcherProps>(
  ({ value, label, labelPlacement = 'start', onChange, onBlur, ...rest }, ref) => {
    return (
      <FormControlLabel
        control={
          <Switch
            color={'success'}
            inputRef={ref}
            checked={value}
            onChange={onChange}
            onBlur={onBlur}
            {...rest}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
      />
    );
  },
);
