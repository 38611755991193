import { createSelector } from '@reduxjs/toolkit';
import { groupBy } from 'lodash-es';
import { calcTrackingData } from 'services/ticket-action-items';
import { selectSourceCrmUsersAllMap } from 'services/user-crm-profiles';
import { isAcrossIntervals } from 'utils/dates';
import {
  selectCheckDoneTicketsSelectedID,
  selectTicketsDataFiltered,
  selectTicketsDataFilteredMap,
  selectTicketsDataFullMap,
} from '../check-done-tickets';
import { AppState } from '../index';

const selectState = (state: AppState) => state.checkDoneCompleted;

export const selectCheckDoneCompletedStatus = createSelector(
  selectState,
  ({ isLoading, error, isInit }) => {
    return { isLoading, error, isInit };
  },
);

export const selectCheckDoneCompletedFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});

export const selectCheckDoneCompletedHasFilters = createSelector(
  selectCheckDoneCompletedFilters,
  (filters) => {
    return [
      filters.search,
      filters.reporterUserCrmProfileID,
      filters.assigneeUserCrmProfileIDs.length,
      filters.dateRange.length,
      filters.review,
    ].some(Boolean);
  },
);

export const selectCheckDoneCompletedData = createSelector(selectState, ({ data }) => {
  return data;
});
const selectCheckDoneCompletedDataFull = createSelector(
  selectCheckDoneCompletedData,
  selectSourceCrmUsersAllMap,
  (ticketItems, mapUsers) => {
    return ticketItems.map((ticketItem) => {
      const { durationInMinutes, countAttached, count } = calcTrackingData(ticketItem.tracking);
      return {
        ...ticketItem,
        _reporter: mapUsers[ticketItem.reporterUserCrmProfileID],
        _assigner: mapUsers[ticketItem.assigneeUserCrmProfileID],
        _timeTrackingInMinutes: durationInMinutes,
        _timeTrackingAttachedCount: countAttached,
        _timeTrackingCount: count,
      };
    });
  },
);

const selectCheckDoneCompletedDataFiltered = createSelector(
  selectCheckDoneCompletedDataFull,
  selectCheckDoneCompletedFilters,
  selectCheckDoneTicketsSelectedID,
  (items, filters, selectedTicketID) => {
    const { search, reporterUserCrmProfileID, assigneeUserCrmProfileIDs, dateRange, review } =
      filters;

    return items.filter((item) => {
      const res: boolean[] = [true];

      if (selectedTicketID) {
        res.push(item.ticketID === selectedTicketID);
      }

      if (search) {
        res.push(
          [
            item.title.includes(search),
            item._reporter?.fullName.includes(search),
            item._assigner?.fullName.includes(search),
            item.itemKey?.includes(search),
          ].some(Boolean),
        );
      }

      if (reporterUserCrmProfileID) {
        res.push(reporterUserCrmProfileID === item.reporterUserCrmProfileID);
      }

      if (assigneeUserCrmProfileIDs.length) {
        res.push(assigneeUserCrmProfileIDs.some((id) => id === item.assigneeUserCrmProfileID));
      }

      if (review === 'all') {
        res.push(true);
      } else if (review === 'active') {
        res.push(item.review === true);
      } else if (review === 'not-active') {
        res.push(item.review === false);
      }

      if (dateRange.length === 2) {
        res.push(isAcrossIntervals([item.startDateAndTime, item.endDateAndTime], dateRange));
      }

      return res.every(Boolean);
    });
  },
);

const selectTicketsActionItemsDataFilteredByTickets = createSelector(
  selectCheckDoneCompletedDataFiltered,
  selectTicketsDataFilteredMap,
  (ticketActionItems, ticketsMap) => {
    return ticketActionItems.filter((ticketActionItem) => {
      return !!ticketsMap[ticketActionItem.ticketID];
    });
  },
);

export const selectCheckDoneCompletedDataGroupByAssignerAndTicket = createSelector(
  selectTicketsActionItemsDataFilteredByTickets,
  selectTicketsDataFullMap,
  (ticketActionItems, ticketsMap) => {
    const groups = groupBy(ticketActionItems, 'assigneeUserCrmProfileID');

    return Object.entries(groups).map(([assigneeUserCrmProfileID, groupByAssigner]) => {
      const firstItem = groupByAssigner[0];
      const groupsByTickets = groupBy(groupByAssigner, 'ticketID');

      return {
        id: assigneeUserCrmProfileID,
        assigner: firstItem._assigner,
        tickets: Object.entries(groupsByTickets).map(([ticketID, groupByTicket]) => {
          const ticketActionItems = Object.values(groupByTicket);
          return {
            id: ticketID,
            ticket: ticketsMap[ticketID],
            ticketActionItems: ticketActionItems,
          };
        }),
      };
    });
  },
);

export const selectCheckDoneCompletedWithActionItems = createSelector(
  selectTicketsDataFiltered,
  selectCheckDoneCompletedDataFiltered,
  (tickets, actionItems) => {
    const groupOfActionItems = groupBy(actionItems, 'ticketID');

    return tickets.map((ticket) => {
      const actionItems = groupOfActionItems[ticket.id] || [];

      return {
        ...ticket,
        _actionItems: actionItems,
      };
    });
  },
);

export const selectCheckDoneCompletedTicketsWithActionItemsList = createSelector(
  selectCheckDoneCompletedWithActionItems,
  selectCheckDoneCompletedHasFilters,
  selectCheckDoneTicketsSelectedID,
  (tickets) => {
    return tickets.filter((ticket) => ticket._actionItems.length);
  },
);

export const selectCheckDoneCompletedView = createSelector(
  selectCheckDoneCompletedFilters,
  ({ assigneeUserCrmProfileIDs }) => {
    if (assigneeUserCrmProfileIDs.length > 1) {
      return 'board';
    }
    return 'list';
  },
);
