import React, { useMemo } from 'react';

import { Link, LinkProps } from '@mui/material';

interface Props extends LinkProps {
  value: string | null | undefined;
}

export const TemplateEmail: React.FC<Props> = ({ value, href, ...rest }) => {
  const emailHref = useMemo(() => {
    return `mailto:${value}`;
  }, [value]);

  if (!value) return null;

  return (
    <Link underline={'hover'} {...rest} href={href || emailHref}>
      {value}
    </Link>
  );
};
