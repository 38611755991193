import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicService } from 'utils/service';
import {
  BaseImage,
  BaseImageExist,
  BaseImageNew,
  BaseImageOptions,
  BehaviourHandleImages,
  ServiceWithImages,
} from '../@shared/images';
import { ServiceTicketActionItemCommentImages } from '../ticket-action-item-comment-images';

import {
  API_TICKET_ACTION_ITEM_COMMENTS,
  ITicketActionItemComment,
  ITicketActionItemCommentDelete,
  ITicketActionItemCommentForm,
  ITicketActionItemCommentPatch,
  ITicketActionItemCommentPost,
  ITicketActionItemKey,
} from './models';

export * from './models';

type Model = ITicketActionItemComment;

const REVALIDATE_TAG = 'TicketActionItemComments' as const;

class Service extends DynamicService<Model> implements ServiceWithImages {
  async getImagesByID(id: string) {
    const { data } = await this.getDynamic<{ images: BaseImage[] }>(id, {
      select: dynamic.select(
        'ticketActionItemCommentImages.OrderBy(rank).Select(i => new { i.id, i.entryDate, i.description, i.image, i.rank, i.userCrmProfileID }) as images',
      ),
    });

    return data.images;
  }
  async createImage(image: BaseImageNew, options: BaseImageOptions) {
    await ServiceTicketActionItemCommentImages.postItem({
      ...image,
      ticketActionItemCommentID: options.mainID,
    });
  }
  async updateImage(image: BaseImageExist, options: BaseImageOptions) {
    await ServiceTicketActionItemCommentImages.updateItem({
      ...image,
      ticketActionItemCommentID: options.mainID,
    });
  }
  async deleteImage(image: BaseImageExist, options: BaseImageOptions) {
    await ServiceTicketActionItemCommentImages.delete({ id: image.id });
  }

  async postComment(input: ITicketActionItemCommentPost) {
    const { images, notificationUserCrmProfileIDs, ...rest } = input;

    const res = await super.post(rest);

    if (images) {
      await ServiceImages.perform([], images, { mainID: res.data.id });
    }

    return res;
  }

  async patchComment(input: ITicketActionItemCommentPatch) {
    const { images, notificationUserCrmProfileIDs, ...rest } = input;

    if (typeof images !== 'undefined') {
      const prev = await this.getImagesByID(input.id);
      await ServiceImages.perform(prev, images, { mainID: input.id });
    }

    return super.patch(rest);
  }
}

export const ServiceTicketActionItemComments = new Service({
  getAll: API_TICKET_ACTION_ITEM_COMMENTS.GET_ALL_DYNAMIC,
  post: API_TICKET_ACTION_ITEM_COMMENTS.POST,
  patch: API_TICKET_ACTION_ITEM_COMMENTS.PATCH,
  delete: API_TICKET_ACTION_ITEM_COMMENTS.DELETE,
});

const ServiceImages = new BehaviourHandleImages(ServiceTicketActionItemComments);
export const apiTicketActionItemComments = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getTicketActionItemCommentsByTicketAction: build.query<ITicketActionItemCommentForm[], string>({
      queryFn: async (ticketActionItemID) => {
        try {
          const {
            data: { value },
          } = await ServiceTicketActionItemComments.getAllDynamic<ITicketActionItemCommentForm>({
            select: dynamic.select(
              'id',
              'itemKey',
              'userCrmProfileID',
              'ticketActionItemID',
              'description',
              'updatedDate',
              'createdDate',
              'ticketActionItemCommentImages.OrderBy(rank).Select(i => new { i.id, i.entryDate, i.description, i.image, i.rank, i.userCrmProfileID }) as images',
            ),
            filter: dynamic.makeFilter(
              'ticketActionItemID',
              ticketActionItemID,
              dynamic.decoratorIsNotNullable(dynamic.equals),
            ),
            orderBy: dynamic.orderBy('rowIndex', 'desc'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, ticketActionItemID) => [
        { type: REVALIDATE_TAG, id: `ticketActionItemID__${ticketActionItemID}` },
      ],
    }),
    getTicketActionItemIDByCommentItemKey: build.query<ITicketActionItemKey, string>({
      queryFn: async (itemKey) => {
        try {
          const result = await ServiceTicketActionItemComments.getAllDynamic<ITicketActionItemKey>({
            filter: dynamic.makeFilter(
              'itemKey',
              itemKey,
              dynamic.decoratorIsNotNullable(dynamic.equals),
            ),
            select: dynamic.select(
              'ticketActionItem.id as ticketActionID',
              'id',
              'itemKey',
              'ticketActionItem.itemKey as actionItemKey',
            ),
            take: 1,
          });
          const data = result.data.value[0];
          if (!data) {
            throw new Error('record-not-found');
          }
          return { ...result, data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    postTicketActionItemComment: build.mutation<
      ITicketActionItemComment,
      ITicketActionItemCommentPost
    >({
      queryFn: async (input, { dispatch }) => {
        try {
          const { data } = await ServiceTicketActionItemComments.postComment(input);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { ticketActionItemID }) => [
        { type: REVALIDATE_TAG, id: `ticketActionItemID__${ticketActionItemID}` },
      ],
    }),
    patchTicketActionItemComment: build.mutation<
      ITicketActionItemCommentPatch,
      ITicketActionItemCommentPatch
    >({
      queryFn: async (input) => {
        try {
          await ServiceTicketActionItemComments.patchComment({
            ...input,
            updatedDate: new Date().toISOString(),
          });

          return { data: input };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { ticketActionItemID }) => [
        { type: REVALIDATE_TAG, id: `ticketActionItemID__${ticketActionItemID}` },
      ],
    }),
    deleteTicketActionItemComment: build.mutation<void, ITicketActionItemCommentDelete>({
      queryFn: async (data) => {
        try {
          await ServiceTicketActionItemComments.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { ticketActionItemID }) => [
        { type: REVALIDATE_TAG, id: `ticketActionItemID__${ticketActionItemID}` },
      ],
    }),
  }),
});
