import { DateCalendar } from '@mui/x-date-pickers';
import { endOfDay, startOfDay } from 'date-fns';
import React, { useCallback, useMemo } from 'react';
import { convertToDate, DateValue, isDateLike } from 'utils/dates';
import { RenderCalendarProps } from '../../models';

export interface Props extends RenderCalendarProps {}
export const CalendarDay: React.FC<Props> = ({ value: _value, onApply, onChange }) => {
  const value = useMemo(() => {
    const start = _value[0];
    if (!isDateLike(start)) return;

    return convertToDate(start);
  }, [_value]);

  const handleChange = useCallback(
    (value: DateValue) => {
      if (!isDateLike(value)) return;
      const date = convertToDate(value);
      const v = [startOfDay(date), endOfDay(date)];

      onChange(v);
      onApply(v);
    },
    [onApply, onChange],
  );

  return <DateCalendar value={value} onChange={handleChange} />;
};
