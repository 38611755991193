import { Stack, Typography } from '@mui/material';
import { DialogDelete } from 'components/dialog-delete';
import { useEffectNotifyError, useTranslate } from 'hooks';
import React, { useCallback } from 'react';
import { apiTimeTracking } from 'services/time-trackings';
import { isFulfilledMutation } from 'utils/service';
import { useCheckDoneFetchItem } from '../../hooks/use-check-done-fetch-item';
import { ActionComponentProps } from '../../models';

const useDeleteMutation = apiTimeTracking.useDeleteTimeTrackingItemMutation;

interface Props extends ActionComponentProps {}
export const DialogActionDelete: React.FC<Props> = ({
  selectedRows,
  ticketActionItemID,
  onClose,
}) => {
  const { tp } = useTranslate();

  const onRefreshPage = useCheckDoneFetchItem(ticketActionItemID);

  const [deleteItem, resultDeleteItem] = useDeleteMutation();
  useEffectNotifyError(resultDeleteItem.error);

  const onAgree = useCallback(async () => {
    const res = await Promise.all(
      selectedRows.map((row) => {
        return deleteItem({ id: row.id });
      }),
    );
    const isAllSuccess = res.every(isFulfilledMutation);

    if (isAllSuccess) {
      onClose();
      onRefreshPage();
    }
  }, [onClose, selectedRows, deleteItem, onRefreshPage]);

  return (
    <DialogDelete
      isAgreeAuto={false}
      onClose={onClose}
      onAgree={onAgree}
      disabled={resultDeleteItem.isLoading}
    >
      <Stack spacing={1} direction={'row'} alignItems={'center'}>
        <span>{tp('delete-bulk')}</span>
        <Typography color={'textSecondary'}>({selectedRows.length})</Typography>
      </Stack>
    </DialogDelete>
  );
};
