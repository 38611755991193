import { Tab, Tabs } from '@mui/material';
import { TicketActionItemDurationGrid } from 'components/ticket-action-item-containers';
import { useTranslate } from 'hooks';
import React, { useState } from 'react';
import { Unset } from 'utils/types';
import style from './index.module.scss';

enum TABS {
  TIME_TRACKING = 'time',
  REPORT = 'report',
}

interface Props {
  ticketActionItemID: string | undefined;
  activityReportDescription: Unset;
}
export const RightPanel: React.FC<Props> = ({ ticketActionItemID, activityReportDescription }) => {
  const { tp } = useTranslate();
  const [value, setValue] = useState(TABS.TIME_TRACKING);

  return (
    <div className={style.root}>
      <Tabs
        value={value}
        onChange={(e, v) => setValue(v)}
        TabIndicatorProps={{
          sx: {
            display: 'none',
          },
        }}
        className={style.tabs}
      >
        <Tab value={TABS.TIME_TRACKING} label={tp('time-tracking')} className={style.tab} />
      </Tabs>
      <div className={style.wrap}>
        {value === TABS.TIME_TRACKING && (
          <TicketActionItemDurationGrid
            ticketActionItemID={ticketActionItemID}
            meta={{
              displayDescription: false,
              activityReportDescription: activityReportDescription,
            }}
          />
        )}
      </div>
    </div>
  );
};
