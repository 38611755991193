import { useMemo, useState } from 'react';

export const useBoolean = (init: boolean = false) => {
  const [value, setValue] = useState(init);
  const options = useMemo(() => {
    const on = () => setValue(true);
    const off = () => setValue(false);
    const toggle = () => setValue((prev) => !prev);

    const set = setValue;

    return { on, off, toggle, set };
  }, []);

  return [value, options] as const;
};
