import { ValueFileUploader } from 'utils/file-uploader';
import { FixServerObject } from 'utils/types';

const API = 'TicketActionItemCommentImages';

export const API_TICKET_ACTION_ITEM_COMMENT_IMAGES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ITicketActionItemCommentImage, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ITicketActionItemCommentImage, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface ITicketActionItemCommentImage
  extends FixServerObject<
    Components.Schemas.TicketActionItemCommentImage,
    'id' | 'ticketActionItemCommentID'
  > {}

export interface AppTicketActionItemCommentImage
  extends Omit<ITicketActionItemCommentImage, 'image'> {
  image: ValueFileUploader;
}
