import { DesktopDatePicker, DesktopDatePickerProps } from '@mui/x-date-pickers';
import React, { ForwardedRef, forwardRef, memo, useCallback, useMemo } from 'react';
import { convertToDate, isDateLike } from 'utils/dates';

export interface AppDatePickerProps extends Omit<DesktopDatePickerProps<any>, 'renderInput'> {
  error?: boolean;
  helperText?: React.ReactNode;
}
type IChange = Required<AppDatePickerProps>['onChange'];

const DEFAULT_INPUT_FORMAT = 'dd/MM/yyyy';

const Component = (
  {
    onChange,
    error,
    helperText,
    value: _value,
    format = DEFAULT_INPUT_FORMAT,
    ...props
  }: AppDatePickerProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const onChangeWrap = useCallback<IChange>(
    (value, keyboardInputValue) => {
      onChange &&
        onChange(
          isDateLike(value) ? convertToDate(value).toISOString() : value,
          keyboardInputValue,
        );
    },
    [onChange],
  );
  const value = useMemo(() => {
    return isDateLike(_value) ? convertToDate(_value) : null;
  }, [_value]);
  return (
    <DesktopDatePicker
      inputRef={ref}
      {...props}
      format={format}
      slotProps={{
        ...props.slotProps,
        textField: {
          variant: 'standard',
          error,
          helperText,
          ...props.slotProps?.textField,
        },
      }}
      value={value}
      onChange={onChangeWrap}
    />
  );
};

export const AppDatePicker = memo(forwardRef(Component)) as typeof Component;
