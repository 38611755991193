import { useCallback } from 'react';
import { DataGridDefaultTasks, DataGridTask } from '../models';

interface Options<M> {
  onChangeItem?: (data: M) => void;
  onEditRow?: (data: M) => void;
  onDeleteRow?: (data: M) => void;
  onMoveRows?: (value: { newRows: M[]; oldRows: M[] }) => void;
}
export const useDataGridTasksHandler = <
  M,
  Task extends DataGridTask<any> = DataGridDefaultTasks<M>,
>(
  options: Options<M>,
) => {
  const { onDeleteRow, onEditRow, onChangeItem, onMoveRows } = options;
  const onTask = useCallback(
    (task: Task) => {
      switch (task.type) {
        case 'item-change': {
          return onChangeItem && onChangeItem(task.payload.data);
        }
        case 'row-delete': {
          return onDeleteRow && onDeleteRow(task.payload.row);
        }
        case 'row-edit': {
          return onEditRow && onEditRow(task.payload.row);
        }
        case 'move-rows': {
          return onMoveRows && onMoveRows(task.payload);
        }
        case 'row-view-more': {
          return onEditRow && onEditRow(task.payload.row);
        }
      }
    },
    [onDeleteRow, onEditRow, onChangeItem, onMoveRows],
  );

  return { onTask };
};
