import { memo } from 'react';
import { DialogNoteEdit } from '../dialog-edit';
import { DialogNoteNew } from '../dialog-new';

interface Props {
  itemID: undefined | string;
  customerID: null | string;
  onClose: () => void;
}

export const DialogNote = memo<Props>(({ onClose, itemID, customerID }) => {
  if (!itemID) return <DialogNoteNew customerID={customerID} onClose={onClose} onDone={onClose} />;
  return <DialogNoteEdit itemID={itemID} onDone={onClose} onClose={onClose} />;
});
