import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AssignmentReturnOutlinedIcon from '@mui/icons-material/AssignmentReturnOutlined';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { Stack, Tab, Tabs, Typography } from '@mui/material';
import { CollapsePanel } from 'components/collapse-panel';
import { SvgRemove } from 'components/svg-icons/remove';
import { APP_ROUTES } from 'configs';
import { useAppDispatch, useOpen, useTranslate } from 'hooks';
import { useValueQuery } from 'hooks/use-value-query';
import { memo, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { apiTicketStatuses } from 'services/ticket-statuses';
import { apiUserCrmProfiles } from 'services/user-crm-profiles';
import { actionCheckDoneTicketsFetchAll } from 'store/check-done-tickets';
import { Filters, TicketRegular, Tickets } from './components';
import { QUERY_NAME_EDIT } from './helpers';
import style from './index.module.scss';

export const CheckDoneLayout = memo((props) => {
  const dispatch = useAppDispatch();
  const { tp } = useTranslate();
  const { onToggle, isOpen: isSmall } = useOpen();
  const { value, onClear } = useValueQuery({ name: QUERY_NAME_EDIT });

  const { pathname } = useLocation();

  //start: loading relative states
  apiUserCrmProfiles.useGetUserCrmProfileSourceQuery();
  apiTicketStatuses.useGetTicketStatusesQuery();
  //end: loading relative states

  useEffect(() => {
    dispatch(actionCheckDoneTicketsFetchAll());
  }, [dispatch]);

  return (
    <>
      <CollapsePanel
        in={!isSmall}
        onClick={onToggle}
        panel={
          <div className={style.left}>
            <Typography color={'primary'} variant={'h2'} component={'h1'} pb={0.2}>
              {tp('tickets')}
            </Typography>
            <Filters />
            <Tickets />
          </div>
        }
      >
        <div className={style.right}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Tabs value={pathname}>
              <Tab
                value={APP_ROUTES.CHECK_DONE.path}
                label={tp('not-completed')}
                component={Link}
                to={APP_ROUTES.CHECK_DONE().config}
                icon={<AssignmentIcon sx={{ fontSize: 20 }} />}
                iconPosition={'start'}
                sx={{
                  minHeight: 48,
                }}
              />
              <Tab
                value={APP_ROUTES.CHECK_DONE_COMPLETED.path}
                label={tp('completed')}
                component={Link}
                to={APP_ROUTES.CHECK_DONE_COMPLETED().config}
                icon={<AssignmentTurnedInIcon sx={{ fontSize: 20 }} />}
                iconPosition={'start'}
                sx={{
                  minHeight: 48,
                }}
              />
            </Tabs>
            <Tabs value={pathname}>
              <Tab
                value={APP_ROUTES.CHECK_DONE_UN_ASSIGN.path}
                label={tp('un-assign')}
                component={Link}
                to={APP_ROUTES.CHECK_DONE_UN_ASSIGN().config}
                icon={<AssignmentReturnOutlinedIcon sx={{ fontSize: 20 }} />}
                iconPosition={'start'}
                sx={{
                  minHeight: 48,
                }}
              />
              <Tab
                value={APP_ROUTES.CHECK_DONE_ALL.path}
                label={tp('all')}
                component={Link}
                to={APP_ROUTES.CHECK_DONE_ALL().config}
                icon={<AssignmentOutlinedIcon sx={{ fontSize: 20 }} />}
                iconPosition={'start'}
                sx={{
                  minHeight: 48,
                }}
              />
              <Tab
                value={APP_ROUTES.CHECK_DONE_TRASH.path}
                label={tp('trash')}
                component={Link}
                to={APP_ROUTES.CHECK_DONE_TRASH().config}
                icon={<SvgRemove sx={{ fontSize: 20 }} />}
                iconPosition={'start'}
                sx={{
                  minHeight: 48,
                }}
                classes={{ selected: style.tabSelected }}
              />
            </Tabs>
          </Stack>

          <Outlet />
        </div>
      </CollapsePanel>
      {value && <TicketRegular itemKey={value} onClose={onClear} onDone={onClear} />}
    </>
  );
});
