import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APP_NOTIFICATION_TYPE } from 'services/app-notifications';
import { DateValue } from 'utils/dates';
import { PREFIX } from './helpers';

interface Filters {
  search: string;
  date: DateValue[];
  type: null | APP_NOTIFICATION_TYPE;
}

interface State {
  filters: Filters;
  isExpanded: boolean;
}

const initState = (): State => {
  return {
    filters: {
      search: '',
      date: [],
      type: null,
    },
    isExpanded: true,
  };
};

const slice = createSlice({
  name: PREFIX,
  initialState: initState(),
  reducers: {
    reset() {
      return initState();
    },
    filtersSet(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    filtersReset(state) {
      state.filters = initState().filters;
    },
    toggleExpanded(state) {
      state.isExpanded = !state.isExpanded;
    },
  },
  extraReducers: (builder) => {},
});

export const actionsNotificationsLayout = slice.actions;
export const reducerNotificationsLayout = slice.reducer;
