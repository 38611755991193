import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import style from './index.module.scss';

interface BaseCountProps extends BoxProps {
  count: number;
  className?: string;
}
export const BaseCount: React.FC<BaseCountProps> = ({ count, className }) => {
  return <Box className={clsx(style.root, className)}>{count}</Box>;
};
