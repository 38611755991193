import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { ServiceReportDoneItems } from '../report-done-items';
import {
  API_DONE_ITEMS,
  IDoneItem,
  IGetAllDoneItem,
  IGetDoneItem,
  PatchDoneItemBulkInput,
} from './models';

const REVALIDATE_KEY = 'DoneItems' as const;

export * from './models';

class Service extends DynamicService<IDoneItem> {}

export const ServiceDoneItems = new Service({
  getAll: API_DONE_ITEMS.GET_ALL_DYNAMIC,
  post: API_DONE_ITEMS.CREATE,
  patch: API_DONE_ITEMS.PATCH,
  delete: API_DONE_ITEMS.DELETE,
});

type ApiModel = IDoneItem;

export const apiDoneItems = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getAllDoneItems: build.query<IGetAllDoneItem[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceDoneItems.getAllDynamic<IGetAllDoneItem>({
            select: dynamic.select(
              'id',
              'userCrmProfileID',
              'duration',
              'billableDuration',
              'description',
            ),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error) => [{ type: REVALIDATE_KEY }],
    }),
    getDoneItem: build.query<IGetDoneItem | null, string>({
      queryFn: async (timeTrackingID) => {
        try {
          const response = await ServiceDoneItems.getAllDynamic<IGetDoneItem>({
            select: dynamic.select(
              'id',
              'userCrmProfileID',
              'duration',
              'billableDuration',
              'description',
              'userCrmProfile.fullName as userCrmProfileFullName',
            ),
            filter: dynamic.makeFilter(
              'timeTrackingID',
              timeTrackingID,
              dynamic.decoratorIsNotNullable(dynamic.equals),
            ),
            take: 1,
          });
          const { data } =
            ServiceDoneItems.transformDynamicResponseToItemMaybe<IGetDoneItem>(response);
          return { data: data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postDoneItem: build.mutation<IDoneItem, Omit<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          return await ServiceDoneItems.post(data);
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    createDoneItemsWithReport: build.mutation<
      void,
      { reportID: string; items: Array<Omit<ApiModel, 'id'>> }
    >({
      queryFn: async (input) => {
        const { reportID, items } = input;
        try {
          await Promise.all(
            items.map(async (row) => {
              const { data: doneItem } = await ServiceDoneItems.post(row);
              const reportDoneItem = await ServiceReportDoneItems.post({
                reportID,
                doneItemID: doneItem.id,
              });
              return { doneItem, reportDoneItem };
            }),
          );
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, arg, meta) =>
        arg.items.map((row) => {
          return { type: REVALIDATE_KEY, id: `timeTrackingID__${row.timeTrackingID}` };
        }),
    }),
    patchDoneItem: build.mutation<PatchPartial<ApiModel, 'id'>, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceDoneItems.patch(data);
          return { data: data };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    patchDoneItemBulk: build.mutation<void, PatchDoneItemBulkInput>({
      queryFn: async (input) => {
        try {
          const { ids, data } = input;

          await Promise.all(
            ids.map((id) => {
              return ServiceDoneItems.patch({ ...data, id });
            }),
          );

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, input) => [
        { type: REVALIDATE_KEY },
        ...input.ids.map((id) => ({ type: REVALIDATE_KEY, id })),
      ],
    }),
    deleteDoneItem: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceDoneItems.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
  }),
});
