import RestoreIcon from '@mui/icons-material/Restore';
import { Button, DialogActions, LinearProgress } from '@mui/material';
import { ButtonCancel, ButtonRemove } from 'components/buttons';
import { DialogDelete } from 'components/dialog-delete';
import { NativeScroll } from 'components/native-scroll';
import { TicketActionItemPreview } from 'components/ticket-action-item-containers/components/ticket-action-item-preview';
import { useTicketActionItemInformation } from 'components/ticket-action-item-containers/hooks/use-ticket-information';
import { useEffectNotifyError, useOpen, useTranslate } from 'hooks';
import { memo, useCallback } from 'react';
import { apiTicketActionItems } from 'services/ticket-action-items';
import { composeFunctions } from 'utils/other';
import { isFulfilledMutation } from 'utils/service';

const useFetchQuery = apiTicketActionItems.useGetTicketActionItemByKeyQuery;
const useActivateMutation = apiTicketActionItems.useActivateTicketActionItemMutation;
const useDeleteMutation = apiTicketActionItems.useDeleteTicketActionItemMutation;

interface Props {
  itemKey: string;
  onClose: () => void;
  onDone: () => void;
}
export const PreviewContent = memo<Props>(({ itemKey, onClose, onDone }) => {
  const { tp } = useTranslate();
  const resultGet = useFetchQuery(itemKey);
  useEffectNotifyError(resultGet.error);

  const initData = resultGet.data;
  const id = initData?.id;
  const resultInfo = useTicketActionItemInformation(id);
  useEffectNotifyError(resultInfo.error);

  const [activateItem, resultActivate] = useActivateMutation();
  useEffectNotifyError(resultActivate.error);

  const [deleteItem, resultDelete] = useDeleteMutation();
  useEffectNotifyError(resultDelete.error);

  const onActivate = useCallback(async () => {
    if (!id) return;
    await activateItem({ id });
  }, [activateItem, id]);

  const onConfirmDelete = useCallback(async () => {
    if (!id) return;
    const result = await deleteItem({ id });

    if (isFulfilledMutation(result)) {
      onClose();
      onDone();
    }
  }, [id, deleteItem, onClose, onDone]);

  const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useOpen();

  const isLoading =
    resultGet.isFetching ||
    resultDelete.isLoading ||
    resultActivate.isLoading ||
    resultInfo.isFetching;

  return (
    <>
      {isLoading && <LinearProgress />}
      <NativeScroll>
        <TicketActionItemPreview itemKey={itemKey} data={initData} />
      </NativeScroll>

      <DialogActions sx={{ pt: '2rem' }}>
        <ButtonRemove
          disabled={isLoading || !id}
          sx={{ marginRight: 'auto' }}
          onClick={onOpenDelete}
        />
        <ButtonCancel disabled={isLoading} onClick={onClose} color={'primary'}>
          {tp('cancel')}
        </ButtonCancel>
        <Button
          variant={'contained'}
          disabled={isLoading}
          onClick={onActivate}
          startIcon={<RestoreIcon />}
        >
          {tp('restore')}
        </Button>
      </DialogActions>
      {isOpenDelete && (
        <DialogDelete
          onClose={onCloseDelete}
          onAgree={composeFunctions(onCloseDelete, onConfirmDelete)}
        >
          {tp('delete-item')}
        </DialogDelete>
      )}
    </>
  );
});
