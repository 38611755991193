import { ButtonBase, ButtonBaseProps } from '@mui/material';
import React from 'react';

interface Classes {
  root: string;
}

interface Props extends ButtonBaseProps {
  className?: string;
  classes?: Partial<Classes>;
}

export const AppButtonBase: React.FC<Props> = ({ title, children, ...rest }) => {
  return (
    <ButtonBase
      {...rest}
      sx={{
        borderRadius: '0.4rem',
        padding: '0.2rem 0.4rem',
        textAlign: 'left',
        ...rest?.sx,
      }}
    >
      {children}
    </ButtonBase>
  );
};
