import { useContext } from 'react';
import { DataGridSortingContext } from '../components/provider-sorting';

export const useDataGridSorting = () => {
  const result = useContext(DataGridSortingContext);

  if (!result) {
    throw Error('You should use DataGridSortingContext');
  }

  return result;
};
