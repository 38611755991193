import { InputLabel } from '@mui/material';
import { AppInput, AppInputProps } from 'components/app-input';
import { ForwardedRef, forwardRef, memo } from 'react';

const Input = (props: AppInputProps, ref: ForwardedRef<HTMLInputElement>) => {
  return (
    <div>
      <InputLabel shrink>{props.label}</InputLabel>
      <AppInput {...props} ref={ref} label={null} />
    </div>
  );
};

export const AppInputLabel = memo(forwardRef(Input)) as typeof Input;
