import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { ButtonCancel } from 'components/buttons';
import { DialogHeader } from 'components/dialog-header';
import { BaseBreadcrumbs, BaseDialogTitle } from 'components/tickets';
import { useEffectNotifyError, useTranslate } from 'hooks';
import React, { useMemo } from 'react';
import { handleEventStopAndPrevent } from 'utils/handlers';
import { useTicketActionItemInformation } from '../../hooks/use-ticket-information';
import { TicketActionItemDurationGrid } from '../ticket-action-item-durations-grid';

interface Props {
  ticketActionItemID: string;
  onClose: () => void;
}
export const TicketActionItemDurationsDialog: React.FC<Props> = ({
  ticketActionItemID,
  onClose,
}) => {
  const { tp } = useTranslate();
  const resultInfo = useTicketActionItemInformation(ticketActionItemID);
  useEffectNotifyError(resultInfo.error);
  const { customer, ticket, ticketActionItem } = resultInfo;

  const breadcrumbs = useMemo(
    () => [
      ...(customer ? [{ label: customer.title }] : []),
      ...(ticket ? [{ label: ticket.title }] : []),
    ],
    [customer, ticket],
  );

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth={'md'}
      onDoubleClick={handleEventStopAndPrevent}
    >
      <DialogHeader title={tp('track-time')} onClose={onClose} />
      <DialogContent
        sx={{
          minHeight: '60vh',
          display: 'grid',
          gridTemplateRows: 'auto auto 1fr',
          paddingBottom: 1,
        }}
      >
        <BaseBreadcrumbs isLoading={resultInfo.isFetching} items={breadcrumbs} />
        <BaseDialogTitle isLoading={resultInfo.isFetching} mt={1.5} mb={2}>
          {ticketActionItem?.title}
        </BaseDialogTitle>
        <TicketActionItemDurationGrid
          ticketActionItemID={ticketActionItemID}
          meta={{
            displayDescription: true,
            activityReportDescription: resultInfo.ticketActionItem?.activityReportDescription,
          }}
        />
      </DialogContent>
      <DialogActions>
        <ButtonCancel onClick={onClose}>{tp('close')}</ButtonCancel>
      </DialogActions>
    </Dialog>
  );
};
