import React, { ComponentProps, ComponentType, ForwardedRef, forwardRef } from 'react';
import { DialogConfirm } from '../components/dialog-confirm';
import { useOpen } from '../hooks';
import { composeFunctions } from '../utils/other';

export const withConfirmClick = <C extends ComponentType<{ onClick?: (e: any) => void }>>(
  Component: C,
) => {
  const WithConfirmClick = (
    props: ComponentProps<C> & { confirmTitle?: React.ReactNode; confirmMessage: React.ReactNode },
    ref: ForwardedRef<any>,
  ) => {
    const { confirmTitle, confirmMessage, ...rest } = props;
    const { isOpen, onOpen, onClose } = useOpen();
    return (
      <>
        <Component ref={ref} {...(rest as unknown as any)} onClick={onOpen} />
        {isOpen && (
          <DialogConfirm
            title={confirmTitle}
            onClose={onClose}
            onCancel={onClose}
            onConfirm={composeFunctions(onClose, rest.onClick)}
          >
            {confirmMessage}
          </DialogConfirm>
        )}
      </>
    );
  };

  return forwardRef(WithConfirmClick) as unknown as typeof WithConfirmClick;
};
