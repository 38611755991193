import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, BoxProps, Button, Divider } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import style from './index.module.scss';

interface Props extends BoxProps {
  onClick?: () => void;
  isSmall?: boolean;
}
export const CollapseDivider: React.FC<Props> = ({ onClick, isSmall, ...rest }) => {
  return (
    <Box className={clsx(style.root, isSmall && style.rootSmall)} {...rest}>
      <Button variant={'outlined'} size={'small'} className={style.button} onClick={onClick}>
        <ChevronLeftIcon />
      </Button>
      <Divider orientation={'vertical'} className={style.divider} />
    </Box>
  );
};
