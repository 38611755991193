import React, { memo, PropsWithChildren, useMemo } from 'react';
import { DataGridSortingValue } from '../../models';

interface DataGridSortingContextValue {
  value: Omit<DataGridSortingValue, 'field'> & { field: undefined | string };
  onChange: (value: Partial<DataGridSortingValue>) => void;
}

const defaultValue: DataGridSortingContextValue['value'] = {
  field: undefined,
  order: null,
};
const defaultOnChange = (value: Partial<DataGridSortingValue>) => {
  console.error('DataGrid: sortChange was not provided');
};
export const DataGridSortingContext = React.createContext<DataGridSortingContextValue | null>(null);

interface Props {
  value?: DataGridSortingValue;
  onChange?: (value: DataGridSortingValue) => void;
}

export const DataGridSortingProvider = memo<PropsWithChildren<Props>>(
  function DataGridSortingProvider({
    children,
    value: _value = defaultValue,
    onChange = defaultOnChange,
  }) {
    const { field, order } = _value;
    const value = useMemo(() => {
      return {
        value: { field, order },
        onChange: (value: Partial<DataGridSortingValue>) =>
          onChange({ field: field as string, order, ...value }),
      };
    }, [field, order, onChange]);
    return (
      <DataGridSortingContext.Provider value={value}>{children}</DataGridSortingContext.Provider>
    );
  },
);
