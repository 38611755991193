import { DynamicOrder } from 'utils/dynamic';
import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'ProjectAlerts';

export const API_PROJECT_ALERTS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<IProjectAlert, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<IProjectAlert, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IProjectAlert
  extends FixServerObject<
    Components.Schemas.ProjectAlert,
    'id' | 'isActive' | 'projectID' | 'dueDate' | 'remarks'
  > {}

export const schemaProjectAlert = yup.object({
  remarks: yup.string().min(2, 'rule-min-length').default(''),
  projectID: yup.string().required('rule-required').default(''),
  dueDate: yup.string().required('rule-required').default(''),
  isActive: yup.boolean().default(true),
});

export interface IProjectAlertDashboard extends Pick<IProjectAlert, 'id' | 'remarks' | 'dueDate'> {
  projectName: string;
}

export interface IGridAlerts
  extends Pick<IProjectAlert, 'id' | 'projectID' | 'remarks' | 'dueDate' | 'isActive'> {
  projectName: string;
  customerName: string;
}

export interface IGridProjectAlertsArgs {
  search: string;
  projectID: string;
  take: number;
  skip: number;
  orderBy: DynamicOrder;
}
