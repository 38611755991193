import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGridCrmPermissions } from 'services/user-crm-profile-permissions';
import { actionCrmPermissionsChangeItem, actionCrmPermissionsGetAllDynamic } from './actions';

interface OrderBy {
  field: string;
  order: 'desc' | 'asc' | null;
}

interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;
  data: IGridCrmPermissions[];
  orderBy: OrderBy;
}

const initState = (): State => {
  return {
    error: null,
    isLoading: false,
    isInit: false,
    data: [],
    orderBy: {
      field: 'title',
      order: 'desc',
    },
  };
};

const slice = createSlice({
  name: 'CRM_PERMISSIONS',
  initialState: initState(),
  reducers: {
    actionCrmPermissionsOrderBySet(state, action: PayloadAction<Partial<OrderBy>>) {
      state.orderBy = { ...state.orderBy, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionCrmPermissionsChangeItem.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(actionCrmPermissionsChangeItem.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(actionCrmPermissionsChangeItem.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    builder.addCase(actionCrmPermissionsGetAllDynamic.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(actionCrmPermissionsGetAllDynamic.fulfilled, (state, action) => {
      const {
        payload: { value },
      } = action;
      state.isLoading = false;
      state.isInit = true;
      state.data = value;
    });
    builder.addCase(actionCrmPermissionsGetAllDynamic.rejected, (state, action) => {
      const { error } = action;
      state.isLoading = false;
      state.error = error;
      state.isInit = true;
    });
  },
});

export const { actionCrmPermissionsOrderBySet } = slice.actions;
export const reducerCrmPermissions = slice.reducer;
