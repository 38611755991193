import React, { useCallback, useMemo } from 'react';
import { ConditionPartial } from 'utils/types';
import { AnyObjectSchema } from 'yup';
import {
  DataGridClasses,
  DataGridDefaultTasks,
  DataGridSortingValue,
  IDataGridColumnProps,
  IDataGridGroupOptions,
  IDataGridRenderGroupProps,
  IDataGridRowProps,
  TDataGridVirtualProps,
} from '../../models';
import { DataGridTable } from '../data-grid-table';
import { DataGridTableVirtualized } from '../data-grid-table-virtualized';
import { DataGridDragAndDropProvider } from '../provider-drag-and-drop';
import { DataGridSettingsProvider } from '../provider-settings';
import { DataGridSortingProvider } from '../provider-sorting';
import { DataGridTasksProvider } from '../provider-tasks';

interface Props<
  T extends Record<string, any>,
  Task extends DataGridDefaultTasks<T>,
  Virtual extends boolean = boolean,
> {
  className?: string;
  rows: T[];
  sortValue?: DataGridSortingValue;
  sortChange?: (value: DataGridSortingValue) => void;
  rowProps?: IDataGridRowProps<T>;
  classes?: Partial<DataGridClasses>;
  children?: React.ReactNode;
  bodyStart?: React.ReactNode;
  bodyEnd?: React.ReactNode;
  onTask?: (task: Task) => void;
  scheme?: AnyObjectSchema;
  virtual?: Virtual;
  groupOptions?: IDataGridGroupOptions<T>;
  renderGroup?: (renderProps: IDataGridRenderGroupProps<T>) => React.ReactNode;
}

export const DataGrid = <
  T extends Record<string, any>,
  Task extends DataGridDefaultTasks<T>,
  Virtual extends boolean = boolean,
>({
  rows,
  rowProps,
  rowKey = 'id' as keyof T,
  classes,
  children,
  sortValue,
  sortChange,
  bodyStart,
  bodyEnd,
  onTask,
  scheme,
  virtualProps,
  virtual,
  groupOptions,
  renderGroup,
}: Props<T, Task> &
  ConditionPartial<T, { id: string }, { rowKey: keyof T }> &
  ConditionPartial<
    Virtual,
    true,
    {
      virtualProps?: TDataGridVirtualProps;
    }
  >) => {
  const columns = useMemo(() => {
    return React.Children.toArray(children)
      .filter(React.isValidElement)
      .filter((el) => {
        // @ts-ignore
        return typeof el.type === 'function' && el.type.displayName === 'DataGridColumn';
      })
      .map((element) => {
        const {
          HeadCellProps,
          HeadCellInnerProps,
          sortable,
          sortableKey,

          title,
          sticky,

          field,
          ...rest
        } = element.props as Required<IDataGridColumnProps<T>>;
        return {
          cellOptions: {
            sticky,
            field,
            ...rest,
          },
          headCellOptions: {
            HeadCellProps,
            HeadCellInnerProps,
            sortable,
            sortableKey,
            title,
            sticky,
            field,
          },
        };
      });
  }, [children]);

  const renderInner = useCallback(
    (innerRows: T[]) => {
      if (virtual && virtualProps) {
        return (
          <DataGridTableVirtualized
            bodyStart={bodyStart}
            bodyEnd={bodyEnd}
            columns={columns}
            rowProps={rowProps}
            rows={innerRows}
            virtualProps={virtualProps}
          />
        );
      }
      return (
        <DataGridTable
          bodyStart={bodyStart}
          bodyEnd={bodyEnd}
          columns={columns}
          rowProps={rowProps}
          rows={innerRows}
          groupOptions={groupOptions}
          renderGroup={renderGroup}
        />
      );
    },
    [bodyStart, bodyEnd, columns, rowProps, virtual, virtualProps, renderGroup, groupOptions],
  );

  return (
    <DataGridTasksProvider onTask={onTask}>
      <DataGridSettingsProvider rowKey={rowKey} schema={scheme} classes={classes}>
        <DataGridSortingProvider value={sortValue} onChange={sortChange}>
          <DataGridDragAndDropProvider rows={rows}>{renderInner}</DataGridDragAndDropProvider>
        </DataGridSortingProvider>
      </DataGridSettingsProvider>
    </DataGridTasksProvider>
  );
};
