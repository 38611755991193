import { isEqual, pick } from 'lodash-es';
import { useCallback, useEffect } from 'react';
import { FieldValues, useForm, UseFormProps } from 'react-hook-form';

interface Props<TFieldValues extends FieldValues = FieldValues, TContext extends object = object>
  extends UseFormProps<TFieldValues, TContext> {
  onUpdate?: (value: TFieldValues) => void;
}
export const useFilterForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object,
>(
  props: Props<TFieldValues, TContext>,
) => {
  const result = useForm(props);

  const { defaultValues, onUpdate } = props;

  const { reset, getValues } = result;

  useEffect(() => {
    if (typeof defaultValues != 'function') {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  const onChangeItem = useCallback(() => {
    const currentValues = getValues();
    const previousValues = pick(defaultValues, Object.keys(currentValues));

    if (!isEqual(currentValues, previousValues)) {
      onUpdate && onUpdate(getValues());
    }
  }, [getValues, defaultValues, onUpdate]);

  return { ...result, onChangeItem };
};
