import { useContext } from 'react';
import { DataGridSettingContext } from '../components/provider-settings';

export const useDataGridSettings = () => {
  const result = useContext(DataGridSettingContext);

  if (!result) {
    throw Error('You should use DataGridSettingContext');
  }

  return result;
};
