import { format, startOfDay } from 'date-fns';
import { ServiceMonthlyActivityReports } from 'services/monthly-activity-reports';
import { convertToDate, isDateLike } from 'utils/dates';
import * as dynamic from 'utils/dynamic';
import {
  apiRtk,
  DynamicResult,
  DynamicService,
  transformResponseDynamic,
  transformResponseDynamicItem,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { InferType } from 'yup';
import { ServiceDoneItems } from '../done-items';
import { ServiceReportDoneItems } from '../report-done-items';
import {
  API_TIME_TRACKINGS,
  GetDoneItemsToDeleteOutput,
  GridTimeTrackingForTicketActionItems,
  IGridTimeTracking,
  IGridTimeTrackingArgs,
  IGridTimeTrackingForTicketActionItemsArgs,
  IGridTimeTrackingProjectItemArgs,
  ITimeTracking,
  ITimeTrackingMoveItemToActivityInput,
  ITimeTrackingPreview,
  ITimeTrackPatchInput,
  ITimeTrackPostInput,
  ITimeTrackPostMultipleInput,
  schemaTimeTracking,
  TimeTrackingDeleteDoneItem,
  TimeTrackingItemForAddReport,
} from './models';

export * from './models';

type Model = ITimeTracking;

class Service extends DynamicService<Model> {
  prepareData = <T extends Partial<InferType<typeof schemaTimeTracking>>>(data: T) => {
    return {
      ...data,
      date: isDateLike(data.date) ? format(convertToDate(data.date), 'yyyy-MM-dd') : data.date,
      // remove from the schema
      ticketID: undefined,
      customerID: undefined,
    } as unknown as T;
  };
  async postItemMultiple(data: ITimeTrackPostMultipleInput) {
    let { customerID, date, dates, ...rest } = data;

    const set = new Set(
      dates.filter(Boolean).map((v) => startOfDay(convertToDate(v)).toISOString()),
    );
    const days = Array.from(set.values());

    return Promise.all(
      days.map((date) => {
        return this.postItem({ ...rest, date });
      }),
    );
  }

  async postItem(data: Omit<ITimeTracking, 'id'>) {
    const result = this.prepareData(data);
    return this.post({ ...result, id: undefined as any });
  }

  async patchItem(data: ITimeTrackPatchInput) {
    let { customerID, ...rest } = data;
    const result = this.prepareData(rest);
    return super.patch(result);
  }

  moveItemToActivity = async (input: ITimeTrackingMoveItemToActivityInput) => {
    const date = convertToDate(input.date);

    return ServiceMonthlyActivityReports.performPost({
      customerID: input.customerID,
      userCrmProfileID: input.userCrmProfileID,
      date: input.date,
      activityYear: date.getFullYear(),
      activityMonth: date.getMonth(),
      duration: input.duration,
      billableDuration: input.duration,
      projectName: input.projectNameHeb || '',
      activityType: input.titleHeb || '',
      description: input.description,
      shortDescription: null,
      isSent: false,
    });
  };

  getDoneItemsToDelete = async (id: string) => {
    const { data } = await this.getDynamic<GetDoneItemsToDeleteOutput>(id, {
      select: dynamic.select(
        'id',
        `doneItems.Where(d => d.isActive && d.reportDoneItems.All(rd => rd.report.bill==false && rd.report.lock==false ))
                .Select(d => new { d.id, d.reportDoneItems.Select(rd => new { rd.id, rd.report.bill, rd.report.lock }) as reportDoneItems }) as doneItems`,
      ),
    });

    return { data: data.doneItems };
  };
  deleteTimeTrackingDoneItem = async (input: TimeTrackingDeleteDoneItem) => {
    const { id, reportDoneItems } = input;

    // remove report done items
    await Promise.all(
      reportDoneItems.map((reportDoneItem) => {
        return ServiceReportDoneItems.delete({ id: reportDoneItem.id });
      }),
    );

    await ServiceDoneItems.delete({ id });
  };
}

const REVALIDATE_TAG = 'TimeTracking';
const DASHBOARD_REPORTS_REVALIDATE_TAG = 'DashboardReports';

export const ServiceTimeTracking = new Service({
  getAll: API_TIME_TRACKINGS.GET_ALL_DYNAMIC,
  post: API_TIME_TRACKINGS.POST,
  patch: API_TIME_TRACKINGS.PATCH,
  delete: API_TIME_TRACKINGS.DELETE,
});

export const apiTimeTracking = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getProjectTimeTracking: build.query<
      DynamicResult<IGridTimeTracking, { count: true }>,
      IGridTimeTrackingProjectItemArgs
    >({
      query: ({ userCrmProfileID, projectID, period, order, take, skip }) => ({
        url: API_TIME_TRACKINGS.GET_ALL_DYNAMIC,
        params: {
          filter: dynamic
            .mergeFilters(
              dynamic.makeFilter('projectID', projectID, dynamic.equals),
              dynamic.makeFilter(
                'userCrmProfileID',
                userCrmProfileID,
                dynamic.decoratorIsNotNullable(dynamic.equals),
              ),
              dynamic.makeFilter('date', period, dynamic.dateRange),
            )
            .join('&&'),
          select: dynamic.select(
            'id',
            'isActive',
            'baseRate',
            'billableRate',
            'duration',
            'billableDuration',
            'description',
            'date',
            'internal',
            'userCrmProfile.firstName as firstName',
            'userCrmProfile.lastName as lastName',
            'userCrmProfile.userPhoto as userPhoto',
            'timeTrackingActivity.title as timeTrackingActivityTitle',
          ),
          orderBy: dynamic.orderBy(order.field as any, order.order),
          count: true,
          take,
          skip,
        },
      }),
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getProjectTimeTrackingDuration: build.query<
      { duration: string; billableDuration: string }[],
      IGridTimeTrackingProjectItemArgs
    >({
      query: ({ userCrmProfileID, projectID, period, order }) => ({
        url: API_TIME_TRACKINGS.GET_ALL_DYNAMIC,
        params: {
          filter: dynamic
            .mergeFilters(
              dynamic.makeFilter('projectID', projectID, dynamic.equals),
              dynamic.makeFilter(
                'userCrmProfileID',
                userCrmProfileID,
                dynamic.decoratorIsNotNullable(dynamic.equals),
              ),
              dynamic.makeFilter('date', period, dynamic.dateRange),
            )
            .join('&&'),
          select: dynamic.select('duration', 'billableDuration'),
          orderBy: dynamic.orderBy(order.field as any, order.order),
        },
      }),
      providesTags: [{ type: REVALIDATE_TAG }],
      transformResponse: transformResponseDynamic,
    }),
    getTimeTrackingItem: build.query<ITimeTracking, string>({
      query: (id) => {
        return {
          url: API_TIME_TRACKINGS.GET_ALL_DYNAMIC,
          params: {
            filter: dynamic.makeFilter('id', id, dynamic.equals),
            select: dynamic.select(
              'userCrmProfileID',
              'timeTrackingActivityID',
              'projectID',
              'ticketActionItemID',
              'isActive',
              'date',
              'duration',
              'description',
              'id',
              'project.customerID as customerID',
              'ticketActionItem.ticketID as ticketID',
            ),
            take: 1,
          },
        };
      },
      transformResponse(result: DynamicResult<any>) {
        const newResult = transformResponseDynamicItem(result);
        return { ...newResult };
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getTimeTrackingPreview: build.query<ITimeTrackingPreview, string>({
      query: (id) => {
        return {
          url: API_TIME_TRACKINGS.GET_ALL_DYNAMIC,
          params: {
            filter: dynamic.makeFilter('id', id, dynamic.equals),
            select: dynamic.select(
              'id',
              'userCrmProfile.fullName as userCrmProfileName',
              'timeTrackingActivity.title as timeTrackingActivityTitle',
              'ticketActionItem.title as ticketActionItemTitle',
              'date',
              'duration',
            ),
            take: 1,
          },
        };
      },
      transformResponse(result: DynamicResult<any>) {
        const newResult = transformResponseDynamicItem(result);
        return { ...newResult };
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getTimeTrackingItemForAddReport: build.query<TimeTrackingItemForAddReport, string>({
      query: (id: string) => {
        return {
          url: API_TIME_TRACKINGS.GET_ALL_DYNAMIC,
          params: {
            filter: dynamic.makeFilter('id', id, dynamic.equals),
            select: dynamic.select(
              'id',
              'ticketActionItem.ticket.project.customer.companyName as customerName',
              'ticketActionItem.ticket.project.projectName as projectName',
              'ticketActionItem.ticket.project.customerID as customerID',
              'duration',
              'billableDuration',
              'userCrmProfileID',
              'description',
              'date',
              'ticketActionItem.activityReportDescription as activityReportDescription',
              'ticketActionItemID',
            ),
            take: 1,
          },
        };
      },
      transformResponse(result: DynamicResult<any>) {
        const newResult = transformResponseDynamicItem(result);
        return { ...newResult };
      },
      providesTags: (result, error, arg, meta) => [
        { type: REVALIDATE_TAG },
        ...(result?.ticketActionItemID
          ? [{ type: 'TicketActionItems' as const, id: result.ticketActionItemID }]
          : []),
      ],
    }),
    getGridTimeTracking: build.query<
      DynamicResult<IGridTimeTracking, { count: true }>,
      IGridTimeTrackingArgs
    >({
      query: ({
        userCrmProfileID,
        projectID,
        customerID,
        timeTrackingActivityID,
        period,
        orderBy,
      }) => ({
        url: API_TIME_TRACKINGS.GET_ALL_DYNAMIC,
        params: {
          filter: dynamic
            .mergeFilters(
              dynamic.makeFilter(
                'userCrmProfileID',
                userCrmProfileID,
                dynamic.decoratorIsNotNullable(dynamic.equals),
              ),
              dynamic.makeFilter(
                'timeTrackingActivityID',
                timeTrackingActivityID,
                dynamic.decoratorIsNotNullable(dynamic.equals),
              ),
              projectID
                ? dynamic.makeFilter(
                    'projectID',
                    projectID,
                    dynamic.decoratorIsNotNullable(dynamic.equals),
                  )
                : dynamic.makeFilter(
                    'project.customerID',
                    customerID,
                    dynamic.decoratorIsNotNullable(dynamic.equals),
                  ),
              dynamic.makeFilter('date', period, dynamic.dateRange),
            )
            .join('&&'),
          select: dynamic.select(
            'id',
            'projectID',
            'date',
            'duration',
            'description',
            'baseRate',
            'billableRate',
            'billableDuration',
            'internal',
            'timeTrackingActivityID',
            'userCrmProfileID',
            'userCrmProfile.firstName as employeeFirstName',
            'userCrmProfile.lastName as employeeLastName',
            'userCrmProfile.userPhoto as employeePhoto',
            'timeTrackingActivity.title as timeTrackingActivityTitle',
            'project.customer.companyName as customerName',
            'project.projectName as projectName',
          ),
          orderBy: dynamic.orderBy(orderBy.field as any, orderBy.order),
          count: true,
        },
      }),
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getGridTimeTrackingForTicketActionItems: build.query<
      DynamicResult<GridTimeTrackingForTicketActionItems>,
      IGridTimeTrackingForTicketActionItemsArgs
    >({
      queryFn: async ({ ticketActionItemID }) => {
        try {
          const { data } =
            await ServiceTimeTracking.getAllDynamic<GridTimeTrackingForTicketActionItems>({
              select: dynamic.select(
                'id',
                'userCrmProfileID',
                'new { userCrmProfile.appIdentityUserID, userCrmProfile.fullName, userCrmProfile.userPhoto } as userCrmProfile',
                'date',
                'timeTrackingActivityID',
                'ticketActionItemID',
                'duration',
                'billableDuration',
                'description',
                'new { timeTrackingActivity.id, timeTrackingActivity.title } as timeTrackingActivity',
                'project.customerID as customerID',
                'doneItems.All(d => d.reportDoneItems.All(r => r.isActive)) as isActive',
                'doneItems.Where(c => c.reportDoneItems.Count()>0).Select(d => new { d.id, d.isActive }) as doneItems',
                'doneItems.Where(c => c.description!=null&&c.reportDoneItems.Count()>0).Select(c => c.description).Take(1) as doneItemsDescriptionData',
                'doneItems.Select(k => (k.reportDoneItems.Select(c => c.report).FirstOrDefault())).FirstOrDefault() as report',
              ),
              filter: dynamic
                .mergeFilters(
                  dynamic.makeFilter('ticketActionItemID', ticketActionItemID, dynamic.equals),
                )
                .join('&&'),
              count: true,
            });
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result) => [
        { type: REVALIDATE_TAG },
        { type: 'ReportDoneItems' },
        { type: 'DoneItems' },
        ...(result
          ? result.value.map((row) => {
              return { type: 'DoneItems' as const, id: `timeTrackingID__${row.id}` };
            })
          : []),
      ],
    }),
    postTimeTrackingItem: build.mutation({
      queryFn: async (data: ITimeTrackPostInput) => {
        return ServiceTimeTracking.postItem(data);
      },
      invalidatesTags: (res, err, arg) => [
        { type: REVALIDATE_TAG },
        { type: DASHBOARD_REPORTS_REVALIDATE_TAG },
        ...(res?.ticketActionItemID
          ? [{ type: 'TicketActionItems' as const, id: res.ticketActionItemID }]
          : []),
      ],
    }),
    postTimeTrackingItemMultiple: build.mutation({
      queryFn: async (data: ITimeTrackPostMultipleInput) => {
        const result = await ServiceTimeTracking.postItemMultiple(data);
        return { data: result };
      },
      invalidatesTags: (res, error, arg) => [
        { type: REVALIDATE_TAG },
        { type: DASHBOARD_REPORTS_REVALIDATE_TAG },
        ...(arg.ticketActionItemID
          ? [{ type: 'TicketActionItems' as const, id: arg.ticketActionItemID }]
          : []),
      ],
    }),
    patchTimeTrackingItem: build.mutation({
      queryFn: async (data: ITimeTrackPatchInput) => {
        return ServiceTimeTracking.patchItem(data);
      },
      invalidatesTags: (result, error, arg) => [
        { type: REVALIDATE_TAG },
        { type: REVALIDATE_TAG, id: arg.id },
        { type: DASHBOARD_REPORTS_REVALIDATE_TAG },
      ],
    }),
    deleteTimeTrackingItem: build.mutation({
      queryFn: async (data: PatchPartial<ITimeTracking, 'id'>) => {
        try {
          const { data: doneItems } = await ServiceTimeTracking.getDoneItemsToDelete(data.id);

          await Promise.all(
            doneItems.map((doneItem) => {
              return ServiceTimeTracking.deleteTimeTrackingDoneItem(doneItem);
            }),
          );

          return ServiceTimeTracking.delete({ id: data.id });
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res, error, arg) => [
        { type: REVALIDATE_TAG },
        { type: DASHBOARD_REPORTS_REVALIDATE_TAG },
        { type: DASHBOARD_REPORTS_REVALIDATE_TAG },
        ...(res?.ticketActionItemID
          ? [{ type: 'TicketActionItems' as const, id: res?.ticketActionItemID }]
          : []),
      ],
    }),
  }),
});
