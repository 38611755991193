import { useCallback } from 'react';
import { useDataGridSorting } from './use-data-grid-sorting';

const ORDER_VALUES = [null, 'desc', 'asc'] as const;

interface Options {
  fieldKey: string;
}
export const useDataGridSortColumn = (options: Options) => {
  const { fieldKey } = options;
  const {
    value: { field, order },
    onChange,
  } = useDataGridSorting();
  const isSelected = field === fieldKey;

  const isAsc = isSelected && order === 'asc';
  const isDesc = isSelected && order === 'desc';

  const onNext = useCallback(() => {
    const currentIndex = !isSelected ? 0 : ORDER_VALUES.findIndex((item) => item === order);
    const nextIndex = currentIndex + 1;
    const nextItem =
      ORDER_VALUES[nextIndex] === undefined ? ORDER_VALUES[0] : ORDER_VALUES[nextIndex];
    onChange({ field: fieldKey, order: nextItem });
  }, [onChange, fieldKey, order, isSelected]);

  return { isAsc, isDesc, onNext };
};
