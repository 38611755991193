import React, { useEffect, useState } from 'react';

interface IProps {
  delay: number;
  children: React.ReactNode;
}
export const DelayRender: React.FC<IProps> = ({ delay, children }) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsReady(true), delay);

    return () => clearTimeout(timer);
  }, [delay]);

  return isReady ? <>{children}</> : null;
};
