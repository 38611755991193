import { Stack, StackProps } from '@mui/material';
import { useVirtualizer, VirtualizerOptions } from '@tanstack/react-virtual';
import React, { useRef } from 'react';
import { NativeScroll } from '../../../native-scroll';

export interface VirtualListBoxProps
  extends React.HTMLAttributes<HTMLElement>,
    Pick<VirtualizerOptions<any, any>, 'estimateSize'> {
  StackProps?: Partial<StackProps>;
}
export const VirtualListBox = React.forwardRef<HTMLDivElement, VirtualListBoxProps>(
  function VirtualListBoxComponent(props, ref) {
    const { children, StackProps, estimateSize, ...rest } = props;

    const scrollRef = useRef<HTMLDivElement>(null);

    const itemData: React.ReactElement[] = [];
    (children as React.ReactElement[]).forEach(
      (item: React.ReactElement & { children?: React.ReactElement[] }) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
      },
    );
    const itemCount = itemData.length;

    const virtualizer = useVirtualizer({
      count: itemCount,
      getScrollElement: () => scrollRef.current,
      estimateSize,
    });

    const items = virtualizer.getVirtualItems();

    return (
      <div ref={ref} role={'listbox'}>
        <NativeScroll ref={scrollRef} mode={'always'} {...rest}>
          <div style={{ height: virtualizer.getTotalSize(), position: 'relative' }}>
            <Stack
              {...StackProps}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${items[0]?.start ?? 0}px)`,
              }}
            >
              {items.map((virtualRow) => {
                return (
                  <div
                    key={virtualRow.key}
                    data-index={virtualRow.index}
                    ref={virtualizer.measureElement}
                  >
                    {itemData[virtualRow.index]}
                  </div>
                );
              })}
            </Stack>
          </div>
        </NativeScroll>
      </div>
    );
  },
);
