import {
  Button,
  ButtonProps,
  DialogActions,
  DialogContent,
  ModalProps,
  PaperProps,
} from '@mui/material';
import { DialogHeader } from 'components/dialog-header';
import {
  DraggableCalcInitPosition,
  DraggableModal,
  useDraggablePosition,
} from 'components/draggable-modal';
import { useTranslate } from 'hooks';
import React, { useCallback } from 'react';
import { calcInitCenterPosition } from 'utils/app-helpers';

interface Props {
  title?: React.ReactNode;
  isLoading: boolean;

  DialogProps?: Partial<ModalProps>;
  PaperProps?: Partial<PaperProps>;

  CloseProps?: Partial<Omit<ButtonProps, 'onClick'>>;
  onClose: () => void;

  ApplyProps?: Partial<Omit<ButtonProps, 'onClick'>>;
  onApply: () => void;

  children: React.ReactNode;
}
export const DialogApply: React.FC<Props> = ({
  title,
  isLoading,
  children,
  onApply,
  ApplyProps,
  onClose,
  CloseProps,
  DialogProps,
  PaperProps,
}) => {
  const { tp } = useTranslate();
  const calcInitPosition = useCallback<DraggableCalcInitPosition>(
    (paperRef) => calcInitCenterPosition(paperRef),
    [],
  );
  const [position, , { paperRef, onStopDrag }] = useDraggablePosition({
    calcInitPosition,
  });
  return (
    <DraggableModal
      {...DialogProps}
      open={true}
      onClose={onClose}
      paperRef={paperRef}
      PaperProps={{
        ...PaperProps,
        sx: { maxWidth: '45rem', cursor: 'move', ...PaperProps?.sx },
      }}
      DraggableProps={{
        handle: '.header',
        position: position,
        onStop: onStopDrag,
      }}
    >
      <DialogHeader isLoading={isLoading} title={title} onClose={onClose} className={'header'} />

      <DialogContent
        sx={{
          cursor: 'default',
        }}
      >
        {children}
      </DialogContent>
      <DialogActions
        sx={{
          cursor: 'default',
        }}
      >
        <Button color={'primary'} disabled={isLoading} {...CloseProps} onClick={onClose}>
          {CloseProps?.children ?? tp('cancel')}
        </Button>
        <Button
          color={'primary'}
          variant={'contained'}
          disabled={isLoading}
          {...ApplyProps}
          onClick={onApply}
        >
          {ApplyProps?.children ?? tp('apply')}
        </Button>
      </DialogActions>
    </DraggableModal>
  );
};
