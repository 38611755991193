import { Button } from '@mui/material';
import { DialogNotificationsCreate, NotificationsCreateModel } from 'components/notifications';
import { useCurrentUser, useEffectNotifyError, useOpen, useTranslate } from 'hooks';
import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import {
  apiAppNotifications,
  APP_NOTIFICATION_TYPE,
  getNotificationIcon,
} from 'services/app-notifications';
import { isFulfilledMutation } from 'utils/service';

const Icon = getNotificationIcon(APP_NOTIFICATION_TYPE.REQUESTS);

const useCreateMutation = apiAppNotifications.useCreateAppNotificationMutation;

interface Props {
  disabled: boolean;
  ticketActionItemID: string;
}
export const ControlAddNotification: React.FC<Props> = ({ disabled, ticketActionItemID }) => {
  const { tp } = useTranslate();
  const { isOpen, onOpen, onClose } = useOpen();
  const { appUserID } = useCurrentUser();

  const [create, resultCreate] = useCreateMutation();
  useEffectNotifyError(resultCreate.error);

  const onSubmit = useCallback(
    async (formData: NotificationsCreateModel) => {
      const res = await create({
        ...formData,
        reporterUserCrmProfileID: appUserID,
        ticketActionItemID,
        ticketActionItemCommentID: null,
      });

      if (isFulfilledMutation(res)) {
        onClose();
        toast.success(tp('success'));
      }
    },
    [create, ticketActionItemID, appUserID, tp, onClose],
  );

  const isLoading = resultCreate.isLoading;

  return (
    <>
      <Button startIcon={<Icon />} disabled={disabled} onClick={onOpen} color={'primary'}>
        {tp('add-notification')}
      </Button>
      {isOpen && (
        <DialogNotificationsCreate
          type={APP_NOTIFICATION_TYPE.ACTIONS}
          isLoading={isLoading}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      )}
    </>
  );
};
