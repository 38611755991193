import { schemaBaseImage } from 'services/@shared/images';
import { InferType } from 'yup';

export const schemaImageData = schemaBaseImage.pick([
  'id',
  'description',
  'entryDate',
  'userCrmProfileID',
]);
export type ImageData = InferType<typeof schemaImageData>;
export type ImageValue = InferType<typeof schemaBaseImage>;
