import { Box, Typography } from '@mui/material';
import { useTranslate } from 'hooks';
import ExcelIcon from 'images/excel.svg';
import React from 'react';

interface Props {
  label: React.ReactNode;
}
export const BaseLabelExcel: React.FC<Props> = ({ label }) => {
  const { tp } = useTranslate();
  return (
    <Box display={'flex'} gap={1}>
      <img src={ExcelIcon} alt={tp('excel-icon')} />
      <Typography
        color={'black'}
        alignSelf={'center'}
        fontWeight={'500'}
        fontSize={'1.4rem'}
        component={'h5'}
      >
        {label}
      </Typography>
    </Box>
  );
};
