import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogActions, DialogContent, Theme, useMediaQuery } from '@mui/material';
import { ButtonCancel, ButtonCreate } from 'components/buttons';
import { DialogHeader } from 'components/dialog-header';
import { useEffectNotifyError, useTranslate } from 'hooks';
import { memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { apiTickets, schemaTicket } from 'services/tickets';
import { isFulfilledMutation } from 'utils/service';
import { Form } from './form';

const schema = schemaTicket;
const defaultValues = schema.cast({});

interface Props {
  initValues: Partial<typeof defaultValues>;
  onClose: () => void;
  onDone: () => void;
}

export const TicketNew = memo<Props>(({ initValues, onDone, onClose }) => {
  const { tp } = useTranslate();

  const methods = useForm({
    defaultValues: schema.cast(
      {
        ...initValues,
      },
      { assert: false },
    ),
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });
  const { handleSubmit, getValues } = methods;

  const [postItem, { error: errorPost, isLoading: isLoadingPost }] =
    apiTickets.usePostTicketMutation();
  useEffectNotifyError(errorPost);

  const isLoading = isLoadingPost;

  const onSubmit = useCallback(async () => {
    const formData = getValues();
    const result = await postItem({
      ...formData,
      mustTrackTime: formData.mustTrackTime ?? undefined,
      isActive: formData.isActive ?? undefined,
      done: formData.done ?? undefined,
    });

    if (isFulfilledMutation(result)) {
      onClose();
      onDone();
    }
  }, [postItem, onClose, getValues, onDone]);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Dialog open={true} fullWidth maxWidth={'md'} fullScreen={isMobile}>
      <DialogHeader isLoading={isLoading} title={tp('create-new')} onClose={onClose} />
      <DialogContent>
        <FormProvider {...methods}>
          <Form isLoading={isLoading} />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <ButtonCancel disabled={isLoading} onClick={onClose}>
          {tp('close')}
        </ButtonCancel>
        <ButtonCreate disabled={isLoading} onClick={handleSubmit(onSubmit)}>
          {tp('create')}
        </ButtonCreate>
      </DialogActions>
    </Dialog>
  );
});
