import React, { useMemo } from 'react';

import style from './index.module.scss';

import CloseIcon from '@mui/icons-material/Close';
import {
  DialogTitle,
  DialogTitleProps,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslate } from 'hooks';
import variables from 'styles/config.scss';
import { hexToRgb } from 'utils/colors';

export interface DialogHeaderProps extends Omit<DialogTitleProps, 'title'> {
  startIcon?: React.ReactNode;
  isLoading?: boolean;
  title?: React.ReactNode;
  disabled?: boolean;
  onClose?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  bColor?: string;
}

export const DialogHeader: React.FC<DialogHeaderProps> = ({
  isLoading,
  title,
  onClose,
  className,
  disabled,
  children,
  bColor = variables.colorPrimary,
  ...rest
}) => {
  const { tp } = useTranslate();
  const [r, g, b] = useMemo(() => hexToRgb(bColor), [bColor]);
  return (
    <Stack
      className={clsx(style.root, className)}
      direction={'row'}
      style={
        {
          '--b-color': bColor,
          '--b-color-light': `rgba(${r}, ${g}, ${b}, 0.2)`,
          ...rest.style,
        } as typeof rest.style
      }
    >
      {title && (
        <DialogTitle
          variant={'h3'}
          fontWeight={400}
          minWidth={0}
          sx={{
            flex: '1 1 auto',
            backgroundColor: 'transparent',
            overflowWrap: 'break-word',
            ...rest.sx,
          }}
          color={'inherit'}
          {...rest}
        >
          {title}
        </DialogTitle>
      )}
      {children}
      {onClose && (
        <IconButton className={style.close} onClick={onClose} disabled={disabled} color={'default'}>
          <Tooltip title={tp('close')}>
            <CloseIcon />
          </Tooltip>
        </IconButton>
      )}
      <LinearProgress
        className={clsx(style.progress, {
          [style.progressVisible]: isLoading,
        })}
        color={'info'}
      />
    </Stack>
  );
};
