import TodayIcon from '@mui/icons-material/Today';
import { IconButton, Popover } from '@mui/material';
import { AppInput, AppInputProps } from 'components/app-input';
import { format, isSameDay } from 'date-fns';
import { useMergeRef, useOpen } from 'hooks';
import React, { ForwardedRef, forwardRef, memo, useCallback, useMemo, useRef } from 'react';
import variables from 'styles/config.scss';
import { convertToDate, isDateLike } from 'utils/dates';
import { composeFunctions } from 'utils/other';
import { CalendarRangePicker, CalendarRangePickerProps } from '../calendar-range-picker';

const DEFAULT_INPUT_FORMAT = 'dd/MM/yyyy';

interface Classes {
  root: string;
}

interface Props {
  value: any[];
  onChange: (value: Date[]) => void;
  onApply?: (value: Date[]) => void;
  closeOnApply?: boolean;
  className?: string;
  classes?: Partial<Classes>;
  inputFormat?: string;
  inputDivider?: string;
  isOpenPicker?: boolean;
  CalendarProps?: Partial<Omit<CalendarRangePickerProps, 'value' | 'onChange'>>;
}

const Component = (
  {
    value: originValue,
    inputFormat = DEFAULT_INPUT_FORMAT,
    inputDivider = ' - ',
    onChange,
    closeOnApply = true,
    onApply,
    isOpenPicker,
    CalendarProps,
    ...rest
  }: Props & Omit<AppInputProps, 'value' | 'onChange' | 'onClick'>,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const refTextField = useRef<HTMLInputElement>(null);
  const { isOpen, onOpen, onClose } = useOpen(isOpenPicker);

  const inputValue = useMemo(() => {
    if (
      originValue.length === 2 &&
      isSameDay(convertToDate(originValue[0]), convertToDate(originValue[1]))
    ) {
      return format(convertToDate(originValue[0]), inputFormat);
    }
    return originValue
      .filter(isDateLike)
      .map((v) => format(convertToDate(v), inputFormat))
      .join(inputDivider);
  }, [originValue, inputFormat, inputDivider]);
  const checkApply = useCallback(
    (value: Date[]) => {
      if (value.length === 2) {
        onApply && onApply(value);
        closeOnApply && onClose();
      }
    },
    [onApply, onClose, closeOnApply],
  );

  const onClear = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onChange([]);
      onApply && onApply([]);
    },
    [onChange, onApply],
  );

  return (
    <>
      <AppInput
        variant={'standard'}
        value={inputValue}
        fullWidth={true}
        sx={{ minWidth: '22.6rem' }}
        {...rest}
        onClick={rest.disabled ? undefined : onOpen}
        inputRef={useMergeRef(ref, refTextField)}
        InputProps={{
          endAdornment: (
            <IconButton
              disabled={rest.disabled}
              size={'small'}
              sx={{ margin: '-0.8rem 0', color: variables.colorGrey }}
            >
              <TodayIcon />
            </IconButton>
          ),
          ...rest.InputProps,
        }}
        onClear={composeFunctions(onClear, rest.onClear)}
      />
      <Popover
        anchorEl={refTextField.current}
        open={isOpen}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <CalendarRangePicker
          value={originValue}
          onChange={composeFunctions(onChange, checkApply)}
          {...CalendarProps}
        />
      </Popover>
    </>
  );
};

export const DateRangePicker = memo(forwardRef(Component)) as typeof Component;
