import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { useTranslate } from 'hooks';
import React, { memo, useCallback } from 'react';
import { ITicket } from 'services/tickets';
import variables from 'styles/config.scss';
import { useCheckDoneEditTicket } from '../../../../hooks';
import { MenuPriorities } from './menu-priorities';
import { MenuStatuses } from './menu-statuses';

interface Props {
  data: ITicket;
}

export const BoardTicketMenu = memo<Props>(
  ({ data: { id, ticketStatusID, priorityID, ticketTypeID, itemKey } }) => {
    const { tp } = useTranslate();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleOpen = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
      },
      [setAnchorEl],
    );
    const handleClose = useCallback(() => {
      setAnchorEl(null);
    }, [setAnchorEl]);

    const { onEdit } = useCheckDoneEditTicket();
    const handleEdit = useCallback(() => {
      onEdit({ id, key: String(itemKey) });
      handleClose();
    }, [id, handleClose, onEdit, itemKey]);

    return (
      <Box>
        <IconButton size={'small'} onClick={handleOpen}>
          <MoreVertIcon color={'inherit'} sx={{ color: variables.colorGrey }} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuStatuses id={id} ticketStatusID={ticketStatusID} onClose={handleClose} />
          <Divider />
          <MenuPriorities id={id} priorityID={priorityID} onClose={handleClose} />
          <Divider />
          <MenuItem onClick={handleEdit}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{tp('edit')}</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <CloseIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{tp('cancel')}</ListItemText>
          </MenuItem>
        </Menu>
      </Box>
    );
  },
);
