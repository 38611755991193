import { ActionMatchingPattern } from '@redux-saga/types';
import { apiTicketActionItems } from 'services/ticket-action-items';
import { apiTimeTracking } from 'services/time-trackings';
import { call, debounce, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import { workerErrorNotifyThunk } from 'utils/sagas';
import { actionCheckDoneUnAssignFetchAll, actionCheckDoneUnAssignFetchItem } from './actions';
import { selectCheckDoneUnAssignData } from './selectors';

function* sagaRefetchTicketActionItem(ticketActionItemID: string) {
  const items = yield* select(selectCheckDoneUnAssignData);

  const item = items.find((item) => item.id === ticketActionItemID);

  if (item) {
    yield* put(actionCheckDoneUnAssignFetchItem(String(item.id)));
  }
}

function* watchTrackTimePost(
  action: ActionMatchingPattern<
    typeof apiTimeTracking.endpoints.postTimeTrackingItem.matchFulfilled
  >,
) {
  const { payload } = action;
  const ticketActionItemID = payload.ticketActionItemID;

  if (ticketActionItemID) {
    yield* call(sagaRefetchTicketActionItem, ticketActionItemID);
  }
}
function* watchTrackTimePostMultiple(
  action: ActionMatchingPattern<
    typeof apiTimeTracking.endpoints.postTimeTrackingItemMultiple.matchFulfilled
  >,
) {
  const {
    meta: {
      arg: { originalArgs },
    },
  } = action;

  const ticketActionItemID = originalArgs.ticketActionItemID;
  if (ticketActionItemID) {
    yield* call(sagaRefetchTicketActionItem, ticketActionItemID);
  }
}

function* watchUpdateTicketActionItemError(
  action: ActionMatchingPattern<
    typeof apiTicketActionItems.endpoints.patchTicketActionItem.matchRejected
  >,
) {
  const {
    meta: {
      arg: { originalArgs },
    },
  } = action;

  const id = originalArgs.id;
  if (id) {
    yield* call(sagaRefetchTicketActionItem, id);
  }
}

function* watchTrackTimePatch(
  action: ActionMatchingPattern<
    typeof apiTimeTracking.endpoints.patchTimeTrackingItem.matchFulfilled
  >,
) {
  const {
    meta: {
      arg: { originalArgs },
    },
  } = action;

  const ticketActionItemID = originalArgs.ticketActionItemID;

  if (ticketActionItemID) {
    yield* call(sagaRefetchTicketActionItem, ticketActionItemID);
  }
}

function* watchTrackTimeDelete(
  action: ActionMatchingPattern<
    typeof apiTimeTracking.endpoints.deleteTimeTrackingItem.matchFulfilled
  >,
) {
  const {
    meta: {
      arg: { originalArgs },
    },
  } = action;

  const ticketActionItemID = originalArgs.ticketActionItemID;

  if (ticketActionItemID) {
    yield* call(sagaRefetchTicketActionItem, ticketActionItemID);
  }
}

function* watchDeleteTicketActionItemError(
  action: ActionMatchingPattern<
    | typeof apiTicketActionItems.endpoints.deleteTicketActionItem.matchRejected
    | typeof apiTicketActionItems.endpoints.deactivateTicketActionItem.matchRejected
  >,
) {
  const items = yield* select(selectCheckDoneUnAssignData);

  const {
    meta: {
      arg: { originalArgs },
    },
  } = action;

  const item = items.find((item) => item.id === originalArgs.id);

  if (item) {
    yield* put(actionCheckDoneUnAssignFetchAll());
  }
}

export const sagaCheckDoneUnAssign = [
  //start: time tracking
  takeLatest(apiTimeTracking.endpoints.postTimeTrackingItem.matchFulfilled, watchTrackTimePost),
  takeLatest(
    apiTimeTracking.endpoints.postTimeTrackingItemMultiple.matchFulfilled,
    watchTrackTimePostMultiple,
  ),
  takeEvery(apiTimeTracking.endpoints.patchTimeTrackingItem.matchFulfilled, watchTrackTimePatch),
  takeEvery(apiTimeTracking.endpoints.deleteTimeTrackingItem.matchFulfilled, watchTrackTimeDelete),
  //start: time tracking
  takeEvery(
    apiTicketActionItems.endpoints.patchTicketActionItem.matchRejected,
    watchUpdateTicketActionItemError,
  ),
  takeLatest(
    [
      apiTicketActionItems.endpoints.deleteTicketActionItem.matchRejected,
      apiTicketActionItems.endpoints.deactivateTicketActionItem.matchRejected,
    ],
    watchDeleteTicketActionItemError,
  ),

  // handle errors
  debounce(
    500,
    [actionCheckDoneUnAssignFetchAll.rejected, actionCheckDoneUnAssignFetchItem.rejected],
    workerErrorNotifyThunk,
  ),
];
