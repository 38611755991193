import { Box } from '@mui/material';
import clsx from 'clsx';
import type { Identifier, XYCoord } from 'dnd-core';
import React, { CSSProperties, ForwardedRef, forwardRef, useMemo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useMergeRef } from '../../../../hooks';
import { useDataGridDragAndDrop, useDataGridSettings } from '../../hooks';
import { IDataGridColumnParsed, IDataGridRowProps } from '../../models';
import { DataGridColumnCell } from '../data-grid-column';

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export interface DataGridTableRowProps<T extends Record<string, any>> {
  ref?: ForwardedRef<HTMLElement>;
  columns: IDataGridColumnParsed<T>[];
  row: any;
  rowID: string;
  index: number;
  dataIndex?: number;
  style?: CSSProperties;
  dragType: string;
  rowProps?: IDataGridRowProps<T>;
  className?: string;
}

const DataGridTableRowComponent = <T extends Record<string, any>>(
  {
    rowID,
    index,
    row,
    columns,
    dragType,
    rowProps,
    dataIndex,
    style,
  }: React.PropsWithRef<DataGridTableRowProps<T>>,
  forwardedRef: ForwardedRef<HTMLTableRowElement>,
) => {
  const { moveRows, endMoveRows } = useDataGridDragAndDrop();
  const ref = useRef<HTMLTableRowElement>(null);
  const mergedRef = useMergeRef(ref, forwardedRef);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>(
    {
      accept: dragType,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      hover(item: DragItem, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        // Time to actually perform the action
        moveRows(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      },
      drop: (item) => {
        endMoveRows();
      },
    },
    [moveRows, endMoveRows, index, dragType],
  );

  const [{ isDragging }, drag, preview] = useDrag({
    type: dragType,
    item: () => {
      return { id: rowID, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const props = useMemo(() => {
    return {
      ...(typeof rowProps === 'function' ? rowProps({ row }) : rowProps),
    };
  }, [row, rowProps]);

  const opacity = isDragging ? 0 : 1;

  const _style = { ...props?.style, opacity, ...style };

  preview(drop(ref));
  const { classes } = useDataGridSettings();

  const renderCell = (column: IDataGridColumnParsed<any>) => {
    const { cellOptions } = column;

    const id = row['field'];
    return <DataGridColumnCell key={id} row={row} drag={drag} options={cellOptions} />;
  };

  return (
    <Box
      {...props}
      style={_style}
      className={clsx(props.className, classes.row)}
      ref={mergedRef}
      component={'tr'}
      data-handler-id={handlerId}
      data-index={dataIndex}
    >
      {columns.map(renderCell)}
    </Box>
  );
};

export const DataGridTableRow = forwardRef(
  DataGridTableRowComponent,
) as typeof DataGridTableRowComponent;
