export type EventEmitterHandler<
  Events,
  K extends keyof Events = keyof Events,
> = Events[K] extends void ? () => void : (params: Events[K]) => void;

export type EventEmitterEmit<Events, K extends keyof Events = keyof Events> = Events[K] extends void
  ? [K]
  : [K, Events[K]];

export type EventEmitterHandlers<Events extends Record<string, any>> = {
  [K in keyof Events]: EventEmitterHandler<Events, K>;
};

export class EventEmitter<Events extends Record<string, any>> {
  protected listeners: Record<keyof Events, any[]> = {} as Record<
    keyof Events,
    EventEmitterHandler<Events>[]
  >;

  on<K extends keyof Events>(eventName: K, handler: EventEmitterHandler<Events, K>) {
    if (!this.listeners[eventName]) {
      this.listeners[eventName] = [];
    }
    this.listeners[eventName].push(handler);
  }

  off<K extends keyof Events>(eventName: K, handler: EventEmitterHandler<Events, K>) {
    if (!this.listeners[eventName]) {
      return;
    }
    this.listeners[eventName] = this.listeners[eventName].filter((cb) => cb !== handler);
  }

  emit<K extends keyof Events>(...args: EventEmitterEmit<Events, K>) {
    if (!this.listeners[args[0]]) {
      return;
    }
    this.listeners[args[0]].forEach((handler) => {
      handler(args[1]);
    });
  }
}
