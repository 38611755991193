import { FixServerObject } from 'utils/types';

const API = 'UserCrmProfilePermissions';

export const API_USER_CRM_PROFILE_PERMISSIONS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<IUserCrmProfilePermissions, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<IUserCrmProfilePermissions, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IUserCrmProfilePermissions
  extends FixServerObject<Components.Schemas.UserCrmProfilePermission, 'id' | 'title'> {}

export class UserCrmProfilePermissions {
  id!: string;
  isActive = true;
  title = '';
  isAllowToEditSetting = false;
  isAllowToEditLabels = false;
  isAllowToEditProfile = false;
  isAllowToEditCrmUsers = false;
  isAllowToEditPermissions = false;
  isAllowToViewLog = false;
  isAllowToViewSensitiveInformation = false;
  isAllowToResetSensitiveInformation = false;
}

export interface IGridCrmPermissions extends IUserCrmProfilePermissions {}
