import { yupResolver } from '@hookform/resolvers/yup';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Dialog, DialogActions, DialogContent, Grid, InputAdornment } from '@mui/material';
import { AppDatePicker } from 'components/app-date-picker';
import { AppInput } from 'components/app-input';
import { AppSelect } from 'components/app-select';
import { AppTimeDuration } from 'components/app-time-duration';
import { ButtonCancel, ButtonCreate } from 'components/buttons';
import { DialogHeader } from 'components/dialog-header';
import { BaseBreadcrumbs, BaseDialogTitle } from 'components/tickets';
import { WeekDaysInput } from 'components/week-days-input';
import {
  useCurrentUser,
  useEffectNotifyError,
  useFieldProps,
  useSourceTimeTrackingActivities,
  useTranslate,
} from 'hooks';
import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { apiTimeTracking, schemaTimeTrackingMultiple } from 'services/time-trackings';
import { handleEventStopAndPrevent } from 'utils/handlers';
import { composeFunctions } from 'utils/other';
import { isFulfilledMutation } from 'utils/service';
import { InferType } from 'yup';
import { useTicketActionItemInformation } from '../../../../hooks/use-ticket-information';

const usePostMutation = apiTimeTracking.usePostTimeTrackingItemMultipleMutation;

const schema = schemaTimeTrackingMultiple.pick([
  'timeTrackingActivityID',
  'duration',
  'description',
  'date',
  'dates',
]);

type FormModel = InferType<typeof schema>;
interface Props {
  ticketActionItemID: string;
  onClose: () => void;
}

export const TicketActionItemDurationDialogNew: React.FC<Props> = ({
  onClose,
  ticketActionItemID,
}) => {
  const { appUserID } = useCurrentUser();
  const { tp } = useTranslate();
  const methods = useForm({ defaultValues: schema.cast({}), resolver: yupResolver(schema) });
  const getFieldProps = useFieldProps();
  const { control, watch, setValue, handleSubmit } = methods;

  const resultInfo = useTicketActionItemInformation(ticketActionItemID);
  useEffectNotifyError(resultInfo.error);
  const { customer, project, ticket, ticketActionItem } = resultInfo;
  const projectID = project?.id;

  const breadcrumbs = useMemo(
    () => [
      ...(customer ? [{ label: customer.title }] : []),
      ...(ticket ? [{ label: ticket.title }] : []),
    ],
    [customer, ticket],
  );

  const date = watch('date');
  const onChangeDate = useCallback(
    (v: string | null) => {
      setValue('dates', v ? [v] : []);
    },
    [setValue],
  );

  const sourceActivities = useSourceTimeTrackingActivities();

  const [postItem, resultPost] = usePostMutation();
  useEffectNotifyError(resultPost.error);

  const onSubmit = useCallback(
    async (formData: FormModel) => {
      if (!projectID) return;

      const result = await postItem({
        ...formData,
        projectID: projectID,
        userCrmProfileID: appUserID,
        ticketActionItemID,
      });

      if (isFulfilledMutation(result)) {
        onClose();
      }
    },
    [postItem, projectID, ticketActionItemID, onClose, appUserID],
  );

  const isLoading = resultInfo.isFetching || resultPost.isLoading;
  return (
    <Dialog open={true} fullWidth maxWidth={'md'} onDoubleClick={handleEventStopAndPrevent}>
      <DialogHeader title={tp('track-time')} onClose={onClose} />
      <DialogContent>
        <BaseBreadcrumbs isLoading={resultInfo.isFetching} items={breadcrumbs} />
        <BaseDialogTitle isLoading={resultInfo.isFetching} mt={1.5} mb={2}>
          {ticketActionItem?.title}
        </BaseDialogTitle>
        <Grid container rowSpacing={0.2} columnSpacing={2.8}>
          <Grid item container xs={12} md={5.8} columnSpacing={2} rowSpacing={0.4}>
            <Grid item xs={'auto'}>
              <Controller
                control={control}
                name={'duration'}
                render={(renderProps) => (
                  <AppTimeDuration
                    {...getFieldProps(renderProps)}
                    disabled={isLoading}
                    disableClearable
                    sx={{
                      minWidth: '14rem',
                    }}
                    RenderInputProps={{
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position={'end'}>
                            <AccessTimeIcon />
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={true}>
              <Controller
                name={'date'}
                control={control}
                render={(renderProps) => {
                  const props = getFieldProps(renderProps);
                  return (
                    <AppDatePicker
                      {...props}
                      onChange={composeFunctions(props.onChange, onChangeDate)}
                      disabled={isLoading}
                      slotProps={{
                        textField: { fullWidth: true },
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={'timeTrackingActivityID'}
                render={(renderProps) => (
                  <AppSelect
                    {...getFieldProps(renderProps)}
                    disabled={isLoading}
                    disableClearable
                    loading={sourceActivities.isLoading}
                    options={sourceActivities.data}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6.2} display={'grid'}>
            <Controller
              name={'dates'}
              control={control}
              render={(renderProps) => (
                <WeekDaysInput date={date} {...getFieldProps(renderProps)} label={tp('apply-to')} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={'description'}
              render={(renderProps) => (
                <AppInput
                  {...getFieldProps(renderProps)}
                  multiline
                  minRows={3}
                  disabled={isLoading}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonCancel onClick={onClose} color={'primary'} />
        <ButtonCreate color={'primary'} disabled={isLoading} onClick={handleSubmit(onSubmit)}>
          {tp('track-time')}
        </ButtonCreate>
      </DialogActions>
    </Dialog>
  );
};
