import { useTheme } from '@mui/material';
import {
  DraggableCalcInitPosition,
  DraggableModal,
  useDraggablePosition,
} from 'components/draggable-modal';
import { Loading } from 'components/loading';
import { ContentReportCreate } from 'components/tickets';
import { useEffectNotifyError, useOpen } from 'hooks';
import React, { useCallback, useMemo } from 'react';
import { apiTimeTracking } from 'services/time-trackings';
import { calcInitCenterPosition } from 'utils/app-helpers';
import { ReportsListDialogContent } from '../reports-list-dialog-content';

const useQuery = apiTimeTracking.useGetTimeTrackingItemForAddReportQuery;

interface Props {
  timeTrackingID: string;
  onClose: () => void;
}
export const AddToReportPopup: React.FC<Props> = ({ onClose, timeTrackingID }) => {
  const {
    isOpen: isOpenAddNewReport,
    onOpen: onOpenNewReport,
    onClose: onCloseNewReport,
  } = useOpen();

  const { data, isSuccess, isFetching, error } = useQuery(timeTrackingID || '', {
    skip: !timeTrackingID,
  });
  useEffectNotifyError(error);

  const calcInitPosition = useCallback<DraggableCalcInitPosition>(
    (paperRef) => calcInitCenterPosition(paperRef),
    [],
  );
  const [position, , { paperRef, onStopDrag }] = useDraggablePosition({
    calcInitPosition,
  });

  const theme = useTheme();

  const reportMeta = useMemo(() => {
    return { customerName: data?.customerName, projectName: data?.projectName };
  }, [data]);

  return (
    <DraggableModal
      open={true}
      onClose={onClose}
      paperRef={paperRef}
      PaperProps={{
        sx: { maxWidth: theme.breakpoints.values.md, cursor: 'move' },
      }}
      DraggableProps={{
        position: position,
        onStop: onStopDrag,
      }}
    >
      {!isOpenAddNewReport && (
        <ReportsListDialogContent
          onAdd={onOpenNewReport}
          onClose={onClose}
          duration={data?.duration}
          userCrmProfileID={data?.userCrmProfileID}
          timeTrackingID={timeTrackingID}
          activityReportDescription={data?.activityReportDescription}
          customerID={data?.customerID}
          date={data?.date}
          ticketActionItemID={data?.ticketActionItemID}
        />
      )}
      {isSuccess && isOpenAddNewReport && (
        <ContentReportCreate
          onClose={onCloseNewReport}
          date={data?.date}
          customerID={data?.customerID ?? null}
          meta={reportMeta}
        />
      )}
      {isFetching && <Loading />}
    </DraggableModal>
  );
};
