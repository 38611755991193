import {
  TicketActionItemCommentsDialog,
  TicketActionItemDialogEdit,
} from 'components/ticket-action-item-containers';
import { useEffectNotifyError, useOpen } from 'hooks';
import React from 'react';
import { AppNotificationLatest } from 'services/app-notifications';
import { apiTicketActionItemComments } from 'services/ticket-action-item-comments';
import { composeFunctions } from 'utils/other';

const useFetchCommentQuery =
  apiTicketActionItemComments.useGetTicketActionItemIDByCommentItemKeyQuery;

interface DialogCommentProps {
  itemKey: string;
  onClose: () => void;
}
const DialogComment: React.FC<DialogCommentProps> = ({ itemKey, onClose }) => {
  const resultComment = useFetchCommentQuery(itemKey);
  useEffectNotifyError(resultComment.error);

  const ticketActionItemID = resultComment.data?.ticketActionID;
  const actionItemKey = resultComment.data?.actionItemKey;

  const openAction = useOpen();
  const onCloseAll = composeFunctions(onClose, openAction.onClose);

  return (
    <>
      {ticketActionItemID && (
        <TicketActionItemCommentsDialog
          ticketActionItemID={ticketActionItemID}
          ticketActionItemCommentID={resultComment.data?.id}
          onClose={onClose}
          onOpenAction={openAction.onOpen}
        />
      )}
      {actionItemKey && openAction.isOpen && (
        <TicketActionItemDialogEdit
          itemKey={actionItemKey}
          onClose={onCloseAll}
          onDone={onCloseAll}
        />
      )}
    </>
  );
};

interface DialogActionProps {
  itemKey: string;
  onClose: () => void;
}
const DialogAction: React.FC<DialogActionProps> = ({ itemKey, onClose }) => {
  return <TicketActionItemDialogEdit itemKey={itemKey} onClose={onClose} onDone={onClose} />;
};

interface Props {
  row: Pick<AppNotificationLatest, 'itemKey' | 'commentItemKey'>;
  onClose: () => void;
}

export const DialogNotificationsView: React.FC<Props> = ({ row, onClose }) => {
  const itemID = row.itemKey;
  const commentItemKey = row?.commentItemKey;

  if (itemID) {
    return <DialogAction itemKey={itemID} onClose={onClose} />;
  }

  if (commentItemKey) {
    return <DialogComment itemKey={commentItemKey} onClose={onClose} />;
  }

  throw new Error('DialogNotificationsView: unexpected behaviour');
};
