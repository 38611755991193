import SearchIcon from '@mui/icons-material/Search';
import { AppInput, AppInputProps } from 'components/app-input';
import { useTranslate } from 'hooks';
import { ForwardedRef, forwardRef, memo } from 'react';
import variables from 'styles/config.scss';
import style from './index.module.scss';

const Input = (props: AppInputProps, ref: ForwardedRef<HTMLInputElement>) => {
  const { tp } = useTranslate();
  return (
    <AppInput
      placeholder={tp('search-by-keyword')}
      {...props}
      InputProps={{
        endAdornment: <SearchIcon sx={{ color: variables.colorGrey }} />,
        classes: {
          input: style.input,
        },

        ...props.InputProps,
      }}
      ref={ref}
    />
  );
};

export const AppInputSearch = memo(forwardRef(Input)) as typeof Input;
