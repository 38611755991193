import { FixServerObject, PatchPartial } from 'utils/types';
import * as yup from 'yup';
import { BaseImage } from '../@shared/images';

const API = 'TicketActionItemComments';

export const API_TICKET_ACTION_ITEM_COMMENTS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ITicketActionItemComment, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ITicketActionItemComment, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface ITicketActionItemComment
  extends FixServerObject<
    Components.Schemas.TicketActionItemComment,
    'id' | 'userCrmProfileID' | 'ticketActionItemID' | 'description'
  > {}

export interface ITicketActionItemKey {
  ticketActionID: Components.Schemas.TicketActionItem['id'];
  id: string;
  itemKey: string;
  actionItemKey: string;
}

export const schemaTicketActionItemComment = yup.object({
  id: yup.string().notRequired().default(undefined),
  description: yup.string().required('rule-required').min(1, 'rule-min-length').default(''),
});

export interface ITicketActionItemCommentPost
  extends Omit<ITicketActionItemComment, 'id' | 'updatedDate'> {
  ticketActionItemID: string;
  images: BaseImage[];
  notificationUserCrmProfileIDs: string[];
}

export interface ITicketActionItemCommentPatch
  extends PatchPartial<ITicketActionItemComment, 'id' | 'ticketActionItemID'> {
  images?: BaseImage[];
  notificationUserCrmProfileIDs: string[];
}

export interface ITicketActionItemCommentDelete
  extends PatchPartial<ITicketActionItemComment, 'id' | 'ticketActionItemID'> {}

export interface ITicketActionItemCommentForm
  extends Pick<
    ITicketActionItemComment,
    | 'id'
    | 'itemKey'
    | 'userCrmProfileID'
    | 'ticketActionItemID'
    | 'description'
    | 'createdDate'
    | 'updatedDate'
  > {
  images: BaseImage[];
}
