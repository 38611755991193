import React from 'react';
import style from './index.module.scss';

interface Props {
  value: string;
  label: string;
}
export const InfoBox: React.FC<Props> = ({ label, value }) => {
  return (
    <div>
      <div className={style.label}>{label}</div>
      <div className={style.value}>{value}</div>
    </div>
  );
};
