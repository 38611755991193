import { makeFullName } from 'utils/app-helpers';
import { decoratorWithFiles } from 'utils/decorators';
import * as dynamic from 'utils/dynamic';
import { contains, dateRange, decoratorIsNotNullable, equals, mergeFilters } from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import {
  API_USER_CRM_PROFILES,
  IGridCrmProfileArgs,
  IGridCrmUsers,
  IUserCrmProfile,
} from './models';

type Model = IUserCrmProfile;
const REVALIDATE_TAG = 'UserCrmProfiles';

class Service extends DynamicService<Model> {
  @decoratorWithFiles('appIdentityUserID', 'userPhoto')
  async post(data: Omit<Model, 'id' | 'appIdentityUserID'>) {
    return super.post(data);
  }
  @decoratorWithFiles('appIdentityUserID', 'userPhoto')
  async patch(data: Partial<Model>) {
    return super.patch({
      userPhoto: data.userPhoto,
      appIdentityUserID: data.appIdentityUserID,
      isActive: data.isActive,
      firstName: data.firstName,
      lastName: data.lastName,
      nameHeb: data.nameHeb,
      dateOfBirth: data.dateOfBirth,
      personalEmail: data.personalEmail,
      mobilePhone: data.mobilePhone,
      slackUserName: data.slackUserName,
      userCrmProfilePermissionID: data.userCrmProfilePermissionID,
      gitHubUserName: data.gitHubUserName,
      color: data.color,
      isCoreTeamMember: data.isCoreTeamMember,
      isBillable: data.isBillable,
      startDate: data.startDate,
      absences: data.absences,
      noteFadeDefaultDuration: data.noteFadeDefaultDuration,
    });
  }
}

export const ServiceUserCrmProfiles = new Service({
  mainField: 'appIdentityUserID',
  getAll: API_USER_CRM_PROFILES.GET_ALL_DYNAMIC,
  post: API_USER_CRM_PROFILES.POST,
  patch: API_USER_CRM_PROFILES.PATCH,
  delete: API_USER_CRM_PROFILES.DELETE,
});

export interface UserCrmProfileSource
  extends Pick<IUserCrmProfile, 'lastName' | 'firstName' | 'isActive' | 'userPhoto' | 'color'> {
  id: string;
  fullName: string;
}
export interface UserCrmProfileSelectSource
  extends Pick<
    IUserCrmProfile,
    'lastName' | 'firstName' | 'userPhoto' | 'color' | 'isActive' | 'dateOfBirth'
  > {
  id: string;
  fullName: string;
}

export const apiUserCrmProfiles = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getUserCrmProfileSource: build.query<UserCrmProfileSource[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceUserCrmProfiles.getAllDynamic<UserCrmProfileSource>({
            select: dynamic.select(
              'appIdentityUserID as id',
              'userPhoto',
              'firstName',
              'lastName',
              'fullName',
              'isActive',
              'color',
            ),
            orderBy: 'isActive desc, firstName,lastName asc',
          });
          const data = value.map((item) => ({ ...item, fullName: makeFullName(item) }));

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getUserCrmProfileSelectSource: build.query<UserCrmProfileSelectSource[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceUserCrmProfiles.getAllDynamic<UserCrmProfileSelectSource>({
            select: dynamic.select(
              'appIdentityUserID as id',
              'userPhoto',
              'color',
              'firstName',
              'lastName',
              'isActive',
              'dateOfBirth',
            ),
            orderBy: 'isActive desc, firstName,lastName asc',
          });
          const data = value.map((item) => ({ ...item, fullName: makeFullName(item) }));
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getUserCrmProfile: build.query<Model, string>({
      queryFn: async (appIdentityUserID) => {
        try {
          const { data } = await ServiceUserCrmProfiles.getDynamic(appIdentityUserID);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    getGridUserCrmProfiles: build.query<
      DynamicResult<IGridCrmUsers, { count: true }>,
      IGridCrmProfileArgs
    >({
      query: ({ search, startDate, userCrmProfilePermissionID, take, skip, orderBy }) => ({
        url: API_USER_CRM_PROFILES.GET_ALL_DYNAMIC,
        params: {
          filter: mergeFilters(
            dynamic.makeFilter(
              ['firstName', 'lastName', 'slackUserName', 'gitHubUserName'],
              search,
              decoratorIsNotNullable(contains),
            ),
            dynamic.makeFilter('startDate', startDate, dateRange),
            dynamic.makeFilter(
              'userCrmProfilePermissionID',
              userCrmProfilePermissionID,
              decoratorIsNotNullable(equals),
            ),
          ).join('&&'),
          select: dynamic.select(
            'id',
            'userPhoto',
            'appIdentityUserID',
            'firstName',
            'lastName',
            'userCrmProfilePermissionID',
            'slackUserName',
            'gitHubUserName',
            'color',
            'email',
            'mobilePhone',
            'startDate',
            'isActive',
            'userCrmProfilePermission.title as position',
          ),
          orderBy:
            dynamic.orderBy(orderBy.field as any, orderBy.order) ||
            'userCrmProfilePermission.title,firstName,lastName',
          count: true,
          take,
          skip,
        },
      }),
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    postUserCrmProfile: build.mutation<void, Omit<Model, 'id' | 'appIdentityUserID'>>({
      queryFn: async (data) => {
        try {
          await ServiceUserCrmProfiles.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchUserCrmProfile: build.mutation<void, PatchPartial<Model, 'appIdentityUserID'>>({
      queryFn: async (data) => {
        try {
          await ServiceUserCrmProfiles.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { appIdentityUserID }) => [
        { type: REVALIDATE_TAG, id: appIdentityUserID },
      ],
    }),
    deleteUserCrmProfile: build.mutation<void, PatchPartial<Model, 'appIdentityUserID'>>({
      queryFn: async (data) => {
        try {
          await ServiceUserCrmProfiles.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { appIdentityUserID }) => [
        { type: REVALIDATE_TAG },
        { type: REVALIDATE_TAG, id: appIdentityUserID },
      ],
    }),
  }),
});
