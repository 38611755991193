import { select } from 'utils/dynamic';
import { apiRtk, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_USER_CRM_PROFILE_PERMISSIONS, IUserCrmProfilePermissions } from './models';

export * from './models';

type Model = IUserCrmProfilePermissions;
const REVALIDATE_TAG = 'UserCrmProfilePermissions';

type Source = Pick<Model, 'id' | 'title'>;
class Service extends DynamicService<Model> {}

export const ServiceUserCrmProfilePermissions = new Service({
  getAll: API_USER_CRM_PROFILE_PERMISSIONS.GET_ALL_DYNAMIC,
  post: API_USER_CRM_PROFILE_PERMISSIONS.POST,
  patch: API_USER_CRM_PROFILE_PERMISSIONS.PATCH,
  delete: API_USER_CRM_PROFILE_PERMISSIONS.DELETE,
});

export const apiUserCrmProfilePermissions = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getUserCrmProfilePermissionSource: build.query<Source[], void>({
      queryFn: async (id) => {
        try {
          const {
            data: { value },
          } = await ServiceUserCrmProfilePermissions.getAllDynamic<Source>({
            select: select('id', 'title'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchUserCrmProfilePermission: build.mutation<void, PatchPartial<Model, 'id'>>({
      queryFn: async (customer) => {
        try {
          await ServiceUserCrmProfilePermissions.patch(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_TAG },
        { type: REVALIDATE_TAG, id },
      ],
    }),
  }),
});
