import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { Divider, Stack, Typography } from '@mui/material';
import { useTranslate } from 'hooks';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

export const CommentPreview: React.FC<Props> = ({ children }) => {
  const { tp } = useTranslate();
  return (
    <div>
      <Typography
        color={'primary'}
        display={'flex'}
        alignItems={'center'}
        component={'div'}
        fontWeight={600}
        textTransform={'uppercase'}
        letterSpacing={1.25}
        gap={0.6}
        mt={1.6}
        mb={1}
      >
        <ChatBubbleOutlineIcon /> {tp('comments')}
      </Typography>
      <Stack spacing={1.8} divider={<Divider orientation={'horizontal'} />}>
        {children}
      </Stack>
    </div>
  );
};
