import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGridProjects } from 'services/projects';
import { actionProjectsGetDynamic } from './actions';

interface Filters {
  search: string;
  projectTypeID: string;
}
interface Pagination {
  take: number;
  count: number;
  page: number;
}
interface OrderBy {
  field: string;
  order: 'desc' | 'asc' | null;
}

interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;
  data: IGridProjects[];
  filters: Filters;
  pagination: Pagination;
  orderBy: OrderBy;
}

const initState = (): State => {
  return {
    error: null,
    isLoading: false,
    isInit: false,
    data: [],
    filters: {
      search: '',
      projectTypeID: '',
    },
    pagination: {
      take: 20,
      count: 0,
      page: 1,
    },
    orderBy: {
      field: 'projectName',
      order: 'asc',
    },
  };
};

const slice = createSlice({
  name: 'PROJECTS',
  initialState: initState(),
  reducers: {
    actionProjectsFiltersSet(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    actionProjectsFiltersReset(state) {
      state.filters = initState().filters;
    },
    actionProjectsOrderBySet(state, action: PayloadAction<Partial<OrderBy>>) {
      state.orderBy = { ...state.orderBy, ...action.payload };
    },
    actionProjectsRevalidate(state) {
      state.isInit = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionProjectsGetDynamic.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
      state.pagination.page = action.meta.arg ? action.meta.arg.page : state.pagination.page;
      state.pagination.take = action.meta.arg?.take || state.pagination.take;
    });
    builder.addCase(actionProjectsGetDynamic.fulfilled, (state, action) => {
      const {
        payload: { value, count },
      } = action;
      state.isLoading = false;
      state.isInit = true;
      state.data = value;
      state.pagination.count = count;
    });
    builder.addCase(actionProjectsGetDynamic.rejected, (state, action) => {
      const { error } = action;
      state.isLoading = false;
      state.error = error;
      state.isInit = true;
    });
  },
});

export const { actionProjectsOrderBySet, actionProjectsFiltersSet, actionProjectsRevalidate } =
  slice.actions;
export const reducerProjects = slice.reducer;
