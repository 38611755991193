import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { ButtonBase, Stack } from '@mui/material';
import React, { useCallback } from 'react';
import style from './index.module.scss';
interface Props {
  value: boolean;
  onChange: (value: this['value']) => void;
  label: React.ReactNode;
  disabled: boolean;
}

export const BaseChecker: React.FC<Props> = ({ value, onChange, label, disabled }) => {
  const icon = value ? (
    <DoneAllIcon fontSize={'small'} color={'success'} />
  ) : (
    <CheckIcon fontSize={'small'} color={'disabled'} />
  );
  const onToggle = useCallback(() => {
    return onChange(!value);
  }, [value, onChange]);

  return (
    <ButtonBase onClick={onToggle} className={style.root} disabled={disabled}>
      <Stack p={0.2} direction={'row'} spacing={0.2} alignItems={'center'}>
        {icon}
        <div>{label}</div>
      </Stack>
    </ButtonBase>
  );
};
