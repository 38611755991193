import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'lodash-es';
import { apiTicketStatuses } from './api';

const selectTicketStatusesResult = apiTicketStatuses.endpoints.getTicketStatuses.select();
export const selectSourceTicketStatusesAll = createSelector(selectTicketStatusesResult, (res) => {
  return res.data || [];
});
export const selectSourceTicketStatusesAllMap = createSelector(
  selectSourceTicketStatusesAll,
  (statuses) => {
    return keyBy(statuses, 'id');
  },
);
