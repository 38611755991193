import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/src/createAction';
import { endOfMonth, startOfMonth } from 'date-fns';
import { apiTicketActionItemComments } from 'services/ticket-action-item-comments';
import { apiTicketActionItems, ITicketActionItem } from 'services/ticket-action-items';
import { DateValue } from 'utils/dates';
import { actionCheckDoneAllFetchAll, actionCheckDoneAllFetchItem } from './actions';
import { ICheckDoneAllItem } from './helpers';

export interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;

  filters: Filters;
  data: ICheckDoneAllItem[];

  editor: null | Partial<ITicketActionItem>;
}
interface Filters {
  search: string;
  assigneeUserCrmProfileIDs: string[];
  reporterUserCrmProfileID: string;
  dateRange: DateValue[];
  ticketActionPriorityID: string;
  ticketActionStatusID: string;
}

const initState = (): State => {
  const currentDate = new Date();
  const startDate = startOfMonth(currentDate);
  const endDate = endOfMonth(currentDate);
  return {
    error: null,
    isLoading: false,
    isInit: false,

    filters: {
      search: '',
      assigneeUserCrmProfileIDs: [],
      reporterUserCrmProfileID: '',

      ticketActionPriorityID: '',
      ticketActionStatusID: '',
      dateRange: [startDate, endDate],
    },
    data: [],
    editor: null,
  };
};

const slice = createSlice({
  name: 'CHECK_DONE_ALL',
  initialState: initState(),
  reducers: {
    setFilters(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    clearFilters(state) {
      state.filters = initState().filters;
    },
    removeFilterAssigner(state, action: PayloadAction<string>) {
      state.filters.assigneeUserCrmProfileIDs = state.filters.assigneeUserCrmProfileIDs.filter(
        (id) => {
          return id !== action.payload;
        },
      );
    },
    setEditor(state, action: PayloadAction<Partial<ITicketActionItem> | null>) {
      state.editor = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionCheckDoneAllFetchAll.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(actionCheckDoneAllFetchAll.fulfilled, (state, action) => {
        const { payload: value } = action;

        state.data = value;
        state.isLoading = false;
        state.isInit = true;
      })
      .addCase(actionCheckDoneAllFetchAll.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });

    builder.addCase(actionCheckDoneAllFetchItem.fulfilled, (state, action) => {
      const ticketAction = state.data.find((ticketAction) => {
        return ticketAction.id === action.payload.id;
      });

      if (ticketAction) {
        Object.assign(ticketAction, action.payload);
      }
    });

    // watch update ticket action item
    builder.addMatcher(
      apiTicketActionItems.endpoints.patchTicketActionItem.matchPending,
      (state, action) => {
        const ticketAction = state.data.find((ticketAction) => {
          return ticketAction.id === action.meta.arg.originalArgs.id;
        });

        if (ticketAction) {
          Object.assign(ticketAction, action.meta.arg.originalArgs);
        }
      },
    );
    builder.addMatcher(
      isAnyOf(
        apiTicketActionItems.endpoints.deleteTicketActionItem.matchPending,
        apiTicketActionItems.endpoints.deactivateTicketActionItem.matchPending,
      ),
      (state, action) => {
        state.data = state.data.filter((ticketAction) => {
          return ticketAction.id !== action.meta.arg.originalArgs.id;
        });
      },
    );
    // watch create ticket action item
    builder.addMatcher(
      apiTicketActionItems.endpoints.postTicketActionItem.matchFulfilled,
      (state, action) => {
        state.data.push({
          ...action.payload,
          totalComments: 0,
          tracking: [],
        });
      },
    );
    // watch ticket action item comments
    builder.addMatcher(
      apiTicketActionItemComments.endpoints.postTicketActionItemComment.matchFulfilled,
      (state, action) => {
        const ticketAction = state.data.find((ticketAction) => {
          return ticketAction.id === action.meta.arg.originalArgs.ticketActionItemID;
        });

        if (ticketAction) {
          Object.assign(ticketAction, { totalComments: ticketAction.totalComments + 1 });
        }
      },
    );
  },
});

export const actionsCheckDoneAll = slice.actions;
export const reducerCheckDoneAll = slice.reducer;
