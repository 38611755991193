import CakeIcon from '@mui/icons-material/Cake';
import { Box } from '@mui/material';
import { useSourceCrmUsersSelect } from 'hooks';
import React, { memo, useMemo } from 'react';
import { getShortFullName } from 'utils/app-helpers';
import { convertToDate, DateValue, isBirthdayWithinOneWeek } from 'utils/dates';
import style from './index.module.scss';

const getDate = (date: Date) => {
  const isoDate = date.toISOString();

  return `${isoDate.substring(8, 10)}/${isoDate.substring(5, 7)}`;
};

interface UserProps {
  dateOfBirth: DateValue;
  fullName: string;
}
const User: React.FC<UserProps> = ({ dateOfBirth, fullName }) => {
  const date = useMemo(() => getDate(convertToDate(dateOfBirth)), [dateOfBirth]);
  const name = getShortFullName(fullName);

  return (
    <div className={style.birthdayAlertWrapper}>
      <div>{date}</div>
      <CakeIcon className={style.cakeIcon} color={'warning'} />
      <div title={fullName}>{name}</div>
    </div>
  );
};

export const UsersBirthdayList = memo(() => {
  const { data } = useSourceCrmUsersSelect();
  const usersWithinOneWeekBirthday = useMemo(
    () => data.filter((item) => item.isActive && isBirthdayWithinOneWeek(item.dateOfBirth)),
    [data],
  );

  return (
    <Box className={style.root}>
      {usersWithinOneWeekBirthday.map((item) => (
        <User key={item.id} dateOfBirth={item.dateOfBirth} fullName={item.fullName} />
      ))}
    </Box>
  );
});
