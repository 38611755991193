import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import React, { memo } from 'react';
import { AppThemeOptions } from 'styles/theme';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

const themeLtr = createTheme(AppThemeOptions);
const cacheLtr = createCache({
  key: 'muiltr',
  stylisPlugins: [prefixer],
  prepend: true,
});

const themeRtl = createTheme({ ...AppThemeOptions, direction: 'rtl' });
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
  prepend: true,
});

interface Props {
  children: React.ReactNode;
  direction?: 'ltr' | 'rtl';
}
export const AppThemeProvider = memo<Props>(({ children, direction = 'ltr' }) => {
  return (
    <CacheProvider value={direction === 'ltr' ? cacheLtr : cacheRtl}>
      <ThemeProvider theme={direction === 'ltr' ? themeLtr : themeRtl}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
});
