import { apiNotes } from 'services/notes';
import { debounce, put, select, takeLatest } from 'typed-redux-saga';
import { workerErrorNotifyThunk } from 'utils/sagas';
import { actionNotesFetchAll } from './actions';
import { selectNotesStatuses } from './selectors';

function* sagaRefresh() {
  const { isMounted } = yield* select(selectNotesStatuses);

  if (!isMounted) return;

  yield* put(actionNotesFetchAll());
}

export const sagasNotes = [
  debounce(500, [actionNotesFetchAll.rejected], workerErrorNotifyThunk),
  takeLatest(
    [
      apiNotes.endpoints.postNote.matchFulfilled,
      apiNotes.endpoints.patchNote.matchFulfilled,
      apiNotes.endpoints.patchNoteDates.matchFulfilled,
      apiNotes.endpoints.deleteNote.matchFulfilled,
      apiNotes.endpoints.doneNote.matchFulfilled,
      apiNotes.endpoints.toggleArchiveNote.matchFulfilled,
      apiNotes.endpoints.manageNotesDueDate.matchFulfilled,
    ],
    sagaRefresh,
  ),
];
