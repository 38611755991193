const hexToDec = (hex: string = 'FF') => {
  let result = 0;
  for (let i = 0; i < hex.length; i++) {
    result = result * 16 + '0123456789abcdefgh'.indexOf(hex[i].toLowerCase());
  }
  return result;
};

export const middleColor = (color1: string, color2: string, ratioValue: number) => {
  const ratio = isNaN(ratioValue) ? 1 : ratioValue;
  let c1 = color1.replace('#', '').match(/.{1,2}/g) as string[];
  let c2 = color2.replace('#', '').match(/.{1,2}/g) as string[];
  const factor1 = 1 - ratio;

  const [rr, gg, bb, aa] = new Array(4)
    .fill(null)
    .map((_, i) => parseInt(String(hexToDec(c1[i]) * factor1 + hexToDec(c2[i]) * ratio)));

  return '#' + [rr, gg, bb, aa].map((v) => `0${v.toString(16)}`.slice(-2)).join('');
};

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
    : [255, 255, 255];
};

export const colorLuminance = (hex: string, lum: number = 0) => {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  // convert to decimal and change luminosity
  let rgb = '#';
  for (let i = 0; i < 3; i++) {
    let c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255));
    rgb += ('00' + c.toString(16)).substr(c.toString(16).length);
  }

  return rgb;
};

export const getContrastColor = (hex: string): string => {
  hex = hex.replace('#', '');

  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('');
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  return brightness > 128 ? '#000000' : '#FFFFFF';
};


export function applyOpacityEffect(color: string, opacity: number): string {
  const num = parseInt(color.replace('#', ''), 16);
  const amt = Math.round(255 * opacity);
  const R = (num >> 16) + amt;
  const G = ((num >> 8) & 0x00ff) + amt;
  const B = (num & 0x0000ff) + amt;

  return '#' + (
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
    (B < 255 ? (B < 1 ? 0 : B) : 255)
  ).toString(16).slice(1).toUpperCase();
}

export function applyOpacityEffectHex(color: string, opacity: number): string {
  // Convert hex to RGB
  const num = parseInt(color.replace('#', ''), 16);
  const R = (num >> 16) & 0xff;
  const G = (num >> 8) & 0xff;
  const B = num & 0xff;

  // Blend with white background
  const newR = Math.round(R * opacity + 255 * (1 - opacity));
  const newG = Math.round(G * opacity + 255 * (1 - opacity));
  const newB = Math.round(B * opacity + 255 * (1 - opacity));

  // Convert back to hex
  const toHex = (value: number) => value.toString(16).padStart(2, '0');
  return `#${toHex(newR)}${toHex(newG)}${toHex(newB)}`;
}
