import { Box, Typography } from '@mui/material';
import { useTranslate } from 'hooks';
import React from 'react';
import VARS from 'styles/config.scss';
import style from './index.module.scss';

interface Props {
  className?: string;
}

export const Footer: React.FC<Props> = ({ className }) => {
  const { tp } = useTranslate();

  return (
    <footer className={style.root}>
      <Typography
        color={VARS.colorGrey}
        variant={'caption'}
        component={'div'}
        textAlign={{ xs: 'center', sm: 'right' }}
      >
        {tp('copyright')}
      </Typography>
      <Box className={style.image} />
    </footer>
  );
};
