import { FixServerObject } from 'utils/types';

const API = 'ProjectStatuses';

export const API_PROJECT_STATUSES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ProjectStatus, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ProjectStatus, 'id'>) => `${API}/Delete/${data.id}`,
};
export class ProjectStatus {
  id!: string;
  title = '';
  isActive = true;
  rank = 0;
}

export interface IProjectStatus
  extends FixServerObject<Components.Schemas.ProjectStatus, 'id' | 'title' | 'isActive' | 'rank'> {}

export const PROJECT_STATUS_ACTIVE = 'active';
export const PROJECT_STATUS_ARCHIVED = 'archived';
