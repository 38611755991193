import { Box, BoxProps } from '@mui/material';
import { TIME_FORMAT_DAY } from 'configs/const';
import { format } from 'date-fns';
import React from 'react';

interface Props extends BoxProps {
  value: string;
  format?: string;
}

export const TemplateDay: React.FC<Props> = ({ value, className, ...rest }) => {
  return (
    <Box className={className} {...rest}>
      {format(new Date(value), TIME_FORMAT_DAY)}
    </Box>
  );
};
