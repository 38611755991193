import { Modal, ModalProps, PaperProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { DraggableProps } from 'react-draggable';
import { DraggablePaper } from './comments/draggable-paper';
import { useDraggablePosition } from './hooks';
import style from './index.module.scss';
import { DraggableCalcInitPosition } from './models';

interface Props extends Omit<ModalProps, 'children'> {
  DraggableProps?: Partial<DraggableProps>;
  PaperProps?: Partial<Omit<PaperProps, 'ref'>>;
  paperRef?: PaperProps['ref'];
  invisibleBackdrop?: boolean;
  children: React.ReactNode;
}

export const DraggableModal: React.FC<Props> = ({
  children,
  paperRef,
  DraggableProps,
  PaperProps,
  invisibleBackdrop = true,
  slotProps,
  ...rest
}) => {
  return (
    <Modal
      {...rest}
      slotProps={{
        ...slotProps,
        backdrop: {
          className: clsx(invisibleBackdrop && style.backdropInvisible),
          ...slotProps?.backdrop,
        },
      }}
    >
      <DraggablePaper
        {...PaperProps}
        DraggableProps={DraggableProps}
        ref={paperRef}
        variant={'elevation'}
        elevation={0}
        sx={{
          overflow: 'hidden',
          ...PaperProps?.sx,
        }}
      >
        {children}
      </DraggablePaper>
    </Modal>
  );
};

export { useDraggablePosition };
export type { DraggableCalcInitPosition };
