import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { Button, ButtonProps, IconButtonProps, Typography } from '@mui/material';
import { IconButtonGrey } from 'components/buttons';
import React, { useMemo } from 'react';
import { minutesToDuration } from 'utils/app-helpers';

interface BaseDurationNewProps extends IconButtonProps {}
export const BaseDurationNew: React.FC<BaseDurationNewProps> = (props) => {
  return (
    <IconButtonGrey size={'small'} {...props}>
      <TimerOutlinedIcon sx={{ fontSize: 20 }} />
    </IconButtonGrey>
  );
};
interface BaseDurationListProps extends ButtonProps {
  minutes: number;
}
export const BaseDurationList: React.FC<BaseDurationListProps> = ({ minutes, ...rest }) => {
  const duration = useMemo(() => {
    return minutesToDuration(minutes);
  }, [minutes]);

  const isEmpty = minutes === 0;

  return (
    <Button color={'primary'} size={'small'} {...rest}>
      <Typography
        component={'span'}
        fontWeight={500}
        color={isEmpty ? 'textSecondary' : 'textPrimary'}
      >
        {duration}
      </Typography>
    </Button>
  );
};
