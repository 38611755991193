import SearchOffIcon from '@mui/icons-material/SearchOff';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { useTranslate } from 'hooks';
import React from 'react';
import style from './index.module.scss';

interface Props {
  className?: string;
  title?: React.ReactNode | string;
}

export const DataGridEmpty: React.FC<Props> = ({ className, title }) => {
  const { t } = useTranslate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const TitleComponent = title || t('no-data');
  return (
    <div className={clsx(style.wrap, className)}>
      <div className={style.data}>
        <SearchOffIcon
          sx={{
            fontSize: '7rem',
          }}
          className={style.icon}
        />
        <Typography
          variant={isMobile ? 'h4' : 'h3'}
          component={'div'}
          sx={{
            textTransform: 'uppercase',
          }}
        >
          {TitleComponent}
        </Typography>
      </div>
    </div>
  );
};
