import { IDataGridColumnProps } from 'components/data-grid';
import merge from 'deepmerge';
import { ExcelColumn } from 'modules/export-excel';

interface BaseConfigColumnProps<T extends Record<string, any>> {
  field: keyof T;
  title?: string;
  gridProps: Partial<IDataGridColumnProps<T>>;
  excelProps?: Partial<ExcelColumn<T>>;
  includeInExcel?: boolean;
}
export type ConfigColumnProps<
  T extends Record<string, any>,
  ExtraProps extends any = void,
> = ExtraProps extends void
  ? BaseConfigColumnProps<T> & { extraProps?: void }
  : BaseConfigColumnProps<T> & { extraProps: ExtraProps };

export const createConfigColumn = <T extends Record<string, any>, ExtraProps = void>(
  props: ConfigColumnProps<T, ExtraProps>,
) => ({
  includeInExcel: props.includeInExcel ?? true,
  ...props,
  gridProps: { field: props.field, title: props.title, ...props?.gridProps },
  excelProps: { field: props.field, title: props.title, ...props?.excelProps },
  extraProps: { ...props.extraProps },
});

export const factoryCreateConfigColumn =
  <Shared extends Record<string, any>, ExtraProps = void>(
    sharedProps?: Partial<ConfigColumnProps<Shared, ExtraProps>>,
  ) =>
  (props: ConfigColumnProps<Shared, ExtraProps>) =>
    createConfigColumn<Shared, ExtraProps>(merge({ ...sharedProps }, { ...props }) as any);
