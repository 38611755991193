import ArticleIcon from '@mui/icons-material/Article';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import LayersIcon from '@mui/icons-material/Layers';
import QueryBuilderTwoToneIcon from '@mui/icons-material/QueryBuilderTwoTone';
import { Box, Button, Stack } from '@mui/material';
import clsx from 'clsx';
import { UsersBirthdayList } from 'components/users-birthday-list';
import { APP_ROUTES } from 'configs';
import { usePermissions, useTranslate } from 'hooks';
import React, { useMemo } from 'react';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import style from './index.module.scss';

interface Props {
  className?: string;
  isVertical?: boolean;
  onChanged?: () => void;
}

export const HeaderNavigation: React.FC<Props> = ({ isVertical, onChanged }) => {
  const { t } = useTranslate();
  const permissions = usePermissions();
  const { pathname: locationPathname } = useLocation();
  const menuItems = useMemo(() => {
    return [
      {
        id: 1,
        pathname: [APP_ROUTES.DASHBOARD.path],
        labelKey: 'dashboard',
        icon: <DashboardTwoToneIcon />,
      },
      {
        id: 2,
        pathname: [APP_ROUTES.NOTES.path, APP_ROUTES.NOTES_ARCHIVE.path],
        labelKey: 'notes',
        icon: <LayersIcon />,
      },
      {
        id: 3,
        pathname: [APP_ROUTES.TIME_TRACING.path],
        labelKey: 'time-tracking',
        icon: <QueryBuilderTwoToneIcon />,
      },
      {
        id: 4,
        pathname: [
          APP_ROUTES.CHECK_DONE.path,
          APP_ROUTES.CHECK_DONE_COMPLETED.path,
          APP_ROUTES.CHECK_DONE_ALL.path,
          APP_ROUTES.CHECK_DONE_UN_ASSIGN.path,
          APP_ROUTES.CHECK_DONE_TRASH.path,
        ],
        labelKey: 'tickets',
        icon: <DashboardCustomizeOutlinedIcon />,
      },
      ...(permissions.__isAllowMakeReport
        ? [
            {
              id: 5,
              pathname: [APP_ROUTES.REPORTS.path, APP_ROUTES.REPORT_ITEM.path],
              labelKey: 'reports',
              icon: <ArticleIcon />,
            },
          ]
        : []),
    ];
  }, [permissions]);
  const value = useMemo(() => {
    const item = menuItems.find(({ pathname }) =>
      pathname.some((path) => Boolean(matchPath(path, locationPathname))),
    );
    return item ? item.id : false;
  }, [locationPathname, menuItems]);
  return (
    <Stack
      direction={isVertical ? 'column' : 'row'}
      alignItems={'center'}
      spacing={{ xs: 1.7, xl: 1, xxl: 1.7 }}
    >
      {menuItems.map(({ pathname, labelKey, id, icon }) => {
        return (
          <Button
            color={'inherit'}
            size={'medium'}
            component={RouterLink}
            onClick={onChanged}
            to={pathname[0]}
            key={id}
            className={clsx(
              style.item,
              id === value && style.itemActive,
              isVertical && style.itemVertical,
            )}
            fullWidth={isVertical}
          >
            <Box component={'span'} mr={0.8} lineHeight={0}>
              {icon}
            </Box>
            {t(labelKey)}
          </Button>
        );
      })}
      <UsersBirthdayList />
    </Stack>
  );
};
