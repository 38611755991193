import { endOfDay, endOfWeek, startOfDay, startOfWeek } from 'date-fns';

export const PREFIX = 'NOTES';

export enum NOTES_DUE_DATE {
  ALL = 'all-notes',
  TODAY = 'today',
  WORKING_WEEK = 'working-week',
  PAST_DUE_DATE = 'past-due-date',
}

export const notesDueDateToRange = (notesDueDate: NOTES_DUE_DATE): Date[] => {
  switch (notesDueDate) {
    case NOTES_DUE_DATE.ALL: {
      return [];
    }
    case NOTES_DUE_DATE.TODAY: {
      return [startOfDay(new Date()), endOfDay(new Date())];
    }
    case NOTES_DUE_DATE.WORKING_WEEK: {
      return [startOfWeek(new Date()), endOfWeek(new Date())];
    }
    case NOTES_DUE_DATE.PAST_DUE_DATE: {
      return [new Date(0), new Date()];
    }
  }
};

export enum NOTES_VIEW {
  GRID = 'grid',
  CALENDAR = 'calendar',
  TIMELINE = 'timeline',
}

export enum DATE_RANGE_TYPE {
  WEEK = 'week',
  MONTH = 'month',
}
