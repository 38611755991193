import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiMonthlyActivityReports } from 'services/monthly-activity-reports';
import {
  IGridTimeTracking,
  ITimeTracking,
  schemaTimeTracking,
  ServiceTimeTracking,
} from 'services/time-trackings';
import { TIME_TRACKING_VIEW } from 'store/time-tracking/slice';
import * as dynamic from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';
import {
  selectTimeTrackingData,
  selectTimeTrackingDataToMove,
  selectTimeTrackingDataToMoveWithError,
  selectTimeTrackingFilters,
  selectTimeTrackingOrderBy,
  selectTimeTrackingPagination,
  selectTimeTrackingView,
} from './selectors';

export const actionTimeTrackingGetDynamic = createAsyncThunk<
  { value: IGridTimeTracking[]; count: number },
  { page: number; take?: number } | void,
  AppAsyncThunkConfig
>(`TIME_TRACKING/getDynamic`, async (_, { getState }) => {
  const { userCrmProfileID, projectID, customerID, timeTrackingActivityID, period } =
    selectTimeTrackingFilters(getState());
  const view = selectTimeTrackingView(getState());
  const { take, skip } = selectTimeTrackingPagination(getState());
  const orderBy = selectTimeTrackingOrderBy(getState());
  try {
    const params = {
      filter: dynamic
        .mergeFilters(
          dynamic.makeFilter(
            'userCrmProfileID',
            userCrmProfileID,
            dynamic.decoratorIsNotNullable(dynamic.equals),
          ),
          dynamic.makeFilter(
            'timeTrackingActivityID',
            timeTrackingActivityID,
            dynamic.decoratorIsNotNullable(dynamic.equals),
          ),
          projectID
            ? dynamic.makeFilter(
                'projectID',
                projectID,
                dynamic.decoratorIsNotNullable(dynamic.equals),
              )
            : dynamic.makeFilter(
                'project.customerID',
                customerID,
                dynamic.decoratorIsNotNullable(dynamic.equals),
              ),
          dynamic.makeFilter('date', period, dynamic.dateRange),
        )
        .join('&&'),
      select: dynamic.select(
        'id',
        'projectID',
        'date',
        'duration',
        'description',
        'baseRate',
        'billableRate',
        'billableDuration',
        'internal',
        'timeTrackingActivityID',
        'userCrmProfileID',
        'userCrmProfile.firstName as employeeFirstName',
        'userCrmProfile.lastName as employeeLastName',
        'userCrmProfile.userPhoto as employeePhoto',
        'timeTrackingActivity.title as timeTrackingActivityTitle',
        'project.customer.companyName as customerName',
        'project.customerID as customerID',
        'project.projectName as projectName',
        'project.projectNameHeb as projectNameHeb',
        'timeTrackingActivity.titleHeb as titleHeb',
        'ticketActionItemID',
      ),
      orderBy: dynamic.orderBy(orderBy.field, orderBy.order),
      count: true,
      take: view === TIME_TRACKING_VIEW.WEEK ? undefined : take,
      skip: view === TIME_TRACKING_VIEW.WEEK ? undefined : skip,
    };
    const { data: result } = await ServiceTimeTracking.getAllDynamic<
      IGridTimeTracking,
      typeof params
    >(params);
    return result;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionTimeTrackingDurationGetDynamic = createAsyncThunk<
  { value: IGridTimeTracking[] },
  { page: number; take?: number } | void,
  AppAsyncThunkConfig
>(`TIME_TRACKING/getDynamicDuration`, async (_, { getState }) => {
  const { userCrmProfileID, projectID, customerID, timeTrackingActivityID, period } =
    selectTimeTrackingFilters(getState());
  const orderBy = selectTimeTrackingOrderBy(getState());
  try {
    const params = {
      filter: dynamic
        .mergeFilters(
          dynamic.makeFilter(
            'userCrmProfileID',
            userCrmProfileID,
            dynamic.decoratorIsNotNullable(dynamic.equals),
          ),
          dynamic.makeFilter(
            'timeTrackingActivityID',
            timeTrackingActivityID,
            dynamic.decoratorIsNotNullable(dynamic.equals),
          ),
          projectID
            ? dynamic.makeFilter(
                'projectID',
                projectID,
                dynamic.decoratorIsNotNullable(dynamic.equals),
              )
            : dynamic.makeFilter(
                'project.customerID',
                customerID,
                dynamic.decoratorIsNotNullable(dynamic.equals),
              ),
          dynamic.makeFilter('date', period, dynamic.dateRange),
        )
        .join('&&'),
      select: dynamic.select('duration', 'billableDuration'),
      orderBy: dynamic.orderBy(orderBy.field, orderBy.order),
    };
    const { data: result } = await ServiceTimeTracking.getAllDynamic<
      IGridTimeTracking,
      typeof params
    >(params);
    return result;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionTimeTrackingMove = createAsyncThunk<
  void,
  {
    itemID: string;
    data: Pick<
      Components.Schemas.TimeTracking,
      'projectID' | 'timeTrackingActivityID' | 'date' | 'ticketActionItemID'
    >;
  },
  AppAsyncThunkConfig
>(`TIME_TRACKING/move`, async ({ itemID, data }, { dispatch }) => {
  try {
    const { projectID, timeTrackingActivityID, date, ticketActionItemID } = data;

    const preparedData = ServiceTimeTracking.prepareData({
      id: itemID,
      projectID: projectID ?? undefined,
      timeTrackingActivityID: timeTrackingActivityID ?? undefined,
      date: date ?? undefined,
      ticketActionItemID: ticketActionItemID ?? undefined,
    });

    await ServiceTimeTracking.patch(preparedData);
    await dispatch(actionTimeTrackingGetDynamic()).unwrap();
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionTimeTrackingMoveToActivityItems = createAsyncThunk<
  { itemID: string; error: null | Error }[],
  void,
  AppAsyncThunkConfig
>(`TIME_TRACKING/moveToActivityItems`, async (_, { getState, dispatch }) => {
  try {
    const itemsToMove = selectTimeTrackingDataToMove(getState());

    return Promise.all(
      itemsToMove.map(async (item) => {
        try {
          await ServiceTimeTracking.moveItemToActivity({
            customerID: item.customerID,
            userCrmProfileID: item.userCrmProfileID,
            date: item.date,
            duration: item.duration,
            description: item.description,
            projectNameHeb: item.projectNameHeb,
            titleHeb: item.titleHeb,
          });
          return { itemID: item.id, error: null };
        } catch (e: any) {
          return { itemID: item.id, error: parseErrorData(e) as Error };
        }
      }),
    );
  } catch (e: any) {
    throw parseErrorData(e);
  } finally {
    dispatch(
      apiMonthlyActivityReports.util.invalidateTags([
        { type: 'MonthlyActivityReports', id: 'grid' },
      ]),
    );
  }
});

export const actionTimeTrackingMoveToActivityItemsRerun = createAsyncThunk<
  { itemID: string; error: null | Error }[],
  void,
  AppAsyncThunkConfig
>(`TIME_TRACKING/moveToActivityItemsRerun`, async (_, { getState, dispatch }) => {
  try {
    const itemsToMove = selectTimeTrackingDataToMoveWithError(getState());

    return Promise.all(
      itemsToMove.map(async (item) => {
        try {
          await ServiceTimeTracking.moveItemToActivity({
            customerID: item.customerID,
            userCrmProfileID: item.userCrmProfileID,
            date: item.date,
            duration: item.duration,
            description: item.description,
            projectNameHeb: item.projectNameHeb,
            titleHeb: item.titleHeb,
          });
          return { itemID: item.id, error: null };
        } catch (e: any) {
          return { itemID: item.id, error: parseErrorData(e) as Error };
        }
      }),
    );
  } catch (e: any) {
    throw parseErrorData(e);
  } finally {
    dispatch(
      apiMonthlyActivityReports.util.invalidateTags([
        { type: 'MonthlyActivityReports', id: 'grid' },
      ]),
    );
  }
});

export const actionTimeTrackingCopy = createAsyncThunk<
  void,
  {
    itemID: string;
    data: Pick<
      ITimeTracking,
      'projectID' | 'timeTrackingActivityID' | 'date' | 'ticketActionItemID'
    >;
  },
  AppAsyncThunkConfig
>(`TIME_TRACKING/copy`, async ({ itemID, data }, { getState, dispatch }) => {
  try {
    const rows = selectTimeTrackingData(getState());

    const targetItem = rows.find(({ id }) => id === itemID);

    if (!targetItem) {
      throw new Error('unexpected-behaviour');
    }

    const payload = schemaTimeTracking.cast(
      {
        ...targetItem,
        ...data,
      },
      { stripUnknown: true },
    );
    let { customerID, ...preparedData } = ServiceTimeTracking.prepareData(payload);

    await ServiceTimeTracking.post({
      ...preparedData,
      baseRate: preparedData.baseRate ?? undefined,
      billableRate: preparedData.billableRate ?? undefined,
      internal: preparedData.internal ?? undefined,
      isActive: true,
      id: undefined as any,
    });

    await dispatch(actionTimeTrackingGetDynamic()).unwrap();
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
