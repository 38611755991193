import { UIEventHandler, useCallback, useEffect, useRef } from 'react';

export interface Position {
  scrollLeft: number;
  scrollTop: number;
  scrollHeight: number;
  scrollWidth: number;

  clientHeight: number;
  clientWidth: number;
}

export const useTrackScrollPosition = <T extends (pos: Position, el: HTMLElement) => void>(
  cb: T,
) => {
  const refCb = useRef(cb);
  useEffect(() => {
    refCb.current = cb;
  }, [cb]);

  const ref = useRef<HTMLElement>(null);
  useEffect(() => {
    const el = ref.current;

    if (!el) return;
    const observer = new ResizeObserver((entries) => {
      entries.forEach(({ target }) => {
        const position = {
          scrollLeft: target.scrollLeft,
          scrollTop: target.scrollTop,
          clientHeight: target.clientHeight,
          clientWidth: target.clientWidth,
          scrollHeight: target.scrollHeight,
          scrollWidth: target.scrollWidth,
        };
        refCb.current(position, el);
      });
    });

    observer.observe(el);

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  const onScroll = useCallback<UIEventHandler<HTMLElement>>((e) => {
    e.persist();
    const target = e.target as HTMLElement;
    if (!target) {
      return;
    }

    const { scrollLeft, scrollTop, clientHeight, clientWidth, scrollHeight, scrollWidth } = target;

    const position = {
      scrollLeft,
      scrollTop,
      clientHeight,
      clientWidth,
      scrollHeight,
      scrollWidth,
    };

    refCb.current(position, target);
  }, []);

  return { ref, onScroll };
};
