import { useAppSelector } from 'hooks';
import { apiTicketActionItems } from 'services/ticket-action-items';
import { apiTickets } from 'services/tickets';
import { selectSourceProjectsWithCustomerMap } from 'store/sources';
import { apiCustomers } from '../../../services/customers';
import { apiProjects } from '../../../services/projects';

const useFetchTicketItemActionQuery = apiTicketActionItems.useGetTicketActionItemQuery;
const useFetchTicketQuery = apiTickets.useGetTicketQuery;

export const useTicketInformation = (ticketID: string | undefined) => {
  const resultTicket = useFetchTicketQuery(ticketID || '', {
    skip: !ticketID,
  });
  const ticket = resultTicket.data;

  const projectID = ticket?.projectID;

  apiProjects.useGetProjectSourcesQuery();
  apiCustomers.useGetCustomersQuery();
  const projectsMap = useAppSelector(selectSourceProjectsWithCustomerMap);

  const project = projectID ? projectsMap[projectID] : null;
  const customer = project?._customer;

  const error = resultTicket.error;
  const isFetching = resultTicket.isFetching;
  const isSuccess = resultTicket.isSuccess;

  return { error, isFetching, isSuccess, ticket, project, customer };
};

export const useTicketActionItemInformation = (ticketActionItemID: string | undefined) => {
  const resultTicketActionItem = useFetchTicketItemActionQuery(ticketActionItemID || '', {
    skip: !ticketActionItemID,
  });
  const ticketActionItem = resultTicketActionItem.data;

  const ticketID = ticketActionItem?.ticketID;
  const res = useTicketInformation(ticketID);

  const error = resultTicketActionItem.error || res.error;
  const isFetching = resultTicketActionItem.isFetching || res.isFetching;
  const isSuccess = resultTicketActionItem.isSuccess || res.isSuccess;

  return { ...res, ticketActionItem, error, isFetching, isSuccess };
};
