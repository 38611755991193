import { LABEL_SOURCE_KEYS } from 'services/label-sources';
import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_LABELS, IGridLabel, IGridLabelsArgs, ILabel } from './models';

const REVALIDATE_KEY = 'Labels' as const;

export * from './models';

class Service extends DynamicService<ILabel> {
  async post(data: Omit<ILabel, 'id'>) {
    return super.post({ ...data, labelKey: data.labelKey.trim() });
  }
  async patch(data: Partial<ILabel>) {
    return super.patch({
      ...data,
      ...(data.labelKey
        ? {
            labelKey: data.labelKey.trim(),
          }
        : {}),
    });
  }
}

type ApiModel = ILabel;

export const ServiceLabels = new Service({
  getAll: API_LABELS.GET_ALL_DYNAMIC,
  post: API_LABELS.POST,
  patch: API_LABELS.PATCH,
  delete: API_LABELS.DELETE,
});

export const apiLabels = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getAppLabels: build.query<ApiModel[], string>({
      queryFn: async (languageID) => {
        try {
          const {
            data: { value },
          } = await ServiceLabels.getAllDynamic({
            select: dynamic.select('id', 'labelKey', 'title', 'labelSourceID'),
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('languageID', languageID, dynamic.equals),
                dynamic.makeFilter('labelSource.key', LABEL_SOURCE_KEYS.CRM, dynamic.equals),
                dynamic.makeFilter('isActive', true, dynamic.equals),
              )
              .join('&&'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    getLabel: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServiceLabels.getDynamic(id);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    getGridLabels: build.query<DynamicResult<IGridLabel, { count: true }>, IGridLabelsArgs>({
      query: ({ search, labelSourceID, languageID, take, skip, orderBy }) => ({
        url: API_LABELS.GET_ALL_DYNAMIC,
        params: {
          filter: dynamic
            .mergeFilters(
              dynamic.makeFilter(['title', 'labelKey'], search, dynamic.contains),
              dynamic.makeFilter(
                ['languageID'],
                languageID,
                dynamic.decoratorIsNotNullable(dynamic.equals),
              ),
              dynamic.makeFilter(
                ['labelSourceID'],
                labelSourceID,
                dynamic.decoratorIsNotNullable(dynamic.decoratorExclude(dynamic.equals, 'all')),
              ),
            )
            .join('&&'),
          select: dynamic.select('id', 'title', 'labelKey', 'isActive'),
          orderBy: dynamic.orderBy(orderBy.field as any, orderBy.order),
          count: true,
          take,
          skip,
        },
      }),
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    postLabel: build.mutation<void, Omit<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceLabels.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }, { type: REVALIDATE_KEY }],
    }),
    patchLabel: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceLabels.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deleteLabel: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceLabels.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
  }),
});
