import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { endOfWeek, startOfWeek } from 'date-fns';
import { IGridTimeTracking } from 'services/time-trackings';
import { maxDate } from 'utils/dates';
import {
  actionTimeTrackingDurationGetDynamic,
  actionTimeTrackingGetDynamic,
  actionTimeTrackingMoveToActivityItems,
  actionTimeTrackingMoveToActivityItemsRerun,
} from './actions';

interface MoveItemState {
  isSelected: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  error: null | Error;
}

export interface IGridTimeTrackingItem extends IGridTimeTracking {
  _move: MoveItemState;
  _totalHours?: string;
}

export enum TIME_TRACKING_VIEW {
  LIST = 'LIST',
  WEEK = 'WEEK',
}

export enum TIME_TRACKING_LIST_ORDER {
  GROUP = 'by-date',
}

interface Filters {
  userCrmProfileID: string;
  customerID: string;
  projectID: string;
  timeTrackingActivityID: string;
  period: string[];
}
interface Pagination {
  take: number;
  count: number;
  page: number;
}

interface State {
  error: null | Error;
  pagination: Pagination;
  isLoading: boolean;
  isInit: boolean;
  data: IGridTimeTrackingItem[];
  dataDuration: Pick<IGridTimeTracking, 'billableDuration' | 'duration'>[];
  filters: Filters;
  view: TIME_TRACKING_VIEW;
  listOrder: TIME_TRACKING_LIST_ORDER | null;
  orderBy: {
    field: string;
    order: 'asc' | 'desc' | null;
  };
  addNewPayload: Partial<Components.Schemas.TimeTracking>;
  addExistingPayload: Partial<Components.Schemas.TimeTracking>;
}

const initState = (): State => {
  return {
    error: null,
    isLoading: false,
    isInit: false,
    data: [],
    dataDuration: [],
    filters: {
      userCrmProfileID: '',
      customerID: '',
      projectID: '',
      timeTrackingActivityID: '',
      period: [startOfWeek(new Date()).toISOString(), endOfWeek(new Date()).toISOString()],
    },
    pagination: {
      take: 100,
      count: 0,
      page: 1,
    },
    view: TIME_TRACKING_VIEW.LIST,
    listOrder: TIME_TRACKING_LIST_ORDER.GROUP,
    orderBy: {
      field: 'date',
      order: 'desc',
    },
    addNewPayload: {},
    addExistingPayload: {},
  };
};

const slice = createSlice({
  name: 'TIME_TRACKING',
  initialState: initState(),
  reducers: {
    actionTimeTrackingFiltersSet(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    actionTimeTrackingFiltersReset(state) {
      state.filters = initState().filters;
    },
    actionTimeTrackingSetView(state, action: PayloadAction<TIME_TRACKING_VIEW>) {
      state.view = action.payload;
      if (action.payload === TIME_TRACKING_VIEW.WEEK) {
        const max = maxDate(...state.filters.period) || new Date();

        state.filters.period = [startOfWeek(max).toISOString(), endOfWeek(max).toISOString()];
      }
    },
    actionTimeTrackingSetListOrder(state, action: PayloadAction<TIME_TRACKING_LIST_ORDER | null>) {
      state.listOrder = action.payload;
    },
    actionTimeTrackingRevalidate(state) {
      state.isInit = false;
    },
    actionTimeTrackingSetOrderBy(state, action: PayloadAction<Partial<State['orderBy']>>) {
      state.orderBy = { ...state.orderBy, ...action.payload };
    },
    actionTimeTrackingSetNewPayload(
      state,
      action: PayloadAction<Partial<Components.Schemas.TimeTracking>>,
    ) {
      state.addNewPayload = action.payload;
    },
    actionTimeTrackingSetExistingPayload(
      state,
      action: PayloadAction<Partial<Components.Schemas.TimeTracking>>,
    ) {
      state.addExistingPayload = action.payload;
    },
    actionTimeTrackingCheckMoveAll(state, action: PayloadAction<boolean>) {
      state.data = state.data.map((item) => {
        return { ...item, _move: { ...item._move, isSelected: action.payload } };
      });
    },
    actionTimeTrackingChangeMoveItem(
      state,
      action: PayloadAction<{ itemID: string } & Partial<MoveItemState>>,
    ) {
      const { itemID, ...rest } = action.payload;
      state.data = state.data.map((item) => {
        if (item.id !== itemID) return { ...item };

        return { ...item, _move: { ...item._move, ...rest } };
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionTimeTrackingGetDynamic.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
        state.pagination.page = action.meta.arg ? action.meta.arg.page : state.pagination.page;
        state.pagination.take = action.meta.arg?.take || state.pagination.take;
      })
      .addCase(actionTimeTrackingGetDynamic.fulfilled, (state, action) => {
        const {
          payload: { value, count },
        } = action;
        state.isLoading = false;
        state.isInit = true;
        state.data = value.map((item) => {
          return {
            ...item,
            _move: { isSelected: false, isLoading: false, isSuccess: false, error: null },
          };
        });
        state.pagination.count = count;
      })
      .addCase(actionTimeTrackingGetDynamic.rejected, (state, action) => {
        const { error } = action;
        state.isLoading = false;
        state.error = error;
        state.isInit = true;
      });

    builder
      .addCase(actionTimeTrackingDurationGetDynamic.fulfilled, (state, action) => {
        const {
          payload: { value },
        } = action;
        state.dataDuration = value;
      })
      .addCase(actionTimeTrackingDurationGetDynamic.rejected, (state, action) => {
        const { error } = action;
        state.error = error;
      });

    builder.addCase(actionTimeTrackingMoveToActivityItems.pending, (state) => {
      state.data = state.data.map((item) => {
        if (!item._move.isSelected) return item;
        return { ...item, _move: { ...item._move, isLoading: true } };
      });
    });
    builder.addCase(actionTimeTrackingMoveToActivityItemsRerun.pending, (state) => {
      state.data = state.data.map((item) => {
        if (!item._move.isSelected) return item;
        if (!item._move.error) return item;

        return { ...item, _move: { ...item._move, isLoading: true } };
      });
    });

    builder.addMatcher(
      isAnyOf(
        actionTimeTrackingMoveToActivityItems.fulfilled,
        actionTimeTrackingMoveToActivityItemsRerun.fulfilled,
      ),
      (state, action) => {
        const mapResult = Object.fromEntries(
          action.payload.map((item) => {
            return [item.itemID, item];
          }),
        );
        state.data = state.data.map((item) => {
          const resItem = mapResult[item.id];
          if (!resItem) return item;
          return {
            ...item,
            _move: {
              ...item._move,
              isLoading: false,
              error: resItem.error,
              isSuccess: !resItem.error,
            },
          };
        });
      },
    );
  },
});

export const {
  actionTimeTrackingFiltersSet,
  actionTimeTrackingSetView,
  actionTimeTrackingRevalidate,
  actionTimeTrackingSetOrderBy,
  actionTimeTrackingSetNewPayload,
  actionTimeTrackingSetExistingPayload,
  actionTimeTrackingChangeMoveItem,
  actionTimeTrackingCheckMoveAll,
  actionTimeTrackingSetListOrder,
} = slice.actions;
export const reducerTimeTracking = slice.reducer;
