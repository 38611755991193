import { DynamicOrder } from 'utils/dynamic';
import { replaceSpace } from 'utils/other';
import { FixServerObject } from 'utils/types';
import * as yup from 'yup';
const API = 'TicketActionItemStatuses';

export const API_TICKET_ACTION_ITEM_STATUS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ITicketActionItemStatus, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ITicketActionItemStatus, 'id'>) => `${API}/Delete/${data.id}`,
};
export enum TICKET_ACTION_ITEM_STATUS_IDS {
  IN_PROGRESS = '93a860fa-65c3-40e3-8b78-7b8d6f86227f',
  ON_HOLD = '2af28238-867b-4683-aa3f-eec7dfe6f8b8',
}
export interface ITicketActionItemStatus
  extends FixServerObject<
    Components.Schemas.TicketActionItemStatus,
    'id' | 'isActive' | 'isDefault' | 'labelKey' | 'icon' | 'color'
  > {}
export const schemaTicketActionItemStatus = yup.object({
  labelKey: yup.string().required('rule-required').transform(replaceSpace).default(''),
  color: yup.string().required('rule-required').default(''),
  icon: yup.mixed<string>().default(''),
  isActive: yup.boolean().default(false),
  isDefault: yup.boolean().default(false),
  rank: yup.number().notRequired().default(1),
});

export interface ITicketActionItemStatusArgs {
  search: string;

  take: number;
  skip: number;
  orderBy: DynamicOrder;
}

export const isOnHold = (ticketActionItemStatusID: string) => {
  return ticketActionItemStatusID === TICKET_ACTION_ITEM_STATUS_IDS.ON_HOLD;
};
export const isInProgress = (ticketActionItemStatusID: string) => {
  return ticketActionItemStatusID === TICKET_ACTION_ITEM_STATUS_IDS.IN_PROGRESS;
};
