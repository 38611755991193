import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Box, ButtonBase, Checkbox as MaterialCheckbox } from '@mui/material';
import clsx from 'clsx';
import { TIME_FORMAT_DATE } from 'configs/const';
import { useTranslate } from 'hooks';
import React, { useMemo } from 'react';
import { DateValue, formatDate, isDateLike } from 'utils/dates';
import style from './index.module.scss';

interface Props {
  fromDate: DateValue;
  toDate: DateValue;
  checked: boolean;
  onCheck: (value: boolean) => void;
  lock: boolean;
  bill: boolean;
  reportName: string;
  className?: string;
}

export const ReportItem: React.FC<Props> = ({
  checked,
  lock,
  bill,
  fromDate,
  toDate,
  reportName,
  onCheck,
  className,
}) => {
  const { tp } = useTranslate();
  const date = useMemo(
    () =>
      [fromDate, toDate]
        .filter(isDateLike)
        .map((v) => formatDate(v, TIME_FORMAT_DATE))
        .join(' - '),
    [fromDate, toDate],
  );
  return (
    <div className={clsx(style.report, className)}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'} alignItems={'center'} height={'3.5rem'}>
          <MaterialCheckbox
            checked={checked}
            onChange={(event, value) => {
              onCheck(value);
            }}
            disabled={lock}
          />
          <div className={style.reportDate}>{date}</div>
        </Box>
        <Box display={'flex'} alignItems={'center'} pr={0.8} gap={0.8}>
          {bill && <ButtonBase className={style.billed}>{tp('billed')}</ButtonBase>}
          {lock ? <LockIcon color={'disabled'} /> : <LockOpenIcon color={'action'} opacity={0.3} />}
        </Box>
      </Box>
      <div className={style.reportName}>{reportName}</div>
    </div>
  );
};
