import LinkIcon from '@mui/icons-material/Link';
import { ButtonBase, Link, Stack, Typography } from '@mui/material';
import { APP_ROUTES } from 'configs';
import { useCopyToClipboard, useNotify, useTranslate } from 'hooks';
import React, { MouseEventHandler, useCallback, useMemo } from 'react';
import { handleEventStopAndPrevent } from 'utils/handlers';
import style from './index.module.scss';

interface Props {
  itemKey: string;
  queryKey?: 'ticket-action-item-comment';
}
export const CommentLink: React.FC<Props> = ({ itemKey }) => {
  const { tp } = useTranslate();
  const { onSuccess } = useNotify();
  const link = useMemo(() => {
    return APP_ROUTES.CHECK_DONE_ALL({ 'ticket-action-item-comment': itemKey }).href;
  }, [itemKey]);

  const [onCopy] = useCopyToClipboard();

  const onClick = useCallback<MouseEventHandler>(
    async (e) => {
      e.stopPropagation();
      e.preventDefault();

      const res = await onCopy(link);

      if (res.success) {
        onSuccess(tp('copied'));
      }
    },
    [onCopy, onSuccess, link, tp],
  );

  return (
    <ButtonBase
      component={Link}
      href={link}
      variant={'body2'}
      onClick={onClick}
      className={style.root}
      onDoubleClick={handleEventStopAndPrevent}
    >
      <Stack direction={'row'} spacing={0.4} alignItems={'center'}>
        <LinkIcon
          color={'silver'}
          fontSize={'inherit'}
          sx={{
            fontSize: '2rem',
          }}
        />
        <Typography
          variant={'caption'}
          component={'div'}
          alignSelf={'center'}
          lineHeight={1}
          pt={0.4}
          pb={0.4}
        >
          {itemKey}
        </Typography>
      </Stack>
    </ButtonBase>
  );
};
