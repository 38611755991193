import { DynamicOrder } from 'utils/dynamic';
import { replaceSpace } from 'utils/other';
import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'Priorities';

export const API_PRIORITIES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<IPriority, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<IPriority, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IPriority
  extends FixServerObject<
    Components.Schemas.Priority,
    'id' | 'isActive' | 'isDefault' | 'labelKey' | 'icon' | 'rank' | 'color'
  > {}
export const schemaPriority = yup.object({
  labelKey: yup.string().required('rule-required').transform(replaceSpace).default(''),
  color: yup.string().required('rule-required').default(''),
  icon: yup.mixed<string>().default(''),
  isActive: yup.boolean().default(false),
  isDefault: yup.boolean().default(false),
  rank: yup.number().notRequired(),
});

export interface IPriorityArgs {
  search: string;

  take: number;
  skip: number;
  orderBy: DynamicOrder;
}
