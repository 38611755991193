import { Stack } from '@mui/material';
import { Loading } from 'components/loading';
import { NativeCustomScroll } from 'components/native-custom-scroll';
import { useAppDispatch, useAppSelector } from 'hooks';
import { memo, useCallback } from 'react';
import {
  actionsCheckDoneTickets,
  selectCheckDoneTicketsSelectedID,
  selectCheckDoneTicketsStatus,
  selectCheckDoneTicketsStatusesBoards,
} from 'store/check-done-tickets';
import { TicketCard, TicketRegular, TicketStatusRow } from './components';

export { TicketRegular };

export const Tickets = memo(() => {
  const dispatch = useAppDispatch();
  const { isLoading, isInit } = useAppSelector(selectCheckDoneTicketsStatus);
  const statuses = useAppSelector(selectCheckDoneTicketsStatusesBoards);
  const selectedID = useAppSelector(selectCheckDoneTicketsSelectedID);

  const factorySelect = useCallback(
    (id: string) => {
      return () => {
        dispatch(actionsCheckDoneTickets.selectTicket(id));
      };
    },
    [dispatch],
  );

  return (
    <NativeCustomScroll>
      <Stack direction={'column'} spacing={0.2}>
        {statuses.map((status) => {
          const tickets = status.tickets;
          return (
            <TicketStatusRow key={status.id} status={status} count={tickets.length}>
              <Stack direction={'column'} spacing={0.4}>
                {tickets.map((ticket) => {
                  return (
                    <TicketCard
                      key={ticket.id}
                      data={ticket}
                      project={ticket._project}
                      customer={ticket._customer}
                      selected={selectedID === ticket.id}
                      onSelect={factorySelect(ticket.id)}
                    />
                  );
                })}
              </Stack>
            </TicketStatusRow>
          );
        })}
        {isLoading && !isInit && <Loading />}
      </Stack>
    </NativeCustomScroll>
  );
});
