import { BoxProps, Checkbox } from '@mui/material';
import clsx from 'clsx';
import { AppLoading } from 'components/app-loading';
import {
  DataGrid,
  DataGridColumn,
  DataGridDefaultTasks,
  IDataGridRowPropsFunction,
  TDataGridVirtualProps,
} from 'components/data-grid';
import { EmptyTimeTracking } from 'components/empty-time-tracking';
import { NativeCustomScroll } from 'components/native-custom-scroll';
import deepmerge from 'deepmerge';
import { useTranslate } from 'hooks';
import { useValueQuery } from 'hooks/use-value-query';
import React, { useCallback, useMemo, useRef } from 'react';
import { DelayRender } from '../../../../../delay-render';
import { useGridConfig } from '../../hooks/use-grid-config';
import { useRtkReducer } from '../../hooks/use-rtk-reducer';
import { useSelected } from '../../hooks/use-selected';
import { GridModel, GridModelExtended, Meta, QUERY_NAME_ADD_TO_REPORT_POPUP } from '../../models';
import { AddToReportPopup } from '../add-to-report-popup';
import style from './index.module.scss';

interface Props {
  isLoading: boolean;
  isEmpty: boolean;
  meta: Meta;
  rows: GridModelExtended[];
  onTask: (task: DataGridDefaultTasks<GridModel>) => undefined | void;
  onNew: () => void;
}
export const ViewDesktop: React.FC<Props> = ({ isLoading, isEmpty, rows, onNew, onTask, meta }) => {
  const { tp } = useTranslate();
  const {
    isAllSelected,
    isAnySelected,
    onSelectAll,
    map: mapSelected,
    onSelectItem,
  } = useSelected();
  const { value, onClear } = useValueQuery<string>({
    name: QUERY_NAME_ADD_TO_REPORT_POPUP,
  });
  const { gridColumns, getOrderFactory } = useGridConfig({ meta });

  const {
    state: { orderBy },
    orderSet,
  } = useRtkReducer();

  const orderedRows = useMemo(() => {
    return Array.from(rows).sort((a, b) => {
      return getOrderFactory(orderBy.field as any, orderBy.order)(a, b);
    });
  }, [rows, getOrderFactory, orderBy]);

  const factoryClickRow = useCallback(
    (row: GridModel) => {
      return () => onTask({ type: 'row-edit', payload: { row } });
    },
    [onTask],
  );

  const CellPropsColor = useCallback((props: { row: GridModelExtended }): Partial<BoxProps> => {
    if (!props.row.__isInReport) {
      return {};
    }

    return { style: { backgroundColor: '#EFF7EE' } };
  }, []);
  const CellPropsClick = useCallback(
    (props: { row: GridModelExtended }): Partial<BoxProps> => {
      const isLock = props.row.__isLocked;
      if (isLock) {
        return {};
      }

      return { onClick: factoryClickRow(props.row) };
    },
    [factoryClickRow],
  );

  const CellProps = useCallback(
    (props: { row: GridModelExtended }) => {
      return deepmerge(CellPropsColor(props), CellPropsClick(props));
    },
    [CellPropsColor, CellPropsClick],
  );

  const refScroll = useRef<HTMLDivElement>(null);
  const virtualProps = useMemo<TDataGridVirtualProps>(() => {
    return {
      getScrollElement: () => {
        return refScroll.current;
      },
      estimateSize: () => 37,
    };
  }, []);

  const factorySelectItem = useCallback(
    (id: string) => {
      return () => onSelectItem(id);
    },
    [onSelectItem],
  );

  const rowProps = useCallback<IDataGridRowPropsFunction<GridModel>>(({ row }) => {
    return { className: clsx(!row.isActive && style.unActivate) };
  }, []);

  const element = isEmpty ? (
    <EmptyTimeTracking onOpen={onNew} title={tp('tracked-time-is-empty-for-task')} />
  ) : (
    <DelayRender delay={100}>
      <DataGrid
        onTask={onTask}
        rows={orderedRows}
        sortValue={orderBy}
        sortChange={orderSet}
        rowKey={'id'}
        virtual={true}
        virtualProps={virtualProps}
        classes={{
          row: clsx(style.row),
        }}
        rowProps={rowProps}
      >
        <DataGridColumn<GridModelExtended>
          field={'id'}
          title={
            <Checkbox
              checked={isAnySelected}
              indeterminate={isAnySelected && !isAllSelected}
              onChange={onSelectAll}
              size={'small'}
            />
          }
          p={0}
          HeadCellProps={{
            padding: 0,
          }}
          CellProps={CellPropsColor}
          children={({ row }) => {
            return (
              <Checkbox
                checked={!!mapSelected[row.id]}
                size={'small'}
                onChange={factorySelectItem(row.id)}
              />
            );
          }}
        />
        {gridColumns.map((column) => {
          return <DataGridColumn key={column.field} {...column} CellProps={CellProps} />;
        })}
      </DataGrid>
    </DelayRender>
  );

  return (
    <div className={style.root}>
      <NativeCustomScroll ref={refScroll} mode={'always'}>
        {element}
        {isLoading && <AppLoading />}
        {value && <AddToReportPopup onClose={onClear} timeTrackingID={value} />}
      </NativeCustomScroll>
    </div>
  );
};
