import { FixServerObject, PatchPartial } from 'utils/types';
import * as yup from 'yup';

const API = 'Tickets';

export const API_TICKETS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ITicket, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ITicket, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface ITicket
  extends FixServerObject<
    Components.Schemas.Ticket,
    | 'id'
    | 'title'
    | 'ticketStatusID'
    | 'priorityID'
    | 'defaultReporterUserCrmProfileID'
    | 'ownerUserCrmProfileID'
    | 'projectID'
  > {}

export const schemaTicket = yup.object({
  id: yup.string().notRequired().default(undefined),
  title: yup.string().required('rule-required').default(''),

  ticketStatusID: yup.string().required('rule-required').default(''),
  priorityID: yup.string().required('rule-required').default(''),
  projectID: yup.string().required('rule-required').default(''),
  ownerUserCrmProfileID: yup.string().required('rule-required').default(''),
  defaultReporterUserCrmProfileID: yup.string().required('rule-required').default(''),
  description: yup.string().nullable().notRequired().default(''),
  mustTrackTime: yup.boolean().optional().default(false),

  isActive: yup.boolean().optional().default(true),
  done: yup.boolean().optional().default(false),
});

export interface ITicketPost extends Omit<ITicket, 'id'> {}

export interface ITicketPatch extends PatchPartial<ITicket, 'id'> {}

export interface ITicketDelete extends Pick<ITicket, 'id'> {}

export interface ITicketFull
  extends Pick<
    ITicket,
    | 'id'
    | 'title'
    | 'itemKey'
    | 'done'
    | 'isPrivate'
    | 'mustTrackTime'
    | 'ticketTypeID'
    | 'ticketStatusID'
    | 'priorityID'
    | 'ownerUserCrmProfileID'
    | 'defaultReporterUserCrmProfileID'
    | 'projectID'
  > {}
