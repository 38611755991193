import { createSelector } from '@reduxjs/toolkit';
import { calcPaginationState } from 'utils/service';
import { AppState } from '../index';

const selectState = (state: AppState) => state.alerts;

export const selectAlertsStatuses = createSelector(selectState, ({ isLoading, error, isInit }) => {
  return { isLoading, error, isInit };
});
export const selectAlertsData = createSelector(selectState, ({ data }) => {
  return data;
});
export const selectAlertsFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});
export const selectAlertsPagination = createSelector(selectState, ({ pagination }) => {
  return calcPaginationState(pagination);
});
export const selectAlertsOrderBy = createSelector(selectState, ({ orderBy }) => {
  return orderBy;
});
