import { apiRtk, DynamicService } from 'utils/service';
import {
  API_NOTIFICATIONS,
  INotification,
  NotificationDeleteInput,
  NotificationPatchInput,
  NotificationPostInput,
} from './models';

export * from './models';

type Model = INotification;

const REVALIDATE_TAG = 'Notifications' as const;

class Service extends DynamicService<Model> {}

export const ServiceNotifications = new Service({
  getAll: API_NOTIFICATIONS.GET_ALL_DYNAMIC,
  post: API_NOTIFICATIONS.POST,
  patch: API_NOTIFICATIONS.PATCH,
  delete: API_NOTIFICATIONS.DELETE,
});

export const apiNotifications = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    postNotification: build.mutation<INotification, NotificationPostInput>({
      queryFn: async (input) => {
        try {
          const { data } = await ServiceNotifications.post(input);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG, id: 'grid' }],
    }),
    patchNotification: build.mutation<void, NotificationPatchInput>({
      queryFn: async (data, { dispatch }) => {
        try {
          await ServiceNotifications.patch(data);

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG, id: 'grid' }],
    }),
    deleteNotification: build.mutation<void, NotificationDeleteInput>({
      queryFn: async (data) => {
        try {
          await ServiceNotifications.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG, id: 'grid' }],
    }),
  }),
});
