import { apiAppNotifications } from 'services/app-notifications';
import { call, delay, put, race, select, take, takeEvery } from 'typed-redux-saga';
import { actionAccountGetUser, actionAccountLogout, selectAccountUser } from '../auth';
import { actionNotificationsNotDoneFetch } from './actions';
import { selectNotificationsNotDoneIsOpen } from './selectors';

function* sagaRefresh() {
  yield* put(actionNotificationsNotDoneFetch());
}

function* sagaRefreshLoop() {
  while (true) {
    const isOpen = yield* select(selectNotificationsNotDoneIsOpen);
    if (!isOpen) {
      yield* put(actionNotificationsNotDoneFetch());
    }
    yield* delay(1000 * 60);
  }
}

function* sagaWatchAuth() {
  while (true) {
    yield* take(actionAccountGetUser.fulfilled);

    let user = yield* select(selectAccountUser);

    if (!user) {
      continue;
    }

    yield* race({
      task: call(sagaRefreshLoop),
      cancel: take([actionAccountGetUser.rejected, actionAccountLogout.fulfilled, 'TEST']),
    });
  }
}

export const sagasNotificationsNotDone = [
  sagaWatchAuth(),
  takeEvery(
    [
      apiAppNotifications.endpoints.createAppNotification.matchFulfilled,
      apiAppNotifications.endpoints.doneAppNotification.matchFulfilled,
    ],
    sagaRefresh,
  ),
];
