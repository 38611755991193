import { Paper, PaperProps } from '@mui/material';
import { forwardRef } from 'react';
import Draggable, { DraggableProps } from 'react-draggable';

interface DraggablePaperProps extends PaperProps {
  DraggableProps?: Partial<DraggableProps>;
}
export const DraggablePaper = forwardRef<HTMLDivElement, DraggablePaperProps>(
  ({ DraggableProps, ...paperProps }, ref) => {
    return (
      <Draggable {...DraggableProps}>
        <Paper
          {...paperProps}
          ref={ref}
          sx={{ outline: 'none', borderRadius: '0.8rem', ...paperProps?.sx }}
        />
      </Draggable>
    );
  },
);
