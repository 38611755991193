import { Paper, Typography } from '@mui/material';
import { BaseCommentDescription } from 'components/tickets';
import { useTranslate } from 'hooks';
import React from 'react';
import { BaseImage } from 'services/@shared/images';
import { Unset } from 'utils/types';

interface Props {
  description: Unset;
  images: BaseImage[];
}

export const DescriptionPreview: React.FC<Props> = ({ images, description }) => {
  const { tp } = useTranslate();
  return (
    <Paper
      sx={{
        paddingX: 1.6,
        paddingTop: 1,
        paddingBottom: 1.6,
      }}
    >
      <Typography color={'text.secondary'} variant={'caption'} component={'div'} mb={0.5}>
        {tp('description')}
      </Typography>
      <BaseCommentDescription images={images} description={description} />
    </Paper>
  );
};
