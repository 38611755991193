import { AppSwitch } from 'components/app-switch';
import { useEffectNotifyError } from 'hooks';
import React, { useCallback, useState } from 'react';
import { apiAppNotifications, AppNotificationLatest } from 'services/app-notifications';
import { handleEventStop } from 'utils/handlers';

const useDoneMutation = apiAppNotifications.useDoneAppNotificationMutation;

interface Props {
  row: AppNotificationLatest;
  disabled?: boolean;
}
export const NotificationCardControlDone: React.FC<Props> = ({ row, disabled }) => {
  const [done, setDone] = useState(false);
  const [doneItem, resultDoneItem] = useDoneMutation();
  useEffectNotifyError(resultDoneItem.error);

  const onChange = useCallback(() => {
    setDone(true);
    doneItem(String(row.id));
  }, [doneItem, row.id]);

  return (
    <AppSwitch
      value={done}
      color={'primary'}
      onChange={onChange}
      disabled={resultDoneItem.isLoading || done || disabled}
      onClick={handleEventStop}
    />
  );
};
