import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_LANGUAGES, Language } from './models';

const REVALIDATE_KEY = 'Languages' as const;

export * from './models';

export enum DIRECTION {
  RTL = 2,
  LTR = 1,
}
class Service extends DynamicService<Language> {}

export const ServiceLanguages = new Service({
  getAll: API_LANGUAGES.GET_ALL_DYNAMIC,
  post: API_LANGUAGES.CREATE,
  patch: API_LANGUAGES.PATCH,
  delete: API_LANGUAGES.DELETE,
});

type ApiModel = Language;

export const apiLanguages = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getAllLanguages: build.query<ApiModel[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceLanguages.getAllDynamic({
            select: dynamic.select('id', 'title'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error) => [{ type: REVALIDATE_KEY }],
    }),
    getLanguage: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServiceLanguages.getDynamic(id);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postLanguage: build.mutation<void, ApiModel>({
      queryFn: async (data) => {
        try {
          await ServiceLanguages.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }, { type: REVALIDATE_KEY }],
    }),
    patchLanguage: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceLanguages.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deleteLanguage: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceLanguages.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
  }),
});
