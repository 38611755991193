import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/src/createAction';
import { apiTickets, ITicket } from 'services/tickets';
import { actionCheckDoneTicketsFetchAll, actionCheckDoneTicketsFetchItem } from './actions';

export interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;
  selectedID: string | null;

  filters: Filters;

  data: ITicket[];
}

export interface Filters {
  customerID: string;
  projectID: string;
}

const initState = (): State => {
  return {
    error: null,
    isLoading: false,
    isInit: false,

    selectedID: null,
    filters: {
      customerID: '',
      projectID: '',
    },

    data: [],
  };
};

const slice = createSlice({
  name: 'CHECK_DONE_TICKETS',
  initialState: initState(),
  reducers: {
    setFilters(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    selectTicket(state, action: PayloadAction<string | null>) {
      state.selectedID = state.selectedID === action.payload ? null : action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionCheckDoneTicketsFetchAll.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(actionCheckDoneTicketsFetchAll.fulfilled, (state, action) => {
        const { payload } = action;
        state.data = payload;
        state.isLoading = false;
      })
      .addCase(actionCheckDoneTicketsFetchAll.rejected, (state, action) => {
        const { error } = action;
        state.isLoading = false;
        state.error = error;
        state.isInit = true;
      });

    builder.addCase(actionCheckDoneTicketsFetchItem.fulfilled, (state, action) => {
      const ticket = state.data.find((ticket) => ticket.id === action.payload.id);

      if (ticket) {
        Object.assign(action, action.payload);
      }
    });
    // watch update ticket
    builder.addMatcher(apiTickets.endpoints.patchTicket.matchPending, (state, action) => {
      const ticket = state.data.find((ticket) => {
        return ticket.id === action.meta.arg.originalArgs.id;
      });

      if (ticket) {
        Object.assign(ticket, action.meta.arg.originalArgs);
      }
    });
    // watch delete ticket
    builder.addMatcher(apiTickets.endpoints.deleteTicket.matchPending, (state, action) => {
      state.data = state.data.filter((ticket) => {
        return ticket.id !== action.meta.arg.originalArgs.id;
      });
    });
  },
});

export const actionsCheckDoneTickets = slice.actions;
export const reducerCheckDoneTickets = slice.reducer;
