import { yupResolver } from '@hookform/resolvers/yup';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Dialog, DialogActions, DialogContent, Grid, InputAdornment } from '@mui/material';
import { AppDatePicker } from 'components/app-date-picker';
import { AppSelect } from 'components/app-select';
import { AppTimeDuration } from 'components/app-time-duration';
import { ButtonCancel, ButtonCreate, ButtonDelete } from 'components/buttons';
import { DialogDelete } from 'components/dialog-delete';
import { DialogHeader } from 'components/dialog-header';
import { DEFAULT_INIT, IAllProps } from 'components/html-editor';
import { HtmlEditorPreview } from 'components/html-editor-preview';
import { BaseBreadcrumbs, BaseDialogTitle } from 'components/tickets';
import {
  useEffectNotifyError,
  useFieldProps,
  useOpen,
  useSourceTimeTrackingActivities,
  useTranslate,
} from 'hooks';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { apiTimeTracking, schemaTimeTrackingMultiple } from 'services/time-trackings';
import { composeFunctions } from 'utils/other';
import { isFulfilledMutation } from 'utils/service';
import { InferType } from 'yup';
import { useTicketActionItemInformation } from '../../../../hooks/use-ticket-information';

const useFetchItemQuery = apiTimeTracking.useGetTimeTrackingItemQuery;
const usePatchMutation = apiTimeTracking.usePatchTimeTrackingItemMutation;
const useDeleteMutation = apiTimeTracking.useDeleteTimeTrackingItemMutation;

const EditorProps: IAllProps = {
  init: {
    ...DEFAULT_INIT,
    height: 150,
  },
};

const schema = schemaTimeTrackingMultiple.pick([
  'timeTrackingActivityID',
  'duration',
  'description',
  'date',
]);

type FormModel = InferType<typeof schema>;
interface Props {
  itemID: string;
  ticketActionItemID: string;
  onClose: () => void;
}
export const TicketActionItemDurationDialogRegular: React.FC<Props> = ({
  onClose,
  ticketActionItemID,
  itemID,
}) => {
  const { tp } = useTranslate();
  const methods = useForm({ defaultValues: schema.cast({}), resolver: yupResolver(schema) });
  const getFieldProps = useFieldProps();
  const { control, reset, handleSubmit } = methods;

  const resultInfo = useTicketActionItemInformation(ticketActionItemID);
  useEffectNotifyError(resultInfo.error);
  const { customer, ticket, ticketActionItem } = resultInfo;

  const breadcrumbs = useMemo(
    () => [
      ...(customer ? [{ label: customer.title }] : []),
      ...(ticket ? [{ label: ticket.title }] : []),
    ],
    [customer, ticket],
  );

  const resultItem = useFetchItemQuery(itemID);
  useEffectNotifyError(resultItem.error);
  useEffect(() => {
    if (!resultItem.data) return;
    reset(schema.cast(resultItem.data, { stripUnknown: true, assert: false }));
  }, [reset, resultItem.data]);

  const sourceActivities = useSourceTimeTrackingActivities();

  const [patchItem, resultPatch] = usePatchMutation();
  useEffectNotifyError(resultPatch.error);

  const onSubmit = useCallback(
    async (formData: FormModel) => {
      const result = await patchItem({
        ...formData,
        id: itemID,
        ticketActionItemID,
      });

      if (isFulfilledMutation(result)) {
        onClose();
      }
    },
    [patchItem, onClose, itemID, ticketActionItemID],
  );

  const [deleteItem, resultDelete] = useDeleteMutation();
  useEffectNotifyError(resultDelete.error);
  const onConfirmDelete = useCallback(async () => {
    const result = await deleteItem({ id: itemID, ticketActionItemID });

    if (isFulfilledMutation(result)) {
      onClose();
    }
  }, [itemID, deleteItem, onClose, ticketActionItemID]);
  const stateDelete = useOpen();

  const isLoading =
    resultInfo.isFetching ||
    resultPatch.isLoading ||
    resultItem.isFetching ||
    resultDelete.isLoading;

  return (
    <>
      <Dialog open={true} fullWidth maxWidth={'sm'}>
        <DialogHeader title={tp('track-time')} onClose={onClose} />
        <DialogContent>
          <BaseBreadcrumbs isLoading={resultInfo.isFetching} items={breadcrumbs} />
          <BaseDialogTitle isLoading={resultInfo.isFetching} component={'h4'} mt={1.5} mb={2}>
            {ticketActionItem?.title}
          </BaseDialogTitle>
          <Grid container spacing={1}>
            <Grid item container xs={12} md={true} spacing={1}>
              <Grid item xs={'auto'}>
                <Controller
                  control={control}
                  name={'duration'}
                  render={(renderProps) => (
                    <AppTimeDuration
                      {...getFieldProps(renderProps)}
                      disabled={isLoading}
                      disableClearable
                      sx={{
                        minWidth: '14rem',
                      }}
                      RenderInputProps={{
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position={'end'}>
                              <AccessTimeIcon />
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={true}>
                <Controller
                  name={'date'}
                  control={control}
                  render={(renderProps) => {
                    const props = getFieldProps(renderProps);
                    return (
                      <AppDatePicker
                        {...props}
                        disabled={isLoading}
                        slotProps={{
                          textField: { fullWidth: true },
                        }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={'timeTrackingActivityID'}
                  render={(renderProps) => (
                    <AppSelect
                      {...getFieldProps(renderProps)}
                      disabled={isLoading}
                      disableClearable
                      loading={sourceActivities.isLoading}
                      options={sourceActivities.data}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={'description'}
                render={(renderProps) => (
                  <HtmlEditorPreview
                    editorProps={EditorProps}
                    {...getFieldProps(renderProps)}
                    disabled={isLoading}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDelete onClick={stateDelete.onOpen} sx={{ mr: 'auto' }} />
          <ButtonCancel onClick={onClose} color={'primary'} />
          <ButtonCreate color={'primary'} disabled={isLoading} onClick={handleSubmit(onSubmit)}>
            {tp('track-time')}
          </ButtonCreate>
        </DialogActions>
      </Dialog>
      {stateDelete.isOpen && (
        <DialogDelete
          onClose={stateDelete.onClose}
          onAgree={composeFunctions(stateDelete.onClose, onConfirmDelete)}
        >
          {tp('delete-item')}
        </DialogDelete>
      )}
    </>
  );
};
