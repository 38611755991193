import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

interface Options<T> {
  name: string;
  init?: T;
}
export const useValueQuery = <T extends string | number | boolean | null>(options: Options<T>) => {
  const { name, init } = options;

  let [searchParams, setSearchParams] = useSearchParams();
  const onChange = useCallback(
    (value: T | null) => {
      const sp = new URLSearchParams(window.location.search);

      if (value === null || value === undefined) {
        sp.delete(name);
      } else if (sp.has(name)) {
        sp.set(name, String(value));
      } else {
        sp.append(name, String(value));
      }
      return setSearchParams(sp);
    },
    [setSearchParams, name],
  );
  const onClear = useCallback(() => {
    onChange(null);
  }, [onChange]);
  const value = useMemo(() => {
    return searchParams.get(name);
  }, [name, searchParams]);

  const refOnce = useRef(false);
  useEffect(() => {
    if (init && !value && !refOnce.current) {
      refOnce.current = true;
      onChange(init);
    }
  }, [value, init, onChange]);
  return { value, onChange, onClear };
};
