import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { endOfMonth, startOfMonth } from 'date-fns';
import { TICKET_ACTION_ITEM_STATUS_IDS } from 'services/ticket-action-item-statuses';
import { apiTicketActionItems, ITicketActionItem } from 'services/ticket-action-items';
import { DateValue } from 'utils/dates';
import { listToOrderList, orderListToOrderMap, OrderMap } from 'utils/order';
import {
  actionCheckDoneNotCompletedFetchAll,
  actionCheckDoneNotCompletedFetchItem,
  actionCheckDoneNotCompletedMove,
} from './actions';
import { ICheckDoneNotCompletedItem } from './helpers';

export interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;

  filters: Filters;
  data: ICheckDoneNotCompletedItem[];

  orders: OrderMap;
  lastOrderedID: string | null;

  editor: null | Partial<ITicketActionItem>;
}
interface Filters {
  search: string;
  assigneeUserCrmProfileIDs: string[];
  reporterUserCrmProfileID: string;
  dateRange: DateValue[];
  ticketActionPriorityID: string;
  ticketActionStatusID: string;
}

const initState = (): State => {
  const currentDate = new Date();
  const startDate = startOfMonth(currentDate);
  const endDate = endOfMonth(currentDate);
  return {
    error: null,
    isLoading: false,
    isInit: false,

    filters: {
      search: '',
      assigneeUserCrmProfileIDs: [],
      reporterUserCrmProfileID: '',

      ticketActionPriorityID: '',
      ticketActionStatusID: '',
      dateRange: [startDate, endDate],
    },
    data: [],
    orders: {},
    lastOrderedID: null,
    editor: null,
  };
};

const slice = createSlice({
  name: 'CHECK_DONE_NOT_COMPLETED',
  initialState: initState(),
  reducers: {
    setFilters(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    removeFilterAssigner(state, action: PayloadAction<string>) {
      state.filters.assigneeUserCrmProfileIDs = state.filters.assigneeUserCrmProfileIDs.filter(
        (id) => {
          return id !== action.payload;
        },
      );
    },
    setEditor(state, action: PayloadAction<Partial<ITicketActionItem> | null>) {
      state.editor = action.payload;
    },
    removeLastOrderedID(state) {
      state.lastOrderedID = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionCheckDoneNotCompletedFetchAll.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(actionCheckDoneNotCompletedFetchAll.fulfilled, (state, action) => {
        const { payload: value } = action;

        state.data = value.map((row) => ({ ...row, isPinned: false }));
        state.isLoading = false;
        state.isInit = true;

        const normalizedOrderList = listToOrderList(state.data, state.orders);
        const normalizedOrders = orderListToOrderMap(normalizedOrderList);

        Object.assign(state.orders, normalizedOrders);
      })
      .addCase(actionCheckDoneNotCompletedFetchAll.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });

    builder.addCase(actionCheckDoneNotCompletedFetchItem.fulfilled, (state, action) => {
      const ticketAction = state.data.find((ticketAction) => {
        return ticketAction.id === action.payload.id;
      });

      if (ticketAction) {
        Object.assign(ticketAction, action.payload);
      }
    });

    builder.addCase(actionCheckDoneNotCompletedMove.pending, (state, action) => {
      const { id, to } = action.meta.arg;
      const toIndex = to.index;

      state.lastOrderedID = id;

      const dataToSort = state.data.filter((item) => item.id !== id);
      const sortedList = listToOrderList(dataToSort, state.orders);

      const theSameRankIndex = sortedList.findIndex((item) => item.rank === toIndex);
      if (theSameRankIndex === -1) {
        Object.assign(state.orders, orderListToOrderMap(sortedList));
      } else {
        const increasedPart = sortedList
          .slice(theSameRankIndex)
          .map((item) => ({ ...item, rank: item.rank + 1 }));

        Object.assign(state.orders, orderListToOrderMap(increasedPart));
      }

      state.orders[id] = toIndex;
    });

    // watch update ticket action item
    builder.addMatcher(
      apiTicketActionItems.endpoints.patchTicketActionItem.matchPending,
      (state, action) => {
        const ticketAction = state.data.find((ticketAction) => {
          return ticketAction.id === action.meta.arg.originalArgs.id;
        });

        if (ticketAction) {
          Object.assign(ticketAction, action.meta.arg.originalArgs);
        }
      },
    );
    builder.addMatcher(
      apiTicketActionItems.endpoints.pinTicketActionItem.matchPending,
      (state, action) => {
        const row = state.data.find((row) => row.id === action.meta.arg.originalArgs.id);

        if (row) {
          row.pinned = true;
          row.ticketActionItemStatusID = TICKET_ACTION_ITEM_STATUS_IDS.IN_PROGRESS;
        }
      },
    );
    builder.addMatcher(
      apiTicketActionItems.endpoints.unPinTicketActionItem.matchPending,
      (state, action) => {
        const row = state.data.find((row) => row.id === action.meta.arg.originalArgs.id);

        if (row) {
          row.pinned = false;
        }
      },
    );
    builder.addMatcher(
      apiTicketActionItems.endpoints.patchTicketActionItem.matchFulfilled,
      (state, action) => {
        if (action.meta.arg.originalArgs.done === true) {
          state.data = state.data.filter((ticketAction) => {
            return ticketAction.id !== action.meta.arg.originalArgs.id;
          });
        }
      },
    );
    builder.addMatcher(
      isAnyOf(
        apiTicketActionItems.endpoints.deleteTicketActionItem.matchPending,
        apiTicketActionItems.endpoints.deactivateTicketActionItem.matchPending,
      ),
      (state, action) => {
        state.data = state.data.filter((ticketAction) => {
          return ticketAction.id !== action.meta.arg.originalArgs.id;
        });
      },
    );
    // watch create ticket action item
    builder.addMatcher(
      apiTicketActionItems.endpoints.postTicketActionItem.matchFulfilled,
      (state, action) => {
        state.data.push({
          ...action.payload,
          pinned: false,
          tracking: [],
        });
      },
    );
  },
});

export const actionsCheckDoneNotCompleted = slice.actions;
export const reducerCheckDoneNotCompleted = slice.reducer;
