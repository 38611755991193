import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { endOfYear, startOfYear } from 'date-fns';
import { DateValue } from 'utils/dates';
import { PREFIX } from './helpers';

interface Filters {
  search: string;
  customerID: string;
  inProgress: boolean;
  dateRange: DateValue[];
}

interface State {
  filters: Filters;
  isExpanded: boolean;
}

const initState = (): State => {
  const today = new Date();
  return {
    filters: {
      search: '',
      customerID: '',
      inProgress: true,
      dateRange: [startOfYear(today), endOfYear(today)],
    },
    isExpanded: true,
  };
};

const slice = createSlice({
  name: PREFIX,
  initialState: initState(),
  reducers: {
    reset() {
      return initState();
    },
    filtersSet(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    filtersReset(state) {
      state.filters = initState().filters;
    },
    toggleExpanded(state) {
      state.isExpanded = !state.isExpanded;
    },
  },
  extraReducers: (builder) => {},
});

export const actionsReports = slice.actions;
export const reducerReports = slice.reducer;
