import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns';
import { isNumber } from 'lodash-es';
import { convertToDate, isDateLike } from 'utils/dates';

class MergeFilters {
  private value: Array<string | undefined | boolean>;
  constructor(value: Array<string | undefined | boolean>) {
    this.value = value.filter(Boolean);
  }
  join(separator: string) {
    if (this.value.length === 0) {
      return undefined;
    }
    return createStart(this.value.length, this.value.join(separator));
  }
}

export type ValueCreator<T = any> = (name: string, value: T) => string | undefined;

export const select = (...args: string[]) => {
  return args
    .join(',')
    .replace(/  +/g, ' ') // remove extra spaces
    .replace(/\n/gm, '') // remove new lines
    .replace(' .', '.'); // remove spaces for methods
};

export interface DynamicOrder<F extends string = string> {
  field: F;
  order: 'desc' | 'asc' | null;
}
export function orderBy(data: DynamicOrder<any>): string | undefined;
export function orderBy(data: string, order?: 'desc' | 'asc' | null): string | undefined;
export function orderBy(data: any, order?: any): string | undefined {
  if (!data) {
    return undefined;
  }
  let _name: string;
  let _order: string;
  if (typeof data === 'object') {
    _name = data.field;
    _order = data.order;
  } else {
    _name = data;
    _order = order;
  }

  if (!_order) return;

  const names = _name.split(',');
  return names.map((name) => [name, _order].join(' ')).join(',');
}

const prepareValue = (value?: string) => {
  return String(value).trim().replace(/"/gi, '\\"');
};
const createStart = (count: number, query: string) => {
  return count > 1 ? `(${query})` : query === '' ? undefined : query;
};

export const makeFilter = (
  name: string | string[],
  value: any | any[],
  valueCreator: ValueCreator,
): string | undefined => {
  const names = Array.isArray(name) ? name : [name];

  if (value === undefined) {
    return undefined;
  }

  const values = names.map((_n) => valueCreator(String(_n), value)).filter(Boolean);

  return createStart(values.length, values.join('||'));
};

export const mergeFilters = (...filters: (string | undefined | boolean)[]) => {
  return new MergeFilters(filters);
};

// Value creators
export const more: ValueCreator = (name, value) => {
  if (!isNumber(value)) return undefined;

  return `${name}>${value}`;
};
export const moreOrEquals: ValueCreator = (name, value) => {
  if (!value) return undefined;

  return `${name}>=${value}`;
};
export const less: ValueCreator = (name, value) => {
  if (!value) return undefined;
  return `${name}<${value}`;
};
export const lessOrEquals: ValueCreator = (name, value) => {
  if (!value) return undefined;
  return `${name}<=${value}`;
};
export const equals: ValueCreator = (name, value) => {
  let innerValue = value;

  if (typeof value === 'string') {
    innerValue = `"${prepareValue(value)}"`;
  }
  return `${name}==${innerValue}`;
};
export const notEquals: ValueCreator = (name, value) => {
  let innerValue = value;

  if (typeof value === 'string') {
    innerValue = `"${prepareValue(value)}"`;
  }
  return `${name}!=${innerValue}`;
};
export const contains: ValueCreator = (name, value) => {
  if (!value) return undefined;

  return `${name}.contains("${prepareValue(String(value))}")`;
};
export const dateRange: ValueCreator = (name, value) => {
  if (!Array.isArray(value)) return undefined;

  const range = value.filter(isDateLike);

  if (range.length < 2) {
    return undefined;
  }
  const [start, end] = range;
  return [
    `(${name} >= "${format(convertToDate(start), 'yyyy-MM-dd')}"`,
    `${name} <= "${format(convertToDate(end), 'yyyy-MM-dd')}")`,
  ].join('&&');
};

export const dateRangeTwoMonths: ValueCreator = (name, value) => {
  if (!isDateLike(value)) return undefined;

  const startDate = startOfMonth(subMonths(convertToDate(value), 2));
  const endDate = endOfMonth(convertToDate(value));

  return [
    `(${name} >= "${format(startDate, 'yyyy-MM-dd')}"`,
    `${name} <= "${format(endDate, 'yyyy-MM-dd')}")`,
  ].join('&&');
};

// decorators

export const decoratorIsNotNullable = (creator: ValueCreator): ValueCreator => {
  const result: ValueCreator = (name, value) => {
    if ([value === null, value === ''].some(Boolean)) return undefined;
    return creator(name, value);
  };
  return result;
};
export const decoratorIsNumber = (creator: ValueCreator): ValueCreator => {
  const result: ValueCreator = (name, value) => {
    if ([!isFinite(value as any)].some(Boolean)) return undefined;
    return creator(name, value);
  };
  return decoratorIsNotNullable(result);
};
export const decoratorValueArray = (creator: ValueCreator, separator = '||') => {
  return ((name, value) => {
    if (!Array.isArray(value)) return undefined;
    if (value.length === 0) return undefined;
    return createStart(
      value.length,
      value.map((v) => makeFilter(name, v, creator)).join(separator),
    );
  }) as ValueCreator;
};
export const decoratorExclude = (creator: ValueCreator, excludeValue: any) => {
  return ((name, value) => {
    if (value === excludeValue) return undefined;
    return creator(name, value);
  }) as ValueCreator;
};
export const decoratorDateISO = (creator: ValueCreator) => {
  return ((name, value) => {
    if (!isDateLike(value)) {
      return undefined;
    }
    return creator(name, `DateTime("${convertToDate(value).toISOString()}")`);
  }) as ValueCreator;
};
