import { omit } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useMap } from './use-map';

type MapSelected = Record<string, true>;

export type MapSelectedOptions<T> = {
  rows: T[];
  key: keyof T;
};
export type MapSelectedResult<T> = {
  map: Record<string, T | undefined>;
  selectedRows: Array<T>;
  onSelectItem: (id: string) => void;
  onSelectAll: () => void;
  isAnySelected: boolean;
  isAllSelected: boolean;
};

export const useMapSelected = <T extends Record<string, any>>(
  options: MapSelectedOptions<T>,
): MapSelectedResult<T> => {
  const { rows, key } = options;

  const [selected, setSelected] = useState<MapSelected>({});

  const selectedRows = useMemo(() => {
    return rows.filter((row) => selected[row[key]]);
  }, [rows, key, selected]);
  const mapSelected = useMap(selectedRows, key);

  const isAnySelected = useMemo(() => {
    return Object.keys(mapSelected).length > 0;
  }, [mapSelected]);
  const isAllSelected = useMemo(() => {
    if (!isAnySelected) {
      return false;
    }
    return Object.keys(mapSelected).length === rows.length;
  }, [isAnySelected, mapSelected, rows.length]);

  const onSelectItem = useCallback((id: string) => {
    setSelected((prevState) => {
      const checked = prevState[id];

      if (checked) {
        return omit(prevState, id);
      }

      return { ...prevState, [id]: true };
    });
  }, []);
  const onSelectAll = useCallback(() => {
    if (isAllSelected) {
      setSelected({});
    } else {
      setSelected(
        rows.reduce((acc, item) => {
          acc[item[key]] = true;
          return acc;
        }, {} as Record<string, true>),
      );
    }
  }, [isAllSelected, key, rows]);

  return {
    map: mapSelected,
    selectedRows,
    onSelectItem,
    onSelectAll,
    isAnySelected,
    isAllSelected,
  };
};
