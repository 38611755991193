import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogActions, Paper, Theme, useMediaQuery } from '@mui/material';
import { AppInputTitle } from 'components/app-input-title';
import { ButtonCancel, ButtonCreate, ButtonRemove } from 'components/buttons';
import { DialogDelete } from 'components/dialog-delete';
import { DialogHeader } from 'components/dialog-header';
import { NativeScroll } from 'components/native-scroll';
import { BaseDescriptionInput } from 'components/tickets';
import { useCurrentUser, useEffectNotifyError, useFieldProps, useOpen, useTranslate } from 'hooks';
import { memo, useCallback, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { apiTickets, schemaTicket } from 'services/tickets';
import { composeFunctions } from 'utils/other';
import { isFulfilledMutation } from 'utils/service';
import { Form } from './form';
import style from './index.module.scss';

const schema = schemaTicket;
const defaultValues = schema.cast({});

const useFetchQuery = apiTickets.useGetTicketByKeyQuery;
const usePatchMutation = apiTickets.usePatchTicketMutation;
const useDeleteMutation = apiTickets.useDeleteTicketMutation;

interface Props {
  itemKey: string;
  onClose: () => void;
  onDone: () => void;
}

export const TicketRegular = memo<Props>(({ itemKey, onClose, onDone }) => {
  const { tp } = useTranslate();

  const user = useCurrentUser();
  const methods = useForm({
    defaultValues: {
      ...defaultValues,
      defaultReporterUserCrmProfileID: user.appUserID,
    },
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });
  const { reset, handleSubmit, getValues, control } = methods;
  const getFieldProps = useFieldProps();
  const { data, isLoading: isLoadingGet, error: errorGet } = useFetchQuery(itemKey);
  useEffectNotifyError(errorGet);
  useEffect(() => {
    if (!data) return;
    reset(schema.cast({ ...getValues(), ...data }));
  }, [data, reset, getValues]);

  const id = data?.id;

  const [patchItem, resultPatch] = usePatchMutation();
  useEffectNotifyError(resultPatch.error);

  const [deleteItem, resultDelete] = useDeleteMutation();
  useEffectNotifyError(resultDelete.error);

  const onConfirmDelete = useCallback(async () => {
    if (!id) return;

    const result = await deleteItem({ id });

    if (isFulfilledMutation(result)) {
      onClose();
      onDone();
    }
  }, [deleteItem, onClose, onDone, id]);

  const isLoading = isLoadingGet || resultPatch.isLoading || resultDelete.isLoading;

  const onSubmit = useCallback(
    async (formData: typeof defaultValues) => {
      if (!id) return;

      const result = await patchItem({
        ...formData,
        id,
        isActive: formData.isActive ?? undefined,
        done: formData.done ?? undefined,
        mustTrackTime: formData.mustTrackTime ?? undefined,
      });
      if (isFulfilledMutation(result)) {
        onClose();
        onDone();
      }
    },
    [id, patchItem, onClose, onDone],
  );

  const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useOpen();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Dialog open={true} fullWidth maxWidth={'lg'} fullScreen={isMobile}>
      <DialogHeader
        isLoading={isLoading}
        title={data ? data.title : tp('create-new')}
        onClose={onClose}
      />
      <FormProvider {...methods}>
        <div className={style.layout}>
          <NativeScroll className={style.left} mode={'always'}>
            <div className={style.top}>
              <Controller
                control={control}
                name={'title'}
                render={(renderProps) => {
                  const props = getFieldProps(renderProps);

                  return (
                    <AppInputTitle
                      value={props.value}
                      onChange={props.onChange}
                      error={props.error}
                      helperText={props.helperText}
                      placeholder={tp('ticket-placeholder')}
                    />
                  );
                }}
              />
            </div>
            <Controller
              control={control}
              name={'description'}
              render={(renderProps) => {
                return <BaseDescriptionInput {...getFieldProps(renderProps)} />;
              }}
            />
          </NativeScroll>
          <Paper variant={'outlined'} sx={{ px: 1.6, pt: 0.8, pb: 1.6 }}>
            <Form isLoading={isLoading} />
          </Paper>
        </div>
      </FormProvider>
      <DialogActions
        sx={{
          pt: '2rem',
        }}
      >
        {id && (
          <ButtonRemove
            disabled={isLoading || !id}
            sx={{ marginRight: 'auto' }}
            onClick={onOpenDelete}
          />
        )}
        <ButtonCancel disabled={isLoading} onClick={onClose}>
          {tp('cancel')}
        </ButtonCancel>
        <ButtonCreate disabled={isLoading} onClick={handleSubmit(onSubmit)} color={'primary'}>
          {tp('save')}
        </ButtonCreate>
      </DialogActions>
      {isOpenDelete && (
        <DialogDelete
          onClose={onCloseDelete}
          onAgree={composeFunctions(onCloseDelete, onConfirmDelete)}
        >
          {tp('delete-item')}
        </DialogDelete>
      )}
    </Dialog>
  );
});
