import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiLabels, IGridLabel, ILabel, ServiceLabels } from 'services/labels';
import * as dynamic from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';
import { selectLabelsFilters, selectLabelsOrderBy, selectLabelsPagination } from './selectors';

export const actionLabelsGetDynamic = createAsyncThunk<
  { value: IGridLabel[]; count: number },
  { page: number; take?: number } | void,
  AppAsyncThunkConfig
>(`LABELS/getDynamic`, async (payload, { getState }) => {
  try {
    const { search, languageID, labelSourceID } = selectLabelsFilters(getState());
    const { take, skip } = selectLabelsPagination(getState());
    const { field, order } = selectLabelsOrderBy(getState());
    const params = {
      filter: dynamic.mergeFilters(
        dynamic.makeFilter(['title', 'labelKey'], search, dynamic.contains),
        dynamic.makeFilter(['languageID'], languageID, dynamic.decoratorIsNotNullable(dynamic.equals)),
        dynamic.makeFilter(
          ['labelSourceID'],
          labelSourceID,
            dynamic.decoratorIsNotNullable(dynamic.decoratorExclude(dynamic.equals, 'all')),
        ),
      ).join('&&'),
      select: dynamic.select('id', 'title', 'labelKey', 'isActive'),
      orderBy: dynamic.orderBy(field as any, order),
      count: true,
      take,
      skip,
    };
    const { data: result } = await ServiceLabels.getAllDynamic<IGridLabel, typeof params>(params);
    return result;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionLabelsChangeItem = createAsyncThunk<void, Partial<ILabel>, AppAsyncThunkConfig>(
  `LABELS/changeItem`,
  async (data, { dispatch }) => {
    try {
      const id = data.id;
      if (!id) {
        throw new Error('id is required');
      }
      await dispatch(apiLabels.endpoints.patchLabel.initiate({ ...data, id })).unwrap();
      await dispatch(actionLabelsGetDynamic()).unwrap();
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);
