import { AxiosResponse } from 'axios';
import { ServiceMediaUploads } from 'services/media-uploads';
import { equals, makeFilter } from 'utils/dynamic';
import { isFileLike, ValueFileUploaderFile } from 'utils/file-uploader';
import { DynamicParams, DynamicResult } from 'utils/service';

export const decoratorWithFiles = <
  T extends {
    getAllDynamic: (params?: Partial<DynamicParams>) => Promise<AxiosResponse<DynamicResult<M>>>;
  },
  M extends Record<string, any>,
>(
  mainField: keyof M,
  ...fileFields: (keyof M)[]
) => {
  return (target: T, propertyName: string, propertyDescriptor: PropertyDescriptor) => {
    const origin = propertyDescriptor.value;

    propertyDescriptor.value = async function (formData: M) {
      const service = this as T;
      let oldData = {} as M;

      const shouldLoadData =
        formData[mainField] &&
        Object.keys(formData).some((formDataKey) =>
          fileFields.some((fileField) => fileField === formDataKey),
        );

      if (shouldLoadData) {
        const {
          data: { value },
        } = await service.getAllDynamic({
          filter: makeFilter(String(mainField), formData[mainField], equals),
          select: [...fileFields].join(','),
          take: 1,
        });

        oldData = value[0] || {};
      }

      let entriesToUpload: [keyof M, ValueFileUploaderFile][] = [];
      let entriesToDelete: [keyof M, string][] = [];

      fileFields.forEach((key) => {
        if (isFileLike(formData[key])) {
          entriesToUpload.push([key, formData[key]]);
        }
        if (oldData[key] && typeof oldData[key] === 'string' && oldData[key] !== formData[key]) {
          entriesToDelete.push([key, oldData[key]]);
        }
      });

      const entriesDeleted = await Promise.all(
        entriesToDelete.map(async ([key, value]) => {
          await ServiceMediaUploads.remove({ filePath: value });
          return [key, ''];
        }),
      );
      const entriesUploaded = await Promise.all(
        entriesToUpload.map(async ([key, value]) => {
          const {
            data: { filePath },
          } = await ServiceMediaUploads.uploadFile(value);
          return [key, filePath];
        }),
      );
      const updatedData = [...entriesDeleted, ...entriesUploaded].reduce(
        (acc, [key, value]) => {
          // @ts-ignore
          acc[key] = value;
          return acc;
        },
        { ...formData },
      );

      return origin.call(this, updatedData);
    };
  };
};

export const decoratorRank = <
  M extends Record<string, any>,
  T extends {
    getAllDynamic: (
      params: Pick<DynamicParams, 'orderBy'>,
    ) => Promise<AxiosResponse<DynamicResult<M>>>;
  },
>(
  rankField: keyof M,
) => {
  return function (target: T, memberName: string, propertyDescriptor: PropertyDescriptor) {
    const origin = propertyDescriptor.value;

    propertyDescriptor.value = async function (formData: any) {
      const {
        data: { value },
        // @ts-ignore
      } = await this.getAllDynamic({
        take: 1,
        select: rankField,
        orderBy: `${String(rankField)} desc `,
      });
      const rankValue = value[0] || { [rankField]: 0 };
      return origin.call(this, { ...formData, [rankField]: rankValue[rankField] + 1 });
    };
  };
};
