import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'lodash-es';
import { apiTicketActionItemStatuses } from './api';

const selectSourceTicketActionItemStatusesResult =
  apiTicketActionItemStatuses.endpoints.getTicketActionItemStatusesSource.select();
export const selectSourceTicketActionItemStatusesAll = createSelector(
  selectSourceTicketActionItemStatusesResult,
  (res) => {
    return res.data || [];
  },
);
export const selectSourceTicketActionItemStatusesAllMap = createSelector(
  selectSourceTicketActionItemStatusesAll,
  (statuses) => {
    return keyBy(statuses, 'id');
  },
);
