import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  Autocomplete,
  AutocompleteProps,
  FormControlLabelProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { SelectListBox, SelectPopper } from 'components/app-select/components';
import { eachMinuteOfInterval, endOfDay, startOfDay } from 'date-fns';
import { useOpen } from 'hooks';
import React, { memo, useCallback, useState } from 'react';
import { IMask, useIMask } from 'react-imask';
import { DateValue, formatDate } from 'utils/dates';
import { composeFunctions } from 'utils/other';
import style from './index.module.scss';

const maskOptions = {
  mask: 'HH:MM',
  blocks: {
    HH: {
      mask: IMask.MaskedRange,
      placeholderChar: 'HH',
      from: 0,
      to: 23,
      maxLength: 2,
    },
    MM: {
      mask: IMask.MaskedRange,
      placeholderChar: 'MM',
      from: 0,
      to: 59,
      maxLength: 2,
    },
  },
};

export const TIME_DURATION_FORMAT = 'HH:mm';
export const dateToTimeDuration = (value: DateValue) => {
  if (!value) return '';
  return formatDate(value, TIME_DURATION_FORMAT);
};

export interface AppTimeDurationProps<
  T extends string,
  DisableClearable extends boolean | undefined = false,
> extends Omit<
    AutocompleteProps<T, false, DisableClearable, true>,
    'value' | 'options' | 'onChange' | 'renderInput'
  > {
  label?: FormControlLabelProps['label'];
  value?: any;
  onChange?: (v: any) => void;
  options?: T[];
  error?: boolean | undefined;
  helperText?: React.ReactNode;
  onComplete?: (value: string) => void;
  RenderInputProps?: Partial<TextFieldProps>;
}

const defTimeOptions = ((interval = 15) => {
  return eachMinuteOfInterval(
    {
      start: startOfDay(new Date()),
      end: endOfDay(new Date()),
    },
    { step: interval },
  ).map(dateToTimeDuration);
})();

const AppTimeDurationComponent = <
  T extends string,
  DisableClearable extends boolean | undefined = false,
>({
  label,
  value,
  onChange,
  onComplete,
  options = defTimeOptions as T[],
  error,
  helperText,
  RenderInputProps,
  ...rest
}: AppTimeDurationProps<T, DisableClearable>) => {
  const [inputValue, setInputValue] = useState('');
  const onClearInnerValue = useCallback(() => {
    setInputValue('');
  }, []);

  const { ref, setValue } = useIMask(maskOptions, {
    onAccept: (value) => {
      onChange && onChange(value);
      setInputValue(value);
    },
    onComplete: onComplete,
  });

  const { isOpen, onOpen, onClose } = useOpen();
  return (
    <Autocomplete
      freeSolo
      options={options}
      value={value}
      renderInput={(params) => (
        <TextField
          inputRef={ref}
          variant={'standard'}
          {...params}
          {...RenderInputProps}
          InputProps={{
            ...RenderInputProps?.InputProps,
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps?.endAdornment}
                {RenderInputProps?.InputProps?.endAdornment}
              </>
            ),
          }}
          inputProps={{
            ...RenderInputProps?.inputProps,
            ...params.inputProps,
          }}
          error={error}
          helperText={helperText}
          label={label}
        />
      )}
      filterOptions={(options) => {
        return options.filter((option) => String(option).includes(inputValue));
      }}
      popupIcon={<AccessTimeIcon />}
      onOpen={composeFunctions(onOpen, onClearInnerValue)}
      open={isOpen}
      onClose={composeFunctions(onClose, onClearInnerValue)}
      onChange={(_, value) => {
        setValue(String(value ?? ''));
      }}
      PopperComponent={SelectPopper}
      ListboxComponent={SelectListBox}
      classes={{ popper: style.popper, option: style.option }}
      {...rest}
    />
  );
};

export const AppTimeDuration = memo(AppTimeDurationComponent) as typeof AppTimeDurationComponent;
