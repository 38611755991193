import { Unset } from './types';

export const makeUniqueOrder = (orders: Array<{ id: string; rank: number }>) => {
  let set = new Set<number>();

  const makeUnique = (value: number): number => {
    if (set.has(value)) {
      return makeUnique(value + 1);
    } else {
      set.add(value);
      return value;
    }
  };

  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];
    const rank = order.rank;
    order.rank = makeUnique(rank);
  }

  return orders;
};

export type OrderMap = Record<string, number | undefined>;
export type OrderItemNormalized = { id: string; rank: number };

type Row = { id: string; rowIndex?: Unset<number> };

export const listToOrderList = (rows: Array<Row>, orderMap: OrderMap) => {
  const list = rows
    .map(({ id, rowIndex }) => {
      const rank = orderMap[id] || rowIndex || 0;
      return { id, rank };
    })
    .sort((a, b) => a.rank - b.rank);
  return makeUniqueOrder(list);
};
export const orderListToOrderMap = (orderList: OrderItemNormalized[]) => {
  return orderList.reduce((acc, item) => {
    acc[item.id] = item.rank;
    return acc;
  }, {} as Record<string, number>);
};
