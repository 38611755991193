import { useCallback, useState } from 'react';

type Status = 'error' | 'success' | 'default';

export const useCopyToClipboard = () => {
  const [error, setError] = useState<Error>();
  const [status, setStatus] = useState<Status>('default');

  const isSuccess = status === 'success';

  const onCopy = useCallback(async (text: string) => {
    setStatus('default');
    try {
      await navigator.clipboard.writeText(text);
      setStatus('success');
      return { success: true };
    } catch (e: any) {
      setStatus('error');
      setError(e);

      return { success: false };
    }
  }, []);

  return [onCopy, { error, status, isSuccess }] as const;
};
