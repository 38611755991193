import { Stack, Typography } from '@mui/material';
import { ButtonColorful } from 'components/button-colorful';
import { NativeCustomScroll } from 'components/native-custom-scroll';
import { useAppDispatch, useAppSelector, useTranslate } from 'hooks';
import React, { useCallback, useMemo } from 'react';
import { APP_NOTIFICATION_TYPE, getNotificationIcon } from 'services/app-notifications';
import {
  actionsNotificationsLayout,
  selectNotificationsLayoutFilters,
} from 'store/notifications-layout';
import variables from 'styles/config.scss';
import { enumToArray } from 'utils/types';
import style from './index.module.scss';

interface Props {
  isLoading: boolean;
}
export const Panel: React.FC<Props> = ({ isLoading }) => {
  const { tp, t } = useTranslate();
  const dispatch = useAppDispatch();

  const factoryClick = useCallback(
    (value: APP_NOTIFICATION_TYPE | null) => {
      return () => {
        dispatch(actionsNotificationsLayout.filtersSet({ type: value }));
      };
    },
    [dispatch],
  );

  const types = useMemo(() => {
    return enumToArray(APP_NOTIFICATION_TYPE).map(({ id, title }) => {
      return { id, title: t(id) };
    });
  }, [t]);
  const { type } = useAppSelector(selectNotificationsLayoutFilters);

  return (
    <div className={style.root}>
      <Typography
        fontWeight={500}
        component={'h3'}
        textTransform={'uppercase'}
        letterSpacing={1.25}
        color={variables.colorGrey}
        px={0.6}
      >
        {tp('categories')}
      </Typography>
      <NativeCustomScroll mode={'always'} sx={{ mx: -1 }}>
        <Stack spacing={0.4} p={1}>
          <ButtonColorful
            bColor={'#fff'}
            onClick={factoryClick(null)}
            disabled={isLoading}
            isActive={type === null}
          >
            {tp('all')}
          </ButtonColorful>
          {types.map(({ id, title }) => {
            const isActive = type === id;
            const Icon = getNotificationIcon(id);
            return (
              <ButtonColorful
                key={id}
                bColor={'#fff'}
                onClick={factoryClick(id)}
                disabled={isLoading}
                isActive={isActive}
              >
                <Icon color={isActive ? 'inherit' : 'primary'} sx={{ mr: 1 }} />
                {title}
              </ButtonColorful>
            );
          })}
        </Stack>
      </NativeCustomScroll>
    </div>
  );
};
