import { Box, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import { AppLoading } from 'components/app-loading';
import { ButtonCancel } from 'components/buttons';
import { DialogHeader } from 'components/dialog-header';
import { useTicketActionItemInformation } from 'components/ticket-action-item-containers';
import { BaseBreadcrumbs, BaseDialogTitle } from 'components/tickets';
import { useEffectNotifyError, useMap, useSourceCrmUser, useTranslate } from 'hooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { apiTicketActionItemComments } from 'services/ticket-action-item-comments';
import { convertToDate } from 'utils/dates';
import { handleEventStopAndPrevent } from 'utils/handlers';
import {
  TicketActionItemCommentContainer,
  TicketActionItemCommentNewContainer,
} from '../ticket-action-item-comments';
import style from './index.module.scss';

const useFetchQuery = apiTicketActionItemComments.useGetTicketActionItemCommentsByTicketActionQuery;

interface Props {
  ticketActionItemID: string;
  ticketActionItemCommentID?: string;
  onClose: () => void;
  onOpenAction: () => void;
}

export const TicketActionItemCommentsDialog: React.FC<Props> = ({
  ticketActionItemID,
  ticketActionItemCommentID,
  onClose,
  onOpenAction,
}) => {
  const { tp } = useTranslate();

  const resultInfo = useTicketActionItemInformation(ticketActionItemID);
  useEffectNotifyError(resultInfo.error);
  const { customer, ticket, ticketActionItem } = resultInfo;

  const sourceCrmUser = useSourceCrmUser();
  const mapUsers = useMap(sourceCrmUser.data, 'id');

  const breadcrumbs = useMemo(
    () => [
      ...(customer ? [{ label: customer.title }] : []),
      ...(ticket ? [{ label: ticket.title }] : []),
    ],
    [customer, ticket],
  );

  const resultFetch = useFetchQuery(ticketActionItemID);
  useEffectNotifyError(resultFetch.error);

  const rows = useMemo(() => {
    return (resultFetch.data || []).map((item) => {
      return {
        id: item.id,
        user: mapUsers[item.userCrmProfileID],
        createdDate: convertToDate(item.createdDate),
        updatedDate: convertToDate(item.updatedDate),
        description: item.description,
        itemKey: item.itemKey,
        images: item.images,
      };
    });
  }, [resultFetch.data, mapUsers]);

  const [highlightedID, setHighlightedID] = useState<string | null>(null);
  const refOnce = useRef(false);
  useEffect(() => {
    if (!ticketActionItemCommentID) return;
    if (refOnce.current) return;
    let item = rows.find((row) => row.id === ticketActionItemCommentID);
    if (!item) return;
    setHighlightedID(ticketActionItemCommentID);
    refOnce.current = true;
  }, [ticketActionItemCommentID, rows]);

  useEffect(() => {
    if (!highlightedID) return;
    let timerID = setTimeout(() => {
      setHighlightedID(null);
    }, 10_000);
    return () => {
      clearTimeout(timerID);
    };
  }, [highlightedID]);

  const isLoading = resultFetch.isLoading || sourceCrmUser.isLoading;

  const initComment = useMemo(() => {
    return {
      notificationUserCrmProfileIDs: [ticketActionItem?.assigneeUserCrmProfileID].filter(
        Boolean,
      ) as Array<string>,
    };
  }, [ticketActionItem]);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth={'md'}
      onDoubleClick={handleEventStopAndPrevent}
    >
      <DialogHeader title={tp('comments')} onClose={onClose} />
      <DialogContent
        sx={{
          paddingBottom: 0,
          minHeight: 130,
        }}
      >
        <BaseBreadcrumbs isLoading={resultInfo.isFetching} items={breadcrumbs} />
        <Box mt={1.5} mb={rows.length ? 2 : 0}>
          <BaseDialogTitle
            isLoading={resultInfo.isFetching}
            onClick={onOpenAction}
            className={style.title}
          >
            {ticketActionItem?.title}
          </BaseDialogTitle>
        </Box>

        <div className={style.content}>
          <Stack spacing={1.8} mb={1}>
            {rows.map((row) => (
              <TicketActionItemCommentContainer
                key={row.id}
                id={row.id}
                ticketActionItemID={ticketActionItemID}
                user={row.user}
                createdDate={row.createdDate}
                updatedDate={row.updatedDate}
                description={row.description}
                itemKey={row.itemKey}
                images={row.images}
                isHighlighted={highlightedID === row.id}
              />
            ))}
          </Stack>
          <div className={style.bottom}>
            <TicketActionItemCommentNewContainer
              ticketActionItemID={ticketActionItemID}
              disabled={isLoading}
              init={initComment}
            />
          </div>
          {isLoading && <AppLoading sx={{ transform: 'scale(0.7)' }} />}
        </div>
      </DialogContent>
      <DialogActions
        sx={{
          paddingTop: '2rem',
        }}
      >
        <ButtonCancel onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};
