import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_PROJECT_STATUSES, ProjectStatus } from './models';

export * from './models';

type Model = ProjectStatus;
const REVALIDATE_TAG = 'ProjectStatuses';

class Service extends DynamicService<Model> {}

export const ServiceProjectStatuses = new Service({
  getAll: API_PROJECT_STATUSES.GET_ALL_DYNAMIC,
  post: API_PROJECT_STATUSES.POST,
  patch: API_PROJECT_STATUSES.PATCH,
  delete: API_PROJECT_STATUSES.DELETE,
});

type SourceModel = { id: string; title: string };

export const apiProjectStatuses = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getProjectStatuses: build.query<SourceModel[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceProjectStatuses.getAllDynamic({
            select: dynamic.select('id', 'title'),
            orderBy: dynamic.orderBy('title', 'desc'),
            filter: dynamic.mergeFilters(dynamic.makeFilter('isActive', true, dynamic.equals)).join('&&'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getProjectStatus: build.query<Model, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServiceProjectStatuses.getDynamic(id);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    postProjectStatus: build.mutation<void, Model>({
      queryFn: async (data) => {
        try {
          await ServiceProjectStatuses.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchProjectStatus: build.mutation<void, PatchPartial<Model, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceProjectStatuses.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: REVALIDATE_TAG, id }],
    }),
    deleteProjectStatus: build.mutation<void, PatchPartial<Model, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceProjectStatuses.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_TAG },
        { type: REVALIDATE_TAG, id },
      ],
    }),
  }),
});
