import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_CUSTOMERS, ICustomer, IGridCustomers, IGridCustomersArgs } from './models';

const REVALIDATE_TAG = 'Customers' as const;

class Service extends DynamicService<ICustomer> {}

export const ServiceCustomers = new Service({
  getAll: API_CUSTOMERS.GET_ALL_DYNAMIC,
  post: API_CUSTOMERS.POST,
  patch: API_CUSTOMERS.PATCH,
  delete: API_CUSTOMERS.DELETE,
});

interface SourceModel {
  id: string;
  title: string;
  isActive: boolean;
  color: string;
}

export const apiCustomers = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getCustomers: build.query<SourceModel[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceCustomers.getAllDynamic<SourceModel>({
            select: dynamic.select('id', 'companyName as title', 'isActive', 'color'),
            orderBy: 'isActive desc, customerStatus.rank asc,companyName asc',
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, arg, meta) => [
        { type: REVALIDATE_TAG },
        ...(result || []).map(({ id }) => ({ type: REVALIDATE_TAG, id })),
      ],
    }),
    getCustomersWithExistingReportsOnly: build.query<SourceModel[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceCustomers.getAllDynamic<SourceModel>({
            select: dynamic.select('id', 'companyName as title', 'isActive', 'color'),
            filter: dynamic.makeFilter('reports.count()', 0, dynamic.more),
            orderBy: 'isActive desc, customerStatus.rank asc,companyName asc',
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, arg, meta) => [
        { type: REVALIDATE_TAG },
        ...(result || []).map(({ id }) => ({ type: REVALIDATE_TAG, id })),
      ],
    }),
    getCustomer: build.query<ICustomer, string>({
      queryFn: async (customerID) => {
        try {
          const { data } = await ServiceCustomers.getDynamic(customerID);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    getGridCustomers: build.query<
      DynamicResult<IGridCustomers, { count: true }>,
      IGridCustomersArgs
    >({
      query: ({ search, status, take, skip, orderBy }) => ({
        url: API_CUSTOMERS.GET_ALL_DYNAMIC,
        params: {
          filter: dynamic
            .mergeFilters(
              dynamic.makeFilter(['companyName', 'invoiceCompanyName'], search, dynamic.contains),
              dynamic.makeFilter(
                ['customerStatusID'],
                status,
                dynamic.decoratorExclude(dynamic.equals, 'all'),
              ),
            )
            .join('&&'),
          select: dynamic.select(
            'id',
            'customerStatusID',
            'companyName',
            'invoiceCompanyName',
            'contacts.count as totalContacts',
            'projects.count as totalProjects',
            'customerStatus.title as customerStatusTitle',
          ),
          orderBy: dynamic.orderBy(orderBy.field as any, orderBy.order),
          count: true,
          take,
          skip,
        },
      }),
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    postCustomer: build.mutation<void, Omit<ICustomer, 'id'>>({
      queryFn: async (customer) => {
        try {
          await ServiceCustomers.post(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchCustomer: build.mutation<void, PatchPartial<ICustomer, 'id'>>({
      queryFn: async (customer) => {
        try {
          await ServiceCustomers.patch(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: REVALIDATE_TAG, id }],
    }),
    deleteCustomer: build.mutation<void, PatchPartial<ICustomer, 'id'>>({
      queryFn: async (customer) => {
        try {
          await ServiceCustomers.delete(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_TAG },
        { type: REVALIDATE_TAG, id },
      ],
    }),
  }),
});
