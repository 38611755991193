import { FixServerObject } from 'utils/types';

const API = 'Accounts';

export const API_AUTH = {
  GENERATE_PASSWORD: `${API}/GeneratePassword`,
  LOGIN: `${API}/LoginWithCode`,
  LOGOUT: `${API}/Logout`,
  REFRESH_TOKEN: `${API}/RefreshToken`,
  GET_CURRENT_USER: `${API}/GetCurrentAppUser`,
  PATCH_CURRENT_USER: `${API}/PatchCurrentUser`,
};

export interface CurrentAppUser
  extends FixServerObject<
    Components.Schemas.CurrentUserProfileResponse,
    | 'appUserID'
    | 'userCrmProfilePermission'
    | 'userEmployeeProfileDepartmentID'
    | 'isActive'
    | 'email'
    | 'languageID'
    | 'name'
    | 'userPhoto'
    | 'isCoreTeamMember'
  > {}

export interface JWT {
  jwt: {
    token: string;
    refreshToken: string;
  };
  expires: string;
}
