import { dynamicFilterSwitcher } from 'utils/app-helpers';
import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService, transformResponseDynamic } from 'utils/service';
import {
  API_MONTHLY_ACTIVITY_REPORTS,
  IGridMonthlyActivityReport,
  IGridMonthlyActivityReportArgs,
  IMonthlyActivityReport,
  IMonthlyActivityReportDeleteInput,
  IMonthlyActivityReportPatchInput,
  IMonthlyActivityReportPostInput,
  schemaMonthlyActivityReport,
  SchemaMonthlyActivityReport,
} from './models';

export * from './models';

type Model = IMonthlyActivityReport;
const REVALIDATE_TAG = 'MonthlyActivityReports' as const;

class Service extends DynamicService<Model> {
  async performPost(data: IMonthlyActivityReportPostInput) {
    return super.post(data);
  }
  async performPatch(data: IMonthlyActivityReportPatchInput) {
    return super.patch(data);
  }
}

export const ServiceMonthlyActivityReports = new Service({
  getAll: API_MONTHLY_ACTIVITY_REPORTS.GET_ALL_DYNAMIC,
  post: API_MONTHLY_ACTIVITY_REPORTS.POST,
  patch: API_MONTHLY_ACTIVITY_REPORTS.PATCH,
  delete: API_MONTHLY_ACTIVITY_REPORTS.DELETE,
});

type IGridMonthlyActivityReportArgsFilter = Omit<
  IGridMonthlyActivityReportArgs,
  'take' | 'orderBy' | 'skip'
>;
const makeGridFilter = (arg: IGridMonthlyActivityReportArgsFilter) => {
  const { isSent, customerID, userCrmProfileID, activityType, projectName, year, month, search } =
    arg;
  return dynamic
    .mergeFilters(
      dynamic.makeFilter('isSent', isSent, dynamic.decoratorIsNotNullable(dynamicFilterSwitcher)),
      dynamic.makeFilter('customerID', customerID, dynamic.decoratorIsNotNullable(dynamic.equals)),
      dynamic.makeFilter(
        'activityType',
        activityType,
        dynamic.decoratorIsNotNullable(dynamic.contains),
      ),
      dynamic.makeFilter(
        'projectName',
        projectName,
        dynamic.decoratorIsNotNullable(dynamic.contains),
      ),
      dynamic.makeFilter(
        'userCrmProfileID',
        userCrmProfileID,
        dynamic.decoratorIsNotNullable(dynamic.equals),
      ),
      dynamic.makeFilter('activityYear', year, dynamic.decoratorIsNotNullable(dynamic.equals)),
      month
        ? dynamic.makeFilter(
            'activityMonth',
            Number(month),
            dynamic.decoratorIsNotNullable(dynamic.equals),
          )
        : undefined,
      dynamic.makeFilter(
        ['customer.companyName', 'projectName', 'activityType'],
        search,
        dynamic.decoratorIsNotNullable(dynamic.contains),
      ),
    )
    .join('&&');
};

export const apiMonthlyActivityReports = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridMonthlyActivityReports: build.query<
      DynamicResult<IGridMonthlyActivityReport, { count: true }>,
      IGridMonthlyActivityReportArgs
    >({
      query: (arg) => {
        const { orderBy, take, skip } = arg;
        return {
          url: API_MONTHLY_ACTIVITY_REPORTS.GET_ALL_DYNAMIC,
          params: {
            filter: makeGridFilter(arg),
            select: dynamic.select(
              'id',
              'date',
              'customer.companyName as customerName',
              'userCrmProfile.userPhoto as employeePhoto',
              'userCrmProfile.nameHeb as employeeName',
              'projectName',
              'activityType',
              'duration',
              'billableDuration',
              'shortDescription',
              'isSent',
            ),
            ...(take ? { take } : {}),
            ...(skip ? { skip } : {}),
            orderBy: dynamic.orderBy(orderBy),
            count: true,
          },
        };
      },
      providesTags: [{ type: REVALIDATE_TAG, id: 'grid' }],
    }),
    getGridMonthlyActivityReportsKPIs: build.query<
      Pick<IMonthlyActivityReport, 'duration' | 'billableDuration'>[],
      IGridMonthlyActivityReportArgsFilter
    >({
      query: (arg) => {
        return {
          url: API_MONTHLY_ACTIVITY_REPORTS.GET_ALL_DYNAMIC,
          params: {
            filter: makeGridFilter(arg),
            select: dynamic.select('duration', 'billableDuration'),
          },
        };
      },
      transformResponse: transformResponseDynamic,
      providesTags: [{ type: REVALIDATE_TAG, id: 'grid' }],
    }),
    getMonthlyActivityReport: build.query<SchemaMonthlyActivityReport, string>({
      queryFn: async (id) => {
        return ServiceMonthlyActivityReports.getDynamic<SchemaMonthlyActivityReport>(id, {
          select: ['id', Object.keys(schemaMonthlyActivityReport.cast({}))].join(','),
        });
      },
      providesTags: (result, error, id) => [
        { type: REVALIDATE_TAG, id },
        { type: REVALIDATE_TAG, id: 'grid' },
      ],
    }),
    postMonthlyActivityReport: build.mutation({
      queryFn: async (postData: IMonthlyActivityReportPostInput) => {
        return ServiceMonthlyActivityReports.performPost(postData);
      },
      invalidatesTags: [{ type: REVALIDATE_TAG, id: 'grid' }],
    }),
    patchMonthlyActivityReport: build.mutation({
      queryFn: async (patchData: IMonthlyActivityReportPatchInput) => {
        return ServiceMonthlyActivityReports.performPatch(patchData);
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_TAG, id: 'grid' },
        { type: REVALIDATE_TAG, id },
      ],
    }),
    deleteMonthlyActivityReport: build.mutation({
      queryFn: async (deleteData: IMonthlyActivityReportDeleteInput) => {
        return ServiceMonthlyActivityReports.delete(deleteData);
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_TAG, id: 'grid' },
        { type: REVALIDATE_TAG, id },
      ],
    }),
  }),
});
