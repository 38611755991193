import EditIcon from '@mui/icons-material/Edit';
import ReorderIcon from '@mui/icons-material/Reorder';
import { Box, DialogActions, DialogContent, IconButton, Stack } from '@mui/material';
import clsx from 'clsx';
import { ButtonAdd, ButtonCancel } from 'components/buttons';
import { DataGridEmpty } from 'components/data-grid';
import { DialogHeader } from 'components/dialog-header';
import { Loading } from 'components/loading';
import { useEffectNotifyError, useOpen, useTranslate } from 'hooks';
import { keyBy } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { apiDoneItems } from 'services/done-items';
import { apiReportDoneItems, IGetAllReportDoneItem } from 'services/report-done-items';
import { apiReports, IGetAllReport } from 'services/reports';
import variables from 'styles/config.scss';
import { getShortFullName } from 'utils/app-helpers';
import { AppSwitch } from '../../../../../app-switch';
import { useCheckDoneFetchItem } from '../../hooks/use-check-done-fetch-item';
import { AddToReportItemForm } from '../add-to-report-item-form';
import { InfoBox } from '../info-box';
import { ReportItem } from '../report-item';
import style from './index.module.scss';

const useFetchReportsQuery = apiReports.useGetAllReportsQuery;
const useFetchReportDoneItemsQuery = apiReportDoneItems.useGetReportDoneItemByTimeTrackingIDQuery;
const useFetchDoneItemsQuery = apiDoneItems.useGetDoneItemQuery;

interface Props {
  onClose: () => void;
  onAdd: () => void;
  duration?: string;
  userCrmProfileID?: string;
  timeTrackingID: string;
  activityReportDescription?: string | null;
  customerID?: string | null;
  date: string | null | undefined;
  ticketActionItemID: string | null | undefined;
}
export const ReportsListDialogContent: React.FC<Props> = ({
  onClose,
  onAdd,
  duration,
  userCrmProfileID,
  timeTrackingID,
  activityReportDescription,
  customerID,
  date,
  ticketActionItemID,
}) => {
  const { tp } = useTranslate();
  const checkDoneFetchItem = useCheckDoneFetchItem(ticketActionItemID);
  const stateOpenForm = useOpen();

  const resultReports = useFetchReportsQuery(
    { customerID: customerID || '' },
    { skip: !customerID },
  );
  useEffectNotifyError(resultReports.error);

  const [isAllReports, setIsAllReports] = useState(false);
  const reports = useMemo(() => resultReports.data || [], [resultReports.data]);
  const reportsInProgress = useMemo(() => {
    return reports.filter((report) => !report.bill && !report.lock);
  }, [reports]);

  const filteredReport = isAllReports ? reports : reportsInProgress;

  const resultReportDoneItems = useFetchReportDoneItemsQuery(timeTrackingID);
  useEffectNotifyError(resultReportDoneItems.error);
  const reportDoneItems = useMemo(
    () => resultReportDoneItems.data || [],
    [resultReportDoneItems.data],
  );

  const mapReportDoneItems = useMemo(() => {
    return keyBy(reportDoneItems, 'reportID');
  }, [reportDoneItems]);
  const getReportDoneItem = useCallback(
    (reportID: string): IGetAllReportDoneItem | null => {
      return mapReportDoneItems[reportID] || null;
    },
    [mapReportDoneItems],
  );

  const resultDoneItem = useFetchDoneItemsQuery(timeTrackingID || '', {
    skip: !timeTrackingID,
  });
  useEffectNotifyError(resultDoneItem.error);
  const doneItem = resultDoneItem.data;

  const [deleteItem, { error: errorDelete, isLoading: isLoadingDelete }] =
    apiReportDoneItems.useDeleteReportDoneItemMutation();
  useEffectNotifyError(errorDelete);

  const [postItem, { error: errorPost, isLoading: isLoadingPost }] =
    apiReportDoneItems.usePostReportDoneItemMutation();
  useEffectNotifyError(errorPost);

  const onDelete = useCallback(
    async (id: string | undefined) => {
      if (!id) return;
      await deleteItem({ id });
    },
    [deleteItem],
  );

  const [report, setReport] = useState<IGetAllReport | null>(null);

  const isNoData = resultReports.isSuccess && filteredReport.length === 0;

  const onClear = useCallback(() => {
    setReport(null);
  }, []);

  const isLoading =
    resultReports.isFetching ||
    resultReportDoneItems.isFetching ||
    isLoadingDelete ||
    resultDoneItem.isFetching ||
    isLoadingPost;

  const defaultValues = useMemo(() => {
    return {
      duration: doneItem?.duration || duration,
      billableDuration: doneItem?.billableDuration || duration,
      userCrmProfileID: doneItem?.userCrmProfileID || userCrmProfileID,
      description: doneItem?.description || activityReportDescription,
      date,
    };
  }, [duration, userCrmProfileID, activityReportDescription, doneItem, date]);

  const isLockDoneItem = useMemo(() => {
    return reportDoneItems.some((item) => item.reportLock && item.doneItemID === doneItem?.id);
  }, [reportDoneItems, doneItem]);

  const factoryCheck = useCallback(
    (report: IGetAllReport) => {
      return async (checked: boolean) => {
        const reportDoneItem = getReportDoneItem(report.id);
        if (!checked) {
          if (reportDoneItem) {
            await onDelete(reportDoneItem.id);
          }
        } else {
          if (!isLockDoneItem) {
            setReport(report);
          } else {
            if (doneItem) {
              await postItem({
                doneItemID: doneItem.id,
                reportID: report.id,
              });
            }
          }
        }

        checkDoneFetchItem();
      };
    },
    [getReportDoneItem, checkDoneFetchItem, doneItem, isLockDoneItem, onDelete, postItem],
  );

  //Start: Add to report => auto select the first record if possible
  const refOnce = useRef(0);
  useEffect(() => {
    if (refOnce.current > 0) return;
    if (!resultDoneItem.isSuccess) return;
    if (resultDoneItem.data) return;
    if (reportsInProgress.length !== 1) return;

    refOnce.current++;

    factoryCheck(reportsInProgress[0])(true);
  }, [resultDoneItem, reportsInProgress, factoryCheck]);
  //End: Add to report => auto select the first record if possible

  const onCloseDoneForm = useCallback(() => {
    onClear();
    stateOpenForm.onClose();
  }, [onClear, stateOpenForm]);

  const onCompleteDoneForm = useCallback(() => {
    onClear();
    stateOpenForm.onClose();

    if (filteredReport.length === 1) {
      onClose();
    }
  }, [onClear, stateOpenForm, onClose, filteredReport.length]);

  return (
    <>
      <DialogHeader
        title={
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <ReorderIcon />
            <div>{tp('add-to-report')}</div>
          </Stack>
        }
        onClose={onClose}
        className={clsx(report && style.none, stateOpenForm.isOpen && style.none)}
      />
      <DialogContent
        className={clsx(style.content, report && style.none, stateOpenForm.isOpen && style.none)}
      >
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={1.5}>
          <AppSwitch
            value={isAllReports}
            onChange={(_, v) => setIsAllReports(v)}
            label={tp('view-locked')}
          />
          <ButtonAdd
            sx={{
              flexShrink: 0,
            }}
            onClick={onAdd}
          >
            {tp('add-new-report')}
          </ButtonAdd>
        </Stack>
        {doneItem && !!reportDoneItems.length && (
          <div className={style.infoBox}>
            <Box display={'flex'} gap={2}>
              <InfoBox label={tp('duration')} value={doneItem.duration} />
              <InfoBox label={tp('billable-duration')} value={doneItem.billableDuration} />
              <InfoBox
                label={tp('user-crm-profile')}
                value={getShortFullName(doneItem.userCrmProfileFullName) || ''}
              />
            </Box>
            {!isLockDoneItem && (
              <IconButton
                size={'small'}
                sx={{ color: variables.colorGrey, flexShrink: 0, alignSelf: 'center' }}
                onClick={stateOpenForm.onOpen}
              >
                <EditIcon />
              </IconButton>
            )}
          </div>
        )}
        {!report && !isNoData && (
          <div>
            {filteredReport.map((report) => {
              const currentElement = getReportDoneItem(report.id);

              return (
                <ReportItem
                  key={report.id}
                  className={style.report}
                  checked={!!currentElement}
                  fromDate={report.fromDate}
                  toDate={report.toDate}
                  reportName={report.reportName}
                  lock={report.lock}
                  bill={report.bill}
                  onCheck={factoryCheck(report)}
                />
              );
            })}
          </div>
        )}
        {isNoData && <DataGridEmpty />}
        {isLoading && <Loading />}
      </DialogContent>
      <DialogActions
        className={clsx(style.actions, report && style.none, stateOpenForm.isOpen && style.none)}
      >
        <ButtonCancel onClick={onClose} color={'primary'}>
          {tp('close')}
        </ButtonCancel>
      </DialogActions>
      {!resultDoneItem.isFetching && (report || stateOpenForm.isOpen) && (
        <AddToReportItemForm
          report={report}
          onClose={onCloseDoneForm}
          onDone={onCompleteDoneForm}
          defaultValues={defaultValues}
          itemID={doneItem?.id}
          timeTrackingID={timeTrackingID}
          ticketActionItemID={ticketActionItemID}
        />
      )}
    </>
  );
};
