import { Stack } from '@mui/material';
import { withDefaultProps } from 'hoc/with-default-props';
import variables from 'styles/config.scss';

export const BaseSlot = withDefaultProps(Stack)({
  width: 96,
  height: 72,
  border: `1px solid ${variables.colorGreyLight}`,
  borderRadius: 1,
});
