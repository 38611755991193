import CheckIcon from '@mui/icons-material/Check';
import React from 'react';

import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';
import style from './index.module.scss';

interface Props extends BoxProps {
  value: boolean | undefined | null;
  IconChecked?: React.ReactNode;
  IconUnchecked?: React.ReactNode;
}

export const TemplateBoolean: React.FC<Props> = ({
  value,
  className,
  IconChecked = <CheckIcon color={'success'} />,
  IconUnchecked = <CheckIcon color={'disabled'} />,
  ...rest
}) => {
  return (
    <Box {...rest} className={clsx(style.root, className)}>
      {value ? IconChecked : IconUnchecked}
    </Box>
  );
};
