import { Loading } from 'components/loading';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React, { memo, useEffect } from 'react';
import { apiLabels } from 'services/labels';
import { apiLanguages } from 'services/languages';
import { actionAccountSetLanguageID, selectAccountLanguageID } from 'store/auth';

interface Props {
  children: React.ReactNode;
}

export const TranslateProvider = memo<Props>(({ children }) => {
  const dispatch = useAppDispatch();
  const languageID = useAppSelector(selectAccountLanguageID);
  const { data: languages = [], isSuccess: isSuccessLanguages } =
    apiLanguages.useGetAllLanguagesQuery();
  const { isSuccess: isSuccessLabels } = apiLabels.useGetAppLabelsQuery(languageID || '', {
    skip: !languageID,
  });

  useEffect(() => {
    if (!languageID && languages.length > 0) {
      dispatch(actionAccountSetLanguageID(languages[0].id));
    }
  }, [languageID, languages, dispatch]);

  const isReady = isSuccessLanguages || isSuccessLabels;

  return (
    <>
      {isReady && children}
      {!isReady && <Loading />}
    </>
  );
});
