import EventIcon from '@mui/icons-material/Event';
import { Button, ButtonBase, Chip, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { TemplateDate, TemplateUserName } from 'components/data-grid-templates';
import { DialogNotificationsView } from 'components/notifications';
import { useAppSelector, useOpen, useTranslate } from 'hooks';
import React from 'react';
import {
  AppNotificationLatest,
  getNotificationActionType,
  getNotificationIcon,
  isNotificationView,
} from 'services/app-notifications';
import { selectSourceCrmUsersAllMap } from 'services/user-crm-profiles';
import { Unset } from 'utils/types';
import style from './index.module.scss';

interface Props {
  row: AppNotificationLatest;
  userID: Unset;
}
export const NotificationCard: React.FC<Props> = ({ row, userID }) => {
  const { tp } = useTranslate();

  const type = getNotificationActionType(row.notification);
  const Icon = getNotificationIcon(type);

  const mapUsers = useAppSelector(selectSourceCrmUsersAllMap);

  const user = userID ? mapUsers[userID] : null;
  const isDisabled = row.done;

  const isViewable = isNotificationView(type);
  const stateView = useOpen();

  return (
    <>
      <ButtonBase
        component={'div'}
        disableRipple={!isViewable}
        disabled={!isViewable}
        onClick={isViewable ? stateView.onOpen : undefined}
        className={clsx({
          [style.root]: true,
          [style.rootDisabled]: isDisabled,
          [style.rootClickable]: isViewable,
        })}
      >
        <div className={style.header}>
          <Stack direction={'row'} alignItems={'center'} spacing={0.8}>
            <Stack direction={'row'} spacing={0.8} flexGrow={1} alignItems={'center'}>
              <div className={style.type}>
                <Icon
                  color={'inherit'}
                  fontSize={'inherit'}
                  sx={{ fontSize: 20 }}
                  className={style.icon}
                />
                <Typography fontSize={12} fontWeight={600}>
                  {type}
                </Typography>
              </div>
              <TemplateUserName
                avatarHeight={24}
                avatarWidth={24}
                userPhoto={user?.userPhoto}
                fullName={user?.fullName}
                TypographyProps={{
                  fontWeight: 'bold',
                }}
              />
              {row.urgent && (
                <Chip label={tp('urgent')} size={'small'} color={'error'} className={style.chip} />
              )}
            </Stack>
            <Stack direction={'row'} spacing={0.4} alignItems={'center'}>
              <EventIcon className={style.iconDate} />
              <TemplateDate
                value={row.notification.entryDate}
                format={'dd.MM.yyyy, HH:mm'}
                sx={{
                  fontSize: 12,
                }}
              />
            </Stack>
          </Stack>
        </div>
        <div className={style.footer}>
          <Typography component={'div'} className={style.description}>
            {row.notification.message}
          </Typography>
          {isViewable && <Button className={style.button}>{tp('view-more')}</Button>}
        </div>
      </ButtonBase>
      {isViewable && stateView.isOpen && (
        <DialogNotificationsView row={row} onClose={stateView.onClose} />
      )}
    </>
  );
};
