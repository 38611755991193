import React from 'react';
import { GridTimeTrackingForTicketActionItems } from 'services/time-trackings';
import { Unset } from 'utils/types';

export type GridModel = GridTimeTrackingForTicketActionItems;
export type GridModelExtended = GridModel & { __isLocked: boolean; __isInReport: boolean };
export type GridFilters = { showMyReports: boolean };
export const QUERY_NAME = 'track-time-for-action-item';
export const QUERY_NAME_ADD_TO_REPORT_POPUP = 'add-to-report-popup';

export interface ActionComponentProps {
  ticketActionItemID: string | undefined;
  selectedRows: GridModelExtended[];
  onClose: () => void;
  meta: Meta;
}

export enum ACTIONS {
  ADD_TO_REPORT = 'add-to-report',
  UPDATE_REPORT_DESCRIPTION = 'update-report-description',
  DELETE = 'delete',
}

export type ActionComponent = React.FunctionComponent<ActionComponentProps>;
export type Meta = { displayDescription: boolean; activityReportDescription: Unset };
