import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../index';

const selectState = (state: AppState) => state.reports;

export const selectReportsFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});

export const selectReportsExpanded = createSelector(selectState, ({ isExpanded }) => {
  return isExpanded;
});
