import React from 'react';

import { Box, Grid } from '@mui/material';
import { AppSwitch } from 'components/app-switch';
import { ButtonAdd } from 'components/buttons';
import { useFieldProps, useFilterForm, useTranslate } from 'hooks';
import { Controller } from 'react-hook-form';
import { composeFunctions } from 'utils/other';
import { useRtkReducer } from '../../hooks/use-rtk-reducer';

interface Props {
  isLoading: boolean;
  onNew: () => void;
}
export const Filters: React.FC<Props> = ({ isLoading, onNew }) => {
  const { tp } = useTranslate();
  const {
    state: { filters: defaultValues },
    filtersSet: onChange,
  } = useRtkReducer();
  const { control, onChangeItem } = useFilterForm({ defaultValues, onUpdate: onChange });
  const getFieldProps = useFieldProps();

  return (
    <Grid container rowSpacing={1} columnSpacing={2} alignItems={'center'} mb={1.2}>
      <Grid item xs={12} md={true}>
        <Box ml={-1.6}>
          <Controller
            control={control}
            name={'showMyReports'}
            render={(renderProps) => {
              const props = getFieldProps(renderProps);
              return (
                <AppSwitch
                  {...props}
                  label={tp('show-my-time-tracking')}
                  onChange={composeFunctions(props.onChange, onChangeItem)}
                  disabled={isLoading}
                />
              );
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={'auto'}>
        <ButtonAdd onClick={onNew} variant={'text'}>
          {tp('track-time')}
        </ButtonAdd>
      </Grid>
    </Grid>
  );
};
