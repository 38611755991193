import { createAsyncThunk } from '@reduxjs/toolkit';
import { IBoardNote, ServiceNotes } from 'services/notes';
import { parseErrorData } from 'utils/service';
import { selectAppUserID } from '../auth';
import { AppAsyncThunkConfig } from '../index';
import { PREFIX } from './helpers';

export const actionNotesFetchAll = createAsyncThunk<IBoardNote[], void, AppAsyncThunkConfig>(
  `${PREFIX}/actionNotesFetchAll`,
  async (_, { getState }) => {
    try {
      const appUserID = selectAppUserID(getState());
      const { data } = await ServiceNotes.getNotesBoard(appUserID);

      return data;
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);
