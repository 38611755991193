import { compareAsc, compareDesc } from 'date-fns';
import { convertToDate, DateValue } from './dates';
import { DynamicOrder } from './dynamic';
import { Unset } from './types';

const compareStringsDesc = (a: Unset, b: Unset) => {
  return String(a).toLowerCase() > String(b).toLowerCase() ? -1 : 1;
};
const compareStringsAsc = (a: Unset, b: Unset) => {
  return compareStringsDesc(b, a);
};

export const compareStrings = (a: Unset, b: Unset, order: DynamicOrder['order']) => {
  if (order === 'desc') {
    return compareStringsDesc(a, b);
  }

  if (order === 'asc') {
    return compareStringsAsc(a, b);
  }

  return 0;
};

export const compareDates = (
  a: Unset<DateValue>,
  b: Unset<DateValue>,
  order: DynamicOrder['order'],
) => {
  const dateA = convertToDate(a);
  const dateB = convertToDate(b);

  if (order === 'desc') {
    return compareDesc(dateA, dateB);
  }

  if (order === 'asc') {
    return compareAsc(dateA, dateB);
  }

  return 0;
};

export const compareNumbers = (
  a: Unset<number>,
  b: Unset<number>,
  order: DynamicOrder['order'],
) => {
  if (order === 'desc') {
    return Number(b) - Number(a);
  }

  if (order === 'asc') {
    return Number(a) - Number(b);
  }

  return 0;
};
