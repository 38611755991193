import EventIcon from '@mui/icons-material/Event';
import { Stack, StackProps, Typography } from '@mui/material';
import { FORMAT_TIME, TIME_FORMAT_DATE, TIME_FORMAT_DATE_TIME } from 'configs/const';
import { format, isSameDay, set } from 'date-fns';
import React, { useMemo } from 'react';
import { convertToDate, DateValue } from 'utils/dates';

interface Props extends StackProps {
  startDate: DateValue;
  endDate: DateValue;
}
export const BaseDatesPreview: React.FC<Props> = ({ startDate, endDate, ...rest }) => {
  const value = useMemo(() => {
    const start = convertToDate(startDate);
    const end = convertToDate(endDate);

    const isTheSameDay = isSameDay(start, end);

    if (!isTheSameDay)
      return [format(start, TIME_FORMAT_DATE), format(end, TIME_FORMAT_DATE)].join(' - '); // 05/01/2024 - 10/01/2024

    const isTheSameTime =
      set(start, { seconds: 0, milliseconds: 0 }).getTime() ===
      set(end, { seconds: 0, milliseconds: 0 }).getTime();

    if (isTheSameTime) {
      return format(start, TIME_FORMAT_DATE_TIME); //05/01/2024 12:30
    }

    return [
      format(start, TIME_FORMAT_DATE),
      [format(start, FORMAT_TIME), format(end, FORMAT_TIME)].join(' - '),
    ].join(' '); // // 05/01/2024 12:30 - 14:30
  }, [startDate, endDate]);

  return (
    <Stack direction={'row'} spacing={0.4} alignItems={'center'} {...rest}>
      <EventIcon fontSize={'small'} color={'silver'} />
      <Typography
        title={value}
        variant={'caption'}
        component={'div'}
        lineHeight={1}
        alignSelf={'center'}
        pt={0.1}
      >
        {value}
      </Typography>
    </Stack>
  );
};
