import { useNotify } from 'hooks/use-notify';
import { useTranslate } from 'hooks/use-translate';
import { useEffect } from 'react';

import { ChangeEvent, HTMLProps, useCallback, useMemo, useRef, useState } from 'react';
import {
  ErrorFile,
  ErrorFileCode,
  fileToBase64,
  getFileAccept,
  validateFileSize,
  validateFileType,
  ValueFileUploader,
  ValueFileUploaderFile,
} from 'utils/file-helpers';
import { parseErrorData } from '../utils/service';

interface ResultSuccess extends ValueFileUploaderFile {
  file: File;
}
const isResultSuccess = (res: any): res is ResultSuccess => {
  return Boolean(!res.error);
};

interface ResultError {
  error: ErrorFile;
  file: File;
}
const isResultError = (res: any): res is ResultError => {
  return Boolean(res.error);
};

export type FileAccept =
  | '.png'
  | '.jpg'
  | '.jpeg'
  | '.doc'
  | '.docx'
  | '.svg'
  | '.xml'
  | '.pdf'
  | '.webp';
export type SkipValidationsContentType = 'image/svg+xml';
export interface UseFileUploaderProps<Multiple extends boolean = false> {
  accept?: FileAccept[];
  skipValidationsContentTypes?: SkipValidationsContentType[];
  maxSize?: number;
  convertFileToBase64?: (file: Blob) => Promise<string>;
  disabled?: boolean | undefined;
  multiple?: Multiple;
  onChange?: Multiple extends true
    ? (v: ValueFileUploaderFile[], files: File[]) => void
    : (v: ValueFileUploaderFile | null, files: File | null) => void;
}
export const useFileUploader = <M extends boolean = false>(props: UseFileUploaderProps<M>) => {
  const {
    accept,
    maxSize = 2,
    convertFileToBase64 = fileToBase64,
    disabled,
    skipValidationsContentTypes,
  } = props;
  const ref = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<ResultError[]>([]);

  const { inputType, contentTypes } = useMemo(() => getFileAccept(accept), [accept]);

  const onChangeWrap = useCallback(
    (v: ValueFileUploader[], files: File[]) => {
      if (!props.onChange) return;
      if (v.length === 0) return;

      if (props.multiple === true) {
        // @ts-ignore
        props.onChange(v, files);
      } else {
        // @ts-ignore
        props.onChange(v[0] || null, files[0] || null);
      }
    },
    [props],
  );

  const onAddFiles = useCallback(
    async (files: File[]) => {
      const result = await Promise.all(
        files.map(async (file) => {
          try {
            await validateFileType({
              file,
              contentTypes,
              skipContentTypes: skipValidationsContentTypes || [],
            });
            await validateFileSize(file, maxSize);

            const value = await convertFileToBase64(file);

            return {
              name: file.name,
              size: file.size,
              type: file.type,
              value,
              file,
            } as ResultSuccess;
          } catch (error) {
            return { error: error as ErrorFile, file } as ResultError;
          }
        }),
      );
      const successResult = result.filter(isResultSuccess);

      onChangeWrap(
        successResult,
        successResult.map(({ file }) => file),
      );

      const errorResult = result.filter(isResultError);

      setErrors(errorResult);
    },
    [contentTypes, maxSize, convertFileToBase64, skipValidationsContentTypes, onChangeWrap],
  );

  const onChangeInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setErrors([]);
      const { files } = e.target;

      if (!files) {
        return;
      }

      onAddFiles(Array.from(files));
    },
    [onAddFiles],
  );

  const inputProps = useMemo<HTMLProps<HTMLInputElement>>(() => {
    return {
      ref,
      type: 'file',
      hidden: true,
      onChange: onChangeInput,
      accept: inputType,
      multiple: props.multiple,
      disabled,
      onClick: () => {
        if (ref.current) {
          ref.current.value = '';
        }
      },
    };
  }, [inputType, onChangeInput, props.multiple, disabled]);

  const onOpen = useCallback(() => {
    ref.current?.click();
  }, []);
  const onRemove = useCallback(() => {
    if (ref.current) {
      ref.current.value = '';
      onChangeWrap([], []);
    }
  }, [onChangeWrap]);

  return { inputProps, onOpen, onRemove, errors, onAddFiles };
};

export const useFileUploaderParseError = (option: { maxSize?: number; accept?: string[] }) => {
  const { maxSize, accept: _accept } = option;
  const accept = String(_accept);
  const { tp } = useTranslate();

  return useCallback(
    (error: ErrorFile) => {
      let _error: Error = error;
      if (error?.code === ErrorFileCode.MAX_SIZE) {
        _error = new Error(tp('file-error-size', { maxSize: maxSize || 0 }));
      }
      if (error?.code === ErrorFileCode.FILE_TYPE) {
        _error = new Error(tp('file-error-type', { accept }));
      }
      return parseErrorData(_error).message;
    },
    [tp, accept, maxSize],
  );
};
export const useFileUploaderNotifyErrors = (
  errors: { error: ErrorFile }[],
  option: { maxSize: number; accept?: string[] },
) => {
  const { maxSize, accept: _accept } = option;
  const accept = String(_accept);
  const { tp } = useTranslate();
  const { onError } = useNotify();

  useEffect(() => {
    const showError = (data: { error: ErrorFile }) => {
      const { error } = data;
      if (error?.code === ErrorFileCode.MAX_SIZE) {
        onError(new Error(tp('file-error-size', { maxSize })));
      } else if (error?.code === ErrorFileCode.FILE_TYPE) {
        onError(new Error(tp('file-error-type', { accept })));
      } else if (error) {
        onError(error);
      }
    };

    errors.forEach(showError);
  }, [errors, tp, accept, maxSize, onError]);
};
