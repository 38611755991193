import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { startOfMonth } from 'date-fns';
import defaultImage from 'images/login-bacground.jpg';
import { IDashboardUser } from 'services/dashboard-reports/models';

export interface IProjectHoursFilters {
  isChangedCustomDate: boolean;
  userID: string;
  period: string[];
  usersOptions: {
    [key: string]: {
      users: IDashboardUser[];
      options: {
        id: string;
        title: string;
        photo: string;
      }[];
    };
  };
}

export interface IMonthWorkFilters {
  userID: string;
}
export interface IUserHoursFilters {
  period: string[];
}

interface State {
  userHoursFilters: IUserHoursFilters;
  projectHoursFilters: IProjectHoursFilters;
  monthWorkFilters: IMonthWorkFilters;
  interactionsProjectsLength: number;
  projectHoursHeight: number;
}

export enum HOURS_PROJECT_TABS {
  CURRENT_MONTH = 'current-month',
  CURRENT_YEAR = 'current-year',
  CUSTOM_DATE = 'custom-date',
}

export const DEFAULT_DASHBOARD_REPORTS_OPTION = 'all-team';
export const DEFAULT_DASHBOARD_REPORTS_OPTION_PHOTO = defaultImage;
export const PROJECT_HOURS_DEFAULT_HEIGHT = 390;

const initState = (): State => {
  return {
    projectHoursHeight: PROJECT_HOURS_DEFAULT_HEIGHT,
    userHoursFilters: {
      period: [startOfMonth(new Date()).toISOString(), new Date().toISOString()],
    },
    interactionsProjectsLength: 0,
    projectHoursFilters: {
      isChangedCustomDate: false,
      userID: DEFAULT_DASHBOARD_REPORTS_OPTION,
      period: [startOfMonth(new Date()).toISOString(), new Date().toISOString()],
      usersOptions: {
        [HOURS_PROJECT_TABS.CURRENT_MONTH]: {
          users: [],
          options: [
            {
              id: DEFAULT_DASHBOARD_REPORTS_OPTION,
              title: DEFAULT_DASHBOARD_REPORTS_OPTION,
              photo: DEFAULT_DASHBOARD_REPORTS_OPTION_PHOTO,
            },
          ],
        },
        [HOURS_PROJECT_TABS.CURRENT_YEAR]: {
          users: [],
          options: [
            {
              id: DEFAULT_DASHBOARD_REPORTS_OPTION,
              title: DEFAULT_DASHBOARD_REPORTS_OPTION,
              photo: DEFAULT_DASHBOARD_REPORTS_OPTION_PHOTO,
            },
          ],
        },
        [HOURS_PROJECT_TABS.CUSTOM_DATE]: {
          users: [],
          options: [
            {
              id: DEFAULT_DASHBOARD_REPORTS_OPTION,
              title: DEFAULT_DASHBOARD_REPORTS_OPTION,
              photo: DEFAULT_DASHBOARD_REPORTS_OPTION_PHOTO,
            },
          ],
        },
      },
    },
    monthWorkFilters: {
      userID: '',
    },
  };
};

const slice = createSlice({
  name: 'DASHBOARD_REPORTS',
  initialState: initState(),
  reducers: {
    actionProjectHoursFiltersSet(state, action: PayloadAction<IProjectHoursFilters>) {
      state.projectHoursFilters = { ...state.projectHoursFilters, ...action.payload };
    },
    actionUserHoursFiltersSet(state, action: PayloadAction<IUserHoursFilters>) {
      state.userHoursFilters = { ...state.userHoursFilters, ...action.payload };
    },
    actionMonthWorkFiltersSet(state, action: PayloadAction<IMonthWorkFilters>) {
      state.monthWorkFilters = { ...state.monthWorkFilters, ...action.payload };
    },
    actionInteractionsProjectsLengthSet(state, action: PayloadAction<number>) {
      state.interactionsProjectsLength = action.payload;
    },
    actionProjectHoursHeightSet(state, action: PayloadAction<number>) {
      state.projectHoursHeight = action.payload;
    },
  },
});

export const {
  actionProjectHoursFiltersSet,
  actionUserHoursFiltersSet,
  actionMonthWorkFiltersSet,
  actionInteractionsProjectsLengthSet,
  actionProjectHoursHeightSet,
} = slice.actions;
export const reducerDashboardReports = slice.reducer;
