import { useValueQuery } from 'hooks/use-value-query';
import { useCallback } from 'react';
import { QUERY_NAME_EDIT } from './helpers';

export const useCheckDoneEditTicket = () => {
  const { value, onChange } = useValueQuery({ name: QUERY_NAME_EDIT });

  const onEdit = useCallback(
    (options: { id: string; key: string }) => {
      onChange(options.key);
    },
    [onChange],
  );

  const onClear = useCallback(() => {
    onChange(null);
  }, [onChange]);

  return { value, onEdit, onClear };
};
