import { TIME_FORMAT_DATE } from 'configs/const';
import {
  differenceInDays, endOfMonth, endOfWeek,
  format as _formatDate,
  isValid,
  isWithinInterval,
  max,
  min,
  parse, startOfMonth, startOfWeek,
  toDate as fnsToDate,
} from 'date-fns';
import { FormatOptions } from 'date-fns/format';
import { Unset } from './types';

export type DateValue = Date | string | number | null | undefined;

export const isDateLike = (value: any): value is string | number | Date => {
  if (!value) return false;
  if (typeof value === 'string') {
    return isValid(new Date(value));
  }
  return isValid(fnsToDate(value));
};

export const convertToDate = (value: DateValue) => {
  if (!value) {
    return new Date();
  }
  if (typeof value === 'string') {
    return new Date(value);
  }
  return fnsToDate(value);
};

export const formatDate = (
  date: DateValue,
  format: string = TIME_FORMAT_DATE,
  options?: FormatOptions,
) => {
  return _formatDate(convertToDate(date), format, options);
};

export const minDate = (...args: DateValue[]) => {
  const values = args.filter(isDateLike).map(convertToDate);
  return min(values);
};
export const maxDate = (...args: DateValue[]) => {
  const values = args.filter(isDateLike).map(convertToDate);
  return max(values);
};

export const isBirthdayWithinOneWeek = (birthdayDate: Unset) => {
  const formattedBirthDate = formatDate(convertToDate(birthdayDate), TIME_FORMAT_DATE);
  const currentDate = new Date();
  const birthday = parse(formattedBirthDate, TIME_FORMAT_DATE, new Date());
  const thisYearBirthday = new Date(
    currentDate.getFullYear(),
    birthday.getMonth(),
    birthday.getDate(),
  );
  const nextYearBirthday = new Date(
    currentDate.getFullYear() + 1,
    birthday.getMonth(),
    birthday.getDate(),
  );

  const daysDiffThisYear = differenceInDays(thisYearBirthday, currentDate);
  const daysDiffNextYear = differenceInDays(currentDate, nextYearBirthday);

  return (
    (daysDiffThisYear <= 7 && daysDiffThisYear >= 0) ||
    (daysDiffNextYear <= 7 && daysDiffNextYear >= 0)
  );
};

export const multiplyTimeDuration = (time: string | null | undefined, multiplier: number) => {
  if (!time) return '00:00';
  // Parse the time string into hours and minutes
  const [hours, minutes] = time.split(':').map(Number);

  // Convert time to total minutes and multiply it
  let totalMinutes = (hours * 60 + minutes) * multiplier;

  // Normalize hours and minutes
  const adjustedHours = Math.floor(totalMinutes / 60);
  const adjustedMinutes = Math.round(totalMinutes % 60);

  // Format the adjusted time

  return [adjustedHours, adjustedMinutes].map((item) => item.toString().padStart(2, '0')).join(':');
};

export enum ROUND_TIME_DIRECTION {
  TOP = 'to-top',
  BOTTOM = 'to-bottom',
}
export const roundTimeDuration = (
  time: string | null | undefined,
  step: number,
  direction: ROUND_TIME_DIRECTION,
) => {
  if (!time) return '00:00';
  // Parse the time string into hours and minutes
  const [hours, minutes] = time.split(':').map(Number);

  // Convert time to total minutes
  let totalMinutes = hours * 60 + minutes;

  // Adjust the time based on the direction
  if (direction === ROUND_TIME_DIRECTION.TOP) {
    // Round up to the nearest step
    totalMinutes = Math.ceil(totalMinutes / step) * step;
  } else if (direction === ROUND_TIME_DIRECTION.BOTTOM) {
    // Round down to the nearest step
    totalMinutes = Math.floor(totalMinutes / step) * step;
  }

  // Normalize hours and minutes
  const adjustedHours = Math.floor(totalMinutes / 60);
  const adjustedMinutes = totalMinutes % 60;

  // Format the adjusted time
  return `${adjustedHours.toString().padStart(2, '0')}:${adjustedMinutes
    .toString()
    .padStart(2, '0')}`;
};

export const rangeToInterval = (range: DateValue[]) => {
  const start = minDate(...range);
  const end = maxDate(...range);
  return { start, end };
};
export const hoursToMinutes = (hours: number) => {
  return Math.round(hours * 60);
};

export const isAcrossIntervals = (range1: DateValue[], range2: DateValue[]) => {
  const AB = range1.map((v) => convertToDate(v).getTime());
  const CD = range2.map((v) => convertToDate(v).getTime());

  return [
    isWithinInterval(CD[0], rangeToInterval(AB)),
    isWithinInterval(CD[1], rangeToInterval(AB)),
    isWithinInterval(AB[0], rangeToInterval(CD)),
    isWithinInterval(AB[1], rangeToInterval(CD)),
  ].some(Boolean);
};

export const getCalendarMonthBounds = (date: Date) => {
  return [startOfWeek(startOfMonth(date)), endOfWeek(endOfMonth(date))];
}
