// noinspection SpellCheckingInspection

import { DraggableCalcInitPosition } from 'components/draggable-modal';
import { makePopupVisible } from 'utils/elements';
import { Unset } from './types';

export const durationToMinutes = (duration: string | null | undefined) => {
  if (!duration) return 0;
  const [hours = '0', minutes = '0'] = duration.split(':');

  return Number(hours) * 60 + Number(minutes);
};
export const durationToHours = (duration: string | null | undefined) => {
  const minutes = durationToMinutes(duration);

  return parseFloat((minutes / 60).toFixed(2));
};

export const minutesToDuration = (minutes: number) => {
  const hours = minutes / 60;
  return hoursToDuration(hours);
};

export const minutesToHours = (minutes: number) => {
  return parseFloat((minutes / 60).toFixed(2));
};

export const hoursToDuration = (hours: number) => {
  const totalMinutes = Math.round(hours * 60);
  const hoursString = Math.floor(totalMinutes / 60).toString();
  const minutesString = (totalMinutes % 60).toString();

  return [hoursString, minutesString].map((v) => v.padStart(2, '0')).join(':');
};

// noinspection SpellCheckingInspection

export const convertToHoursAndMinutes = (totalMinutes: number) => {
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

export const calculateAxisValue = (maxDuration: number) => {
  if (!maxDuration) {
    return [0];
  }
  const start = 0;
  const end = maxDuration;
  const step = maxDuration / 5;
  const arrayLength = Math.floor((end - start) / step) + 1;
  return [...Array(arrayLength).keys()].map((x) => {
    const rounder = maxDuration > 100 ? Math.pow(10, maxDuration.toString().length - 2) : 1;
    return (Math.round((x * step + start) / rounder) * rounder).toLocaleString();
  });
};

export const monthsOptions = [
  {
    title: 'January',
    id: 1,
  },
  {
    title: 'February',
    id: 2,
  },
  {
    title: 'March',
    id: 3,
  },
  {
    title: 'April',
    id: 4,
  },
  {
    title: 'May',
    id: 5,
  },
  {
    title: 'June',
    id: 6,
  },
  {
    title: 'July',
    id: 7,
  },
  {
    title: 'August',
    id: 8,
  },
  {
    title: 'September',
    id: 9,
  },
  {
    title: 'October',
    id: 10,
  },
  {
    title: 'November',
    id: 11,
  },
  {
    title: 'December',
    id: 12,
  },
];

export const dynamicFilterSwitcher = (name: string, value: any) => {
  switch (value) {
    case 'true': {
      return `${name}==true`;
    }
    case 'false': {
      return `${name}==false`;
    }
    default: {
      return undefined;
    }
  }
};

export const roundTo = (n: number, place: number) => {
  return +(Math.round(Number(n + 'e+' + place)) + 'e-' + place);
};

export const makeFullName = (user: { firstName?: Unset; lastName?: Unset }) => {
  return [user.firstName, user.lastName].filter(Boolean).join(' ');
};

export const factoryGetOptionDisabled = (values: string[], maxItems: number = Infinity) => {
  return (option: { id: Unset }) => {
    if (values.some((id) => option.id === id)) {
      return false;
    }
    return values.length >= maxItems;
  };
};

export const findMentionUserIDs = (value: Unset) => {
  if (!value) {
    return [];
  }

  const matchesWithAttributes = String(value).matchAll(new RegExp('data-mention-id=".+?"', 'gi'));

  const matches = Array.from(matchesWithAttributes).map(([value]) => {
    const match = value.match(/"([^"]*)"/);

    return match ? match[1] : '';
  });

  return matches.filter(Boolean);
};

export const getShortFullName = (fullName?: string | null) => {
  if (!fullName) return '';

  const [firstName, lastName] = fullName.split(' ');

  const shortLastName = lastName ? `${lastName[0]}.` : null;

  return [firstName, shortLastName].filter(Boolean).join(' ');
};

export const calcTotatDurations = (items: Array<{ duration: Unset; billableDuration: Unset }>) => {
  return items.reduce(
    (acc, item) => {
      acc.duration += durationToMinutes(item.duration);
      acc.billableDuration += durationToMinutes(item.billableDuration);
      return acc;
    },
    { duration: 0, billableDuration: 0 },
  );
};

export const calcInitCenterPosition: DraggableCalcInitPosition = (paperEl, isRTL = false) => {
  const rect = paperEl.getBoundingClientRect();

  const viewportElem = document.getElementsByTagName('body')[0];
  const viewport = viewportElem ? viewportElem.getBoundingClientRect() : undefined;

  let parentCenterX = 0;
  let parentCenterY = 0;

  if (viewport) {
    parentCenterX = viewport.left + viewport.width / 2;
    parentCenterY = viewport.top + viewport.height / 2;
  }

  const childTop = parentCenterY - rect.height / 2;
  const childLeft = parentCenterX - rect.width / 2;

  const centeredChild = {
    ...rect,
    top: childTop,
    left: childLeft,
  };

  const { left, top } = makePopupVisible(centeredChild, viewport);

  return { x: isRTL ? -left : left, y: top };
};
