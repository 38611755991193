import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppNotificationLatest, ServiceAppNotifications } from 'services/app-notifications';
import { parseErrorData } from 'utils/service';
import { selectAppUserID } from '../auth';
import { AppAsyncThunkConfig } from '../index';
import { PREFIX } from './helpers';

export const actionNotificationsNotDoneFetch = createAsyncThunk<
  { value: AppNotificationLatest[]; count: number },
  void,
  AppAsyncThunkConfig
>(`${PREFIX}/actionNotificationsNotDoneFetch`, async (_, { getState }) => {
  try {
    const appUserID = selectAppUserID(getState());

    const result = await ServiceAppNotifications.getCurrentNotDone(appUserID);
    return result.data;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
