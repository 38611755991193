import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { BaseSlot } from '../base-slot';
import style from './index.module.scss';

interface Classes {
  root: string;
  controls: string;
}

interface Props extends BoxProps {
  className?: string;
  title?: string;
  src: string;
  onClick: () => void;
  children?: React.ReactNode;
  classes?: Partial<Classes>;
}
export const BaseSlotImage: React.FC<Props> = ({
  src,
  onClick,
  className,
  title,
  children,
  classes,
  ...rest
}) => {
  return (
    <Box className={clsx(style.root, className, classes?.root)} {...rest}>
      <BaseSlot
        alignItems={'center'}
        justifyContent={'center'}
        title={title}
        className={style.slot}
      >
        <img
          onClick={onClick}
          src={src}
          alt={title}
          style={{ objectFit: 'contain' }}
          width={'100%'}
          height={'100%'}
        />
      </BaseSlot>
      <div
        className={clsx(style.controls, classes?.controls, !children && style.cursor)}
        onClick={onClick}
        title={title}
      >
        {children}
        <ZoomInOutlinedIcon color={'primary'} onClick={onClick} className={style.zoom} />
      </div>
    </Box>
  );
};
