import { Paper, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { Unset } from 'utils/types';
import { useTranslate } from '../../hooks';
import style from './index.module.scss';

const colors = [
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#CDDC39',
  '#F7B800',
  '#FF5722',
  '#795548',
  '#9E9E9E',
  '#607D8B',
];

interface Props {
  value: Unset;
  onChange: (hex: string) => void;
}
export const ColorPickerCustomer: React.FC<Props> = ({ value, onChange }) => {
  const { tp } = useTranslate();
  const factoryClick = useCallback(
    (color: string) => {
      return () => onChange(color);
    },
    [onChange],
  );
  return (
    <Paper elevation={4} sx={{ p: 1.6 }}>
      <Typography mb={1.6} variant={'subtitle1'}>
        {tp('select-color')}:
      </Typography>
      <div className={style.area}>
        {colors.map((color, i) => {
          return (
            <div
              key={i}
              className={clsx(style.item)}
              style={{ borderColor: color === value ? color : undefined }}
              onClick={factoryClick(color)}
            >
              <div className={style.pointer} style={{ backgroundColor: color }} />
            </div>
          );
        })}
      </div>
    </Paper>
  );
};
