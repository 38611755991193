import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import { MenuButton } from 'components/menu-button';
import { memo, useMemo } from 'react';
import { useDataGridTasks } from '../../hooks';

interface Props {
  row: any;
  isEdit?: boolean;
  isDelete?: boolean;
}

export const DataGridRowControls = memo<Props>(function DataGridRowControls({
  row,
  isEdit = true,
  isDelete = true,
}) {
  const { onTask } = useDataGridTasks();
  const items = useMemo(() => {
    return [
      ...(isEdit
        ? [{ title: 'edit', onClick: () => onTask({ type: 'row-edit', payload: { row } }) }]
        : []),
      ...(isDelete
        ? [
            {
              title: 'delete',
              onClick: () => onTask({ type: 'row-delete', payload: { row } }),
              style: { color: 'red' },
            },
          ]
        : []),
    ];
  }, [isEdit, row, onTask, isDelete]);
  return (
    <MenuButton items={items}>
      <IconButton size={'small'}>
        <MoreVertIcon />
      </IconButton>
    </MenuButton>
  );
});
