import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_PROJECTS, IGridProjects, IGridProjectsArgs, IProject } from './models';

type Model = IProject;
const REVALIDATE_TAG = 'Projects';

class Service extends DynamicService<IProject> {}

interface ProjectSource
  extends Pick<IProject, 'id' | 'customerID' | 'isActive' | 'projectNameHeb'> {
  title: string;
}

export const ServiceProjects = new Service({
  getAll: API_PROJECTS.GET_ALL_DYNAMIC,
  post: API_PROJECTS.POST,
  patch: API_PROJECTS.PATCH,
  delete: API_PROJECTS.DELETE,
});

export const apiProjects = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getProjectSources: build.query<ProjectSource[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceProjects.getAllDynamic<ProjectSource>({
            select: dynamic.select(
              'id',
              'projectName as title',
              'projectNameHeb',
              'customerID',
              'isActive',
            ),
            orderBy: 'isActive desc, projectStatus.rank asc, projectName asc',
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getProject: build.query<Model, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServiceProjects.getDynamic(id);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    getGridProjects: build.query<DynamicResult<IGridProjects, { count: true }>, IGridProjectsArgs>({
      query: ({ search, projectTypeID, take, skip, orderBy }) => ({
        url: API_PROJECTS.GET_ALL_DYNAMIC,
        params: {
          filter: dynamic
            .mergeFilters(
              dynamic.makeFilter(
                ['customer.companyName', 'projectName', 'projectStatus.title'],
                search,
                dynamic.decoratorIsNotNullable(dynamic.contains),
              ),
              dynamic.makeFilter(
                ['projectTypeID'],
                projectTypeID,
                dynamic.decoratorIsNotNullable(dynamic.equals),
              ),
            )
            .join('&&'),
          select: dynamic.select(
            'id',
            'isActive',
            'projectName',
            'projectNameHeb',
            'projectKey',
            'startDate',
            'projectStatus.title as projectStatusTitle',
            'projectType.title as projectTypeTitle',
            'customer.companyName as customerCompanyName',
          ),
          orderBy: dynamic.orderBy(orderBy.field as any, orderBy.order),
          count: true,
          take,
          skip,
        },
      }),
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    postProject: build.mutation<void, Omit<Model, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceProjects.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchProject: build.mutation<void, PatchPartial<Model, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceProjects.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: REVALIDATE_TAG, id }],
    }),
    deleteProject: build.mutation<void, PatchPartial<Model, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceProjects.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_TAG },
        { type: REVALIDATE_TAG, id },
      ],
    }),
  }),
});
