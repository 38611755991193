import { isEqual, pick } from 'lodash-es';
import { isDateLike } from 'utils/dates';
import { ValueFileUploader } from 'utils/file-uploader';
import * as yup from 'yup';
import { InferType } from 'yup';

export const schemaBaseImage = yup.object({
  id: yup.string().nullable().default(null),
  userCrmProfileID: yup.string().nullable().default(null),
  entryDate: yup
    .string()
    .notRequired()
    .nonNullable()
    .test('date', 'rule-date', isDateLike)
    .default(() => new Date().toISOString()),
  description: yup.string().nullable().notRequired().default(null),
  image: yup.mixed<ValueFileUploader>().required('rule-required').default(''),
  rank: yup.number().notRequired().nonNullable().default(1),
});

export type BaseImage = InferType<typeof schemaBaseImage>;
export type BaseImageNew = Omit<BaseImage, 'id'>;
export type BaseImageExist = Omit<BaseImage, 'id'> & { id: string };

export type BaseImageOptions = { mainID: string };

export interface ServiceWithImages {
  createImage: (image: BaseImageNew, options: BaseImageOptions) => Promise<void>;
  updateImage: (image: BaseImageExist, options: BaseImageOptions) => Promise<void>;
  deleteImage: (image: BaseImageExist, options: BaseImageOptions) => Promise<void>;
}

export class BehaviourHandleImages {
  service: ServiceWithImages;
  constructor(service: ServiceWithImages) {
    this.service = service;
  }
  async perform(prev: BaseImage[], next: BaseImage[], options: BaseImageOptions) {
    const { toCreate, toUpdate, toDelete } = this.prepare(prev, next);

    let createPromises = toCreate.map((item) => this.service.createImage(item, options));
    let updatePromises = toUpdate.map((item) => this.service.updateImage(item, options));
    let deletePromises = toDelete.map((item) => this.service.deleteImage(item, options));

    await Promise.all([...createPromises, ...updatePromises, ...deletePromises]);
  }
  private prepare(prev: BaseImage[], next: BaseImage[]) {
    let keys = Object.keys(schemaBaseImage.fields);

    const mapMaker = (item: BaseImage) => [String(item.id), item] as const;

    const mapPrev = new Map(prev.map(mapMaker));

    const toCreate: Array<BaseImage> = [];
    const toDelete: Array<BaseImage> = [];
    let toUpdate: Array<BaseImage> = [];

    for (let i = 0; i < next.length; i++) {
      let item = next[i];

      const rank = i + 1;

      if (!item.id) {
        toCreate.push({ ...item, rank });
      } else {
        toUpdate.push({ ...item, rank });
      }
    }

    let mapUpdate = new Map(toUpdate.map(mapMaker));

    for (let i = 0; i < prev.length; i++) {
      let item = prev[i];
      if (!mapUpdate.has(String(item.id))) {
        toDelete.push(item);
      }
    }

    toUpdate = toUpdate.filter((item) => {
      let prevItem = pick(item, keys);
      let nextItem = pick(mapPrev.get(String(item.id)), keys);
      return !isEqual(prevItem, nextItem);
    });

    return {
      toCreate: toCreate as Array<BaseImageNew>,
      toUpdate: toUpdate as Array<BaseImageExist>,
      toDelete: toDelete as Array<BaseImageExist>,
    };
  }
}
