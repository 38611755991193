import { TIME_FORMAT_DATE } from 'configs/const';
import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { useTranslate } from 'hooks';
import { useMemo } from 'react';
import { convertToDate } from 'utils/dates';
import { Shortcut } from '../components';

export const usePickerDefaultShortcuts = () => {
  const { t } = useTranslate();
  return useMemo<Shortcut[]>(
    () => [
      {
        title: t('today'),
        value: 'today',
        makePickerValue: () => [startOfDay(new Date()), endOfDay(new Date())],
        renderInputValue: (currentValue) => {
          const val = currentValue[0];
          return val ? format(convertToDate(val), TIME_FORMAT_DATE) : '--';
        },
        buttonsStrategy: (dir, v) => {
          const value = convertToDate(v[0]);
          const increment = dir === 'next' ? 1 : -1;
          const newValue = addDays(value, increment);

          return [startOfDay(newValue), endOfDay(newValue)];
        },
      },
      {
        title: t('current-week'),
        value: 'week',
        makePickerValue: () => [startOfWeek(new Date()), endOfWeek(new Date())],
        buttonsStrategy: (dir, v) => {
          const value = convertToDate(v[0]);
          const increment = dir === 'next' ? 1 : -1;
          const newValue = addWeeks(value, increment);

          return [startOfWeek(newValue), endOfWeek(newValue)];
        },
      },
      {
        title: t('current-month'),
        value: 'month',
        renderInputValue: (currentValue) => {
          const val = currentValue[0];
          return val ? format(convertToDate(val), 'yyyy-MM') : '--';
        },
        makePickerValue: () => [startOfMonth(new Date()), endOfMonth(new Date())],
        buttonsStrategy: (dir, v) => {
          const value = convertToDate(v[0]);
          const increment = dir === 'next' ? 1 : -1;
          const newValue = addMonths(value, increment);

          return [startOfMonth(newValue), endOfMonth(newValue)];
        },
      },
      {
        title: t('current-quarter'),
        value: 'quarter',
        renderInputValue: (currentValue) => {
          if (currentValue.length !== 2) return '--';
          return currentValue.map((v) => format(convertToDate(v), 'yyyy-MM')).join(' - ');
        },
        makePickerValue: () => [startOfQuarter(new Date()), endOfQuarter(new Date())],
        buttonsStrategy: (dir, v) => {
          const value = convertToDate(v[0]);
          const increment = dir === 'next' ? 1 : -1;
          const newValue = addQuarters(value, increment);

          return [startOfQuarter(newValue), endOfQuarter(newValue)];
        },
      },
      {
        title: t('current-year'),
        value: 'year',
        renderInputValue: (currentValue) => {
          const val = currentValue[0];
          return val ? format(convertToDate(val), 'yyyy') : '--';
        },
        makePickerValue: () => [startOfYear(new Date()), endOfYear(new Date())],
        buttonsStrategy: (dir, v) => {
          const value = convertToDate(v[0]);
          const increment = dir === 'next' ? 1 : -1;
          const newValue = addYears(value, increment);

          return [startOfYear(newValue), endOfYear(newValue)];
        },
      },
    ],
    [t],
  );
};
