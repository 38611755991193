import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  apiProjectAlerts,
  IGridAlerts,
  IProjectAlert,
  ServiceProjectAlerts,
} from 'services/project-alerts';
import * as dynamic from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';
import { selectAlertsFilters, selectAlertsOrderBy, selectAlertsPagination } from './selectors';

export const actionAlertsGetAllDynamic = createAsyncThunk<
  { value: IGridAlerts[]; count: number },
  { page: number; take?: number } | void,
  AppAsyncThunkConfig
>(`ALERTS/getAllDynamic`, async (payload, { getState }) => {
  try {
    const { search, projectID } = selectAlertsFilters(getState());
    const { take, skip } = selectAlertsPagination(getState());
    const { field, order } = selectAlertsOrderBy(getState());
    const params = {
      filter: dynamic
        .mergeFilters(
          dynamic.makeFilter(
            ['remarks', 'project.projectName', 'project.customer.companyName'],
            search,
            dynamic.decoratorIsNotNullable(dynamic.contains),
          ),
          dynamic.makeFilter(
            ['projectID'],
            projectID,
            dynamic.decoratorIsNotNullable(dynamic.equals),
          ),
        )
        .join('&&'),
      select: dynamic.select(
        'id',
        'isActive',
        'remarks',
        'dueDate',
        'project.projectName as projectName',
        'project.customer.companyName as customerName',
      ),
      orderBy: dynamic.orderBy(field, order),
      count: true,
      take,
      skip,
    };
    const { data: result } = await ServiceProjectAlerts.getAllDynamic<IGridAlerts, typeof params>(
      params,
    );
    return result;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
export const actionAlertsChangeItem = createAsyncThunk<
  void,
  Partial<IProjectAlert>,
  AppAsyncThunkConfig
>(`ALERTS/changeItem`, async (data, { dispatch }) => {
  try {
    const id = data.id;
    if (!id) {
      throw new Error('id is required');
    }
    await dispatch(apiProjectAlerts.endpoints.patchProjectAlert.initiate({ ...data, id })).unwrap();
    await dispatch(actionAlertsGetAllDynamic()).unwrap();
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
