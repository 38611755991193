import { Avatar, Stack, Typography, TypographyProps } from '@mui/material';
import React, { memo, useMemo } from 'react';
import { getShortFullName } from 'utils/app-helpers';
import { Unset } from 'utils/types';

interface TemplateUserFullNameProps {
  userPhoto: Unset;
  fullName: Unset;
  avatarHeight?: number | string;
  avatarWidth?: number | string;
  fontSize?: number | string;
  isFullName?: boolean;
  TypographyProps?: Partial<TypographyProps>;
}
export const TemplateUserFullName: React.FC<TemplateUserFullNameProps> = ({
  userPhoto,
  fullName = '',
  avatarHeight = 32,
  avatarWidth = 32,
  fontSize = 14,
  TypographyProps,
}) => {
  const shortName = useMemo(() => {
    return (fullName || '')
      .split(' ')
      .slice(0, 2)
      .filter(Boolean)
      .map((word) => word[0]);
  }, [fullName]);

  return (
    <Stack direction={'row'} spacing={0.8} alignItems={'center'}>
      <Avatar src={userPhoto ?? ''} sx={{ height: avatarHeight, width: avatarWidth }}>
        {userPhoto ? shortName : undefined}
      </Avatar>
      <Typography fontSize={fontSize} {...TypographyProps}>
        {fullName}
      </Typography>
    </Stack>
  );
};

interface TemplateUserPartialNameProps {
  userPhoto: Unset;
  firstName: Unset;
  lastName: Unset;
  avatarHeight?: number | string;
  avatarWidth?: number | string;
  fontSize?: number | string;
  isFullName?: boolean;
  TypographyProps?: Partial<TypographyProps>;
}
const TemplateUserPartialName: React.FC<TemplateUserPartialNameProps> = ({
  userPhoto,
  lastName,
  firstName,
  avatarHeight = 32,
  avatarWidth = 32,
  fontSize = 14,
  TypographyProps,
}) => {
  const fullName = useMemo(
    () => [firstName, lastName].filter(Boolean).join(' '),
    [firstName, lastName],
  );

  return (
    <TemplateUserFullName
      userPhoto={userPhoto}
      fullName={fullName}
      avatarHeight={avatarHeight}
      avatarWidth={avatarWidth}
      fontSize={fontSize}
      TypographyProps={TypographyProps}
    />
  );
};

export const TemplateUserName = memo<TemplateUserFullNameProps | TemplateUserPartialNameProps>(
  (props) => {
    if ('fullName' in props) {
      const updatedFullName = props?.isFullName ? props.fullName : getShortFullName(props.fullName);
      return (
        <TemplateUserFullName
          userPhoto={props.userPhoto}
          fullName={updatedFullName}
          avatarHeight={props.avatarHeight}
          avatarWidth={props.avatarWidth}
          fontSize={props.fontSize}
          TypographyProps={props.TypographyProps}
        />
      );
    }

    const updatedLastName = props.lastName ? `${props.lastName[0]}.` : props.lastName;
    return (
      <TemplateUserPartialName
        userPhoto={props.userPhoto}
        firstName={props.firstName}
        lastName={updatedLastName}
        avatarHeight={props.avatarHeight}
        avatarWidth={props.avatarWidth}
        fontSize={props.fontSize}
        TypographyProps={props.TypographyProps}
      />
    );
  },
);
