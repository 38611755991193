export const transformToCatalogName = <T extends string | null | undefined>(value: T) => {
  if (!value) return value;

  return String(value)
    .toLowerCase()
    .replace(/[^a-z0-9- ]+/g, '')
    .replace(/(^\d+)([^0-9])/, (_, g1, g2) => `${g1}-${g2}`)
    .replace(/ +/g, '-')
    .replace(/-+/g, '-')
    .trim();
};
export const transformFieldToLabelKey = <T extends string | null | undefined>(value: T) => {
  if (!value) return value;

  const label = String(value)
    .replace(/ID/g, '')
    .replace(/[A-Z]+/g, (substring) => {
      return `-${substring}`;
    });

  return transformToCatalogName(label);
};
export const transformFromOldToLabelKey = <T extends string | null | undefined>(value: T) => {
  if (!value) return value;

  return transformFieldToLabelKey(value.replace(/_/g, '-'));
};
