import { apiCustomers } from 'services/customers';
import { apiProjects } from 'services/projects';
import { apiTicketStatuses } from 'services/ticket-statuses';
import { apiTickets } from 'services/tickets';
import { selectSourceTicketsFullByStatus } from '../store/sources';
import { useAppSelector } from './redux';

export const useStatusesWithTickets = () => {
  // start: load relative data
  const rS = apiTicketStatuses.useGetTicketStatusesQuery();
  const rT = apiTickets.useGetTicketsAllQuery();
  const rP = apiProjects.useGetProjectSourcesQuery();
  const rC = apiCustomers.useGetCustomersQuery();
  // end: load relative data

  const isLoading = rS.isLoading || rT.isLoading || rP.isLoading || rC.isLoading;

  const statuses = useAppSelector(selectSourceTicketsFullByStatus);

  return { data: statuses, isLoading };
};
