import { ButtonBaseProps } from '@mui/material';
import clsx from 'clsx';
import { BaseColorfulPaper } from 'components/tickets';
import React from 'react';
import { Unset } from '../../utils/types';
import style from './index.module.scss';

interface Props extends ButtonBaseProps {
  bColor: Unset;
  isActive: boolean;
  className?: string;
}
export const ButtonColorful: React.FC<Props> = ({
  bColor,
  children,
  isActive,
  className,
  ...rest
}) => {
  return (
    <BaseColorfulPaper
      borders={{
        left: true,
      }}
      bColor={bColor}
      {...rest}
      className={clsx(style.root, className, isActive && style.active)}
    >
      {children}
    </BaseColorfulPaper>
  );
};
