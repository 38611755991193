import { yupResolver } from '@hookform/resolvers/yup';
import { AppDatePicker } from 'components/app-date-picker';
import { AppInput } from 'components/app-input';
import { DialogApply } from 'components/dialog-apply';
import { useCurrentUser, useFieldProps, useTranslate } from 'hooks';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ImageData, schemaImageData } from '../../models';

const schema = schemaImageData;

interface Props {
  onClose: () => void;
  onInsert: (formData: ImageData) => void;
}

export const DialogInsert: React.FC<Props> = ({ onClose, onInsert }) => {
  const { appUserID } = useCurrentUser();
  const { tp } = useTranslate();
  const { handleSubmit, control, register } = useForm({
    defaultValues: schema.cast({ userCrmProfileID: appUserID }),
    resolver: yupResolver(schema),
  });

  const getFieldProps = useFieldProps();

  return (
    <DialogApply
      isLoading={false}
      onClose={onClose}
      onApply={handleSubmit(onInsert)}
      title={tp('insert-images')}
      ApplyProps={{
        children: tp('insert'),
      }}
    >
      <input {...register('id')} hidden />
      <input {...register('userCrmProfileID', { value: appUserID })} hidden />
      <Controller
        control={control}
        name={'entryDate'}
        render={(renderProps) => {
          return (
            <AppDatePicker
              {...getFieldProps(renderProps)}
              slotProps={{ textField: { fullWidth: true } }}
            />
          );
        }}
      />
      <Controller
        control={control}
        name={'description'}
        render={(renderProps) => {
          return <AppInput autoFocus {...getFieldProps(renderProps)} multiline minRows={2} />;
        }}
      />
    </DialogApply>
  );
};
