import { AxiosInstance } from 'axios';
import React, { memo, useEffect, useState } from 'react';

export interface AxiosInterceptorsTokenGetToken {
  (): void | { token: string };
}

export interface AxiosInterceptorTokenProps {
  /**
   * a required prop that represents the child elements of the component.
   */
  children: React.ReactNode;
  /**
   * an array of AxiosInstance instances that are used to intercept the requests.
   */
  instances: AxiosInstance[];
  /**
   * a function that returns the token to be added in the request headers.
   */
  getToken: AxiosInterceptorsTokenGetToken;
}
export const AxiosInterceptorToken = memo<AxiosInterceptorTokenProps>(
  ({ children, instances, getToken }) => {
    const [init, setInit] = useState(false);

    useEffect(() => {
      const ids = instances.map((instance) => {
        const id = instance.interceptors.request.use((config) => {
          const result = getToken();
          const token = result ? result.token : null;

          if (token && config.headers) {
            config.headers['Authorization'] = 'Bearer ' + token;
          }
          return config;
        });
        return { id, instance };
      });

      setInit(true);
      return () => {
        ids.forEach(({ instance, id }) => {
          instance.interceptors.request.eject(id);
        });
      };
    }, [instances, getToken]);

    return <>{init && children}</>;
  },
);
