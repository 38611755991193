import { DynamicOrder } from 'utils/dynamic';
import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'TimeTrackingActivities';

export const API_TIME_TRACKING_ACTIVITIES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ITrackingActivity, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ITrackingActivity, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface ITrackingActivity
  extends FixServerObject<
    Components.Schemas.TimeTrackingActivity,
    'id' | 'isActive' | 'title' | 'isAbsence'
  > {}
export const schemaTrackingActivity = yup.object({
  id: yup.string().notRequired().default(undefined),
  title: yup.string().required('rule-required').default(''),
  titleHeb: yup.string().notRequired().default(''),
  isActive: yup.boolean().default(true),
  isAbsence: yup.boolean().default(false),
  rank: yup.number().notRequired().default(1),
});

export interface ITrackingActivityArgs {
  search: string;

  take: number;
  skip: number;
  orderBy: DynamicOrder;
}
