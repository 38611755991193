import { Box, BoxProps, Typography } from '@mui/material';
import { ButtonAdd } from 'components/buttons';
import { SvgTimer } from 'components/svg-icons/timer';
import { useTranslate } from 'hooks';
import React from 'react';
import VARS from 'styles/config.scss';
import style from './index.module.scss';

interface Props extends BoxProps {
  title?: string;
  onOpen: () => void;
}

export const EmptyTimeTracking: React.FC<Props> = ({ onOpen, title, ...rest }) => {
  const { tp } = useTranslate();

  return (
    <Box className={style.root} {...rest}>
      <SvgTimer sx={{ width: 188, height: 188, fill: 'none' }} />
      <Typography variant={'h3'} color={VARS.colorGrey} m={'3.2rem 0 2.4rem'} textAlign={'center'}>
        {title || tp('you-have-no-tracked-time-for-today-yet')}
      </Typography>
      <ButtonAdd onClick={onOpen} startIcon={null}>
        {tp('track-time')}
      </ButtonAdd>
    </Box>
  );
};
