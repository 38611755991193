import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { eachDayOfInterval, endOfWeek, format, startOfDay, startOfWeek } from 'date-fns';
import { useSelectHandler } from 'hooks/use-select-handler';
import React, { memo, useMemo } from 'react';
import { convertToDate, DateValue } from 'utils/dates';
import style from './index.module.scss';

interface LabelProps {
  date: DateValue;
}
const Label: React.FC<LabelProps> = ({ date }) => {
  const { title, subTitle } = useMemo(() => {
    return {
      title: format(convertToDate(date), 'iii'),
      subTitle: format(convertToDate(date), 'dd.MM'),
    };
  }, [date]);

  return (
    <Stack textAlign={'center'}>
      <Typography textTransform={'uppercase'} fontSize={'1.3rem'} className={style.title}>
        {title}
      </Typography>
      <Typography fontSize={'1.1rem'} pt={0.1}>
        {subTitle}
      </Typography>
    </Stack>
  );
};

interface Props {
  date?: DateValue;
  label?: React.ReactNode;
  value: string[];
  onChange: (value: this['value']) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  disabled?: boolean;
}

const makeID = (value: DateValue) => {
  return startOfDay(convertToDate(value)).toISOString();
};

const WeekDaysInputComponent: React.FC<Props> = ({
  date,
  onChange,
  helperText,
  error,
  label,
  value: _outerValue,
  disabled,
}) => {
  const value = useMemo(() => {
    return _outerValue.map(makeID);
  }, [_outerValue]);
  const options = useMemo(() => {
    const day = convertToDate(date);
    return eachDayOfInterval({
      start: startOfWeek(day),
      end: endOfWeek(day),
    }).map((dateItem) => ({
      id: makeID(dateItem),
    }));
  }, [date]);

  const { onChangeItem, mapSelected } = useSelectHandler({
    multiple: true,
    value,
    options,
    onChange,
  });

  return (
    <FormControl error={error} component="fieldset" variant="standard">
      {label && (
        <InputLabel shrink variant={'standard'} component={'legend'}>
          {label}
        </InputLabel>
      )}
      <FormGroup
        row
        classes={{
          root: style.group,
        }}
        sx={{
          mt: label ? 1 : 0,
        }}
      >
        {options.map((option, i) => {
          return (
            <FormControlLabel
              key={i}
              control={
                <Checkbox
                  checked={Boolean(mapSelected[option.id])}
                  onChange={() => onChangeItem(option.id)}
                />
              }
              disabled={disabled}
              labelPlacement={'bottom'}
              label={<Label date={option.id} />}
              classes={{
                root: clsx(style.item, Boolean(mapSelected[option.id]) && style.itemActive),
              }}
            />
          );
        })}
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export const WeekDaysInput = memo(WeekDaysInputComponent) as typeof WeekDaysInputComponent;
