import InboxIcon from '@mui/icons-material/Inbox';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Tab, Tabs, Typography } from '@mui/material';
import { CollapsePanel } from 'components/collapse-panel';
import { APP_ROUTES } from 'configs';
import { useAppDispatch, useAppSelector, useTranslate } from 'hooks';
import { memo, useCallback } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import {
  actionsNotificationsLayout,
  selectNotificationsLayoutExpanded,
} from 'store/notifications-layout';
import { Panel } from './components';
import style from './index.module.scss';

interface Props {}

const NotificationsLayout = memo<Props>(() => {
  const { tp } = useTranslate();
  const dispatch = useAppDispatch();
  const title = tp('notifications');

  const { pathname } = useLocation();

  const isExpanded = useAppSelector(selectNotificationsLayoutExpanded);
  const onToggleExpand = useCallback(() => {
    dispatch(actionsNotificationsLayout.toggleExpanded());
  }, [dispatch]);

  return (
    <>
      <CollapsePanel
        in={isExpanded}
        onClick={onToggleExpand}
        panel={
          <div className={style.left}>
            <Typography variant={'h2'} component={'h1'} color={'primary'}>
              {title}
            </Typography>
            <Panel isLoading={false} />
          </div>
        }
      >
        <div className={style.right}>
          <Tabs value={pathname}>
            <Tab
              value={APP_ROUTES.NOTIFICATIONS.path}
              label={tp('notifications-inbox')}
              component={Link}
              to={APP_ROUTES.NOTIFICATIONS().config}
              icon={<InboxIcon sx={{ fontSize: 20 }} />}
              iconPosition={'start'}
              sx={{
                minHeight: 48,
              }}
            />
            <Tab
              value={APP_ROUTES.NOTIFICATIONS_PERSONAL.path}
              label={tp('notifications-sent')}
              component={Link}
              to={APP_ROUTES.NOTIFICATIONS_PERSONAL().config}
              icon={<TelegramIcon sx={{ fontSize: 20 }} />}
              iconPosition={'start'}
              sx={{
                minHeight: 48,
              }}
            />
          </Tabs>
          <Outlet />
        </div>
      </CollapsePanel>
    </>
  );
});

export default NotificationsLayout;
