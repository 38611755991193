import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { AppInputLabel } from 'components/app-input-label';
import { AppSwitch } from 'components/app-switch';
import { ButtonCancel, ButtonCreate } from 'components/buttons';
import { SelectEmployees } from 'components/select-employees';
import { useFieldProps, useTranslate } from 'hooks';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  APP_NOTIFICATION_TYPE,
  getNotificationIcon,
  schemaAppNotificationPost,
} from 'services/app-notifications';
import { InferType } from 'yup';

const schema = schemaAppNotificationPost.pick(['message', 'urgent', 'userCrmProfileIDs']);
export type NotificationsCreateModel = InferType<typeof schema>;

interface Props {
  type: APP_NOTIFICATION_TYPE;
  isLoading: boolean;
  onSubmit: (formData: NotificationsCreateModel) => void;
  onCancel: () => void;
}

export const DialogNotificationsCreate: React.FC<Props> = ({
  type,
  isLoading,
  onSubmit,
  onCancel,
}) => {
  const { tp } = useTranslate();
  const methods = useForm({ defaultValues: schema.cast({}), resolver: yupResolver(schema) });
  const { control, handleSubmit } = methods;
  const getFieldProps = useFieldProps();

  const Icon = getNotificationIcon(type);

  return (
    <Dialog open={true} fullWidth maxWidth="sm" onClose={onCancel}>
      <DialogContent>
        <Stack
          direction={'row'}
          spacing={1}
          justifyContent={'space-between'}
          alignItems={'center'}
          pt={0.5}
          mb={1.5}
        >
          <Stack direction={'row'} spacing={1} flexGrow={1} alignItems={'center'}>
            <Icon color={'primary'} />
            <Typography variant={'subtitle1'}>{tp('add-notification')}</Typography>
          </Stack>

          <Controller
            control={control}
            name={'urgent'}
            render={(renderProps) => {
              return (
                <AppSwitch
                  color={'error'}
                  {...getFieldProps(renderProps)}
                  disabled={isLoading}
                  label={tp('make-urgent')}
                />
              );
            }}
          />
        </Stack>
        <Stack spacing={0.2}>
          <Controller
            control={control}
            name={'userCrmProfileIDs'}
            render={(renderProps) => {
              return (
                <SelectEmployees
                  {...getFieldProps(renderProps)}
                  multiple
                  disabled={isLoading}
                  label={tp('assignees')}
                />
              );
            }}
          />
          <Controller
            control={control}
            name={'message'}
            render={(renderProps) => {
              const props = getFieldProps(renderProps);
              return (
                <AppInputLabel
                  {...props}
                  multiline
                  disabled={isLoading}
                  minRows={5}
                  variant={'outlined'}
                />
              );
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <ButtonCancel onClick={onCancel} />
        <ButtonCreate onClick={handleSubmit(onSubmit)} disabled={isLoading}>
          {tp('add')}
        </ButtonCreate>
      </DialogActions>
    </Dialog>
  );
};
