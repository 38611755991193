import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { Stack, Typography } from '@mui/material';
import { AppLoading } from 'components/app-loading';
import { InlineOrder } from 'components/inline-order';
import { compareAsc, compareDesc } from 'date-fns';
import { useEffectNotifyError, useMap, useSourceCrmUser, useTranslate } from 'hooks';
import React, { useMemo, useState } from 'react';
import { apiTicketActionItemComments } from 'services/ticket-action-item-comments';
import { convertToDate } from 'utils/dates';
import { enumToArray } from 'utils/types';
import { useTicketActionItemInformation } from '../../hooks/use-ticket-information';
import { TicketActionItemCommentContainer } from './comment-item-container';
import { TicketActionItemCommentNewContainer } from './comment-item-new-container';
import style from './index.module.scss';

const useFetchQuery = apiTicketActionItemComments.useGetTicketActionItemCommentsByTicketActionQuery;

type SortItem = { createdDate: Date };
const sortDesc = (itemA: SortItem, itemB: SortItem) => {
  return compareDesc(itemA.createdDate, itemB.createdDate);
};
const sortAsc = (itemA: SortItem, itemB: SortItem) => {
  return compareAsc(itemA.createdDate, itemB.createdDate);
};

enum ORDER {
  NEW = 'new-first',
  OLD = 'old-first',
}
const factorySort = (order: ORDER) => {
  switch (order) {
    case ORDER.NEW:
      return sortDesc;
    case ORDER.OLD:
      return sortAsc;
  }
};

interface Props {
  ticketActionItemID: string | undefined;
}
export const TicketActionItemComments: React.FC<Props> = ({ ticketActionItemID }) => {
  const { tp, t } = useTranslate();

  const resultInfo = useTicketActionItemInformation(ticketActionItemID);
  const { ticketActionItem } = resultInfo;

  const sourceCrmUser = useSourceCrmUser();
  const mapUsers = useMap(sourceCrmUser.data, 'id');

  const resultFetch = useFetchQuery(ticketActionItemID || '', {
    skip: !ticketActionItemID,
  });
  useEffectNotifyError(resultFetch.error);
  const [sort, setSort] = useState(ORDER.NEW);
  const sortOptions = useMemo(() => {
    return enumToArray(ORDER).map(({ id, title }) => ({ id, title: t(id) }));
  }, [t]);

  const rows = useMemo(() => {
    return (resultFetch.data || []).map((item) => {
      return {
        id: item.id,
        user: mapUsers[item.userCrmProfileID],
        createdDate: convertToDate(item.createdDate),
        updatedDate: convertToDate(item.updatedDate),
        description: item.description,
        itemKey: item.itemKey,
        images: item.images,
      };
    });
  }, [resultFetch.data, mapUsers]);
  const rowsSorted = useMemo(() => {
    return rows.sort(factorySort(sort));
  }, [rows, sort]);

  const isLoading = sourceCrmUser.isFetching || !ticketActionItemID;

  const initComment = useMemo(() => {
    return {
      notificationUserCrmProfileIDs: [ticketActionItem?.assigneeUserCrmProfileID].filter(
        Boolean,
      ) as Array<string>,
    };
  }, [ticketActionItem]);

  return (
    <div className={style.root}>
      <Stack direction={'row'} alignItems={'center'} spacing={0.8} mb={rowsSorted.length ? 1.6 : 0}>
        <ChatBubbleOutlineIcon color={'primary'} />
        <Typography
          color={'primary'}
          component={'h5'}
          fontWeight={500}
          textTransform={'uppercase'}
          flexGrow={1}
        >
          {tp('comments')}
        </Typography>
        <InlineOrder value={sort} onChange={setSort} options={sortOptions} />
      </Stack>
      <div className={style.content}>
        <Stack spacing={1.8}>
          {rowsSorted.map((row) => (
            <TicketActionItemCommentContainer
              key={row.id}
              id={row.id}
              ticketActionItemID={ticketActionItemID}
              user={row.user}
              createdDate={row.createdDate}
              updatedDate={row.updatedDate}
              description={row.description}
              itemKey={row.itemKey}
              images={row.images}
              isHighlighted={false}
            />
          ))}
        </Stack>
        <div className={style.bottom}>
          <TicketActionItemCommentNewContainer
            ticketActionItemID={ticketActionItemID}
            disabled={isLoading}
            init={initComment}
          />
        </div>
        {isLoading && <AppLoading />}
      </div>
    </div>
  );
};

export { TicketActionItemCommentContainer, TicketActionItemCommentNewContainer };
