import TodayIcon from '@mui/icons-material/Today';
import {
  ClickAwayListener,
  FormHelperText,
  IconButton,
  InputAdornment,
  Paper,
  Popper,
} from '@mui/material';
import clsx from 'clsx';
import { AppInput, AppInputProps } from 'components/app-input';
import { RadioButtonOption, RadioButtons } from 'components/radio-buttons';
import { FORMAT_TIME, TIME_FORMAT_DATE, TIME_FORMAT_DATE_TIME } from 'configs/const';
import { endOfDay, isSameDay, startOfDay } from 'date-fns';
import { useOpen } from 'hooks';
import React, {
  ForwardedRef,
  forwardRef,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { convertToDate, DateValue, formatDate, isDateLike } from 'utils/dates';
import { composeFunctions } from 'utils/other';
import { CalendarDay } from './components/calendar-day';
import { CalendarDayTime } from './components/calendar-day-time';
import { CalendarRange } from './components/calendar-range';
import style from './index.module.scss';
import { RenderCalendarProps, Strategy } from './models';

export const isSame = (first: DateValue, second: DateValue) => {
  if (!isDateLike(first)) return false;
  if (!isDateLike(second)) return false;
  return (
    formatDate(convertToDate(first), 'yyyy/MM/dd hh:mm:ss') ===
    formatDate(convertToDate(second), 'yyyy/MM/dd hh:mm:ss')
  );
};

export const defaultRenderInputValue = (currentValue: DateValue[]) => {
  return currentValue
    .filter(isDateLike)
    .map((d) => formatDate(d, TIME_FORMAT_DATE))
    .join(' - ');
};

export const defaultStrategies: Strategy[] = [
  {
    value: 'all-day',
    title: 'all-day',
    isMatch: (currentValue) => {
      const [start, end] = currentValue.map(convertToDate);

      if (!isSameDay(start, end)) return false;

      const isStart = isSame(start, startOfDay(start));
      const isEnd = isSame(end, endOfDay(end));

      return isStart && isEnd;
    },
    renderInputValue: (currentValue) => {
      return formatDate(currentValue[0], DEFAULT_INPUT_FORMAT);
    },
    renderCalendar: (renderProps) => {
      return <CalendarDay {...renderProps} />;
    },
  },
  {
    value: 'date-and-time',
    title: 'date-and-time',
    isMatch: (currentValue) => {
      const [start, end] = currentValue.map(convertToDate);

      return isSameDay(start, end);
    },
    renderInputValue: (currentValue) => {
      const [start, end] = currentValue.filter(isDateLike);
      if (!start) return '';
      if (!end) return '';

      return `${formatDate(start, TIME_FORMAT_DATE_TIME)} - ${formatDate(end, FORMAT_TIME)}`;
    },
    renderCalendar: (renderProps) => <CalendarDayTime {...renderProps} />,
  },
  {
    value: 'date-range',
    title: 'date-range',
    isMatch: (currentValue) => {
      const [start, end] = currentValue.map(convertToDate);

      return !isSameDay(start, end);
    },
    renderInputValue: defaultRenderInputValue,
    renderCalendar: (renderProps) => <CalendarRange {...renderProps} />,
  },
];

const DEFAULT_INPUT_FORMAT = 'dd/MM/yyyy';
interface Classes {
  root: string;
}

interface Props {
  error?: boolean;
  helperText?: React.ReactNode;
  value: any[];
  onChange?: (value: Date[]) => void;
  onApply?: (value: Date[]) => void;
  closeOnApply?: boolean;
  className?: string;
  classes?: Partial<Classes>;
  isOpenPicker?: boolean;
  strategies?: Strategy[];
}

const defOnChange = () => {};

const Component = (
  {
    value: originValue,
    onChange = defOnChange,
    closeOnApply = true,
    onApply,
    isOpenPicker,
    strategies: _strategies,
    error,
    helperText,
    ...rest
  }: Props & Omit<AppInputProps, 'value' | 'onChange' | 'onClick'>,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const refRoot = useRef<HTMLDivElement>(null);
  const strategies = _strategies ?? defaultStrategies;

  const [strategyValue, onChangeStrategyValue] = useState();
  const onClearShortcut = useCallback(() => {
    onChangeStrategyValue(undefined);
  }, []);

  const isEmpty = useMemo(() => {
    return originValue.length !== 2;
  }, [originValue]);

  const strategy = useMemo(() => {
    if (strategyValue) {
      return strategies.find(({ value }) => value === strategyValue);
    }
    if (!isEmpty) {
      return strategies.find(({ isMatch }) => isMatch(originValue));
    }
  }, [strategies, strategyValue, originValue, isEmpty]);

  const { isOpen, onOpen, onClose, onToggle } = useOpen(isOpenPicker);

  const inputValue = useMemo(() => {
    if (!strategy) {
      return null;
    }
    const render = strategy.renderInputValue || defaultRenderInputValue;
    return render(originValue);
  }, [originValue, strategy]);

  const shouldRenderInput = inputValue && originValue.length === 2;

  const checkApply = useCallback(
    (value: Date[]) => {
      if (value.length === 2) {
        onApply && onApply(value);
        closeOnApply && onClose();
      }
    },
    [onApply, onClose, closeOnApply],
  );

  const onClear = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onChange([]);
      onClearShortcut();
      onApply && onApply([]);
    },
    [onChange, onApply, onClearShortcut],
  );

  const strategyOptions = useMemo<RadioButtonOption[]>(() => {
    return strategies.map((st) => ({
      id: st.value,
      title: st.title,
      TabProps: {
        onClick: onOpen,
        sx: {
          textTransform: 'uppercase !important',
        },
      },
    }));
  }, [strategies, onOpen]);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div ref={refRoot}>
        <div className={style.wrap}>
          {shouldRenderInput ? (
            <AppInput
              variant={'standard'}
              value={inputValue}
              fullWidth={true}
              {...rest}
              error={error}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={'end'}>
                    <IconButton
                      disabled={rest.disabled}
                      size={'small'}
                      onClick={rest.disabled ? undefined : onToggle}
                    >
                      <TodayIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                ...rest.InputProps,
              }}
              inputProps={rest.inputProps}
              onClear={composeFunctions(onClear, rest.onClear)}
            />
          ) : (
            <RadioButtons
              className={clsx(style.field, { [style.error]: error })}
              value={strategyValue}
              onChange={composeFunctions(onChangeStrategyValue, onOpen)}
              options={strategyOptions}
              includeAll={false}
              variant={'fullWidth'}
              classes={{
                root: style.tab,
              }}
            />
          )}
          {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
        </div>

        <Popper
          anchorEl={refRoot.current}
          open={isOpen}
          placement={'bottom-start'}
          sx={{ zIndex: 1300 }}
          keepMounted={false}
        >
          <Paper>
            {strategy &&
              strategy?.renderCalendar({
                value: originValue,
                onChange,
                onClose,
                onApply: checkApply,
              })}
          </Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export const DateRangeInput = memo(forwardRef(Component)) as typeof Component;
export type { Strategy, RenderCalendarProps };
export { CalendarRange, CalendarDay, CalendarDayTime };
