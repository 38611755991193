import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import {
  API_REPORT_DONE_ITEMS,
  DoneItemsForReport,
  GetDoneItemsForReportInput,
  IGetAllReportDoneItem,
  IReportDoneItem,
} from './models';

const REVALIDATE_KEY = 'ReportDoneItems' as const;

export * from './models';

class Service extends DynamicService<IReportDoneItem> {
  getDoneItemsForReport = async (input: GetDoneItemsForReportInput) => {
    const { reportID, orderBy } = input;
    const res = await this.getAllDynamic<DoneItemsForReport>({
      filter: dynamic
        .mergeFilters(
          dynamic.makeFilter('reportID', reportID, dynamic.decoratorIsNotNullable(dynamic.equals)),
          dynamic.makeFilter('isActive', true, dynamic.equals),
        )
        .join('&&'),
      select: dynamic.select(
        'id',
        'reportID',
        'doneItemID',
        'doneItem.tag as tag',
        'doneItem.date as date',
        'doneItem.billableDuration as billableDuration',
        'doneItem.duration as duration',
        'doneItem.description as description',
        'doneItem.timeTrackingID as timeTrackingID',
        'doneItem.timeTracking.projectID as projectID',
        'doneItem.projectName as projectName',
        'doneItem.timeTracking.date as timeTrackingDate',
        'doneItem.userName as userName',
        'doneItem.userCrmProfileID as userCrmProfileID',
        'doneItem.userCrmProfile.userPhoto as userPhoto',
        'doneItem.userCrmProfile.fullName as fullName',
        'doneItem.timeTracking.ticketActionItemID as ticketActionItemID',
        'report.reportName as reportName',
        'report.customer.companyName as customerName',
      ),
      orderBy: dynamic.orderBy(orderBy),
    });

    return res.data.value;
  };
}

export const ServiceReportDoneItems = new Service({
  getAll: API_REPORT_DONE_ITEMS.GET_ALL_DYNAMIC,
  post: API_REPORT_DONE_ITEMS.CREATE,
  patch: API_REPORT_DONE_ITEMS.PATCH,
  delete: API_REPORT_DONE_ITEMS.DELETE,
});

type ApiModel = IReportDoneItem;

export const apiReportDoneItems = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getAllReportDoneItems: build.query<IGetAllReportDoneItem[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceReportDoneItems.getAllDynamic<IGetAllReportDoneItem>({
            select: dynamic.select('id', 'reportID', 'doneItemID'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error) => [{ type: REVALIDATE_KEY }],
    }),
    getReportDoneItem: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServiceReportDoneItems.getDynamic(id);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    getReportDoneItemByTimeTrackingID: build.query<IGetAllReportDoneItem[], string>({
      queryFn: async (timeTrackingID) => {
        try {
          const {
            data: { value },
          } = await ServiceReportDoneItems.getAllDynamic<IGetAllReportDoneItem>({
            select: dynamic.select('id', 'reportID', 'doneItemID', 'report.lock as reportLock'),
            filter: dynamic.makeFilter(
              'doneItem.timeTrackingID',
              timeTrackingID,
              dynamic.decoratorIsNotNullable(dynamic.equals),
            ),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postReportDoneItem: build.mutation<void, Omit<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceReportDoneItems.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }, { type: 'TimeTracking' }],
    }),
    patchReportDoneItem: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceReportDoneItems.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deleteReportDoneItem: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceReportDoneItems.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
        { type: 'TimeTracking' },
      ],
    }),
    deleteReportDoneItemBulk: build.mutation<void, string[]>({
      queryFn: async (input) => {
        try {
          await Promise.all(
            input.map((id) => {
              return ServiceReportDoneItems.delete({ id });
            }),
          );
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: () => [{ type: REVALIDATE_KEY }, { type: 'TimeTracking' }],
    }),
    deactivateReportDoneItemBulk: build.mutation<void, string[]>({
      queryFn: async (input) => {
        try {
          await Promise.all(
            input.map((id) => {
              return ServiceReportDoneItems.patch({ id, isActive: false });
            }),
          );
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: () => [{ type: REVALIDATE_KEY }, { type: 'TimeTracking' }],
    }),
  }),
});
