import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService } from 'utils/service';
import { PatchPartial, Unset } from 'utils/types';
import {
  API_REPORTS,
  IGetAllReport,
  IGetReport,
  IGridReport,
  IGridReportArgs,
  IGridReportOutput,
  IReport,
} from './models';

const REVALIDATE_KEY = 'Reports' as const;

export * from './models';

class Service extends DynamicService<IReport> {
  isReportLocked(report: { bill: Unset<boolean>; lock: Unset<boolean> }) {
    return Boolean(report.bill || report.lock);
  }
}

export const ServiceReports = new Service({
  getAll: API_REPORTS.GET_ALL_DYNAMIC,
  post: API_REPORTS.CREATE,
  patch: API_REPORTS.PATCH,
  delete: API_REPORTS.DELETE,
});

type ApiModel = IReport;

export const apiReports = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridReports: build.query<DynamicResult<IGridReport, { count: true }>, IGridReportArgs>({
      queryFn: async (arg) => {
        const { search, inProgress, customerID, dateRange } = arg;

        const params = {
          filter: dynamic
            .mergeFilters(
              dynamic.makeFilter(
                'fromDate',
                dateRange[0],
                dynamic.decoratorDateISO(dynamic.moreOrEquals),
              ),
              dynamic.makeFilter(
                'toDate',
                dateRange[1],
                dynamic.decoratorDateISO(dynamic.lessOrEquals),
              ),
              dynamic.makeFilter(
                'customerID',
                customerID,
                dynamic.decoratorIsNotNullable(dynamic.equals),
              ),
              dynamic.makeFilter(
                ['reportName', 'customer.companyName'],
                search,
                dynamic.decoratorIsNotNullable(dynamic.contains),
              ),
              inProgress ? 'bill==false && lock==false' : undefined,
            )
            .join('&&'),
          select: dynamic.select(
            'id',
            'fromDate',
            'toDate',
            'reportName',
            'bill',
            'lock',
            'maxHoursReport',
            'maxHoursPerUser',
            `reportDoneItems.Where(c => c.isActive).Select(c => new {
              c.doneItem.duration,
              c.doneItem.billableDuration,
              c.doneItem.userCrmProfileID
            }) as doneItems`,
          ),
          count: true,
          orderBy: 'lock desc, fromDate asc',
        };

        try {
          const res = await ServiceReports.getAllDynamic<IGridReportOutput, typeof params>(params);
          return { data: res.data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY, id: 'grid' }],
    }),
    getAllReports: build.query<IGetAllReport[], { customerID: string; timeTrackingID?: string }>({
      queryFn: async ({ customerID, timeTrackingID }) => {
        try {
          const {
            data: { value },
          } = await ServiceReports.getAllDynamic<IGetAllReport>({
            select: dynamic.select(
              'id',
              'createdDate',
              'reportName',
              'bill',
              'lock',
              'fromDate',
              'toDate',
              'reportDoneItems',
              ...(timeTrackingID
                ? [
                    `reportDoneItems.Where(c => c.doneItem.timeTrackingID=="${timeTrackingID}").Count() as reportDoneItemsCount`,
                  ]
                : []),
            ),
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter(
                  'customerID',
                  customerID,
                  dynamic.decoratorIsNotNullable(dynamic.equals),
                ),
              )
              .join('&&'),
            orderBy: 'fromDate desc, toDate desc',
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error) => [
        { type: REVALIDATE_KEY },
        { type: 'DoneItems' },
        { type: 'ReportDoneItems' },
      ],
    }),
    getReport: build.query<IGetReport, string>({
      queryFn: async (id) => {
        try {
          const params = {
            select: dynamic.select(
              'id',
              'excelFileName',
              'reportName',
              'fromDate',
              'toDate',
              'displayColumnCustomerName',
              'columnCustomerName',
              'maxHoursReport',
              'maxHoursPerUser',
              'direction',
              'customerID',
              'lock',
              'bill',

              'displayColumnDate',
              'columnDate',

              'displayColumnBillableDuration',
              'columnBillableDuration',

              'displayColumnDuration',
              'columnDuration',

              'displayColumnUserName',
              'columnUserName',

              'displayColumnDescription',
              'columnDescription',

              'displayColumnTag',
              'columnTag',

              'displayColumnProjectName',
              'columnProjectName',

              'customer.companyName as customerName',
            ),
          };
          const { data } = await ServiceReports.getDynamic<IGetReport>(id, params);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postReport: build.mutation<void, Omit<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceReports.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }, { type: REVALIDATE_KEY }],
    }),
    patchReport: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceReports.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deleteReport: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceReports.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
  }),
});
