import { DynamicOrder } from 'utils/dynamic';
import { replaceSpace } from 'utils/other';
import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'Labels';

export const API_LABELS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ILabel, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ILabel, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface ILabel
  extends FixServerObject<
    Components.Schemas.Label,
    'id' | 'languageID' | 'labelKey' | 'labelSourceID' | 'title'
  > {}

export const schemaLabels = yup.object({
  title: yup.string().required('rule-required').default(''),
  labelKey: yup.string().required('rule-required').transform(replaceSpace).default(''),
  languageID: yup.string().required('rule-required').default(''),
  labelSourceID: yup.string().required('rule-required').default(''),
  isActive: yup.boolean().default(true),
});

export interface IGridLabel extends Pick<ILabel, 'id' | 'labelKey' | 'title' | 'isActive'> {}

export interface IGridLabelsArgs {
  search: string;
  languageID: string;
  labelSourceID: string;
  take: number;
  skip: number;
  orderBy: DynamicOrder;
}
