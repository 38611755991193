import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {}

export const SvgTimer = (props: Props) => {
  return (
    <SvgIcon {...props} width="188" height="188" viewBox="0 0 188 188">
      <path
        d="M69.2161 112.369C74.8481 119.793 83.76 124.593 93.792 124.593C110.8 124.593 124.592 110.801 124.592 93.7925C124.592 76.7845 110.8 63.0086 93.792 63.0086"
        stroke="#EBEEEF"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M80.3362 45.0719C60.0002 50.6879 44.5762 68.7679 43.3282 90.9919C42.9442 97.9039 43.9681 104.576 46.1601 110.72"
        stroke="#EBEEEF"
        strokeWidth="4"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M96.1443 93.7926C96.1443 95.1046 95.0883 96.1605 93.7923 96.1605C92.4803 96.1605 91.4243 95.1046 91.4243 93.7926C91.4243 92.4966 92.4803 91.4405 93.7923 91.4405C95.0883 91.4405 96.1443 92.4966 96.1443 93.7926Z"
        stroke="#EBEEEF"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.7921 35.7603V93.7923L47.9521 128.464"
        stroke="#EBEEEF"
        strokeWidth="8"
        strokeMiterlimit="10"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M4 93.7921H16.1441"
        stroke="#EBEEEF"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M171.44 93.7921H183.584"
        stroke="#EBEEEF"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M93.792 4.00083V16.1449"
        stroke="#EBEEEF"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M93.792 171.44V183.6"
        stroke="#EBEEEF"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M184 94C184 143.721 143.696 184 93.9844 184C44.3035 184 4 143.721 4 94C4 44.2795 44.3035 4 93.9844 4C143.696 4 184 44.2795 184 94Z"
        stroke="#EBEEEF"
        strokeWidth="8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
