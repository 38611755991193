import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Box, ButtonBase } from '@mui/material';
import { DataGridEmpty } from 'components/data-grid';
import { Loading } from 'components/loading';
import { TIME_FORMAT_DATE } from 'configs/const';
import { format as formatDate } from 'date-fns';
import { useEffectNotifyError, useTranslate } from 'hooks';
import React, { useMemo } from 'react';
import { apiDoneItems } from 'services/done-items';
import { apiReports } from 'services/reports';
import { getShortFullName } from 'utils/app-helpers';
import { convertToDate } from 'utils/dates';
import { InfoBox } from '../info-box';
import style from './index.module.scss';

interface Props {
  customerID?: string | null;
  timeTrackingID: string;
}
export const ReportsListContentReadonly: React.FC<Props> = ({ customerID, timeTrackingID }) => {
  const { tp } = useTranslate();
  const { data, isFetching, isSuccess, error } = apiReports.useGetAllReportsQuery(
    { customerID: customerID || '', timeTrackingID },
    { skip: !customerID },
  );
  useEffectNotifyError(error);

  const {
    data: doneItemData,
    isFetching: doneItemIsFetching,
    error: doneItemErrorGet,
  } = apiDoneItems.useGetDoneItemQuery(timeTrackingID || '', {
    skip: !timeTrackingID,
  });
  useEffectNotifyError(doneItemErrorGet);

  const updatedData = useMemo(() => {
    return data?.filter((item) => item.reportDoneItemsCount) || [];
  }, [data]);

  const inNoData = isSuccess && updatedData.length === 0;

  const isLoading = isFetching || doneItemIsFetching;

  return (
    <>
      {!inNoData && (
        <div className={style.root}>
          {doneItemData && (
            <div className={style.infoBox}>
              <InfoBox label={tp('duration')} value={doneItemData.duration} />
              <InfoBox label={tp('billable-duration')} value={doneItemData.billableDuration} />
              <InfoBox
                label={tp('user-crm-profile')}
                value={getShortFullName(doneItemData.userCrmProfileFullName) || ''}
              />
            </div>
          )}
          <div className={style.header}>{tp('included-in-reports')}:</div>
          <div>
            {updatedData.map((item) => {
              const isLock = item.bill || item.lock;
              return (
                <div key={item.id} className={style.item}>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Box display={'flex'} alignItems={'flex-start'}>
                      <Box display={'flex'} alignItems={'center'} mr={0.8}>
                        {isLock ? (
                          <LockIcon color={'disabled'} />
                        ) : (
                          <LockOpenIcon color={'action'} opacity={0.3} />
                        )}
                      </Box>
                      <Box>
                        <div className={style.itemTitle}>
                          {formatDate(convertToDate(item.createdDate), TIME_FORMAT_DATE)}
                        </div>
                        <div className={style.itemDescription}>{item.reportName}</div>
                      </Box>
                    </Box>
                    <Box display={'flex'} alignItems={'center'}>
                      {item.bill && (
                        <ButtonBase className={style.billed}>{tp('billed')}</ButtonBase>
                      )}
                    </Box>
                  </Box>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {inNoData && <DataGridEmpty />}
      {isLoading && <Loading />}
    </>
  );
};
