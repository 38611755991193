import { Identifier } from 'dnd-core';
import React, { memo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { addPercent, getPercent } from 'utils/numbers';
import style from './index.module.scss';

interface DragItem {
  index: number;
  type: string;
}

interface Props {
  children: React.ReactNode;
  index: number;
  type: string;
  move: (dragIndex: number, hoverIndex: number) => void;
  moveEnd: () => void;
}
export const SlotDndWrapper = memo<Props>(({ children, index, type, move, moveEnd }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const rect = ref.current.getBoundingClientRect();

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      if (!clientOffset) return;

      // start: reduce hit box
      let x1 = rect.x + getPercent(rect.width, 10);
      let x2 = rect.x + addPercent(rect.width, -10);

      let y1 = rect.y + getPercent(rect.height, 10);
      let y2 = rect.y + addPercent(rect.height, -10);
      // end: reduce hit box

      if (clientOffset.x < x1 || clientOffset.x > x2) return;
      if (clientOffset.y < y1 || clientOffset.y > y2) return;

      // Time to actually perform the action
      move(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: type,
    item: () => {
      return { index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end(item, monitor) {
      moveEnd();
    },
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <div ref={ref} data-handler-id={handlerId} className={style.root} style={{ opacity }}>
      {children}
    </div>
  );
});
