import { Paper, PaperProps } from '@mui/material';
import { PaperTypeMap } from '@mui/material/Paper/Paper';
import React from 'react';
import variables from 'styles/config.scss';
import { Unset } from 'utils/types';

type Borders = {
  left: boolean;
  top: boolean;
  right: boolean;
  bottom: boolean;
};
const defaultBorders: Partial<Borders> = {
  left: true,
};
interface Props {
  bColor?: Unset;
  children?: React.ReactNode;
  borders?: Partial<Borders>;
}

export const BaseColorfulPaper = <
  RootComponent extends React.ElementType = PaperTypeMap['defaultComponent'],
  AdditionalProps = {},
>({
  bColor = variables.colorGrey,
  children,
  borders = defaultBorders,
  ...rest
}: PaperProps<RootComponent, AdditionalProps> & Props) => {
  const borderStyle = `3px solid ${bColor}`;
  return (
    <Paper
      variant={'outlined'}
      square={false}
      {...rest}
      sx={{
        borderLeft: borders.left ? borderStyle : undefined,
        borderTop: borders.top ? borderStyle : undefined,
        borderRight: borders.right ? borderStyle : undefined,
        borderBottom: borders.bottom ? borderStyle : undefined,
        ...rest?.sx,
      }}
    >
      {children}
    </Paper>
  );
};
