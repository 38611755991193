import { IconButton, IconButtonProps } from '@mui/material';
import { IconButtonTypeMap } from '@mui/material/IconButton/IconButton';
import clsx from 'clsx';
import React from 'react';
import style from './index.module.scss';

export const IconButtonGrey = <
  RootComponent extends React.ElementType = IconButtonTypeMap['defaultComponent'],
  AdditionalProps = {},
>({
  children,
  ...rest
}: IconButtonProps<RootComponent, AdditionalProps>) => {
  return (
    <IconButton {...rest} className={clsx(style.root, rest.className)}>
      {children}
    </IconButton>
  );
};
