import {
  BaseCommentInput,
  BaseCommentItem,
  CommentRenderEditorProps,
  CommentUser,
  FormData,
} from 'components/tickets';
import { useMountedRef } from 'hooks';
import React, { useCallback, useMemo } from 'react';
import { BaseImage } from 'services/@shared/images';
import { apiTicketActionItemComments } from 'services/ticket-action-item-comments';
import { DateValue } from 'utils/dates';
import { isFulfilledMutation } from 'utils/service';
import { Unset } from 'utils/types';

const usePatchMutation = apiTicketActionItemComments.usePatchTicketActionItemCommentMutation;

interface EditorProps extends CommentRenderEditorProps {
  id: string;
  ticketActionItemID: string | undefined;
}
const Editor: React.FC<EditorProps> = ({
  id,
  ticketActionItemID,
  description,
  images,
  onClose,
}) => {
  const [patchItem, resultPatch] = usePatchMutation();
  const mountedRef = useMountedRef();
  const onSubmit = useCallback(
    async (input: FormData) => {
      if (!ticketActionItemID) return;

      const result = await patchItem({
        id,
        description: input.description,
        images: input.images,
        ticketActionItemID,
        notificationUserCrmProfileIDs: input.notificationUserCrmProfileIDs,
      });

      if (!mountedRef.current) return;
      if (isFulfilledMutation(result)) {
        onClose();
      }
    },
    [onClose, id, ticketActionItemID, patchItem, mountedRef],
  );

  const init = useMemo(() => {
    return { description: description || '', images };
  }, [description, images]);

  return (
    <BaseCommentInput
      init={init}
      onCancel={onClose}
      disabled={resultPatch.isLoading}
      onSubmit={onSubmit}
    />
  );
};

const defaultUser = {
  id: '',
  fullName: '...',
  userPhoto: '',
};

interface Props {
  id: string;
  ticketActionItemID: string | undefined;
  user: Unset<CommentUser>;
  createdDate: DateValue;
  updatedDate: DateValue;
  description: string;
  images: BaseImage[];
  itemKey?: string | null;
  isHighlighted: boolean;
}
export const TicketActionItemCommentContainer: React.FC<Props> = ({
  id,
  ticketActionItemID,
  user,
  createdDate,
  updatedDate,
  description,
  itemKey,
  images,
  isHighlighted,
}) => {
  return (
    <BaseCommentItem
      user={user || defaultUser}
      createdDate={createdDate}
      updatedDate={updatedDate}
      description={description}
      itemKey={itemKey}
      images={images}
      isHighlighted={isHighlighted}
      renderEditor={({ description, onClose, images }) => {
        return (
          <Editor
            id={id}
            ticketActionItemID={ticketActionItemID}
            description={description}
            images={images}
            onClose={onClose}
          />
        );
      }}
    />
  );
};
