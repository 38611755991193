import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { endOfMonth, startOfMonth } from 'date-fns';
import { IBoardNote } from 'services/notes';
import { listToOrderList, orderListToOrderMap, OrderMap } from 'utils/order';
import { actionNotesFetchAll } from './actions';
import {
  DATE_RANGE_TYPE,
  notesDueDateToRange,
  NOTES_DUE_DATE,
  NOTES_VIEW,
  PREFIX,
} from './helpers';

interface GridFilters {
  search: string;
  dates: NOTES_DUE_DATE;
}

interface TimeLineFilters {
  search: string;
  dates: [Date, Date];
  dateRangeType: DATE_RANGE_TYPE;
}

interface CalendarFilters {
  search: string;
  dates: [Date, Date];
}

type ViewFilters<V extends NOTES_VIEW> = V extends NOTES_VIEW.GRID
  ? GridFilters
  : V extends NOTES_VIEW.TIMELINE
  ? TimeLineFilters
  : V extends NOTES_VIEW.CALENDAR
  ? CalendarFilters
  : never;

type Move = { id: string; index: number };
type Size = { height: number; width: number };

interface State {
  view: NOTES_VIEW;
  data: IBoardNote[];

  isMounted: boolean;
  isLoading: boolean;
  isOpenExpiredManager: boolean;

  [NOTES_VIEW.GRID]: {
    filters: GridFilters;
    orders: OrderMap;
    sizes: Record<string, Size>;
  };
  [NOTES_VIEW.TIMELINE]: {
    filters: TimeLineFilters;
  };
  [NOTES_VIEW.CALENDAR]: {
    filters: CalendarFilters;
  };

  archive: IBoardNote | null;
  attachToTicket: IBoardNote | null;
}

const initState = (): State => {
  return {
    view: NOTES_VIEW.GRID,
    data: [],

    isMounted: false,
    isLoading: false,
    isOpenExpiredManager: false,

    [NOTES_VIEW.GRID]: {
      filters: {
        search: '',
        dates: NOTES_DUE_DATE.ALL,
      },
      orders: {},
      sizes: {},
    },
    [NOTES_VIEW.TIMELINE]: {
      filters: {
        search: '',
        dates: notesDueDateToRange(NOTES_DUE_DATE.WORKING_WEEK) as [Date, Date],
        dateRangeType: DATE_RANGE_TYPE.WEEK,
      },
    },
    [NOTES_VIEW.CALENDAR]: {
      filters: {
        search: '',
        dates: [startOfMonth(new Date()), endOfMonth(new Date())],
      },
    },

    archive: null,
    attachToTicket: null,
  };
};

const slice = createSlice({
  name: PREFIX,
  initialState: initState(),
  reducers: {
    reset() {
      return initState();
    },
    filtersSet<V extends NOTES_VIEW>(
      state: State,
      action: PayloadAction<{ view: V; filters: Partial<ViewFilters<V>> }>,
    ) {
      const { view, filters } = action.payload;
      state[view].filters = {
        ...state[view].filters,
        ...filters,
      };
    },
    filtersReset(state, action: PayloadAction<NOTES_VIEW>) {
      state[action.payload].filters = initState()[action.payload].filters;
    },
    setView(state, action: PayloadAction<NOTES_VIEW>) {
      state.view = action.payload;
    },
    mount(state, action: PayloadAction<boolean>) {
      state.isMounted = action.payload;
    },
    reorder(state, action: PayloadAction<{ from: Move; to: Move }>) {
      state.grid.orders[action.payload.from.id] = action.payload.to.index;
      state.grid.orders[action.payload.to.id] = action.payload.from.index;
    },
    setSize(state, action: PayloadAction<{ id: string; size: Size }>) {
      state.grid.sizes[action.payload.id] = action.payload.size;
    },
    setExpiredManager(state, action: PayloadAction<boolean>) {
      state.isOpenExpiredManager = action.payload;
    },
    setArchive(state, action: PayloadAction<IBoardNote | null>) {
      state.archive = action.payload;
    },
    setAttachToTicket(state, action: PayloadAction<IBoardNote | null>) {
      state.attachToTicket = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionNotesFetchAll.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(actionNotesFetchAll.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;

        const normalizedOrderList = listToOrderList(state.data, state.grid.orders);
        const normalizedOrders = orderListToOrderMap(normalizedOrderList);

        Object.assign(state.grid.orders, normalizedOrders);
      })
      .addCase(actionNotesFetchAll.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const actionsNotes = slice.actions;
export const reducerNotes = slice.reducer;
