import htmlParse from 'html-react-parser';
import React, { useMemo } from 'react';

import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';
import style from './index.module.scss';

interface Props extends BoxProps {
  value: string | null;
}

export const TemplateHtml: React.FC<Props> = ({ value, children, className, ...rest }) => {
  const html = useMemo(() => {
    return value ? htmlParse(value) : '';
  }, [value]);
  return (
    <Box justifyContent={'center'} {...rest} className={clsx(style.root, className)}>
      {html}
      {children}
    </Box>
  );
};
