import React, { useMemo } from 'react';

import { Tooltip, Typography, TypographyProps } from '@mui/material';
import clsx from 'clsx';
import { TIME_FORMAT_DATE, TIME_FORMAT_DATE_TIME } from 'configs/const';
import { format as formatDate } from 'date-fns';
import { useTranslate } from 'hooks';
import { convertToDate, DateValue } from 'utils/dates';
import style from './index.module.scss';

interface Props extends TypographyProps {
  value: DateValue;
  format?: string;
  formatTitle?: string;
}

export const TemplateDate: React.FC<Props> = ({
  value,
  format = TIME_FORMAT_DATE,
  formatTitle = TIME_FORMAT_DATE_TIME,
  className,
  ...rest
}) => {
  const dateValue = useMemo(() => {
    return value ? formatDate(convertToDate(value), format) : '';
  }, [value, format]);
  const dateTime = useMemo(() => {
    return value ? formatDate(convertToDate(value), formatTitle) : undefined;
  }, [value, formatTitle]);

  const { tp } = useTranslate();
  return (
    <Tooltip title={dateTime || tp('invalid-date')}>
      <Typography
        component={'time'}
        dateTime={dateTime}
        {...rest}
        className={clsx(style.root, className)}
      >
        {dateValue}
      </Typography>
    </Tooltip>
  );
};
