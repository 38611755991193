import AddIcon from '@mui/icons-material/Add';
import ArticleIcon from '@mui/icons-material/Article';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LockIcon from '@mui/icons-material/Lock';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Box, IconButton, Tooltip } from '@mui/material';
import { IconButtonGrey } from 'components/buttons';
import { useValueQuery } from 'hooks/use-value-query';
import React, { useCallback } from 'react';
import { handleEventStopAndPrevent } from 'utils/handlers';
import { composeFunctions } from 'utils/other';
import { ticketActionItemEmitter } from '../../../../emitter';
import { useTasks } from '../../hooks/use-rtk-reducer';
import { GridModelExtended, QUERY_NAME_ADD_TO_REPORT_POPUP } from '../../models';
import { ReportsListContentReadonly } from '../reports-list-content-readonly';
import style from './index.module.scss';

interface Props {
  row: GridModelExtended;
}
export const ColumnControls: React.FC<Props> = ({ row }) => {
  const { onTask } = useTasks();
  const { onChange } = useValueQuery<string>({
    name: QUERY_NAME_ADD_TO_REPORT_POPUP,
  });
  const onClick = useCallback(() => {
    onTask({ type: 'row-edit', payload: { row } });
  }, [row, onTask]);

  const onAddToReport = useCallback(() => {
    ticketActionItemEmitter.emit('save-active-item-form');
    onChange(row.id);
  }, [row, onChange]);

  const description = row.doneItemsDescriptionData[0] || '';
  const isLock = row.__isLocked;

  return (
    <Box display={'flex'} alignItems={'end'} justifyContent={'end'}>
      {description && (
        <Tooltip title={<Box dangerouslySetInnerHTML={{ __html: description }} />}>
          <IconButton size={'small'}>
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}

      <IconButton
        onClick={composeFunctions(handleEventStopAndPrevent, onAddToReport)}
        size={'small'}
      >
        {row.__isInReport ? (
          <Tooltip
            classes={{ tooltip: style.tooltip }}
            title={
              <ReportsListContentReadonly customerID={row.customerID} timeTrackingID={row.id} />
            }
          >
            <ArticleIcon color={'disabled'} />
          </Tooltip>
        ) : (
          <AddIcon />
        )}
      </IconButton>
      <IconButtonGrey size={'small'} onClick={isLock ? undefined : onClick} disableRipple={isLock}>
        {isLock ? <LockIcon color={'disabled'} opacity={0.3} /> : <ModeEditIcon />}
      </IconButtonGrey>
    </Box>
  );
};
