import {
  PartialKeys,
  useVirtualizer,
  VirtualItem,
  VirtualizerOptions,
} from '@tanstack/react-virtual';
import clsx from 'clsx';
import { useTranslate } from 'hooks';
import { memo, useId } from 'react';
import { transformFieldToLabelKey } from 'utils/transformers';
import { useDataGridSettings } from '../../hooks';
import { IDataGridColumnParsed } from '../../models';
import { DataGridColumnHead } from '../data-grid-column';
import { DataGridTableProps } from '../data-grid-table';
import { DataGridTableRow } from '../data-grid-table-row';
import style from './index.module.scss';

export interface DataGridTableVirtualizedProps<T extends Record<string, any>>
  extends Omit<DataGridTableProps<T>, 'groupOptions' | 'renderGroup'> {
  virtualProps: PartialKeys<
    VirtualizerOptions<any, any>,
    'observeElementRect' | 'observeElementOffset' | 'scrollToFn' | 'count'
  >;
}

const Component = <T extends Record<string, any>>({
  rows,
  columns,
  rowProps,
  bodyStart,
  bodyEnd,
  virtualProps,
}: DataGridTableVirtualizedProps<T>) => {
  const { t } = useTranslate();
  const { classes, rowKey } = useDataGridSettings();
  const dragType = useId();

  const count = rows.length;
  const virtualizer = useVirtualizer({
    count,
    ...virtualProps,
  });

  const renderHeaderCell = (props: IDataGridColumnParsed<T>) => {
    const { HeadCellProps, HeadCellInnerProps, title, field, sortableKey, sticky, sortable } =
      props.headCellOptions;

    const child =
      HeadCellProps?.children || title === undefined
        ? t(transformFieldToLabelKey(String(field)))
        : title;

    return (
      <DataGridColumnHead
        key={field as string}
        field={field as string}
        {...HeadCellProps}
        InnerProps={HeadCellInnerProps}
        sticky={sticky}
        sortable={sortable}
        sortableKey={sortableKey}
        style={{
          transform: `translateY(${virtualizer.scrollOffset}px)`,
          opacity: virtualizer.isScrolling ? 0 : 1,
          zIndex: 11,
        }}
      >
        {child}
      </DataGridColumnHead>
    );
  };

  const renderRow = (
    virtualRow: VirtualItem<Element>,
    index: number,
    array: VirtualItem<Element>[],
  ) => {
    const row = rows[virtualRow.index];
    const rowID = row[rowKey];
    const isEven = virtualRow.index % 2 === 0;

    const first = array[0];
    const firstStart = first?.start || 0;

    return (
      <DataGridTableRow
        key={virtualRow.key}
        dataIndex={virtualRow.index}
        ref={virtualizer.measureElement}
        dragType={dragType}
        rowID={rowID}
        index={index}
        columns={columns}
        row={row}
        rowProps={rowProps}
        style={{
          transform: `translateY(${firstStart}px)`,
        }}
        className={clsx(!isEven && style.rowEven)}
      />
    );
  };

  return (
    <div style={{ height: `${virtualizer.getTotalSize()}px` }}>
      <table className={clsx(style.table, classes?.table)}>
        <thead>
          <tr className={clsx(style.rowHead, classes?.rowHead)}>{columns.map(renderHeaderCell)}</tr>
        </thead>
        <tbody>
          {bodyStart}
          {count > 0 && virtualizer.getVirtualItems().map(renderRow)}
          {bodyEnd}
        </tbody>
      </table>
    </div>
  );
};

export const DataGridTableVirtualized = memo(Component) as typeof Component;
