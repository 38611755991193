import { useTranslate } from 'hooks/use-translate';
import React, { useCallback } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { fieldToLabelKey } from 'utils/other';

export type FieldRenderProps = {
  field: ControllerRenderProps<any, any>;
  fieldState: ControllerFieldState;
};
export const useFieldProps = <T = {}>(shared?: T) => {
  const { t } = useTranslate();

  return useCallback(
    (renderProps: FieldRenderProps, props?: { label: React.ReactNode }) => {
      const { field, fieldState } = renderProps;
      const error = fieldState.error;

      return {
        ...field,
        ...shared,
        label: props?.label === undefined ? t(fieldToLabelKey(field.name)) : props?.label,
        error: Boolean(error),
        helperText: error && error.message ? t(error.message) : ' ',
      };
    },
    [t, shared],
  );
};

export const useInlineFieldProps = <T = void>(shared?: T) => {
  const origin = useFieldProps(shared);

  return useCallback<typeof origin>(
    (renderProps) => {
      const res = origin(renderProps);
      return { ...res, helperText: res.helperText === ' ' ? '' : res.helperText };
    },
    [origin],
  );
};
