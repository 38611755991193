import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import { makeTitle } from 'components/attachment-input';
import { BaseSlotImage } from 'components/attachment-input/components/base-slot-image';
import { DialogImagesPreview } from 'components/dialog-images-preview';
import DOMPurify from 'dompurify';
import { useOpenValue } from 'hooks';
import React, { useCallback, useMemo } from 'react';
import { BaseImage } from 'services/@shared/images';
import { getFileInfo } from 'utils/file-uploader';
import { Unset } from 'utils/types';
import style from './index.module.scss';

interface ImageViewProps {
  src: string;
  title: string;
  onClick: () => void;
}
const ImageView: React.FC<ImageViewProps> = ({ src, title, onClick }) => {
  return <BaseSlotImage src={src} title={title} onClick={onClick} />;
};

interface Props extends BoxProps {
  description: Unset;
  images: BaseImage[];
  isHiddenDescription?: boolean;
}
export const BaseCommentDescription: React.FC<Props> = ({
  description,
  images,
  isHiddenDescription,
  ...rest
}) => {
  const html = useMemo(() => {
    return description ? DOMPurify.sanitize(description) : '';
  }, [description]);

  const [initIndex, initIndexActions] = useOpenValue<number>();

  const previewImages = useMemo(() => {
    return images.map((image) => {
      return { picture: getFileInfo(image.image).fileUrl, title: makeTitle(image) };
    });
  }, [images]);

  const factoryClickImage = useCallback(
    (index: number) => {
      return () => {
        initIndexActions.open(index);
      };
    },
    [initIndexActions],
  );

  return (
    <Box {...rest}>
      <div
        dangerouslySetInnerHTML={{ __html: html }}
        className={clsx(style.root, isHiddenDescription && style.rootHidden)}
      />
      {images.length > 0 && (
        <div className={style.group}>
          {previewImages.map((image, i) => {
            return (
              <ImageView
                key={i}
                src={image.picture}
                onClick={factoryClickImage(i)}
                title={image.title}
              />
            );
          })}
        </div>
      )}
      {typeof initIndex === 'number' && (
        <DialogImagesPreview
          initialIndex={initIndex}
          images={previewImages}
          onClose={initIndexActions.close}
        />
      )}
    </Box>
  );
};
