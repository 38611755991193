import LoopIcon from '@mui/icons-material/Loop';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Box, BoxProps, Button, ButtonProps, Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslate } from 'hooks';
import React from 'react';
import variables from 'styles/config.scss';
import style from './index.module.scss';

interface Props extends BoxProps {
  className?: string;
  message?: string;
  ReloadButtonProps?: Partial<ButtonProps>;
}

export const LayoutError: React.FC<Props> = ({
  className,
  message,
  ReloadButtonProps,
  ...rest
}) => {
  const { tp } = useTranslate();
  let _message = message === undefined ? tp('error-message') : message;
  return (
    <Box className={clsx(style.root, className)} {...rest}>
      <div className={style.icon}>
        <SentimentVeryDissatisfiedIcon
          fontSize={'inherit'}
          sx={{
            fontSize: 200,
            opacity: 0.1,
          }}
        />
      </div>
      <Box position={'relative'} zIndex={2}>
        <Typography
          component={'h2'}
          mb={2}
          fontSize={26}
          fontWeight={500}
          textTransform={'uppercase'}
        >
          {tp('error-title')}
        </Typography>
        {_message && (
          <Typography mb={1.2} fontSize={18} fontWeight={500} color={variables.colorGrey}>
            {_message}
          </Typography>
        )}

        {ReloadButtonProps && (
          <Button {...ReloadButtonProps} startIcon={<LoopIcon />} variant={'contained'}>
            {tp('reload')}
          </Button>
        )}
      </Box>
    </Box>
  );
};
