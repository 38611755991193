import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { IconButton, Modal, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import style from './index.module.scss';

const MAX_ZOOM = 5;
const MIN_ZOOM = 0.5;

const STEP = 0.5;

interface Props {
  title: string;
  picture: string;
  onClose: () => void;
}
export const DialogImagePreview: React.FC<Props> = ({ title, picture, onClose }) => {
  const [scale, setScale] = useState(1);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });

  const zoomIn = useCallback(() => setScale((prev) => Math.min(MAX_ZOOM, prev + STEP)), []);

  const zoomOut = useCallback(() => setScale((prev) => Math.max(MIN_ZOOM, prev - STEP)), []);

  const handleMouseDown = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (scale <= 1) return;

    setIsDragging(true);
    setStartPos({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (!isDragging) return;

    const dx = event.clientX - startPos.x;
    const dy = event.clientY - startPos.y;

    setTranslate((prev) => ({
      x: prev.x + dx,
      y: prev.y + dy,
    }));
    setStartPos({ x: event.clientX, y: event.clientY });
  };

  const handleMouseUp = () => setIsDragging(false);

  const resetZoomPan = () => {
    setScale(1);
    setTranslate({ x: 0, y: 0 });
  };

  // Handle custom zooming logic on Ctrl+Scroll and Pinch gestures
  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();

        // Use deltaY to determine zoom direction
        if (event.deltaY < 0) {
          zoomIn();
        } else {
          zoomOut();
        }
      }
    };

    const handleGestureChange = (event: any) => {
      event.preventDefault();

      // event.scale provides the zoom level in pinch gestures
      const zoomDirection = event.scale > 1 ? 'in' : 'out';

      if (zoomDirection === 'in') {
        zoomIn();
      } else {
        zoomOut();
      }
    };

    // Attach wheel and gesturechange event listeners
    window.addEventListener('wheel', handleWheel, { passive: false });
    window.addEventListener('gesturechange', handleGestureChange, { passive: false });

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('gesturechange', handleGestureChange);
    };
  }, [zoomIn, zoomOut]);

  return (
    <Modal open={true} onClose={onClose}>
      <div
        className={style.root}
        onMouseDownCapture={handleMouseDown}
        onMouseMoveCapture={handleMouseMove}
        onMouseUpCapture={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <div className={clsx(style.panel, style.panelTop)}>
          <Stack>
            <Typography variant={'h2'} component={'h1'} color={'inherit'} textAlign={'center'}>
              {title}
            </Typography>
            <IconButton className={style.close} color={'inherit'} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </div>
        <div className={style.imageContainer} onDoubleClick={resetZoomPan}>
          <img
            className={style.image}
            src={picture}
            alt={title}
            style={{
              transform: `scale(${scale}) translate(${translate.x}px, ${translate.y}px)`,
              cursor: scale > 1 ? 'grab' : 'default',
            }}
            draggable={false}
          />
        </div>
        <div className={clsx(style.panel, style.panelBottom)}>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={1}>
            <IconButton color={'inherit'} onClick={zoomIn} disabled={scale >= MAX_ZOOM}>
              <ZoomInIcon />
            </IconButton>
            <IconButton color={'inherit'} onClick={zoomOut} disabled={scale <= MIN_ZOOM}>
              <ZoomOutIcon />
            </IconButton>
          </Stack>
          <Typography className={style.info} color={'inherit'} textAlign={'center'}>
            {100 * scale}%
          </Typography>
        </div>
      </div>
    </Modal>
  );
};
