import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'lodash-es';
import { apiCustomers } from './api';

const selectCustomersResult = apiCustomers.endpoints.getCustomers.select();
export const selectSourceCustomersAll = createSelector(selectCustomersResult, (res) => {
  return res.data || [];
});
export const selectSourceCustomersAllMap = createSelector(selectSourceCustomersAll, (res) => {
  return keyBy(res, 'id');
});
