import { AxiosInstance } from 'axios';
import React, { memo, useEffect, useRef, useState } from 'react';

type InnerState = {
  isRefreshing: boolean;
  refreshingCall: Promise<any>;
};
const REQUEST_ID = '__REFRESH_TOKEN__';
export type AxiosInterceptorRefreshTokenSuccessArg = {
  /** A string representing the new authentication token */
  token: string;
  /** A string representing the new refresh token */
  refreshToken: string;
  /** A string representing the expiration time of the new authentication token */
  expires: string;
};
export interface AxiosInterceptorRefreshTokenCheckIsRefreshCase {
  (options: { instance: AxiosInstance; error: any }): Promise<boolean>;
}
export interface AxiosInterceptorRefreshTokenRefresh {
  (): Promise<AxiosInterceptorRefreshTokenSuccessArg>;
}
export interface AxiosInterceptorRefreshTokenSuccess {
  (options: AxiosInterceptorRefreshTokenSuccessArg): void;
}
export interface AxiosInterceptorRefreshTokenError {
  (options: AxiosInterceptorRefreshTokenSuccessArg): void;
}
export interface AxiosInterceptorRefreshTokenProps {
  /** A function that refreshes the authentication token. This function should return a promise that resolves to an object with the new token, refresh token, and expiration time*/
  refresh: AxiosInterceptorRefreshTokenRefresh;
  /** A function that will be called when the token refresh is successful. This function should take an object with the new token, refresh token, and expiration time as its argument*/
  onSuccess: AxiosInterceptorRefreshTokenSuccess;
  /** A function that will be called when the token refresh fails. This function should take the error object as its argument. */
  onError: AxiosInterceptorRefreshTokenError;
  /** The child components that will be rendered */
  children: React.ReactNode;
  /** An array of Axios instances to which the interceptor will be applied */
  instances: AxiosInstance[];
  /** A function that checks whether a response error indicates that a token refresh is needed. This function should return a boolean indicating whether the error is a token refresh case */
  checkIsRefreshCase: AxiosInterceptorRefreshTokenCheckIsRefreshCase;
}

export const AxiosInterceptorRefreshToken = memo<AxiosInterceptorRefreshTokenProps>(
  ({ children, instances, checkIsRefreshCase, refresh, onSuccess, onError }) => {
    const [canRender, setCanRender] = useState(false);

    const refInnerState = useRef<InnerState>({
      isRefreshing: false,
      refreshingCall: Promise.resolve(),
    });

    useEffect(() => {
      const results = instances.map((instance) => {
        const id = instance.interceptors.response.use(
          (response) => response,
          async (error) => {
            if (error.config?.headers?.[REQUEST_ID] === REQUEST_ID) {
              return Promise.reject(error);
            }

            const isRefreshCase = await checkIsRefreshCase({ instance, error });

            if (!isRefreshCase) {
              return Promise.reject(error);
            }

            if (refInnerState.current.isRefreshing) {
              return refInnerState.current.refreshingCall.then(() => {
                return instance(error.config);
              });
            }

            refInnerState.current.isRefreshing = true;

            refInnerState.current.refreshingCall = refresh()
              .then((res) => {
                refInnerState.current.isRefreshing = false;
                const { token, refreshToken, expires } = res;
                onSuccess({ token, refreshToken, expires });
                return instance({
                  ...error.config,
                  headers: {
                    ...error.config.headers,
                    [REQUEST_ID]: REQUEST_ID,
                  },
                });
              })
              .catch((err) => {
                refInnerState.current.isRefreshing = false;
                onError(err);
                return Promise.reject(err);
              });

            return refInnerState.current.refreshingCall;
          },
        );

        return { id, instance };
      });

      setCanRender(true);

      return () => {
        results.forEach(({ instance, id }) => {
          instance.interceptors.response.eject(id);
        });
      };
    }, [refresh, onSuccess, onError, instances, checkIsRefreshCase]);

    return <>{canRender ? children : null}</>;
  },
);
