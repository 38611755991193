import React, { useEffect } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Stack,
  Typography,
} from '@mui/material';
import { ButtonCancel } from 'components/buttons';
import { useTimer, useTranslate } from 'hooks';

import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';

const AGREE_AUTO_TIME = 10;

interface Props extends Partial<DialogProps> {
  children: React.ReactNode;
  onClose: () => void;
  onAgree: () => void;
  isAgreeAuto?: boolean;
  disabled?: boolean;
}

export const DialogDelete: React.FC<Props> = ({
  children,
  onClose,
  onAgree,
  isAgreeAuto = true,
  disabled,
  ...rest
}) => {
  const { tp } = useTranslate();
  const [time, setTimer] = useTimer();

  useEffect(() => {
    if (isAgreeAuto) {
      setTimer(AGREE_AUTO_TIME);
    }
  }, [isAgreeAuto, setTimer]);

  useEffect(() => {
    if (isAgreeAuto && time === 0) {
      onAgree();
    }
  }, [isAgreeAuto, time, onAgree]);
  return (
    <Dialog open={true} {...rest} maxWidth={'xs'} fullWidth>
      <DialogContent>
        <Stack direction={'row'} spacing={2}>
          <ErrorTwoToneIcon color={'secondary'} />
          <Box pt={0.3}>
            <Typography component={'h4'} variant={'subtitle1'} mb={1.2}>
              {children}
            </Typography>
            {isAgreeAuto && (
              <Typography>
                {tp('auto-agree')} <strong>{time}</strong>
              </Typography>
            )}
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <ButtonCancel onClick={onClose} />
        <Button disabled={disabled} variant={'contained'} color={'primary'} onClick={onAgree}>
          {tp('agree')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
