import React from 'react';

import { FormControlLabel, Grid } from '@mui/material';
import { AppCheckbox } from 'components/app-checkbox';
import { AppSelect, defaultGetOptionDisabled } from 'components/app-select';
import { getUserDetailsOptionLabel } from 'components/render-option';
import { renderOptionWithIcon } from 'components/render-option/render-option-with-icon';
import { SelectEmployees } from 'components/select-employees';
import {
  useFieldProps,
  useSourcePriorities,
  useSourceProjects,
  useSourceTicketStatuses,
  useTranslate,
} from 'hooks';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
  isLoading: boolean;
}

export const Form: React.FC<Props> = ({ isLoading }) => {
  const { tp } = useTranslate();
  const { control } = useFormContext();
  const getFieldProps = useFieldProps();

  const sourceTicketStatuses = useSourceTicketStatuses();
  const sourceTicketPriorities = useSourcePriorities();
  const sourceProjects = useSourceProjects();
  return (
    <Grid container rowSpacing={0.2} columnSpacing={2}>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={'ticketStatusID'}
          render={(renderProps) => (
            <AppSelect
              {...getFieldProps(renderProps)}
              options={sourceTicketStatuses.data}
              loading={sourceTicketStatuses.isLoading}
              disabled={isLoading}
              disableClearable
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={'priorityID'}
          render={(renderProps) => (
            <AppSelect
              {...getFieldProps(renderProps, { label: tp('default-priority') })}
              options={sourceTicketPriorities.data}
              loading={sourceTicketPriorities.isLoading}
              disabled={isLoading}
              disableClearable
              renderOption={renderOptionWithIcon}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={'projectID'}
          render={(renderProps) => (
            <AppSelect
              {...getFieldProps(renderProps)}
              options={sourceProjects.data}
              loading={sourceProjects.isLoading}
              disabled={true}
              disableClearable
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name={'mustTrackTime'}
          render={(renderProps) => {
            const { label, value, onChange, onBlur } = getFieldProps(renderProps);
            return (
              <FormControlLabel
                sx={{ mt: 1 }}
                control={
                  <AppCheckbox
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={isLoading}
                  />
                }
                label={label}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={'defaultReporterUserCrmProfileID'}
          render={(renderProps) => (
            <SelectEmployees
              {...getFieldProps(renderProps)}
              disabled={isLoading}
              disableClearable
              getOptionLabel={getUserDetailsOptionLabel}
              getOptionDisabled={defaultGetOptionDisabled}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={'ownerUserCrmProfileID'}
          render={(renderProps) => (
            <SelectEmployees
              {...getFieldProps(renderProps)}
              disabled={isLoading}
              disableClearable
              getOptionLabel={getUserDetailsOptionLabel}
              getOptionDisabled={defaultGetOptionDisabled}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
