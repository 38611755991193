import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../index';
import { isNoteCustomerID } from './helpers';

const selectState = (state: AppState) => state.notesLayout;

export const selectNotesLayoutFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});

export const selectNotesLayoutFilterCustomerID = createSelector(
  selectNotesLayoutFilters,
  ({ customer }) => {
    return isNoteCustomerID(customer) ? customer : null;
  },
);

export const selectNotesLayoutExpanded = createSelector(selectState, ({ isExpanded }) => {
  return isExpanded;
});
