import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'lodash-es';
import {
  selectSourceTicketStatusesAll,
  selectSourceTicketStatusesAllMap,
} from 'services/ticket-statuses';
import { AppState } from '../index';
import { selectSourceProjectsWithCustomerMap } from '../sources';

const selectState = (state: AppState) => state.checkDoneTickets;

export const selectCheckDoneTicketsStatus = createSelector(
  selectState,
  ({ isLoading, error, isInit }) => {
    return { isLoading, error, isInit };
  },
);

export const selectCheckDoneTicketsFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});

export const selectCheckDoneTicketsData = createSelector(selectState, ({ data }) => {
  return data;
});
export const selectCheckDoneTicketsSelectedID = createSelector(selectState, ({ selectedID }) => {
  return selectedID;
});
export const selectCheckDoneTicketsDataFull = createSelector(
  selectCheckDoneTicketsData,
  selectSourceProjectsWithCustomerMap,
  selectSourceTicketStatusesAllMap,
  (tickets, mapProjects, mapStatuses) => {
    return tickets.map((ticket) => {
      const projectEntity = mapProjects[ticket.projectID];
      return {
        ...ticket,
        _project: projectEntity || null,
        _customer: projectEntity?._customer || null,
        _status: mapStatuses[ticket.ticketStatusID] || null,
      };
    });
  },
);
export const selectTicketsDataFullMap = createSelector(
  selectCheckDoneTicketsDataFull,
  (tickets) => {
    return keyBy(tickets, 'id');
  },
);
export const selectTicketsDataFiltered = createSelector(
  selectCheckDoneTicketsDataFull,
  selectCheckDoneTicketsFilters,
  (tickets, { customerID, projectID }) => {
    return tickets.filter((ticket) => {
      const result: Boolean[] = [true];
      if (customerID) {
        result.push(customerID === ticket._customer?.id);
      }

      if (projectID) {
        result.push(projectID === ticket.projectID);
      }

      return result.every(Boolean);
    });
  },
);
export const selectTicketsDataFilteredMap = createSelector(selectTicketsDataFiltered, (tickets) => {
  return keyBy(tickets, 'id');
});

export const selectCheckDoneTicketsStatusesBoards = createSelector(
  selectSourceTicketStatusesAll,
  selectTicketsDataFiltered,
  (statuses, tickets) => {
    return statuses.map((status) => {
      return {
        ...status,
        tickets: tickets.filter((ticket) => ticket.ticketStatusID === status.id),
      };
    });
  },
);
