import { yupResolver } from '@hookform/resolvers/yup';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Collapse, FormHelperText, InputAdornment, Stack } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import { AppTimeDuration, dateToTimeDuration } from 'components/app-time-duration';
import { addMinutes, startOfDay } from 'date-fns';
import { FieldRenderProps, useFieldProps } from 'hooks';
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { durationToMinutes } from 'utils/app-helpers';
import { convertToDate, DateValue, isDateLike } from 'utils/dates';
import { composeFunctions } from 'utils/other';
import * as yup from 'yup';
import { InferType } from 'yup';
import { RenderCalendarProps } from '../../models';

const dateToString = (v: DateValue) => {
  if (isDateLike(v)) return convertToDate(v).toISOString();
  return null;
};
const stringToDate = (v: DateValue) => {
  if (isDateLike(v)) return convertToDate(v);
  return null;
};

const schema = yup.object({
  date: yup.string().required('rule-required').default(''),
  startTime: yup
    .string()
    .required('rule-required')
    .test('startTime', 'rule-start-more-end', (value, context) => {
      const endTime = context.parent?.endTime;
      const startValue = durationToMinutes(value);
      const endValue = durationToMinutes(endTime);

      return startValue < endValue;
    })
    .default(''),
  endTime: yup
    .string()
    .required('rule-required')
    .test('endTime', 'rule-end-less-start', (value, context) => {
      const startTime = context.parent?.startTime;
      const startValue = durationToMinutes(startTime);
      const endValue = durationToMinutes(value);

      return startValue < endValue;
    })
    .default(''),
});

export interface Props extends RenderCalendarProps {}
export const CalendarDayTime: React.FC<Props> = ({ value: _value, onClose, onChange, onApply }) => {
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: schema.cast({}),
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    const dates = _value.map(dateToString);
    const [startTime, endTime] = dates.map(dateToTimeDuration);

    const date = dates[0] ? dates[0] : '';

    reset(schema.cast({ startTime, endTime, date }));
  }, [_value, reset]);

  const date = watch('date');

  const getFieldProps = useFieldProps();

  const onSubmit = useCallback(
    (formDate: InferType<typeof schema>) => {
      const { date, startTime, endTime } = formDate;
      const startOfDate = startOfDay(convertToDate(date));
      const start = addMinutes(startOfDate, durationToMinutes(startTime));
      const end = addMinutes(startOfDate, durationToMinutes(endTime));
      const newValue = [start, end];
      onChange(newValue);
      onApply(newValue);
    },
    [onChange, onApply],
  );
  const onComplete = useCallback(() => handleSubmit(onSubmit)(), [handleSubmit, onSubmit]);

  const renderTimeInput = useCallback(
    (renderProps: FieldRenderProps) => {
      return (
        <AppTimeDuration
          {...getFieldProps(renderProps)}
          onComplete={onComplete}
          disableClearable
          RenderInputProps={{
            InputProps: {
              endAdornment: (
                <InputAdornment position={'end'}>
                  <AccessTimeIcon color={'silver'} />
                </InputAdornment>
              ),
            },
          }}
          sx={{ minWidth: 125 }}
        />
      );
    },
    [getFieldProps, onComplete],
  );

  return (
    <div>
      <input hidden {...register('date')} />

      <Collapse in={Boolean(date)}>
        <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'center'} pt={1}>
          <Controller control={control} name={'startTime'} render={renderTimeInput} />
          <Controller control={control} name={'endTime'} render={renderTimeInput} />
        </Stack>
      </Collapse>

      <Collapse in={!!errors.date}>
        <FormHelperText error={!!errors.date}>{errors.date?.message}</FormHelperText>
      </Collapse>
      <Controller
        control={control}
        name={'date'}
        render={(renderProps) => {
          const value = stringToDate(renderProps.field.value);
          const onChange = (v: DateValue) => {
            renderProps.field.onChange(dateToString(v));
          };

          return <DateCalendar value={value} onChange={composeFunctions(onChange, onComplete)} />;
        }}
      />
    </div>
  );
};
