import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_PROJECT_TYPES, ProjectType } from './models';

export * from './models';

type Model = ProjectType;
const REVALIDATE_TAG = 'ProjectTypes';

class Service extends DynamicService<Model> {}

export const ServiceProjectTypes = new Service({
  getAll: API_PROJECT_TYPES.GET_ALL_DYNAMIC,
  post: API_PROJECT_TYPES.POST,
  patch: API_PROJECT_TYPES.PATCH,
  delete: API_PROJECT_TYPES.DELETE,
});

export const apiProjectTypes = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getProjectTypes: build.query<Model[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceProjectTypes.getAllDynamic({
            orderBy: dynamic.orderBy('rank', 'asc'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getProjectType: build.query<Model, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServiceProjectTypes.getDynamic(id);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    postProjectType: build.mutation<void, Model>({
      queryFn: async (customer) => {
        try {
          await ServiceProjectTypes.post(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchProjectType: build.mutation<void, PatchPartial<Model, 'id'>>({
      queryFn: async (customer) => {
        try {
          await ServiceProjectTypes.patch(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: REVALIDATE_TAG, id }],
    }),
    deleteProjectType: build.mutation<void, PatchPartial<Model, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceProjectTypes.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_TAG },
        { type: REVALIDATE_TAG, id },
      ],
    }),
  }),
});
