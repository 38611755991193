import { decoratorRank, decoratorWithFiles } from 'utils/decorators';
import * as dynamic from 'utils/dynamic';
import { apiRtk, behaviourMoveRows, DynamicResult, DynamicService } from 'utils/service';
import {
  API_TICKET_ACTION_ITEM_STATUS,
  ITicketActionItemStatus,
  ITicketActionItemStatusArgs,
} from './models';

type Model = ITicketActionItemStatus;

const REVALIDATE_TAG = 'TicketActionItemStatuses' as const;

class Service extends DynamicService<Model> {
  @decoratorWithFiles('id', 'icon')
  async patchWithFiles(data: Model) {
    return super.patch(data);
  }

  @decoratorWithFiles('id', 'icon')
  @decoratorRank('rank')
  async postWithFiles(data: Omit<Model, 'id'>) {
    return super.post(data);
  }

  moveRows = async (newRows: Partial<Model>[], oldRows: Partial<Model>[]) => {
    return behaviourMoveRows({
      mainField: 'id',
      moveField: 'rank',
      newRows,
      oldRows,
      requestPatch: this.patch,
    });
  };
}

export const ServiceTicketActionItemStatuses = new Service({
  getAll: API_TICKET_ACTION_ITEM_STATUS.GET_ALL_DYNAMIC,
  post: API_TICKET_ACTION_ITEM_STATUS.POST,
  patch: API_TICKET_ACTION_ITEM_STATUS.PATCH,
  delete: API_TICKET_ACTION_ITEM_STATUS.DELETE,
});

export const apiTicketActionItemStatuses = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getTicketActionItemStatusesSource: build.query<Model[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceTicketActionItemStatuses.getAllDynamic({
            filter: dynamic.makeFilter('isActive', true, dynamic.equals),
            select: dynamic.select('id', 'labelKey', 'icon', 'color', 'rank', 'isDefault'),
            orderBy: dynamic.orderBy('rank', 'asc'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getTicketActionItemStatusesGrid: build.query<
      DynamicResult<Model, { count: true }>,
      ITicketActionItemStatusArgs
    >({
      query: ({ search, orderBy, take, skip }) => ({
        url: API_TICKET_ACTION_ITEM_STATUS.GET_ALL_DYNAMIC,
        params: {
          filter: dynamic
            .mergeFilters(
              dynamic.makeFilter(
                ['labelKey'],
                search,
                dynamic.decoratorIsNotNullable(dynamic.contains),
              ),
            )
            .join('&&'),
          select: dynamic.select(
            'id',
            'labelKey',
            'icon',
            'color',
            'isActive',
            'isDefault',
            'rank',
          ),
          take,
          skip,
          orderBy: dynamic.orderBy(orderBy),
          count: true,
        },
      }),
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getTicketActionItemStatus: build.query<Model, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServiceTicketActionItemStatuses.getDynamic(id);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    postTicketActionItemStatus: build.mutation<void, Omit<Model, 'id'>>({
      queryFn: async (postData) => {
        try {
          await ServiceTicketActionItemStatuses.postWithFiles(postData);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchTicketActionItemStatus: build.mutation<void, Model>({
      queryFn: async (patchData) => {
        try {
          await ServiceTicketActionItemStatuses.patchWithFiles(patchData);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_TAG },
        { type: REVALIDATE_TAG, id },
      ],
    }),
    deleteTicketActionItemStatus: build.mutation<void, Pick<Model, 'id'>>({
      queryFn: async (deleteData) => {
        try {
          await ServiceTicketActionItemStatuses.delete(deleteData);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_TAG },
        { type: REVALIDATE_TAG, id },
      ],
    }),
    normalizeOrderTicketActionItemStatuses: build.mutation<void, void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceTicketActionItemStatuses.getAllDynamic<Pick<Model, 'id'>>({
            select: dynamic.select('id', 'rank'),
            orderBy: dynamic.orderBy('rank', 'asc'),
          });

          for (let i = 0; i < value.length; i++) {
            const item = value[i];
            await ServiceTicketActionItemStatuses.patch({ ...item, rank: i + 1 });
          }

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    moveTicketActionItemStatus: build.mutation<
      void,
      { newRows: Partial<Model>[]; oldRows: Partial<Model>[] }
    >({
      queryFn: async ({ newRows, oldRows }) => {
        try {
          await ServiceTicketActionItemStatuses.moveRows(newRows, oldRows);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { newRows }) => [
        { type: REVALIDATE_TAG },
        ...newRows.map((item) => ({ type: REVALIDATE_TAG, id: item.id })),
      ],
    }),
  }),
});
