import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Box, BoxProps, IconButton, Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import style from './index.module.scss';

interface Props extends BoxProps {
  className?: string;
  photo?: string;
  fullName?: string;
  status?: string;
  onDelete?: () => void;
}
export const BaseTicketUser: React.FC<Props> = ({
  className,
  photo,
  fullName,
  onDelete,
  status,
  ...rest
}) => {
  return (
    <Box className={clsx(style.root, className)} {...rest}>
      <Avatar sx={{ width: 40, height: 40 }} src={photo} />
      <div className={style.wrap}>
        {status && (
          <Typography variant={'caption'} component={'div'} color={'text.secondary'}>
            {status}
          </Typography>
        )}
        <Typography component={'div'}>{fullName}</Typography>
      </div>
      <IconButton onClick={onDelete}>
        <CloseIcon color={'silver'} />
      </IconButton>
    </Box>
  );
};
