import { decoratorWithFiles } from 'utils/decorators';
import { DynamicService } from 'utils/service';
import {
  API_TICKET_ACTION_ITEM_IMAGES,
  AppTicketActionItemImage,
  ITicketActionItemImage,
} from './models';

export * from './models';

type Model = ITicketActionItemImage;

class Service extends DynamicService<Model> {
  @decoratorWithFiles('id', 'image')
  postItem(input: Omit<AppTicketActionItemImage, 'id'>) {
    return this.post(input as Model);
  }

  @decoratorWithFiles('id', 'image')
  updateItem(input: AppTicketActionItemImage) {
    return this.patch(input as Model);
  }
}

export const ServiceTicketActionItemImages = new Service({
  getAll: API_TICKET_ACTION_ITEM_IMAGES.GET_ALL_DYNAMIC,
  post: API_TICKET_ACTION_ITEM_IMAGES.POST,
  patch: API_TICKET_ACTION_ITEM_IMAGES.PATCH,
  delete: API_TICKET_ACTION_ITEM_IMAGES.DELETE,
});
