import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDataGridTasks } from '../../hooks';
import { DataGridTableProps } from '../data-grid-table';

interface ContextValue {
  moveRows: (dragIndex: number, hoverIndex: number) => void;
  endMoveRows: () => void;
}
export const DataGridDragAndDropContext = React.createContext<ContextValue | null>(null);

interface Props<T extends Record<string, any>> extends Pick<DataGridTableProps<T>, 'rows'> {
  children: (innerRows: T[]) => React.ReactNode;
}
const Component = <T extends Record<string, any>>({ rows, children }: Props<T>) => {
  const [innerRows, setInnerRows] = useState(rows);
  useEffect(() => {
    setInnerRows(rows);
  }, [rows]);

  const { onTask } = useDataGridTasks();

  const moveRows = useCallback((dragIndex: number, hoverIndex: number) => {
    setInnerRows((prevRows) => {
      const newRows = [...prevRows];
      newRows.splice(dragIndex, 1);
      newRows.splice(hoverIndex, 0, prevRows[dragIndex]);
      return newRows;
    });
  }, []);

  const endMoveRows = useCallback(() => {
    onTask({ type: 'move-rows', payload: { newRows: innerRows, oldRows: rows } });
  }, [rows, innerRows, onTask]);

  const value = useMemo(() => {
    return { moveRows, endMoveRows };
  }, [endMoveRows, moveRows]);
  return (
    <DataGridDragAndDropContext.Provider value={value}>
      {children(innerRows)}
    </DataGridDragAndDropContext.Provider>
  );
};

export const DataGridDragAndDropProvider = memo(Component) as typeof Component;
