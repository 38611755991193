import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Box,
  Collapse,
  DialogActions,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { ButtonAdd, ButtonCancel, ButtonCreate } from 'components/buttons';
import { DialogHeader, DialogHeaderProps } from 'components/dialog-header';
import { NativeScroll } from 'components/native-scroll';
import { useFieldProps, useTranslate } from 'hooks';
import React, { useEffect, useRef } from 'react';
import { Controller, useController, useFieldArray, useForm } from 'react-hook-form';
import { AppInput } from '../../../../../app-input';
import { BaseReportRadioField } from '../../../base-report-radio-field';
import { BaseReport, BaseReportList, schemaReportList } from '../../models';
import style from './index.module.scss';

interface Props {
  HeaderProps: Partial<DialogHeaderProps>;
  isInit: boolean;
  isLoading: boolean;
  reports: BaseReport[];
  initialValues?: Partial<BaseReportList>;
  onClose: () => void;
  onApply: (data: BaseReportList) => void;
  onAdd: () => void;
}
export const ContentReports: React.FC<Props> = ({
  HeaderProps,
  isInit,
  isLoading,
  reports,
  initialValues,
  onClose,
  onApply,
  onAdd,
}) => {
  const { tp } = useTranslate();

  const { control, handleSubmit, reset, getValues, setValue } = useForm({
    defaultValues: schemaReportList.cast({ ...initialValues }),
    resolver: yupResolver(schemaReportList),
  });
  const getFieldProps = useFieldProps();

  const { fields } = useFieldArray({ control, name: 'ticketActionItems' });

  const { field } = useController({ name: 'reportID', control });

  useEffect(() => {
    const payload = schemaReportList.cast(
      { ...getValues(), ...initialValues },
      { stripUnknown: true, assert: false },
    );

    reset(payload);
  }, [getValues, reset, initialValues]);

  const refSetDefReportID = useRef(false);
  useEffect(() => {
    if (reports.length !== 1) return;
    if (refSetDefReportID.current) return;

    setValue('reportID', String(reports[0].id));
    refSetDefReportID.current = true;
  }, [reports, setValue]);

  return (
    <>
      <DialogHeader
        title={tp('add-to-report')}
        {...HeaderProps}
        isLoading={isLoading || HeaderProps.isLoading}
      />
      <NativeScroll
        mode={'always'}
        sx={{
          paddingX: 2,
        }}
      >
        <Grid container spacing={3.2}>
          <Grid item md={5}>
            <Alert
              severity="info"
              action={<ButtonAdd size="small" onClick={onAdd} disabled={isLoading} />}
              sx={{ mb: 1 }}
            >
              {tp('report-list-select-or-add')}
            </Alert>
            <Collapse in={reports.length === 0 && isInit}>
              <Box mb={1}>
                <Alert severity={'warning'}>{tp('reports-list-empty')}</Alert>
              </Box>
            </Collapse>
            {reports.length > 0 && (
              <BaseReportRadioField
                value={field.value}
                onChange={field.onChange}
                disabled={isLoading}
                options={reports}
              />
            )}
          </Grid>
          <Grid item md={7}>
            <Stack
              divider={<Divider flexItem orientation={'horizontal'} className={style.divider} />}
              className={style.rootItem}
            >
              {fields.map((field, index) => {
                return (
                  <div className={style.item} key={field.id}>
                    <Controller
                      control={control}
                      name={`ticketActionItems.${index}.description` as const}
                      render={(renderProps) => {
                        return (
                          <AppInput
                            {...getFieldProps(renderProps)}
                            InputLabelProps={{ shrink: true }}
                            label={
                              <Typography
                                color={'#000'}
                                alignSelf={'center'}
                                fontWeight={500}
                                fontSize={'1.7rem'}
                                component={'h5'}
                              >
                                {field.title}
                              </Typography>
                            }
                            InputProps={{
                              classes: { root: style.itemContent },
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                );
              })}
            </Stack>
          </Grid>
        </Grid>
      </NativeScroll>
      <DialogActions
        sx={{
          pt: 2,
        }}
      >
        <ButtonCancel sx={{ minWidth: 100 }} onClick={onClose} />
        <ButtonCreate
          disabled={isLoading || !field.value}
          sx={{ minWidth: 100 }}
          onClick={handleSubmit(onApply)}
        >
          {tp('apply')}
        </ButtonCreate>
      </DialogActions>
    </>
  );
};
