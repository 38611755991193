import { LinearProgress, Stack, StackProps, Typography } from '@mui/material';
import { memo, useMemo } from 'react';
import { roundTo } from 'utils/app-helpers';
import style from './index.module.scss';

interface Props extends StackProps {
  value: number;
}
export const BaseProgress = memo<Props>(({ value: _value, ...rest }) => {
  const value = isNaN(_value) ? 0 : _value;

  const title = useMemo(() => {
    return roundTo(value, 2) + '%';
  }, [value]);

  return (
    <Stack spacing={1} direction={'row'} alignItems={'center'} justifyContent={'center'} {...rest}>
      <Typography variant={'body1'} fontWeight={500} lineHeight={1}>
        {title}
      </Typography>
      <LinearProgress
        classes={{ root: style.root }}
        sx={{ flexGrow: 1, height: 6, borderRadius: 3 }}
        variant={'determinate'}
        color={'success'}
        value={value}
      />
    </Stack>
  );
});
