import EditIcon from '@mui/icons-material/Edit';
import { Collapse, FormControl, FormHelperText, IconButton, InputAdornment } from '@mui/material';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import Typography, { TypographyProps } from '@mui/material/Typography';
import clsx from 'clsx';
import { useOpen } from 'hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getHandlerKayPress } from 'utils/handlers';
import { composeFunctions } from 'utils/other';
import { Unset } from 'utils/types';
import style from './index.module.scss';

export interface EditableTypographyProps {
  placeholder?: string;
  error?: boolean;
  helperText?: React.ReactNode;
  disabled?: boolean;
  value: Unset;
  onChange?: (value: string) => void;
}

const InputBaseWithChildren: React.FC<InputBaseProps & { children?: React.ReactNode }> = ({
  children,
  ...props
}) => {
  let value = '';
  if (children) {
    if (typeof children == 'string' || typeof children == 'number') {
      value = children.toString();
    }
  }

  return <InputBase {...props} value={value} />;
};

/**
 * Displaying like a `Typography`. But acting as an `input`
 */
export const AppInputTitle: React.FC<
  EditableTypographyProps & Omit<TypographyProps, 'children'>
> = ({ onChange, error, disabled, helperText, value, ...props }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [internalValue, setInternalValue] = useState('');

  useEffect(() => {
    setInternalValue(String(value || ''));
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInternalValue(e.target.value);
  };

  const stateEdit = useOpen();

  const isValid = !!internalValue.trim();
  const isEdit = stateEdit.isOpen || !isValid || !!error;

  const onClickEdit = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const onApply = useCallback(() => {
    if (internalValue) {
      onChange && onChange(internalValue);
    }
  }, [onChange, internalValue]);

  const onEnter = useCallback(() => {
    inputRef.current?.blur();
  }, []);

  return (
    <FormControl
      error={error}
      fullWidth
      className={clsx(style.root, isEdit && style.edit, error && style.error)}
    >
      <Typography
        fullWidth
        variant={'subtitle1'}
        color={'primary'}
        {...props}
        className={clsx(style.field, props.className)}
        children={isEdit ? internalValue : value}
        component={InputBaseWithChildren}
        onChange={handleChange}
        onFocus={stateEdit.onOpen}
        onBlur={composeFunctions(stateEdit.onClose, onApply)}
        onKeyUp={getHandlerKayPress('Enter', onEnter)}
        inputRef={inputRef}
        disabled={disabled}
        endAdornment={
          <InputAdornment className={style.adornment} position={'end'}>
            <IconButton onClick={onClickEdit} disabled={disabled} color={'inherit'} size={'small'}>
              <EditIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      <Collapse in={!!helperText}>
        <FormHelperText>{helperText}</FormHelperText>
      </Collapse>
    </FormControl>
  );
};
