import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import * as yup from 'yup';
import { DateValue } from '../../utils/dates';
import { schemaNotificationUserProfile } from '../notification-user-profile';
import { schemaNotification } from '../notifications';

export const schemaAppNotificationPost = schemaNotification
  .pick(['reporterUserCrmProfileID', 'message', 'ticketActionItemID', 'ticketActionItemCommentID'])
  .concat(schemaNotificationUserProfile.pick(['urgent']))
  .shape({
    userCrmProfileIDs: yup
      .array()
      .of(yup.string().required('rule-required'))
      .required('rule-required')
      .min(1, 'rule-required')
      .default([]),
  });

export interface IAppNotificationPostInput
  extends yup.InferType<typeof schemaAppNotificationPost> {}

export enum APP_NOTIFICATION_TYPE {
  REQUESTS = 'requests',
  COMMENTS = 'comments',
  ACTIONS = 'actions',
}

export const getNotificationActionType = (
  notification: Pick<
    Components.Schemas.Notification,
    'ticketActionItemID' | 'ticketActionItemCommentID'
  >,
) => {
  if (notification.ticketActionItemCommentID) {
    return APP_NOTIFICATION_TYPE.COMMENTS;
  }
  if (notification.ticketActionItemID) {
    return APP_NOTIFICATION_TYPE.ACTIONS;
  }
  return APP_NOTIFICATION_TYPE.REQUESTS;
};

export const getNotificationIcon = (type: APP_NOTIFICATION_TYPE) => {
  switch (type) {
    case APP_NOTIFICATION_TYPE.COMMENTS: {
      return ChatBubbleOutlineIcon;
    }
    case APP_NOTIFICATION_TYPE.ACTIONS: {
      return AssignmentOutlinedIcon;
    }
    default: {
      return NotificationImportantOutlinedIcon;
    }
  }
};

export const isNotificationView = (type: APP_NOTIFICATION_TYPE) => {
  return [APP_NOTIFICATION_TYPE.ACTIONS, APP_NOTIFICATION_TYPE.COMMENTS].some((t) => t === type);
};

export interface AppNotificationLatest
  extends Pick<
    Components.Schemas.NotificationUserProfile,
    'id' | 'notificationID' | 'urgent' | 'done' | 'userCrmProfileID'
  > {
  notification: Pick<
    Components.Schemas.Notification,
    | 'message'
    | 'entryDate'
    | 'ticketActionItemID'
    | 'ticketActionItemCommentID'
    | 'reporterUserCrmProfileID'
  >;
  itemKey: string | null;
  commentItemKey: string | null;
}

export interface AppNotificationGridInput {
  search: string;
  appUserID: string;
  date: DateValue[];
  type: null | APP_NOTIFICATION_TYPE;
  skip: number;
  take: number;
}
export interface AppNotificationGridPersonalInput {
  search: string;
  appUserID: string;
  date: DateValue[];
  type: null | APP_NOTIFICATION_TYPE;
  skip: number;
  take: number;
}
