import { FixServerObject } from 'utils/types';

const API = 'ProjectTypes';

export const API_PROJECT_TYPES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ProjectType, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ProjectType, 'id'>) => `${API}/Delete/${data.id}`,
};
export class ProjectType {
  id!: string;
  title = '';
  isActive = true;
  rank = 0;
  color = '';
}

export interface IProjectType
  extends FixServerObject<
    Components.Schemas.ProjectType,
    'id' | 'title' | 'isActive' | 'rank' | 'color'
  > {}
