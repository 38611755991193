import { useSourceCustomers, useTranslate } from 'hooks';
import React from 'react';
import { Unset } from 'utils/types';
import { AppSelect } from '../app-select';
import { DialogHeader } from '../dialog-header';
import style from './index.module.scss';

export interface DialogHeaderCustomerProps {
  value: Unset;
  onChange: (value: this['value']) => void;
  disabled?: boolean;
  onClose?: () => void;
}

export const DialogHeaderCustomer: React.FC<DialogHeaderCustomerProps> = ({
  value,
  disabled,
  onChange,
  onClose,
}) => {
  const sourceCustomer = useSourceCustomers();
  const customer = value ? sourceCustomer.map[value] : null;
  const { tp } = useTranslate();

  return (
    <DialogHeader bColor={customer?.color} onClose={onClose}>
      <AppSelect
        className={style.select}
        value={value}
        onChange={onChange}
        RenderInputProps={{
          inputProps: {
            style: { fontSize: 20 },
          },
        }}
        sx={{
          flexGrow: 1,
          ml: 2,
        }}
        label={null}
        placeholder={tp('select-customer')}
        options={sourceCustomer.data}
        loading={sourceCustomer.isLoading}
        disabled={disabled}
      />
    </DialogHeader>
  );
};
