import { TabsPage } from 'components/tabs-page';
import { APP_ROUTES } from 'configs';
import { useTranslate } from 'hooks';
import { memo, useMemo } from 'react';

export const ExpensesLayout = memo((props) => {
  const { tp } = useTranslate();
  const tabs = useMemo(() => {
    return [
      {
        value: APP_ROUTES.EXPENSES.path,
        to: APP_ROUTES.EXPENSES().config,
        label: tp('expenses'),
      },
      {
        value: APP_ROUTES.SUPPLIERS.path,
        to: APP_ROUTES.SUPPLIERS().config,
        label: tp('suppliers'),
      },
      {
        value: APP_ROUTES.CATEGORIES.path,
        to: APP_ROUTES.CATEGORIES().config,
        label: tp('categories'),
      },
    ];
  }, [tp]);
  return <TabsPage tabs={tabs} />;
});
