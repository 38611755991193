import { decoratorWithFiles } from 'utils/decorators';
import { DynamicService } from 'utils/service';
import { API_NOTE_IMAGES, AppNoteImage, INoteImage } from './models';

export * from './models';

type Model = INoteImage;

class Service extends DynamicService<Model> {
  @decoratorWithFiles('id', 'image')
  postItem(input: Omit<AppNoteImage, 'id'>) {
    return this.post(input as Model);
  }

  @decoratorWithFiles('id', 'image')
  updateItem(input: AppNoteImage) {
    return this.patch(input as Model);
  }
}

export const ServiceNoteImages = new Service({
  getAll: API_NOTE_IMAGES.GET_ALL_DYNAMIC,
  post: API_NOTE_IMAGES.POST,
  patch: API_NOTE_IMAGES.PATCH,
  delete: API_NOTE_IMAGES.DELETE,
});
