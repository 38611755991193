import { decoratorRank } from 'utils/decorators';
import * as dynamic from 'utils/dynamic';
import { apiRtk, behaviourMoveRows, DynamicResult, DynamicService } from 'utils/service';
import { PatchPartial } from '../../utils/types';
import { API_TIME_TRACKING_ACTIVITIES, ITrackingActivity, ITrackingActivityArgs } from './models';

export * from './models';

type Model = ITrackingActivity;

const REVALIDATE_TAG = 'TimeTrackingActivities' as const;

class Service extends DynamicService<Model> {
  @decoratorRank('rank')
  async post(data: Omit<Model, 'id'>) {
    return super.post(data);
  }
  moveRows = async (newRows: Partial<Model>[], oldRows: Partial<Model>[]) => {
    return behaviourMoveRows({
      mainField: 'id',
      moveField: 'rank',
      newRows,
      oldRows,
      requestPatch: this.patch,
    });
  };
}

interface SourceModel extends Pick<ITrackingActivity, 'id' | 'title' | 'titleHeb'> {}

export const ServiceTimeTrackingActivities = new Service({
  getAll: API_TIME_TRACKING_ACTIVITIES.GET_ALL_DYNAMIC,
  post: API_TIME_TRACKING_ACTIVITIES.POST,
  patch: API_TIME_TRACKING_ACTIVITIES.PATCH,
  delete: API_TIME_TRACKING_ACTIVITIES.DELETE,
});

export const apiTimeTrackingActivities = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getTimeTrackingActivitiesSource: build.query<SourceModel[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceTimeTrackingActivities.getAllDynamic({
            select: dynamic.select('id', 'title', 'titleHeb'),
            orderBy: dynamic.orderBy('rank', 'asc'),
            filter: dynamic
              .mergeFilters(dynamic.makeFilter('isActive', true, dynamic.equals))
              .join('&&'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getTimeTrackingActivities: build.query<
      DynamicResult<ITrackingActivity, { count: true }>,
      ITrackingActivityArgs
    >({
      query: ({ search, orderBy, take, skip }) => ({
        url: API_TIME_TRACKING_ACTIVITIES.GET_ALL_DYNAMIC,
        params: {
          filter: dynamic
            .mergeFilters(
              dynamic.makeFilter(
                ['title', 'titleHeb'],
                search,
                dynamic.decoratorIsNotNullable(dynamic.contains),
              ),
            )
            .join('&&'),
          select: dynamic.select('id', 'title', 'titleHeb', 'isActive', 'isAbsence', 'rank'),
          take,
          skip,
          orderBy: dynamic.orderBy(orderBy),
          count: true,
        },
      }),
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getTimeTrackingActivityItem: build.query<ITrackingActivity, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServiceTimeTrackingActivities.getDynamic(id);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    postTimeTrackingActivity: build.mutation<void, Omit<ITrackingActivity, 'id'>>({
      queryFn: async (postData) => {
        try {
          await ServiceTimeTrackingActivities.post(postData);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchTimeTrackingActivity: build.mutation<void, PatchPartial<ITrackingActivity, 'id'>>({
      queryFn: async (patchData) => {
        try {
          await ServiceTimeTrackingActivities.patch(patchData);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_TAG },
        { type: REVALIDATE_TAG, id },
      ],
    }),
    deleteTimeTrackingActivity: build.mutation<void, Pick<ITrackingActivity, 'id'>>({
      queryFn: async (deleteData) => {
        try {
          await ServiceTimeTrackingActivities.delete(deleteData);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_TAG },
        { type: REVALIDATE_TAG, id },
      ],
    }),
    normalizeTimeTrackingActivities: build.mutation<void, void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceTimeTrackingActivities.getAllDynamic<Pick<Model, 'id'>>({
            select: dynamic.select('id', 'rank'),
            orderBy: dynamic.orderBy('rank', 'asc'),
          });

          for (let i = 0; i < value.length; i++) {
            const item = value[i];
            await ServiceTimeTrackingActivities.patch({ ...item, rank: i + 1 });
          }

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    moveTimeTrackingActivities: build.mutation<
      void,
      { newRows: Partial<Model>[]; oldRows: Partial<Model>[] }
    >({
      queryFn: async ({ newRows, oldRows }) => {
        try {
          await ServiceTimeTrackingActivities.moveRows(newRows, oldRows);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { newRows }) => [
        { type: REVALIDATE_TAG },
        ...newRows.map((item) => ({ type: REVALIDATE_TAG, id: item.id })),
      ],
    }),
  }),
});
