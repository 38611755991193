import RestoreIcon from '@mui/icons-material/Restore';
import { Button, ButtonProps, Tooltip } from '@mui/material';
import { useTranslate } from 'hooks';
import React, { useMemo } from 'react';

interface Props extends ButtonProps {}

export const ButtonRestore: React.FC<Props> = ({ title, children, ...rest }) => {
  const { tp } = useTranslate();
  const _title = useMemo(() => {
    if (children === tp('restore')) {
      return tp('restore-tooltip');
    }
  }, [children, tp]);
  return (
    <Tooltip title={title || _title}>
      <Button variant={'contained'} startIcon={<RestoreIcon />} {...rest}>
        {children || tp('restore')}
      </Button>
    </Tooltip>
  );
};
