import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'DoneItems';

export const API_DONE_ITEMS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  CREATE: `${API}/Create`,
  PATCH: (data: Pick<IDoneItem, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<IDoneItem, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface IGetAllDoneItem
  extends FixServerObject<
    Components.Schemas.DoneItem,
    'id' | 'userCrmProfileID' | 'duration' | 'billableDuration' | 'description'
  > {}

export interface IDoneItem
  extends FixServerObject<
    Components.Schemas.DoneItem,
    'id' | 'userCrmProfileID' | 'duration' | 'billableDuration' | 'description'
  > {}

export interface IGetDoneItem extends IDoneItem {
  userCrmProfileFullName: Components.Schemas.UserCrmProfile['fullName'];
}
export const schemaDoneItemFull = yup.object({
  userCrmProfileID: yup.string().required('rule-required').default(''),
  timeTrackingID: yup.string().required('rule-required').default(''),
  duration: yup.string().required('rule-required').duration('rule-duration').default(''),
  billableDuration: yup.string().required('rule-required').duration('rule-duration').default(''),
  description: yup.string().nullable().notRequired().default(''),
  date: yup.string().default(''),
  userName: yup.string().required('rule-required').trim().default(''),
  projectName: yup.string().required('rule-required').trim().default(''),
  tag: yup.string().trim().default(''),
});
export const schemaDoneItem = schemaDoneItemFull.pick([
  'userCrmProfileID',
  'timeTrackingID',
  'duration',
  'billableDuration',
  'description',
  'date',
]);

export interface PatchDoneItemBulkInput {
  ids: string[];
  data: Partial<IDoneItem>;
}
