import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'lodash-es';
import { apiPriorities } from './api';

const selectSource = apiPriorities.endpoints.getPrioritiesAll.select();
export const selectSourcePrioritiesAll = createSelector(selectSource, (res) => {
  return res.data || [];
});
export const selectSourcePrioritiesAllMap = createSelector(selectSourcePrioritiesAll, (users) => {
  return keyBy(users, 'id');
});
