import { ValueFileUploader } from 'utils/file-uploader';
import { FixServerObject } from 'utils/types';

const API = 'NoteImages';

export const API_NOTE_IMAGES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<INoteImage, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<INoteImage, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface INoteImage
  extends FixServerObject<Components.Schemas.NoteImage, 'id' | 'noteID'> {}

export interface AppNoteImage extends Omit<INoteImage, 'image'> {
  image: ValueFileUploader;
}
