import { createSelector } from '@reduxjs/toolkit';
import { groupBy } from 'lodash-es';
import { calcTrackingData } from 'services/ticket-action-items/helpers';
import { selectSourceCrmUsersAllMap } from 'services/user-crm-profiles';
import { isAcrossIntervals } from 'utils/dates';
import {
  selectCheckDoneTicketsSelectedID,
  selectTicketsDataFiltered,
  selectTicketsDataFilteredMap,
  selectTicketsDataFullMap,
} from '../check-done-tickets';
import { AppState } from '../index';

const selectState = (state: AppState) => state.checkDoneAll;

export const selectCheckDoneAllStatus = createSelector(
  selectState,
  ({ isLoading, error, isInit }) => {
    return { isLoading, error, isInit };
  },
);

export const selectCheckDoneAllFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});

export const selectCheckDoneAllHasFilters = createSelector(selectCheckDoneAllFilters, (filters) => {
  return [
    filters.search,
    filters.reporterUserCrmProfileID,
    filters.assigneeUserCrmProfileIDs.length,
    filters.dateRange.length,
    filters.ticketActionPriorityID,
    filters.ticketActionStatusID,
  ].some(Boolean);
});

export const selectCheckDoneAllData = createSelector(selectState, ({ data }) => {
  return data;
});

const selectCheckDoneAllDataFull = createSelector(
  selectCheckDoneAllData,
  selectSourceCrmUsersAllMap,
  (ticketItems, mapUsers) => {
    return ticketItems.map((ticketItem) => {
      const { durationInMinutes, countAttached, count } = calcTrackingData(ticketItem.tracking);
      return {
        ...ticketItem,
        _reporter: mapUsers[ticketItem.reporterUserCrmProfileID],
        _assigner: mapUsers[ticketItem.assigneeUserCrmProfileID],
        _timeTrackingInMinutes: durationInMinutes,
        _timeTrackingAttachedCount: countAttached,
        _timeTrackingCount: count,
      };
    });
  },
);

const selectCheckDoneAllDataFiltered = createSelector(
  selectCheckDoneAllDataFull,
  selectCheckDoneAllFilters,
  selectCheckDoneTicketsSelectedID,
  (items, filters, selectedTicketID) => {
    const {
      search,
      reporterUserCrmProfileID,
      assigneeUserCrmProfileIDs,
      dateRange,
      ticketActionPriorityID,
      ticketActionStatusID,
    } = filters;

    return items.filter((item) => {
      const res: boolean[] = [true];

      if (selectedTicketID) {
        res.push(item.ticketID === selectedTicketID);
      }

      if (search) {
        res.push(
          [
            item.title.includes(search),
            item._reporter?.fullName.includes(search),
            item._assigner?.fullName.includes(search),
            item.itemKey?.includes(search),
          ].some(Boolean),
        );
      }

      if (reporterUserCrmProfileID) {
        res.push(reporterUserCrmProfileID === item.reporterUserCrmProfileID);
      }

      if (assigneeUserCrmProfileIDs.length) {
        res.push(assigneeUserCrmProfileIDs.some((id) => id === item.assigneeUserCrmProfileID));
      }

      if (ticketActionPriorityID) {
        res.push(ticketActionPriorityID === item.priorityID);
      }

      if (ticketActionStatusID) {
        res.push(ticketActionStatusID === item.ticketActionItemStatusID);
      }

      if (dateRange.length === 2) {
        res.push(isAcrossIntervals([item.startDateAndTime, item.endDateAndTime], dateRange));
      }

      return res.every(Boolean);
    });
  },
);

const selectTicketsActionItemsDataFilteredByTickets = createSelector(
  selectCheckDoneAllDataFiltered,
  selectTicketsDataFilteredMap,
  (ticketActionItems, ticketsMap) => {
    return ticketActionItems.filter((ticketActionItem) => {
      return !!ticketsMap[ticketActionItem.ticketID];
    });
  },
);

export const selectCheckDoneAllDataGroupByAssignerAndTicket = createSelector(
  selectTicketsActionItemsDataFilteredByTickets,
  selectTicketsDataFullMap,
  (ticketActionItems, ticketsMap) => {
    const groups = groupBy(ticketActionItems, 'assigneeUserCrmProfileID');

    return Object.entries(groups).map(([assigneeUserCrmProfileID, groupByAssigner]) => {
      const firstItem = groupByAssigner[0];
      const groupsByTickets = groupBy(groupByAssigner, 'ticketID');

      return {
        id: assigneeUserCrmProfileID,
        assigner: firstItem._assigner,
        tickets: Object.entries(groupsByTickets).map(([ticketID, groupByTicket]) => {
          const ticketActionItems = Object.values(groupByTicket);
          const completedActionItems = ticketActionItems.filter(({ done }) => done);
          return {
            id: ticketID,
            ticket: ticketsMap[ticketID],
            ticketActionItems,
            progress: (completedActionItems.length / ticketActionItems.length) * 100,
          };
        }),
      };
    });
  },
);

export const selectCheckDoneAllWithActionItems = createSelector(
  selectTicketsDataFiltered,
  selectCheckDoneAllDataFiltered,
  (tickets, actionItems) => {
    const groupOfActionItems = groupBy(actionItems, 'ticketID');

    return tickets.map((ticket) => {
      const actionItems = groupOfActionItems[ticket.id] || [];
      const completedActionItems = actionItems.filter(({ done }) => done);

      return {
        ...ticket,
        _actionItems: actionItems,
        _progress: (completedActionItems.length / actionItems.length) * 100,
      };
    });
  },
);

export const selectCheckDoneAllTicketsWithActionItemsList = createSelector(
  selectCheckDoneAllWithActionItems,
  selectCheckDoneAllHasFilters,
  selectCheckDoneTicketsSelectedID,
  (tickets, hasFilters, selectedTicketID) => {
    if (!hasFilters && !selectedTicketID) {
      return tickets;
    }
    return tickets.filter((ticket) => ticket._actionItems.length);
  },
);

export const selectCheckDoneAllView = createSelector(
  selectCheckDoneAllFilters,
  ({ assigneeUserCrmProfileIDs }) => {
    if (assigneeUserCrmProfileIDs.length > 1) {
      return 'board';
    }
    return 'list';
  },
);
