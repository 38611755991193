import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TodayIcon from '@mui/icons-material/Today';
import { IconButton, IconButtonProps, Popover, Stack } from '@mui/material';
import { AppInput, AppInputProps } from 'components/app-input';
import { usePickerDefaultShortcuts } from 'components/date-picker-range/hooks';
import { Item, MenuButton } from 'components/menu-button';
import { format } from 'date-fns';
import { useMergeRef, useOpen } from 'hooks';
import { isEqual } from 'lodash-es';
import React, {
  ForwardedRef,
  forwardRef,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { convertToDate, DateValue, isDateLike } from 'utils/dates';
import { composeFunctions } from 'utils/other';
import { CalendarRangePicker, CalendarRangePickerProps } from '../calendar-range-picker';

const DEFAULT_INPUT_FORMAT = 'dd/MM/yyyy';

interface IStrategyButton {
  (direction: 'next' | 'prev', currentValue: any[]): Date[];
}
interface Classes {
  root: string;
}

export interface Shortcut<T extends string = any> {
  value: T;
  title: React.ReactNode;
  makePickerValue: () => Date[];
  buttonsStrategy?: IStrategyButton;
  renderInputValue?: (currentValue: DateValue[]) => string;
}

interface Props {
  value: any[];
  onChange?: (value: Date[]) => void;
  onApply?: (value: Date[]) => void;
  closeOnApply?: boolean;
  className?: string;
  classes?: Partial<Classes>;
  inputFormat?: string;
  inputDivider?: string;
  isOpenPicker?: boolean;
  CalendarProps?: Partial<Omit<CalendarRangePickerProps, 'value' | 'onChange'>>;
  NextButtonProps?: Partial<Omit<IconButtonProps, 'onClick'>>;
  PrevButtonProps?: Partial<Omit<IconButtonProps, 'onClick'>>;
  shortcuts?: Shortcut[];
}

const defOnChange = () => {};

const Component = (
  {
    value: originValue,
    inputFormat = DEFAULT_INPUT_FORMAT,
    inputDivider = ' - ',
    onChange = defOnChange,
    closeOnApply = true,
    onApply,
    isOpenPicker,
    CalendarProps,
    NextButtonProps,
    PrevButtonProps,
    shortcuts: _shortcuts,
    ...rest
  }: Props & Omit<AppInputProps, 'value' | 'onChange' | 'onClick'>,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const defShortcuts = usePickerDefaultShortcuts();
  const shortcuts = _shortcuts || defShortcuts;

  const [shortcutValue, onChangeShortcutValue] = useState();
  const onClearShortcut = useCallback(() => {
    onChangeShortcutValue(undefined);
  }, []);

  const shortcut = useMemo(() => {
    return shortcuts.find(
      (shortcut) =>
        isEqual(shortcut.value, shortcutValue) ||
        isEqual(shortcut.makePickerValue().map(convertToDate), originValue.map(convertToDate)),
    );
  }, [shortcuts, shortcutValue, originValue]);

  const refTextField = useRef<HTMLInputElement>(null);
  const { isOpen, onOpen, onClose } = useOpen(isOpenPicker);
  const onClickOpen = composeFunctions(onOpen, onClearShortcut);

  const defaultInputRender = useCallback(
    (currentValue: DateValue[]) => {
      return currentValue
        .filter(isDateLike)
        .map((v) => format(convertToDate(v), inputFormat))
        .join(inputDivider);
    },
    [inputFormat, inputDivider],
  );

  const inputValue = useMemo(() => {
    if (shortcut?.renderInputValue) {
      return shortcut.renderInputValue(originValue);
    }
    return defaultInputRender(originValue);
  }, [originValue, shortcut, defaultInputRender]);

  const checkApply = useCallback(
    (value: Date[]) => {
      if (value.length === 2) {
        onApply && onApply(value);
        closeOnApply && onClose();
      }
    },
    [onApply, onClose, closeOnApply],
  );

  const onClear = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onChange([]);
      onClearShortcut();
      onApply && onApply([]);
    },
    [onChange, onApply, onClearShortcut],
  );

  const strategyButton = useMemo(() => {
    return shortcut?.buttonsStrategy;
  }, [shortcut]);

  const factoryClickButton = useCallback(
    (direction: 'next' | 'prev') => {
      if (!strategyButton) {
        return undefined;
      }
      return () => {
        const _value = strategyButton(direction, originValue);
        onChange(_value);
        checkApply(_value);
      };
    },
    [originValue, strategyButton, checkApply, onChange],
  );

  const items = useMemo<Item[]>(() => {
    return shortcuts.map((sc) => ({
      title: sc.title,
      selected: sc.value === shortcut?.value,
      onClick: () => {
        const value = sc.makePickerValue();
        onChange(value);
        onApply && onApply(value);
        onChangeShortcutValue(sc.value);
      },
    }));
  }, [onChange, onApply, shortcuts, onChangeShortcutValue, shortcut]);

  return (
    <>
      <Stack
        display={'inline-flex'}
        direction={'row'}
        spacing={0.1}
        alignItems={rest.variant === 'outlined' ? 'center' : 'flex-end'}
        justifyContent={'center'}
        width={'100%'}
      >
        <IconButton
          size={'small'}
          children={<ArrowBackIosNewIcon sx={{ fontSize: '1.6rem' }} />}
          color={'primary'}
          sx={{
            padding: '0.7rem',
          }}
          {...PrevButtonProps}
          onClick={factoryClickButton('prev')}
          disabled={!strategyButton || rest.disabled || PrevButtonProps?.disabled}
        />
        <AppInput
          variant={'standard'}
          value={inputValue}
          fullWidth={true}
          {...rest}
          ref={useMergeRef(ref, refTextField)}
          InputProps={{
            endAdornment: (
              <>
                {shortcuts && shortcuts.length > 0 && (
                  <MenuButton items={items}>
                    <IconButton
                      disabled={rest.disabled}
                      size={'small'}
                      color={shortcut ? 'primary' : undefined}
                    >
                      <FormatListBulletedIcon sx={{ fontSize: '1.6rem' }} />
                    </IconButton>
                  </MenuButton>
                )}

                <IconButton
                  disabled={rest.disabled}
                  size={'small'}
                  sx={{ margin: '0 -0.8rem 0 0' }}
                  onClick={rest.disabled ? undefined : onClickOpen}
                  color={shortcut ? 'primary' : undefined}
                >
                  <TodayIcon sx={{ fontSize: '1.6rem' }} />
                </IconButton>
              </>
            ),
            ...rest.InputProps,
          }}
          inputProps={{
            ...rest.inputProps,
            onClick: rest.disabled ? undefined : onClickOpen,
          }}
          onClear={composeFunctions(onClear, rest.onClear)}
        />
        <IconButton
          size={'small'}
          children={<ArrowForwardIosIcon sx={{ fontSize: '1.6rem' }} />}
          color={'primary'}
          sx={{
            padding: '0.7rem',
          }}
          {...NextButtonProps}
          disabled={!strategyButton || rest.disabled || NextButtonProps?.disabled}
          onClick={factoryClickButton('next')}
        />
      </Stack>
      <Popover
        anchorEl={refTextField.current}
        open={isOpen}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <CalendarRangePicker
          value={originValue}
          onChange={composeFunctions(onChange, checkApply)}
          {...CalendarProps}
        />
      </Popover>
    </>
  );
};

export const DateRangeMultiplePicker = memo(forwardRef(Component)) as typeof Component;
