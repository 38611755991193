import { yupResolver } from '@hookform/resolvers/yup';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ReorderIcon from '@mui/icons-material/Reorder';
import {
  Box,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { AppInput } from 'components/app-input';
import { AppTimeDuration } from 'components/app-time-duration';
import { ButtonCancel, ButtonCreate } from 'components/buttons';
import { DialogHeader } from 'components/dialog-header';
import { getUserDetailsOptionLabel } from 'components/render-option';
import { SelectEmployees } from 'components/select-employees';
import { TIME_FORMAT_DATE } from 'configs/const';
import { useEffectNotifyError, useFieldProps, useTranslate } from 'hooks';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { apiDoneItems, schemaDoneItem } from 'services/done-items';
import { apiReportDoneItems } from 'services/report-done-items';
import { IGetAllReport } from 'services/reports';
import { formatDate, isDateLike } from 'utils/dates';
import { isFulfilledMutation } from 'utils/service';
import { InferType } from 'yup';
import { useCheckDoneFetchItem } from '../../hooks/use-check-done-fetch-item';
import style from './index.module.scss';

const usePostDoneItemMutation = apiDoneItems.usePostDoneItemMutation;
const usePatchDoneItemMutation = apiDoneItems.usePatchDoneItemMutation;
const usePostReportDoneItemMutation = apiReportDoneItems.usePostReportDoneItemMutation;

const schema = schemaDoneItem;

export type AddToReportItemFormModel = InferType<typeof schema>;

interface DefaultValues {
  duration?: string;
  billableDuration?: string;
  userCrmProfileID?: string;
  description?: string | null;
}

interface AddToReportItemFormProps {
  onClose: () => void;
  onDone: () => void;
  report: IGetAllReport | null;
  timeTrackingID: string | null;
  defaultValues: DefaultValues;
  itemID: string | undefined;
  ticketActionItemID: string | undefined | null;
}
export const AddToReportItemForm = memo<AddToReportItemFormProps>(
  ({ onClose, onDone, report, timeTrackingID, defaultValues, itemID, ticketActionItemID }) => {
    const { tp } = useTranslate();
    const checkDoneFetchItem = useCheckDoneFetchItem(ticketActionItemID);
    const getFieldProps = useFieldProps();
    const methods = useForm({
      defaultValues: schema.cast({ timeTrackingID }),
      resolver: yupResolver(schema),
    });

    const { control, handleSubmit, reset, register } = methods;

    const [postDoneItem, resultDoneItemPost] = usePostDoneItemMutation();
    useEffectNotifyError(resultDoneItemPost.error);

    const [patchDoneItem, resultDoneItemPatch] = usePatchDoneItemMutation();
    useEffectNotifyError(resultDoneItemPatch.error);

    const [postReportDoneItem, resultReportDoneItemPost] = usePostReportDoneItemMutation();
    useEffectNotifyError(resultReportDoneItemPost.error);

    const onSubmit = useCallback(
      async (formData: AddToReportItemFormModel) => {
        const result = await (itemID
          ? patchDoneItem({ ...formData, id: itemID, description: formData.description ?? '' })
          : postDoneItem({ ...formData, description: formData.description ?? '' }));

        const doneItemID = isFulfilledMutation(result) ? result.data.id : itemID;

        if (report?.id && (itemID || (isFulfilledMutation(result) && result?.data?.id))) {
          await postReportDoneItem({
            doneItemID: String(doneItemID),
            reportID: report.id,
          });

          checkDoneFetchItem();
        }

        if (isFulfilledMutation(result)) {
          onDone();
        }
      },
      [postDoneItem, onDone, postReportDoneItem, report, patchDoneItem, itemID, checkDoneFetchItem],
    );

    const isLoading =
      resultDoneItemPost.isLoading ||
      resultReportDoneItemPost.isLoading ||
      resultDoneItemPatch.isLoading;

    useEffect(() => {
      reset(defaultValues);
    }, [reset, defaultValues]);

    const date = useMemo(
      () =>
        [report?.fromDate, report?.toDate]
          .filter(isDateLike)
          .map((v) => formatDate(v, TIME_FORMAT_DATE))
          .join(' - '),
      [report],
    );

    return (
      <>
        <DialogHeader
          title={
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <ReorderIcon />
              <div>{tp('add-to-report')}</div>
            </Stack>
          }
          onClose={onClose}
        />
        <DialogContent>
          <input {...register('timeTrackingID')} hidden />
          {report && (
            <Box padding={'2rem 0'}>
              <Typography className={style.date}>{date}</Typography>
              <Typography>{report.reportName}</Typography>
            </Box>
          )}
          <Grid container rowSpacing={0.2} columnSpacing={2.8}>
            <Grid item xs={4}>
              <Controller
                control={control}
                name={'duration'}
                render={(renderProps) => (
                  <AppTimeDuration
                    {...getFieldProps(renderProps)}
                    disabled={isLoading}
                    disableClearable
                    RenderInputProps={{
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position={'end'}>
                            <AccessTimeIcon color={'silver'} />
                          </InputAdornment>
                        ),
                      },
                    }}
                    sx={{ minWidth: 125 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name={'billableDuration'}
                render={(renderProps) => (
                  <AppTimeDuration
                    {...getFieldProps(renderProps)}
                    disabled={isLoading}
                    disableClearable
                    RenderInputProps={{
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position={'end'}>
                            <AccessTimeIcon color={'silver'} />
                          </InputAdornment>
                        ),
                      },
                    }}
                    sx={{ minWidth: 125 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name={'userCrmProfileID'}
                render={(renderProps) => (
                  <SelectEmployees
                    {...getFieldProps(renderProps)}
                    getOptionLabel={getUserDetailsOptionLabel}
                    disabled={isLoading}
                    disableClearable
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={'description'}
                render={(renderProps) => (
                  <AppInput {...getFieldProps(renderProps)} disabled={isLoading} />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={style.actions}>
          <ButtonCancel onClick={onClose} color={'primary'} />
          <ButtonCreate
            title={tp('add-tooltip')}
            color={'primary'}
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {tp('add')}
          </ButtonCreate>
        </DialogActions>
      </>
    );
  },
);
