import {
  AxiosInterceptorRefreshToken,
  AxiosInterceptorRefreshTokenCheckIsRefreshCase,
  AxiosInterceptorRefreshTokenError,
  AxiosInterceptorRefreshTokenRefresh,
  AxiosInterceptorRefreshTokenSuccess,
} from 'components/axios/refresh-token';
import { AxiosInterceptorToken } from 'components/axios/token';
import { useAppDispatch } from 'hooks';
import React, { memo, useCallback } from 'react';
import { ServiceAccounts } from 'services/accounts';
import { actionAccountClear } from 'store/auth';
import { getAuthTokens, updateAuthTokens } from 'utils/cookies';
import { apiApp, isAxiosError } from 'utils/service';
import { AxiosInterceptorPatch } from './patch';
import { AxiosInterceptorPermissionError } from './permission-error';
import { AxiosInterceptorPost } from './post';

const instances = [apiApp];
const getToken = () => {
  const { token } = getAuthTokens();

  if (!token) {
    return undefined;
  }
  return { token };
};

interface Props {
  children: React.ReactNode;
}

export const AxiosInterceptorsProvider = memo<Props>(({ children }) => {
  const dispatch = useAppDispatch();
  const refresh = useCallback<AxiosInterceptorRefreshTokenRefresh>(async () => {
    const storage = getAuthTokens();

    if (!storage.token || !storage.refreshToken || !storage.expires) {
      throw new Error('token-not-found');
    }

    const {
      data: { token, refreshToken },
    } = await ServiceAccounts.refreshToken({
      token: storage.token,
      refreshToken: storage.refreshToken,
    });

    return { token, refreshToken, expires: storage.expires };
  }, []);
  const onSuccess = useCallback<AxiosInterceptorRefreshTokenSuccess>(({ token, refreshToken }) => {
    const storage = getAuthTokens();
    updateAuthTokens({ token, refreshToken, expires: storage.expires });
  }, []);

  const onError = useCallback<AxiosInterceptorRefreshTokenError>(() => {
    updateAuthTokens();
    dispatch(actionAccountClear());
  }, [dispatch]);
  const checkIsRefreshCase = useCallback<AxiosInterceptorRefreshTokenCheckIsRefreshCase>(
    async ({ error }) => {
      const storage = getAuthTokens();
      if (!storage.token || !storage.refreshToken) {
        return false;
      }
      if (!isAxiosError(error)) {
        return false;
      }

      return error.response?.status === 401;
    },
    [],
  );

  return (
    <AxiosInterceptorToken instances={instances} getToken={getToken}>
      <AxiosInterceptorRefreshToken
        checkIsRefreshCase={checkIsRefreshCase}
        instances={instances}
        refresh={refresh}
        onSuccess={onSuccess}
        onError={onError}
      >
        <AxiosInterceptorPatch>
          <AxiosInterceptorPost>
            <AxiosInterceptorPermissionError>{children}</AxiosInterceptorPermissionError>
          </AxiosInterceptorPost>
        </AxiosInterceptorPatch>
      </AxiosInterceptorRefreshToken>
    </AxiosInterceptorToken>
  );
});
