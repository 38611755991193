import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React, { useCallback } from 'react';

import { IconButton, Link, LinkProps, Snackbar, Stack, Typography } from '@mui/material';
import { useCopyToClipboard, useOpen, useTranslate } from 'hooks';

import style from './index.module.scss';

interface Props extends LinkProps {
  value: string | null | undefined;
}

export const TemplateLink: React.FC<Props> = ({ value, href, ...rest }) => {
  const [copy] = useCopyToClipboard();
  const { tp } = useTranslate();
  const { isOpen, onOpen, onClose } = useOpen();
  const onCopy = useCallback(async () => {
    await copy(value!);
    onOpen();
  }, [copy, onOpen, value]);

  if (!value) return null;

  return (
    <>
      <Stack direction={'row'} spacing={1} alignItems={'center'} className={style.root}>
        <Link
          underline={'hover'}
          textOverflow={'ellipsis'}
          overflow={'hidden'}
          whiteSpace={'nowrap'}
          color={'primary'}
          target={'_blank'}
          {...rest}
          href={href}
        >
          {value}
        </Link>
        <IconButton onClick={onCopy}>
          <ContentCopyIcon fontSize={'inherit'} sx={{ fontSize: 20 }} />
        </IconButton>
      </Stack>
      <Snackbar
        open={isOpen}
        autoHideDuration={3000}
        onClose={onClose}
        message={
          <Typography variant={'subtitle2'} display={'flex'} alignItems={'center'}>
            {tp('copied')}
            <ContentCopyIcon sx={{ p: 0.5 }} />
          </Typography>
        }
        action={
          <IconButton aria-label="close" color="inherit" sx={{ p: 0.5 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
      />
    </>
  );
};
