import { Grid } from '@mui/material';
import { AppSelect } from 'components/app-select';
import renderUserDetailsOption from 'components/render-option';
import {
  useAppDispatch,
  useAppSelector,
  useFieldProps,
  useFilterForm,
  useSourceCustomers,
  useSourceProjects,
} from 'hooks';
import { memo, useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import {
  actionsCheckDoneTickets,
  selectCheckDoneTicketsFilters,
  selectCheckDoneTicketsStatus,
} from 'store/check-done-tickets';
import { composeFunctions } from 'utils/other';
import { ArrayType } from 'utils/types';

export const Filters = memo(() => {
  const { isLoading } = useAppSelector(selectCheckDoneTicketsStatus);
  const dispatch = useAppDispatch();
  const defaultValues = useAppSelector(selectCheckDoneTicketsFilters);

  const onUpdate = useCallback(
    (data: typeof defaultValues) => {
      dispatch(actionsCheckDoneTickets.setFilters(data));
    },
    [dispatch],
  );
  const { control, onChangeItem, watch, setValue } = useFilterForm({ defaultValues, onUpdate });

  const sourceCustomers = useSourceCustomers();
  const sourceProjects = useSourceProjects();

  const customerOptions = useMemo(() => {
    return sourceCustomers.data.map((customer) => {
      return {
        ...customer,
        projects: sourceProjects.data.filter((project) => project.customerID === customer.id),
      };
    });
  }, [sourceCustomers.data, sourceProjects.data]);
  const customerID = watch('customerID');
  const customer = useMemo(() => {
    return customerOptions.find((c) => c.id === customerID);
  }, [customerID, customerOptions]);
  const onChangeCustomer = useCallback(() => {
    setValue('projectID', '');
  }, [setValue]);

  const projectOptions = useMemo(() => {
    return customer ? customer.projects : sourceProjects.data;
  }, [customer, sourceProjects.data]);
  const onChangeProject = useCallback(
    (v: string, option: ArrayType<typeof projectOptions> | null) => {
      if (!option) return;
      setValue('customerID', option.customerID);
    },
    [setValue],
  );
  const getFieldProps = useFieldProps({ disabled: isLoading });

  return (
    <Grid container columnSpacing={2} rowSpacing={1} alignItems={'center'}>
      <Grid item md={6}>
        <Controller
          name={'customerID'}
          control={control}
          render={(renderProps) => {
            const props = getFieldProps(renderProps);
            return (
              <AppSelect
                {...props}
                onChange={composeFunctions(props.onChange, onChangeCustomer, onChangeItem)}
                options={customerOptions}
                loading={sourceCustomers.isLoading}
                renderOption={renderUserDetailsOption}
                helperText={null}
              />
            );
          }}
        />
      </Grid>
      <Grid item md={6}>
        <Controller
          name={'projectID'}
          control={control}
          render={(renderProps) => {
            const props = getFieldProps(renderProps);
            return (
              <AppSelect
                {...props}
                onChange={composeFunctions(props.onChange, onChangeProject, onChangeItem)}
                options={projectOptions}
                loading={sourceProjects.isLoading}
                renderOption={renderUserDetailsOption}
                helperText={null}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
});
