import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  apiUserCrmProfiles,
  IGridCrmUsers,
  IUserCrmProfile,
  ServiceUserCrmProfiles,
} from 'services/user-crm-profiles';
import * as dynamic from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';
import {
  selectCrmUsersFilters,
  selectCrmUsersOrderBy,
  selectCrmUsersPagination,
} from './selectors';

export const actionCrmUsersGetAllDynamic = createAsyncThunk<
  { value: IGridCrmUsers[]; count: number },
  { page: number; take?: number } | void,
  AppAsyncThunkConfig
>(`CRM_USERS/getAllDynamic`, async (payload, { getState }) => {
  try {
    const { search, userCrmProfilePermissionID, startDate } = selectCrmUsersFilters(getState());
    const { take, skip } = selectCrmUsersPagination(getState());
    const { field, order } = selectCrmUsersOrderBy(getState());
    const params = {
      filter: dynamic
        .mergeFilters(
          dynamic.makeFilter(
            ['firstName', 'lastName', 'slackUserName', 'gitHubUserName'],
            search,
            dynamic.decoratorIsNotNullable(dynamic.contains),
          ),
          dynamic.makeFilter('startDate', startDate, dynamic.dateRange),
          dynamic.makeFilter(
            'userCrmProfilePermissionID',
            userCrmProfilePermissionID,
            dynamic.decoratorIsNotNullable(dynamic.equals),
          ),
        )
        .join('&&'),
      select: dynamic.select(
        'id',
        'userPhoto',
        'appIdentityUserID',
        'firstName',
        'lastName',
        'userCrmProfilePermissionID',
        'slackUserName',
        'gitHubUserName',
        'color',
        'email',
        'mobilePhone',
        'startDate',
        'isActive',
        'userCrmProfilePermission.title as position',
      ),
      orderBy: dynamic.orderBy(field, order) || 'userCrmProfilePermission.title,firstName,lastName',
      count: true,
      take,
      skip,
    };
    const { data: result } = await ServiceUserCrmProfiles.getAllDynamic<
      IGridCrmUsers,
      typeof params
    >(params);
    return result;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionCrmUsersChangeItem = createAsyncThunk<
  void,
  Partial<IUserCrmProfile>,
  AppAsyncThunkConfig
>(`CRM_USERS/actionCrmUsersChangeItem`, async (data, { dispatch }) => {
  try {
    const appIdentityUserID = data.appIdentityUserID;
    if (!appIdentityUserID) {
      throw new Error('appIdentityUserID is required');
    }
    await dispatch(
      apiUserCrmProfiles.endpoints.patchUserCrmProfile.initiate({ ...data, appIdentityUserID }),
    ).unwrap();
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
