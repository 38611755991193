import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import { Button, ButtonProps, Tooltip } from '@mui/material';
import { useTranslate } from 'hooks';
import React from 'react';

interface Props extends ButtonProps {}

export const ButtonArchive: React.FC<Props> = ({ title, children, ...rest }) => {
  const { tp } = useTranslate();
  return (
    <Tooltip title={title || tp('archive-tooltip')} arrow>
      <Button startIcon={<ArchiveOutlinedIcon />} color={'silver'} {...rest}>
        {children || tp('archive')}
      </Button>
    </Tooltip>
  );
};
