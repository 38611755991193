import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

import { IconButton } from '@mui/material';
import clsx from 'clsx';
import { BaseSlotImage } from 'components/attachment-input/components/base-slot-image';
import { useOpen } from 'hooks';
import React, { useMemo } from 'react';
import { getFileInfo } from 'utils/file-uploader';
import { handleEventStopAndPrevent } from 'utils/handlers';
import { composeFunctions } from 'utils/other';
import { makeTitle } from '../../helpers';
import { ImageData } from '../../models';
import { DialogEdit } from '../dialog-edit';
import style from './index.module.scss';

interface Props {
  id: string | null;
  entryDate: string;
  description: string | null;
  userCrmProfileID: string | null;
  hideControls: boolean;
  url: any;
  disabled?: boolean;
  onRemove: () => void;
  onPreview: () => void;
  onUpdated: (data: ImageData) => void;
}
export const SlotImage: React.FC<Props> = ({
  disabled,
  id,
  description,
  entryDate,
  userCrmProfileID,
  url,
  hideControls,
  onRemove,
  onPreview,
  onUpdated,
}) => {
  const { isOpen, onOpen, onClose } = useOpen();
  const title = useMemo(() => {
    return makeTitle({ entryDate, description });
  }, [entryDate, description]);

  const { fileUrl } = getFileInfo(url);

  return (
    <>
      <BaseSlotImage
        title={title}
        src={fileUrl}
        onClick={onPreview}
        classes={{ controls: clsx({ [style.controlsHide]: hideControls }) }}
      >
        <div className={style.controls}>
          <IconButton
            size={'small'}
            disabled={disabled}
            onClick={composeFunctions(handleEventStopAndPrevent, onRemove)}
            className={style.button}
          >
            <CloseIcon fontSize={'inherit'} sx={{ fontSize: 14 }} />
          </IconButton>
          <IconButton
            size={'small'}
            disabled={disabled}
            onClick={composeFunctions(handleEventStopAndPrevent, onOpen)}
            className={style.button}
          >
            <EditIcon fontSize={'inherit'} sx={{ fontSize: 14 }} />
          </IconButton>
        </div>
      </BaseSlotImage>
      {isOpen && (
        <DialogEdit
          initValue={{ id, description, entryDate, userCrmProfileID }}
          onClose={onClose}
          onChange={composeFunctions(onUpdated, onClose)}
        />
      )}
    </>
  );
};
