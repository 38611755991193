import clsx from 'clsx';
import { useTranslate } from 'hooks';
import React, { Fragment, useCallback, useId, useMemo } from 'react';
import { transformFieldToLabelKey } from 'utils/transformers';
import { useDataGridSettings } from '../../hooks';
import {
  IDataGridColumnParsed,
  IDataGridGroupOptions,
  IDataGridRenderGroupProps,
  IDataGridRowProps,
} from '../../models';
import { DataGridColumnHead } from '../data-grid-column';
import { DataGridTableRow } from '../data-grid-table-row';
import style from './index.module.scss';

export interface DataGridTableProps<T extends Record<string, any>> {
  rows: T[];
  columns: IDataGridColumnParsed<T>[];
  rowProps?: IDataGridRowProps<T>;
  bodyStart?: React.ReactNode;
  bodyEnd?: React.ReactNode;
  groupOptions?: IDataGridGroupOptions<T>;
  renderGroup?: (renderProps: IDataGridRenderGroupProps<T>) => React.ReactNode;
}

export const DataGridTable = <T extends Record<string, any>>({
  rows,
  columns,
  rowProps,
  bodyStart,
  bodyEnd,
  groupOptions,
  renderGroup,
}: DataGridTableProps<T>) => {
  const { classes, rowKey } = useDataGridSettings();
  const { t } = useTranslate();
  const dragType = useId();
  const renderHeaderCell = useCallback(
    (props: IDataGridColumnParsed<T>) => {
      const { HeadCellProps, HeadCellInnerProps, title, field, sortableKey, sortable, sticky } =
        props.headCellOptions;
      const child =
        HeadCellProps?.children || title === undefined
          ? t(transformFieldToLabelKey(String(field)))
          : title;

      return (
        <DataGridColumnHead
          key={field as string}
          field={field as string}
          {...HeadCellProps}
          InnerProps={HeadCellInnerProps}
          sticky={sticky}
          sortable={sortable}
          sortableKey={sortableKey}
        >
          {child}
        </DataGridColumnHead>
      );
    },
    [t],
  );

  const renderSingleRow = useCallback(
    (row: T, index: number) => {
      const rowID = row[rowKey];

      return (
        <DataGridTableRow
          key={rowID}
          dragType={dragType}
          rowID={rowID}
          index={index}
          columns={columns}
          row={row}
          rowProps={rowProps}
        />
      );
    },
    [columns, dragType, rowKey, rowProps],
  );

  const renderGroupRow = useCallback(
    ([name, group]: [string, T[]], index: number) => {
      const groupsChildren = group.map(renderSingleRow);

      if (!renderGroup) {
        throw new Error('renderGroup is expected');
      }

      return (
        <Fragment key={`group__${index}`}>
          {renderGroup({
            group,
            children: groupsChildren,
            columns: columns,
            name: name,
          })}
        </Fragment>
      );
    },
    [columns, renderSingleRow, renderGroup],
  );

  const makeGroups = groupOptions?.makeGroups;
  const groupedRows = useMemo(() => {
    return makeGroups ? makeGroups(rows) : null;
  }, [makeGroups, rows]);

  return (
    <table className={clsx(style.table, classes?.table)}>
      <thead>
        <tr className={clsx(style.rowHead, classes?.rowHead)}>{columns.map(renderHeaderCell)}</tr>
      </thead>
      <tbody>
        {bodyStart}
        {groupedRows ? groupedRows.map(renderGroupRow) : rows.map(renderSingleRow)}
        {bodyEnd}
      </tbody>
    </table>
  );
};
