import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DoneItemsForReport } from 'services/report-done-items';
import { DateValue } from 'utils/dates';
import { apiReports } from '../../services/reports';
import { actionsReportItemInit, actionsReportItemRefresh } from './actions';
import { PREFIX } from './helpers';

interface Filters {
  search: string;
  projectID: string;
  userCrmProfileID: string;
  tag: string;
  dateRange: DateValue[];
}
interface OrderBy {
  field: string;
  order: 'desc' | 'asc' | null;
}

interface State {
  reportID: string | null;
  filters: Filters;
  orderBy: OrderBy;
  reportItems: DoneItemsForReport[];
  isLoading: boolean;
  isInit: boolean;
  error: null | Error;
  isExpanded: boolean;
  hoverRowID: string | null;
}

const initState = (): State => {
  return {
    reportID: null,
    filters: {
      search: '',
      projectID: '',
      userCrmProfileID: '',
      tag: '',
      dateRange: [],
    },
    orderBy: {
      field: 'doneItem.date',
      order: 'desc',
    },
    reportItems: [],
    isLoading: false,
    isInit: false,
    error: null,

    isExpanded: true,
    hoverRowID: null,
  };
};

const slice = createSlice({
  name: PREFIX,
  initialState: initState(),
  reducers: {
    reset() {
      return initState();
    },
    filtersSet(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    filtersReset(state) {
      state.filters = initState().filters;
    },
    orderBySet(state, action: PayloadAction<Partial<OrderBy>>) {
      state.orderBy = { ...state.orderBy, ...action.payload };
    },
    toggleExpanded(state) {
      state.isExpanded = !state.isExpanded;
    },
    hoverRow(state, action: PayloadAction<string | null>) {
      state.hoverRowID = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionsReportItemInit.pending, (state, action) => {
        state.reportID = action.meta.arg;
        state.isLoading = true;
        state.error = null;
      })
      .addCase(actionsReportItemInit.fulfilled, (state, action) => {
        state.reportItems = action.payload;
        state.isLoading = false;
        state.isInit = true;
      })
      .addCase(actionsReportItemInit.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        state.isInit = true;
      });

    builder
      .addCase(actionsReportItemRefresh.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(actionsReportItemRefresh.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reportItems = action.payload;
      })
      .addCase(actionsReportItemRefresh.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });

    builder.addMatcher(apiReports.endpoints.patchReport.matchFulfilled, (state, action) => {
      const originalArgs = action.meta.arg.originalArgs;
      if (originalArgs.id !== state.reportID) {
        return state;
      }

      if (originalArgs.displayColumnTag === false) {
        state.filters.tag = '';
      }
      if (originalArgs.displayColumnUserName === false) {
        state.filters.userCrmProfileID = '';
      }
      if (originalArgs.displayColumnProjectName === false) {
        state.filters.projectID = '';
      }
    });
  },
});

export const actionsReportItem = slice.actions;
export const reducerReportItem = slice.reducer;
