interface Rect {
  top: number;
  left: number;
  width: number;
  height: number;
}

export const makePopupVisible = (rect: Rect, viewportRect?: Rect) => {
  const rectTop = rect.top;
  const rectBottom = rect.top + rect.height;
  const rectLeft = rect.left;
  const rectRight = rect.left + rect.width;

  const viewportTop = viewportRect?.top || 0;
  const viewportLeft = viewportRect?.left || 0;

  let viewportWidth = viewportRect?.width || window.innerWidth;
  let viewportHeight = viewportRect?.height || window.innerHeight;

  viewportWidth += viewportLeft;
  viewportHeight += viewportTop;

  let newTop = rectTop;
  let newLeft = rectLeft;

  if (rectTop < 0) {
    newTop = 0;
  } else if (rectBottom > viewportHeight) {
    newTop = rectTop - (rectBottom - viewportHeight);
  }

  if (rectLeft < 0) {
    newLeft = 0;
  } else if (rectRight > viewportWidth) {
    newLeft = rectLeft - (rectRight - viewportWidth);
  }

  return {
    top: newTop,
    left: newLeft,
    height: rect.height,
    width: rect.width,
  };
};
