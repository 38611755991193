import { apiTimeTracking } from 'services/time-trackings';
import { selectAccountUser } from 'store/auth';
import { selectTimeTrackingFilters } from 'store/time-tracking/selectors';
import { put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import { workerErrorNotifyThunk } from 'utils/sagas';
import {
  actionTimeTrackingCopy,
  actionTimeTrackingDurationGetDynamic,
  actionTimeTrackingGetDynamic,
  actionTimeTrackingMove,
} from './actions';
import {
  actionTimeTrackingFiltersSet,
  actionTimeTrackingRevalidate,
  actionTimeTrackingSetView,
  TIME_TRACKING_VIEW,
} from './slice';

function* watcherChangeView(action: ReturnType<typeof actionTimeTrackingSetView>) {
  if (action.payload === TIME_TRACKING_VIEW.WEEK) {
    const user = yield* select(selectAccountUser);

    if (!user) {
      throw new Error('unexpected-behaviour');
    }
    const { userCrmProfileID } = yield* select(selectTimeTrackingFilters);

    if (!userCrmProfileID) {
      yield* put(actionTimeTrackingFiltersSet({ userCrmProfileID: user.appUserID }));
    }
  }

  yield* put(actionTimeTrackingGetDynamic());
  yield* put(actionTimeTrackingDurationGetDynamic());
}
function* watcherChanges() {
  yield* put(actionTimeTrackingRevalidate());
}

function* watcherTimeTrackingGetDynamic() {
  yield* put(actionTimeTrackingDurationGetDynamic());
}
export const sagasTimeTracking = [
  takeLatest(actionTimeTrackingGetDynamic.pending, watcherTimeTrackingGetDynamic),
  takeLatest(actionTimeTrackingSetView, watcherChangeView),
  takeLatest(
    [
      apiTimeTracking.endpoints.postTimeTrackingItem.matchFulfilled,
      apiTimeTracking.endpoints.patchTimeTrackingItem.matchFulfilled,
      apiTimeTracking.endpoints.deleteTimeTrackingItem.matchFulfilled,
      apiTimeTracking.endpoints.postTimeTrackingItemMultiple.matchFulfilled,
    ],
    watcherChanges,
  ),
  takeEvery(
    [actionTimeTrackingCopy.rejected, actionTimeTrackingMove.rejected],
    workerErrorNotifyThunk,
  ),
];
