import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, BoxProps, IconButton, Tooltip } from '@mui/material';
import React from 'react';

import style from './index.module.scss';

import clsx from 'clsx';

interface Props extends BoxProps {
  html: string | null | undefined;
}

export const TemplateDescription: React.FC<Props> = ({ html }) => {
  if (!html) return null;

  return (
    <Box className={clsx(style.root)}>
      <Tooltip title={<Box dangerouslySetInnerHTML={{ __html: html || '' }} />}>
        <IconButton size={'small'}>
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
