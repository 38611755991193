import { useContext } from 'react';
import { DataGridDragAndDropContext } from '../components/provider-drag-and-drop';

export const useDataGridDragAndDrop = () => {
  const context = useContext(DataGridDragAndDropContext);
  if (!context) {
    throw Error('You should use DataGridDragAndDropContext');
  }
  return context;
};
