import { yupResolver } from '@hookform/resolvers/yup';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { ButtonArchive, ButtonCancel, ButtonCreate } from 'components/buttons';
import { TicketActionItemDialogEdit } from 'components/ticket-action-item-containers';
import { withConfirmClick } from 'hoc/with-confirm-click';
import { useCurrentUser, useEffectNotifyError, useOpen, useTranslate } from 'hooks';
import React, { useCallback, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { apiNotes, schemaNotePatch } from 'services/notes';
import { ITicketActionItem } from 'services/ticket-action-items';
import { composeFunctions } from 'utils/other';
import { isFulfilledMutation } from 'utils/service';
import { InferType } from 'yup';
import { DialogHeaderCustomer } from '../../../../../dialog-header-customer';
import { ContainerAttachToTicket } from '../../../container-attach-to-ticket';
import { Form } from '../../../form';

const ButtonDelete = withConfirmClick(ButtonArchive);

const useGetQuery = apiNotes.useGetNoteQuery;
const usePatchMutation = apiNotes.usePatchNoteMutation;
const useArchiveMutation = apiNotes.useToggleArchiveNoteMutation;
const useDoneMutation = apiNotes.useDoneNoteMutation;

const schema = schemaNotePatch.omit(['id']);
type FormData = InferType<typeof schema>;

interface Props {
  itemID: string;
  onClose: () => void;
  onDone: () => void;
}
export const ContentEdit: React.FC<Props> = ({ itemID, onClose, onDone }) => {
  const { tp } = useTranslate();

  const resultGet = useGetQuery(itemID);
  useEffectNotifyError(resultGet.error);

  const [performDoneTicket, resultDoneTicket] = useDoneMutation();
  useEffectNotifyError(resultDoneTicket.error);

  const onDoneTicket = useCallback(
    async (ticketActionItem: Pick<ITicketActionItem, 'id'>) => {
      await performDoneTicket({ id: itemID, ticketActionItemID: ticketActionItem.id });
      onClose();
    },
    [itemID, performDoneTicket, onClose],
  );

  const initData = resultGet?.data;

  const { appUserID } = useCurrentUser();
  const [patchItem, resultPatch] = usePatchMutation();
  useEffectNotifyError(resultPatch.error);

  const methods = useForm({
    defaultValues: { userCrmProfileID: appUserID },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset, control } = methods;

  const onSubmit = useCallback(
    async (formData: FormData) => {
      const res = await patchItem({ ...formData, id: itemID });

      if (isFulfilledMutation(res)) {
        onDone();
      }
    },
    [patchItem, onDone, itemID],
  );

  useEffect(() => {
    if (!initData) return;
    reset(schema.cast({ ...initData }, { stripUnknown: true, assert: false }));
  }, [reset, initData]);

  const [archiveItem, resultArchive] = useArchiveMutation();

  const onArchive = useCallback(async () => {
    const res = await archiveItem({ id: itemID, value: true });

    if (isFulfilledMutation(res)) {
      onDone();
    }
  }, [archiveItem, onDone, itemID]);

  const isLoading =
    resultPatch.isLoading ||
    resultGet.isFetching ||
    resultArchive.isLoading ||
    resultDoneTicket.isLoading;

  const stateCreateTicket = useOpen();

  return (
    <>
      <Controller
        control={control}
        name={'customerID'}
        render={(renderProps) => {
          return (
            <DialogHeaderCustomer
              value={renderProps.field.value}
              onChange={renderProps.field.onChange}
              onClose={onClose}
            />
          );
        }}
      />
      <DialogContent>
        <FormProvider {...methods}>
          <Form isLoading={isLoading} />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <ButtonDelete
          confirmMessage={tp('archive-confirm')}
          onClick={onArchive}
          disabled={isLoading}
        />
        <Button
          startIcon={initData?.itemKey ? <RemoveRedEyeIcon /> : <AssignmentIcon />}
          sx={{ mr: 'auto' }}
          disabled={isLoading}
          onClick={stateCreateTicket.onOpen}
        >
          {tp(initData?.itemKey ? 'view-ticket-action-item' : 'create-ticket-action-item')}
        </Button>
        <ButtonCancel onClick={onClose} />
        <ButtonCreate onClick={handleSubmit(onSubmit)} disabled={isLoading}>
          {tp('save')}
        </ButtonCreate>
      </DialogActions>
      {stateCreateTicket.isOpen &&
        initData &&
        (initData.itemKey ? (
          <TicketActionItemDialogEdit
            itemKey={initData.itemKey}
            onClose={stateCreateTicket.onClose}
            onDone={stateCreateTicket.onClose}
          />
        ) : (
          <ContainerAttachToTicket
            note={initData}
            onClose={stateCreateTicket.onClose}
            onDone={composeFunctions(onDoneTicket, stateCreateTicket.onClose)}
          />
        ))}
    </>
  );
};
