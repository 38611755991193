import React, { useCallback, useMemo, useRef } from 'react';
import { composeFunctions } from 'utils/other';

import { Box, FormControlLabel, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { AppCheckbox } from 'components/app-checkbox';
import { AppInput } from 'components/app-input';
import { AppSelect, defaultGetOptionDisabled } from 'components/app-select';
import { AttachmentInput, AttachmentInputHandle } from 'components/attachment-input';
import { DateRangeInput } from 'components/date-picker-range';
import { DEFAULT_INIT, IAllProps } from 'components/html-editor';
import { HtmlEditorLight } from 'components/html-editor-light';
import { getUserDetailsOptionLabel } from 'components/render-option';
import { SelectEmployees } from 'components/select-employees';
import { BaseDone } from 'components/tickets';
import {
  mergeRef,
  useFieldProps,
  useSourcePriorities,
  useSourceTicketItemActionStatuses,
  useTranslate,
} from 'hooks';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { isDateLike } from 'utils/dates';
import { DeepPartial } from 'utils/types';
import style from './index.module.scss';

export type Meta = {
  disabled: { done: boolean; reporterUserCrmProfileID: boolean; assigneeUserCrmProfileID: boolean };
  helperTexts: { done: string };
  errors: { done: boolean };
};
interface Props {
  isLoading: boolean;
  meta?: DeepPartial<Meta>;
  showReportDescription?: boolean;
  disabled: boolean;
}

const EditorProps: IAllProps = {
  init: {
    ...DEFAULT_INIT,
    height: 250,
  },
};

export const Form: React.FC<Props> = ({ isLoading, meta, showReportDescription, disabled }) => {
  const { tp, t } = useTranslate();
  const { control, register, watch, setValue, formState } = useFormContext();
  const getFieldProps = useFieldProps();

  const { errors } = formState;

  const sourceTicketActionItemPriorities = useSourcePriorities();
  const sourceTicketActionItemStatuses = useSourceTicketItemActionStatuses();

  const done = watch('done');

  const handleDone = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.checked) {
        setValue('review', false);
      }
    },
    [setValue],
  );

  const startDateAndTime = watch('startDateAndTime');
  const endDateAndTime = watch('endDateAndTime');
  const rangeValue = useMemo(() => {
    return [startDateAndTime, endDateAndTime].filter(isDateLike);
  }, [startDateAndTime, endDateAndTime]);
  const onChangeRange = useCallback(
    (value: Date[]) => {
      setValue('startDateAndTime', value[0]?.toISOString());
      setValue('endDateAndTime', value[1]?.toISOString());
    },
    [setValue],
  );
  const rangeError = useMemo(() => {
    return (errors['startDateAndTime'] || errors['endDateAndTime']) as FieldError;
  }, [errors]);
  const rangeHelperText = useMemo(() => {
    return rangeError ? (rangeError.message ? t(rangeError.message) : '') : ' ';
  }, [rangeError, t]);

  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('xl'));

  const ref = useRef<AttachmentInputHandle>();
  const onInsertImages = useCallback((files: File[]) => {
    if (!ref.current) return;
    ref.current.addFiles(files);
  }, []);

  return (
    <>
      <input {...register('id')} type={'hidden'} />
      <input {...register('entryDate')} type={'hidden'} />
      <input {...register('ticketID')} type={'hidden'} />
      <input {...register('startDateAndTime')} type={'hidden'} />
      <input {...register('endDateAndTime')} type={'hidden'} />
      <Grid container columnSpacing={2} rowSpacing={0.2} alignItems={'flex-end'}>
        <Grid item xs={12} md={'auto'}>
          <Controller
            control={control}
            name={'done'}
            render={(renderProps) => {
              const props = getFieldProps(renderProps);
              return (
                <BaseDone
                  {...props}
                  error={props.error || meta?.errors?.done}
                  onChange={composeFunctions(props.onChange, handleDone)}
                  disabled={isLoading || meta?.disabled?.done || disabled}
                  helperText={props.helperText?.trim() || meta?.helperTexts?.done}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Controller
            control={control}
            name={'priorityID'}
            render={(renderProps) => (
              <AppSelect
                {...getFieldProps(renderProps)}
                options={sourceTicketActionItemPriorities.data}
                loading={sourceTicketActionItemPriorities.isLoading}
                disabled={isLoading || disabled}
                disableClearable
              />
            )}
          />
        </Grid>
        {done ? (
          <Grid item md={true}>
            <Box pb={0.9} display={'flex'} flexWrap={'wrap'} columnGap={0.5}>
              <Controller
                control={control}
                name={'review'}
                render={(renderProps) => {
                  const { value, onChange, onBlur } = getFieldProps(renderProps);
                  return (
                    <FormControlLabel
                      control={<AppCheckbox value={value} onChange={onChange} onBlur={onBlur} />}
                      label={tp('review')}
                      disabled={disabled || isLoading}
                    />
                  );
                }}
              />
            </Box>
          </Grid>
        ) : (
          <Grid item xs={6} md={3}>
            <Controller
              control={control}
              name={'ticketActionItemStatusID'}
              render={(renderProps) => (
                <AppSelect
                  {...getFieldProps(renderProps)}
                  options={sourceTicketActionItemStatuses.data}
                  loading={sourceTicketActionItemStatuses.isLoading}
                  disabled={isLoading || disabled}
                  disableClearable
                />
              )}
            />
          </Grid>
        )}
      </Grid>
      <Grid container columnSpacing={2} pt={0.4} alignItems={'flex-end'}>
        <Grid item xs={12} md={6} xl={3}>
          <div className={clsx(!isTablet && showReportDescription && style.reporter)}>
            <Controller
              control={control}
              name={'reporterUserCrmProfileID'}
              render={(renderProps) => (
                <SelectEmployees
                  {...getFieldProps(renderProps)}
                  disabled={isLoading || meta?.disabled?.reporterUserCrmProfileID || disabled}
                  disableClearable
                  getOptionLabel={getUserDetailsOptionLabel}
                  getOptionDisabled={defaultGetOptionDisabled}
                />
              )}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6} xl={3}>
          <Controller
            control={control}
            name={'assigneeUserCrmProfileID'}
            render={(renderProps) => (
              <SelectEmployees
                {...getFieldProps(renderProps)}
                disabled={isLoading || meta?.disabled?.assigneeUserCrmProfileID || disabled}
                disableClearable
                getOptionLabel={getUserDetailsOptionLabel}
                getOptionDisabled={defaultGetOptionDisabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12} xl={6} alignSelf={'flex-end'}>
          <DateRangeInput
            error={Boolean(rangeError)}
            helperText={rangeHelperText}
            value={rangeValue}
            onChange={onChangeRange}
            disabled={isLoading || disabled}
          />
        </Grid>
        {showReportDescription && (
          <Grid item xs={12}>
            <Box className={style.reportDescriptionWrapper}>
              <Controller
                control={control}
                name={'activityReportDescription'}
                render={(renderProps) => (
                  <AppInput
                    {...getFieldProps(renderProps)}
                    InputLabelProps={{ shrink: true }}
                    label={
                      <Typography
                        color={'black'}
                        alignSelf={'center'}
                        fontWeight={'500'}
                        fontSize={'1.8rem'}
                        component={'h5'}
                      >
                        {getFieldProps(renderProps).label}
                      </Typography>
                    }
                    InputProps={{
                      classes: { root: style.reportDescriptionContent },
                    }}
                    disabled={isLoading || disabled}
                  />
                )}
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Controller
            control={control}
            name={'description'}
            render={(renderProps) => (
              <HtmlEditorLight
                editorProps={EditorProps}
                {...getFieldProps(renderProps)}
                disabled={isLoading || disabled}
                onInsertImages={onInsertImages}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={'images'}
            render={(renderProps) => {
              const props = getFieldProps(renderProps);
              return (
                <AttachmentInput {...props} ref={mergeRef(ref, props.ref)} disabled={isLoading} />
              );
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
