import StraightIcon from '@mui/icons-material/Straight';
import { Avatar, ButtonBaseProps, Stack, StackProps, Typography } from '@mui/material';
import { AppButtonBase } from 'components/buttons/app-button-base';
import { useTranslate } from 'hooks';
import React, { useCallback } from 'react';
import variables from 'styles/config.scss';
import { getShortFullName } from 'utils/app-helpers';
import { handleEventStopAndPrevent } from 'utils/handlers';
import { Unset } from 'utils/types';

type User = { id: string; fullName: Unset; userPhoto?: Unset };

interface BaseParticipantProps extends Omit<ButtonBaseProps, 'component' | 'onClick'> {
  variant?: 'none' | 'mini' | 'full';
  user: Unset<User>;
  onClick?: (user: User) => void;
}
export const BaseParticipant: React.FC<BaseParticipantProps> = ({
  user,
  variant = 'full',
  onClick: _onClick,
  ...rest
}) => {
  const onClick = useCallback(() => {
    if (!user) return;
    if (!_onClick) return;
    _onClick(user);
  }, [user, _onClick]);

  return (
    <AppButtonBase onClick={onClick} {...rest}>
      <Stack spacing={0.8} alignItems={'center'} direction={'row'}>
        <Avatar
          sx={{ width: 24, height: 24, border: `1px solid ${variables.colorGrey}` }}
          src={user?.userPhoto ?? undefined}
        />
        {variant === 'full' && (
          <Typography variant={'caption'} component={'div'}>
            {user?.fullName}
          </Typography>
        )}
        {variant === 'mini' && (
          <Typography variant={'caption'} component={'div'}>
            {getShortFullName(user?.fullName)}
          </Typography>
        )}
      </Stack>
    </AppButtonBase>
  );
};

interface Props extends StackProps {
  variant?: 'mini' | 'full';
  reporter: Unset<User>;
  onClickReporter?: (user: User) => void;
  assigner: Unset<User>;
  onClickAssigner?: (user: User) => void;
}
export const BaseParticipants: React.FC<Props> = ({
  reporter,
  onClickReporter,
  assigner,
  onClickAssigner,
  variant,
  ...rest
}) => {
  const { tp } = useTranslate();

  return (
    <Stack
      direction={'row'}
      spacing={0.4}
      divider={
        <StraightIcon
          color={'disabled'}
          sx={{ transform: 'rotate(90deg)', color: variables.colorGrey }}
        />
      }
      alignItems={'center'}
      onDoubleClick={handleEventStopAndPrevent}
      {...rest}
    >
      <BaseParticipant
        variant={variant}
        user={reporter}
        title={tp('reporter')}
        onClick={onClickReporter}
      />
      <BaseParticipant
        variant={variant}
        user={assigner}
        title={tp('assigner')}
        onClick={onClickAssigner}
      />
    </Stack>
  );
};
