import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo } from 'react';
import { useForm, UseFormProps } from 'react-hook-form';
import { useDataGridSettings } from './use-data-grid-settings';
import { useDataGridTasks } from './use-data-grid-tasks';

export interface UseDataGridEditorFormProps<Model extends Record<string, any> = any>
  extends UseFormProps {
  row: Model;
  field: keyof Model;
  onClose: () => void;
}
export const useDataGridEditorForm = <M extends Record<string, any>>(
  props: UseDataGridEditorFormProps<M>,
) => {
  const { rowKey, schema } = useDataGridSettings();

  const { row, field, onClose, resolver: outerResolver, ...rest } = props;

  const defaultResolver = useMemo(() => {
    return schema ? yupResolver(schema.pick([field as string])) : undefined;
  }, [field, schema]);

  const defaultValues = { [field]: row[field] };
  const formMethods = useForm({
    defaultValues,
    resolver: outerResolver || defaultResolver,
    ...rest,
  });

  const { onTask } = useDataGridTasks();
  const onSubmit = useCallback(
    (data: M) => {
      if (data[field] !== row[field]) {
        onTask({
          type: 'item-change',
          // @ts-ignore
          payload: { row, data: { ...data, [rowKey]: row[rowKey] } },
        });
      }
      onClose();
    },
    [field, onClose, onTask, row, rowKey],
  );
  return { ...formMethods, onSubmit };
};
