import { decoratorRank, decoratorWithFiles } from 'utils/decorators';
import * as dynamic from 'utils/dynamic';
import { apiRtk, behaviourMoveRows, DynamicResult, DynamicService } from 'utils/service';
import { API_TICKET_STATUSES, ITicketStatus, ITicketStatusArgs } from './models';

type Model = ITicketStatus;

const REVALIDATE_TAG = 'TicketStatuses' as const;

class Service extends DynamicService<Model> {
  @decoratorWithFiles('id', 'icon')
  async patchWithFiles(data: Model) {
    return super.patch(data);
  }

  @decoratorWithFiles('id', 'icon')
  @decoratorRank('rank')
  async postWithFiles(data: Omit<Model, 'id'>) {
    return super.post(data);
  }

  moveRows = async (newRows: Partial<Model>[], oldRows: Partial<Model>[]) => {
    return behaviourMoveRows({
      mainField: 'id',
      moveField: 'rank',
      newRows,
      oldRows,
      requestPatch: this.patch,
    });
  };
}

export const ServiceTicketStatuses = new Service({
  getAll: API_TICKET_STATUSES.GET_ALL_DYNAMIC,
  post: API_TICKET_STATUSES.POST,
  patch: API_TICKET_STATUSES.PATCH,
  delete: API_TICKET_STATUSES.DELETE,
});

export const apiTicketStatuses = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getTicketStatuses: build.query<Model[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceTicketStatuses.getAllDynamic({
            filter: dynamic.makeFilter('isActive', true, dynamic.equals),
            select: dynamic.select('id', 'labelKey', 'icon', 'color', 'rank', 'isTicketDone'),
            orderBy: dynamic.orderBy('rank', 'asc'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getGridTicketStatuses: build.query<DynamicResult<Model, { count: true }>, ITicketStatusArgs>({
      query: ({ search, orderBy, take, skip }) => ({
        url: API_TICKET_STATUSES.GET_ALL_DYNAMIC,
        params: {
          filter: dynamic
            .mergeFilters(
              dynamic.makeFilter(
                ['labelKey'],
                search,
                dynamic.decoratorIsNotNullable(dynamic.contains),
              ),
            )
            .join('&&'),
          select: dynamic.select(
            'id',
            'labelKey',
            'icon',
            'color',
            'isActive',
            'rank',
            'isTicketDone',
          ),
          take,
          skip,
          orderBy: dynamic.orderBy(orderBy),
          count: true,
        },
      }),
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getTicketStatusItem: build.query<Model, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServiceTicketStatuses.getDynamic(id);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    postTicketStatus: build.mutation<void, Omit<Model, 'id'>>({
      queryFn: async (postData) => {
        try {
          await ServiceTicketStatuses.postWithFiles(postData);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchTicketStatus: build.mutation<void, Model>({
      queryFn: async (patchData) => {
        try {
          await ServiceTicketStatuses.patchWithFiles(patchData);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_TAG },
        { type: REVALIDATE_TAG, id },
      ],
    }),
    deleteTicketStatus: build.mutation<void, Pick<Model, 'id'>>({
      queryFn: async (deleteData) => {
        try {
          await ServiceTicketStatuses.delete(deleteData);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_TAG },
        { type: REVALIDATE_TAG, id },
      ],
    }),
    normalizeTicketStatuses: build.mutation<void, void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceTicketStatuses.getAllDynamic<Pick<Model, 'id'>>({
            select: dynamic.select('id', 'rank'),
            orderBy: dynamic.orderBy('rank', 'asc'),
          });

          for (let i = 0; i < value.length; i++) {
            const item = value[i];
            await ServiceTicketStatuses.patch({ ...item, rank: i + 1 });
          }

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    moveTicketStatuses: build.mutation<
      void,
      { newRows: Partial<Model>[]; oldRows: Partial<Model>[] }
    >({
      queryFn: async ({ newRows, oldRows }) => {
        try {
          await ServiceTicketStatuses.moveRows(newRows, oldRows);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { newRows }) => [
        { type: REVALIDATE_TAG },
        ...newRows.map((item) => ({ type: REVALIDATE_TAG, id: item.id })),
      ],
    }),
  }),
});
