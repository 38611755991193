import React, { useCallback } from 'react';

import { Grid } from '@mui/material';
import { AppInputSearch } from 'components/app-input-search';
import { DateRangeMultiplePicker } from 'components/date-picker-range';
import {
  useAppDispatch,
  useAppSelector,
  useFieldProps,
  useFilterForm,
  useFilterInputProps,
} from 'hooks';
import { Controller } from 'react-hook-form';
import {
  actionsNotificationsLayout,
  selectNotificationsLayoutFilters,
} from 'store/notifications-layout';

interface Props {
  isLoading: boolean;
}

export const NotificationPageFilters: React.FC<Props> = ({ isLoading }) => {
  const dispatch = useAppDispatch();
  const defaultValues = useAppSelector(selectNotificationsLayoutFilters);
  const onUpdate = useCallback(
    (formValue: typeof defaultValues) => {
      dispatch(actionsNotificationsLayout.filtersSet(formValue));
    },
    [dispatch],
  );

  const { control, onChangeItem } = useFilterForm({ defaultValues, onUpdate });

  const getInputProps = useFilterInputProps({ onChangeItem, disabled: isLoading });

  const getFieldProps = useFieldProps();

  return (
    <Grid container columnSpacing={2} rowSpacing={1}>
      <Grid item xs={'auto'}>
        <Controller
          name={'search'}
          control={control}
          render={(renderProps) => (
            <AppInputSearch
              {...getInputProps(renderProps)}
              label={' '}
              InputLabelProps={{ shrink: true }}
              sx={{
                minWidth: 314,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={'auto'}>
        <Controller
          name={'date'}
          control={control}
          render={(renderProps) => (
            <DateRangeMultiplePicker
              {...getFieldProps(renderProps)}
              disableClearable
              helperText={null}
              onApply={onChangeItem}
              sx={{
                minWidth: 314,
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
