import { useValueQuery } from 'hooks/use-value-query';
import { useCallback, useMemo } from 'react';

type Defined<M, Other> = M extends { id: string }
  ? Other & { field?: keyof M }
  : Other & { field: keyof M };

interface Options {
  name: string;
}

export const useDataGridForm = <M extends Record<string, any> = { id: string }>(
  options: Defined<M, Options>,
) => {
  const { name, field = 'id' } = options;
  const { value, onChange } = useValueQuery({ name });

  const isOpen = Boolean(value);

  const onNew = useCallback(() => {
    onChange(true);
  }, [onChange]);
  const onEditRow = useCallback(
    (data: M) => {
      if (data.hasOwnProperty(field)) {
        onChange(data[field]);
      } else {
        throw new Error(`The edit objects does not contains field: ${field}`);
      }
    },
    [onChange, field],
  );

  const itemID = useMemo(() => {
    if (value === 'true' || value === null) {
      return undefined;
    }
    return value;
  }, [value]);
  const onClose = useCallback(() => {
    onChange(null);
  }, [onChange]);
  return { isOpen, onNew, itemID, onClose, onEditRow };
};
