import { createSelector } from '@reduxjs/toolkit';
import { groupBy, sortBy } from 'lodash-es';
import { selectSourceCrmUsersAllMap } from 'services/user-crm-profiles';
import { convertToDate, isAcrossIntervals } from 'utils/dates';
import { calcTrackingData } from '../../services/ticket-action-items';
import {
  selectCheckDoneTicketsFilters,
  selectCheckDoneTicketsSelectedID,
  selectTicketsDataFilteredMap,
  selectTicketsDataFullMap,
} from '../check-done-tickets';
import { AppState } from '../index';

const selectState = (state: AppState) => state.checkDoneUnAssign;

export const selectCheckDoneUnAssignStatus = createSelector(
  selectState,
  ({ isLoading, error, isInit }) => {
    return { isLoading, error, isInit };
  },
);

export const selectCheckDoneUnAssignResetScroll = createSelector(selectState, ({ resetScroll }) => {
  return resetScroll;
});

export const selectCheckDoneUnAssignFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});

export const selectCheckDoneUnAssignHasFilters = createSelector(
  selectCheckDoneUnAssignFilters,
  (filters) => {
    return [
      filters.search,
      filters.reporterUserCrmProfileID,
      filters.assigneeUserCrmProfileIDs.length,
      filters.dateRange.length,
      filters.ticketActionStatusID,
    ].some(Boolean);
  },
);

export const selectCheckDoneUnAssignData = createSelector(selectState, ({ data }) => {
  return data;
});

const selectCheckDoneUnAssignDataFilteredByTickets = createSelector(
  selectCheckDoneUnAssignData,
  selectTicketsDataFilteredMap,
  (ticketActionItems, ticketsMap) => {
    return ticketActionItems.filter((ticketActionItem) => {
      return !!ticketsMap[ticketActionItem.ticketID];
    });
  },
);

const selectCheckDoneUnAssignDataFull = createSelector(
  selectCheckDoneUnAssignDataFilteredByTickets,
  selectSourceCrmUsersAllMap,
  (ticketItems, mapUsers) => {
    return ticketItems.map((ticketItem) => {
      const { durationInMinutes, countAttached, count } = calcTrackingData(ticketItem.tracking);

      const unattached = ticketItem.tracking
        .filter(({ isAttached }) => isAttached === false)
        .sort((a, b) => convertToDate(a.date).getTime() - convertToDate(b.date).getTime());

      const _timeTrackingStart = unattached.at(0)?.date;
      const _timeTrackingEnd = unattached.at(-1)?.date;

      return {
        ...ticketItem,
        _reporter: mapUsers[ticketItem.reporterUserCrmProfileID],
        _assigner: mapUsers[ticketItem.assigneeUserCrmProfileID],
        _timeTrackingInMinutes: durationInMinutes,
        _timeTrackingAttachedCount: countAttached,
        _timeTrackingCount: count,
        _timeTrackingStart,
        _timeTrackingEnd,
      };
    });
  },
);

const selectCheckDoneUnAssignDataFiltered = createSelector(
  selectCheckDoneUnAssignDataFull,
  selectCheckDoneUnAssignFilters,
  selectCheckDoneTicketsSelectedID,
  (items, filters, selectedTicketID) => {
    const {
      search,
      reporterUserCrmProfileID,
      assigneeUserCrmProfileIDs,
      dateRange,
      ticketActionStatusID,
    } = filters;

    return items.filter((item) => {
      const res: boolean[] = [true];

      if (selectedTicketID) {
        res.push(item.ticketID === selectedTicketID);
      }

      if (search) {
        res.push(
          [
            item.title.includes(search),
            item._reporter?.fullName.includes(search),
            item._assigner?.fullName.includes(search),
            item.itemKey?.includes(search),
          ].some(Boolean),
        );
      }

      if (reporterUserCrmProfileID) {
        res.push(reporterUserCrmProfileID === item.reporterUserCrmProfileID);
      }

      if (assigneeUserCrmProfileIDs.length) {
        res.push(assigneeUserCrmProfileIDs.some((id) => id === item.assigneeUserCrmProfileID));
      }

      if (ticketActionStatusID) {
        res.push(ticketActionStatusID === item.ticketActionItemStatusID);
      }

      if (dateRange.length === 2) {
        res.push(isAcrossIntervals([item.startDateAndTime, item.endDateAndTime], dateRange));
      }

      return res.every(Boolean);
    });
  },
);

export const selectCheckDoneUnAssignItemsWithTicket = createSelector(
  selectCheckDoneUnAssignDataFiltered,
  selectTicketsDataFullMap,
  (actionItems, ticketsMap) => {
    const items = actionItems.map((actionItem) => {
      const ticket = ticketsMap[actionItem.ticketID];
      return {
        ...actionItem,
        _ticket: ticket,
      };
    });
    return sortBy(items, (row) => row._ticket._customer.title);
  },
);

export const selectCheckDoneUnAssignItemsWithTicketChecked = createSelector(
  selectCheckDoneUnAssignItemsWithTicket,
  (rows) => {
    return rows.filter(({ __checked }) => __checked);
  },
);
export const selectCheckDoneUnAssignIsSelectionLimit = createSelector(
  selectCheckDoneUnAssignItemsWithTicketChecked,
  (rows) => {
    return rows.length >= 20;
  },
);

export const selectCheckDoneUnAssignItemsWithTicketGrouped = createSelector(
  selectCheckDoneUnAssignItemsWithTicket,
  (ticketActionItems) => {
    const groups = groupBy(ticketActionItems, 'assigneeUserCrmProfileID');

    return Object.entries(groups).map(([assigneeUserCrmProfileID, groupByAssigner]) => {
      const firstItem = groupByAssigner[0];

      return {
        id: assigneeUserCrmProfileID,
        assigner: firstItem._assigner,
        actionItems: groupByAssigner,
      };
    });
  },
);
export const selectCheckDoneUnAssignView = createSelector(
  selectCheckDoneUnAssignFilters,
  ({ assigneeUserCrmProfileIDs }) => {
    if (assigneeUserCrmProfileIDs.length > 1) {
      return 'board';
    }
    return 'list';
  },
);

export const selectCheckDoneUnAssignCustomerID = createSelector(
  selectCheckDoneTicketsFilters,
  (filters) => {
    return filters.customerID;
  },
);

export const selectCheckDoneUnAssignDisableSelectable = createSelector(
  selectCheckDoneUnAssignCustomerID,
  (customerID) => {
    return !customerID;
  },
);
