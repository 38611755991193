import { Paper, PaperProps, Typography, TypographyProps } from '@mui/material';
import React from 'react';

interface Props extends PaperProps {
  title?: string;
  children?: React.ReactNode;
  PropsChildren?: Partial<TypographyProps>;
}

export const IntoItemWrapper: React.FC<Props> = ({ title, children, PropsChildren, ...rest }) => {
  return (
    <Paper
      {...rest}
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gap: 0.3,
        paddingX: 1,
        paddingY: 0.5,
        ...rest?.sx,
      }}
    >
      <Typography color={'text.secondary'} variant={'caption'} component={'div'}>
        {title}
      </Typography>
      <Typography variant={'caption'} component={'div'} alignSelf={'center'} {...PropsChildren}>
        {children}
      </Typography>
    </Paper>
  );
};
