import { createAsyncThunk } from '@reduxjs/toolkit';
import { ITicket, ServiceTickets } from 'services/tickets';
import * as dynamic from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import { selectAppUserID } from '../auth';
import { AppAsyncThunkConfig } from '../index';

const SELECT_TICKET = dynamic.select(
  'id',
  'title',
  'itemKey',
  'done',
  'isPrivate',
  'mustTrackTime',

  'ticketTypeID',
  'ticketStatusID',
  'priorityID',

  'ownerUserCrmProfileID',
  'defaultReporterUserCrmProfileID',

  'projectID',
);

export const actionCheckDoneTicketsFetchAll = createAsyncThunk<
  ITicket[],
  void,
  AppAsyncThunkConfig
>('CHECK_DONE_TICKETS/actionCheckDoneTicketsFetchAll', async (_, { getState }) => {
  try {
    const appUserID = selectAppUserID(getState());
    const params = {
      filter: dynamic
        .mergeFilters(
          dynamic.makeFilter('isActive', true, dynamic.equals),
          dynamic
            .mergeFilters(
              dynamic.makeFilter('isPrivate', false, dynamic.equals),
              dynamic.makeFilter('ownerUserCrmProfileID', appUserID, dynamic.equals),
            )
            .join('||'),
        )
        .join('&&'),
      select: SELECT_TICKET,
      orderBy: dynamic.orderBy('rowIndex', 'asc'),
    };

    const result = await ServiceTickets.getAllDynamic<ITicket, typeof params>(params);
    return result.data.value;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionCheckDoneTicketsFetchItem = createAsyncThunk<
  ITicket,
  string,
  AppAsyncThunkConfig
>('CHECK_DONE_TICKETS/actionCheckDoneTicketsFetchItem', async (id) => {
  try {
    const params = {
      select: SELECT_TICKET,
    };

    const result = await ServiceTickets.getDynamic(id, params);
    return result.data;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
