import { ValueFileUploader } from 'utils/file-uploader';
import { FixServerObject } from 'utils/types';

const API = 'TicketActionItemImages';

export const API_TICKET_ACTION_ITEM_IMAGES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ITicketActionItemImage, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ITicketActionItemImage, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface ITicketActionItemImage
  extends FixServerObject<Components.Schemas.TicketActionItemImage, 'id' | 'ticketActionItemID'> {}

export interface AppTicketActionItemImage extends Omit<ITicketActionItemImage, 'image'> {
  image: ValueFileUploader;
}
