import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../index';

const selectState = (state: AppState) => state.notificationsNotDone;

export const selectNotificationsNotDoneStatus = createSelector(
  selectState,
  ({ isLoading, error, isInit, isOpen }) => {
    return { isLoading, error, isInit, isOpen };
  },
);

export const selectNotificationsNotDoneIsOpen = createSelector(
  selectNotificationsNotDoneStatus,
  ({ isOpen }) => {
    return isOpen;
  },
);

const selectNotificationsNotDoneData = createSelector(selectState, ({ data }) => {
  return data;
});

export const selectNotificationsNotDoneDataVisible = createSelector(
  selectNotificationsNotDoneData,
  (data) => {
    return data.slice(0, 5);
  },
);

export const selectNotificationsNotDoneCount = createSelector(selectState, ({ count }) => {
  return count;
});
