import { DialogActions, Paper, Typography } from '@mui/material';
import { ButtonCancel, ButtonRemove, ButtonRestore } from 'components/buttons';
import { NativeScroll } from 'components/native-scroll';
import { BaseCommentDescription } from 'components/tickets';
import { withConfirmClick } from 'hoc/with-confirm-click';
import { useAppSelector, useEffectNotifyError, useTranslate } from 'hooks';
import React, { useCallback } from 'react';
import { apiNotes } from 'services/notes';
import { isFulfilledMutation } from 'utils/service';
import { selectSourceCustomersAllMap } from '../../../../../../services/customers';
import { DialogHeader } from '../../../../../dialog-header';

const ButtonDelete = withConfirmClick(ButtonRemove);

const useGetQuery = apiNotes.useGetNoteQuery;
const useArchiveMutation = apiNotes.useToggleArchiveNoteMutation;
const useDeleteMutation = apiNotes.useDeleteNoteMutation;

interface Props {
  itemID: string;
  onClose: () => void;
  onDone: () => void;
}
export const ContentPreview: React.FC<Props> = ({ itemID, onClose, onDone }) => {
  const { t, tp } = useTranslate();

  const resultGet = useGetQuery(itemID);
  useEffectNotifyError(resultGet.error);

  const initData = resultGet?.data;

  const [archiveItem, resultArchive] = useArchiveMutation();
  useEffectNotifyError(resultArchive.error);
  const onRestore = useCallback(async () => {
    const res = await archiveItem({ id: itemID, value: false });

    if (isFulfilledMutation(res)) {
      onDone();
    }
  }, [archiveItem, onDone, itemID]);

  const [deleteItem, resultDelete] = useDeleteMutation();
  useEffectNotifyError(resultDelete.error);
  const onDelete = useCallback(async () => {
    const res = await deleteItem({ id: itemID });

    if (isFulfilledMutation(res)) {
      onDone();
    }
  }, [deleteItem, onDone, itemID]);

  const isLoading = resultGet.isFetching || resultArchive.isLoading || resultDelete.isLoading;

  const mapCustomers = useAppSelector(selectSourceCustomersAllMap);
  const customer = initData?.customerID ? mapCustomers[initData?.customerID] : null;

  return (
    <>
      <DialogHeader
        title={customer?.title}
        bColor={customer?.color}
        sx={{ fontSize: 20 }}
        color={'grey'}
        onClose={onClose}
      />
      <NativeScroll
        mode={'always'}
        sx={{
          p: 2,
        }}
      >
        <Typography variant={'subtitle1'} component={'h4'} color={'primary'} mb={2}>
          {initData?.title}
        </Typography>

        {(initData?.description || !!initData?.images.length) && (
          <Paper
            sx={{
              paddingX: 1.6,
              paddingTop: 1,
              paddingBottom: 1.6,
            }}
          >
            <Typography color={'text.secondary'} variant={'caption'} component={'div'} mb={0.5}>
              {t('description')}
            </Typography>
            <BaseCommentDescription description={initData?.description} images={initData?.images} />
          </Paper>
        )}
      </NativeScroll>
      <DialogActions>
        <ButtonDelete
          sx={{ mr: 'auto' }}
          confirmMessage={tp('delete-confirm')}
          onClick={onDelete}
          disabled={isLoading}
        />
        <ButtonCancel onClick={onClose} />
        <ButtonRestore onClick={onRestore} disabled={isLoading} />
      </DialogActions>
    </>
  );
};
