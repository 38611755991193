import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { SvgLogo } from 'components/svg-icons/logo';
import { useTranslate } from 'hooks';
import backgroundUrl from 'images/login-bacground.jpg';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';

import VARS from 'styles/config.scss';
import style from './index.module.scss';

export const LoginLayout = memo(() => {
  const { t } = useTranslate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return (
    <Box className={style.wrap}>
      <Box className={style.header}>
        <Box textAlign={'center'}>
          <SvgLogo
            color={'primary'}
            sx={{ width: isMobile ? 111 : 195, height: isMobile ? 40 : 70 }}
          />
          {!isMobile && <img className={style.image} src={backgroundUrl} alt={''} />}
        </Box>
      </Box>
      <Box className={style.content}>
        <Box className={style.form}>
          <Outlet />
        </Box>
      </Box>
      <Box className={style.footer}>
        <Typography
          component={'div'}
          variant={'caption'}
          textAlign={'center'}
          color={VARS.colorGrey}
        >
          {t('copyright')}
        </Typography>
      </Box>
    </Box>
  );
});
