import React, { useCallback, useContext, useMemo, useReducer, useRef } from 'react';
import { DynamicOrder } from 'utils/dynamic';
import { DeepPartial } from 'utils/types';

interface Pagination {
  page: number;
  take: number;
}

interface State<F> {
  filters: F;
  pagination: Pagination;
  orderBy: DynamicOrder;
}

type Actions<F> =
  | { type: 'FILTERS_SET'; payload: DeepPartial<F> }
  | { type: 'FILTERS_RESET'; payload: DeepPartial<F> }
  | { type: 'PAGE_SET'; payload: DeepPartial<Pagination> }
  | { type: 'ORDER_SET'; payload: DeepPartial<DynamicOrder> };

const createReducer =
  <F = any,>() =>
  (state: State<F>, action: Actions<F>): State<F> => {
    switch (action.type) {
      case 'FILTERS_RESET':
        return {
          ...state,
          filters: { ...state.filters, ...action.payload },
          pagination: { ...state.pagination, page: 1 },
        };
      case 'FILTERS_SET':
        return {
          ...state,
          filters: { ...state.filters, ...action.payload },
          pagination: { ...state.pagination, page: 1 },
        };
      case 'PAGE_SET':
        return {
          ...state,
          pagination: { ...state.pagination, ...action.payload },
        };
      case 'ORDER_SET':
        return {
          ...state,
          orderBy: { ...state.orderBy, ...action.payload },
          pagination: { ...state.pagination, page: 1 },
        };
    }
    return state;
  };

type RtkQueryReducerResult<F = any> = {
  state: State<F>;
  dispatch: React.Dispatch<Actions<F>>;
  filtersReset: () => void;
  filtersSet: (filters: DeepPartial<F>) => void;
  paginationSet: (pagination: Partial<Pagination>) => void;

  orderSet: (data: Partial<DynamicOrder>) => void;
  isHasFilters: boolean;
};

export const useRtkQueryReducer = <F,>(
  initialState: DeepPartial<State<F>>,
): RtkQueryReducerResult<F> => {
  const refInitState = useRef(initialState);
  const reducer = useMemo(() => createReducer<F>(), []);

  const [state, dispatch] = useReducer(reducer, initialState, (arg) => {
    return {
      filters: { ...arg.filters },
      pagination: { take: 20, page: 1, ...arg.pagination },
      orderBy: { ...arg.orderBy },
    } as State<F>;
  });

  const filtersReset = useCallback(() => {
    if (refInitState.current.filters) {
      dispatch({ type: 'FILTERS_RESET', payload: refInitState.current.filters });
    }
  }, []);

  const filtersSet = useCallback((filters: DeepPartial<F>) => {
    dispatch({ type: 'FILTERS_SET', payload: filters });
  }, []);

  const paginationSet = useCallback((data: Partial<Pagination>) => {
    dispatch({ type: 'PAGE_SET', payload: data });
  }, []);

  const orderSet = useCallback((data: Partial<DynamicOrder>) => {
    if (
      data.order === null &&
      refInitState.current.orderBy &&
      data.field !== refInitState.current.orderBy?.field
    ) {
      return dispatch({ type: 'ORDER_SET', payload: refInitState.current.orderBy });
    }
    dispatch({ type: 'ORDER_SET', payload: data });
  }, []);

  const isHasFilters = useMemo(() => {
    return JSON.stringify(state.filters) !== JSON.stringify(refInitState.current.filters);
  }, [state.filters, refInitState]);

  return { state, dispatch, filtersReset, filtersSet, paginationSet, orderSet, isHasFilters };
};

const RtkQueryReducerContext = React.createContext<RtkQueryReducerResult | null>(null);

export const useRtkQueryReducerContext = <F,>() => {
  const context = useContext<RtkQueryReducerResult<F>>(RtkQueryReducerContext as any);

  if (!context) {
    throw new Error('RtkQueryReducerContext is required');
  }

  return context;
};

interface Props<F> extends RtkQueryReducerResult<F> {
  children: React.ReactNode;
}
export const RtkQueryReducerProvider = <F,>(props: Props<F>) => {
  const { children, ...value } = props;
  return (
    <RtkQueryReducerContext.Provider value={value}>{children}</RtkQueryReducerContext.Provider>
  );
};
