import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { endOfMonth, startOfMonth } from 'date-fns';
import { DateValue } from 'utils/dates';
import { apiTicketActionItems } from '../../services/ticket-action-items';
import { actionCheckDoneTrashFetchAll } from './actions';
import { ITicketActionItemCheckDoneTrash, PREFIX } from './helpers';

export interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;

  filters: Filters;
  data: ITicketActionItemCheckDoneTrash[];
}
interface Filters {
  search: string;
  assigneeUserCrmProfileIDs: string[];
  reporterUserCrmProfileID: string;
  dateRange: DateValue[];
  ticketActionStatusID: string;
}

const initState = (): State => {
  const currentDate = new Date();
  const startDate = startOfMonth(currentDate);
  const endDate = endOfMonth(currentDate);
  return {
    error: null,
    isLoading: false,
    isInit: false,

    filters: {
      search: '',
      assigneeUserCrmProfileIDs: [],
      reporterUserCrmProfileID: '',

      ticketActionStatusID: '',
      dateRange: [startDate, endDate],
    },
    data: [],
  };
};

const slice = createSlice({
  name: PREFIX,
  initialState: initState(),
  reducers: {
    setFilters(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    clearFilters(state) {
      state.filters = initState().filters;
    },
    removeFilterAssigner(state, action: PayloadAction<string>) {
      state.filters.assigneeUserCrmProfileIDs = state.filters.assigneeUserCrmProfileIDs.filter(
        (id) => {
          return id !== action.payload;
        },
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionCheckDoneTrashFetchAll.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(actionCheckDoneTrashFetchAll.fulfilled, (state, action) => {
        const { payload: value } = action;

        state.data = value;
        state.isLoading = false;
        state.isInit = true;
      })
      .addCase(actionCheckDoneTrashFetchAll.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });

    builder.addMatcher(
      isAnyOf(
        apiTicketActionItems.endpoints.deleteTicketActionItem.matchPending,
        apiTicketActionItems.endpoints.activateTicketActionItem.matchPending,
      ),
      (state, action) => {
        state.data = state.data.filter((ticketAction) => {
          return ticketAction.id !== action.meta.arg.originalArgs.id;
        });
      },
    );
  },
});

export const actionsCheckDoneTrash = slice.actions;
export const reducerCheckDoneTrash = slice.reducer;
