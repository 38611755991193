import { yupResolver } from '@hookform/resolvers/yup';
import { AppInput } from 'components/app-input';
import { DialogApply } from 'components/dialog-apply';
import { useEffectNotifyError, useFieldProps, useTranslate } from 'hooks';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { apiDoneItems } from 'services/done-items';
import { isFulfilledMutation } from 'utils/service';
import * as yup from 'yup';
import { InferType } from 'yup';
import { ActionComponentProps } from '../../models';

const usePatchMutation = apiDoneItems.usePatchDoneItemBulkMutation;
const schema = yup.object({
  description: yup.string().required('rule-required').trim().default(''),
});

type FormModel = InferType<typeof schema>;

interface Props extends ActionComponentProps {}
export const DialogActionUpdateDescription: React.FC<Props> = ({ selectedRows, onClose }) => {
  const { tp } = useTranslate();
  const [action, resultAction] = usePatchMutation();
  useEffectNotifyError(resultAction.error);

  const { control, handleSubmit } = useForm({
    defaultValues: schema.cast({}),
    resolver: yupResolver(schema),
  });
  const getFieldProps = useFieldProps();

  const onApply = useCallback(
    async (formData: FormModel) => {
      const ids = Array.from(
        new Set(
          selectedRows.map((row) => row.doneItems.map((doneItem) => doneItem.id)).flat(),
        ).keys(),
      );

      const res = await action({
        ids,
        data: formData,
      });

      if (isFulfilledMutation(res)) {
        onClose();
      }
    },
    [onClose, selectedRows, action],
  );

  const isLoading = resultAction.isLoading;

  return (
    <DialogApply
      isLoading={isLoading}
      onClose={onClose}
      title={tp('update-report-description')}
      onApply={handleSubmit(onApply)}
    >
      <Controller
        control={control}
        name={'description'}
        render={(renderProps) => {
          return (
            <AppInput
              {...getFieldProps(renderProps)}
              multiline={true}
              minRows={4}
              disabled={isLoading}
            />
          );
        }}
      />
    </DialogApply>
  );
};
