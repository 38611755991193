import React, { forwardRef, useCallback, useMemo } from 'react';

import { Tab, TabProps, Tabs, TabsProps } from '@mui/material';
import clsx from 'clsx';
import { useTranslate } from 'hooks';
import styles from './index.module.scss';

export interface RadioButtonOption {
  id: any;
  title: React.ReactNode;
  TabProps?: Partial<TabProps>;
}

interface Props extends Omit<TabsProps, 'onChange'> {
  options: RadioButtonOption[];
  includeAll?: boolean;
  onChange: (value: this['value']) => void;
  disabled?: boolean;
}

export const RadioButtons = forwardRef<HTMLDivElement, Props>(
  ({ includeAll = true, onChange, options, className, classes, disabled, ...rest }, ref) => {
    const { tp } = useTranslate();
    const innerOptions = useMemo(() => {
      return includeAll ? [{ id: 'all', title: tp('all') }, ...options] : options;
    }, [options, includeAll, tp]);

    const onChangeWrap = useCallback<Required<TabsProps>['onChange']>(
      (e, v) => {
        onChange(v);
      },
      [onChange],
    );
    return (
      <Tabs
        ref={ref}
        className={clsx(styles.root, className)}
        {...rest}
        onChange={onChangeWrap}
        classes={{
          ...classes,
          flexContainer: clsx(styles.flexContainer, classes?.flexContainer),
          indicator: clsx(styles.indicator, classes?.indicator),
        }}
      >
        {innerOptions.map((item) => {
          return (
            <Tab
              key={item.id}
              value={item.id}
              label={item.title}
              {...item?.TabProps}
              disabled={disabled || item.TabProps?.disabled}
              classes={{
                ...item.TabProps?.classes,
                root: clsx(styles.tabRoot, item.TabProps?.classes?.root),
                selected: clsx(styles.tabSelected, item.TabProps?.classes?.selected),
                disabled: clsx(styles.tabDisabled, item.TabProps?.classes?.disabled),
              }}
            />
          );
        })}
      </Tabs>
    );
  },
);
