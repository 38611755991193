import {} from 'axios';
import React, { memo, useEffect } from 'react';
import { apiApp, apiFree, isAxiosError } from 'utils/service';

const instances = [apiApp, apiFree];

interface Props {
  children: React.ReactNode;
}
export const AxiosInterceptorPermissionError = memo<Props>(({ children }) => {
  useEffect(() => {
    const interceptors = instances.map((instance) => {
      const id = instance.interceptors.response.use(undefined, (error) => {
        if (isAxiosError(error)) {
          if (error.response?.status === 403) {
            error.response.data = 'permission-error';
          }
        }
        return Promise.reject(error);
      });
      return { instance, id };
    });
    return () => {
      interceptors.forEach(({ id, instance }) => {
        instance.interceptors.request.eject(id);
      });
    };
  }, []);

  return <>{children}</>;
});
