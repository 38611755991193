import { createAsyncThunk } from '@reduxjs/toolkit';
import { ITicketActionItemExtended, ServiceTicketActionItems } from 'services/ticket-action-items';
import * as dynamic from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import { selectAppUserID } from '../auth';
import { AppAsyncThunkConfig } from '../index';
import { ITicketActionItemCheckDoneTrash, PREFIX } from './helpers';

const SELECT_TICKET_ITEMS = dynamic.select(
  'id',
  'title',
  'itemKey',

  'reporterUserCrmProfileID',
  'assigneeUserCrmProfileID',
  'ticketActionItemStatusID',

  'startDateAndTime',
  'endDateAndTime',

  'ticketID',
);

export const actionCheckDoneTrashFetchAll = createAsyncThunk<
  ITicketActionItemCheckDoneTrash[],
  void,
  AppAsyncThunkConfig
>(`${PREFIX}/actionCheckDoneTrashFetchAll`, async (_, { getState }) => {
  try {
    const appUserID = selectAppUserID(getState());
    const params = {
      filter: dynamic
        .mergeFilters(
          dynamic.makeFilter('isActive', false, dynamic.equals),
          dynamic
            .mergeFilters(
              dynamic.makeFilter('ticket.isPrivate', false, dynamic.equals),
              dynamic.makeFilter('ticket.ownerUserCrmProfileID', appUserID, dynamic.equals),
            )
            .join('||'),
        )
        .join('&&'),
      select: SELECT_TICKET_ITEMS,
    };
    const result = await ServiceTicketActionItems.getAllDynamic<
      ITicketActionItemExtended,
      typeof params
    >(params);
    return result.data.value;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
