import { TabsPage } from 'components/tabs-page';
import { APP_ROUTES } from 'configs';
import { useTranslate } from 'hooks';
import { memo, useMemo } from 'react';

export const SettingsLayout = memo((props) => {
  const { tp } = useTranslate();
  const tabs = useMemo(() => {
    return [
      {
        value: APP_ROUTES.LABELS.path,
        to: APP_ROUTES.LABELS().config,
        label: tp('labels'),
      },
      {
        value: APP_ROUTES.SETTINGS.path,
        to: APP_ROUTES.SETTINGS().config,
        label: tp('customers'),
      },
      {
        value: APP_ROUTES.PROJECTS.path,
        to: APP_ROUTES.PROJECTS().config,
        label: tp('projects'),
      },
      {
        value: APP_ROUTES.USERS.path,
        to: APP_ROUTES.USERS().config,
        label: tp('users'),
      },
      {
        value: APP_ROUTES.PERMISSIONS.path,
        to: APP_ROUTES.PERMISSIONS().config,
        label: tp('permissions'),
      },
      {
        value: APP_ROUTES.ALERTS.path,
        to: APP_ROUTES.ALERTS().config,
        label: tp('alerts'),
      },
      {
        value: APP_ROUTES.TIME_TRACKING_ACTIVITIES.path,
        to: APP_ROUTES.TIME_TRACKING_ACTIVITIES().config,
        label: tp('time-tracking-activities'),
      },
      {
        value: APP_ROUTES.TICKET_TYPES.path,
        to: APP_ROUTES.TICKET_TYPES().config,
        label: tp('ticket-types'),
      },
      {
        value: APP_ROUTES.TICKET_STATUSES.path,
        to: APP_ROUTES.TICKET_STATUSES().config,
        label: tp('ticket-statuses'),
      },
      {
        value: APP_ROUTES.PRIORITIES.path,
        to: APP_ROUTES.PRIORITIES().config,
        label: tp('priorities'),
      },
      {
        value: APP_ROUTES.TICKET_ACTION_ITEM_STATUSES.path,
        to: APP_ROUTES.TICKET_ACTION_ITEM_STATUSES().config,
        label: tp('ticket-action-item-statuses'),
      },
    ];
  }, [tp]);
  return <TabsPage tabs={tabs} />;
});
