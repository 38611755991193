import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IExpense } from 'services/expenses';

type IDuplicatedExpenseItemData = null | Pick<
  IExpense,
  | 'invoiceDate'
  | 'supplierID'
  | 'expenseCategoryID'
  | 'description'
  | 'amount'
  | 'isActive'
  | 'oneTimeExpense'
  | 'isIncludeVAT'
>;

interface State {
  duplicatedExpenseItemData: IDuplicatedExpenseItemData;
}

const initState = (): State => {
  return {
    duplicatedExpenseItemData: null,
  };
};

const slice = createSlice({
  name: 'EXPENSES',
  initialState: initState(),
  reducers: {
    actionSetDuplicatedExpenseItem(state, action: PayloadAction<IDuplicatedExpenseItemData>) {
      state.duplicatedExpenseItemData = action.payload;
    },
  },
});

export const { actionSetDuplicatedExpenseItem } = slice.actions;
export const reducerExpenses = slice.reducer;
