import { isDateLike } from 'utils/dates';
import * as yup from 'yup';
import { InferType } from 'yup';

const API = 'Notifications';

export const API_NOTIFICATIONS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<INotification, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<INotification, 'id'>) => `${API}/Delete/${data.id}`,
};

export const schemaNotification = yup.object().shape({
  id: yup.string().required('rule-required').default(undefined),
  message: yup.string().required('rule-required').trim().default(''),
  reporterUserCrmProfileID: yup.string().required('rule-required').default(''),
  ticketActionItemID: yup.string().notRequired().nullable().default(null),
  ticketActionItemCommentID: yup.string().notRequired().nullable().default(null),
  entryDate: yup
    .string()
    .required('rule-required')
    .test('date-test', 'rule-date', isDateLike)
    .default(() => new Date().toISOString()),
});

export type INotification = InferType<typeof schemaNotification>;

export const schemaNotificationPost = schemaNotification.pick([
  'message',
  'reporterUserCrmProfileID',
  'ticketActionItemID',
  'ticketActionItemCommentID',
]);

export type NotificationPostInput = InferType<typeof schemaNotificationPost>;

export const schemaNotificationPatch = schemaNotificationPost.concat(
  schemaNotification.pick(['id']),
);

export type NotificationPatchInput = InferType<typeof schemaNotificationPatch>;

export type NotificationDeleteInput = Pick<INotification, 'id'>;
