import React, { useEffect, useMemo, useRef } from 'react';

import { FormControlLabel, Grid } from '@mui/material';
import { AppCheckbox } from 'components/app-checkbox';
import { AppInput } from 'components/app-input';
import { AppSelect, defaultGetOptionDisabled } from 'components/app-select';
import { HtmlEditor } from 'components/html-editor';
import { DEFAULT_INIT_AUTOSIZE } from 'components/html-editor-preview';
import { getUserDetailsOptionLabel } from 'components/render-option';
import { SelectEmployees } from 'components/select-employees';
import {
  useFieldProps,
  useSourcePriorities,
  useSourceProjects,
  useSourceTicketStatuses,
  useTranslate,
} from 'hooks';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
  isLoading: boolean;
}

export const Form: React.FC<Props> = ({ isLoading }) => {
  const { tp } = useTranslate();
  const { control, watch, setValue } = useFormContext();
  const getFieldProps = useFieldProps();

  const sourceTicketStatuses = useSourceTicketStatuses();

  const sourceTicketPriorities = useSourcePriorities();
  const defaultPriority = useMemo(() => {
    return (sourceTicketPriorities.data || []).find((priority) => priority.isDefault);
  }, [sourceTicketPriorities.data]);
  const refDefPriorityOnce = useRef(false);
  const priorityID = watch('priorityID');
  useEffect(() => {
    if (refDefPriorityOnce.current) return;
    if (!defaultPriority) return;
    if (priorityID) return;

    setValue('priorityID', defaultPriority.id);
    refDefPriorityOnce.current = true;
  }, [defaultPriority, priorityID, setValue]);

  const sourceProjects = useSourceProjects();
  return (
    <Grid container rowSpacing={0.8} columnSpacing={2}>
      <Grid item md={12}>
        <Controller
          control={control}
          name={'title'}
          render={(renderProps) => (
            <AppInput {...getFieldProps(renderProps)} multiline maxRows={4} disabled={isLoading} />
          )}
        />
      </Grid>
      <Grid item md={4}>
        <Controller
          control={control}
          name={'ticketStatusID'}
          render={(renderProps) => (
            <AppSelect
              {...getFieldProps(renderProps)}
              options={sourceTicketStatuses.data}
              loading={sourceTicketStatuses.isLoading}
              disabled={isLoading}
              disableClearable
            />
          )}
        />
      </Grid>
      <Grid item md={4}>
        <Controller
          control={control}
          name={'priorityID'}
          render={(renderProps) => (
            <AppSelect
              {...getFieldProps(renderProps, { label: tp('default-priority') })}
              options={sourceTicketPriorities.data}
              loading={sourceTicketPriorities.isLoading}
              disabled={isLoading}
              disableClearable
            />
          )}
        />
      </Grid>
      <Grid item md={4}>
        <Controller
          control={control}
          name={'projectID'}
          render={(renderProps) => (
            <AppSelect
              {...getFieldProps(renderProps)}
              options={sourceProjects.data}
              loading={sourceProjects.isLoading}
              disabled={isLoading}
              disableClearable
            />
          )}
        />
      </Grid>
      <Grid item md={4}>
        <Controller
          control={control}
          name={'defaultReporterUserCrmProfileID'}
          render={(renderProps) => (
            <SelectEmployees
              {...getFieldProps(renderProps)}
              disabled={isLoading}
              disableClearable
              getOptionLabel={getUserDetailsOptionLabel}
              getOptionDisabled={defaultGetOptionDisabled}
            />
          )}
        />
      </Grid>
      <Grid item md={4}>
        <Controller
          control={control}
          name={'ownerUserCrmProfileID'}
          render={(renderProps) => (
            <SelectEmployees
              {...getFieldProps(renderProps)}
              disabled={isLoading}
              disableClearable
              getOptionLabel={getUserDetailsOptionLabel}
              getOptionDisabled={defaultGetOptionDisabled}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Controller
          control={control}
          name={'mustTrackTime'}
          render={(renderProps) => {
            const { label, value, onChange, onBlur } = getFieldProps(renderProps);
            return (
              <FormControlLabel
                sx={{ mt: 1 }}
                control={
                  <AppCheckbox
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={isLoading}
                  />
                }
                label={label}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={'description'}
          render={(renderProps) => (
            <HtmlEditor
              {...getFieldProps(renderProps)}
              disabled={isLoading}
              editorProps={{ init: DEFAULT_INIT_AUTOSIZE }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
