import { yupResolver } from '@hookform/resolvers/yup';
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { ButtonCancel } from 'components/buttons';
import { DialogHeader } from 'components/dialog-header';
import { BaseBreadcrumbs, BaseBreadcrumbsItem } from 'components/tickets';
import { useTranslate } from 'hooks';
import React from 'react';
import { useController, useForm } from 'react-hook-form';
import { schemaTicketActionItem } from 'services/ticket-action-items';
import { composeFunctions } from 'utils/other';
import { Unset } from 'utils/types';
import { InferType } from 'yup';
import style from './index.module.scss';

export const schemaTicket = schemaTicketActionItem.pick(['ticketID']);

interface RenderOptionProps {
  id: string;
  title: string;
  breadcrumbs: BaseBreadcrumbsItem[];
}
const Item: React.FC<RenderOptionProps> = ({ id, title, breadcrumbs, ...rest }) => {
  return (
    <FormControlLabel
      value={id}
      control={<Radio className={style.icon} />}
      classes={{
        root: style.item,
      }}
      label={
        <div>
          <BaseBreadcrumbs items={breadcrumbs} />
          <Typography component={'div'} mt={0.9} className={style.itemTitle}>
            {title}
          </Typography>
        </div>
      }
    />
  );
};

export type ModelTicket = InferType<typeof schemaTicket>;

interface Ticket {
  id: string;
  title: string;
  _customer: Unset<{ title: string }>;
  _project: Unset<{ title: string }>;
}
interface Status {
  id: string;
  labelKey: string;
  tickets: Ticket[];
}

interface DialogSelectTicketProps {
  isLoading?: boolean;
  onSubmit: (model: ModelTicket) => void;
  onCancel: () => void;
  statuses: Status[];
}
export const BaseSelectTicketDialog: React.FC<DialogSelectTicketProps> = ({
  isLoading,
  onSubmit,
  onCancel,
  statuses,
}) => {
  const { tp, t } = useTranslate();

  const { control, handleSubmit } = useForm({
    defaultValues: schemaTicket.cast({}),
    resolver: yupResolver(schemaTicket),
  });
  const { field } = useController({ control, name: 'ticketID' });

  return (
    <Dialog open={true} onClose={onCancel} fullWidth maxWidth={'md'}>
      <DialogHeader isLoading={isLoading} title={tp('create-an-action')} onClose={onCancel} />
      <DialogContent>
        <Typography variant={'subtitle2'} mb={1.3}>
          {tp('select-ticket')}
        </Typography>
        <RadioGroup
          value={field.value}
          onChange={composeFunctions(field.onChange, handleSubmit(onSubmit))}
        >
          <div className={style.group}>
            {statuses.map((status) => {
              return (
                <div key={status.id}>
                  <Typography component={'div'} className={style.title}>
                    {t(status.labelKey)}
                  </Typography>
                  <div>
                    {status.tickets.map((item) => {
                      const customer = item._customer;

                      const project = item._project;
                      const breadcrumbs = [
                        { label: customer?.title },
                        { label: project?.title },
                      ].filter((item) => item.label);

                      return (
                        <Item
                          id={item.id}
                          key={item.id}
                          breadcrumbs={breadcrumbs}
                          title={item.title}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <ButtonCancel onClick={onCancel} />
      </DialogActions>
    </Dialog>
  );
};
