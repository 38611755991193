import { Box } from '@mui/material';
import clsx from 'clsx';
import { CollapseDivider } from 'components/collapse-divider';
import React, { memo } from 'react';
import style from './index.module.scss';

interface Classes {
  root: string;
  in: string;
  panel: string;
  content: string;
}

interface Props {
  in: boolean;
  onClick: () => void;
  panel: React.ReactNode;
  children: React.ReactNode;
  classes?: Partial<Classes>;
}

export const CollapsePanel = memo<Props>(({ in: _isIn, panel, children, onClick, classes }) => {
  return (
    <Box className={clsx(style.root, classes?.root, !_isIn && clsx(style.rootSmall, classes?.in))}>
      <div className={clsx(style.left, classes?.panel)}>{panel}</div>
      <CollapseDivider isSmall={!_isIn} onClick={onClick} />
      <div className={clsx(style.right, classes?.content)}>{children}</div>
    </Box>
  );
});
