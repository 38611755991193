import { createSelector } from '@reduxjs/toolkit';
import { durationToMinutes } from 'utils/app-helpers';
import { calcPaginationState } from 'utils/service';
import { AppState } from '../index';

const selectState = (state: AppState) => state.timeTracking;

export const selectTimeTrackingStatuses = createSelector(
  selectState,
  ({ isLoading, error, isInit }) => {
    return { isLoading, error, isInit };
  },
);
export const selectTimeTrackingData = createSelector(selectState, ({ data }) => {
  return data;
});
export const selectTimeTrackingDataToMove = createSelector(selectTimeTrackingData, (data) => {
  return data.filter((item) => item._move.isSelected);
});
export const selectTimeTrackingDataToMoveWithError = createSelector(
  selectTimeTrackingData,
  (data) => {
    return data.filter((item) => item._move.error);
  },
);
export const selectTimeTrackingDataToMoveStatuses = createSelector(
  selectTimeTrackingDataToMove,
  (selectedItems) => {
    return {
      isAnyLoading: selectedItems.some(({ _move }) => _move.isLoading),
      isAnyError: selectedItems.some(({ _move }) => _move.error),
    };
  },
);
export const selectDataDuration = createSelector(selectState, ({ dataDuration }) => {
  return dataDuration;
});
export const selectTimeTrackingDuration = createSelector(selectDataDuration, (dataDuration) => {
  return dataDuration.reduce((acc, { duration }) => acc + durationToMinutes(duration), 0) / 60;
});
export const selectTimeTrackingBillableDuration = createSelector(
  selectDataDuration,
  (dataDuration) => {
    return (
      dataDuration.reduce(
        (acc, { billableDuration }) => acc + durationToMinutes(billableDuration),
        0,
      ) / 60
    );
  },
);
export const selectTimeTrackingFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});
export const selectTimeTrackingView = createSelector(selectState, ({ view }) => {
  return view;
});
export const selectTimeTrackingListOrder = createSelector(selectState, ({ listOrder }) => {
  return listOrder;
});
export const selectTimeTrackingPreparedValue = createSelector(selectState, ({ addNewPayload }) => {
  return addNewPayload;
});

export const selectTimeTrackingPreparedExistingValue = createSelector(
  selectState,
  ({ addExistingPayload }) => {
    return addExistingPayload;
  },
);
export const selectTimeTrackingOrderBy = createSelector(selectState, ({ orderBy }) => {
  return orderBy;
});
export const selectTimeTrackingPagination = createSelector(selectState, ({ pagination }) => {
  return calcPaginationState(pagination);
});
