import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';
import { NativeScroll } from 'components/native-scroll';
import { ForwardedRef, forwardRef, memo, UIEventHandler, useCallback, useRef } from 'react';
import { composeFunctions } from 'utils/other';
import style from './index.module.scss';

export { useTrackScrollPosition } from './hooks';
export {
  isHasScrollBottom,
  isHasScrollLeft,
  isHasScrollRight,
  isHasScrollTop,
  isHasScrollX,
  isHasScrollY,
} from './utils';

interface Props extends BoxProps {
  className?: string;
  verticalScroll?: boolean;
  horizontalScroll?: boolean;
  onScrollDown?: UIEventHandler<HTMLDivElement>;
  onScrollDownEnd?: UIEventHandler<HTMLDivElement>;
  onScrollUp?: UIEventHandler<HTMLDivElement>;
  mode?: 'hover' | 'always';
  size?: 'small' | 'medium';
}

const Component = (
  {
    className,
    children,
    verticalScroll = true,
    horizontalScroll = true,
    onScroll,
    onScrollUp,
    onScrollDown,
    onScrollDownEnd,
    mode,
    size = 'medium',
    ...rest
  }: Props,
  ref: ForwardedRef<typeof Box>,
) => {
  const scrollValueRef = useRef<{ scrollTop: number; scrollLeft: number }>({
    scrollTop: 0,
    scrollLeft: 0,
  });

  const onInnerScroll = useCallback<UIEventHandler<any>>(
    (e) => {
      const { scrollLeft, scrollTop, clientHeight, scrollHeight } = e.currentTarget;

      if (clientHeight + scrollTop === scrollHeight) {
        onScrollDownEnd && onScrollDownEnd(e);
      }

      if (scrollTop > scrollValueRef.current.scrollTop) {
        onScrollDown && onScrollDown(e);
      } else if (scrollTop < scrollValueRef.current.scrollTop) {
        onScrollUp && onScrollUp(e);
      }

      scrollValueRef.current = { scrollLeft, scrollTop };
    },
    [onScrollDown, onScrollUp, onScrollDownEnd],
  );

  return (
    <Box className={clsx(style.wrap, className)} {...rest}>
      <NativeScroll
        ref={ref}
        className={clsx(style.box, !verticalScroll && style.boxVerticalOff)}
        onScroll={composeFunctions(onInnerScroll, onScroll)}
        mode={mode}
        size={size}
      >
        {children}
      </NativeScroll>
    </Box>
  );
};

export const NativeCustomScroll = memo(forwardRef(Component)) as typeof Component;
