import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Divider, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { IconButtonGrey } from 'components/buttons';
import { FORMAT_TIME, TIME_FORMAT_DATE } from 'configs/const';
import { format } from 'date-fns';
import { useCurrentUser, useOpen, useTranslate } from 'hooks';
import React, { useCallback, useMemo, useRef } from 'react';
import { BaseImage } from 'services/@shared/images';
import { convertToDate, DateValue } from 'utils/dates';
import { Unset } from 'utils/types';
import { CommentUser } from '../../models';
import { BaseCommentDescription } from '../comment-description';
import { CommentLink } from '../comment-link';
import style from './index.module.scss';

export type CommentRenderEditorProps = {
  description: Unset;
  images: BaseImage[];
  onClose: () => void;
};
interface Props {
  user: CommentUser;
  createdDate: DateValue;
  updatedDate: DateValue;
  description: Unset;
  images: BaseImage[];
  renderEditor: (renderProps: CommentRenderEditorProps) => React.ReactNode;
  itemKey?: string | null;
  isHighlighted: boolean;
}
export const BaseCommentItem: React.FC<Props> = ({
  user,
  createdDate,
  updatedDate,
  description,
  renderEditor,
  itemKey,
  images,
  isHighlighted,
}) => {
  const { tp } = useTranslate();
  const { appUserID } = useCurrentUser();
  const isEditable = appUserID === user.id;

  const formatDate = useCallback((dateValue: DateValue) => {
    const date = convertToDate(dateValue);
    return [TIME_FORMAT_DATE, FORMAT_TIME].map((f) => format(date, f)).join(' at ');
  }, []);

  const createdAt = useMemo(() => {
    return formatDate(createdDate);
  }, [createdDate, formatDate]);
  const updatedAt = useMemo(() => {
    return formatDate(updatedDate);
  }, [updatedDate, formatDate]);

  const isWasEdit = createdAt !== updatedAt;

  const stateEdit = useOpen();

  const ref = useRef<HTMLDivElement>(null);
  const onClickEdit = useCallback(() => {
    stateEdit.onOpen();
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: 'smooth', inline: 'end', block: 'center' });
    }, 500);
  }, [stateEdit]);

  const descriptionElement = stateEdit.isOpen ? (
    renderEditor({ description, images, onClose: stateEdit.onClose })
  ) : (
    <BaseCommentDescription description={description} images={images} />
  );

  return (
    <div className={clsx(style.root, isHighlighted && style.highlighted)}>
      <div className={style.icon}>
        <Avatar sx={{ width: 24, height: 24 }} src={user.userPhoto} />
      </div>
      <Stack
        direction={'row'}
        flexWrap={'wrap'}
        gap={'0.1rem 0.8rem'}
        alignItems={'baseline'}
        className={style.wrap}
      >
        <Typography
          className={style.name}
          fontWeight={'bold'}
          alignSelf={'center'}
          component={'h5'}
        >
          {user.fullName}
        </Typography>
        <Stack
          direction={'row'}
          className={style.date}
          spacing={0.4}
          alignItems={'center'}
          alignSelf={'center'}
          divider={<Divider flexItem orientation={'vertical'} />}
        >
          <Typography
            title={tp('created')}
            variant={'caption'}
            component={'time'}
            color={'textSecondary'}
            noWrap
          >
            {createdAt}
          </Typography>
          {isWasEdit && (
            <Typography
              title={updatedAt}
              variant={'caption'}
              component={'time'}
              color={'textSecondary'}
            >
              {tp('updated')}
            </Typography>
          )}
        </Stack>
      </Stack>
      <div className={style.controls}>
        {itemKey && <CommentLink itemKey={itemKey} />}
        <IconButtonGrey
          className={clsx(!isEditable && style.hidden)}
          size={'small'}
          onClick={onClickEdit}
        >
          <EditIcon fontSize={'inherit'} sx={{ fontSize: '2rem' }} />
        </IconButtonGrey>
      </div>

      <div className={style.desc} ref={ref}>
        {descriptionElement}
      </div>
    </div>
  );
};
