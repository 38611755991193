import { Grid } from '@mui/material';
import { AppInput } from 'components/app-input';
import { AttachmentInput, AttachmentInputHandle } from 'components/attachment-input';
import { DEFAULT_INIT, IAllProps } from 'components/html-editor';
import { HtmlEditorLight } from 'components/html-editor-light';
import { mergeRef, useFieldProps } from 'hooks';
import React, { useCallback, useMemo, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NotePatchInput, NotePostInput } from 'services/notes';
import { AppDatePicker } from '../../../app-date-picker';

const EditorProps: IAllProps = {
  init: {
    ...DEFAULT_INIT,
    height: 250,
  },
};

type FormModel = NotePostInput | NotePatchInput;

interface Props {
  isLoading: boolean;
}
export const Form: React.FC<Props> = ({ isLoading }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormModel>();
  const getFieldProps = useFieldProps();

  const ref = useRef<AttachmentInputHandle>(null);
  const onInsertImages = useCallback((images: File[]) => {
    if (!ref.current) return;
    ref.current.addFiles(images);
  }, []);

  const sharedError = useMemo(() => {
    return Object.values(errors).find((error) => {
      return error.type === 'at-least-one-field';
    });
  }, [errors]);

  const getFieldPropsWithShared = useCallback<typeof getFieldProps>(
    (...args) => {
      const res = getFieldProps(...args);

      return {
        ...res,
        error: !!sharedError || res.error,
        helperText: sharedError?.message || res.helperText,
      };
    },
    [getFieldProps, sharedError],
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={true}>
        <Controller
          control={control}
          name={'title'}
          render={(renderProps) => {
            return <AppInput {...getFieldPropsWithShared(renderProps)} disabled={isLoading} />;
          }}
        />
      </Grid>
      <Grid item xs={'auto'}>
        <Controller
          control={control}
          name={'entryDate'}
          render={(renderProps) => {
            return (
              <AppDatePicker {...getFieldProps(renderProps)} disabled={isLoading} />
            );
          }}
        />
      </Grid>
      <Grid item xs={'auto'}>
        <Controller
          control={control}
          name={'dueDate'}
          render={(renderProps) => {
            return (
              <AppDatePicker {...getFieldProps(renderProps)} disabled={isLoading} disablePast />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={'description'}
          render={(renderProps) => {
            return (
              <HtmlEditorLight
                editorProps={EditorProps}
                {...getFieldPropsWithShared(renderProps)}
                disabled={isLoading}
                onInsertImages={onInsertImages}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={'images'}
          render={(renderProps) => {
            const props = getFieldProps(renderProps);
            return (
              <AttachmentInput {...props} ref={mergeRef(ref, props.ref)} disabled={isLoading} />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};
