import AddIcon from '@mui/icons-material/Add';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Collapse, IconButton, Typography } from '@mui/material';
import clsx from 'clsx';
import { useOpen, useTranslate } from 'hooks';
import React, { memo, MouseEventHandler, useCallback, useMemo } from 'react';

import { BaseColorfulPaper } from 'components/tickets';
import variables from 'styles/config.scss';
import { TicketNew } from '../ticket-editor';
import style from './index.module.scss';

type Status = { id: string; color: string; labelKey: string };
interface Props {
  status: Status;
  children: React.ReactNode;
  count: number;
}
export const TicketStatusRow = memo<Props>(({ status, children, count }) => {
  const { tp, t } = useTranslate();
  const { id, color, labelKey } = status;
  const { isOpen, onToggle } = useOpen();

  const stateOpenNew = useOpen();

  const initValues = useMemo(
    () => ({
      ticketStatusID: id,
    }),
    [id],
  );
  const handleNewTask = useCallback<MouseEventHandler>(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      stateOpenNew.onOpen();
    },
    [stateOpenNew],
  );

  return (
    <>
      <BaseColorfulPaper bColor={color} variant={'elevation'} className={style.row}>
        <div className={style.header}>
          <Button
            fullWidth
            className={clsx(style.headerWrap, isOpen && style.headerWrapOpen)}
            onClick={onToggle}
          >
            <Typography
              variant={'subtitle2'}
              component={'span'}
              color={'text.secondary'}
              textTransform={'uppercase'}
              letterSpacing={'1.25px'}
              lineHeight={1.2}
              pr={5}
            >
              {t(labelKey)}
              <span className={style.badge}>{count}</span>
            </Typography>
            {isOpen ? (
              <ExpandLessIcon color={'inherit'} sx={{ color: variables.colorGrey }} />
            ) : (
              <ExpandMoreIcon color={'inherit'} sx={{ color: variables.colorGrey }} />
            )}
          </Button>
          <IconButton className={style.headerButton} onClick={handleNewTask} color={'primary'}>
            <AddIcon />
          </IconButton>
        </div>
        <Collapse in={isOpen}>
          <div className={style.content}>
            {children}
            {count === 0 && (
              <Button
                fullWidth
                startIcon={<AddIcon />}
                variant={'text'}
                onClick={handleNewTask}
                className={style.contentButton}
              >
                {tp('ticket-add')}
              </Button>
            )}
          </div>
        </Collapse>
      </BaseColorfulPaper>
      {stateOpenNew.isOpen && (
        <TicketNew
          initValues={initValues}
          onClose={stateOpenNew.onClose}
          onDone={stateOpenNew.onClose}
        />
      )}
    </>
  );
});
