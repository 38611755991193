import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Button, Stack, Typography } from '@mui/material';
import { useDataGridForm, useDataGridTasksHandler } from 'components/data-grid';
import { useCurrentUser, useEffectNotifyError, useTranslate } from 'hooks';
import { useMapSelected } from 'hooks/use-map-selected';
import { RtkQueryReducerProvider, useRtkQueryReducer } from 'hooks/use-rtk-query-reducer';
import { memo, useCallback, useMemo, useState } from 'react';
import { apiTimeTracking } from 'services/time-trackings';
import { convertToHoursAndMinutes, durationToMinutes } from 'utils/app-helpers';
import { Item, MenuButton } from '../../../menu-button';
import { Filters, ViewDesktop } from './components';
import { DialogActionAddToReport } from './components/dialog-action-add-to-report';
import { DialogActionDelete } from './components/dialog-action-delete';
import { DialogActionUpdateDescription } from './components/dialog-action-update-description';
import { Editor } from './components/editor';
import { SelectedProvider } from './components/selected-provider';
import { isInReport, isLocked } from './helpers';
import style from './index.module.scss';
import { ActionComponent, ACTIONS, GridFilters, GridModel, Meta, QUERY_NAME } from './models';

export * from './components/editor-new';

const useQuery = apiTimeTracking.useGetGridTimeTrackingForTicketActionItemsQuery;

const MAP_ACTION_POPUP_COMPONENTS: Record<ACTIONS, ActionComponent> = {
  [ACTIONS.ADD_TO_REPORT]: DialogActionAddToReport,
  [ACTIONS.UPDATE_REPORT_DESCRIPTION]: DialogActionUpdateDescription,
  [ACTIONS.DELETE]: DialogActionDelete,
};

interface Props {
  ticketActionItemID: string | undefined;
  meta: Meta;
}
export const TicketActionItemDurationGrid = memo<Props>(({ ticketActionItemID, meta }) => {
  const { tp } = useTranslate();
  const { appUserID } = useCurrentUser();
  const methods = useRtkQueryReducer<GridFilters>({
    filters: {
      showMyReports: false,
    },
    orderBy: { field: 'date', order: 'desc' },
    pagination: { take: 100 },
  });

  const [action, setAction] = useState<ACTIONS>();
  const onCloseActions = useCallback(() => {
    setAction(undefined);
  }, []);
  const ActionComponent = useMemo(() => {
    if (!action) return null;
    return MAP_ACTION_POPUP_COMPONENTS[action];
  }, [action]);

  const {
    state: { filters },
  } = methods;

  const args = useMemo(
    () => ({
      ticketActionItemID: ticketActionItemID || '',
    }),
    [ticketActionItemID],
  );

  const { data, isSuccess, isFetching, error } = useQuery(args, {
    skip: !ticketActionItemID,
  });
  useEffectNotifyError(error);

  const _rows = useMemo(() => {
    const items = data?.value || [];

    if (!filters.showMyReports) {
      return items;
    }
    return items.filter(({ userCrmProfileID }) => {
      return userCrmProfileID === appUserID;
    });
  }, [filters, appUserID, data]);

  const rows = useMemo(() => {
    return _rows.map((row) => ({
      ...row,
      __isLocked: isLocked(row),
      __isInReport: isInReport(row),
    }));
  }, [_rows]);

  const totalDuration = useMemo(() => {
    const totalMinutes = rows.reduce((acc, row) => acc + durationToMinutes(row.duration), 0);
    return convertToHoursAndMinutes(totalMinutes);
  }, [rows]);

  const selectedMethods = useMapSelected({ rows, key: 'id' });
  const { isAnySelected, selectedRows } = selectedMethods;

  const { isOpen, itemID, onEditRow, onNew, onClose } = useDataGridForm<GridModel>({
    field: 'id',
    name: QUERY_NAME,
  });
  const { onTask } = useDataGridTasksHandler<GridModel>({
    onEditRow,
  });

  const isEmpty = isSuccess && rows.length === 0;
  const isLoading = isFetching;

  const isAllowDelete = useMemo(() => {
    return selectedRows.every((row) => !row.__isLocked);
  }, [selectedRows]);

  const isAllowToUpdate = useMemo(() => {
    return selectedRows.some((row) => row.__isInReport);
  }, [selectedRows]);

  const isAllowToAdd = useMemo(() => {
    return selectedRows.every((row) => !row.__isInReport);
  }, [selectedRows]);

  const menuItems = useMemo<Array<Item>>(() => {
    return [
      {
        title: tp('add-to-report'),
        disabled: !isAllowToAdd,
        onClick: () => setAction(ACTIONS.ADD_TO_REPORT),
      },
      {
        title: tp('update-report-description'),
        disabled: !isAllowToUpdate,
        onClick: () => setAction(ACTIONS.UPDATE_REPORT_DESCRIPTION),
      },
      {
        title: tp('delete'),
        style: { color: 'red' },
        disabled: !isAllowDelete,
        onClick: () => setAction(ACTIONS.DELETE),
      },
    ];
  }, [tp, isAllowDelete, isAllowToUpdate, isAllowToAdd]);

  return (
    <RtkQueryReducerProvider {...methods}>
      <div className={style.root}>
        <Filters isLoading={isLoading} onNew={onNew} />
        <SelectedProvider {...selectedMethods}>
          <ViewDesktop
            meta={meta}
            isLoading={isLoading}
            isEmpty={isEmpty}
            rows={rows}
            onTask={onTask}
            onNew={onNew}
          />
        </SelectedProvider>
        <Stack py={1} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <MenuButton
            items={menuItems}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            }}
          >
            <Button
              variant={'contained'}
              color={'secondary'}
              endIcon={<ArrowRightIcon />}
              disabled={!isAnySelected}
              size={'small'}
              sx={{
                minWidth: 100,
              }}
            >
              {tp('select-an-action')}
            </Button>
          </MenuButton>
          <Stack alignItems={'center'} justifyContent={'flex-end'} direction={'row'} spacing={1}>
            <Typography textAlign={'right'}>{tp('total')}:</Typography>
            <Typography component={'strong'} fontWeight={'bold'}>
              {totalDuration} {tp('hrs')}
            </Typography>
          </Stack>
        </Stack>
      </div>
      {isOpen && ticketActionItemID && (
        <Editor ticketActionItemID={ticketActionItemID} itemID={itemID} onClose={onClose} />
      )}
      {ActionComponent && (
        <ActionComponent
          selectedRows={selectedRows}
          onClose={onCloseActions}
          ticketActionItemID={ticketActionItemID}
          meta={meta}
        />
      )}
    </RtkQueryReducerProvider>
  );
});
