import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../index';

const selectState = (state: AppState) => state.notificationsLayout;

export const selectNotificationsLayoutFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});

export const selectNotificationsLayoutExpanded = createSelector(selectState, ({ isExpanded }) => {
  return isExpanded;
});
