import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/src/createAction';
import { endOfMonth, startOfMonth } from 'date-fns';
import { apiTicketActionItems, ITicketActionItem } from 'services/ticket-action-items';
import { DateValue } from 'utils/dates';
import {
  actionCheckDoneUnAssignAttachToReportItem,
  actionCheckDoneUnAssignFetchAll,
  actionCheckDoneUnAssignFetchItem,
} from './actions';
import { CHECK_DONE_UN_ASSIGN_STATUS, ICheckDoneUnAssignItem, PREFIX } from './helpers';

export interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;

  filters: Filters;
  data: ICheckDoneUnAssignItem[];

  editor: null | Partial<ITicketActionItem>;

  resetScroll: number;
}
interface Filters {
  search: string;
  assigneeUserCrmProfileIDs: string[];
  reporterUserCrmProfileID: string;
  dateRange: DateValue[];
  ticketActionStatusID: string;
}

const initState = (): State => {
  const currentDate = new Date();
  const startDate = startOfMonth(currentDate);
  const endDate = endOfMonth(currentDate);
  return {
    error: null,
    isLoading: false,
    isInit: false,

    filters: {
      search: '',
      assigneeUserCrmProfileIDs: [],
      reporterUserCrmProfileID: '',

      ticketActionStatusID: '',
      dateRange: [startDate, endDate],
    },
    data: [],
    editor: null,
    resetScroll: 0,
  };
};

const slice = createSlice({
  name: PREFIX,
  initialState: initState(),
  reducers: {
    setFilters(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    clearFilters(state) {
      state.filters = initState().filters;
    },
    removeFilterAssigner(state, action: PayloadAction<string>) {
      state.filters.assigneeUserCrmProfileIDs = state.filters.assigneeUserCrmProfileIDs.filter(
        (id) => {
          return id !== action.payload;
        },
      );
    },
    setEditor(state, action: PayloadAction<Partial<ITicketActionItem> | null>) {
      state.editor = action.payload;
    },
    toggleItem(state, action: PayloadAction<string>) {
      const item = state.data.find((item) => item.id === action.payload);

      if (!item) return state;

      item.__checked = !item.__checked;
    },
    removeItem(state, action: PayloadAction<string>) {
      state.data = state.data.filter((item) => action.payload !== item.id);
    },
    resetScroll(state) {
      state.resetScroll++;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionCheckDoneUnAssignFetchAll.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(actionCheckDoneUnAssignFetchAll.fulfilled, (state, action) => {
        const { payload: value } = action;

        state.data = value.map((item) => ({
          ...item,
          __checked: false,
          __error: null,
          __status: CHECK_DONE_UN_ASSIGN_STATUS.DEFAULT,
        }));
        state.isLoading = false;
        state.isInit = true;
      })
      .addCase(actionCheckDoneUnAssignFetchAll.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });

    builder.addCase(actionCheckDoneUnAssignFetchItem.fulfilled, (state, action) => {
      const ticketActionIndex = state.data.findIndex((ticketAction) => {
        return ticketAction.id === action.payload.id;
      });

      if (ticketActionIndex === -1) {
        return state;
      }

      const ticketAction = state.data[ticketActionIndex];

      const isAllAttached = action.payload.tracking.every((i) => i.isAttached);

      if (isAllAttached) {
        state.data.splice(ticketActionIndex, 1);
      } else {
        Object.assign(ticketAction, action.payload);
      }
    });

    builder
      .addCase(actionCheckDoneUnAssignAttachToReportItem.pending, (state, action) => {
        let item = state.data.find((row) => row.id === action.meta.arg.id);

        if (!item) {
          return state;
        }

        item.__status = CHECK_DONE_UN_ASSIGN_STATUS.LOADING;
      })
      .addCase(actionCheckDoneUnAssignAttachToReportItem.fulfilled, (state, action) => {
        let item = state.data.find((row) => row.id === action.meta.arg.id);

        if (!item) {
          return state;
        }

        item.__status = CHECK_DONE_UN_ASSIGN_STATUS.SUCCESS;
        item.tracking = item.tracking.map((item) => ({ ...item, isAttached: true }));
        item.__checked = false;
      })
      .addCase(actionCheckDoneUnAssignAttachToReportItem.rejected, (state, action) => {
        let item = state.data.find((row) => row.id === action.meta.arg.id);

        if (!item) {
          return state;
        }

        item.__status = CHECK_DONE_UN_ASSIGN_STATUS.ERROR;
        item.__error = action.error;
      });

    // watch update ticket action item
    builder.addMatcher(
      apiTicketActionItems.endpoints.patchTicketActionItem.matchPending,
      (state, action) => {
        const ticketAction = state.data.find((ticketAction) => {
          return ticketAction.id === action.meta.arg.originalArgs.id;
        });

        if (ticketAction) {
          Object.assign(ticketAction, action.meta.arg.originalArgs);
        }
      },
    );
  },
});

export const actionsCheckDoneUnAssign = slice.actions;
export const reducerCheckDoneUnAssign = slice.reducer;
