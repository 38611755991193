import { useTheme } from '@mui/material';
import {
  DraggableCalcInitPosition,
  DraggableModal,
  useDraggablePosition,
} from 'components/draggable-modal';
import React, { useCallback, useState } from 'react';
import { calcInitCenterPosition } from 'utils/app-helpers';
import { ContentReportCreate } from './components/content-report-create';
import { ContentReports } from './components/content-reports';
import style from './index.module.scss';
import { BaseReport, BaseReportList, ReportMeat } from './models';

export enum STATE {
  LIST = 'list',
  REPORT = 'report',
}

interface Props {
  isInit: boolean;
  isLoading: boolean;
  reports: BaseReport[];
  initialValues?: Partial<BaseReportList>;
  onClose: () => void;
  onApply: (data: BaseReportList) => void;
  customerID: string;
  meta: ReportMeat;
}

export const BaseDialogActionAddToReport: React.FC<Props> = ({
  isInit,
  isLoading,
  reports,
  onClose,
  onApply,
  initialValues,
  customerID,
  meta,
}) => {
  const calcInitPosition = useCallback<DraggableCalcInitPosition>(
    (paperRef) => calcInitCenterPosition(paperRef),
    [],
  );
  const [position, , { paperRef, onStopDrag }] = useDraggablePosition({
    calcInitPosition,
  });
  const theme = useTheme();

  const [state, setState] = useState(STATE.LIST);

  const setStateToAdd = useCallback(() => {
    setState(STATE.REPORT);
  }, []);
  const setStateToList = useCallback(() => {
    setState(STATE.LIST);
  }, []);

  const HeaderProps = { className: style.header, onClose };

  return (
    <DraggableModal
      open={true}
      onClose={onClose}
      paperRef={paperRef}
      PaperProps={{
        sx: {
          maxWidth:
            state === STATE.REPORT ? theme.breakpoints.values.sm : theme.breakpoints.values.lg,
          maxHeight: '85vh',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
      DraggableProps={{
        handle: `.${style.header}`,
        position: position,
        onStop: onStopDrag,
      }}
    >
      {state === STATE.LIST && (
        <ContentReports
          HeaderProps={HeaderProps}
          initialValues={initialValues}
          isInit={isInit}
          isLoading={isLoading}
          reports={reports}
          onClose={onClose}
          onApply={onApply}
          onAdd={setStateToAdd}
        />
      )}
      {state === STATE.REPORT && (
        <ContentReportCreate
          HeaderProps={HeaderProps}
          onClose={setStateToList}
          customerID={customerID}
          meta={meta}
        />
      )}
    </DraggableModal>
  );
};

export * from './models';
export { ContentReportCreate };
