import * as dynamic from 'utils/dynamic';
import { decoratorIsNotNullable } from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService } from 'utils/service';
import {
  BaseImage,
  BaseImageExist,
  BaseImageNew,
  BaseImageOptions,
  BehaviourHandleImages,
  ServiceWithImages,
} from '../@shared/images';
import { ServiceTicketActionItemImages } from '../ticket-action-item-images';
import { TICKET_ACTION_ITEM_STATUS_IDS } from '../ticket-action-item-statuses';
import {
  API_TICKET_ACTION_ITEMS,
  GetTicketActionItemsForAutocompleteInput,
  ITicketActionItem,
  ITicketActionItemDelete,
  ITicketActionItemPatch,
  ITicketActionItemPin,
  ITicketActionItemPost,
  ITicketActionItemWithMeta,
  ITicketActionPreview,
  TicketActionItemAutocomplete,
  TicketActionItemAutocompleteInput,
  TicketActionItemInfoForNotificationCreated,
  TicketActionItemsAutocomplete,
} from './models';

export * from './helpers';
export * from './models';

type Model = ITicketActionItem;

const REVALIDATE_TAG = 'TicketActionItems' as const;
const REVALIDATE_TAG_HELPER = 'TicketActionItemsHelper' as const;

class Service extends DynamicService<Model> implements ServiceWithImages {
  async getImagesByID(id: string) {
    const { data } = await this.getDynamic<{ images: Array<BaseImage> }>(id, {
      select: dynamic.select(
        'id',
        'ticketActionItemImages.OrderBy(rank).Select(i => new { i.id, i.entryDate, i.description, i.image, i.rank, i.userCrmProfileID }) as images',
      ),
    });

    return data.images;
  }
  async createImage(input: BaseImageNew, options: BaseImageOptions) {
    await ServiceTicketActionItemImages.postItem({ ...input, ticketActionItemID: options.mainID });
  }
  async updateImage(input: BaseImageExist, options: BaseImageOptions) {
    await ServiceTicketActionItemImages.updateItem({
      ...input,
      ticketActionItemID: options.mainID,
    });
  }
  async deleteImage(input: BaseImageExist) {
    await ServiceTicketActionItemImages.delete({
      id: input.id,
    });
  }

  async postTicket(input: ITicketActionItemPost) {
    const { images, ...rest } = input;
    const res = await super.post(rest);

    if (typeof images !== 'undefined') {
      await ServiceImages.perform([], images, { mainID: res.data.id });
    }

    return res;
  }
  async patchTicket(input: ITicketActionItemPatch) {
    const { images, ...rest } = input;

    const res = await super.patch(rest);

    if (typeof images !== 'undefined') {
      const prev = await this.getImagesByID(input.id);
      await ServiceImages.perform(prev, images, { mainID: input.id });
    }

    return res;
  }

  getInfoForNotificationCreated = async (id: string) => {
    return this.getDynamic<TicketActionItemInfoForNotificationCreated>(id, {
      select: dynamic.select(
        'id',
        'title',
        `new { reporterUserCrmProfile.fullName, reporterUserCrmProfile.appIdentityUserID } as reporter`,
        `new { assigneeUserCrmProfile.fullName, assigneeUserCrmProfile.appIdentityUserID } as assignee`,
      ),
    });
  };
}

export const ServiceTicketActionItems = new Service({
  getAll: API_TICKET_ACTION_ITEMS.GET_ALL_DYNAMIC,
  post: API_TICKET_ACTION_ITEMS.POST,
  patch: API_TICKET_ACTION_ITEMS.PATCH,
  delete: API_TICKET_ACTION_ITEMS.DELETE,
});

const ServiceImages = new BehaviourHandleImages(ServiceTicketActionItems);

const selectDefault = dynamic.select(
  'id',
  'title',
  'description',

  'reporterUserCrmProfileID',
  'assigneeUserCrmProfileID',
  'priorityID',
  'ticketActionItemStatusID',

  'ticket.projectID as projectID',
  'ticketID',

  'entryDate',
  'startDateAndTime',
  'endDateAndTime',

  'activityReportDescription',

  'done',
  'review',

  'timeTrackings.count as _durationCount',

  'isActive',
);

const selectTicketActionItemAutocomplete = dynamic.select(
  'id',
  'title',
  'pinned',
  `new { ticketActionItemStatus.color, ticketActionItemStatus.labelKey } as status`,
);

interface ITicketActionItemWithMetaAndItemKey extends ITicketActionItemWithMeta {
  itemKey: Components.Schemas.TicketActionItem['itemKey'];
}

export const apiTicketActionItems = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getTicketActionItem: build.query<ITicketActionItemWithMetaAndItemKey, string>({
      queryFn: async (id) => {
        try {
          const result =
            await ServiceTicketActionItems.getDynamic<ITicketActionItemWithMetaAndItemKey>(id, {
              select: dynamic.select(selectDefault, 'itemKey'),
            });
          return { data: result.data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    getTicketActionItemsForAutocomplete: build.query<
      TicketActionItemsAutocomplete[],
      GetTicketActionItemsForAutocompleteInput
    >({
      queryFn: async ({ search, assigneeUserCrmProfileID, projectID, date }) => {
        try {
          const params = {
            select: [
              'id',
              'ticket.projectID as projectID',
              'title',
              'done',
              'ticketID',
              'new { ticketActionItemStatus.labelKey, ticketActionItemStatus.color } as status',
              'createdDate',
            ].join(','),
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('isActive', true, dynamic.equals),
                dynamic.makeFilter('title', search, dynamic.contains),
                dynamic.makeFilter('ticket.isPrivate', false, dynamic.equals),
                dynamic.makeFilter(
                  'assigneeUserCrmProfileID',
                  assigneeUserCrmProfileID,
                  dynamic.equals,
                ),
                dynamic.makeFilter(
                  'ticket.projectID',
                  projectID,
                  decoratorIsNotNullable(dynamic.equals),
                ),
                `(done==true ? (${
                  dynamic.makeFilter('createdDate', date, dynamic.dateRangeTwoMonths) || 'true'
                }) : (${dynamic.makeFilter(
                  'ticketActionItemStatusID',
                  TICKET_ACTION_ITEM_STATUS_IDS.ON_HOLD,
                  dynamic.notEquals,
                )}))`,
              )
              .join('&&'),
            orderBy: 'createdDate desc,done desc',
            take: 10,
            count: true,
          };
          const result = await ServiceTicketActionItems.getAllDynamic<
            TicketActionItemsAutocomplete,
            typeof params
          >(params);
          return { data: result.data.value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) =>
        result ? result.map(({ id }) => ({ type: REVALIDATE_TAG, id })) : [],
    }),
    getTicketActionItemsAutocompleteByTicket: build.query<
      DynamicResult<TicketActionItemAutocomplete, { count: true }>,
      TicketActionItemAutocompleteInput
    >({
      queryFn: async ({ ticketID, search, isPinned }) => {
        try {
          const params = {
            select: selectTicketActionItemAutocomplete,
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter(['title', 'description'], search, dynamic.contains),
                dynamic.makeFilter('ticketID', ticketID, dynamic.equals),
                isPinned && dynamic.makeFilter('pinned', true, dynamic.equals),
              )
              .join('&&'),
            orderBy: 'pinned desc, createdDate desc,done desc',
            take: 20,
            count: true,
          };
          const result = await ServiceTicketActionItems.getAllDynamic<
            TicketActionItemAutocomplete,
            typeof params
          >(params);
          return { data: result.data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) =>
        result ? result.value.map(({ id }) => ({ type: REVALIDATE_TAG, id })) : [],
    }),
    getTicketActionItemAutocomplete: build.query<TicketActionItemAutocomplete, string>({
      queryFn: async (id) => {
        try {
          const params = {
            select: selectTicketActionItemAutocomplete,
          };
          const result = await ServiceTicketActionItems.getDynamic<TicketActionItemAutocomplete>(
            id,
            params,
          );
          return { data: result.data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    getTicketActionPreview: build.query<ITicketActionPreview, string>({
      queryFn: async (id) => {
        try {
          const result = await ServiceTicketActionItems.getDynamic<ITicketActionPreview>(id, {
            select: dynamic.select(
              'id',
              'title',
              'itemKey',
              'priority.labelKey as priorityLabel',
              'description',
              'assigneeUserCrmProfile.fullName as assignee',
              'reporterUserCrmProfile.fullName as reporter',
              'ticketActionItemStatus.labelKey as status',
              'startDateAndTime',
              'endDateAndTime',
            ),
          });
          return { data: result.data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    getTicketActionItemByKey: build.query<ITicketActionItemWithMeta, string>({
      queryFn: async (itemKey) => {
        try {
          const result = await ServiceTicketActionItems.getAllDynamic<ITicketActionItemWithMeta>({
            select: dynamic.select(
              selectDefault,
              'ticketActionItemImages.OrderBy(rank).Select(i => new { i.id, i.entryDate, i.description, i.image, i.rank, i.userCrmProfileID }) as images',
            ),
            filter: dynamic.makeFilter(
              'itemKey',
              itemKey,
              dynamic.decoratorIsNotNullable(dynamic.equals),
            ),
            take: 1,
          });
          const data = result.data.value[0];
          if (!data) {
            throw new Error('record-not-found');
          }
          return { ...result, data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, itemKey) => [
        { type: REVALIDATE_TAG, id: `itemKey__${itemKey}` },
        ...(result ? [{ type: REVALIDATE_TAG, id: result.id }] : []),
      ],
    }),
    postTicketActionItem: build.mutation<Model, ITicketActionItemPost>({
      queryFn: async (input) => {
        try {
          const { data } = await ServiceTicketActionItems.postTicket(input);
          return { data: data };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { ticketID }) => [
        { type: REVALIDATE_TAG_HELPER, id: ticketID },
      ],
    }),
    patchTicketActionItem: build.mutation<void, ITicketActionItemPatch>({
      queryFn: async (data) => {
        try {
          const { ticketID, ...rest } = data;
          await ServiceTicketActionItems.patchTicket(rest);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { ticketID, id }) => [
        { type: REVALIDATE_TAG, id },
        { type: REVALIDATE_TAG_HELPER, id: ticketID },
      ],
    }),
    activateTicketActionItem: build.mutation<void, ITicketActionItemDelete>({
      queryFn: async (data) => {
        try {
          await ServiceTicketActionItems.patch({ id: data.id, isActive: true });
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: REVALIDATE_TAG }],
    }),
    pinTicketActionItem: build.mutation<void, ITicketActionItemPin>({
      queryFn: async ({ id, ticketID }) => {
        try {
          await ServiceTicketActionItems.patch({
            id,
            pinned: true,
            ticketActionItemStatusID: TICKET_ACTION_ITEM_STATUS_IDS.IN_PROGRESS,
          });

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { ticketID, id }) => [
        { type: REVALIDATE_TAG, id },
        { type: REVALIDATE_TAG_HELPER, id: ticketID },
      ],
    }),
    unPinTicketActionItem: build.mutation<void, ITicketActionItemPin>({
      queryFn: async ({ id, ticketID }) => {
        try {
          await ServiceTicketActionItems.patch({
            id,
            pinned: false,
          });
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { ticketID, id }) => [
        { type: REVALIDATE_TAG, id },
        { type: REVALIDATE_TAG_HELPER, id: ticketID },
      ],
    }),
    deactivateTicketActionItem: build.mutation<void, ITicketActionItemDelete>({
      queryFn: async (data) => {
        try {
          await ServiceTicketActionItems.patch({ id: data.id, isActive: false });
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: REVALIDATE_TAG }],
    }),
    deleteTicketActionItem: build.mutation<void, ITicketActionItemDelete>({
      queryFn: async (data) => {
        try {
          await ServiceTicketActionItems.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: REVALIDATE_TAG }],
    }),
  }),
});
