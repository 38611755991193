import NotificationImportantTwoToneIcon from '@mui/icons-material/NotificationImportantTwoTone';
import { Badge, IconButton, Popover } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { useRef } from 'react';
import { apiUserCrmProfiles } from 'services/user-crm-profiles';
import {
  actionsNotificationsNotDone,
  selectNotificationsNotDoneCount,
  selectNotificationsNotDoneDataVisible,
  selectNotificationsNotDoneIsOpen,
  selectNotificationsNotDoneStatus,
} from 'store/notifications-not-done';
import VARS from 'styles/config.scss';
import { NotificationList } from '../notification-list';

interface Props {}

export const HeaderNotification: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const ref = useRef(null);

  const count = useAppSelector(selectNotificationsNotDoneCount);
  const data = useAppSelector(selectNotificationsNotDoneDataVisible);
  const { isInit } = useAppSelector(selectNotificationsNotDoneStatus);

  // load relative data
  apiUserCrmProfiles.useGetUserCrmProfileSourceQuery();

  const isOpen = useAppSelector(selectNotificationsNotDoneIsOpen);
  const onOpen = () => dispatch(actionsNotificationsNotDone.toggle(true));
  const onClose = () => dispatch(actionsNotificationsNotDone.toggle(false));

  return (
    <>
      <IconButton ref={ref} color={'inherit'} sx={{ color: VARS.colorGrey }} onClick={onOpen}>
        <Badge badgeContent={count} color={'error'}>
          <NotificationImportantTwoToneIcon />
        </Badge>
      </IconButton>
      <Popover
        open={isOpen}
        anchorEl={ref.current}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <NotificationList isLoading={!isInit} rows={data} onClose={onClose} />
      </Popover>
    </>
  );
};
