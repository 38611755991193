import { ITicketActionItem } from 'services/ticket-action-items';

export const PREFIX = 'CHECK_DONE_TRASH';
export interface ITicketActionItemCheckDoneTrash
  extends Pick<
    ITicketActionItem,
    | 'id'
    | 'title'
    | 'itemKey'
    | 'ticketID'
    | 'startDateAndTime'
    | 'endDateAndTime'
    | 'reporterUserCrmProfileID'
    | 'assigneeUserCrmProfileID'
    | 'ticketActionItemStatusID'
  > {}
