import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';
import { useTranslate } from 'hooks';
import { memo } from 'react';
import style from './index.module.scss';

interface Props extends BoxProps {}
export const InactiveLabel = memo<Props>((props) => {
  const { tp } = useTranslate();
  return (
    <Box {...props} className={clsx(style.root, props.className)}>
      {tp('inactive')}
    </Box>
  );
});

export default InactiveLabel;
