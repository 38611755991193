import { Paper, Popper, PopperProps } from '@mui/material';
import clsx from 'clsx';
import { NativeScroll } from 'components/native-scroll';
import React, { forwardRef } from 'react';

export const SelectPopper: React.FC<PopperProps> = (props) => {
  return (
    <Popper {...props} placement="bottom-start" className={clsx(props.className)}>
      {props.children}
    </Popper>
  );
};
export const SelectPaper: React.FC<React.HTMLAttributes<HTMLElement>> = (props) => {
  return <Paper {...props} className={clsx(props.className)} />;
};

export const SelectListBox = forwardRef<any, React.HTMLAttributes<HTMLElement>>(
  ({ children, ...rest }, ref) => {
    return (
      <NativeScroll
        mode={'always'}
        ref={ref}
        {...rest}
        role={'listbox'}
        className={clsx(rest.className)}
      >
        {children}
      </NativeScroll>
    );
  },
);
