import { endOfMonth, endOfWeek, endOfYear, startOfMonth, startOfWeek, startOfYear } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import {
  CalendarRangePicker,
  isSame,
  RenderCalendarProps,
  Strategy,
} from '../components/date-picker-range';
import { convertToDate, DateValue, formatDate, isDateLike } from '../utils/dates';
import { composeFunctions } from '../utils/other';
import { useTranslate } from './use-translate';

interface EmptyCalendarProps extends RenderCalendarProps {
  getInitValue: () => Date[];
}
export const EmptyCalendar: React.FC<EmptyCalendarProps> = ({
  onApply,
  onClose,
  onChange,
  getInitValue,
}) => {
  useEffect(() => {
    const value = getInitValue();
    onChange(value);
    onApply(value);
    onClose();
  }, [getInitValue, onChange, onApply, onClose]);

  return null;
};

enum FROM_TO_STRATEGIES {
  WEEK = 'current-week',
  MONTH = 'current-month',
  YEAR = 'current-year',
  CUSTOM = 'custom',
}
const fromToStrategies: Array<Strategy> = [
  {
    value: FROM_TO_STRATEGIES.WEEK,
    title: FROM_TO_STRATEGIES.WEEK,
    isMatch: (date) => {
      const now = new Date();
      const start = startOfWeek(now);
      const end = endOfWeek(now);
      return [isSame(start, date[0]), isSame(end, date[1])].every(Boolean);
    },
    renderCalendar: (renderProps) => {
      const now = new Date();
      const getInitValue = () => [startOfWeek(now), endOfWeek(now)];
      return <EmptyCalendar getInitValue={getInitValue} {...renderProps} />;
    },
  },
  {
    value: FROM_TO_STRATEGIES.MONTH,
    title: FROM_TO_STRATEGIES.MONTH,
    isMatch: (date) => {
      const now = new Date();
      const start = startOfMonth(now);
      const end = endOfMonth(now);
      return [isSame(start, date[0]), isSame(end, date[1])].every(Boolean);
    },
    renderCalendar: (renderProps) => {
      const now = new Date();
      const getInitValue = () => [startOfMonth(now), endOfMonth(now)];
      return <EmptyCalendar getInitValue={getInitValue} {...renderProps} />;
    },
    renderInputValue: (currentValue) => formatDate(currentValue[0], 'MM/yyyy'),
  },
  {
    value: FROM_TO_STRATEGIES.YEAR,
    title: FROM_TO_STRATEGIES.YEAR,
    isMatch: (date) => {
      const now = new Date();
      const start = startOfYear(now);
      const end = endOfYear(now);

      return [isSame(start, date[0]), isSame(end, date[1])].every(Boolean);
    },
    renderCalendar: (renderProps) => {
      const now = new Date();
      const getInitValue = () => [startOfYear(now), endOfYear(now)];
      return <EmptyCalendar getInitValue={getInitValue} {...renderProps} />;
    },
    renderInputValue: (currentValue) => formatDate(currentValue[0], 'yyyy'),
  },
  {
    value: FROM_TO_STRATEGIES.CUSTOM,
    title: FROM_TO_STRATEGIES.CUSTOM,
    isMatch: (date) => {
      return date.length === 2;
    },
    renderCalendar: (renderProps) => {
      return (
        <CalendarRangePicker
          value={renderProps.value}
          onChange={composeFunctions(renderProps.onChange, renderProps.onApply)}
        />
      );
    },
  },
];

export const toCorrectDateString = (value: DateValue) => {
  return isDateLike(value) ? convertToDate(value).toISOString() : '';
};

export const useDateInputStrategyCurrent = () => {
  const { t } = useTranslate();
  return useMemo(() => {
    return fromToStrategies.map((strategy) => ({
      ...strategy,
      title: typeof strategy.title === 'string' ? t(strategy.title) : strategy.title,
    }));
  }, [t]);
};
