import { useTranslate } from 'hooks/use-translate';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { parseErrorData } from 'utils/service';

export const useNotify = () => {
  const { t } = useTranslate();

  const onError = useCallback(
    (e: { message?: string }) => {
      const err = parseErrorData(e);
      return toast.error(t(err.message));
    },
    [t],
  );
  const onSuccess = useCallback(
    (message?: string) => {
      return toast.success(message || t('success'));
    },
    [t],
  );
  return { onError, onSuccess };
};
export const useEffectNotifyError = (e: { message?: string } | undefined | null) => {
  const { onError } = useNotify();

  useEffect(() => {
    if (e) {
      onError(e);
    }
  }, [e, onError]);
};
