import { FixServerObject } from 'utils/types';

const API = 'CustomerStatuses';

export const API_CUSTOMER_STATUCES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<CustomerStatus, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<CustomerStatus, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface CustomerStatus
  extends FixServerObject<Components.Schemas.CustomerStatus, 'id' | 'title' | 'rank'> {}

export const CUSTOMER_STATUS_ID_ARCHIVE = 'ca78f723-1631-48d3-aeb5-d89bdefefe74';
export const CUSTOMER_STATUS_ID_ACTIVE = '78059094-52dd-4f06-8fd8-d2fee50a48e0';
