import { ActionMatchingPattern } from '@redux-saga/types';
import { apiTickets } from 'services/tickets';
import { call, debounce, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import { workerErrorNotifyThunk } from 'utils/sagas';
import { actionCheckDoneTicketsFetchAll, actionCheckDoneTicketsFetchItem } from './actions';
import {
  selectCheckDoneTicketsData,
  selectCheckDoneTicketsSelectedID,
  selectTicketsDataFilteredMap,
} from './selectors';
import { actionsCheckDoneTickets } from './slice';

function* sagaRefetchTicket(ticketID: string) {
  const tickets = yield* select(selectCheckDoneTicketsData);

  const ticket = tickets.find((ticket) => ticket.id === ticketID);

  if (ticket) {
    yield* put(actionCheckDoneTicketsFetchItem(ticket.id));
  }
}
function* watchUpdateTicketError(
  action: ActionMatchingPattern<typeof apiTickets.endpoints.patchTicket.matchRejected>,
) {
  const {
    meta: {
      arg: { originalArgs },
    },
  } = action;

  yield* call(sagaRefetchTicket, originalArgs.id);
}
function* watchDeleteTicketError(
  action: ActionMatchingPattern<typeof apiTickets.endpoints.deleteTicket.matchRejected>,
) {
  yield* put(actionCheckDoneTicketsFetchAll());
}

function* workerSetFilters() {
  const selectedID = yield* select(selectCheckDoneTicketsSelectedID);
  if (!selectedID) return;
  const ticketsMap = yield* select(selectTicketsDataFilteredMap);

  if (!ticketsMap[selectedID]) {
    yield* put(actionsCheckDoneTickets.selectTicket(null));
  }
}

export const sagaCheckDoneTickets = [
  takeEvery(apiTickets.endpoints.patchTicket.matchRejected, watchUpdateTicketError),
  takeLatest(apiTickets.endpoints.deleteTicket.matchRejected, watchDeleteTicketError),
  takeEvery(actionsCheckDoneTickets.setFilters, workerSetFilters),

  debounce(
    500,
    [actionCheckDoneTicketsFetchAll.rejected, actionCheckDoneTicketsFetchItem.rejected],
    workerErrorNotifyThunk,
  ),
];
