import { createAsyncThunk } from '@reduxjs/toolkit';
import { ServiceTicketActionItems } from 'services/ticket-action-items';
import * as dynamic from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import { selectAppUserID } from '../auth';
import { AppAsyncThunkConfig } from '../index';
import { ICheckDoneAllResponseItem } from './helpers';

const SELECT_TICKET_ITEMS = dynamic.select(
  'id',
  'title',

  'done',
  'review',
  'itemKey',

  'reporterUserCrmProfileID',
  'assigneeUserCrmProfileID',
  'priorityID',
  'ticketActionItemStatusID',

  'startDateAndTime',
  'endDateAndTime',

  'ticketID',
  'ticketActionItemComments.count as totalComments',

  `timeTrackings.Select(t => 
    new {
        t.duration,
        t.date,
        (t.doneItems.Where(d => d.reportDoneItems.count() > 0).count() > 0) as isAttached
    }) as tracking`,
);

export const actionCheckDoneAllFetchAll = createAsyncThunk<
  ICheckDoneAllResponseItem[],
  void,
  AppAsyncThunkConfig
>('CHECK_DONE_ALL/actionCheckDoneAllFetchAll', async (_, { getState }) => {
  try {
    const appUserID = selectAppUserID(getState());
    const params = {
      filter: dynamic
        .mergeFilters(
          dynamic.makeFilter('isActive', true, dynamic.equals),
          dynamic
            .mergeFilters(
              dynamic.makeFilter('ticket.isPrivate', false, dynamic.equals),
              dynamic.makeFilter('ticket.ownerUserCrmProfileID', appUserID, dynamic.equals),
            )
            .join('||'),
        )
        .join('&&'),
      select: SELECT_TICKET_ITEMS,
    };
    const result = await ServiceTicketActionItems.getAllDynamic<
      ICheckDoneAllResponseItem,
      typeof params
    >(params);
    return result.data.value;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionCheckDoneAllFetchItem = createAsyncThunk<
  ICheckDoneAllResponseItem,
  string,
  AppAsyncThunkConfig
>('CHECK_DONE_ALL/actionCheckDoneAllFetchItem', async (id) => {
  try {
    const params = {
      select: SELECT_TICKET_ITEMS,
    };
    const result = await ServiceTicketActionItems.getDynamic<
      ICheckDoneAllResponseItem,
      typeof params
    >(id, params);
    return result.data;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
