import { createSelector } from '@reduxjs/toolkit';
import { endOfDay, startOfDay } from 'date-fns';
import { groupBy } from 'lodash-es';
import { apiReports } from 'services/reports';
import { calcTotatDurations, durationToMinutes } from 'utils/app-helpers';
import { convertToDate, formatDate, hoursToMinutes } from 'utils/dates';
import { getSearchFilter } from 'utils/front-filters';
import { AppState } from '../index';
import { REPORT_EMPTY_TAG } from './helpers';

const selectRootState = (state: AppState) => state;
const selectState = (state: AppState) => state.reportItem;
export const selectReportItemStatuses = createSelector(
  selectState,
  ({ reportID, isLoading, error, isInit }) => {
    return { reportID, isInit, isLoading, error };
  },
);

const selectReportID = createSelector(selectReportItemStatuses, ({ reportID }) => {
  return reportID;
});
export const selectReportItemHoverRowID = createSelector(selectState, ({ hoverRowID }) => {
  return hoverRowID;
});

const selectReportItemReportRequest = createSelector(
  selectRootState,
  selectReportID,
  (state, reportID) => {
    if (!reportID) {
      return null;
    }

    return apiReports.endpoints.getReport.select(reportID)(state);
  },
);

export const selectReportItemReport = createSelector(selectReportItemReportRequest, (request) => {
  if (!request) {
    return null;
  }

  return request.data;
});
export const selectReportItemReportStatuses = createSelector(
  selectReportItemReportRequest,
  (request) => {
    return { isLoading: !!request?.isLoading };
  },
);
export const selectReportItemReportIsEditable = createSelector(selectReportItemReport, (report) => {
  if (!report) {
    return false;
  }

  return report.lock === false;
});
export const selectReportItemReportIsFrozen = createSelector(selectReportItemReport, (report) => {
  if (!report) {
    return true;
  }

  return report.bill === true;
});

export const selectReportItemFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});
export const selectReportItemOrderBy = createSelector(selectState, ({ orderBy }) => {
  return orderBy;
});

export const selectReportItemExpanded = createSelector(selectState, ({ isExpanded }) => {
  return isExpanded;
});

export const selectReportItemReportItems = createSelector(selectState, ({ reportItems }) => {
  return reportItems.map((row) => {
    return {
      ...row,
      _duration: durationToMinutes(row.duration),
      _billableDuration: durationToMinutes(row.billableDuration),
    };
  });
});
export const selectReportItemReportItemsFiltered = createSelector(
  selectReportItemReportItems,
  selectReportItemFilters,
  (rows, filters) => {
    const { projectID, userCrmProfileID, search, tag, dateRange } = filters;
    return rows.filter((row) => {
      let res: Array<Boolean> = [true];
      if (projectID) {
        res.push(row.projectID === projectID);
      }
      if (userCrmProfileID) {
        res.push(row.userCrmProfileID === userCrmProfileID);
      }
      if (tag) {
        res.push(row.tag === tag);
      }
      if (dateRange.length === 2) {
        const dateValue = convertToDate(row.date).getTime();

        res.push(
          startOfDay(convertToDate(dateRange[0])).getTime() <= dateValue,
          endOfDay(convertToDate(dateRange[1])).getTime() >= dateValue,
        );
      }
      if (search) {
        const searchFc = getSearchFilter<typeof row>({
          value: search,
          fields: ['fullName', 'projectName', 'description', 'tag'],
        });

        res.push(searchFc(row));
      }
      return res.every(Boolean);
    });
  },
);

export const selectReportItemGroupProject = createSelector(selectReportItemReportItems, (rows) => {
  return groupBy(rows, (row) => {
    return row.projectID;
  });
});
export const selectReportItemGroupUsers = createSelector(selectReportItemReportItems, (rows) => {
  return groupBy(rows, (row) => {
    return row.userCrmProfileID;
  });
});
export const selectReportItemGroupTags = createSelector(selectReportItemReportItems, (rows) => {
  return groupBy(rows, (row) => {
    return row.tag || REPORT_EMPTY_TAG;
  });
});

export const selectReportItemDurations = createSelector(selectReportItemReportItems, (data) => {
  return calcTotatDurations(data);
});

export const selectReportItemDuplicates = createSelector(
  selectReportItemReport,
  selectReportItemReportItems,
  (report, rows) => {
    const group = groupBy(rows, (row) => {
      return [
        ...(report?.displayColumnDate ? [formatDate(row.date, 'yyyy-MM-dd')] : []),
        ...(report?.displayColumnProjectName ? [row.projectName] : []),
        ...(report?.displayColumnUserName ? [row.userName] : []),
        ...(report?.displayColumnTag ? [row.tag] : []),
        ...(report?.displayColumnDescription ? [row.description] : []),
      ].join('__');
    });

    return Object.values(group).filter((v) => v.length > 1);
  },
);
export const selectReportItemHasDuplicates = createSelector(
  selectReportItemDuplicates,
  (duplications) => {
    return duplications.length > 0;
  },
);
export const selectReportItemOvertimes = createSelector(
  selectReportItemReport,
  selectReportItemReportItems,

  (report, rows) => {
    if (!report) {
      return [];
    }

    const grouped = groupBy(rows, (row) =>
      [
        ...(report?.displayColumnUserName ? [row.userName] : []),
        ...(report?.displayColumnDate ? [formatDate(row.date, 'yyyy-MM-dd')] : []),
      ].join('__'),
    );

    return Object.values(grouped).filter((grouped) => {
      const { duration, billableDuration } = calcTotatDurations(grouped);
      const conditionMinutes = report.maxHoursPerUser
        ? hoursToMinutes(report.maxHoursPerUser)
        : Infinity;

      return [
        ...(report.displayColumnDuration ? [duration] : []),
        ...(report.displayColumnBillableDuration ? [billableDuration] : []),
      ].some((v) => v > conditionMinutes);
    });
  },
);
export const selectReportItemHasOvertimes = createSelector(
  selectReportItemOvertimes,
  (overtimes) => {
    return overtimes.length > 0;
  },
);

export const selectReportItemOverworkTotal = createSelector(
  selectReportItemDurations,
  selectReportItemReport,
  ({ billableDuration }, report) => {
    const maxMinutes = hoursToMinutes(report?.maxHoursReport ?? 0);
    return {
      billableDuration: report?.displayColumnBillableDuration
        ? billableDuration > maxMinutes
        : false,
    };
  },
);

export const selectReportItemOverviewWarnings = createSelector(
  selectReportItemOverworkTotal,
  ({ billableDuration }) => {
    return billableDuration ? 1 : 0;
  },
);

export const selectReportItemIsVisibleStatistic = createSelector(
  selectReportItemReport,
  (report) => {
    const isAnyDuration = !!(
      report?.displayColumnDuration || report?.displayColumnBillableDuration
    );
    const isDisplayTags = !!report?.displayColumnTag;
    const isDisplayUsers = !!report?.displayColumnUserName && isAnyDuration;
    return isDisplayUsers || isDisplayTags;
  },
);
export const selectReportItemIsVisibleValidation = createSelector(
  selectReportItemReport,
  (report) => {
    return report?.displayColumnDuration || report?.displayColumnBillableDuration;
  },
);
