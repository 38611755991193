import { apiRtk, DynamicService } from 'utils/service';
import {
  API_NOTIFICATION_USER_PROFILES,
  INotificationUserProfile,
  NotificationUserProfileDeleteInput,
  NotificationUserProfilePatchInput,
  NotificationUserProfilePostInput,
} from './models';

export * from './models';

type Model = INotificationUserProfile;

const REVALIDATE_TAG = 'NotificationUserProfiles' as const;

class Service extends DynamicService<Model> {}

export const ServiceNotificationUserProfiles = new Service({
  getAll: API_NOTIFICATION_USER_PROFILES.GET_ALL_DYNAMIC,
  post: API_NOTIFICATION_USER_PROFILES.POST,
  patch: API_NOTIFICATION_USER_PROFILES.PATCH,
  delete: API_NOTIFICATION_USER_PROFILES.DELETE,
});

export const apiNotificationUserProfiles = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    postNotificationUserProfile: build.mutation<void, NotificationUserProfilePostInput>({
      queryFn: async (data, { dispatch }) => {
        try {
          await ServiceNotificationUserProfiles.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG, id: 'grid' }],
    }),
    patchNotificationUserProfile: build.mutation<void, NotificationUserProfilePatchInput>({
      queryFn: async (data, { dispatch }) => {
        try {
          await ServiceNotificationUserProfiles.patch(data);

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG, id: 'grid' }],
    }),
    deleteNotificationUserProfile: build.mutation<void, NotificationUserProfileDeleteInput>({
      queryFn: async (data) => {
        try {
          await ServiceNotificationUserProfiles.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG, id: 'grid' }],
    }),
  }),
});
