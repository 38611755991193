import { ButtonProps } from '@mui/material';
import { ButtonAdd } from 'components/buttons';
import { DialogNotificationsCreate, NotificationsCreateModel } from 'components/notifications';
import { useCurrentUser, useEffectNotifyError, useOpen, useTranslate } from 'hooks';
import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { apiAppNotifications, APP_NOTIFICATION_TYPE } from 'services/app-notifications';
import { isFulfilledMutation } from 'utils/service';

const useCreateMutation = apiAppNotifications.useCreateAppNotificationMutation;

interface Props extends ButtonProps {}

export const NotificationControlAdd: React.FC<Props> = ({ ...rest }) => {
  const { tp } = useTranslate();

  const { isOpen, onOpen, onClose } = useOpen();
  const { appUserID } = useCurrentUser();

  const [create, resultCreate] = useCreateMutation();
  useEffectNotifyError(resultCreate.error);

  const onSubmit = useCallback(
    async (formData: NotificationsCreateModel) => {
      const res = await create({
        ...formData,
        reporterUserCrmProfileID: appUserID,
        ticketActionItemID: null,
        ticketActionItemCommentID: null,
      });

      if (isFulfilledMutation(res)) {
        onClose();
        toast.success(tp('success'));
      }
    },
    [create, appUserID, tp, onClose],
  );

  const isLoading = resultCreate.isLoading;
  return (
    <>
      <ButtonAdd variant={'text'} disabled={isLoading} onClick={onOpen} {...rest} />
      {isOpen && (
        <DialogNotificationsCreate
          type={APP_NOTIFICATION_TYPE.REQUESTS}
          isLoading={isLoading}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      )}
    </>
  );
};
