import { Loading } from 'components/loading';
import { APP_ROUTES } from 'configs';
import { CheckDoneLayout } from 'layouts/check-done';
import { EmptyLayout } from 'layouts/empty';
import { ExpensesLayout } from 'layouts/expenses';
import { LoginLayout } from 'layouts/login';
import { NotesLayout } from 'layouts/notes';
import NotificationsLayout from 'layouts/notifications';
import { PrivateLayout } from 'layouts/private';
import { SettingsLayout } from 'layouts/settings';
import React from 'react';
import { RouteObject } from 'react-router-dom';
import { PermissionsProtector } from 'router/permissopns';
import { RequireAuth } from 'router/requred-auth';

const DashboardPage = React.lazy(
  () => import(/* webpackChunkName: "dashboard" */ 'pages/dashboard'),
);

const NotesPage = React.lazy(() => import(/* webpackChunkName: "notes" */ 'pages/notes'));
const NotesArchivePage = React.lazy(
  () => import(/* webpackChunkName: "notes-archive" */ 'pages/notes-archive'),
);

const TimeTracingPage = React.lazy(
  () => import(/* webpackChunkName: "time-tracing" */ 'pages/time-tracing'),
);
const MonthlyActivitiesReportPage = React.lazy(
  () =>
    import(/* webpackChunkName: "monthly-activities-report" */ 'pages/monthly-activities-report'),
);

const CustomersPage = React.lazy(
  () => import(/* webpackChunkName: "customers" */ 'pages/settings/customers'),
);
const ProjectsPage = React.lazy(
  () => import(/* webpackChunkName: "projects" */ 'pages/settings/projects'),
);
const ProjectItemPage = React.lazy(
  () => import(/* webpackChunkName: "project-item" */ 'pages/project-item'),
);
const ReportItemPage = React.lazy(
  () => import(/* webpackChunkName: "report-item" */ 'pages/report-item'),
);
const UsersPage = React.lazy(
  () => import(/* webpackChunkName: "profiles" */ 'pages/settings/crm-profiles'),
);
const UserItemPage = React.lazy(
  () => import(/* webpackChunkName: "user-profile" */ 'pages/settings/crm-profile-item'),
);
const ProfilePage = React.lazy(() => import(/* webpackChunkName: "profile" */ 'pages/profile'));
const PermissionsPage = React.lazy(
  () => import(/* webpackChunkName: "permissions" */ 'pages/settings/crm-permissions'),
);
const AlertsPage = React.lazy(
  () => import(/* webpackChunkName: "alerts" */ 'pages/settings/alerts'),
);
const LabelsPage = React.lazy(
  () => import(/* webpackChunkName: "labels" */ 'pages/settings/labels'),
);
const TimeTrackingActivities = React.lazy(
  () =>
    import(
      /* webpackChunkName: "time-tracking-activities" */ 'pages/settings/time-tracking-activities'
    ),
);
const TicketTypes = React.lazy(
  () => import(/* webpackChunkName: "ticket-types" */ 'pages/settings/ticket-types'),
);
const TicketStatuses = React.lazy(
  () => import(/* webpackChunkName: "ticket-statuses" */ 'pages/settings/ticket-statuses'),
);
const Priorities = React.lazy(
  () => import(/* webpackChunkName: "ticket-priorities" */ 'pages/settings/priorities'),
);

const TicketActionItemStatuses = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ticket-action-item-statuses" */ 'pages/settings/ticket-action-item-statuses'
    ),
);

const ReportsPage = React.lazy(() => import(/* webpackChunkName: "reports" */ 'pages/reports'));

const NotificationsPage = React.lazy(
  () => import(/* webpackChunkName: "notifications" */ 'pages/notifications'),
);
const NotificationsPersonalPage = React.lazy(
  () => import(/* webpackChunkName: "notes-archive" */ 'pages/notifications-personal'),
);

const LoginPage = React.lazy(() => import(/* webpackChunkName: "login" */ 'pages/login'));
const LoginCodePage = React.lazy(
  () => import(/* webpackChunkName: "login-code" */ 'pages/login-code'),
);

const ExpensesPage = React.lazy(() => import(/* webpackChunkName: "expenses" */ 'pages/expenses'));
const SuppliersPage = React.lazy(
  () => import(/* webpackChunkName: "suppliers" */ 'pages/settings/suppliers'),
);
const CategoriesPage = React.lazy(
  () => import(/* webpackChunkName: "categories" */ 'pages/expenses-categories'),
);

const CheckDoneCompleted = React.lazy(
  () => import(/* webpackChunkName: "check-done-completed" */ 'pages/check-done-completed'),
);
const CheckDoneNotCompleted = React.lazy(
  () => import(/* webpackChunkName: "check-done-not-completed" */ 'pages/check-done-not-completed'),
);
const CheckDoneAll = React.lazy(
  () => import(/* webpackChunkName: "check-done-all" */ 'pages/check-done-all'),
);

const CheckDoneUnAssign = React.lazy(
  () => import(/* webpackChunkName: "check-done-un-assign" */ 'pages/check-done-un-assign'),
);
const CheckDoneTrash = React.lazy(
  () => import(/* webpackChunkName: "check-done-trash" */ 'pages/check-done-trash'),
);

const NotFoundPage = React.lazy(() => import('pages/todo'));

export const routes: RouteObject[] = [
  {
    element: (
      <RequireAuth>
        <PrivateLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: APP_ROUTES.DASHBOARD.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <DashboardPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.NOTES.path,
        element: <NotesLayout />,
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<Loading />}>
                <NotesPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.NOTES_ARCHIVE.path,
            element: (
              <React.Suspense fallback={<Loading />}>
                <NotesArchivePage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: APP_ROUTES.USER_ITEM.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <UserItemPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PROFILE.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <ProfilePage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PROJECT_ITEM.path,
        element: (
          <PermissionsProtector
            allowedValues={{
              isAdmin: true,
            }}
          >
            <React.Suspense fallback={<Loading />}>
              <ProjectItemPage />
            </React.Suspense>
          </PermissionsProtector>
        ),
      },
      {
        path: APP_ROUTES.TIME_TRACING.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <TimeTracingPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.SETTINGS.path,
        element: (
          <PermissionsProtector
            allowedValues={{
              isAdmin: true,
            }}
          >
            <SettingsLayout />
          </PermissionsProtector>
        ),
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<Loading />}>
                <CustomersPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.PROJECTS.path,
            element: (
              <React.Suspense fallback={<Loading />}>
                <ProjectsPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.USERS.path,
            element: (
              <React.Suspense fallback={<Loading />}>
                <UsersPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.PERMISSIONS.path,
            element: (
              <React.Suspense fallback={<Loading />}>
                <PermissionsPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.ALERTS.path,
            element: (
              <React.Suspense fallback={<Loading />}>
                <AlertsPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.LABELS.path,
            element: (
              <React.Suspense fallback={<Loading />}>
                <LabelsPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.TIME_TRACKING_ACTIVITIES.path,
            element: (
              <React.Suspense fallback={<Loading />}>
                <TimeTrackingActivities />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.TICKET_TYPES.path,
            element: (
              <React.Suspense fallback={<Loading />}>
                <TicketTypes />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.PRIORITIES.path,
            element: (
              <React.Suspense fallback={<Loading />}>
                <Priorities />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.TICKET_STATUSES.path,
            element: (
              <React.Suspense fallback={<Loading />}>
                <TicketStatuses />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.TICKET_ACTION_ITEM_STATUSES.path,
            element: (
              <React.Suspense fallback={<Loading />}>
                <TicketActionItemStatuses />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: APP_ROUTES.CHECK_DONE.path,
        element: <CheckDoneLayout />,
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<Loading />}>
                <CheckDoneNotCompleted />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CHECK_DONE_COMPLETED.path,
            element: (
              <React.Suspense fallback={<Loading />}>
                <CheckDoneCompleted />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CHECK_DONE_UN_ASSIGN.path,
            element: (
              <React.Suspense fallback={<Loading />}>
                <CheckDoneUnAssign />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CHECK_DONE_ALL.path,
            element: (
              <React.Suspense fallback={<Loading />}>
                <CheckDoneAll />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CHECK_DONE_TRASH.path,
            element: (
              <React.Suspense fallback={<Loading />}>
                <CheckDoneTrash />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: APP_ROUTES.EXPENSES.path,
        element: (
          <PermissionsProtector
            allowedValues={{
              isAdmin: true,
            }}
          >
            <ExpensesLayout />
          </PermissionsProtector>
        ),
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<Loading />}>
                <ExpensesPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.SUPPLIERS.path,
            element: (
              <React.Suspense fallback={<Loading />}>
                <SuppliersPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CATEGORIES.path,
            element: (
              <React.Suspense fallback={<Loading />}>
                <CategoriesPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: APP_ROUTES.MONTHLY_ACTIVITIES_REPORT.path,
        element: (
          <PermissionsProtector
            allowedValues={{
              isAdmin: true,
            }}
          >
            <React.Suspense fallback={<Loading />}>
              <MonthlyActivitiesReportPage />
            </React.Suspense>
          </PermissionsProtector>
        ),
      },
      {
        path: APP_ROUTES.REPORTS.path,
        element: (
          <PermissionsProtector
            allowedValues={{
              __isAllowMakeReport: true,
            }}
          >
            <React.Suspense fallback={<Loading />}>
              <ReportsPage />
            </React.Suspense>
          </PermissionsProtector>
        ),
      },

      {
        path: APP_ROUTES.NOTIFICATIONS.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <NotificationsPage />
          </React.Suspense>
        ),
      },

      {
        path: APP_ROUTES.NOTIFICATIONS.path,
        element: <NotificationsLayout />,
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<Loading />}>
                <NotificationsPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.NOTIFICATIONS_PERSONAL.path,
            element: (
              <React.Suspense fallback={<Loading />}>
                <NotificationsPersonalPage />
              </React.Suspense>
            ),
          },
        ],
      },

      {
        path: APP_ROUTES.REPORT_ITEM.path,
        element: (
          <PermissionsProtector
            allowedValues={{
              isAdmin: true,
            }}
          >
            <React.Suspense fallback={<Loading />}>
              <ReportItemPage />
            </React.Suspense>
          </PermissionsProtector>
        ),
      },
    ],
  },
  {
    element: <LoginLayout />,
    children: [
      {
        path: APP_ROUTES.LOGIN.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <LoginPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.LOGIN_CODE.path,
        element: (
          <React.Suspense fallback={<Loading />}>
            <LoginCodePage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    element: <EmptyLayout />,
    children: [
      {
        path: '*',
        element: (
          <React.Suspense fallback={<Loading />}>
            <NotFoundPage />
          </React.Suspense>
        ),
      },
    ],
  },
];
