import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useEffectNotifyError, useSourceTicketStatuses, useTranslate } from 'hooks';
import { memo, useCallback } from 'react';
import { ITicketStatusOption } from 'services/ticket-statuses';
import { apiTickets } from 'services/tickets';
import { BaseSubMenu, MenuWithColor } from '../base-sub-menu';

const useUpdateMutation = apiTickets.usePatchTicketMutation;

interface Props {
  id: string;
  ticketStatusID: string;
  onClose: () => void;
}

const renderOption = (option: ITicketStatusOption) => {
  return <MenuWithColor title={option.title} color={option.color} />;
};

export const MenuStatuses = memo<Props>(({ id, ticketStatusID, onClose }) => {
  const { tp } = useTranslate();
  const { data: source, isFetching } = useSourceTicketStatuses();
  const [update, updateResult] = useUpdateMutation();
  useEffectNotifyError(updateResult.error);

  const onChange = useCallback(
    (ticketStatusID: string) => {
      update({ id, ticketStatusID });
      onClose();
    },
    [update, id, onClose],
  );

  return (
    <BaseSubMenu
      value={ticketStatusID}
      onChange={onChange}
      options={source}
      renderOption={renderOption}
      MenuProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
    >
      <MenuItem disabled={isFetching}>
        <ListItemIcon>
          <ViewWeekIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{tp('ticket-status')}</ListItemText>
        <ArrowRightIcon />
      </MenuItem>
    </BaseSubMenu>
  );
});
