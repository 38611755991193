import { DynamicOrder } from 'utils/dynamic';
import { FixServerObject, Unset } from 'utils/types';
import * as yup from 'yup';

const API = 'Customers';

export const API_CUSTOMERS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ICustomer, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ICustomer, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface ICustomer
  extends FixServerObject<
    Components.Schemas.Customer,
    'id' | 'isActive' | 'customerStatusID' | 'companyName' | 'invoiceCompanyName'
  > {}

export const schemaCustomer = yup.object({
  companyName: yup.string().required('rule-required').min(2, 'rule-min-length').default(''),
  invoiceCompanyName: yup.string().required('rule-required').min(2, 'rule-min-length').default(''),
  customerStatusID: yup.string().required('rule-required').default(''),
  isActive: yup.boolean().default(true),
  color: yup.string().hex('rule-color-hex').required('rule-required').default('#000000'),
});

export interface IGridCustomers
  extends Pick<ICustomer, 'id' | 'companyName' | 'invoiceCompanyName' | 'customerStatusID'> {
  totalContacts: number;
  totalProjects: number;
  customerStatusTitle: string;
}

export interface IGridCustomersArgs {
  search: string;
  status: string;
  take: number;
  skip: number;
  orderBy: DynamicOrder;
}

export type BaseCustomerSource = {
  title: Unset;
  color: Unset;
};
