import { DynamicOrder } from 'utils/dynamic';
import { FixServerObject } from 'utils/types';
import * as yup from 'yup';

const API = 'UserCrmProfiles';

export const API_USER_CRM_PROFILES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<IUserCrmProfile, 'appIdentityUserID'>) =>
    `${API}/Patch/${data.appIdentityUserID}`,
  DELETE: (data: Pick<IUserCrmProfile, 'appIdentityUserID'>) =>
    `${API}/Delete/${data.appIdentityUserID}`,
};

export interface IUserCrmProfile
  extends FixServerObject<
    Components.Schemas.UserCrmProfile,
    'firstName' | 'lastName' | 'appIdentityUserID'
  > {
  userPhoto?: any;
}

export const baseSchemaCrmUserProfile = yup.object({
  appIdentityUserID: yup.string().notRequired().default(''),
  firstName: yup.string().required('rule-required').min(2, 'rule-min-length').default(''),
  lastName: yup.string().required('rule-required').min(2, 'rule-min-length').default(''),
  startDate: yup.string().required('rule-required').default(''),
  dateOfBirth: yup.string().required('rule-required').default(''),
  email: yup.string().required('rule-required').email('rule-email').default(''),
  personalEmail: yup.string().notRequired().email('rule-email').default(''),
  absences: yup.number().default(0),
  isActive: yup.boolean().default(true),
  isBillable: yup.boolean().default(false),
  isCoreTeamMember: yup.boolean().default(false),
  languageID: yup.string().nullable().notRequired().default(''),
  mobilePhone: yup.string().notRequired().default(''),
  nameHeb: yup.string().notRequired().default(''),
  color: yup.string().hex('rule-color-hex').notRequired().default(''),
  slackUserName: yup.string().notRequired().default(''),
  gitHubUserName: yup.string().notRequired().default(''),
  userPhoto: yup.mixed().notRequired().nullable(),
  generatePasswordEndDate: yup.string().notRequired().nullable(),
  userCrmProfilePermissionID: yup.string().notRequired().default(''),
  noteFadeDefaultDuration: yup
    .number()
    .typeError('rule-number')
    .integer('rule-number')
    .positive('rule-days')
    .max(30, 'rule-max')
    .default(1),
});
export const schemaCrmUserProfile = baseSchemaCrmUserProfile.omit(['noteFadeDefaultDuration']);

export interface IGridCrmUsers
  extends Pick<
    IUserCrmProfile,
    | 'id'
    | 'appIdentityUserID'
    | 'userPhoto'
    | 'firstName'
    | 'lastName'
    | 'userCrmProfilePermissionID'
    | 'slackUserName'
    | 'gitHubUserName'
    | 'email'
    | 'mobilePhone'
    | 'startDate'
    | 'isActive'
  > {
  position: string;
}
export interface IGridCrmProfileArgs {
  search: string;
  userCrmProfilePermissionID: string;
  startDate: (string | Date)[];
  take: number;
  skip: number;
  orderBy: DynamicOrder;
}
