import { Box, Dialog, Skeleton, Stack } from '@mui/material';
import { DialogHeader } from 'components/dialog-header';
import { useEffectNotifyError } from 'hooks';
import React from 'react';
import { apiNotes } from 'services/notes';
import { ContentEdit } from './components/content-edit';
import { ContentPreview } from './components/content-preview';

const SkeletonView: React.FC = () => {
  return (
    <>
      <DialogHeader title={<Skeleton variant={'rectangular'} height={16} width={'33%'} />} />
      <Box p={2}>
        <Skeleton
          variant={'rectangular'}
          height={15}
          width={'33%'}
          sx={{
            mb: 1,
          }}
        />
        <Skeleton
          variant={'rectangular'}
          height={20}
          width={'100%'}
          sx={{
            mb: 2,
          }}
        />
        <Skeleton
          variant={'rectangular'}
          height={160}
          width={'100%'}
          sx={{
            mb: 2,
          }}
        />
        <Stack direction={'row'} spacing={1} justifyContent={'space-between'}>
          <Skeleton variant={'rectangular'} height={36} width={100} />
          <Skeleton variant={'rectangular'} height={36} width={140} />
        </Stack>
      </Box>
    </>
  );
};

const useGetQuery = apiNotes.useGetNoteQuery;

interface Props {
  itemID: string;
  onClose: () => void;
  onDone: () => void;
}
export const DialogNoteEdit: React.FC<Props> = ({ itemID, onClose, onDone }) => {
  const resultGet = useGetQuery(itemID, {
    refetchOnMountOrArgChange: true,
  });
  useEffectNotifyError(resultGet.error);

  const initData = resultGet?.data;

  const isArchive = initData?.isActive === false;

  const Component = resultGet.isLoading ? SkeletonView : isArchive ? ContentPreview : ContentEdit;

  return (
    <Dialog open={true} fullWidth maxWidth={'md'} onClose={onClose}>
      <Component itemID={itemID} onClose={onClose} onDone={onDone} />
    </Dialog>
  );
};
