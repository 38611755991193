import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Breadcrumbs,
  BreadcrumbsProps,
  ButtonBase,
  ButtonBaseProps,
  Skeleton,
} from '@mui/material';
import React, { useMemo } from 'react';
import style from './index.module.scss';

export interface BaseBreadcrumbsItem extends ButtonBaseProps {
  label?: React.ReactNode;
}
interface Props extends BreadcrumbsProps {
  isLoading?: boolean;
  skeletonCount?: number;
  items: BaseBreadcrumbsItem[];
}

export const BaseBreadcrumbs: React.FC<Props> = ({
  items,
  skeletonCount = 3,
  isLoading,
  ...rest
}) => {
  const listItems = useMemo(
    () =>
      items.map((item, index) => {
        return (
          <ButtonBase
            key={index}
            color={'inherit'}
            sx={{
              fontSize: 12,
              textAlign: 'left',
              fontFamily: 'Roboto',
            }}
            {...item}
            children={item.label || item?.children}
          />
        );
      }),
    [items],
  );

  const listSkeleton = useMemo(() => {
    return Array.from({ length: skeletonCount })
      .fill(null)
      .map((_, index) => {
        return (
          <Skeleton
            key={`skeleton-${index}`}
            variant={'text'}
            sx={{ fontSize: '1.4rem' }}
            width={'8rem'}
          />
        );
      });
  }, [skeletonCount]);

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label={'breadcrumb'}
      classes={{ li: style.li, separator: style.separator }}
      {...rest}
    >
      {isLoading ? listSkeleton : listItems}
    </Breadcrumbs>
  );
};
