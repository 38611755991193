import { apiProjects } from 'services/projects';
import { put, takeLatest } from 'typed-redux-saga';
import { actionProjectsRevalidate } from './slice';

function* watcherChanges() {
  yield* put(actionProjectsRevalidate());
}

export const sagasProjects = [
  takeLatest(
    [
      apiProjects.endpoints.postProject.matchFulfilled,
      apiProjects.endpoints.patchProject.matchFulfilled,
      apiProjects.endpoints.deleteProject.matchFulfilled,
    ],
    watcherChanges,
  ),
];
