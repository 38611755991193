import CloseIcon from '@mui/icons-material/Close';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { Box, BoxProps, Button, Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslate } from 'hooks';
import React from 'react';
import style from './index.module.scss';

interface Props extends BoxProps {
  className?: string;
  onClearFilters: () => void;
  disabled: boolean;
}

export const BaseEmpty: React.FC<Props> = ({ className, onClearFilters, disabled }) => {
  const { t } = useTranslate();
  return (
    <Box className={clsx(style.root, className)}>
      <div className={style.wrap}>
        <SearchOffIcon className={style.icon} />
        <Typography variant={'h3'} component={'div'} color={'primary'} mb={1.3}>
          {t('title-search-empty')}
        </Typography>
        <Typography mb={2.8}>{t('text-search-empty')}</Typography>
        <Button
          disabled={disabled}
          variant={'contained'}
          startIcon={<CloseIcon />}
          onClick={onClearFilters}
        >
          {t('clear-filter')}
        </Button>
      </div>
    </Box>
  );
};
