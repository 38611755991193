import { DEFAULT_INIT, IAllProps } from 'components/html-editor';
import { HtmlEditorPreview, Props as EditorProps } from 'components/html-editor-preview';
import React from 'react';

interface Props extends Omit<EditorProps, 'editorProps'> {}

const editorProps: IAllProps = {
  init: {
    ...DEFAULT_INIT,
    height: 250,
  },
};

export const BaseDescriptionInput: React.FC<Props> = (props) => {
  return <HtmlEditorPreview editorProps={editorProps} {...props} />;
};
