import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {}

export const SvgRemove = (props: Props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M7 21C6.45 21 5.97916 20.8041 5.58749 20.4125C5.19583 20.0208 5 19.55 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8041 20.0208 18.4125 20.4125C18.0208 20.8041 17.55 21 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z" />
    </SvgIcon>
  );
};
