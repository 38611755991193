import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGridCustomers } from 'services/customers';
import { actionCustomersGetDynamic } from './actions';

interface Filters {
  search: string;
  status: string;
}
interface Pagination {
  take: number;
  count: number;
  page: number;
}
interface OrderBy {
  field: string;
  order: 'desc' | 'asc' | null;
}

interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;
  data: IGridCustomers[];
  filters: Filters;
  pagination: Pagination;
  orderBy: OrderBy;
}

const initState = (): State => {
  return {
    error: null,
    isLoading: false,
    isInit: false,
    data: [],
    filters: {
      search: '',
      status: 'all',
    },
    pagination: {
      take: 20,
      count: 0,
      page: 1,
    },
    orderBy: {
      field: 'companyName',
      order: 'desc',
    },
  };
};

const slice = createSlice({
  name: 'CUSTOMERS',
  initialState: initState(),
  reducers: {
    actionCustomerFiltersSet(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    actionCustomerFiltersReset(state) {
      state.filters = initState().filters;
    },
    actionCustomerOrderBySet(state, action: PayloadAction<Partial<OrderBy>>) {
      state.orderBy = { ...state.orderBy, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionCustomersGetDynamic.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
      state.pagination.page = action.meta.arg ? action.meta.arg.page : state.pagination.page;
      state.pagination.take = action.meta.arg?.take || state.pagination.take;
    });
    builder.addCase(actionCustomersGetDynamic.fulfilled, (state, action) => {
      const {
        payload: { value, count },
      } = action;
      state.isLoading = false;
      state.isInit = true;
      state.data = value;
      state.pagination.count = count;
    });
    builder.addCase(actionCustomersGetDynamic.rejected, (state, action) => {
      const { error } = action;
      state.isLoading = false;
      state.error = error;
      state.isInit = true;
    });
  },
});

export const { actionCustomerOrderBySet, actionCustomerFiltersSet } = slice.actions;
export const reducerCustomers = slice.reducer;
