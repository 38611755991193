import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  apiUserCrmProfilePermissions,
  IGridCrmPermissions,
  IUserCrmProfilePermissions,
  ServiceUserCrmProfilePermissions,
} from 'services/user-crm-profile-permissions';
import * as dynamic from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';
import { selectCrmPermissionsOrderBy } from './selectors';

export const actionCrmPermissionsGetAllDynamic = createAsyncThunk<
  { value: IGridCrmPermissions[] },
  void,
  AppAsyncThunkConfig
>(`CRM_PERMISSIONS/getAllDynamic`, async (payload, { getState }) => {
  try {
    const { field, order } = selectCrmPermissionsOrderBy(getState());
    const params = {
      select: dynamic.select(
        'id',
        'isActive',
        'title',
        'isAllowToEditSetting',
        'isAllowToEditLabels',
        'isAllowToEditProfile',
        'isAllowToEditCrmUsers',
        'isAllowToEditPermissions',
        'isAllowToViewLog',
        'isAllowToViewSensitiveInformation',
        'isAllowToResetSensitiveInformation',
      ),
      orderBy: dynamic.orderBy(field, order),
    };
    const { data: result } = await ServiceUserCrmProfilePermissions.getAllDynamic<
      IGridCrmPermissions,
      typeof params
    >(params);
    return result;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
export const actionCrmPermissionsChangeItem = createAsyncThunk<
  void,
  Partial<IUserCrmProfilePermissions>,
  AppAsyncThunkConfig
>(`CRM_PERMISSIONS/changeItem`, async (data, { dispatch }) => {
  try {
    const id = data.id;
    if (!id) {
      throw new Error('id is required');
    }
    await dispatch(
      apiUserCrmProfilePermissions.endpoints.patchUserCrmProfilePermission.initiate({
        ...data,
        id,
      }),
    );
    await dispatch(actionCrmPermissionsGetAllDynamic()).unwrap();
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
