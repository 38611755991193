import { createAsyncThunk } from '@reduxjs/toolkit';
import { IGridProjects, ServiceProjects } from 'services/projects';
import * as dynamic from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';
import {
  selectProjectsFilters,
  selectProjectsOrderBy,
  selectProjectsPagination,
} from './selectors';

export const actionProjectsGetDynamic = createAsyncThunk<
  { value: IGridProjects[]; count: number },
  { page: number; take?: number } | void,
  AppAsyncThunkConfig
>(`PROJECTS/getDynamic`, async (payload, { getState }) => {
  try {
    const { search, projectTypeID } = selectProjectsFilters(getState());
    const { take, skip } = selectProjectsPagination(getState());
    const { field, order } = selectProjectsOrderBy(getState());
    const params = {
      filter: dynamic.mergeFilters(
        dynamic.makeFilter(
          ['customer.companyName', 'projectName', 'projectStatus.title'],
          search,
            dynamic.decoratorIsNotNullable(dynamic.contains),
        ),
        dynamic.makeFilter(['projectTypeID'], projectTypeID, dynamic.decoratorIsNotNullable(dynamic.equals)),
      ).join('&&'),
      select: dynamic.select(
        'id',
        'isActive',
        'projectName',
        'projectNameHeb',
        'projectKey',
        'startDate',
        'projectStatus.title as projectStatusTitle',
        'projectType.title as projectTypeTitle',
        'customer.companyName as customerCompanyName',
      ),
      orderBy: dynamic.orderBy(field, order),
      count: true,
      take,
      skip,
    };
    const { data: result } = await ServiceProjects.getAllDynamic<IGridProjects, typeof params>(
      params,
    );
    return result;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
