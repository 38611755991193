import * as yup from 'yup';
import { InferType } from 'yup';

export type BaseReport = Pick<
  Components.Schemas.Report,
  'id' | 'reportName' | 'fromDate' | 'toDate'
>;
export const schemaReportListTicketActionItem = yup.object({
  id: yup.string().required('rule-required').default(''),
  description: yup.string().default(''),
  title: yup.string().default(''),
});

export const schemaReportList = yup.object({
  reportID: yup.string().required('rule-required').default(''),
  ticketActionItems: yup
    .array()
    .of(schemaReportListTicketActionItem)
    .required('rule-required')
    .min(1, 'rule-min')
    .default([]),
});

export type BaseReportList = InferType<typeof schemaReportList>;

export type ReportMeat = {
  customerName?: string | null;
  projectName?: string | null;
};
