import { FORMAT_DYNAMIC_FILTERS_DATE } from 'configs/const';
import { format } from 'date-fns';
import * as dynamic from 'utils/dynamic';
import { apiRtk, DynamicResult, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import {
  API_PROJECT_ALERTS,
  IGridAlerts,
  IGridProjectAlertsArgs,
  IProjectAlert,
  IProjectAlertDashboard,
} from './models';

export * from './models';

type Model = IProjectAlert;
const REVALIDATE_TAG = 'ProjectAlerts';

class Service extends DynamicService<Model> {}

export const ServiceProjectAlerts = new Service({
  getAll: API_PROJECT_ALERTS.GET_ALL_DYNAMIC,
  post: API_PROJECT_ALERTS.POST,
  patch: API_PROJECT_ALERTS.PATCH,
  delete: API_PROJECT_ALERTS.DELETE,
});

export const apiProjectAlerts = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getProjectAlerts: build.query<Model[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceProjectAlerts.getAllDynamic();
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getProjectDashboardAlerts: build.query<IProjectAlertDashboard[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceProjectAlerts.getAllDynamic<IProjectAlertDashboard>({
            filter: dynamic
              .mergeFilters(
                'isActive',
                `dueDate>"${format(new Date(), FORMAT_DYNAMIC_FILTERS_DATE)}"`,
              )
              .join('&&'),
            select: dynamic.select('id', 'project.projectName', 'remarks', 'dueDate'),
            orderBy: 'dueDate',
            take: 3,
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getProjectAlert: build.query<Model, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServiceProjectAlerts.getDynamic(id);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    getGridProjectAlerts: build.query<
      DynamicResult<IGridAlerts, { count: true }>,
      IGridProjectAlertsArgs
    >({
      query: ({ search, projectID, take, skip, orderBy }) => ({
        url: API_PROJECT_ALERTS.GET_ALL_DYNAMIC,
        params: {
          filter: dynamic
            .mergeFilters(
              dynamic.makeFilter(
                ['remarks', 'project.projectName', 'project.customer.companyName'],
                search,
                dynamic.decoratorIsNotNullable(dynamic.contains),
              ),
              dynamic.makeFilter(
                ['projectID'],
                projectID,
                dynamic.decoratorIsNotNullable(dynamic.equals),
              ),
            )
            .join('&&'),
          select: dynamic.select(
            'id',
            'isActive',
            'remarks',
            'dueDate',
            'project.projectName as projectName',
            'project.customer.companyName as customerName',
          ),
          orderBy: dynamic.orderBy(orderBy.field as any, orderBy.order),
          count: true,
          take,
          skip,
        },
      }),
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    postProjectAlert: build.mutation<void, Omit<Model, 'id'>>({
      queryFn: async (customer) => {
        try {
          await ServiceProjectAlerts.post(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchProjectAlert: build.mutation<void, PatchPartial<Model, 'id'>>({
      queryFn: async (customer) => {
        try {
          await ServiceProjectAlerts.patch(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: REVALIDATE_TAG, id }],
    }),
    deleteProjectAlert: build.mutation<void, PatchPartial<Model, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceProjectAlerts.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_TAG },
        { type: REVALIDATE_TAG, id },
      ],
    }),
  }),
});
