import { Box, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { BaseBreadcrumbs } from 'components/tickets';
import { useAppDispatch } from 'hooks';
import { useValueQuery } from 'hooks/use-value-query';
import { memo, useCallback, useMemo } from 'react';
import { ITicket } from 'services/tickets';
import { actionsCheckDoneTickets } from 'store/check-done-tickets';
import variables from 'styles/config.scss';
import { Unset } from 'utils/types';
import { QUERY_NAME_EDIT } from '../../../../helpers';
import { BoardTicketMenu } from '../board-ticket-menu';
import style from './index.module.scss';

type Project = { id: string; title: string };
type Customer = { color: string; id: string; title: string };
interface Props {
  selected?: boolean;
  data: ITicket;
  project: Unset<Project>;
  customer: Unset<Customer>;
  onSelect: () => void;
}
export const TicketCard = memo<Props>(({ selected, project, customer, data, onSelect }) => {
  const { id, title } = data;
  const dispatch = useAppDispatch();

  const { onChange } = useValueQuery({ name: QUERY_NAME_EDIT });

  const onEdit = useCallback(() => {
    onChange(String(data.itemKey));
  }, [onChange, data.itemKey]);

  const breadcrumbs = useMemo(() => {
    return [
      ...(customer
        ? [
            {
              label: customer.title,
              onClick: () =>
                dispatch(
                  actionsCheckDoneTickets.setFilters({ customerID: customer.id, projectID: '' }),
                ),
            },
          ]
        : []),
    ];
  }, [customer, dispatch]);

  return (
    <>
      <Box
        className={clsx(style.root, selected && style.selected)}
        onDoubleClick={onEdit}
        onClick={onSelect}
        id={`ticket-${id}`}
      >
        <div>
          <Stack direction={'row'} spacing={0.9}>
            <div
              className={style.point}
              style={{ backgroundColor: customer?.color || variables.colorGrey }}
            />
            <BaseBreadcrumbs items={breadcrumbs} />
          </Stack>

          <Typography component={'div'} pt={0.6}>
            {title}
          </Typography>
        </div>
        <BoardTicketMenu data={data} />
      </Box>
    </>
  );
});
