export * from 'utils/file-helpers';

export const fileURLToFile = async (url: string) => {
  // Step 1: Fetch the file data from the URL
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  // Step 2: Convert the fetched data to a Blob
  const blob = await response.blob();

  // Step 3: Create a File object from the Blob
  const file = new File([blob], url, {
    type: blob.type,
    lastModified: new Date().getTime(),
  });

  return file;
};
